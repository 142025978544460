import React, { Component, Fragment } from "react";
import Helper from "./../common/Helper";
import ReactTooltip from 'react-tooltip';

class BalanceSheet extends Component {
  state = {
    data : this.props.data,
    keys : ["ASSETS_ARRAY","EQUITY_ARRAY","LIABILITIES_ARRAY"]
  }
  render() {
    const getVarianceCellColor = function(row){
      if(row.pvvar < 0){
        return row.name == 'LIABILITIES' ? "cell-green": "cell-red";
      }
      else{
        return row.name == 'LIABILITIES' ? "cell-red": "cell-green";
      }
    }
    return (
      <Fragment>
        <div className="table-responsive table-border-radius table-fix-height">
          <table className="table table-styling table-bordered table-financial">
            <thead className="table-dark">
              <tr>
                <th scope="col">
                  ACCOUNT GROUP
                </th>
                <th scope="col" width="38%">
                  ACCOUNT NAME
                </th>
                <th scope="col" width="18%">
                  THIS MONTH BALANCE ($)
                </th>
                <th scope="col" width="26%" className="table-small-font">
                  PRIOR MONTH BALANCE ($)
                </th>
                <th scope="col" width="18%">
                  VARIANCE (%) 
                  {/* <div data-tip="React-tooltip" data-for="forBSVariance" className="tooltip-box"></div>
                  <ReactTooltip className='table-custom-tooltip' id="forBSVariance" place="left" type="light" effect="float" data-html={true}>
                    <ul>
                      <li>NEGATIVE VARIANCE<span className="tooltip-box cell-red"></span></li>
                      <li style={{display:"inline-block"}}>POSITIVE VARIANCE<span className="tooltip-box cell-green"></span></li>
                      </ul>
                  </ReactTooltip>  */}
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {this.props.data ?
                this.state.keys.map((key,keyindex) => {
                  if(this.state.data[key]){
                    let length = this.state.data[key].length;
                    return (
                    this.state.data[key].map(function (row, trindex) {
                      if(trindex === 0) {
                        return <tr key={trindex}>
                              <td className="cell-dark value-top" rowSpan={length}>{row.name}</td>
                              <td className="cell-dark">{row.account_name}</td>
                              <td className="cell-white right-align-text">{row.balance < 0 ? `(${Helper.numberFormat(Math.abs(row.balance))})`: Helper.numberFormat(row.balance)}</td>
                              <td className="cell-white right-align-text">{row.prior_balance < 0 ? `(${Helper.numberFormat(Math.abs(row.prior_balance))})` : Helper.numberFormat(row.prior_balance)}</td>
                              <td className={`${getVarianceCellColor(row)} cell-white right-align-text`}>{row.pvvar < 0 ? `(${Helper.numberFormat(Math.abs(row.pvvar),"NC")})`: `${Helper.numberFormat(row.pvvar,"NC")}`}</td>
                            </tr>
                      }else{
                        return <tr key={trindex}>
                            <td className="cell-dark">{row.account_name}</td>
                            <td className="cell-white right-align-text">{row.balance < 0 ? `(${Helper.numberFormat(Math.abs(row.balance))})`: Helper.numberFormat(row.balance)}</td>
                            <td className="cell-white right-align-text">{row.prior_balance < 0 ? `(${Helper.numberFormat(Math.abs(row.prior_balance))})` : Helper.numberFormat(row.prior_balance)}</td>
                            <td className={`${getVarianceCellColor(row)} cell-white right-align-text`}>{row.pvvar < 0 ? `(${Helper.numberFormat(Math.abs(row.pvvar),"NC")})`: `${Helper.numberFormat(row.pvvar,"NC")}`}</td>
                          </tr>
                      }
                    })
                    );
                  }else{
                    return <tr key={keyindex}>
                      <td className="cell-dark">{key.substring(0, key.length - 6)}</td>
                      <td className="cell-dark">-</td>
                      <td className="cell-white right-align-text">-</td>
                      <td className="cell-white right-align-text">-</td>
                      <td className={`cell-white right-align-text`}>-</td>
                    </tr>
                  }
                })
                : null }
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default BalanceSheet;
