import React from 'react';
import PropTypes from 'prop-types';

import { Tabs } from 'antd';
import StudentsTab from './tabs/studentsTab';
import OverviewTab from './tabs/overviewTab';

const { TabPane } = Tabs;

const InnerTabSection = ({ tabConfig }) => (
  <>
    <Tabs
      activeKey={tabConfig.activeTab}
      onChange={tabConfig.setActiveTab}
      destroyInactiveTabPane
    >
      <TabPane tab="Students" key="students">
        <StudentsTab />
      </TabPane>
      <TabPane tab="Overview" key="overview">
        <OverviewTab />
      </TabPane>
    </Tabs>
  </>
);

InnerTabSection.propTypes = {
  tabConfig: PropTypes.object,
};

export default InnerTabSection;
