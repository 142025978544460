import React, { useRef, useEffect, useState } from 'react';
import ModuleProgressGridView from './moduleProgressGridView';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { get as _get } from 'lodash';
import MetaCellRenderer from '../../common/table/MetaCellRenderer';
import ModuleCompletionBar from './components/moduleCompletionBar';
import { Button } from 'antd';
import { makeInnovativeStudentMainReportCourseComparisonResponseData, makeInnovativeStudentMainReportLoading } from '../../../../selectors/innovative/student.selector';
import { usePrevious } from '../../../../utils/customHooks';
import ChartDataFetch from '../../../innovative_student/studentProfile/chartDataFetch';
import { fetchSelectedCourseProgress } from '../../../../actions/innovative/course.action';
import { makeModuleProgress } from '../../../../selectors/innovative/course.selector';
import Loader from '../../common/layout/Loader';
import {useParams} from "react-router";

const MODULE_COMPARISON_FETCH_ARRAY_SIZE = 3;
const ADMIN_PATH = "/insights/admin";

const ModuleProgressGrid = ({ selectedTerm }) => {
  const dispatch = useDispatch();
  const control = useRef();
  const parsedUrl = new URL(window.location.href);
  const {studentId} = useParams();
  const isAdminPath = parsedUrl.pathname.split("/teacher")[0] === ADMIN_PATH;

  const [chartDataController, setChartDataController] = useState(null);
  const [fetchModuleProgressData, setFetchModuleProgressData] = useState(false);
  const [selectedTablePagination, setSelectedTablePagination] = useState(0);

  // selectors
  const courseComparison = useSelector(makeInnovativeStudentMainReportCourseComparisonResponseData);
  const courseComparisonLoading = useSelector(makeInnovativeStudentMainReportLoading);
  const moduleData = useSelector(makeModuleProgress);
  const prevValues = usePrevious({ studentDetailsArray: courseComparison });

  useEffect(() => {
    const preVisibleStatus = _get(prevValues, 'studentDetailsArray', []);
    if (JSON.stringify(preVisibleStatus) !== JSON.stringify(courseComparison)) {
      setFetchModuleProgressData(true)
    }
  }, [prevValues, courseComparison]);

  useEffect(() => {
    // create abort controller instance for abort fetch calls
    control.current = new AbortController();

    return () => {
      // component will unmount
      control.current.abort()
    };
  }, []);

  useEffect(() => {
    // initiate fetching chart data after main report data loaded
    if (control.current && fetchModuleProgressData) {
      const courseIds = courseComparison.map(course => course.course_id)
      if (courseIds.length) {
        fetchChartData(courseIds)
      }
      setFetchModuleProgressData(false);
    }
  }, [control.current, fetchModuleProgressData]);

  useEffect(() => {
    if (moduleData && moduleData.length > 0 && chartDataController) {
      chartDataController.validateArray(moduleData.map(data => data.courseId), selectedTablePagination)
    }
  }, [chartDataController, moduleData, selectedTablePagination]);

  const fetchChartData = async (courseIds) => {
    // create object of data handler and initiate chart data fetching
    const chartController = new ChartDataFetch(
      MODULE_COMPARISON_FETCH_ARRAY_SIZE,
      courseIds,
      dispatchChartData
    );
    setChartDataController(chartController);
    chartController.initiateFetch();
  }

  const dispatchChartData = (courseId) => {
    const data = {
      "course_id": courseId,
      "student_id": studentId,
    }
    if (studentId && courseId) {
      dispatch(fetchSelectedCourseProgress({ ...data }, control.current.signal));
    }
  }

  const setStateData = ({ courseId }) => {
    const moduleCardDataFilter = moduleData?.filter(module => module.courseId === courseId)?.[0];
    const moduleDataArray = _get(moduleCardDataFilter, "moduleData", []);
    const moduleCardData = moduleDataArray?.map(module => module.moduleId);

    if (moduleCardData) {
      return moduleCardData;
    }

    return [];
  }

  const RenderCompletedModules = ({ courseId, moduleData = [] }) => {
    const isDataAvailable = !!moduleData.filter(module => module.courseId === courseId).length;
    const moduleCardData = moduleData.find(module => module.courseId === courseId);

    return (
      <div>
        <Loader isLoading={!isDataAvailable}>
          <div>
            {moduleCardData?.moduleCompletionView ? moduleCardData.moduleCompletionView : "N/A"}
          </div>
        </Loader>
      </div>
    )
  }

  const getColumnDefinition = () => {
    return [
      {
        title: 'Course',
        key: "module_progress_course",
        dataIndex: 'module_progress_course',
        render: (_, row) =>
          <MetaCellRenderer
            title={row.course_name}
            avatar={row.course_image}
            subtitle={row.course_id}
          />
      },
      {
        title: 'Completed Modules',
        key: "completed_modules",
        dataIndex: 'completed_modules',
        render: (_, row) =>
          <RenderCompletedModules
            courseId={row.course_id}
            moduleData={moduleData}
          />
      },
      {
        title: 'Module Completion %',
        key: "module_completion",
        dataIndex: 'module_completion',
        render: (_, row) =>
          <ModuleCompletionBar
            courseId={row.course_id}
            moduleData={moduleData}
          />
      },
      {
        key: "module_view_btn",
        dataIndex: 'module_view_btn',
        render: (_, row) =>
          <div style={{ float: 'right' }}>
            <Button>
              <Link to={{
                pathname: isAdminPath ? `/insights/admin/teacher/student-assignments/${studentId}/${row["course_id"]}/${selectedTerm}`:
                `/insights/teacher/student-assignments/${studentId}/${row["course_id"]}/${selectedTerm}`,
                state: { moduleData: setStateData({ courseId: row.course_id }), fromModuleProgress: true, isAdminPath }
              }
              }>View</Link>
            </Button>
          </div>
      },
    ];
  };

  return <ModuleProgressGridView
    isLoading={courseComparisonLoading}
    dataSource={courseComparison}
    columns={getColumnDefinition()}
    setSelectedTablePagination={setSelectedTablePagination}
  />
};

export default ModuleProgressGrid;
