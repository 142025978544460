import _get from 'lodash/get';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';
import _snakeCase from 'lodash/snakeCase';

import {
  calculationModes,
  complianceSections,
  noDataValue,
} from '../../../../constant/vetTrack/analysis';

export const getSubSectionDataByKey = (key, sectionData) => {
  const factorKey = Object.keys(sectionData).find(
    item => sectionData[item].key === key,
  );
  return factorKey ? sectionData[factorKey] : null;
};

// this can be used to get an object array for filters in data table
export const getUniqValuesForFilters = (dataset, valueKey, displayKey) => {

  if (typeof dataset !== 'object') {
    return false;
  };

  const dataArr = dataset.map(item => {
    const value = _get(item, valueKey);
    const text = _get(item, displayKey);
    const visaExpirationKey = _snakeCase(
      _get(complianceSections, 'visaExpiration.key', ''),
    );
    let defaultLabel = text;

    if (
      ( displayKey.includes(visaExpirationKey) ||
        displayKey === 'expirationDate'
      ) &&
      parseInt(value) < 1)
    {
      defaultLabel = 'Expired';
    }
    return {
      text: isEmptyValue(text) ? 'No Data' : defaultLabel,
      value: isEmptyValue(value) ? noDataValue : value,
    }
  });

  return _remove(
    _uniqWith(dataArr, _isEqual),
    item => ![undefined, null].includes(item.value),
  );
};

// this provides the correct sorting method for table columns in student tables.
export const getSortMethod = (factorType, sortingValuePosition) => (a, b) => {
  const valueA = _get(a, sortingValuePosition, undefined);
  const valueB = _get(b, sortingValuePosition, undefined);

  if (factorType === calculationModes.condition) {
    return valueA - valueB;
  }

  if (factorType === calculationModes.category && valueA) {
    return valueA.localeCompare(valueB);
  }
};

// this is a generic response error format for reducers
export const resolveResponseErrorFormat = error => {
  return {
    data: {},
    error: error.message,
  };
};

export const isEmptyValue = value =>
  value === null || value === undefined || value === '';


export const onFilterColumn = (value, record, key, target = 'text') => {
  const recordText = _get(record, `${key}.${target}`, '');

  if (value === noDataValue) {
    return !(record && record[key] && record[key][target]);
  }

  return recordText === value;
}; 

export const sortDataColumn = (row1, row2, key) => {
  const row1Value = _get(row1, `${key}.value`) || 0;
  const row2Value = _get(row2, `${key}.value`) || 0;

  return parseFloat(row1Value) - parseFloat(row2Value);
};
