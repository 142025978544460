import React from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import {
  getAddUserAccountsList,
  getAddUserAccountsListLoading,
} from "../../../../selectors/userManagement/userManagement.selector";
import Loader from "../../../connections/common/layout/Loader";

const SelectAccount = ({ setSelectedAccount }) => {
  const accountList = useSelector(getAddUserAccountsList);
  const accountsLoading = useSelector(getAddUserAccountsListLoading);

  const onAccountSelect = (_, data) => {
    setSelectedAccount(data);
  };

  const accountOptionArray = () => {
    if (!accountList.length) {
      return [];
    }

    return accountList.map(account => (
      {
        value: account.userName,
        ...account,
      }
    ))
  };

  return (
    <>
      <Loader isLoading={accountsLoading} />
      {
        !accountsLoading && (
          <Select
            defaultActiveFirstOption
            defaultOpen={!accountsLoading}
            options={accountOptionArray()}
            onSelect={onAccountSelect}
            filterOption={false}
          />
        )
      }
    </>
  )
}

export default SelectAccount;
