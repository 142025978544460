import React from 'react';
import { Typography, Space } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph, Title } = Typography;

const ChartMeta = ({ name, value, currency }) => {
  const getTitle = () => {
    if (currency) {
      return `${currency} ${value}`;
    }

    return value;
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Paragraph strong>{name}</Paragraph>
        <Title level={3}>{getTitle()}</Title>
      </Space>
    </div>
  );
};

ChartMeta.defaultProps = {
  currency: '',
};

ChartMeta.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  currency: PropTypes.string,
};

export default ChartMeta;
