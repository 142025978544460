import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, PageHeader, Row, Col} from "antd";
import _get from "lodash/get";
import _debounce from "lodash/debounce";

import Loader from "../../common/layout/Loader";
import FilterForm from "../../stats/partials/filterForm/filterForm";
import TabView from "../../stats/partials/tabView/TabView";
import moment from "moment";


const AdminList = ({adminData, filterForm, filterFormSubmit}) => {
    const [displayRows, setDisplayRows] = useState("25");
    const [filteredData, setFilteredData] = useState([]);
    const [searchVal, setSearchVal] = useState("");

    useEffect(() => {
        //set default filters
        filterForm.setFieldsValue({
            dateRange: [moment(), moment()],
            rows:  displayRows,
        });
    }, [])

    useEffect(() => {
        setFilteredData({...adminData})
    }, [adminData])

    const filterData = (searchValue) => {
        if(adminData){
            if (searchValue === "") return setFilteredData({...adminData})
            const data = _get(adminData, 'data.response', [])
            const filteredData = data.filter(i => i.name?.toLowerCase().includes(searchValue.toLowerCase()))
            const x = {
                ...adminData,
                data: {
                    ...adminData.data,
                    response: filteredData
                }
            }
            setFilteredData(x)

        }

    }

    const onSearchChange = (searchValue) => {
        setSearchVal(searchValue);
        filterData(searchValue);
        // if rendering is slow, enable debounce by enabling the following
        // _debounce(() => filterData(searchValue), 200)()
    }

    const onSubmit = (values) => {
        setSearchVal("");
        filterFormSubmit(values);
    }

    return (
        <Loader>
            <div>
                <Row className="adminHeadingCover">
                    <Col xl={6}>
                        <h4>User Stats</h4>
                    </Col>
                    <Col xl={18}>
                        <FilterForm
                            onSearchChange={onSearchChange}
                            form={filterForm}
                            onSubmit={onSubmit}
                            setDisplayRows={setDisplayRows}
                            searchVal={searchVal}
                        />
                    </Col>
                </Row>
                <Card className="stat-view-card">
                    <TabView filterData={filterForm.getFieldsValue()} displayRows={displayRows} data={filteredData} className="tabList"/>
                </Card>
            </div>
        </Loader>
    )
}


AdminList.propTypes = {
    adminData: PropTypes.array,
    filterForm: PropTypes.object,
    filterFormSubmit: PropTypes.func
}

export default AdminList;