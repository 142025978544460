import AccClassProfileService from "./acc/ClassProfile";
import AccFilterService from "./acc/Filters";
import AccStudentProfileService from "./acc/StudentProfile";
import AccParentProfile from "./acc/parentProfile";
import AccPrincipalProfileService from "./acc/principalProfile";
import AccEnrolmentProfileService from "./acc/enrolmentProfile";
import AccManagementProfileService from "./acc/managementProfile";
import ACCCeoProfileService from "./acc/ceoProfile";
import Auth from "./acc/auth";

export default {
    AccClassProfileService,
    AccFilterService,
    AccStudentProfileService,
    AccParentProfile,
    AccPrincipalProfileService,
    AccEnrolmentProfileService,
    AccManagementProfileService,
    ACCCeoProfileService,
    Auth,
    AccManagementProfileService
}