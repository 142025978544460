import React from 'react';

import TopCards from './partials/topCards';
import StudentTableContainer from './partials/studentTable';

const OverviewTabContainer = () => (
  <div>
    <TopCards />
    <StudentTableContainer />
  </div>
);

export default OverviewTabContainer;
