import React from 'react';

import SyncingBanner from './SyncingBannerView';

export const withSyncingBanner = Component => ({ ...props }) => {
  const { isSyncing } = props;

  return (
    <>
      {isSyncing && <SyncingBanner />}
      <Component {...props} />
    </>
  );
};
