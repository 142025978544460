import React, {memo, useState} from "react";
import {Table} from "antd";

const UserGrid = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'userId',
        },
        {
            title: 'User Name',
            dataIndex: 'username',
        },
        {
            title: 'Groups',
            dataIndex: 'groups',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Imported From',
            dataIndex: 'importedFrom',
        },
    ];

    const data = [];
    for (let i = 0; i < 46; i++) {
        data.push({
            key: i,
            userId: `Edward King ${i}`,
            username: `Edward King ${i}`,
            groups: `Student, Teacher, Admin`,
            email: "test@test.com",
            importedFrom: "CSV",
        });
    }


    return (
        <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
    )
}

export default memo(UserGrid);