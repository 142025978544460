import React from "react";
import {Form, Input, Button, Select} from "antd";
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const FieldMappingForm = () => {
    return (
        <Form className="user-import-field-mapping" name="dynamic_form_nest_item"  autoComplete="off">
            <Form.List name="fieldMappings">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div className="container">
                                <div key={key} style={{ display: 'flex', marginBottom: 8, justifyContent:"center" }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        className="dynamic-field"
                                        name={[name, "fromField"]}
                                        fieldKey={[fieldKey, 'fromField']}
                                        rules={[{ required: true, message: 'Missing from field' }]}
                                    >
                                        <Select defaultValue="field1">
                                            <Option value="field1">Field 1</Option>
                                            <Option value="field2">Field 2</Option>
                                        </Select>
                                    </Form.Item>
                                    <div  style={{width:"10%", textAlign:"center"}}>
                                        <div className="to-label">
                                            to
                                        </div>
                                    </div>
                                    <Form.Item
                                        {...restField}
                                        className="dynamic-field"
                                        name={[name, 'toField']}
                                        fieldKey={[fieldKey, 'toField']}
                                        rules={[{ required: true, message: 'Missing to field' }]}
                                    >
                                         <Select defaultValue="field1">
                                            <Option value="field1">Field 1</Option>
                                            <Option value="field2">Field 2</Option>
                                        </Select>
                                    </Form.Item>
                                    <Button type="primary" style={{marginLeft:10}} icon={<DeleteOutlined/>} onClick={() => remove(name)} danger/>
                                </div>
                            </div>

                        ))}
                        <Button style={{float:"right", marginBottom:20}} type="dashed" onClick={() => add()}>
                            Add
                        </Button>
                    </>
                )}
            </Form.List>
        </Form>
    )
}

export default FieldMappingForm;