import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { pageSize, headerColumns, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state


        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={headerColumns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                    showPageSizeOptions={false}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Datatable;