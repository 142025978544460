import React from "react";
import {Table} from "antd";
import PropTypes from "prop-types";

import StatChart from "../../../common/Chart";

const CardTable = ({data}) => {

    const prepareChartOptions = (chartData) => {
        return {
            chart: {
                type: "pie",
                width:250,
                height:100
            },
            plotOptions: {
                pie: {
                    showInLegend: true,
                    innerSize: "50%",
                }
            },

            tooltip: {
                formatter: function () {
                    return `<b>${this.key} ${Math.round(this.percentage)} %</b>`;
                },
                hideDelay:0,
                enabled:true
            },

            legend: {
                align: 'right',
                verticalAlign: 'middle',
                labelFormatter: function () {
                    return `${this.name} ${Math.round(this.percentage)} %`;
                },
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: chartData
            }]
        }
    }

    const creatChartData = (chartData) => {
        const chartOptions = prepareChartOptions(chartData)

        return (
            <StatChart chartOptions={chartOptions}/>
        )
    }

    const dataSource = data;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => <span className="card-table-data">{value}</span>
        },
        {
            title: 'Count of Views',
            dataIndex: 'view_count',
            key: 'view_count',
            align:'center',
            render: (value) => <span className="card-table-data">{value}</span>
        },
        {
            title: 'Overview',
            dataIndex: 'overview_cohort',
            key: 'overview_cohort',
            render: (value) => creatChartData(value)
        },
    ];


    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{x:true}}
        />
    )
}

CardTable.propTypes = {
    data: PropTypes.array
}

export default CardTable;