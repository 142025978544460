import { get as _get } from 'lodash';
import {
  ACC_SET_STUDENT_PROFILE,
  ACC_SET_PARENT_STUDENTS,
  ACC_SET_SUBJECT_ANALYSIS,
  ACC_SET_PARENT_DASHBOARD_LOADING,
  ACC_SET_CURRENT_STUDENT_LOADING,
  ACC_SET_SUBJECT_ANALYSIS_LOADING,
  ACC_OVERRIDE_SUBJECT_ANALYSIS, ACC_SET_SELECTED_ITEMS_LOADING,
} from '../../constant/actionTypes';
import ApiServices from  '../../services/tentacle';

const { studentFilters } = ApiServices.AccFilterService;
const { studentProfile } = ApiServices.AccParentProfile;
const { subjectAnalysis } = ApiServices.AccStudentProfileService;

// Fetch all student data
export const fetchParentStudent = (params, token) => dispatch => {
  dispatch(setParentDashbordLoading(true));

  studentFilters(params, token)
    .then(res => res.json())
    .then(result => {
      dispatch(setStudentsMainData(result));
      dispatch(setParentDashbordLoading(false));
    })
    .catch(() => {
      dispatch(setParentDashbordLoading(false));
    });
};

// Fetch data for selected student
export const fetchStudentProfile = (params, token) => dispatch => {
  dispatch(setStudentLoading(true));

  studentProfile(params, token)
    .then(res => res.json())
    .then(result => {
      dispatch(setStudentProfileData(result));
      dispatch(setStudentLoading(false));
    })
    .catch(() => {
      dispatch(setStudentLoading(false));
    });
};

// Fetch Subject Analysis
export const fetchSubjectAnalysis = (params, token) => dispatch => {
  dispatch(setSubjectAnalysisLoading(true));
  dispatch(setSubjectElementsLoading(params.courseIds));

  const { courseNames, ...rest } = params;

  subjectAnalysis(rest, token)
    .then(res => res.json())
    .then(result => {
      dispatch(setSubjectAnalysis(result, params.courseIds, courseNames));
      dispatch(setSubjectAnalysisLoading(false));
    })
    .catch(() => {
      dispatch(setSubjectAnalysisLoading(false));
    });
};

const setParentDashbordLoading = payload => dispatch => {
  dispatch({
    type: ACC_SET_PARENT_DASHBOARD_LOADING,
    payload,
  });
};

const setStudentsMainData = result => dispatch => {
  const data = {
    filterData: _get(result, 'data', {}),
    filterTerms: _get(result, 'data.years'),
  };
  
  dispatch({
    type: ACC_SET_PARENT_STUDENTS,
    payload: data,
  });
};

const setStudentLoading = payload => dispatch => {
  dispatch({
    type: ACC_SET_CURRENT_STUDENT_LOADING,
    payload,
  });
};

const setStudentProfileData = result => dispatch => {
  const data = _get(result, 'data.result', {});

  dispatch({
    type: ACC_SET_STUDENT_PROFILE,
    payload : data,
  });
};

const setSubjectAnalysisLoading = payload => dispatch => {
  dispatch({
    type: ACC_SET_SUBJECT_ANALYSIS_LOADING,
    payload,
  });
};

const setSubjectAnalysis = (result, courseIds, courseNames) => dispatch => {
  const data = _get(result, 'data.response.body.data', []);

  let payload = {};

  if (!data.length && courseIds[0] && courseNames[0]) {
    payload[courseIds[0]] = {
      courseId1: courseIds[0],
      name: courseNames[0],
      isLoading: false,
      hasNoData: true,
    }
  }

  data.forEach(element => {
    payload[element.courseId1] = {
      ...element,
      isLoading: false,
    }
  });

  dispatch({
    type: ACC_SET_SUBJECT_ANALYSIS,
    payload,
  });
};

const setTempSubjectElements = courseIds => dispatch => {
  let payload = {};

  courseIds.forEach(id => {
    payload[id] = {
      courseId1: id,
      isLoading: true,
    }
  });

  dispatch({
    type: ACC_SET_SUBJECT_ANALYSIS,
    payload,
  });
}

const setSubjectElementsLoading = courseIds => dispatch => {
  dispatch({
    type: ACC_SET_SELECTED_ITEMS_LOADING,
    payload: courseIds,
  });
}

export const overrideSubjectAnalysis = data => dispatch => {
  let payload = {};
  data.forEach(element => {
    const { id: courseId1, ...rest } = element
    payload[courseId1] = {
      ...rest,
      courseId1,
      isLoading: true,
    }
  });

  dispatch({
    type: ACC_OVERRIDE_SUBJECT_ANALYSIS,
    payload,
  });
}