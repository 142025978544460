import React, {memo} from 'react';
import {Col} from "antd";
import StatsCard from "./StatsCard";
import PropTypes from "prop-types";

const StatCardGroup = ({summaries}) => {
    return summaries.map(summary => (
        <Col sm={24} md={12} lg={12} xl={6} key={summary.title}>
            <StatsCard summary={summary}/>
        </Col>
    ))
};

StatCardGroup.propTypes = {
    summaries: PropTypes.array,
};

export default memo(StatCardGroup);