import {
    FETCH_GLOBAL_SETTINGS,
    FETCH_GLOBAL_SETTINGS_LOADING,
    SAVE_GLOBAL_SETTINGS,
    SAVE_GLOBAL_SETTINGS_LOADING,
} from "../../constant/actionTypes";
import { settingsReducerDefaultState } from "../../constant/innovative/settingState";

const settingsReducer = (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_GLOBAL_SETTINGS:
            return {
                ...state,
                selected: action.payload
            }
        case FETCH_GLOBAL_SETTINGS_LOADING:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    isLoading: action.payload.isLoading,
                },
            }
        case SAVE_GLOBAL_SETTINGS:
            return {
                ...state,
                saved: action.payload,
            };
        case SAVE_GLOBAL_SETTINGS_LOADING:
            return {
                ...state,
                saved: {
                    ...state.saved,
                    isLoading: action.payload.isLoading,
                },
            };
        default:
            return state;
    }
};

export default settingsReducer;