import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Typography } from 'antd';

import Sidebar from '../../common/layout/Sidebar';

const { Paragraph } = Typography;

const SettingSidebar = ({ onChange, sections, selectedSection }) => {
  const renderMenuItem = item => (
    <Menu.Item key={item.key}>{item.name}</Menu.Item>
  );

  const renderMenu = () => (
    <div>
      <Paragraph strong className="vtt-gen-setting-sidebar-header">
        Manage Risk Settings
      </Paragraph>
      <Menu
        mode="inline"
        selectedKeys={[selectedSection && selectedSection.key]}
        onClick={onChange}
      >
        {sections.map(section => renderMenuItem(section))}
      </Menu>
    </div>
  );

  return <Sidebar>{renderMenu()}</Sidebar>;
};

SettingSidebar.propTypes = {
  onChange: PropTypes.func,
  sections: PropTypes.array,
  selectedSection: PropTypes.object,
};

export default SettingSidebar;
