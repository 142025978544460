import {
    SET_CANVAS_KEYS,
    SET_CANVAS_KEYS_LOADING,
    VALIDATE_USER_TOKEN,
    VALIDATE_USER_TOKEN_LOADING,
} from "../constant/actionTypes";
import request from "../utils/request";

export const loadingCanvasKeys = (isLoading = true) => dispatch => {
    dispatch({
        type: SET_CANVAS_KEYS_LOADING,
        payload: {
            isLoading
        }
    });
};

export const setCanvasKeys = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: SET_CANVAS_KEYS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

export const setupCanvas = payload => dispatch => {
    dispatch(loadingCanvasKeys());

    const feedReducer = (response) => {
        dispatch(setCanvasKeys(response));
    }

    const requestOptions = {
        service: "user/onboarding/keygen",
        method: "POST",
        data: payload.requestParams,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};


export const loadingValidateUserToken = (isLoading = true) => dispatch => {
    dispatch({
        type: VALIDATE_USER_TOKEN_LOADING,
        payload: {
            isLoading
        }
    });
};

export const setUserTokenValidationStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: VALIDATE_USER_TOKEN,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

export const validateUserToken = token => dispatch => {
    dispatch(loadingValidateUserToken());

    const feedReducer = (response) => {
        dispatch(setUserTokenValidationStatus(response));
    }

    const requestOptions = {
        service: `user/validate-token/${token}`,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};