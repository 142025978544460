import {combineReducers} from "redux";

import vwUserDealership from "./vwUserDealership.reducer";
import jrGenUser from "./jrGenUser.reducer";

const vwUserManagementReducers = combineReducers({
    vwUserDealership,
    jrGenUser,
});

export default vwUserManagementReducers;