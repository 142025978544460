import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";

import {
    makeVwDealershipSidebarResponseData,
    makeVwDealershipOpenPanelsDataResponse,
    makeVwDealershipEditModeDataResponse
} from "../../../../../selectors/vwDealership/setting.selector";
import {
    fetchingSidebarData,
    setOpenPanels,
    clearUnitsData
} from "../../../../../actions/vwDealership/settings.action";

import Sidebar from "./Sidebar";

const SidebarContainer = ({setWarningVisible, setBreadcrumb}) => {
    const dispatch = useDispatch();

    // Selectors
    const dealerships = useSelector(makeVwDealershipSidebarResponseData('dealerships'));
    const departments = useSelector(makeVwDealershipSidebarResponseData('departments'));
    const roles = useSelector(makeVwDealershipSidebarResponseData('roles'));
    const openPanels = useSelector(makeVwDealershipOpenPanelsDataResponse);
    const editMode = useSelector(makeVwDealershipEditModeDataResponse);

    useEffect(() => {
        dispatch(setOpenPanels({
            main: "1",
            dealership: null,
            department: null,
            role: null,
            pathway: null,
            program: null,
        }));

        dispatch(fetchingSidebarData({data:openPanels, key:"dealership"}));
    }, [])

    const setOpenPanelData = (data) => {
        dispatch(setOpenPanels(data))
    }

    const findBreadcrumbLabel = (list, key, keyProp = "id", valueProp = "name") => {
        const data =  list.find(item => String(item[keyProp]) === key)
        if(data){
            return data[valueProp];
        }else{
            return null
        }
    };

    const getDrillDownedData = (dataList, key, parentId) => {
        // this will return the selected pathway or program according to the selected data.
        // This is used for breadcrumb data preparation
        let x = [];
        dataList.find(item => {
            // identify the level and find the correct parent dataset
            if(item.childrenKey === key){
                if (item.id === parentId){
                    return x =  item.children;
                }
            }else{
                return x = getDrillDownedData(item.children, key, parentId)
            }

        })

        return x;
    }

    const prepareBreadcrumbData = () => {
        const breadcrumbData = Object.keys(openPanels).filter(key => key !== "main").map(key =>{
            switch (key) {
                case "dealership":
                    return findBreadcrumbLabel(dealerships, openPanels[key]);
                case "department":
                    return findBreadcrumbLabel(departments, openPanels[key]);
                case "role":
                    return findBreadcrumbLabel(roles, openPanels[key]);
                case "pathway":
                    return findBreadcrumbLabel(getDrillDownedData(roles, key, openPanels["role"]), openPanels[key]);
                case "program":
                    return findBreadcrumbLabel(getDrillDownedData(roles, key, openPanels["pathway"]), openPanels[key]);
            }
        })
        setBreadcrumb(breadcrumbData);
    }

    useEffect(() => {
        prepareBreadcrumbData();
    }, [openPanels])

    const sectionOnChange = (key, id) => {
        // check whether the user in the edit mode and show the warning
        if(editMode){
            return setWarningVisible(true)
        }

        // find the opened panel and close all the child panels that already opened
        const keys = Object.keys(openPanels);
        const keyIndex = keys.findIndex(item => item === key);

        let newPanelData = {};
        if (keyIndex+1 < keys.length){
            keys.slice(keyIndex+1).map(item => (newPanelData[item]=null))
        }

        setOpenPanelData({...openPanels, [key]: id, ...newPanelData});

        if(id){
            if(["dealership", "department"].includes(key)) {
                if (keyIndex + 1 < keys.length) {
                    if (openPanels[key] !== id) {
                        dispatch(fetchingSidebarData({data: {...openPanels, [key]: id}, key: keys[keyIndex + 1]}))
                    }
                }
            }
            if(["dealership"].includes(key)) {
                // clear units data
                dispatch(clearUnitsData());
            }
        }
    }

    return (
        <Sidebar
            dealerships={dealerships}
            departments={departments}
            roles={roles}
            openPanels={openPanels}
            setOpenPanels={sectionOnChange}
        />
    )
}

SidebarContainer.propTypes = {
    setWarningVisible: PropTypes.func,
    setBreadcrumb: PropTypes.func,
}

export default SidebarContainer;
