import React from 'react';
import PropTypes from 'prop-types';
import { HELP_CENTER_URL } from '../../../../utils/innovative/constants';

const HelpContents = props => {
  const { setModalStatus } = props;
  return (
    <div className="innovative-help-center">
      <a href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
        Help Center
      </a>
      <span
        onClick={() => {
          setModalStatus(true);
        }}
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Submit Feedback
      </span>
    </div>
  );
};

HelpContents.propTypes = {
  setModalStatus: PropTypes.func,
};

export default HelpContents;
