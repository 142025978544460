import {
    FETCH_DEALERSHIP_MAIN_REPORT_DATA,
    FETCH_ONLINE_MODULES_DATA_LOADING,
    FETCH_ONLINE_MODULES_DATA,
    FETCH_ONLINE_STUDENTS_DATA_LOADING,
    FETCH_ONLINE_STUDENTS_DATA
} from "../../constant/actionTypes";

const mandatoryOnlineReducerDefaultState = {
    modules: {
        list: {
            isLoading:false,
            error: false,
            data: {}
        },
        selected: {
            isLoading:false,
            error: false,
            data: {},
            moduleSelected: false
        }
    }
};

const mandatoryOnlineReducer = (state = mandatoryOnlineReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_DEALERSHIP_MAIN_REPORT_DATA:
            return {
                ...state,
                modules: {
                    ...state.modules,
                    list: {
                        ...state.modules.list,
                        ...(action.payload.data && {data: {modules:action.payload.data.mandatoryModules}}),
                    }
                }
            }
        case FETCH_ONLINE_MODULES_DATA_LOADING:
        case FETCH_ONLINE_MODULES_DATA:
            const {data, ...rest} = action.payload
            return {
                ...state,
                modules: {
                    ...state.modules,
                    list: {
                        ...state.modules.list,
                        ...rest,
                        ...(data && {data: {modules:data.mandatoryModules}}),
                    }
                }
            }
        case FETCH_ONLINE_STUDENTS_DATA_LOADING:
        case FETCH_ONLINE_STUDENTS_DATA:
            return {
                ...state,
                modules: {
                    ...state.modules,
                    selected: {
                        ...state.modules.selected,
                        ...action.payload,
                    }
                }
            }
        default:
            return state;
    }
};

export default mandatoryOnlineReducer;