import React, {Fragment} from "react";
import {Card, PageHeader, Typography, Row, Col} from "antd";

import DataSources from "../dataSourceConfig/partials/DataSources";
import menuData from "../../../../layout/dashboardManagement/menuData";

const {Title, Link} = Typography;

const DataSourceConfigView = () => {
    return (
        <Fragment>
            <PageHeader
                className="site-page-header"
            />
            <Card>

                <Row justify="space-between">
                    <Col span={12}>
                       <Title level={5}>Report Datasource</Title>
                    </Col>
                    <Col span={12} style={{textAlign: "end"}}>
                         <Link href={menuData.manageDataSource.url} target="_top">
                            Manage Data Sources
                        </Link>
                    </Col>
                </Row>
                <div>
                    <DataSources/>
                </div>
            </Card>
        </Fragment>
    )
}

export default DataSourceConfigView;