import React, {memo} from 'react';
import PropTypes from "prop-types";

const StatCellRenderer = ({value, label, styles}) => {
    const labelTextRender = (label, value) => {
        if(value === "N/A") return "";
        return label;
    }
    return (
        <div className="table-stat-cell">
            <div  style={styles} className="stat">{value}</div>
            {label ? <div className="stat-description">{labelTextRender(label, value)}</div> : null}
        </div>        
    );
};

StatCellRenderer.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    styles: PropTypes.object,
}

StatCellRenderer.defaultProps = {
    // value to
    value: null,
    // label of the data
    label: undefined,
    // default styles
    styles: {},
}

export default memo(StatCellRenderer);