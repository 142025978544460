import React from "react";
import {Card, Tabs} from "antd";
import PropTypes from "prop-types";
import _upperFirst from "lodash/upperFirst";
import _kebabCase from "lodash/kebabCase";

import SettingHeader from "../../../common/SectionHeader";
import AcademicTabContainer from "./tabs/academic";
import FinancialTabContainer from "./tabs/financial";
import ComplianceTabContainer from "./tabs/compliance";
import WellbeingTabContainer from "./tabs/wellbeing";
import OverviewTabContainer from "./tabs/overview";

import {subSections} from "../../../../../constant/jobReadyGeneric/constants";
import {menu} from "../../../../../constant/jobReadyGeneric/menu";

const {TabPane} = Tabs;

const TabSection = ({tabConfig, barDisabled}) => {

    return (
        <Card>
            <SettingHeader title={_upperFirst(_kebabCase(tabConfig.activeTab))} items={[{name: 'Risk Analysis', onClick:() =>tabConfig.setActiveTab(subSections.overview.key)}, _upperFirst(_kebabCase(tabConfig.activeTab))]}/>
            <Tabs
                activeKey={tabConfig.activeTab}
                onChange={tabConfig.setActiveTab}
                destroyInactiveTabPane={true}
            >
                <TabPane tab="Overview" key={subSections.overview.key} disabled={barDisabled}>
                    <OverviewTabContainer/>
                </TabPane>
                <TabPane tab="Academic" key={subSections.academic.key} disabled={barDisabled}>
                    <AcademicTabContainer/>
                </TabPane>
                <TabPane tab="Financial" key={subSections.financial.key} disabled={barDisabled}>
                    <FinancialTabContainer/>
                </TabPane>
                <TabPane tab="Compliance" key={subSections.compliance.key} disabled={barDisabled}>
                    <ComplianceTabContainer/>
                </TabPane>
                {/* Not in Phase 01*/}
                {/*<TabPane tab="Well-being" key={subSections.wellBeing.key} disabled={barDisabled}>*/}
                {/*    <WellbeingTabContainer/>*/}
                {/*</TabPane>*/}
            </Tabs>

        </Card>
    )
}

TabSection.propTypes = {
    tabConfig: PropTypes.object,
    barDisabled: PropTypes.bool
}

export default TabSection;