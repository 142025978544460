import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { useLocation } from 'react-router';
import {
  fetchCourses,
  fetchCoursesReport,
  fetchTerms,
  setTerm,
  verifyAdminStatus,
  setSelectedAccount,
  setSwitchFormValues as setSwithFilterValues,
  updateApplicationLevelAdmin,
  fetchNewCourseData,
} from "../../../actions/innovative/course.action";
import {
  makeInnovativeCoursesLoading,
  makeInnovativeCoursesResponseData,
  makeInnovativeTermsResponseData,
  makeInnovativeTermsLoading,
  getSelectedTerm,
  makeInnovativeCoursesMainReportLoading,
  getAdminStatus,
  adminVerificationLoading,
  getAdminAccounts,
  getSelectedAccount,
  makeInnovativeCourseStatus,
  makeInnovativeCourseResponseData,
  getIsApplicationAdmin,
  makeFetchNewCourseDataStatus,
} from "../../../selectors/innovative/course.selector";
import { makeInnovativeSettingByPath, makeInnovativeSettingsClusterEnableData } from "../../../selectors/innovative/setting.selector";
import { setBreadcrumb, setTopNavParam } from "../../../actions/innovative/layout.action";
import CourseView from "./CourseView";
import session from "../../../utils/session";
import useDidMountEffect from "../../../utils/innovative/customHooks";
import impressionHandler from "../../../utils/impressionHandler/impressionHandler";
import useCourseHandlers from '../../../utils/innovative/coursehandlers';
import { usePrevious } from '../../../utils/customHooks';
import {
  ValidateURL,
  isAdminDashboardView,
  queryStringToObject,
} from '../../../utils/general';
import Error403 from "../../dashboardCommon/errorPages/Error403";
import menuData from '../../layout/dashboardManagement/menuData';
const ADMIN_PATH = "/insights/admin/courses";
const SEARCH_PARAMS_KEYS = ["token", "t", "access"];

const CourseContainer = ({ history }) => {

  const location = useLocation();
  useEffect(() => {
    session.setOrAppend(session.keys.innovativeMeta, queryStringToObject(location.search));
  }, [location.search]);

  const dispatch = useDispatch();
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const impressionMetaData = session.get(_get(session, "keys.impressionMeta", ""));
  const applicationAdmin = _get(dashboardManagementData, "is_admin", false);

  // selectors
  const areCoursesLoading = useSelector(makeInnovativeCoursesLoading);
  // to prevent tab switching, wait until the report data is loading
  const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);
  const courseList = useSelector(makeInnovativeCoursesResponseData);
  const isAdminUser = useSelector(getAdminStatus);
  const isVerificationLoading = useSelector(adminVerificationLoading);
  const accountList = useSelector(getAdminAccounts);
  const selectedCourseData = useSelector(makeInnovativeCourseResponseData);
  const terms = useSelector(makeInnovativeTermsResponseData);
  const termsLoading = useSelector(makeInnovativeTermsLoading);
  const selectedTermId = useSelector(getSelectedTerm);
  const selectedAccountId = useSelector(getSelectedAccount);
  const markingScheme = useSelector(makeInnovativeSettingByPath("markingScheme"));
  const isAccountDataLoading = useSelector(makeInnovativeCoursesLoading);
  const courseStatusList = useSelector(makeInnovativeCourseStatus);

  const isClusteringEnabled = useSelector(makeInnovativeSettingsClusterEnableData);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const fetchCoursesEnabled = useSelector(makeFetchNewCourseDataStatus);

  const parsedUrl = new URL(window.location.href);
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const path = parsedUrl.pathname;

  const isAdminDashboard = isAdminDashboardView({ actAs, path, ADMIN_PATH });


  const [loadingFinished, setLoadingFinished] = useState({
    hasCoursesLoaded: false,
    hasCourseMainReportLoaded: false,
    hasTermsLoaded: false,
    hasVerificationLoaded: false,
  });

  const [courseListChanged, setCourseListChanged] = useState(false);
  const [hasAllLoadingFinished, setHasAllLoadingFinished] = useState(false);

  const {
    switchFormValues,
    setSwitchFormValues,
    selectCourse,
  } = useCourseHandlers();

  const previousValues = usePrevious({
    areCoursesLoading,
    courseMainReportLoading,
    termsLoading,
    isVerificationLoading,
    courseList
  });

  const getSearchParams = () => {
    if (applicationAdmin || isAdminDashboard) {
      return [...SEARCH_PARAMS_KEYS, "actAs"];
    }
    return SEARCH_PARAMS_KEYS;
  };

  const validateURL = ValidateURL({
    url: window.location.search,
    validateUrlParams: getSearchParams(),
  });

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  };

  const onChangeStudentState = (states) => {
    const stateObj = {
      active: states.includes('active'),
      completed: states.includes('completed'),
    };

    dispatch(setSwithFilterValues(stateObj));
    const requestParams = {
      "course_id": selectedCourseData?.["course_id"],
      "student_states": states,
    };

    dispatch(fetchCoursesReport({ requestParams }));
  }

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs,
    }));
  }, [actAs]);

  useEffect(() => {
    if (
      !isAdminDashboard &&
      !termsLoading &&
      terms &&
      !terms.length
    ) {
      dispatch(fetchTerms({
        "user_id": session.get([
          session.keys.innovativeMeta,
          "token"
        ])
      }))
    }
  }, [isAdminDashboard, termsLoading, terms])

  // get terms for the selected user
  useEffect(() => {
    setTimeout(() => {
      isAdminDashboard && dispatch(verifyAdminStatus(adminPayload));
    }, 1000);

    //Handle the impression for the user access level : for Stat 
    // Timeout is set to not affect the dashboard loading request
    setTimeout(() =>
      // Set impression data
      impressionHandler.sendImpressionData("innovative_courses")
      , 2000)
  }, []);

  //Admin dashboard filters
  useEffect(() => {
    if (
      selectedAccountId &&
      fetchCoursesEnabled &&
      !Object.keys(selectedCourseData).length
    ) {
      dispatch(fetchCourses({
        requestParams: {
          accountId: selectedAccountId,
          filterCourseStates: courseStatusList,
          ...adminPayload,
        }
      }))
    }
  }, [
    selectedAccountId,
    JSON.stringify(courseStatusList),
    fetchCoursesEnabled,
    selectedCourseData
  ])

  useEffect(() => {
    if (selectedAccountId) {
      dispatch(fetchNewCourseData(true));
    }
  }, [selectedAccountId])

  useDidMountEffect(() => {
    if (!isVerificationLoading && accountList && accountList[0]) {
      dispatch(setSelectedAccount(selectedAccountId ? selectedAccountId : accountList[0]._id))
    }
  }, [accountList])

  // at first page load fetching courses
  // also caching current query params to send with each request
  useEffect(() => {
    if (!termsLoading && terms && terms.length && terms[0]) {
      if (actAs === "AccountMembership") {
        if (isAdminDashboard) {
          dispatch(fetchCourses({
            existingRequest: false,
            requestParams: {
              "user_id": session.get([
                session.keys.innovativeMeta,
                "token"
              ]),
              "term_ids": [selectedTermId ? selectedTermId : terms[0]._id],
              "filterCourseStates": courseStatusList,
              ...adminPayload
            }
          }));
        }
      } else if (actAs === "TeacherEnrollment") {
        dispatch(fetchCourses({
          existingRequest: true,
          requestParams: {
            "user_id": session.get([
              session.keys.innovativeMeta,
              "token"
            ]),
            "term_ids": [selectedTermId ? selectedTermId : terms[0]._id],
            "filterCourseStates": courseStatusList,
            ...adminPayload
          }
        }))
      } else {
        dispatch(fetchCourses({
          existingRequest: true,
          requestParams: {
            "user_id": session.get([
              session.keys.innovativeMeta,
              "token"
            ]),
            "term_ids": [selectedTermId ? selectedTermId : terms[0]._id],
            "filterCourseStates": courseStatusList,
            ...adminPayload
          }
        }))
      }

      dispatch(setTerm(selectedTermId ? selectedTermId : terms[0]._id));
      // disabling settings top navigation
      return () => {
        dispatch(setTopNavParam("settings", false));
      }
    }
    if (!termsLoading && terms && !terms.length) {
      setLoadingFinished(prevState => ({
        ...prevState,
        hasCourseMainReportLoaded: true,
        hasCoursesLoaded: true
      }));
    }
  }, [
    JSON.stringify(terms),
    JSON.stringify(courseStatusList),
    termsLoading
  ]);

  const prepareBreadcrumb = (item) => {
    return [
      {
        key: 'home',
        section: "Home",
      },
      {
        key: item["course_id"],
        section: item["course_name"],
      }
    ];
  }

  // once the courses are loaded, store them in the state
  // and request for the first course details and main report
  useEffect(() => {
    const breadCrumb = prepareBreadcrumb(selectedCourseData);
    dispatch(setBreadcrumb(breadCrumb));

    let item = {};
    const enabledCourses = courseList && courseList.filter(datum => datum.status);
    const enabledCourseIds = enabledCourses.map(course => course.course_id);

    if (courseListChanged && enabledCourses.length) {
      if (Object.keys(selectedCourseData).length
        && (enabledCourseIds.includes(selectedCourseData.course_id)
          || enabledCourseIds.includes(parseInt(selectedCourseData.course_id))
        )
      ) {
        return;
      }
      if (
        _isEmpty(selectedCourseData, true) ||
        !enabledCourseIds.includes(selectedCourseData.course_id)
      ) {
        item = enabledCourses[0];
      } else {
        item = selectedCourseData;
      }
      if (_isEqual(selectedCourseData, item)) {
        return;
      }

      selectCourse(item);
      setCourseListChanged(false);
      return;
    }
    else if (!enabledCourses.length) {
      setCourseListChanged(false);
      selectCourse({});
    }
  }, [
    JSON.stringify(selectedCourseData),
    JSON.stringify(courseList),
    courseListChanged,
  ]);

  useEffect(() => {
    const prevAreCoursesLoading = _get(
      previousValues,
      'areCoursesLoading',
      false,
    );
    const prevCourseMainReportLoading = _get(
      previousValues,
      'courseMainReportLoading',
      false,
    );
    const prevTermsLoading = _get(previousValues, 'termsLoading', false);

    const prevCourseList = _get(previousValues, 'courseList', []);

    if (prevCourseList !== courseList) {
      const reFetchCourseData = () => {
        if (prevCourseList.length === 0 || typeof prevCourseList === "undefined") {
          return true
        }
        else if (JSON.stringify(prevCourseList) !== JSON.stringify(courseList)) {
          return true
        }
        return false
      }

      setCourseListChanged(reFetchCourseData());
    }

    if (prevAreCoursesLoading && !areCoursesLoading) {
      setLoadingFinished(prevState => ({
        ...prevState,
        hasCoursesLoaded: true,
      }));
    }

    if (prevCourseMainReportLoading && !courseMainReportLoading) {
      setLoadingFinished(prevState => ({
        ...prevState,
        hasCourseMainReportLoaded: true,
      }));
    }

    if (prevTermsLoading && !termsLoading) {
      setLoadingFinished(prevState => ({
        ...prevState,
        hasTermsLoaded: true,
      }));
    }

    if (!isVerificationLoading) {
      setLoadingFinished(prevState => ({
        ...prevState,
        hasVerificationLoaded: true,
      }));
    }
  }, [
    areCoursesLoading,
    courseMainReportLoading,
    termsLoading,
    isVerificationLoading,
    JSON.stringify(previousValues),
  ]);

  useEffect(() => {
    setHasAllLoadingFinished(!isLoading());
  }, [loadingFinished]);

  const isLoading = () => {
    const loading =
      areCoursesLoading ||
      courseMainReportLoading ||
      termsLoading ||
      isVerificationLoading;

    if (selectedTermId) {
      return !loading;
    }

    if (isAdminUser) {
      const { hasTermsLoaded, ...restLoading } = loadingFinished;

      if (isAccountDataLoading) {
        return false
      }

      if (!(courseList && courseList.length)) return true;

      if (courseList && courseList[0]?.course_id) {
        return true
      }

      return !Object.values(restLoading).every(item => item);
    }

    return Object.values(loadingFinished).every(item => item);
  };

  const navigateBackTo = () => {
    if (applicationAdmin) {
      history.push(`${menuData.manageDashboard.url}?token=${impressionMetaData.token}&t=${impressionMetaData.t}`);
      return;
    }
    history.push(`${menuData.myDashboard.url}?token=${impressionMetaData.token}&t=${impressionMetaData.t}`);
  };

  if (!validateURL) {
    return (
      <Error403
        onClick={() => navigateBackTo()}
        showBackButton={false}
      />);
  };

  return <CourseView
    selectedCourse={selectedCourseData}
    areCoursesLoading={hasAllLoadingFinished}
    selectedTerm={selectedTermId}
    outcomeEnabled={markingScheme.activatingItems.outcomes}
    onChangeStudentState={onChangeStudentState}
    switchFormValues={switchFormValues}
    setSwitchFormValues={setSwitchFormValues}
    clusteringEnabled={isClusteringEnabled}
  />
};

export default CourseContainer;
