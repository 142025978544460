import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyDashboards from './MyDashboardView';
import { setMenuSelected } from '../../../../actions/vetTrack/dashboardManagement/layout.action';
import {
  fetchDashboards,
  saveDashboard,
} from '../../../../actions/vetTrack/dashboardManagement/myDashboards.action';
import {
  makeDashboardListData,
  makeDashboardListLoading,
  makeDashboardSavedDataLoading,
} from '../../../../selectors/vetTrack/dashboardManagement.selector';
import menuData from '../../../layout/vetTrack/dashboardManagement/vetTrackMenuData';

const JRMyDashboardContainer = () => {
  const dispatch = useDispatch();

  // selectors
  const dashboardsLoading = useSelector(makeDashboardListLoading);
  const dashboards = useSelector(makeDashboardListData);
  const dashboardSaveLoading = useSelector(makeDashboardSavedDataLoading);

  // set menu
  useEffect(() => {
    dispatch(setMenuSelected(menuData.manageDashboard.key));
    dispatch(fetchDashboards());
  }, []);

  const onActiveChange = (dashboard, active) => {
    dispatch(saveDashboard({ dashboard, active }));
  };

  return (
    <MyDashboards
      isLoading={dashboardsLoading || dashboardSaveLoading}
      dashboards={dashboards}
      onActiveChange={onActiveChange}
    />
  );
};

export default JRMyDashboardContainer;
