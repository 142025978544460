import React from 'react';
import PropTypes from 'prop-types';

import noDataImage from '../../../../images/other-images/empty-data-col.svg';

const NoDataCell = props => {
  const { message } = props;
  return (
    <div className="jr-cell-no-data">
      <img src={noDataImage} alt="No Data" width="12px" height="16px"/>
      <p>{message}</p>
    </div>
  );
};

NoDataCell.defaultProps = {
  message: 'Not Enough Data',
};

NoDataCell.propTypes = {
  message: PropTypes.string,
};

export default NoDataCell;
