import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import PropTypes from 'prop-types';

HCSoldGauge(Highcharts);

const SolidGaugeChart = ({ chartOptions }) => {
  const defaultConfig = {
    chart: {
      type: 'solidgauge',
    },
    credits: {
      enabled: false,
    },
    title: null,

    pane: {
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },
  };

  const config = Highcharts.merge(defaultConfig, chartOptions);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
};

SolidGaugeChart.propTypes = {
  chartOptions: PropTypes.object.isRequired,
};

export default SolidGaugeChart;
