import { combineReducers } from 'redux';
import layout from './layout.reducer';
import settings from './settings/index';
import riskAnalysis from './riskAnalysis/index';

const analysisReducers = combineReducers({
  layout,
  settings,
  riskAnalysis,
});

export default analysisReducers;
