import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDashboardFiltersSelectedData,
  getSelectedCardsByPath,
  getComplianceFactorsData,
  getComplianceStudentsData,
  getComplianceStudentsLoading,
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchComplianceStudents } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/compliance.action';
import { setSelectedCards } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/common.action';

import Students from './Students';
import StudentDetailCell from '../../../../../common/StudentDetailCell';
import ProgressBarCell from '../../../../../common/ProgressBarCell';
import Loader from '../../../../../../../../common/layout/Loader';

import {
  complianceSections,
  subSections,
} from '../../../../../../../../../../../constant/vetTrack/analysis';
import {
  getUniqValuesForFilters,
  onFilterColumn,
  sortDataColumn,
} from '../../../../../../../../utils/general';
import { prepareRiskMeasurementsColumns } from '../../../../../../../../utils/studentTable';
import { filterStudentByCard } from '../../../../../common/topCards/subSectionTopCards';
import { isEmptyValue } from '../../../../../../../../utils/general';
import NoDataCell from '../../../../../../../../common/partials/NoDataCell';

const StudentsTab = () => {
  const dispatch = useDispatch();
  const [filteredStudents, setFilteredStudents] = useState([]);

  // selectors
  const students = useSelector(getComplianceStudentsData);
  const enabledFactors = useSelector(getComplianceFactorsData);
  const studentsLoading = useSelector(getComplianceStudentsLoading);
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const selectedCard = useSelector(
    getSelectedCardsByPath(subSections.compliance.key),
  );

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !studentsLoading) {
      const { compliance } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: compliance.id,
      };
      dispatch(setSelectedCards({ [subSections.compliance.key]: null }));
      dispatch(fetchComplianceStudents(data));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (students) {
      setFilteredStudents(students);
    }
  }, [students]);

  useEffect(() => {
    filterStudentByCard(
      selectedCard,
      setFilteredStudents,
      students,
      subSections.compliance.key,
    );
  }, [selectedCard]);

  const renderProgressCells = data => {
    if (!_isEmpty(data) && !isEmptyValue(data.value)) {
      return (
        <div className={`overview-progress-cell-${data.text}`}>
          <ProgressBarCell
            value={data.value}
            displayValue={`${data.value}`}
            color={data.color}
          />
        </div>
      );
    }

    return <NoDataCell />;
  };

  const columns = [
    {
      title: '',
      className: 'table-remove-left',
      children: [
        {
          title: 'Student Details',
          dataIndex: 'studentDetails',
          sorter: (value1, value2) =>
            value1.studentDetails.name &&
            value1.studentDetails.name.localeCompare(value2.studentDetails.name),
          render: value => (
            <StudentDetailCell
              title={value.name}
              subtitle={value.id}
              avatar={value.image}
            />
          ),
          onFilter: (value, record) => record.studentDetails.name === value,
          filters: getUniqValuesForFilters(
            students,
            'studentDetails.name',
            'studentDetails.name',
          ),
          fixed: 'left',
          width: '40%'
        },
        {
          title: 'Compliance Risk Score',
          dataIndex: 'compliance',
          sorter: (row1, row2) => sortDataColumn(row1, row2, 'compliance'),
          onFilter: (value, record) => onFilterColumn(value, record, 'compliance', 'value'),
          render: value => renderProgressCells(value),
          filters: getUniqValuesForFilters(
            students,
            'compliance.value',
            'compliance.value',
          ),
          width: '20%'
        },
      ],
    },
    {
      title: 'Risk Measurements',
      children: prepareRiskMeasurementsColumns(
        enabledFactors,
        students,
        complianceSections,
      ),
    },
  ];

  return (
    <Loader isLoading={studentsLoading}>
      <Students students={filteredStudents} columns={columns} />
    </Loader>
  );
};

export default StudentsTab;
