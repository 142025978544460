import React, { memo, useEffect, useRef } from "react";
import { Avatar, Card, Col, Row } from "antd";
import agentIcon from "../../../../assets/images/chatGPT/agent-iocn.png";
import { get } from "lodash";
import {
  WarningOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { sendChatMessage } from "../../../../actions/innovative/course.action";
import {
  getClusterChartRelatedAssignments,
  getClusterSectionFilters,
  getRetryChatAfter,
  getSelectedClusterSectionsIds,
} from "../../../../selectors/innovative/course.selector";

const STUDENT_ID_REGEX = /\bstudent_id\(([^)]+)\)/gi;
const COURSE_ID_REGEX = /\bcourse_id\(([^)]+)\)/gi;
const SECTION_ID_REGEX = /\bsection_id\(([^)]+)\)/gi;
const ASSIGNMENT_ID_REGEX = /\bassignment_id\(([^)]+)\)/gi;
const CHECK_ADDITIONAL_SPACE_IN_WORD = /\s+/g;
const DATA_INSIDE_PARENTHESES = /\(([^)]+)\)/;
const BOLD_REGEX = /\*\*(.*?)\*\*/g;
const REGEX_IDENTIFIERS = [STUDENT_ID_REGEX, SECTION_ID_REGEX, ASSIGNMENT_ID_REGEX, COURSE_ID_REGEX];
const REMOVE_BOLD_STUDENT_ID_REGEX = {
  regex: /\*\*student_id\((\d+)\)\*\*/g,
  replaceTo: "student_id($1)",
};
const REMOVE_BOLD_COURSE_ID_REGEX = {
  regex: /\*\*course_id\((\d+)\)\*\*/g,
  replaceTo: "course_id($1)",
};
const REMOVE_BOLD_SECTION_ID_REGEX = {
  regex: /\*\*section_id\((\d+)\)\*\*/g,
  replaceTo: "section_id($1)",
};
const BOLD_MENTIONED_IDENTIFIERS = [
  REMOVE_BOLD_STUDENT_ID_REGEX,
  REMOVE_BOLD_COURSE_ID_REGEX,
  REMOVE_BOLD_SECTION_ID_REGEX,
];
const FALSE = 0;

const ChatCard = ({
  chatData,
  align,
  scrollToEndOfChatRef,
  studentList,
  selectedCourse,
  setChatDisabled,
  isLastElement = false,
}) => {
  const cardRef = useRef();
  const dispatch = useDispatch();

  const selectedSectionFilters = useSelector(getSelectedClusterSectionsIds);
  const sectionFiltersArray = useSelector(getClusterSectionFilters);
  const assignments = useSelector(getClusterChartRelatedAssignments);
  const retryAt = useSelector(getRetryChatAfter);
  const messageRetryLoading = get(chatData, "loading", false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      scrollToEndOfChatRef && scrollToEndOfChatRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    });
    resizeObserver.observe(cardRef.current);
    return () => resizeObserver.disconnect();
  }, [])

  const retryMsg = () => {
    if (retryAt === FALSE) {
      setChatDisabled(true);
      dispatch(sendChatMessage({
        isMassageLoading: true,
        question: chatData.content,
        message_id: chatData.message_id,
        filters: {
          sections: selectedSectionFilters,
        },
      }));
    }
  };

  const isError = get(chatData, "error", false);

  const fetchMentionedValues = mentionedValue => {
    const elementId = mentionedValue.match(DATA_INSIDE_PARENTHESES);
    if (mentionedValue.toLowerCase().includes('course_id')) {
      if (selectedCourse.key === mentionedValue) {
        return {
          key: mentionedValue,
          value: `@${selectedCourse.display}`,
          className: "chatCourseMention",
          regex: COURSE_ID_REGEX,
        };
      };
    }
    if (elementId.length) {
      if (mentionedValue.includes('student_id')) {
        const mentionedStudent = studentList.find(student => elementId[1] === student.key.match(DATA_INSIDE_PARENTHESES)[1]);
        if (
          mentionedStudent !== undefined &&
          mentionedStudent !== null &&
          mentionedStudent?.display?.length
        ) {
          return {
            key: mentionedStudent.key,
            value: `@${mentionedStudent.display}`,
            className: "chatStudentMention",
            regex: STUDENT_ID_REGEX,
          };
        };
      }
      if (mentionedValue.includes('assignment_id')) {
        const mentionedAssignment = assignments.find(assignment => elementId[1] === assignment.assignment_id);
        if (
          mentionedAssignment !== undefined &&
          mentionedAssignment !== null &&
          mentionedAssignment?.text?.length
        ) {
          return {
            key: mentionedValue,
            value: `@${mentionedAssignment.text}`,
            className: "chatAssignmentMention",
            regex: ASSIGNMENT_ID_REGEX,
          };
        };
      }
      if (mentionedValue.includes('section_id')) {
        const mentionedSection = sectionFiltersArray.find(section => elementId[1] === section.id);
        if (
          mentionedSection !== undefined &&
          mentionedSection !== null &&
          mentionedSection?.text?.length
        ) {
          return {
            key: mentionedValue,
            value: `@${mentionedSection.text}`,
            className: "chatSectionMention",
            regex: SECTION_ID_REGEX,
          };
        };
      }
    }
    return {};
  }

  const checkMentions = (line, newIdArray) => {
    return line.split(" ").flatMap(
      (word, i) => {
        const mentionedWord = newIdArray.find(mentionedKeys => word.toLowerCase().includes(mentionedKeys.key));
        if (
          mentionedWord !== undefined &&
          mentionedWord !== null &&
          Object.keys(mentionedWord).length
        ) {
          const cleanWord = word.replace(CHECK_ADDITIONAL_SPACE_IN_WORD, '');
          const splitFromKey = cleanWord.split(mentionedWord.regex);
          if (
            splitFromKey.length &&
            splitFromKey[2] &&
            splitFromKey[2] !== ""
          ) {
            return [<span className={mentionedWord.className} key={`${mentionedWord.key}-${i}`}>{mentionedWord.value}</span>, splitFromKey[2]];
          }
          return [<span className={mentionedWord.className} key={`${mentionedWord.key}-${i}`}>{mentionedWord.value}</span>]
        }
        return word + " ";
      }
    )
  }

  const formatText = textQuery => {
    let text = textQuery;

    BOLD_MENTIONED_IDENTIFIERS.forEach(identifier => {
      text = text.replace(identifier.regex, identifier.replaceTo);
    });

    let idArray = [];
    REGEX_IDENTIFIERS.forEach(identifier => {
      const regex = new RegExp(identifier);

      let matchedNewIdArray = [...text.matchAll(regex)].map(matchedId => matchedId[0]);
      if (!matchedNewIdArray.includes(null)) {
        const uniqueArray = [...idArray, ...matchedNewIdArray]
          .reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue)) {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, []);
        idArray = uniqueArray
      }
    })

    const newIdArray = idArray.map(id => {
      return { ...fetchMentionedValues(id.toLowerCase()) };
    })

    const addBreakLinesTextArray = text.split("\n")
      .flatMap((line, i) => {
        const parts = line.split(BOLD_REGEX);

        // Map each part to apply bolding if needed
        const formattedParts = parts.map((part, index) => {
          const testMultipleRegex = () => {
            return REGEX_IDENTIFIERS.some(regex => regex.test(part));
          };

          if (!testMultipleRegex() && index === 1) {
            return <strong key={index}>{part}</strong>;
          }
          return checkMentions(part, newIdArray);
        });

        return [
          formattedParts,
          <br key={`line-${i}`} />
        ];
      });
    return addBreakLinesTextArray
  };

  const renderText = () => {
    if (align === "left") {
      return (<Row justify="start" >
        <div className="chatThreadText">
          {formatText(chatData.content)}
        </div>

        {/* {chatData.content?.length && chatData.content.map((sentence, index) => (
          sentence.length === 0 ?  '\n' :
          <p key={`${index}-sentence`}>{sentence} {'\n'}</p>
        ))} */}
      </Row>)
    }
    return (
      <Row justify="end">
        <div className="chatThreadText">
          {formatText(chatData.content)}
        </div>
      </Row>
    )
  }

  const chatMsgContainer = (isError) => {
    if (isError) {
      return (
        <Row className="chatError">
          <Col>
            <Row justify={"end"}>
              <Card className="chatErrorThread">
                <div ref={cardRef}>
                  {renderText()}
                </div>
              </Card>

            </Row>
            <Row justify={"end"}>
              <div className="retryText">
                <WarningOutlined />
                Error Occurred
              </div>
              {isLastElement && (
                <div className="centerWrapper">
                  <RedoOutlined
                    spin={messageRetryLoading}
                    onClick={retryMsg}
                  />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      )
    }

    return (
      <Row>
        <Col>
          <Row justify={"end"}>
            <Card className="chatThread">
              <div ref={cardRef}>
                {renderText()}
              </div>
            </Card>
          </Row>
        </Col>
      </Row>
    )
  };

  const renderContent = () => {
    if (align !== "left") {
      return chatMsgContainer(isError);
    }

    return (
      <Card className="chatThread">
        <Col justify={align === "left" ? "start" : "end"}>
          {
            align && (
              <div className="botAvatar">
                <Avatar size={"small"} src={agentIcon} />
                <div>
                  <b>Octopus AI</b>
                </div>
                <div className="betaChip">
                  Beta
                </div>
              </div>
            )
          }
          <div ref={cardRef}>
            {renderText()}
          </div>
        </Col>
      </Card>
    )

  }
  return (
    <div className={align === "left" ? "assistant-obi" : "user-obi"}>
      {renderContent()}
    </div>

  )
}

export default memo(ChatCard);