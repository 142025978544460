import React, {memo, useCallback, Fragment} from "react";
import PropTypes from "prop-types";
import {Typography, Radio, List, Button, Space, Tag, Popconfirm} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

import {calculationModes} from "../../../../../constant/jobReadyGeneric/constants";

const ScoreSection = ({conditions, mode, openDrawer, section, onScoreDelete, subSection, onEditClick, hasPermission}) => {
    const renderConditionName = useCallback((logics) => (
        logics.reduce((accum, logic) =>
            <span className="condition-name" style={{fontSize:12}}>{accum}{accum !=="" && <span className="combiner">&nbsp;AND&nbsp; </span>}
                {logic.value}&nbsp; {logic.condition}&nbsp; <Tag className="tag">{logic.name}</Tag></span>, "")
    ), [conditions])

    const renderItemName = (item) => {
        switch (mode) {
            case calculationModes.condition:
                return (
                    item.logics && renderConditionName(item.logics)
                )
            case calculationModes.category:
                return (
                    <div>{item.name}</div>
                )
        }
    }

    const renderListItems = (item) => {
        return (
            <List.Item
                extra={
                    <Space>
                        <Typography.Text strong>Risk: {item.risk} %</Typography.Text>
                        {hasPermission &&
                        <Fragment>
                            <Button size="small" type="primary" onClick={() => onEditClick(item.id, section, mode)} ghost
                                    icon={<EditOutlined/>}/>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => onScoreDelete(section.id, conditions, item.id, "scores", subSection)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" danger icon={<DeleteOutlined/>}/>
                            </Popconfirm>
                        </Fragment>
                        }
                    </Space>
                }
            >
                {renderItemName(item)}
            </List.Item>
        )
    }

    return (
        <div>
            <Typography.Paragraph strong>Score</Typography.Paragraph>
            <div>
                <Radio.Group value={mode} disabled={true}>
                    <Radio value="condition">
                        By Condition
                    </Radio>
                    <Radio value="category">
                        By Category
                    </Radio>
                </Radio.Group>
            </div>
            <List
                size="small"
                style={{marginTop:20}}
                bordered
                dataSource={conditions}
                renderItem={item => renderListItems(item)}
                className="logic-list"
            />
            <div style={{marginTop:5, textAlign:"end"}}>
                <Button disabled={!hasPermission} type="primary" onClick={() => openDrawer(section, mode)}>Add</Button>
            </div>
        </div>
    )
}

ScoreSection.propTypes = {
    conditions: PropTypes.array,
    mode: PropTypes.string.isRequired,
    openDrawer: PropTypes.func,
    section: PropTypes.object,
    onScoreDelete: PropTypes.func,
    subSection: PropTypes.string,
    onEditClick: PropTypes.func,
    hasPermission: PropTypes.bool,
}

export default memo(ScoreSection);