import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Typography} from "antd";
import _startCase from "lodash/startCase";
import {useSelector, useDispatch} from "react-redux";

import {getRiSettingsByPath} from "../../../../../../selectors/jobReadyGeneric/settings/riskIndicators.selector";
import {getPermissionDataByPath} from "../../../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";
import {setSettings} from "../../../../../../actions/jobReadyGeneric/settings/commonSettings.action";

import OverallIndicatorsView from "./OverallIndicatorsView";

import {settingsSections} from "../../../../../../constant/jobReadyGeneric/constants";


const OverallIndicatorsContainer = ({subSection}) => {
    const dispatch = useDispatch();

    // selectors
    const overallData = useSelector(getRiSettingsByPath(`${subSection}.data.overall`))
    const hasPermission = useSelector(getPermissionDataByPath(`${subSection}.saveColors`));

    const onValueChange = (type, key, value) => {
        dispatch(setSettings(value, `data.overall.${type}.${key}`, settingsSections.riskIndicators, subSection))
    }

    return (
        <div className="risk-indicators">
            {overallData &&
            <Fragment >
                <Typography.Title level={5}>Overall {_startCase(subSection)} Risk Indicators</Typography.Title>
                <Typography.Text
                    type="secondary"
                    level={5}
                >
                    Default: Based on Overall {_startCase(subSection)} Risk Score
                </Typography.Text>

                <OverallIndicatorsView
                    onValueChange={onValueChange}
                    subSection={subSection}
                    data={overallData}
                    hasPermission={hasPermission}
                />
            </Fragment>
            }
        </div>
    )
}

OverallIndicatorsContainer.propTypes = {
    subSection: PropTypes.string
}

export default OverallIndicatorsContainer;