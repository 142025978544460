import {
    STUDENT_CLEAR_STUDENT_TERMS_DATA,
    STUDENT_FETCH_ACTING_ROLE,
    STUDENT_FETCH_ACTING_ROLE_LOADING,
    STUDENT_FETCH_COURSE,
    STUDENT_FETCH_COURSE_LOADING,
    STUDENT_FETCH_COURSE_SETTINGS,
    STUDENT_FETCH_COURSE_SETTINGS_LOADING,
    STUDENT_FETCH_STUDENT_LIST,
    STUDENT_FETCH_STUDENT_LIST_LOADING,
    STUDENT_FETCH_STUDENT_TERMS,
    STUDENT_FETCH_STUDENT_TERMS_LOADING,
    STUDENT_FETCH_MASTER_SETTINGS,
    STUDENT_FETCH_MASTER_SETTINGS_LOADING
} from "../../constant/actionTypes";

const fallback = {
    markingScheme: {
        activatingItems: {
            assignments: true,
            quizzes: true,
            outcomes: false,
            outcomeWeight: 0,
        },
        calculatingEngagementScore: {
            relativePageViewScore: {
                status: true,
                value: 30,
            },
            relativeParticipationScore: {
                status: true,
                value: 30,
            },
            onTimeSubmission: {
                status: true,
                value: 25,
            },
            lateSubmission: {
                status: true,
                value: 15,
            },
        },
        calculatingRiskScore: {
            performanceScore: {
                status: true,
                value: 50,
            },
            engagementScore: {
                status: true,
                value: 50,
            },
        },
    },
    colorScheme: {
        marksToGradeMappingForAssignments: [
            { from: 75, to: 100, grade: "A", color: "#50af60" },
            { from: 65, to: 75, grade: "B", color: "#d98c27" },
            { from: 50, to: 65, grade: "C", color: "#2674cf" },
            { from: 35, to: 50, grade: "D", color: "#39c3e5" },
            { from: 25, to: 35, grade: "E", color: "#e77d50" },
            { from: 0, to: 25, grade: "F", color: "#65666b" },
        ],
        marksToGradeMappingForPerformanceScore: [
            { from: 75, to: 100, grade: "A", color: "#50af60" },
            { from: 65, to: 75, grade: "B", color: "#d98c27" },
            { from: 50, to: 65, grade: "C", color: "#2674cf" },
            { from: 35, to: 50, grade: "D", color: "#39c3e5" },
            { from: 25, to: 35, grade: "E", color: "#e77d50" },
            { from: 0, to: 25, grade: "F", color: "#65666b" },
        ],
        taskCompleteStatus: [
            {
                status: "Completed",
                color: "#50af60",
            },
            {
                status: "Incompleted",
                color: "#65666b",
            },
        ],
        submission: {
            onTime: {
                color: "#5cbd26",
                submission_status: "On Time",
            },
            late: {
                color: "#faad14",
                submission_status: "Late",
            },
            missing: {
                color: "#eb5757",
                submission_status: "Missing",
            },
        },
        masteryStatus: {
            mastered: {
                status: "Mastered",
                color: "#50af60",
            },
            notMastered: {
                status: "Not Mastered",
                color: "#65666b",
            },
        },

        availability: true,
    },
    conditionalColors: {
        performanceScore: [
            { from: 0, to: 35, color: "#f5222d" },
            { from: 35, to: 75, color: "#faad14" },
            { from: 75, to: 100, color: "#52c41a" },
        ],
        engagementScore: [
            { from: 0, to: 35, color: "#f5222d" },
            { from: 35, to: 75, color: "#faad14" },
            { from: 75, to: 100, color: "#52c41a" },
        ],
        missingSubmission: [
            { from: 0, to: 3, color: "#262626" },
            { from: 3, to: "max", color: "#f5222d" },
        ],
        lateSubmission: [
            { from: 0, to: 4, color: "#262626" },
            { from: 4, to: "max", color: "#f5222d" },
        ],
        definingRiskCondition: [
            { from: 0, to: 35, color: "#52c41a", risk_category: "Low" },
            { from: 35, to: 75, color: "#faad14", risk_category: "Medium" },
            { from: 75, to: 100, color: "#f5222d", risk_category: "High" },
        ],
    },
};

const commonDataReducerDefaultState = {
    masterSettings: {
        isLoading: false,
        error: false,
        response: {
            data: {},
        }
    },
    courseSettings: {
        isLoading: false,
        error: false,
        response: {
            data: fallback,
        }
    },
    course: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {},
            },
        }
    },
    terms: {
        isLoading: false,
        error: false,
        response: {
            data: [],
        }
    },
    students: {
        isLoading: false,
        error: false,
        response:{
            data: []
        }
    },
    actingRole: {
        isLoading: false,
        error: false,
        response:{
            data: null
        }
    },
};

const commonDataReducer = (state = commonDataReducerDefaultState, action) => {
    switch (action.type) {
        case STUDENT_FETCH_ACTING_ROLE_LOADING:
            return {
                ...state,
                actingRole: {
                    ...state.actingRole,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_ACTING_ROLE:
            return {
                ...state,
                actingRole: action.payload,
            };
        case STUDENT_FETCH_COURSE:
            return {
                ...state,
                course: action.payload
            };
        case STUDENT_FETCH_COURSE_LOADING:
            return {
                ...state,
                course: {
                    ...state.course,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_COURSE_SETTINGS:
            return {
                ...state,
                courseSettings: action.payload
            };
        case STUDENT_FETCH_COURSE_SETTINGS_LOADING:
            return {
                ...state,
                courseSettings: {
                    ...state.courseSettings,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_MASTER_SETTINGS:
            return {
                ...state,
                masterSettings: action.payload
            };
        case STUDENT_FETCH_MASTER_SETTINGS_LOADING:
            return {
                ...state,
                masterSettings: {
                    ...state.masterSettings,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_STUDENT_TERMS:
            return {
                ...state,
                terms: action.payload
            };
        case STUDENT_FETCH_STUDENT_TERMS_LOADING:
            return {
                ...state,
                terms: {
                    ...state.terms,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_CLEAR_STUDENT_TERMS_DATA:
            return {
                ...state,
                terms: commonDataReducerDefaultState.terms
            };
        case STUDENT_FETCH_STUDENT_LIST_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_STUDENT_LIST:
            return {
                ...state,
                students: action.payload,
            };
        default:
            return state;
    }
};

export default commonDataReducer;