import React, { useEffect, useRef, useState, useLayoutEffect  } from 'react';

const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0, false]);
  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isMobile = window.innerWidth < 481

      setSize([width, height, isMobile]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export default useDidMountEffect;