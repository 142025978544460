import React, {forwardRef, useImperativeHandle} from "react";
import PropTypes from 'prop-types';
import {getDate, getNumber} from "../../../../../../utils/formatData"
import StepLicenseConfig from "../../views/StepLicenseConfig";
import moment from "moment";
import {useSelector} from "react-redux";
import {getSubUserPartnerId} from "../../../../../../selectors/onboarding/auth.selector";
import {addDays} from "../../../../../../utils/formatData";

const LicenseConfigContainer = forwardRef(({}, ref) => {
    const userPartner = useSelector(getSubUserPartnerId);
    const fieldMapping = {
        schoolName: {field: "name"},
        schoolEmail: {field: "email"},
        studentCount: {field: "student_count", getter: getNumber},
        licenseStartDate: {field: "enable_from", getter: getDate},
        licenseEndDate: {field: "enable_until", getter: getDate},
        licenseType: {field: "license_status"}
    };
    const dateFormat = 'YYYY/MM/DD';

    useImperativeHandle(ref, () => ({
        fieldMapping
    }));

    const getInitialDates = () => {
        let currentDate = new Date();
        let endDate = addDays(new Date(), 14)
        return {
            start: moment(currentDate, dateFormat),
            end: moment(endDate)
        };
    }

    return <StepLicenseConfig dateFormat={dateFormat} getInitialDates={getInitialDates} partnerId={userPartner}/>
});

LicenseConfigContainer.displayName = "LicenseConfigContainer";

export default LicenseConfigContainer;