import {
  VTT_SET_SELECTED_DASHBOARD,
  VTT_FETCH_DASHBOARD_SELECTIONS,
  VTT_FETCH_DASHBOARD_SELECTIONS_LOADING,
  VTT_FETCH_DASHBOARD_USERS,
  VTT_FETCH_DASHBOARD_USERS_LOADING,
  VTT_ADD_NEW_DASHBOARD_USER,
  VTT_ADD_NEW_DASHBOARD_USER_LOADING,
  VTT_DELETE_DASHBOARD_USER,
  VTT_DELETE_DASHBOARD_USER_LOADING,
  VTT_USER_RESET_PASSWORD,
  VTT_USER_RESET_PASSWORD_LOADING,
} from '../../../../constant/actionTypes';
import { domainCode } from '../../../../constant/vetTrack/analysis';

import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrDashboardManagement';

/** Dashboard selections data fetch * */
// fetch dashboard selections loading
const fetchingDashboardSelectionsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_SELECTIONS_LOADING,
    payload: { isLoading },
  });
};

// fetch dashboard selections handler
const fetchDashboardSelectionsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_SELECTIONS,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch dashboard selections
export const fetchDashboardSelections = () => dispatch => {
  dispatch(fetchingDashboardSelectionsLoading());

  service
    .make({
      service: `dashboard/selections`,
      method: 'POST',
    })
    .then(response => {
      dispatch(
        fetchDashboardSelectionsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchDashboardSelectionsDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Set selected dashboard * */
// set selected dashboard
export const setSelectedDashboard = data => dispatch => {
  dispatch({
    type: VTT_SET_SELECTED_DASHBOARD,
    payload: data,
  });

  // fetch users
  dispatch(fetchDashboardUsers());
};

/** Fetch dashboard related users * */
// fetch dashboard users loading
const fetchingDashboardUsersLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_USERS_LOADING,
    payload: { isLoading },
  });
};

// fetch dashboard users handler
const fetchDashboardUsersDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_USERS,
    payload: {
      isLoading: false,
      error: response.error,
      data: response.data || [],
    },
  });
};

// fetch dashboard users
export const fetchDashboardUsers = () => dispatch => {
  dispatch(fetchingDashboardUsersLoading());

  service
    .make({
      service:`insights/vettrak/vga/user/fetchAllUsers`,
      method: 'POST',
    })
    .then(response => {
      dispatch(
        fetchDashboardUsersDataStatus({
          data: response.data.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchDashboardUsersDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Add new user * */
// add user loading
const addUserLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_ADD_NEW_DASHBOARD_USER_LOADING,
    payload: { isLoading },
  });
};

// add user handler
const addUserDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ADD_NEW_DASHBOARD_USER,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// add user
export const addUser = (data, callback) => dispatch => {
  dispatch(addUserLoading());

  service
    .make({
      service: `insights/vettrak/vga/user/addUser`,
      method: 'POST',
      data: {...data, domainCode: domainCode},
    })
    .then(response => {
      dispatch(
        addUserDataStatus({
          data: response.data,
        }),
      );
      callback();
    })
    .catch(error => {
      dispatch(
        addUserDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Delete user * */
// delete user loading
const deleteUserLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_DELETE_DASHBOARD_USER_LOADING,
    payload: { isLoading },
  });
};

// delete user handler
const deleteUserDataStatus = response => dispatch => {
  dispatch({
    type: VTT_DELETE_DASHBOARD_USER,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// delete user
export const deleteUser = (data, callback) => dispatch => {
  dispatch(deleteUserLoading());

  service
    .make({
      service: `insights/vettrak/vga/user/deleteUser`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        deleteUserDataStatus({
          data: response.data,
        }),
      );
      callback();
    })
    .catch(error => {
      dispatch(
        deleteUserDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Reset user password * */
// reset user password loading
const resetUserPasswordLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_USER_RESET_PASSWORD_LOADING,
    payload: { isLoading },
  });
};

// reset user password handler
const resetUserPasswordDataStatus = response => dispatch => {
  dispatch({
    type: VTT_USER_RESET_PASSWORD,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// reset user password
export const resetUserPassword = (data, callback) => dispatch => {
  dispatch(resetUserPasswordLoading());

  service
    .make({
      service: `insights/vettrak/vga/user/setPassword`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        resetUserPasswordDataStatus({
          data: response.data,
        }),
      );
      callback();
    })
    .catch(error => {
      dispatch(
        resetUserPasswordDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Send Reset Password Link **/
export const sendResetPasswordLink = (data, callback) => dispatch => {

  service.make({
      service:`insights/vettrak/vga/user/generateResetToken`,
      method: "POST",
      data
  })
  .then(response => {
    callback();
  })
  .catch(error => {
    dispatch(
      resetUserPasswordDataStatus({
        error: error.message,
      }),
    );
  });
};
