import React, { memo } from 'react';
import { useSelector } from "react-redux";
import { Col, Form, Layout, Row } from "antd";
import TermFilter from "../../common/termFilter";
import SelectFilter from '../../common/selectFilter';
import {
  makeInnovativeCourseResponseData,
} from '../../../../selectors/innovative/course.selector';

const STUDENT_PROFILE_URL_INDEX_ARRAY = [3,4];
const STUDENT_PROFILE = "student-profile";

const HeaderFilters = ({
  studentsReportLoading,
  terms,
  termsLoading,
  termChange,
  selectedTerm,
  enrollmentOptions,
  selectedEnrollment,
  onEnrollmentChanged,
  student,
}) => {
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;

  const isStudentProfilePath = () => {
    const navigatedPage = path.split("/")[STUDENT_PROFILE_URL_INDEX_ARRAY[0]];
    const adminNavigatedPage = path.split("/")[STUDENT_PROFILE_URL_INDEX_ARRAY[1]];
    return (navigatedPage === STUDENT_PROFILE || adminNavigatedPage === STUDENT_PROFILE);
  };

  return (
    <div className="search-header-enabled">
      {isStudentProfilePath() &&
        <div className="student-profile-sec-header">
          <Layout.Header>
            <div className={'in-container'}>
              <Row justify={"space-between"}>
                <Row>
                  <Col flex={"auto"}
                    className='student-detail-col'
                  >
                    <h5>
                      {selectedCourse.course_name}
                    </h5>
                    <h6>
                      {student.student_name}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row gutter={15}>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Term"
                          className="header-form-item"
                        >
                          <TermFilter
                            terms={terms}
                            termsLoading={termsLoading}
                            reportLoading={studentsReportLoading}
                            termChange={termChange}
                            selectedTerm={selectedTerm}
                            wrapperId="innovative-term-parent-select"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Enrollment Status"
                          className="header-form-item"
                        >
                          <SelectFilter
                            items={enrollmentOptions}
                            itemsLoading={false}
                            reportLoading={studentsReportLoading}
                            itemChange={onEnrollmentChanged}
                            selectedItem={selectedEnrollment}
                            wrapperId="innovative-term-parent-select"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </div>
          </Layout.Header >
        </div>
      }
    </div>
  );
};

export default memo(HeaderFilters);
