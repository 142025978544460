import React from 'react';
import { Card, Row, Col, Space, Divider } from 'antd';
import PropTypes from "prop-types";
import { DownOutlined } from '@ant-design/icons';

const Dropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
  header: HeaderComponent,
  children,
}) => {
  return (
    <Card className='module-dropdown-card'>
      <div 
        className="moduleRow"
        wrap={false}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        <div
          className='module-dropdown-icon-col'
        >
          <div
            className="module-dropdown-icon"
          >
              <DownOutlined rotate={isDropdownOpen ? 180 : 0} />
          </div>
        </div>
        <div
          className='module-dropdown-header-col'
          flex="auto"
        >
          <HeaderComponent />
        </div>
      </div>
      {
        isDropdownOpen &&
        <div
          style={{
            "border-top": "1px solid #eee"
          }}
        >
          {children}
        </div>
      }
    </Card>
  )
};

Dropdown.propTypes = {
  isDropdownOpen: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func,
  header: PropTypes.element,
};

export default Dropdown;
