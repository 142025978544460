import _get from "lodash/get";

// return vw dealership pathway response
export const makeVwDealershipPathwayResponse = state => _get(state, 'vwDealership.pathway');

// return vw dealership pathway elements data response
export const makeVwDealershipPathwayElementsDataResponse = state => {
    const result = makeVwDealershipPathwayResponse(state);
    return result && result.elements;
};

// return vw dealership pathway elements lists response
export const makeVwDealershipPathwayElementsListsResponse = state => {
    const result = makeVwDealershipPathwayElementsDataResponse(state);
    return result && result.list;
};

// return vw dealership pathway elements lists data response
export const makeVwDealershipPathwayElementsListsDataResponse = state => {
    const result = makeVwDealershipPathwayElementsListsResponse(state);
    return result && _get(result, 'data.pathwayModules', []);
};

// return vw dealership pathway elements lists loading response
export const makeVwDealershipPathwayElementsListsLoadingResponse = state => {
    const result = makeVwDealershipPathwayElementsListsResponse(state);
    return result && result.isLoading;
};

// return vw dealership pathway selected response
export const makeVwDealershipPathwaySelectedResponse = state => {
    const result = makeVwDealershipPathwayElementsDataResponse(state);
    return result && result.selected;
};

// return vw dealership pathway selected data response
export const makeVwDealershipPathwaySelectedDataResponse = state => {
    const result = makeVwDealershipPathwaySelectedResponse(state);
    return result && _get(result, "data.students", []);
};

// return vw dealership pathway selected data loading response
export const makeVwDealershipPathwaySelectedDataLoadingResponse = state => {
    const result = makeVwDealershipPathwaySelectedResponse(state);
    return result && result.isLoading;
};

// return vw dealership pathway selected data moduleSelected response
export const makeVwDealershipPathwaySelectedDataSelectedElementResponse = state => {
    const result = makeVwDealershipPathwaySelectedResponse(state);
    return result && result.elementSelected;
};