import {
    FETCH_TENANT_ADMIN_LOADING,
    FETCH_TENANT_ADMIN,
} from "../../constant/actionTypes";

const saleAdminReducerDefaultState = {
    list: {
        isLoading: false,
        error: false,
        data: [],
    },
};

const saleAdminReducer = (state = saleAdminReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_TENANT_ADMIN_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: action.payload
                }
            }
        case FETCH_TENANT_ADMIN:
            return {
                ...state,
                list: {
                    ...state.list,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default saleAdminReducer;