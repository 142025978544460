import React, { useEffect, useCallback } from 'react';
import { Result, Button } from 'antd';
import Loader from '../../../../dashboardCommon/Loader';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { onError } from '../../../../../utils/notificationHandler';
import { queryStringToObject } from '../../../../../utils/general';
import localStore from '../../../../../utils/localStorage';

import { validateSamlAccessToken } from '../../../../../actions/authentication/samlAuthentication.action';
import { getSamlUserData } from '../../../../../selectors/authentication/samlAuthentication.selector';
import { getDomainMetaData } from '../../../../../selectors/tentacleMeta.selector';

const VerifySamlUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryStringToObject(useLocation().search);
  const domainData = useSelector(getDomainMetaData);
  const samlUserData = useSelector(getSamlUserData);

  const path = `${process.env.PUBLIC_URL}/tenant/login`;

  const redirectToLogin = useCallback(() => {
    history.push(path);
  }, [history, path]);

  let isAllowed = queryParams.isAllowed === "true";
  useEffect(() => {
    let validationToken = queryParams && queryParams.validationToken;
    if (validationToken && isAllowed) {
      dispatch(validateSamlAccessToken({ user: queryParams }));
    }
  }, [])
  useEffect(() => {
    try {
      if (samlUserData) {
        let loggedIn = samlUserData.isLoggedIn;
        let statCheck = true;
        if (!domainData) {
          statCheck = false;
          onError("No domain meta data found");
        }
        if (!domainData.main_url) {
          statCheck = false;
          onError("No main URL found for redirection");
        }
        if (statCheck) {
          if (loggedIn) {
            localStore.setLsSlim(localStore.keys.authMeta, samlUserData, 25200, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET);
            domainData && history.replace(domainData.main_url);
          }
        }
      }
    }
    catch (err) {
      redirectToLogin();
    }
  }, [domainData, history, redirectToLogin, samlUserData])
  return (
    !isAllowed ?
      <div className="saml-verify-user-result">
        <Result
          status={"error"}
          title={"User does not exist"}
          subTitle={"Contact system adminstrator"}
          extra={<Button onClick={redirectToLogin} type="primary">Back to login</Button>}
        />
      </div> :
      <Loader isLoading={true} isLoaderText={true} loaderText="Loading..." />
  )
}

export default VerifySamlUser