import React, { useEffect, useState } from "react";
import { Button, Card } from "antd";
import ChatBox from "./chatBox";
import {
  getChatMessages,
  getClusterVisibility,
  getFetchMessages,
  getIsChatMessagesLoading,
  getRetryChatAfter,
  getStudentClusterDataLoading,
} from "../../../../selectors/innovative/course.selector";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchChatHistory,
  resetChatCountDownTimer,
  resetChatHistory,
  updateClusterMessageVisibility,
} from "../../../../actions/innovative/course.action";
import clusterAiLoadingGif from "../../../../assets/images/chatGPT/clusterLogo.gif";
import longLoaderGif from "../../../../assets/images/chatGPT/longLoader.gif";
import AiRetryModal from "./partials/retryAi";
import { get } from "lodash";
import { isValidDate } from "../../../../utils/general";
import CountdownTimer from "../../../../utils/countDownTimer";

const RETRY_SECONDS_ARRAY = [10, 20, 30];
const MAX_RETRY_ATTEMPTS = 3;

const ChatBoxContainer = ({ isClusterCountDownOn }) => {
  const dispatch = useDispatch();

  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const isChatVisible = useSelector(getClusterVisibility);
  const fetchChat = useSelector(getFetchMessages);
  const isMessagesLoading = useSelector(getIsChatMessagesLoading);
  const chatDataArray = useSelector(getChatMessages);
  const retryAt = useSelector(getRetryChatAfter);

  const [isRetryVisible, setIsRetryVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });
  const [currentRetryAttempt, setCurrentRetryAttempt] = useState(1);
  const [reFetchHistoryMsg, setReFetchHistoryMsg] = useState(true);
  const [initialMsgExist, setInitialMsgExist] = useState(false);
  const [timer, setTimer] = useState(null);
  const [initialRetryTimer, setInitialRetryTimer] = useState(null);

  const onCountDownComplete = () => {
    dispatch(resetChatHistory());
    dispatch(resetChatCountDownTimer());
    dispatch(fetchChatHistory());
    setIsRetryVisible(false);
  };

  const fetchHistoryMsg = ({ fetchChat, isChatVisible }) => {
    if (
      fetchChat &&
      isChatVisible &&
      !isMessagesLoading
    ) {
      dispatch(fetchChatHistory());
    }
  };

  useEffect(() => {
    if (
      !initialMsgExist &&
      !chatDataArray.length
    ) {
      fetchHistoryMsg({ fetchChat, isChatVisible });
    }
  }, [initialMsgExist]);

  const onChatStart = () => {
    dispatch(updateClusterMessageVisibility(true));
    fetchHistoryMsg({ fetchChat, isChatVisible: true });
  };

  useEffect(() => {
    return () => {
      if (
        initialRetryTimer !== null &&
        initialRetryTimer.isTimerActive()
      ) {
        initialRetryTimer.stop();
      }
    }
  }, [initialRetryTimer]);

  useEffect(() => {
    return () => {
      if (
        timer !== null &&
        timer.isTimerActive()
      ) {
        timer.stop();
      }
    }
  }, [timer]);

  useEffect(() => {
    if (
      isValidDate(retryAt) &&
      fetchChat &&
      isChatVisible &&
      !chatDataArray.length
    ) {
      setIsRetryVisible(true);
      const retryRelatedTimer = new CountdownTimer({
        onCountDownComplete,
        setTimeLeft,
      });
      setTimer(retryRelatedTimer);

      retryRelatedTimer.start({
        endTime: new Date(retryAt),
      });
    }
  }, [retryAt]);

  useEffect(() => {
    if (chatDataArray.length) {
      const retry = get(chatDataArray[0], "error", false);
      const content = get(chatDataArray[0], "content", "");
      setReFetchHistoryMsg(retry);
      setIsRetryVisible(retry);
      if (
        !retry &&
        content.length > 0
      ) {
        setInitialMsgExist(true);
        dispatch(updateClusterMessageVisibility(true));
        return;
      }
    }
  }, [JSON.stringify(chatDataArray)]);

  useEffect(() => {
    if (
      currentRetryAttempt <= MAX_RETRY_ATTEMPTS &&
      !isMessagesLoading &&
      reFetchHistoryMsg &&
      isRetryVisible
    ) {
      setReFetchHistoryMsg(false);
      const currentDate = new Date();
      const retryIn = currentDate.setSeconds(currentDate.getSeconds() + RETRY_SECONDS_ARRAY[currentRetryAttempt - 1]);
      setCurrentRetryAttempt(currentRetryAttempt + 1);
      const retryRelatedTimer = new CountdownTimer({
        onCountDownComplete,
        setTimeLeft,
      });
      setInitialRetryTimer(retryRelatedTimer);
      retryRelatedTimer.start({
        endTime: new Date(retryIn),
      });
    };
  }, [reFetchHistoryMsg]);

  const renderButtonOptions = () => {
    if (isMessagesLoading) {
      return (
        <>
          <div>
            <img
              src={longLoaderGif}
              alt="loading..."
              className="longLoaderGif"
            />
          </div>
          <div>
            This might take a while
          </div>
        </>
      );
    }

    if (isRetryVisible) {
      return <AiRetryModal timeLeft={timeLeft} />
    }

    return (
      <Button
        onClick={() => onChatStart()}
        type={"primary"}
      >
        Start Chat
      </Button>
    )
  };

  const renderContent = () => {
    if (
      isChatVisible &&
      !isMessagesLoading &&
      !reFetchHistoryMsg &&
      !isRetryVisible &&
      initialMsgExist
    ) {
      return <ChatBox isClusterCountDownOn={isClusterCountDownOn} />;
    }
    return (
      <Card className="clusterStartContainer">
        <img src={clusterAiLoadingGif} alt="loading..." />
        <div className="clusterStartContainerTitle">
          Welcome Aboard!
        </div>
        <p>
          Hello there! Ready to explore your class data and uncover new insights for supporting student learning? Let's discover trends and patterns together! I'm here to guide you. Let's start this  journey together today!
        </p>
        {renderButtonOptions()}
      </Card>
    )
  }

  return (
    <>
      {!isClusterDataLoading && renderContent()}
    </>
  )
}

export default ChatBoxContainer;
