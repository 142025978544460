import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import _startCase from 'lodash/startCase';
import { useSelector, useDispatch } from 'react-redux';

import {
  getRiSettingsByPath,
  getPermissionDataByPath,
} from '../../../../../../../selectors/vetTrack/analysis.selector';
import {
  setSettings,
  setSetttingError,
} from '../../../../../../../actions/vetTrack/analysis/settings/commonSettings.action';

import OverallIndicatorsView from './OverallIndicatorsView';

import { settingsSections } from '../../../../../../../constant/vetTrack/analysis';

const OverallIndicatorsContainer = ({ subSection }) => {
  const dispatch = useDispatch();

  // selectors
  const overallData = useSelector(
    getRiSettingsByPath(`${subSection}.data.overall`),
  );
  const hasPermission = useSelector(
    getPermissionDataByPath(`${subSection}.saveColors`),
  );
  const sectionErrors = useSelector(
    getRiSettingsByPath(`${subSection}.errors`),
  );

  const onValueChange = (type, key, value) => {
    const currentErrors = sectionErrors || {};
    let updated = {
      ...currentErrors,
    };
    const error = `overall_${type}_${key}`;
    delete updated[error];

    if ( value === null || value === '' || value === undefined) {
      updated = {
        ...currentErrors,
        [error]: 'Empty value',
      };
    }

    dispatch(setSetttingError(subSection, updated));

    dispatch(
      setSettings(
        value,
        `data.overall.${type}.${key}`,
        settingsSections.riskIndicators,
        subSection,
      ),
    );
  };

  return (
    <div className="risk-indicators">
      {overallData && (
        <>
          <Typography.Title level={5}>
            Overall {_startCase(subSection)} Risk Indicators
          </Typography.Title>
          <Typography.Text type="secondary" level={5}>
            Default: Based on Overall {_startCase(subSection)} Risk Score
          </Typography.Text>

          <OverallIndicatorsView
            onValueChange={onValueChange}
            subSection={subSection}
            data={overallData}
            hasPermission={hasPermission}
            errors={sectionErrors}
          />
        </>
      )}
    </div>
  );
};

OverallIndicatorsContainer.propTypes = {
  subSection: PropTypes.string,
};

export default OverallIndicatorsContainer;
