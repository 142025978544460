import React from 'react';
import {Button, Card, List} from "antd";
import PropTypes from "prop-types";
import Loader from "../../../common/layout/Loader";
import {ArrowLeftOutlined} from "@ant-design/icons";

const DayView = ({dueAssignmentsLoading, assignmentsOfTheDay, onViewChange, title}) => {
    return (
        <Card
            className="text-center in-CustomCard assignmentCard"
            title={title}
            extra={
                <Button
                    icon={<ArrowLeftOutlined/>}
                    onClick={() => onViewChange('month')}
                >
                </Button>
            }>
            <Loader isLoading={dueAssignmentsLoading}/>
            <List
                dataSource={assignmentsOfTheDay}
                renderItem={item => (
                    <List.Item key={item.id}>
                        <div>{item.title}</div>
                    </List.Item>
                )}
            />
        </Card>
    );
};

DayView.defaultProps = {
    dueAssignmentsLoading: false,
    assignmentsOfTheDay: []
}

DayView.propTypes = {
    dueAssignmentsLoading: PropTypes.bool,
    assignmentsOfTheDay: PropTypes.array,
    onViewChange: PropTypes.func,
    title: PropTypes.string,
};

export default DayView;