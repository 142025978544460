import React from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';

const ProgressBarCell = ({ color, value, displayValue, showInfo = true, ...rest }) => {

  return (
    <div
      className='progress-cell-container'
    >
      <Progress
        format={() => displayValue}
        strokeColor={color}
        percent={value}
        status="normal"
        {...rest}
      />

      {!showInfo &&
        <div 
        className='progress-display-value'
        style={{
          color: color,
        }}>
          {value}%
        </div>
      }
    </div>
  )
}

ProgressBarCell.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayValue: PropTypes.string,
};

export default ProgressBarCell;
