const BASE_URL = `${process.env.REACT_APP_APIURL}ceoprofile`;

export default {
  // budgetsData: async function (filters) {
  //   try {
  //     return fetch(`${BASE_URL}/budget/data`, {
  //       credentials: 'include',
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ term: filters.term }),
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  incomeVsCostData: async function (filters) {
    try {
      return fetch(`${BASE_URL}/incomevscost/data`, {
        credentials: 'include',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ term: filters.term, schools: filters.schools }),
      });
    } catch (e) {
      console.log(e.message);
    }
  },
  managementData: async function (filters) {
    try {
      return fetch(`${BASE_URL}/management/data`, {
        credentials: 'include',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ term: filters.term, school: filters.school }),
      });
    } catch (e) {
      console.log(e.message);
    }
  },
  enrollmentData: async function (filters) {
    try {
      return fetch(`${BASE_URL}/enrollment/data`, {
        credentials: 'include',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ term: filters.term, schools: filters.schools }),
      });
    } catch (e) {
      console.log(e.message);
    }
  },
  modeData: async function (filters) {
    try {
      return fetch(`${BASE_URL}/mode/data`, {
        credentials: 'include',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ schoolId: filters.schoolId })
      });
    } catch (e) {
      console.log(e.message);
    }
  },
  newEnrollmentData: async function (filters) {
    try {
      return fetch(`${BASE_URL}/enrollments/data`, {
        credentials: 'include',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ schoolId: filters.schoolId, modeId: filters.modeId, term: filters.term })
      });
    } catch (e) {
      console.log(e.message);
    }
  },
  // leadCount: async function (filters, token) {
  //   try {
  //     return fetch(`${BASE_URL}/lead/data`, {
  //       credentials: 'include',
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ term: filters.term, school: filters.school }),
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  getSchools: async function (filters, token){
    try {
      return fetch(BASE_URL + `/school/data`, {
        credentials: "include",
        method: 'GET',
      })    
    } catch (e) {
      console.log(e.message);
    }
  }
};
