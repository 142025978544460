import React, {memo} from 'react';
import {Card, Table} from "antd";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";
import {withTablePaginator} from "../../common/table/hoc/withTablePaginator";

const CourseComparisonGridView = ({isLoading, radioOptions, dataSource, columns, pagination, setSelectedTablePagination}) => {
    setSelectedTablePagination(pagination?.current);

    return (
        <Card
            className="tableCoverCard studentDetailTable"
            // TODO :: Removed temporarily, might needed in the future
            // extra={(
            //     <div>
            //         <Radio.Group
            //             options={radioOptions}
            //             onChange={() => {
            //             }}
            //             value={'all'}
            //             optionType="button"
            //             buttonStyle="solid"
            //         />
            //     </div>
            // )}
        >
            <Loader isLoading={isLoading}/>
            {!isLoading &&
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={pagination}
                scroll={{x: "max-content"}}
            />
            }
        </Card>
    );
};

CourseComparisonGridView.propTypes = {
    isLoading: PropTypes.bool,
    radioOptions: PropTypes.array,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    pagination: PropTypes.object,
}

export default memo(withTablePaginator(CourseComparisonGridView));