import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import './../../../assets/css/tass-web/rsuite-default.css';
import "./../../../assets/css/tass-web/financial.css";
import ProfitAndLoss from "./ProfitAndLoss";
import HighChartComponent from "./HighChartComponent";
import AreaGraphComponent from "./AreaGraphComponent";
import ApexChartComponent from "./ApexChartComponent";
import ApiService from "./../../../services/tass-web/ApiService";
import DataStore from "./../common/DataStore";
import BalanceSheet from "./BalanceSheet";
import Loader from "./../common/Loader";
import LoginComponent from "./../common/LoginComponent";
import CardComponent from "./CardComponent";
import Breadcrumb from "../common/Breadcrumb";
import Helper from "./../common/Helper";
import { setSideBarActive } from '../../../actions/authentication.action';

let originalBarChartSeries = [];
class Dashboard extends Component {
  state = {
    filters: {},
    months: DataStore.getMonths(),
    authUser: localStorage.getItem('authUser') || false,
    valueFormat: 'M'
  };

  // _yearOnChnage = (value) => {
  //   let filters = this.state.filters;
  //   filters.year = value;
  //   this.setState({ ...filters });
  //   this.getApiServiceDataOnYearChange();
  // };
  // get yearOnChnage() {
  //   return this._yearOnChnage;
  // }
  // set yearOnChnage(value) {
  //   this._yearOnChnage = value;
  // }

  // _monthOnChange = (value) => {
  //   let filters = this.state.filters;
  //   filters.period = value;
  //   if (filters.period == 1) filters.previous_period = 12;
  //   else filters.previous_period = filters.period - 1;
  //   this.setState({ ...filters });
  //   this.getApiServiceDataOnYearAndPeriodChange();
  // };
  // get monthOnChange() {
  //   return this._monthOnChange;
  // }
  // set monthOnChange(value) {
  //   this._monthOnChange = value;
  // }

  componentDidMount() {
    this.props.dispatch(setSideBarActive({sideBarActive : 'financial_summary'}));
    const {headerFilters} = this.props;
    this.setState({filters: headerFilters}, () => {
        this.getApiServiceDataOnYearChange();
	      this.getApiServiceDataOnYearAndPeriodChange();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {headerFilters} = this.props;
    if (headerFilters !== prevProps.headerFilters) {
      // ApiService.cancelOperatingExpenses();
      this.setState({filters: headerFilters}, () => {
        // on year change
        if (headerFilters.year !== prevProps.headerFilters.year) {
          this.getApiServiceDataOnYearChange();
          this.getApiServiceDataOnYearAndPeriodChange();
        }
        // on month change
        if (headerFilters.period !== prevProps.headerFilters.period) {
          this.getApiServiceDataOnYearAndPeriodChange();
        }
      });
    }
  }

  getApiServiceDataOnYearChange = () => {
    this.setState({
      _barCharts: false,
      rawTwelveMonthData: null
    })
    this.getTwelveMonthData();
  };

  getApiServiceDataOnYearAndPeriodChange = () => {
    this.setState({
      _areaChartValues: null,
      _expenseChart: null,
      _incomeChart: null,
      _liabilitiesChart: null,
      _assetsChart: null,
      _balanceSheetTable: null,
      _profitAndLossTable: null,
      _grossProfitMargin: null,
      _netProfitMargin: null
    })
    this.getProfitAndLossData();
    this.getCurrentAndPreviousData();
    this.getBalanceSheetData();
    this.getOperatingExpenses();
  };

  getTwelveMonthData = () => {
    ApiService.getTwelveMonthData(this.state.filters).then((Response) => Response.json()).then((ResponseJson) => {
        this._generateTwelveMonthCharts(ResponseJson.response);
        this.setState({
          rawTwelveMonthData: ResponseJson.response
        })
      })
      .catch((err) => console.log(err));
  };

  getOperatingExpenses = () => {
    ApiService.getOperatingExpenses(this.state.filters).then((Response) => Response.json()).then((ResponseJson) => {
      this._generateOperatingExpensesInProfitTable(ResponseJson.response);
      this._generateGrossProfitMarginChart(ResponseJson.response);
    })
    .catch((err) => console.log(err));
  }

  getProfitAndLossData = () => {
    ApiService.getProfitAndLoss(this.state.filters).then((Response) => Response.json()).then((ResponseJson) => {
        this._generateProfiAndLossTable(ResponseJson.response);
        this._generateNetProfitMarginChart(ResponseJson.response);
      })
      .catch((err) => console.log(err));
  };

  getBalanceSheetData = () => {
    ApiService.getBalanceSheet(this.state.filters).then((Response) => Response.json()).then((ResponseJson) => {
        this._generateBalanceSheetTable(ResponseJson.response);
      })
      .catch((err) => console.log(err));
  };

  getCurrentAndPreviousData = () => {
    ApiService.getCurrentAndPrevious(this.state.filters).then((Response) => Response.json()).then((ResponseJson) => {
        this._generateCurrentAndPrevoiusCharts(ResponseJson);
      })
      .catch((err) => console.log(err));
  };

  _generateNetProfitMarginChart = (response) => {
    if(this.isObject(response))
        this.setState({
          _netProfitMargin: {
            percentage: response.net_profit_margin,
            value: response.profit_and_loss.net_profit.actual
          }
        });
      else
        this.setState({
          _netProfitMargin: {
            percentage: "No Data",
            value: "No Data"
          }
        });
  };

  _generateGrossProfitMarginChart = (response) => {
    if(this.isObject(response))
      this.setState({
        _grossProfitMargin: {
          percentage: response.gross_profit_margin,
          value: response.gross_profit.actual
        }
      });
    else
      this.setState({
        _grossProfitMargin: {
          percentage: "No Data",
          value: "No Data"
        }
      });
  };

  _generateProfiAndLossTable = (response) => {
    this.setState({
      _profitAndLossTable: this.generateTableRows({
        values: response.profit_and_loss,
        type: "profit_and_loss",
      }),
    });

  };

  _generateBalanceSheetTable = (response) => {
    this.setState({
      _balanceSheetTable: this.generateTableRows({
        values: response,
        type: "balance_sheet",
      }),
    });
  };

  _generateCurrentAndPrevoiusCharts = (response) => {
    this.setState({
      _areaChartValues: this.generateAreaChartValues(
        response,
        this.state.filters
      )
    },() => {
      // this.setState({
      //   _expenseChart: this.generateAreaChartOptions({
      //     values: response,
      //     type: "expenses",
      //     generated: {
      //       previous: this.state._areaChartValues[0].expenses,
      //       current: this.state._areaChartValues[1].expenses
      //     }
      //   }),
      //   _incomeChart: this.generateAreaChartOptions({
      //     values: response,
      //     type: "income",
      //     generated: {
      //       previous: this.state._areaChartValues[0].income,
      //       current: this.state._areaChartValues[1].income
      //     }
      //   }),
      //   _liabilitiesChart: this.generateAreaChartOptions({
      //     values: response,
      //     type: "liabilities",
      //     generated: {
      //       previous: this.state._areaChartValues[0].liabilities,
      //       current: this.state._areaChartValues[1].liabilities
      //     }
      //   }),
      //   _assetsChart: this.generateAreaChartOptions({
      //     values: response,
      //     type: "asset",
      //     generated: {
      //       previous: this.state._areaChartValues[0].assets,
      //       current: this.state._areaChartValues[1].assets
      //     }
      //   })
      // })
      var interval = setInterval(() => {
        console.log("waiting for twelve month data");
        if(this.state.rawTwelveMonthData){
          clearInterval(interval);
          this._prepareAreaChartWithtwelveMonthData();
        }
      },1000);
    });
  };

  _generateTwelveMonthCharts = async(response) => {
    this.setState({
      _expensesBarSeries: this.generateBarChartSeries({
        values: response,
        type: "E",
      }),
      _expensesBarChart: this.generateBarChartOptions({
        values: response,
        type: "E",
        valueFormat: this.state.valueFormat
      }),
      _incomeBarSeries: this.generateBarChartSeries({
        values: response,
        type: "I",
        valueFormat: this.state.valueFormat
      }),
      _incomeBarChart: this.generateBarChartOptions({
        values: response,
        type: "I",
        valueFormat: this.state.valueFormat
      }),
      _liabilitiesBarSeries: this.generateBarChartSeries({
        values: response,
        type: "L",
        valueFormat: this.state.valueFormat
      }),
      _liabilitiesBarChart: this.generateBarChartOptions({
        values: response,
        type: "L",
        valueFormat: this.state.valueFormat
      }),
      _assetsBarSeries: this.generateBarChartSeries({
        values: response,
        type: "A",
        valueFormat: this.state.valueFormat
      }),
      _assetsBarChart: this.generateBarChartOptions({
        values: response,
        type: "A",
        valueFormat: this.state.valueFormat
      }),
    }, function() {
      this.setState({
        _barCharts: true
      })
    });
  };

  _prepareAreaChartWithtwelveMonthData = () => {
    this.setState({
      twelveMonthData: this._generateTwelveMonthValues(this.state.rawTwelveMonthData)
    },() =>{
        this.setState({
        _expenseChart: this.generateAreaChartOptions({
          values: this.state.twelveMonthData["expenses"],
          type: "expenses"
        }),
        _incomeChart: this.generateAreaChartOptions({
          values: this.state.twelveMonthData["income"],
          type: "income"
        }),
        _liabilitiesChart: this.generateAreaChartOptions({
          values: this.state.twelveMonthData["liabilities"],
          type: "liabilities"
        }),
        _assetsChart: this.generateAreaChartOptions({
          values: this.state.twelveMonthData["assets"],
          type: "asset"
        })
      })
    })
  }

  _generateTwelveMonthValues = (response) => {
      var twelveMonthData = [];
      var thisPeriod = this.state.filters.period;
      var months = DataStore.getMonths();
      var valueFormat = this.state.valueFormat;
      ["assets","expenses","income","liabilities"].map((type) => {
        twelveMonthData[type] = [];
        response.map((periodValue, index) => {
          if(periodValue.period == thisPeriod){
            twelveMonthData[type].push({name: months[periodValue.period-1], y:periodValue.value[type], dataLabels: {
              enabled: true,
              backgroundColor: '#D5DAE1',
              borderColor: '#000',
              borderWidth: 1,
              // padding: '5px',
			        verticalAlign: 'top',
              y: periodValue.value[type] > 0 ? -35: 10,
              formatter: function(){
                return months[thisPeriod-1]+' $'+ (periodValue.value[type] < 0 ? `(${Helper.setMetricSuffix(Math.abs(periodValue.value[type]),valueFormat)})` : `${Helper.setMetricSuffix(periodValue.value[type], valueFormat)}`);
              }
          },
            marker: {
              enabled: true,
              symbol: "circle",
              radius:  4,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          });
        }else
            twelveMonthData[type].push({name: months[periodValue.period-1], y:periodValue.value[type]});
        });
      });
      return twelveMonthData;
  }

  _generateOperatingExpensesInProfitTable = (data) => {
    if(this.state._profitAndLossTable){
      this.setState(currentState => ({
        _profitAndLossTable : this.updateTableRows({
          values: data,
          rows : currentState._profitAndLossTable
        })
      }), () => {
        console.log("rows Updated");
      });
    }
  }

  // generatePieChartOptions = (data) => {
  //   return {
  //     chart: {
  //       type: "pie",
  //       renderTo: "container",
  //     },
  //     plotOptions: {
  //       pie: {
  //         innerSize: "50%",
  //         allowPointSelect: true,
  //         cursor: "pointer",
  //         dataLabels: {
  //           enabled: false,
  //         },
  //       },
  //     },
  //     title: {
  //       verticalAlign: "middle",
  //       floating: true,
  //       text: `${data.value}%`,
  //     },
  //     series: [
  //       {
  //         data: [
  //           {
  //             y: Math.abs(data.value),
  //             color: data.color,
  //             negative: data.value < 0 ? true : false,
  //           },
  //           {
  //             y: 100 - Math.abs(data.value),
  //             color: "#494444",
  //             negative: false,
  //           },
  //         ],
  //       },
  //     ],
  //     tooltip: {
  //       enabled: false,
  //     },
  //     credits: {
  //       enabled: false,
  //     },
  //   };
  // };

  generateTableRows = (data) => {
      const rawValues = data.values;
      let rows = [];
      if (data.type == "profit_and_loss") {
        if(rawValues){
          rows[0] = {
            name: "TOTAL INCOME",
            actual: rawValues.total_income.actual < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.total_income.actual))})` : Helper.numberFormat(rawValues.total_income.actual),
            bab: rawValues.total_income.bab < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.total_income.bab))})` : Helper.numberFormat(rawValues.total_income.bab),
            variance: rawValues.total_income.variance < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.total_income.variance))})` : Helper.numberFormat(rawValues.total_income.variance),
            variance_class: rawValues.total_income.variance < 0 ? "cell-red" : "cell-green",
          };
          ["OPERATING EXPENSES","GROSS PROFIT","OTHER EXPENSES"].forEach((name, index) =>{
            rows[index+1] = {
              name: name,
              actual: this.state._profitAndLossTable && this.state._profitAndLossTable[index+1].actual ? this.state._profitAndLossTable[index+1].actual  : "LOADING",
              bab: this.state._profitAndLossTable && this.state._profitAndLossTable[index+1].bab? this.state._profitAndLossTable[index+1].bab : "LOADING",
              variance: this.state._profitAndLossTable && this.state._profitAndLossTable[index+1].variance ? this.state._profitAndLossTable[index+1].variance : "LOADING",
              variance_class: this.state._profitAndLossTable && this.state._profitAndLossTable[index+1].variance_class ? this.state._profitAndLossTable[index+1].variance_class : "",
            };
          })
          rows[4] = {
            name: "NET PROFIT",
            actual: rawValues.net_profit.actual < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.net_profit.actual))})` : Helper.numberFormat(rawValues.net_profit.actual),
            bab: rawValues.net_profit.bab < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.net_profit.bab))})` : Helper.numberFormat(rawValues.net_profit.bab),
            variance: rawValues.net_profit.variance < 0 ? `(${Helper.numberFormat(Math.abs(rawValues.net_profit.variance))})` : Helper.numberFormat(rawValues.net_profit.variance),
            variance_class: rawValues.net_profit.variance < 0 ? "cell-red" : "cell-green",
          };
        }else{
          ["TOTAL INCOME","OPERATING EXPENSES","GROSS PROFIT","OTHER EXPENSES","NET PROFIT"].forEach((name, index) =>{
            rows[index] = {
              name: name,
              actual: index == 0 || index == 4 ? "-" : "LOADING",
              bab: index == 0 || index == 4 ? "-" : "LOADING",
              variance: index == 0 || index == 4 ? "-" : "LOADING",
              variance_class: index == 0 || index == 4 ? "cell-white" : "",
            };
          })
        }
      return rows;
      } else {
        /**Implemented in the Balancesheet component */
        return rawValues;
      }
  };

  updateTableRows = (data) => {
      const newRows = data.rows.map((item, index) => {
        if(this.isObject(data.values) && data.values.total_operating_expenses){
          if (index === 1) {
            const updatedItem = {
              ...item,
              actual: data.values.total_operating_expenses.actual < 0 ? `(${Helper.numberFormat(Math.abs(data.values.total_operating_expenses.actual))})` : Helper.numberFormat(data.values.total_operating_expenses.actual),
              bab: data.values.total_operating_expenses.bab < 0 ? `(${Helper.numberFormat(Math.abs(data.values.total_operating_expenses.bab))})` : Helper.numberFormat(data.values.total_operating_expenses.bab),
              variance: data.values.total_operating_expenses.variance < 0 ? `(${Helper.numberFormat(Math.abs(data.values.total_operating_expenses.variance))})` : Helper.numberFormat(data.values.total_operating_expenses.variance),
              variance_class: data.values.total_operating_expenses.variance <= 0 ? "cell-green" : "cell-red"
            };
            return updatedItem;
          }
          if (index === 2) {
            const updatedItem = {
              ...item,
              actual: data.values.gross_profit.actual < 0 ? `(${Helper.numberFormat(Math.abs(data.values.gross_profit.actual))})` : Helper.numberFormat(data.values.gross_profit.actual),
              bab: data.values.gross_profit.bab < 0 ? `(${Helper.numberFormat(Math.abs(data.values.gross_profit.bab))})` : Helper.numberFormat(data.values.gross_profit.bab),
              variance: data.values.gross_profit.variance < 0 ? `(${Helper.numberFormat(Math.abs(data.values.gross_profit.variance))})` : Helper.numberFormat(data.values.gross_profit.variance),
              variance_class: data.values.gross_profit.variance < 0 ? "cell-red" : "cell-green"
            };
            return updatedItem;
          }
          if (index === 3) {
            const updatedItem = {
              ...item,
              actual: data.values.other_expenses.actual < 0 ? `(${Helper.numberFormat(Math.abs(data.values.other_expenses.actual))})` : Helper.numberFormat(data.values.other_expenses.actual),
              bab: data.values.other_expenses.bab < 0 ? `(${Helper.numberFormat(Math.abs(data.values.other_expenses.bab))})` : Helper.numberFormat(data.values.other_expenses.bab),
              variance: data.values.other_expenses.variance < 0 ? `(${Helper.numberFormat(Math.abs(data.values.other_expenses.variance))})` : Helper.numberFormat(data.values.other_expenses.variance),
              variance_class: data.values.other_expenses.variance <= 0 ? "cell-green": "cell-red" 
            };
            return updatedItem;
          }
        }else{
          if([1,2,3].indexOf(index) > -1){
            const updatedItem = {
              ...item,
              actual: "-",
              bab: "-",
              variance: "-",
              variance_class: "cell-white"
            };
            return updatedItem;
          }
        }
        return item;
      });
      return newRows;
  }

  generateAreaChartOptions = (data) => {
    var gThis = this.state;
    return {
      chart: {
        type: "area", 
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        height: "auto",
        marginLeft: 2,
        marginRight: 2,
        spacingLeft: 0,
        spacingRight: 0
      },
      title: false,
      subtitle: false,
      xAxis: {
        type: 'category',
        visible: true,
        step:1,
        offset: -249,
        labels: {
          style:{
            fontSize:"10px",
          },
          formatter: function() {
            return this.value.charAt(0);
          }
        }
      },
      yAxis: {
        visible: false,
        height: "30%",
        minPadding: 0,
        maxPadding: 0,
        forceNiceScale: true,
      },
      tooltip: {
        enabled: true,
        animation: true,
        outside: true,
        useHTML: true,
        style: {
            padding: 0
        },
        formatter: function () {
          // return this.key == 1940 ? "PREVIOUS MONTH :<br/> $ "+(data.generated.previous < 0 ? `(${Helper.numberFormat(Math.abs(data.generated.previous))})` :Helper.numberFormat(data.generated.previous)) : "THIS MONTH :<br/> $ "+(data.generated.current < 0 ? `(${Helper.numberFormat(Math.abs(data.generated.current))})`:Helper.numberFormat(data.generated.current)); 
          return gThis.months[this.x]+" "+ (this.y < 0 ? "$ ("+Helper.setMetricSuffix(Math.abs(this.y),gThis.valueFormat)+")" : "$ "+Helper.setMetricSuffix(Math.abs(this.y),gThis.valueFormat));
        }
      },
      plotOptions: {
        area: {
          pointStart: 1940,
          marker: {
            enabled: true,
            symbol: "circle",
            radius:  2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        }
      },
      series: [
        {
          // data: data.values[`${data.type}_difference`] < 0 ? 
          //         [{y:Math.abs(data.values[`${data.type}_difference`]), color: "#8B8B8B" },{y:0, color: "#313131"}] : 
          //         [{y:0, color: "#8B8B8B"},{y:Math.abs(data.values[`${data.type}_difference`]), color: "#313131"}],
          data: data.values,
          // color: this.getAreaChartColorOntype(data),
          color: "#323645",
          showInLegend: false,
        },
      ],
      credits: {
        enabled: false,
      },
      // dataLabels: {
      //   format: '<div style="width: 20px; height: 20px; overflow: hidden; border-radius: 50%; margin-left: -25px">' +
      //     '<img src="https://www.highcharts.com/images/employees2014/{point.assignee}.jpg" ' +
      //     'style="width: 30px; margin-left: -5px; margin-top: -2px"></div>',
      //   formatter: function() {
      //     return `${data.month}-${Helper.numberFormat(this.y)}`;
      //   }
      // },
    };
  };

  // getAreaChartColorOntype = (data) => {
  //   switch(data.type){
  //     case 'expenses' : return data.values[`${data.type}_difference`] < 0 ? "#7DDB8A" : "#F45353";
  //     case 'income' : return data.values[`${data.type}_difference`] < 0 ? "#F45353" : "#7DDB8A";
  //     case 'liabilities' : return data.values[`${data.type}_difference`] < 0 ? "#7DDB8A" : "#F45353";
  //     case 'asset' : return data.values[`${data.type}_difference`] < 0 ? "#F45353" : "#7DDB8A";
  //   }
  // }

  generateBarChartOptions = (data) => {
    const options = {
      chart: {
      id: data.type,
      type: 'bar', 
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      height: 'auto',
      stacked: false,
      marginLeft: 0,
      marginRight: 0,
      spacingLeft: 0,
      spacingRight: 0,
      toolbar: {
        show: false
      }
    },
    // colors: ["E","L"].indexOf(data.type) > -1 ? '#584C4C' : '#224A70',
    colors: [function({ value, seriesIndex, w }) {
      if (value < 0) {
          return ["E","L"].indexOf(data.type) > -1 ? '#39b54a' :'#f43939';
      } else {
          return ["E","L"].indexOf(data.type) > -1 ? '#f43939' :'#39b54a';
      }
    }],
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '95%',
        barHeight: "95%",
        barWidth: '95%',
        dataLabels: {
          // position: ["E","L"].indexOf(data.type) > -1 ? "bottom" : "top",
          position: "bottom",
          hideOverflowingLabels: true,
        }
    
      },
    },
    legend: {
        show: true,
        position: "top",
        showForSingleSeries: true,
        horizontalAlign: "center",
        fontSize: '16px',
        formatter: function(seriesName, opts) {
          return seriesName;
        },
        markers: {
          fillColors: ['#fff']
        }
    },
    responsive: [
      {
        breakpoint:2561,
        options: {
          chart: {
            height: '400px',
          },
          yaxis: {
            show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
            reversed: false,
            forceNiceScale: true,
            labels: {
              align: 'center',
              offsetX: 0,
              offsetY: 4,
              style: {
                colors: [],
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass:"apexcharts-yaxis-label",
              }
            },
            opposite: false,
            floating: false,
            axisBorder: {
              show: true,
              color: '#000',
            },
          },
          dataLabels: {
            textAnchor: "middle",
            offsetX: 9
          }
        }
      },
      {
        breakpoint:1691,
        options: {
          chart: {
            height: '400px',
          },
          yaxis: {
            show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
            reversed: false,
            forceNiceScale: true,
            labels: {
              align: 'center',
              offsetX: 0,
              offsetY: 4,
              style: {
                colors: [],
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass:"apexcharts-yaxis-label",
              }
            },
            opposite: false,
            floating: false,
            axisBorder: {
              show: true,
              color: '#000',
            },
          },
          dataLabels: {
            textAnchor: "middle",
            style: {
                fontSize: "12px",
            },
            offsetX: 6
          }
        }
      },
      {
        breakpoint:1401,
        options: {
          chart: {
            height: '400px',
          },
          yaxis: {
            show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
            reversed: false,
            forceNiceScale: true,
            labels: {
              align: 'center',
              offsetX: 0,
              offsetY: 4,
              style: {
                colors: [],
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass:"apexcharts-yaxis-label",
              }
            },
            opposite: false,
            floating: false,
            axisBorder: {
              show: true,
              color: '#000',
            },
          },
          dataLabels: {
            textAnchor: "middle",
            style: {
                fontSize: "12px",
            },
            offsetX: 5,
            background: {
              opacity: 0.7
            }
          }
        }
      },
      {
        breakpoint:1341,
        options: {
          chart: {
            height: '400px',
          },
          dataLabels: {
            textAnchor: "middle",
            style: {
                fontSize: "12px",
            },
            offsetX: 5,
            background: {
              opacity: 0.7
            }
          }
        }
      },
      {
        breakpoint:1201,
        options: {
          chart: {
            height: '400px',
          },
          yaxis: {
            show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
            reversed: false,
            forceNiceScale: true,
            labels: {
              align: 'center',
              offsetX: 0,
              offsetY: 4,
              style: {
                colors: [],
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass:"apexcharts-yaxis-label",
              }
            },
            opposite: false,
            floating: false,
            axisBorder: {
              show: true,
              color: '#000',
            },
          },
          dataLabels: {
            textAnchor: "middle",
            offsetX: 5,
          }
        }
      },
      {
        breakpoint:992,
        options: {
          chart: {
            height: '400px',
          },
          yaxis: {
            show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
            reversed: false,
            forceNiceScale: true,
            labels: {
              align: 'center',
              offsetX: 0,
              offsetY: 4,
              style: {
                colors: [],
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass:"apexcharts-yaxis-label",
              }
            },
            opposite: false,
            floating: false,
            axisBorder: {
              show: true,
              color: '#000',
            },
          },
          dataLabels: {
            textAnchor: "middle",
            offsetX: 5,
            }
          },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: '400px',
          },
            yaxis: {
              show: true,
              labels: {
                align: 'center',
                offsetX: 0,
                offsetY: 4,
                style: {
                  colors: [],
                  fontSize: '13px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  cssClass:"apexcharts-yaxis-label",
                }
              },
              opposite: false,
              floating: false,
              axisBorder: {
                show: true,
                color: '#000',
              }
            }
        }
      }
    ],
    dataLabels: {
      enabled: true,
      // textAnchor: ["E","L"].indexOf(data.type) > -1 ? "end": "start", //start
      textAnchor: "start",
      style: {
          colors: ['#fff'],
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: "12px",
          // fontWeight: 500,

      },
      // offsetX:["E","L"].indexOf(data.type) > -1 ? -35 : 35,
      offsetX: 0,
      formatter: function(val, opt) {
        if(isNaN(val))
          return '';
        return originalBarChartSeries[data.type][opt.seriesIndex][opt.dataPointIndex] == 'PO' ? `${Helper.setMetricSuffix(Math.abs(val), data.valueFormat)}` : `(${Helper.setMetricSuffix(Math.abs(val), data.valueFormat)})`;
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 2,
        borderRadius: 2,
        borderWidth: 0.5,
        borderColor: '#fff',
        opacity: 0.5,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#fff',
          opacity: 0.9
        }
      },
    },
    stroke: {
      width: 1,
      curve: "smooth",
      colors: ["#fff"]
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
          lines: {
              show: false
          },
          minPadding: 0,
          maxPadding: 0,
      },
      padding: {
        // top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }
    },
    yaxis: {
      show: ["E", "L"].indexOf(data.type) > -1 ? true : false,
      // reversed: ["E", "L"].indexOf(data.type) > -1 ? true : false,
      reversed: false,
      // min: -Math.abs(originalBarChartSeries[data.type]['max'] + originalBarChartSeries[data.type]['max']/2),
      // max: originalBarChartSeries[data.type]['max'] + originalBarChartSeries[data.type]['max']/2,
      forceNiceScale: true,
      title: {
        // text: 'Age',
      },
      labels: {
        align: 'center',
        offsetX: ["E", "L"].indexOf(data.type) > -1 ? 0 : 0,
        offsetY: 4,
        style: {
          colors: [],
          fontSize: '13px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          cssClass:"apexcharts-yaxis-label",
        }
      },
      opposite: ["E", "L"].indexOf(data.type) > -1 ? false : true,
      // opposite: false,
      floating: false,
      axisBorder: {
        show: true,
        color: '#000',
      },
    },
    tooltip: {
      enabled: false,
      shared: false,
      theme: 'dark',
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        return '<div class="arrow_box">' +
        `<span> $ ${series[seriesIndex][dataPointIndex] < 0 ? `(${Helper.numberFormat(Math.abs(series[seriesIndex][dataPointIndex]))})` : Helper.numberFormat(series[seriesIndex][dataPointIndex])} </span>`;
      },
      x: {
        show: false,
        formatter: function (val) {
          return val
        }
      },
      y: {
        show: false,
        formatter: function (val) {
          return Math.abs(val)
        }
      }
    },
    title: {
      enabled: false
    },
    xaxis: {
      // categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      //   'September', 'October', 'November', 'December'
      // ],
      categories: DataStore.getMonthInLong(),
      title: {
        // text: 'Percent'
        enabled: false
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        }
      },
      min: originalBarChartSeries[data.type]['max'] == 0 ? -5 : -Math.abs(originalBarChartSeries[data.type]['max'] + originalBarChartSeries[data.type]['max']/2),
      max: originalBarChartSeries[data.type]['max'] == 0 ? 5 : originalBarChartSeries[data.type]['max'] + originalBarChartSeries[data.type]['max']/2,
      axisBorder: {
        show: false
      },
      axisTicks: {
          show: false,
      }
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          strokeDashArray: 0,
          borderColor: "#000",
          fillColor: '#000'
        }
      ]
    }
    }
    return options;
  }

  // generateNegativeStackBarChartOptions = (data) => {
  //   const options = {
  //     chart: {
  //     id: data.type,
  //     type: 'bar',
  //     height: 440,
  //     stacked: false,
  //     marginLeft: 0,
  //     marginRight: 0,
  //     spacingLeft: 0,
  //     spacingRight: 0,
  //     toolbar: {
  //       show: false
  //     }
  //   },
  //   colors: ['#494444', '#9acd32'],
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       columnWidth: '90%',
  //       barHeight: "90%",
  //       barWidth: '90%',
  //       dataLabels: {
  //         position: 'top'
  //       }
    
  //     },
  //   },
  //   legend: {
  //       position: "top",
  //       horizontalAlign: "center",
  //       fontSize: '17px'
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     textAnchor: "center",
  //     style: {
        
  //         colors: ['#333']
  //     },
  //     offsetX:50,
  //     formatter: function(val, opt) {
  //       return originalBarChartSeries[data.type][opt.seriesIndex][opt.dataPointIndex] == 'P' ? `${Math.abs(val)}` : `(${-Math.abs(val)})`;
  //         return val < 0 ? `(${Math.abs(val)})` : `${Math.abs(val)}`;
  //     },
  //   },
  //   stroke: {
  //     width: 1,
  //     colors: ["#fff"]
  //   },
  //   grid: {
  //     xaxis: {
  //       lines: {
  //         show: false
  //       }
  //     },
  //     yaxis: {
  //         lines: {
  //             show: false
  //         }
  //     }, 
  //   },
  //   yaxis: {
  //     // min: -Math.abs(originalBarChartSeries[data.type]['max'] + 500),
  //     // max: originalBarChartSeries[data.type]['max']+500,
  //     forceNiceScale: true,
  //     title: {
  //       // text: 'Age',
  //     },
  //     labels: {
  //       align: 'center',
  //       offsetX: 0

  //     },
  //     opposite: false,
  //     floating: false
  //   },
  //   tooltip: {
  //     shared: false,
  //     x: {
  //       formatter: function (val) {
  //         return val
  //       }
  //     },
  //     y: {
  //       formatter: function (val) {
  //         return Math.abs(val)
  //       }
  //     }
  //   },
  //   title: {
  //     enabled: false
  //   },
  //   xaxis: {
  //     categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  //       'September', 'October', 'November', 'December'
  //     ],
  //     title: {
  //       // text: 'Percent'
  //       enabled: false
  //     },
  //     labels: {
  //       show: true,
  //       formatter: function (val) {
  //         return Math.abs(Math.round(val))
  //       }
  //     }
  //   }
  //   }
  //   return options;
  // };

  generateBarChartSeries = (data) => {
    let series_data = []; let max = 0;
    originalBarChartSeries[data.type] = Array();
    originalBarChartSeries[data.type][0] = Array();
    originalBarChartSeries[data.type]["max"] = 0;
    originalBarChartSeries[data.type]["noData"] = true;

    series_data[0] = Array();
    data.values.map((periodValue, index) => {
      let value = this.getValueOnType({periodValue: periodValue,type: data.type});
      if(periodValue.value && this.isObject(periodValue.value)){
        originalBarChartSeries[data.type]["noData"] = false;
        max = this.getMaxOnType({periodValue: periodValue,type: data.type});
        originalBarChartSeries[data.type][0][index] = value < 0 ? "NE" : "PO";
      }else{
        max = 0;
        originalBarChartSeries[data.type][0][index] = value;
      }
      originalBarChartSeries[data.type]["max"] = Math.max(originalBarChartSeries[data.type]["max"], max);
      series_data[0].push(value);
    });
    
    return this.getSeriesByType({
      series_data : series_data,
      type: data.type
    });
  }

  isObject = (val) => {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
  }

  getMaxOnType = (params) => {
    switch(params.type){
      case "E" : return params.periodValue.value.expenses;
      case "I" : return params.periodValue.value.income;
      case "L" : return params.periodValue.value.liabilities;
      case "A" : return params.periodValue.value.assets;
    }
  }

  getValueOnType = (params) => {
      switch(params.type){
        case "E" : return params.periodValue.value && this.isObject(params.periodValue.value) ? params.periodValue.value.expenses : "NO";
        case "I" : return params.periodValue.value && this.isObject(params.periodValue.value)  ? params.periodValue.value.income : "NO";
        case "L" : return params.periodValue.value && this.isObject(params.periodValue.value)  ? params.periodValue.value.liabilities : "NO";
        case "A" : return params.periodValue.value && this.isObject(params.periodValue.value)  ? params.periodValue.value.assets : "NO";
      }
  }

  getSeriesByType = (params) => {
    switch(params.type){
      case "E" : return [{name: "EXPENSES ($)", data: params.series_data[0]}];
      case "I" : return [{name: "INCOME ($)", data: params.series_data[0]}];
      case "L" : return [{name: "LIABILITIES ($)", data: params.series_data[0]}];
      case "A" : return [{name: "ASSETS ($)", data: params.series_data[0]}];
    }
  }

  // generateNegativeStackBarChartSeries = (data) => {
  //     let series = []; let series_data = [];
  //     originalBarChartSeries[data.type] = Array();
  //     originalBarChartSeries[data.type][0] = Array();
  //     originalBarChartSeries[data.type][1] = Array();
  //     originalBarChartSeries[data.type]['max'] = 0;

  //     series_data[0] = Array();
  //     series_data[1] = Array();
  //     data.values.map((periodValue, index) => {
  //       if(data.type == 'EI'){
  //         let max = Math.max(Math.abs(periodValue.value.expenses), Math.abs(periodValue.value.income));
  //         originalBarChartSeries[data.type]['max'] = Math.max(originalBarChartSeries[data.type]['max'], max);

  //         originalBarChartSeries[data.type][0][index] = periodValue.value.expenses < 0 ? 'N' : 'P';
  //         originalBarChartSeries[data.type][1][index] = periodValue.value.income < 0 ? 'N' : 'P';
  //         /** 0 series contain the negative values */
  //         series_data[0].push(periodValue.value.expenses < 0 ? periodValue.value.expenses : -Math.abs(periodValue.value.expenses));
  //         /** 1 series contain the positive values */
  //         series_data[1].push(periodValue.value.income < 0 ? Math.abs(periodValue.value.income) : periodValue.value.income);
  //       }else{
  //         let max = Math.max(Math.abs(periodValue.value.liabilities), Math.abs(periodValue.value.assets));
  //         originalBarChartSeries[data.type]['max'] = Math.max(originalBarChartSeries[data.type]['max'], max);

  //         originalBarChartSeries[data.type][0][index] = periodValue.value.liabilities < 0 ? 'N' : 'P';
  //         originalBarChartSeries[data.type][1][index] = periodValue.value.assets < 0 ? 'N' : 'P';
  //         /** 0 series contain the negative values */
  //         series_data[0].push(periodValue.value.liabilities < 0 ? periodValue.value.liabilities : -Math.abs(periodValue.value.liabilities));
  //         /** 1 series contain the positive values */
  //         series_data[1].push(periodValue.value.assets < 0 ? Math.abs(periodValue.value.assets) : periodValue.value.assets);
  //       }
  //     });

  //     if(data.type == 'EI'){
  //       series[0] = {name: "EXPENSES ($)", data: series_data[0]};
  //       series[1] = {name: "INCOME ($)", data: series_data[1]};
  //     }else{
  //       series[0] = {name: "LIABILITIES ($)", data: series_data[0]};
  //       series[1] = {name: "ASSETS ($)", data: series_data[1]};
  //     }

  //   return series;
  // }

  generateAreaChartValues = (response, filters) => {
    let data = [];
    /**
     * 0 - previous, 1 - current, 2 - difference
     */
    response.response.map(function (periodData, index) {
      if (periodData.period === filters.previous_period)
        data[0] = periodData.value;
      else data[1] = periodData.value;
    });
    data[2] = {
      // expenses: response.expenses_difference > 0 ? `+${response.expenses_difference}` : response.expenses_difference,
      // income: response.income_difference > 0 ? `+${response.income_difference}` : response.income_difference,
      // liabilities: response.liabilities_difference > 0 ? `+${response.liabilities_difference}` : response.liabilities_difference,
      // assets: response.asset_difference > 0 ? `+${response.asset_difference}` : response.asset_difference,
      expenses: response.expenses_difference,
      income: response.income_difference,
      liabilities: response.liabilities_difference,
      assets: response.asset_difference,
    };
    return data;
  };

  onFormatChange = (event) => {
    this.setState({
      valueFormat: event.target.value
    }, () => {
      if(this.state.rawTwelveMonthData){
          this._prepareAreaChartWithtwelveMonthData();
          this._generateTwelveMonthCharts(this.state.rawTwelveMonthData);
      }
    });
  }

  render() {

    return (
      <Fragment>
        <Breadcrumb
            title={'Financial summary'}
            subtitle={`${this.state.filters.year} / ${this.state.months[this.state.filters.period - 1]}`}
            tooltip={true}
        />
        <div>

        <div className="row">
          <div className="col-12 ">
            <div className="card">
              <div className="row">
                  <div className="col-xl-5">
                      <div className="card-header">
                        <h5>PROFIT & LOSS</h5>
                      </div>
                      <div className="card-body profitTable">
                        {this.state._profitAndLossTable && this.state._profitAndLossTable !== null ? (
                          <ProfitAndLoss data={this.state._profitAndLossTable} />
                        ) : (
                          <Loader />
                        )}
                      </div>
                  </div>

                  <div className="col-xl-2 cardBlockWrapper">
                    <div className="textCardCover ">
                      <div className="card-header">
                        {/* <h5>Net Profit Margin</h5> */}
                      </div>
                      <div className="card-body text-center ">
                        {(this.state._netProfitMargin && this.state._netProfitMargin !== null) || (this.state._netProfitMargin && this.state._netProfitMargin.percentage == 0) ? (
                          <CardComponent value={this.state._netProfitMargin.value} percentage={this.state._netProfitMargin.percentage} id="NPM"/>
                        ) : (
                        <Loader />
                        )}
                      </div>
                    </div>
                    <div className="textCardCover">
                        <div className="card-header">
                          {/* <h5>Gross Profit Margin</h5> */}
                        </div>
                        <div className="card-body">
                          {(this.state._grossProfitMargin && this.state._grossProfitMargin !== null) || (this.state._grossProfitMargin && this.state._grossProfitMargin.percentage == 0) ? (
                            <CardComponent value={this.state._grossProfitMargin.value} percentage={this.state._grossProfitMargin.percentage} id="GPM"/>
                          ) : (
                            <Loader />
                          )}
                      </div>      
                    </div>      
                  </div>  
                  <div className="col-xl-5">
                    <div className="card-header">
                      <h5>Balance Sheet</h5>
                    </div>
                    <div className="card-body profitTable">
                      {this.state._balanceSheetTable && this.state._balanceSheetTable !== null ? (
                        <BalanceSheet data={this.state._balanceSheetTable} />
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>  
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
                <div className="card financial-separator-line">&nbsp;</div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="form-group m-t-3 m-checkbox-inline mb-3 custom-radio-ml value-format-filters">
                  <div className="radio radio-primary" onChange={this.onFormatChange}>
                    <input className="radio_animated" id="radioinline1" type="radio" name="radio3" value="AF" />
                    <label className="mb-0" htmlFor="radioinline1">In Dollars</label>
                  </div>
                  <div className="radio radio-primary" onChange={this.onFormatChange}>
                    <input className="radio_animated" id="radioinline2" type="radio" name="radio3" value="K"/>
                    <label className="mb-0" htmlFor="radioinline2">In Hundred Thousand Dollars (K)</label>
                  </div>
                  <div className="radio radio-primary" onChange={this.onFormatChange}>
                    <input className="radio_animated" id="radioinline3" type="radio" name="radio3" value="M" defaultChecked/>
                    <label className="mb-0" htmlFor="radioinline3">In Million Dollars (M)</label>
                  </div>
                </div>
              </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                  <div className="row">
                    <div className="col-xl-6">
                        <div className="row">
                          <div className="col-md-6 border-right">
                              <div className="card-header">
                                <h5>INCOME</h5>
                              </div>
                              <div className="card-body">
                                {this.state._areaChartValues && this.state._areaChartValues !== null ? (
                                  <AreaGraphComponent id="IN" chartOptions={this.state._incomeChart} previousValue={this.state._areaChartValues[0].income} value={this.state._areaChartValues[1].income} difference={this.state._areaChartValues[2].income} valueFormat={this.state.valueFormat}
                                  />
                                ) : (
                                  <Loader />
                                )}
                              </div>
                          </div>
                          <div className="col-md-6 border-right">
                              <div className="card-header">
                                <h5>EXPENSE</h5>
                              </div>
                              <div className="card-body">
                                {this.state._areaChartValues && this.state._areaChartValues !== null ? (
                                  <AreaGraphComponent id="EX" chartOptions={this.state._expenseChart} previousValue={this.state._areaChartValues[0].expenses} value={this.state._areaChartValues[1].expenses} difference={this.state._areaChartValues[2].expenses} valueFormat={this.state.valueFormat}
                                  />
                                ) : (
                                  <Loader />
                                )}
                              </div>
                          </div>
                        </div>
                    </div>


                    <div className="col-xl-6 ">
                        <div className="row">
                          <div className="col-md-6 border-right">
                            <div className="card-header">
                              <h5>ASSETS</h5>
                              </div>
                              <div className="card-body">
                                {this.state._areaChartValues && this.state._areaChartValues !== null ? (
                                  <AreaGraphComponent id="AS" chartOptions={this.state._assetsChart} previousValue={this.state._areaChartValues[0].assets} value={this.state._areaChartValues[1].assets} difference={this.state._areaChartValues[2].assets} valueFormat={this.state.valueFormat}
                                  />
                                ) : (
                                  <Loader />
                                )}
                              </div>
                          </div>
                          <div className="col-md-6 border-right">
                            <div className="card-header">
                              <h5>LIABILITIES</h5>
                            </div>
                            <div className="card-body">
                              {this.state._areaChartValues && this.state._areaChartValues !== null ? (
                                <AreaGraphComponent id="LI" chartOptions={this.state._liabilitiesChart} previousValue={this.state._areaChartValues[0].liabilities} value={this.state._areaChartValues[1].liabilities} difference={this.state._areaChartValues[2].liabilities} valueFormat={this.state.valueFormat}
                                />
                              ) : (
                                <Loader />
                              )}
                            </div>
                          </div>
                        </div>
                    </div>                          

                  </div>               
                </div>

            </div>
        </div>                  
        </div>
      

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Income and Expenses</h5>
              </div>
              <div className="card-body custom-bar-chart-body">
                {this.state._barCharts ? (
                  <div className="row">
                  {originalBarChartSeries["E"]["noData"] && originalBarChartSeries["I"]["noData"] ? 
                    <div className={`text-center box textCard`}><h4>No Data</h4></div>
                  :
                    <Fragment>
                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-x1-6 left-bar-chart">
                        <ApexChartComponent chartOptions={this.state._incomeBarChart} series={this.state._incomeBarSeries} type="bar" chartId="I"/>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-x1-6  right-bar-chart">
                        <ApexChartComponent chartOptions={this.state._expensesBarChart} series={this.state._expensesBarSeries} type="bar" chartId="E"/>
                      </div>
                    </Fragment>
                    }
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Assets and Liabilities</h5>
              </div>
              <div className="card-body custom-bar-chart-body">
                {this.state._barCharts ? (
                  <div className="row">
                  {originalBarChartSeries["L"]["noData"] && originalBarChartSeries["A"]["noData"] ? 
                    <div className={`text-center box textCard`}><h4>No Data</h4></div>
                  :
                    <Fragment>
                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-x1-6  left-bar-chart">
                        <ApexChartComponent chartOptions={this.state._assetsBarChart} series={this.state._assetsBarSeries} type="bar" chartId="A"/>
                      </div>
                      <div className="ccol-xs-6 col-sm-6 col-md-6 col-lg-6 col-x1-6  right-bar-chart">
                        <ApexChartComponent chartOptions={this.state._liabilitiesBarChart} series={this.state._liabilitiesBarSeries} type="bar" chartId="L"/>
                      </div>
                    </Fragment>
                    }
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}
}

const mapStateToProps = (state) => {
  return {
    headerFilters: state.filters.header
  }
}

export default connect(mapStateToProps)(Dashboard);