import React, {Fragment} from "react";
import {Col, Form, TreeSelect} from 'antd';
import PropTypes from "prop-types";

const AccessUserRoles = ({userRoles}) => {

    const prepareUserRoleData = (mainRole) => {
        // remove duplicate base role types
        let filteredTypes = [];
        return mainRole.roles.filter(role => {
            if (!filteredTypes.includes(role.base_role_type)){
                filteredTypes.push(role.base_role_type)
                return role
            }
        }).map(role =>(
            {
                title: role.label,
                key: role.base_role_type,
                value: role.base_role_type
            }
        ))
    }

    const renderRolesSelect = () => {
        return (
            userRoles?.allSchoolRoles?.filter(m => m.parent).map(mainRole => {
                const selectProps =  {
                    treeData: prepareUserRoleData(mainRole),
                    treeDefaultExpandAll: true,
                    treeCheckable: true,
                    placeholder: 'Please select',
                    style: {
                        width: '100%',
                    },
                };

                return (
                    <Form.Item name={`userRoles.${mainRole.id}`} label={mainRole.name}>
                        <TreeSelect  {...selectProps} />
                    </Form.Item>
                )
            })

        )
    }

    return (
        <Fragment>
            {renderRolesSelect()}
        </Fragment>
    )

}

AccessUserRoles.propTypes = {
    userRoles: PropTypes.array
}

export default AccessUserRoles;