import _get from "lodash/get"
import _camelCase from "lodash/camelCase"

export const SUBMISSIONS = ["onTime", "late", "missing"];

// format return values
const formatData = (selected, resolvedValue) => {
    if (!(selected && selected.color)) {
        return {
            value: resolvedValue,
            styles: {},
        };
    }

    return {
        value: resolvedValue,
        styles: {
            color: selected.color
        },
    };
};

// get element from an array using a key
export const resolveFromArrayByKey = (params) => {
    const {value, row, settings, dataPath, key} = params;
    const resolvedValue = !dataPath ? value : row[dataPath];
    const selected = settings.find(setting => (setting[key].toLowerCase() === resolvedValue.toLowerCase()));

    return formatData(selected, resolvedValue);
}

// return a predefined first matching record for a given number, with compared to a range
export const resolveFromArrayByRange = (params) => {
    const {value, row, settings, dataPath, resolved} = params;
    const selected = settings.find(setting => {
        const to = (isNaN(Number(setting.to)) ? Number.MAX_VALUE : setting.to);
        return (setting.from <= value && (to > value || to === 100 || isNaN(value)))
    });
    let resolvedValue = !dataPath ? value : row[dataPath];
    if (resolved && dataPath) {
        resolvedValue = selected[dataPath];
    }

    return formatData(selected, resolvedValue);
};

// return a predefined matching item from an object
export const resolveFromObjectByKey = (params) => {
    const {value, row, settings, key, dataPath} = params;
    const resolvedValue = !dataPath ? value : _get(row, dataPath);
    const selected = settings[key];

    return formatData(selected, resolvedValue);
};

// return a predefined matching item from an object
// in this resolver, colors are received in separate object
export const resolveFromObjectByKeyWithCustomPath = (params) => {
    const {value, row, settings, key, dataPath} = params;
    const resolvedValue = !dataPath ? value : _get(row, dataPath).count;
    const resolvedColorValue = !dataPath ? value : _get(row, dataPath).color;
    const selected = settings[key];

    return {
        value: resolvedValue,
        styles: {
            color: resolvedColorValue
        },
    };
};

// get an array of a given property from an array
// values = [[A,10], [B, 20]]
// settings = [{grade: A, color: #123456, from:10, to: 20}]
// propertyMapping = [0, grade] (this means 0th element of each item of value variable will be mapped against each item
// of setting's grade property)
// property = color (once items are mapped, value of this property will be collected in to an array, which will return)
export const resolvePropertiesFromSettingArray = (
        values = [],
        settings = [],
        propertyMapping = [0, "grade"],
        property = "color"
) => {
    let colors = [];

    if (!(Array.isArray(values) && Array.isArray(settings))) {
        return colors;
    }

    values.forEach(item => {
        const found = settings.find(setting => {
            return item[propertyMapping[0]].toLowerCase() === setting[propertyMapping[1]].toLowerCase();
        });

        if (found && found[property]) {
            colors.push(found[property]);
        }
    });

    return {
        colors
    };
}

// get an array of given property from an object
export const resolvePropertiesFromSettingObject = (values = [], settings = {}, property = "color") => {
    let colors = [];

    if (!(Array.isArray(values) && typeof settings === "object")) {
        return colors;
    }

    values.forEach(item => {
        const key = item[0];
        if (settings[_camelCase(key)]) {
            colors.push(settings[_camelCase(key)][property]);
        }
    });

    return {
        colors
    };
}

// this is custom changed resolver for new structure
export const resolvePropertiesFromCohortData = (
        values = [],
        settings = [],
        propertyMapping = [0, "grade"],
        property = "color"
) => {
    let colors = [];

    values.forEach(item => {
        colors.push(item[2]);
    });

    return {
        colors
    };
}
