import React from "react";
import moment from "moment";
import {DatePicker, Form} from "antd";
import PropTypes from "prop-types";

const { RangePicker } = DatePicker;

const CustomRangePicker = ({name, label, required}) => (
    <Form.Item name={name} label={label} required={required}>
        <RangePicker
            format="YYYY/MM/DD"
            ranges={{
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            }}
            // defaultPickerValue={[moment().subtract(1, 'months'), moment().startOf('month')]}
        />
    </Form.Item>
)

CustomRangePicker.defaultProps = {
    name: "dateRange",
    label: "Time Period",
    required: false,
}

CustomRangePicker.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,

}
export default CustomRangePicker;