import React, {memo, Fragment} from "react";
import PropTypes from "prop-types";
import {Collapse, Input, Space, Switch, Typography} from "antd";
import {useSelector} from "react-redux";

import {onError} from "../../../../../utils/notificationHandler";

import {getPermissionDataByPath} from "../../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";

import ScoreSection from "./ScoreSection";
import SectionSelect from "./subComponents/SectionSelect";

const {Panel} = Collapse;
const SECTION_UNIT_PROGRESS = 'unitProgress';
const UNIT_PROGRESS_TITLE = 'Valid Unit Completion Outcomes';

const SectionGroup = ({sections, showDrawer, onSectionDataChange, subSection, onScoreDelete, onEditClick}) => {

    // selectors
    const hasPermission = useSelector(getPermissionDataByPath(`${subSection}.saveCalculations`));

    const renderSectionHeader = (section) => (
        <Fragment>
            <Space style={{alignItems:"center"}}>
                <Switch
                    checked={section.enabled}
                    size="small"
                    disabled={!hasPermission}
                    onClick={(value, event) => {
                        event.stopPropagation();
                        onSectionDataChange(section.id, value, "enabled", subSection);
                    }}
                />
                <Typography.Text strong>{section.name}</Typography.Text>
            </Space>
        </Fragment>
    )

    return (
        <Collapse expandIconPosition="right" className="customAcordian">
            {sections.map(section => (
                <Panel header={renderSectionHeader(section)} extra={
                    <Space>
                        <SectionSelect
                            disabled={!hasPermission}
                            section={section}
                            onSelectionChanged={onSectionDataChange}
                            subSection={subSection}
                            width={section.id === SECTION_UNIT_PROGRESS ? 200 : 120}
                            label={
                                section.id === SECTION_UNIT_PROGRESS ? UNIT_PROGRESS_TITLE : ''
                            }
                            placeholder="Select outcomes"
                        />
                        <Input
                            disabled={!hasPermission}
                            type="number"
                            size="small"
                            style={{width:75}}
                            maxLength={3}
                            value={section.weight}
                            suffix="%"
                            onFocus={event => {
                                event.stopPropagation();
                            }}
                            onBlur={event => {
                                if(!event.target.value || event.target.value === ""){
                                    onError("Weight cannot be empty")
                                    onSectionDataChange(section.id, 0, "weight", subSection);
                                }
                            }}
                            onClick={event => {
                                event.stopPropagation();
                            }}
                            onChange={event => {
                                onSectionDataChange(section.id, event.target.value, "weight", subSection);
                            }}
                        />
                    </Space>
                } key={section.id}>
                    <ScoreSection
                        section={section}
                        conditions={section.scores}
                        mode={section.mode}
                        openDrawer={showDrawer}
                        onScoreDelete={onScoreDelete}
                        subSection={subSection}
                        onEditClick={onEditClick}
                        hasPermission={hasPermission}
                    />
                </Panel>
            ))}
        </Collapse>
    )
}

SectionGroup.propTypes = {
    sections: PropTypes.array,
    showDrawer: PropTypes.func,
    onSectionDataChange: PropTypes.func,
    subSection: PropTypes.string,
    onScoreDelete: PropTypes.func,
    onEditClick: PropTypes.func,
}

export default memo(SectionGroup);