import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {
    getDashboardFiltersFetchLoading
} from "../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";

import JRSidebar from "../common/layout/Sidebar";
import JRInnerContent from "../common/layout/InnerContent";
import FilterContainer from "./partials/filters";
import TabSection from "./partials/tabSection/TabSection";
import Loader from "../common/layout/Loader";

import {subSections} from "../../../constant/jobReadyGeneric/constants";
import {
    getOverviewChartsLoading,
    getOverviewStudentsLoading
} from "../../../selectors/jobReadyGeneric/riskAnalysis/overview.selector";
import {
    getAcademicCardsLoading, getAcademicOverviewLoading,
    getAcademicStudentsLoading
} from "../../../selectors/jobReadyGeneric/riskAnalysis/academic.selector";
import {
    getComplianceCardsLoading, getComplianceOverviewLoading,
    getComplianceStudentsLoading
} from "../../../selectors/jobReadyGeneric/riskAnalysis/compliance.selector";
import {
    getFinancialCardsLoading, getFinancialOverviewLoading,
    getFinancialStudentsLoading
} from "../../../selectors/jobReadyGeneric/riskAnalysis/financial.selector";


const JRGenericHome = () => {
    const [activeTab, setActiveTab] = useState(null);

    // selectors
    const sidebarDataFetchLoading = useSelector(getDashboardFiltersFetchLoading);

        // selectors
    const ovChartLoading = useSelector(getOverviewChartsLoading);
    const ovStudentLoading = useSelector(getOverviewStudentsLoading);

    const acCardLoading = useSelector(getAcademicCardsLoading);
    const acStudentLoading = useSelector(getAcademicStudentsLoading);
    const acOverviewLoading = useSelector(getAcademicOverviewLoading);

    const cmCardLoading = useSelector(getComplianceCardsLoading);
    const cmStudentLoading = useSelector(getComplianceStudentsLoading);
    const cmOverviewLoading = useSelector(getComplianceOverviewLoading);

    const fiCardLoading = useSelector(getFinancialCardsLoading);
    const fiStudentLoading = useSelector(getFinancialStudentsLoading);
    const fiOverviewLoading = useSelector(getFinancialOverviewLoading);


    let barLoading = ovChartLoading || ovStudentLoading || acCardLoading || acStudentLoading || acOverviewLoading || cmCardLoading
        || cmStudentLoading || cmOverviewLoading || fiCardLoading || fiStudentLoading || fiOverviewLoading;

    // effects
    useEffect(() => {
        setActiveTab(subSections.overview.key);
    }, [])

    const onTabChange = (key) => {
        setActiveTab(key)
    }

    return (
        <Fragment>
            <Loader isLoading={sidebarDataFetchLoading}/>
            <JRSidebar>
                <div className="filter-section-container">
                    <FilterContainer/>
                </div>
            </JRSidebar>
            <JRInnerContent>
                <TabSection
                    tabConfig={{activeTab, setActiveTab:onTabChange}}
                    barDisabled={barLoading}
                />

            </JRInnerContent>
        </Fragment>
    )
}

export default JRGenericHome;