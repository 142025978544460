import {
  HELP_SUBMITTING_FEEDBACK,
  HELP_SUBMITTED_FEEDBACK,
} from '../../constant/actionTypes';

const initalState = {
  helpFeedBack: {
    isSubmitting: false,
    submitError: {},
    lastSubmitted: null,
  },
};

const genericReducer = (state = initalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case HELP_SUBMITTING_FEEDBACK:
      return {
        ...state,
        helpFeedBack: {
          ...state.helpFeedBack,
          isSubmitting: payload,
        },
      };
    case HELP_SUBMITTED_FEEDBACK:
      return {
        ...state,
        helpFeedBack: {
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default genericReducer;
