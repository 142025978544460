import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {getDashboardFiltersSelectedData} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {
    getFinancialOverviewData,
    getFinancialOverviewLoading
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/financial.selector";
import {fetchFinancialOverview} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/financial.action";

import Overview from "./Overview";

import {subSections} from "../../../../../../../../../../constant/jobReadyGeneric/constants";


const OverviewTab = () => {
    const dispatch = useDispatch();

    // selectors
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const overviewDataLoading = useSelector(getFinancialOverviewLoading);
    const overviewData = useSelector(getFinancialOverviewData);

    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading){
            const data = {
                filters: selectedFilters,
                subSectionId: subSections.financial.id
            }
            dispatch(fetchFinancialOverview(data));
        }
    }, [selectedFilters])

    return (
        <Fragment>
            <Overview
                lineChartData={overviewData?.lineChartData}
                pieChartData={overviewData?.pieChartData}
                speedometerData={overviewData?.speedometerData}
                dataLoading={overviewDataLoading}
            />
        </Fragment>
    )
}

export default OverviewTab