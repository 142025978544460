import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import OutcomeGridView from "./OutcomeGrid";
import OutcomeCellCard from "./partials/OutcomeCellCard";
import OutcomeTitleCell from "./partials/OutcomeTitleCell";

import {
    makeInnovativeAssignmentsLoading,
    makeInnovativeOutcomesResponseData
} from "../../../../selectors/innovative_student/assignment.selector";
import ChartDonutCellRenderer from "../../common/table/ChartDonutCellRenderer";
import {resolvePropertiesFromSettingArray} from "../../../../utils/innovative/resolveSettings";
import {
    makeActingRoleResponseData,
    makeInnovativeMasterSettingByPath,
    makeInnovativeSettingByPath
} from "../../../../selectors/innovative_student/commonData.selector";
import {resolveCohortVisibility} from "../../../../utils/innovative_student/resolveSettings";


const OutcomeGridContainer = () => {

    // selectors
    const outcomes = useSelector(makeInnovativeOutcomesResponseData);
    const assignmentsLoading = useSelector(makeInnovativeAssignmentsLoading);
    const colorScheme = useSelector(makeInnovativeSettingByPath("colorScheme"));
    const actingRole = useSelector(makeActingRoleResponseData);
    const cohortVisibility = useSelector(makeInnovativeMasterSettingByPath("features.cohortVisibility"));

    const resolveCohortVisible = useCallback(() => {
        return resolveCohortVisibility(actingRole, cohortVisibility, {compareVal: "role", returnVal: "access"})
    }, [actingRole, cohortVisibility]);

    const isCohortVisible = resolveCohortVisible();

    // column definition for the table
    const getColumnDefinition = () => {
        return [
            {
                title: "Outcome",
                dataIndex: "name",
                render: (value, row) => <OutcomeTitleCell
                    title={row.title}
                    description={row.description}
                />,
            },
            {
                title: "Student's Results",
                dataIndex: "student_outcome",
                render: (value, row) => <OutcomeCellCard
                    outcome={row}
                />,
            },
            {
                title: "Cohort Proficiency Ratings",
                dataIndex: "proficiencyCohort",
                width: "25%",
                hidden: !isCohortVisible,
                render: (data) => <ChartDonutCellRenderer
                    data={data}
                    settings={colorScheme.proficiencyRatingForOutcomes}
                    customOptions={{chart:{width:280, height: 120, marginTop:20}}}
                    propMapping={[0, "description"]}
                    resolver={resolvePropertiesFromSettingArray}
                    customClasses="outcome-chart"
                />
            },
            {
                title: "Cohort Mastery Status",
                dataIndex: "masteryCohort",
                width: "25%",
                hidden: !isCohortVisible,
                render: (data) => <ChartDonutCellRenderer
                    data={data}
                    settings={colorScheme.masteryStatus}
                    customOptions={{chart:{width:280, height: 120, marginTop:20}}}
                    propMapping={[0, "status"]}
                    resolver={resolvePropertiesFromSettingArray}
                    customClasses="outcome-chart"
                />
            },


        ].filter(item => !item.hidden);
    }
    return (
        <OutcomeGridView
            isLoading={assignmentsLoading}
            columns={getColumnDefinition()}
            dataSource={outcomes}
        />
    )
}
OutcomeGridContainer.propTypes = {

};



export default OutcomeGridContainer;