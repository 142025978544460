import _get from "lodash/get";

// return dashboard manage data
export const getDashboardData = state => _get(state, 'dashboardManage.dashboard');

// return selected dashboard
export const getSelectedDashboard = state => {
    const result = getDashboardData(state);
    return result && result.selected;
};

// return dashboard list data
export const getDashboardListData = state => {
    const result = getDashboardData(state);
    return result && result.list;
};

// return credential management data
export const getCredentialManagementData = state => {
    const result = getDashboardData(state);
    return result && result.credentialsManagement;
};

// return user roles data
export const getUserRolesData = state => {
    const result = getDashboardData(state);
    return result && result.userRoles;
};

// return shared user roles data
export const getSharedUserRolesData = state => {
    const result = getUserRolesData(state);
    return result && result.data && result.data.dashboardSharedRoles;
};

// return share dashboard loading
export const getShareDashboardLoading = state => {
    const result = getDashboardData(state);
    return result && result.shareDashboard.isLoading;
};

// return save dashboard loading
export const getSaveDashboardLoading = state => {
    const result = getDashboardData(state);
    return result && result.saveDashboard.isLoading;
};

// return schoolId
export const getSchoolId = state => {
    const result = getDashboardData(state);
    return result && result.list && result.list.data.length && result.list.data[0].school;
};

// return save dashboard loading
export const getBaseInfo = state => {
    const result = getDashboardData(state);
    return result && result.baseInfo.response;
};