import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const LineChart = ({ chartOptions }) => {
  const defaultConfig = {
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
  };

  const config = Highcharts.merge(defaultConfig, chartOptions);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} immutable />
    </div>
  );
};

LineChart.propTypes = {
  chartOptions: PropTypes.object.isRequired,
};

export default LineChart;
