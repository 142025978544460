import _get from "lodash/get";

// return vw dealership response
export const makeVwDealershipResponse = state => _get(state, 'vwDealership.dealershipMain');

// return vw dealership sidebar data response
export const makeVwDealershipSidebarDataResponse = state => {
    const result = makeVwDealershipResponse(state);
    return result && result.sidebar;
};

// return vw dealership sidebar lists data response
export const makeVwDealershipSidebarListsDataResponse = state => {
    const result = makeVwDealershipSidebarDataResponse(state);
    return result && result.lists;
};

// return vw dealership sidebar lists data dealerships response
export const makeVwDealershipSidebarListsDataDealershipsResponse = state => {
    const result = makeVwDealershipSidebarListsDataResponse(state);
    return result && _get(result, 'data.dealerships');
};

// return vw dealership sidebar filtered data response
export const makeVwDealershipSidebarFilteredDataResponse = state => {
    const result = makeVwDealershipSidebarDataResponse(state);
    return result && _get(result, 'filtered.data');
}

// return vw dealership sidebar selected data response
export const makeVwDealershipSidebarSelectDataResponse = state => {
    const result = makeVwDealershipSidebarDataResponse(state);
    return result && _get(result, 'selected.data');
}

// return vw dealership main report data response
export const makeVwDealershipMainReportDataResponse = state => {
    const result = makeVwDealershipResponse(state);
    return result && result.mainReport;
};

// return vw dealership main report data response Loading
export const makeVwDealershipMainReportDataResponseLoading = state => {
    const result = makeVwDealershipMainReportDataResponse(state);
    return result && result.isLoading;
};

// return vw dealership main report data response data
export const makeVwDealershipMainReportDataResponseData = state => {
    const result = makeVwDealershipMainReportDataResponse(state);
    return result && result.data;
};

// return vw dealership main report data department response data
export const makeVwDealershipMainReportDataDepartmentResponseData = state => {
    const result = makeVwDealershipMainReportDataResponseData(state);
    return result && result.departments;
};

// return vw dealership main report data department response data
export const makeVwDealershipMainReportDataPathwayFilterResponseData = key => state => {
    const result = makeVwDealershipMainReportDataResponseData(state);
    return result && result[key];
};