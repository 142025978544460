import React from "react";
import { Button, Row, Space } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

const UserActionButtons = ({
  userData,
  onDeleteAction,
  setEnableSideDrawer,
  setSelectedUsersArray,
  disabled = false,
}) => {
  return (
    <Row justify={"end"}>
      <Space>
        <Button
          type="primary"
          ghost
          onClick={() => {
            setEnableSideDrawer(true);
            setSelectedUsersArray([userData])
          }}
          disabled={disabled}
        >
          Course Permission
        </Button>
        <Button
          disabled={disabled}
          onClick={() => { onDeleteAction(userData) }}
          danger
          ghost
          icon={<DeleteOutlined />}
        />
      </Space>
    </Row>
  )
}

export default UserActionButtons;
