import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Form } from "antd";
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { updateDeveloperKey } from "../../../../../../actions/dashboardManagement/dashboard.action";
import PopUpModal from "../../../../../common/popup-modal/popup-modal";

const UpdateDevKeyModal = ({ setIsModalVisible, isModalVisible }) => {
    const dispatch = useDispatch();

    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const OnUpdateDeveloperKey = () => {
        const payload = {
            clientId: clientId,
            clientSecret: clientSecret
        }
        dispatch(updateDeveloperKey(payload))
    }

    return (
        <PopUpModal
            title={"Add Developer API Key"}
            okText={"OK"}
            onPressOk={OnUpdateDeveloperKey}
            cancelText={"Cancel"}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
        >

            <div className="addDeveloperKey">
                <Form.Item name="Client ID" label="Client ID" >
                    <input
                        type="text"
                        name="clientId"
                        onChange={(event) => {
                            setClientId(event.target.value);
                        }}
                        className="ant-input"
                    />
                </Form.Item>
                <Form.Item name="Client Secret" label="Client Secret" >
                    <input
                        type="text"
                        name="clientSecret"
                        onChange={(event) => {
                            setClientSecret(event.target.value);
                        }}
                        className="ant-input"
                    />
                </Form.Item>
                <Row className="instruction-card" wrap={false}>
                    <div className="instruction-card-icon">
                        <InfoCircleOutlined />
                    </div>
                    <p className="instruction-card-info">
                        {`Click "+ Add Token" to start. Name the token as you prefer and add the expiration date matching Canvas creation. Place the generated access token in the "Account Token" section. Finish by clicking "Okay" to complete the token addition.`
                        }
                    </p>
                </Row>
            </div>
        </PopUpModal>
    )
}

export default UpdateDevKeyModal;
