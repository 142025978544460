import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Space, Switch, Table } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import {
  getLastRestrictCoursesSearchValue,
  getRestrictedCourseLoadingState,
  getRestrictedCourses,
} from '../../../../../../selectors/userManagement/userManagement.selector';
import {
  getUserManagementCourses,
} from '../../../../../../actions/userManagement/userManagement.action';
import _debounce from "lodash/debounce";
import { get as _get } from 'lodash';
import Loader from "../../../../../../components/dashboardCommon/Loader";
import { dashboardConst } from '../../../../../../constant/dashboardManagement/commonDashboardConst';

const RestrictedCourses = ({
  selectedUsersArray = [],
  onSwitchChange,
  searchCourseText,
  currentSelectedTab,
  previousSearchCourseText,
  fetchCourseData,
}) => {
  const restrictedCourses = useSelector(getRestrictedCourses);
  const tableDataLoading = useSelector(getRestrictedCourseLoadingState);
  const lastSearchQuery = useSelector(getLastRestrictCoursesSearchValue);
  const accountId = _get(selectedUsersArray[0], "account_id", null);

  const dispatch = useDispatch();

  const onCourseSearch = useCallback(_debounce(({ value }) => {
    if (value.length > dashboardConst.minSearchTextLength) {
      const userIds = selectedUsersArray.map(user => user.user_id);
      const payload = {
        searchValue: value,
        accountId,
        userIds,
        tab: dashboardConst.tabKey.restrict,
        ...dashboardConst.initialPagination,
      };

      dispatch(getUserManagementCourses(payload));
    }
  }, 500), [selectedUsersArray]);

  useEffect(() => {
    if (
      currentSelectedTab === dashboardConst.tabKey.restrict &&
      previousSearchCourseText !== searchCourseText &&
      lastSearchQuery !== searchCourseText
    ) {
      onCourseSearch({ value: searchCourseText });
    }
  }, [searchCourseText, currentSelectedTab, previousSearchCourseText]);

  const onPaginationChange = (page, pageSize) => {
    fetchCourseData({
      pageLimit: pageSize,
      pageNo: page,
      tab: dashboardConst.tabKey.restrict,
      searchValue: searchCourseText,
    });
  }

  const renderCourseComponent = (_, record) => {
    return (
      <Row justify={"space-between"}>
        {record.courseName}
        <Space>
          <div className='restricted-course-label'>
            <Space>
              <LockOutlined />
              <div>
                Access Restricted
              </div>
            </Space>
          </div>
          <Switch
            onChange={checked => { onSwitchChange({ checked, record }) }}
          />
        </Space>
      </Row>
    );
  }

  const columns = [
    {
      dataIndex: 'courseId',
      render: renderCourseComponent,
    },
  ];

  return (
    <div>
      <Loader isLoading={tableDataLoading} />
      <Table
        columns={columns}
        dataSource={restrictedCourses.data}
        pagination={{
          onChange: onPaginationChange,
          total: restrictedCourses.count,
          pageSizeOptions: dashboardConst.contentPerPage,
        }}
      />
    </div>
  )
}

export default RestrictedCourses;
