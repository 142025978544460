import {
    JR_GEN_COMPLIANCE_CARDS_DATA_FETCH,
    JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH,
    JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH,
    JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
    JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH,
    JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING
} from "../../../constant/actionTypes";

const defaultState = {
    cards: {
        isLoading: false,
        data: {}
    },
    students: {
        isLoading: false,
        data: {}
    },
    overview: {
        isLoading: false,
        data: {
            lineChartData: null,
            barChartData: null,
            pieChartData: null
        }
    },
    durationToExpire: {
        isLoading: false,
        data: {}
    },
    fileNotes: {
        isLoading: false,
        data: {}
    },
}

const raComplianceReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_COMPLIANCE_CARDS_DATA_FETCH:
        case JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    ...action.payload
                }
            }
        case JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH:
        case JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    ...action.payload
                }
            }
        case JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH:
        case JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    ...action.payload
                }
            }
        case JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH:
        case JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING:
            return {
                ...state,
                durationToExpire: {
                    ...state.durationToExpire,
                    ...action.payload
                }
            }
        case JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH:
        case JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING:
            return {
                ...state,
                fileNotes: {
                    ...state.fileNotes,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default raComplianceReducer;