import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useLocation} from "react-router";
import { startCase } from 'lodash';
import {fetchStudent, fetchStudentsReport, fetchStudentsTerms, clearStudentReportData, fetchStudentsChartData, clearStudentReportChartData} from "../../../actions/innovative/student.action";
import {
    makeInnovativeStudentMainReportLoading,
    makeInnovativeStudentMainReportResponseData,
    makeInnovativeStudentResponseData, makeInnovativeStudentTermsLoading, makeInnovativeStudentTermsResponseData,
    makeInnovativeStudentGaugeDataResponse, makeInnovativeStudentMainReportCourseComparisonResponseData
} from "../../../selectors/innovative/student.selector";
import StudentProfileView from "./StudentProfileView";
import { fetchCourse, fetchingClusterData, resetClusterData } from "../../../actions/innovative/course.action";
import { 
    getIsApplicationAdmin, 
    makeInnovativeCourseResponseData, 
    makeInnovativeCoursesMainReportClusterStatusResponseData 
} from "../../../selectors/innovative/course.selector";
import { setBreadcrumb } from "../../../actions/innovative/layout.action";
import useDidMountEffect from "../../../utils/innovative/customHooks";
import {getAccessToken, queryStringToObject} from "../../../utils/general";
import impressionHandler from "../../../utils/impressionHandler/impressionHandler";
import {fetchSettings} from "../../../actions/innovative/settings.action";
import ChartDataFetch from "./chartDataFetch";
import { ENROLLMENT_STATUSES } from "../../../utils/innovative/constants";
import { makeInnovativeSettingsClusterEnableData } from '../../../selectors/innovative/setting.selector';
import session from '../../../utils/session';

const ADMIN_PATH = "/insights/admin";

const StudentProfileContainer = () => {
    const {studentId, courseId, termId} = useParams();
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [selectedTablePagination, setSelectedTablePagination] = useState(0);
    const [switchFormValues, setSwitchFormValues] = useState({published:true, unpublished:false});
    const [chartDataController, setChartDataController] = useState(null)
    const [selectedEnrollment, setSelectedEnrollment] = useState(ENROLLMENT_STATUSES[0]);
    const control = useRef()

    const parsedUrl = new URL(window.location.href);
    const isAdminPath = parsedUrl.pathname.split("/teacher")[0] === ADMIN_PATH;

    const dispatch = useDispatch();
    const search = queryStringToObject(useLocation().search);
    const [isClusteringDataAvailable, setClusteringDataAvailable] = useState(false);

    // selectors
    const student = useSelector(makeInnovativeStudentResponseData);
    const selectedCourse = useSelector(makeInnovativeCourseResponseData);
    const studentReport = useSelector(makeInnovativeStudentMainReportResponseData);
    const studentReportLoading = useSelector(makeInnovativeStudentMainReportLoading);
    const terms = useSelector(makeInnovativeStudentTermsResponseData);
    const termsLoading = useSelector(makeInnovativeStudentTermsLoading);
    const gaugeData = useSelector(makeInnovativeStudentGaugeDataResponse);
    const courseComparison = useSelector(makeInnovativeStudentMainReportCourseComparisonResponseData);
    
    const clusterStatus = useSelector(makeInnovativeCoursesMainReportClusterStatusResponseData);
    const isClusteringEnabled = useSelector(makeInnovativeSettingsClusterEnableData);
    const innovativeMetaData = session.get(session.keys.innovativeMeta);
    const isApplicationAdminData = useSelector(getIsApplicationAdmin);

    const sideChartsConfigs = [
        { title: "Overall Course Grades" },
        { title: "Overall Task Submissions" },
    ];

    const entrollmentOptions =  ENROLLMENT_STATUSES.map(status => {
        return {name: startCase(status), _id: status};
    });

    const termChange = (e) => {
        abortCallsClearChartData();

        dispatch(fetchStudentsReport({
            requestParams: {
                "course_id": courseId,
                "student_id": studentId,
                "term_ids": [e],
                "course_states": Object.keys(switchFormValues).filter(key => switchFormValues[key] === true && key),
                "enrollment_status": selectedEnrollment,
            }
        }));
        setSelectedTerm([e])
    }

    const onEnrollmentChanged = value => {
        setSelectedEnrollment(value);
        abortCallsClearChartData();

        dispatch(fetchStudentsReport({
            requestParams: {
                "course_id": courseId,
                "student_id": studentId,
                "term_ids": selectedTerm ? selectedTerm : [terms[0]._id],
                "course_states": Object.keys(switchFormValues).filter(key => switchFormValues[key] === true && key),
                "enrollment_status": value,
            }
        }));
    };

    // component did mount calls
    useEffect(() => {
        // Set impression data
        impressionHandler.sendImpressionData("innovative_students")

        // fetch settings for the course
        dispatch(fetchSettings({
            requestParams: {
                "subject_id": courseId,
            }
        }));

        dispatch(fetchStudentsTerms({"student_id": studentId}));

        dispatch(
            fetchStudent({
                requestParams: {
                    course_id: courseId,
                    student_id: studentId,
                },
            })
        );
        dispatch(
            fetchCourse({
                requestParams: {
                    course_id: courseId,
                    termId: [termId],
                },
            })
        );
    }, []);

    useEffect(() => {
        // create abort controller instance for abort fetch calls
        control.current = new AbortController();

        dispatch(clearStudentReportData())
        dispatch(clearStudentReportChartData())

        return () => {
            // component will unmount
            control.current.abort()
        };
    }, []);

    useEffect(() => {
        // initiate fetching chart data after main report data loaded
        const courseIds = courseComparison.map(course => course.course_id);
        if (
            control.current &&
            courseComparison.length &&
            !studentReportLoading &&
            courseIds.length > 0
        ) {
            fetchChartData(courseIds);
        }
    }, [control.current, courseComparison, studentReportLoading])


    const onChangeCourseState = (states) => {
        abortCallsClearChartData();

        dispatch(fetchStudentsReport({
            requestParams: {
                "course_id": courseId,
                "student_id": studentId,
                "term_ids": selectedTerm ? selectedTerm : [terms[0]._id],
                "course_states": states,
                "enrollment_status": selectedEnrollment,
            }
        }));
    }

    useDidMountEffect(() => {
        if(terms && terms[0]){
            const selectedTerm = terms.find(term => term._id === termId);
            setSelectedTerm(selectedTerm ? [termId] : [terms[0]._id])
            dispatch(fetchStudentsReport({
                requestParams: {
                    "course_id": courseId,
                    "student_id": studentId,
                    "term_ids": selectedTerm ? [termId] : [terms[0]._id],
                    "course_states": ["published"],
                    "enrollment_status": selectedEnrollment,

                }
            }));
        }
    }, [terms]);

    const getSearchQuery = () => {
        return `?token=${innovativeMetaData.token}&t=${innovativeMetaData.t}&access=${getAccessToken()}&actAs=${isApplicationAdminData.role}`;
    };

    // setting breadcrumb
    useEffect(() => {
        if (student && selectedCourse) {
            const breadCrumb = [
                {
                    key: "home", 
                    section: "Home", 
                    href: isAdminPath ? "/insights/admin/courses" : "/insights/teacher/courses", 
                    searchQuery: getSearchQuery(),
                },
                { 
                    key: "course", 
                    section: selectedCourse["course_name"],
                    href: isAdminPath ? "/insights/admin/courses" : "/insights/teacher/courses",
                    searchQuery: getSearchQuery(),
                },
                { key: "student", section: student["student_name"] },
            ];
            dispatch(setBreadcrumb(breadCrumb));
        }
    }, [student, selectedCourse]);

    useEffect(() => {
        if(gaugeData && gaugeData.length > 0 && chartDataController){
            chartDataController.validateArray(gaugeData.map(data => data.courseId), selectedTablePagination)
        }
    }, [gaugeData, selectedTablePagination])

    const fetchChartData = async (courseIds) => {
        // create object of data handler and initiate chart data fetching
        const chartController = new ChartDataFetch(process.env.REACT_APP_COHORT_CHART_FETCH_ARRAY_SIZE, courseIds, dispatchChartData);
        setChartDataController(chartController);
        chartController.initiateFetch();
    }

    const dispatchChartData = (course) => {
        const data = {
            "course_ids": [course],
            "student_id": studentId,
            "term_ids": selectedTerm ? [termId] : [terms[0]._id],
            "course_states": ["published"]
        }
        dispatch(fetchStudentsChartData({courseId: course, ...data}, control.current.signal)).then(() => {}).catch(() => {})
    }

    const abortChartFetchCalls = () => {
        control.current.abort()

        control.current = new AbortController();

    }

    const abortCallsClearChartData = () => {
        // remove ongoing chart data fetching calls
        abortChartFetchCalls();
        // clear current fetched chart data
        dispatch(clearStudentReportChartData());
    }

    useEffect(()=>{
        if(clusterStatus){
          switch(clusterStatus.status){
            case 102: {
              dispatch(resetClusterData());
              dispatch(fetchingClusterData())
              setClusteringDataAvailable(true);
              return;
            }
            case 204: {
              setClusteringDataAvailable(false);
              return;
            }
            default: {
              setClusteringDataAvailable(false);
              return;
            }
          }
        }
      },[clusterStatus])

    return <StudentProfileView
        studentsReport={studentReport}
        studentsReportLoading={studentReportLoading}
        student={student}
        studentId={studentId}
        sideChartsConfigs={sideChartsConfigs}
        terms={terms}
        termsLoading={termsLoading}
        termChange={termChange}
        selectedTerm={selectedTerm}
        currentViewTab={search.current}
        onChangeCourseState={onChangeCourseState}
        switchFormValues={switchFormValues}
        setSwitchFormValues={setSwitchFormValues}
        entrollmentOptions={entrollmentOptions}
        selectedEnrollment={selectedEnrollment}
        onEnrollmentChanged={onEnrollmentChanged}
        clusteringEnabled={isClusteringEnabled}
        isClusteringDataAvailable={isClusteringDataAvailable}
        setSelectedTablePagination={setSelectedTablePagination}
    />
};

export default StudentProfileContainer;
