import React from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  addUser,
  fetchDashboardUsers,
} from '../../../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action';

import UserModal from './UserModal';

const UserModalContainer = ({ handleOk, handleCancel, ...rest }) => {
  const [userForm] = Form.useForm();
  const dispatch = useDispatch();

  const postProcessUsers = () => {
    handleCancel();
    userForm.resetFields();
    dispatch(fetchDashboardUsers());
  };

  const handleFormSubmit = values => {
    dispatch(addUser(values, postProcessUsers));
  };

  const onFormSubmit = () => {
    userForm
      .validateFields()
      .then(values => {
        handleFormSubmit(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const onHandleCancel = () => {
    userForm.resetFields();
    handleCancel();
  };

  return (
    <UserModal
      {...rest}
      form={userForm}
      handleOk={onFormSubmit}
      handleCancel={onHandleCancel}
    />
  );
};

UserModalContainer.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default UserModalContainer;
