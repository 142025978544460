import React from "react";
import {Switch, Card, Row, Col, Typography, Form} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import Proptypes from 'prop-types';

const TwoFaAuthentication = ({name}) => {

    return (
        <Card className="authCard">
            <Row justify="space-between">
                <Col span={24}>
                   <div className="custIcon">
                       <FontAwesomeIcon  icon={faLock} />
                    </div> 
                    &nbsp; Enable 2 Factor Authentication
                </Col>

            </Row>
            <Form.Item name={name} valuePropName="checked" className="twofactorSwitch">
                <Switch/>
            </Form.Item>
        </Card>
    )

}

TwoFaAuthentication.defaultProps = {
    name: "2faAuth"
}

TwoFaAuthentication.propTypes = {
    name: Proptypes.string
}

export default TwoFaAuthentication;