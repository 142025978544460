export default {
    profile: async function (filters,token) {
      try {
        console.info(
          `Fetch Student Profile >> ${process.env.REACT_APP_APIURL}studentprofile/student/${filters.logged_in}/${filters.studentId}/${filters.course}/${filters.access}`
        );
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/student/${filters.logged_in}/${filters.studentId}/${filters.course}/${filters.access}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e);
      }
    },
    activityByTime: async function (filters, token) {
        try {
          console.info(
            `Fetch Activity By Time >> ${process.env.REACT_APP_APIURL}studentprofile/activity_by_time_acc/data/`
          );
          return fetch(process.env.REACT_APP_APIURL + "studentprofile/activity_by_time_acc/data/", {
            credentials: "include",
            method: 'POST',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ courseId: filters.courseId, year: filters.year, mode: filters.mode, studentId: filters.studentId })
      
          })
        } catch (e) {
          console.log(e);
        }
    },
    studentSubjectAnalysis: async function (filters, token) {
      try {
        // console.info(
        //   `Fetch Subject Analysis >> ${process.env.REACT_APP_APIURL}studentprofile/subject_analysis_for_student/data/${filters.access}`
        // );
        return fetch(process.env.REACT_APP_APIURL + `studentprofile/subject_analysis_for_student/data/${filters.access}`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ term: filters.term, courseId: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel, status: filters.status, semester: filters.semester })
        })
      } catch (e) {
        console.log(e);
      }
    },
    latestPatMarks: async function (filters, token) {
        try {
          console.info(
            `Fetch Latest Pat >> ${process.env.REACT_APP_APIURL}studentprofile/latest_pat_marks/data/`
          );
          return fetch(process.env.REACT_APP_APIURL + "studentprofile/latest_pat_marks/data/", {
            credentials: "include",
            method: 'POST',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ courseId: filters.courseId, studentId: filters.studentId })
          })
        } catch (e) {
          console.log(e);
        }
    },
    latestNaPlanMarks: async function (filters, token) {
        try {
          console.info(
            `Fetch Latest Na Plan >> ${process.env.REACT_APP_APIURL}studentprofile/latest_naplan_marks/data/`
          );
          return fetch(process.env.REACT_APP_APIURL + "studentprofile/latest_naplan_marks/data/", {
            credentials: "include",
            method: 'POST',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ courseId: filters.courseId, studentId: filters.studentId })
          })
        } catch (e) {
          console.log(e);
        }
    },
    CourseAnalysisByAssignment: async function (filters, token) {
      try {
        console.info(
          `Fetch Course Analysis By assignment >> ${process.env.REACT_APP_APIURL}studentprofile/course_analysis_by_assignment/data/`
        );
        return fetch(process.env.REACT_APP_APIURL + "studentprofile/course_analysis_by_assignment/data/", {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ courseIds: filters.courseIds, studentId: filters.studentId, term: filters.term, semester: filters.semester })
        })
      } catch (e) {
        console.log(e);
      }
    },
    marksByAssignment: async function (filters, token) {
      try {
        console.info(
          `Fetch marks by assignment >> ${process.env.REACT_APP_APIURL}studentprofile/marks_by_assignment_acc/data/${filters.access}`
        );
        return fetch(process.env.REACT_APP_APIURL + `studentprofile/marks_by_assignment_acc/data/${filters.access}`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ courseId: filters.courseId, studentId: filters.studentId, term: filters.term, semester: filters.semester  })
        })
      } catch (e) {
        console.log(e);
      }
    },
    activityByTime: async function (filters, token) {
        try {
          console.info(
            `Fetch Activity By Time >> ${process.env.REACT_APP_APIURL}studentprofile/activity_by_time_acc/data/${filters.access}`
          );
          return fetch(process.env.REACT_APP_APIURL + `studentprofile/activity_by_time_acc/data/${filters.access}`, {
            credentials: "include",
            method: 'POST',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ courseId: filters.courseId, studentId: filters.studentId, year: filters.year, mode: filters.mode, semester: filters.semester })
      
          })
        } catch (e) {
          console.log(e);
        }
    },
    studentFeedbacks: async function (filters, token) {
      try {
        console.info(
          `Fetch Feedbacks >> ${process.env.REACT_APP_APIURL}studentprofile/feedback/data/${filters.access}`
        );
        return fetch(process.env.REACT_APP_APIURL + `studentprofile/feedback/data/${filters.access}`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify(filters)
    
        })
      } catch (e) {
        console.log(e);
      }
    },
    workLoad: async function (filters, token){
      try{
        console.info(
          `Fetch WorkLoad Data >> ${process.env.REACT_APP_APIURL}studentprofile/workload/data/`
        );
        return fetch(process.env.REACT_APP_APIURL + "studentprofile/workload/data/", {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ term: filters.term, courseIds: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel })
    
        })
      } catch (e) {
        console.log(e);
      }
    },
    subjectAnalysis: async function (filters, token) {
      try{
        console.info(
          `Fetch Subject Analysis >> ${process.env.REACT_APP_APIURL}studentprofile/subject_analysis/data/${filters.access}`
        );
        return fetch(process.env.REACT_APP_APIURL + `studentprofile/subject_analysis/data/${filters.access}`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ term: filters.term, courseId: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel, status: filters.status, semester: filters.semester })
    
        })
      } catch (e) {
        console.log(e);
      }
    },
    submissionData: async function (filters, token) {
      try {
        console.info(
          `Fetch Submission Data >> ${process.env.REACT_APP_APIURL}studentprofile/submissionsnew/data/2`
        );
        return fetch(process.env.REACT_APP_APIURL + "studentprofile/submissionsnew/data/2", {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ term: filters.term, courseIds: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel, semester: filters.semester })
    
        })
      } catch (e) {
        console.log(e);
      }
    },
    submissionDetails: async function (filters, token){
        try{
          console.info(
            `Fetch Submission Details >> ${process.env.REACT_APP_APIURL}studentprofile/submissionsnew/details/`
          );
          return fetch(process.env.REACT_APP_APIURL + "studentprofile/submissionsnew/details/", {
            credentials: "include",
            method: 'POST',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ term: filters.term, courseId: filters.courseId, studentId: filters.studentId, yearLevel: filters.yearLevel, semester: filters.semester })
      
          })
        } catch (e) {
          console.log(e);
        }
    },
    lateSubmissionData: async function (filters, token){
      try{
        console.info(
          `Fetch Late Submission Details >> ${process.env.REACT_APP_APIURL}studentprofile/late_submission/data/`
        );
        return fetch(process.env.REACT_APP_APIURL + "studentprofile/late_submission/data/", {
          credentials: "include",
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' },
          body: JSON.stringify({ term: filters.term, courseIds: filters.courseIds, studentId: filters.studentId, semester: filters.semester })
    
        })
      } catch (e) {
        console.log(e);
      }
    },
    studentProfile: async function (filters, token) {
      try {
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/individualStudentProfile/${filters.logged_in}/${filters.studentId}/${filters.access}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e);
      }
    }
}