import serviceMaker from "../../../service";
import session from "../../../session";

// THIS IS A REDIRECTION HANDLER FOR THIS SERVICE.
const redirectionHandler = (status) => {
    switch (status){
        case 401:
            window.location.href = `${window.location.origin}/tenant/login`;
    }
}

const make = async (requestOptions) => {
    return new Promise((resolve, reject) => {
        serviceMaker.make({
            ...requestOptions,
            metaKey: session.keys.vwDealershipMeta,
            // THIS IS HOW WE ADD REDIRECTION HANDLER TO THE SERVICE.
            redirectionHandler: (status) => redirectionHandler(status),
        }).then(response => resolve(response)).catch(error => reject(error))
    })
}

const service = {
    make,
};

export default service;