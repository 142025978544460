import React, { useState, useEffect, useCallback } from 'react';
import { Row, Checkbox, Button, Input } from 'antd';
import Proptypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { debounce as _debounce, get as _get } from 'lodash';
import { usePrevious } from '../../../../../utils/innovative/customHooks';

const TableColumnFilterDropdown = props => {
  const {
    filters,
    selectedKeys,
    setSelectedKeys,
    confirm,
    clearFilters,
    columnIndex,
    setColumnFilterSearch,
    columnFilterSearch,
    visible,
    assignmentsList,
  } = props;

  const [search, setSearch] = useState('');
  const [filteredSelectedKeys, setFilteredSelectedKeys] = useState([]);
  const filterSearchValue = columnFilterSearch[columnIndex];
  const prevValues = usePrevious({ visible, assignmentsList });

  useEffect(() => {
    setSearch(filterSearchValue);

    if (!filterSearchValue) {
      setFilteredSelectedKeys(selectedKeys);
      return;
    }
    const filtered = selectedKeys.filter(value =>
      value.toLowerCase().includes(filterSearchValue.toLowerCase()),
    );
    setFilteredSelectedKeys(filtered);
  }, [filterSearchValue, selectedKeys]);

  useEffect(() => {
    const preVisibleStatus = _get(prevValues, 'visible', false);

    if (preVisibleStatus && !visible) {
      confirm();
    }
  }, [prevValues, visible, confirm]);

  useEffect(() => {
    const prevList = _get(prevValues, 'assignmentsList', []);

    if (
      prevList.length &&
      JSON.stringify(prevList) !== JSON.stringify(assignmentsList)
    ) {
      clearFilters();
      setSearch('');
      debouncedSearch('');
    }
  }, [prevValues, assignmentsList, clearFilters]);

  const checked = filteredSelectedKeys.length === filters.length;
  let indeterminate = !!filteredSelectedKeys.length;

  if (checked) {
    indeterminate = false;
  }

  const onFilterSelectAll = event => {
    event.stopPropagation();
    if (checked) {
      const filtered = selectedKeys.filter(
        datum => !datum.toLowerCase().includes(search.toLowerCase()),
      );
      const deselected = search ? filtered : [];
      setSelectedKeys(deselected);
      return;
    }

    const values = filters.map(filter => filter.value);
    setSelectedKeys(values);
  };

  const getDropdownOptions = () =>
    filters.map(filter => {
      const itemChecked = filteredSelectedKeys.includes(filter.value);
      const className = checked
        ? 'table-filter-dropdown-item active'
        : 'table-filter-dropdown-item';
      return (
        <div
          className={className}
          key={filter.value}
          onClick={event => {
            filterItemChanged(event, filter.value);
          }}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <Checkbox checked={itemChecked} />
          <span>{filter.text}</span>
        </div>
      );
    });

  const filterItemChanged = (event, value) => {
    event.stopPropagation();
    if (selectedKeys.includes(value)) {
      const filtered = selectedKeys.filter(item => item !== value);
      setSelectedKeys(filtered);
      return;
    }

    setSelectedKeys([...selectedKeys, value]);
  };

  const onSearch = event => {
    const { value } = event.target;

    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    _debounce(value => {
      setColumnFilterSearch(prevState => ({
        ...prevState,
        [columnIndex]: value,
      }));
    }, 500),
    [],
  );

  const getDropDownMainContent = () => {
    if (!filters.length) {
      return <p className="tabel-filter-no-data">No Data</p>;
    }

    return (
      <>
        <div
          onClick={onFilterSelectAll}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          className="table-select-all"
        >
          <Checkbox
            className="select-all-check-box"
            checked={checked}
            indeterminate={indeterminate}
          />
          <span>Select All</span>
        </div>
        <div className="dropdown-scroll">
          <>{getDropdownOptions()}</>
        </div>
        <Row className="table-filter-dropdown-btn-section">
          <Button
            onClick={() => clearFilters()}
            size="small"
            ghost
            disabled={!filteredSelectedKeys.length}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            className="filterApply"
            disabled={!filters.length}
          >
            OK
          </Button>
        </Row>
      </>
    );
  };

  return (
    <div className="ant-dropdown-custom">
      <Row className="table-filter-search-row">
        <Input
          className="search-input"
          placeholder="Search in filters"
          value={search}
          onChange={onSearch}
          prefix={<SearchOutlined />}
        />
      </Row>
      {getDropDownMainContent()}
    </div>
  );
};

TableColumnFilterDropdown.propTypes = {
  filters: Proptypes.array,
  selectedKeys: Proptypes.array,
  setSelectedKeys: Proptypes.func,
  confirm: Proptypes.func,
  clearFilters: Proptypes.func,
  columnIndex: Proptypes.string,
  setColumnFilterSearch: Proptypes.func,
  columnFilterSearch: Proptypes.object,
  visible: Proptypes.bool,
  assignmentsList: Proptypes.array,
};

export default TableColumnFilterDropdown;
