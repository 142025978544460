export const topCardPieChartConf = {
    legend: {
        align: "left",
        verticalAlign: "middle",
        layout: 'vertical',
        margin:8,
        width: "50%",
        // labelFormatter: function () {
        //     return `${this.name} ${Math.round(this.percentage)} %`;
        // }
    },

    chart: {
        height: "150px",
    },

    plotOptions: {
        pie: {
            size: "100%",
            innerSize: "60%",
            minSize: 90,
            slicedOffset:0,
            showInLegend: true
        }
    },

}