import React, { memo, useEffect, useState } from 'react';
import { Modal, Input, Table, Space } from 'antd';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { SearchOutlined } from '@ant-design/icons';

import Loader from '../../../../../common/layout/Loader';

const PopupTable = ({
  visible,
  onCancel,
  searchEnabled,
  searchableColumns,
  columns,
  dataSource,
  title,
  isLoading,
}) => {
  const [tableDataSource, setTableDataSource] = useState([]);

  useEffect(() => {
    setTableDataSource(dataSource);
  }, [dataSource]);

  const renderTable = () => (
    <Table
      columns={columns}
      dataSource={tableDataSource}
      scroll={{ x: 'max-content' }}
      className='popupTable'
    />
  );


  const onSearch = event => {
    const { value } = event.target;
    if (value === '') return setTableDataSource(dataSource);

    if (searchableColumns && dataSource) {
      const filterted = dataSource.filter(source => {
        let valid = false;
        searchableColumns.forEach(col => {
          if (
            _get(source, col, '')
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            valid = true;
          }
        });
        return valid;
      });
      setTableDataSource(filterted);
    }
  };

  const renderSearch = () => {
    if (searchEnabled) {
      return (
        <Input
          addonAfter={<SearchOutlined />}
          onChange={onSearch}
          placeholder='Search by Student or Course'
        />
      );
    }
    return null;
  };

  return (
    // if no title received, the modal ui will be messy
    <Modal
      title={title}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      width={800}
    >
      <Loader isLoading={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {renderSearch()}
          {renderTable()}
        </Space>
      </Loader>
    </Modal>
  );
};

PopupTable.defaultProps = {
  title: 'Data',
};

PopupTable.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  searchEnabled: PropTypes.bool,
  searchableColumns: PropTypes.array,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default memo(PopupTable);
