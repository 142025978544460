import React, { Component , Fragment, useRef} from "react";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Xarrow from "react-xarrows";

import Chart from "react-apexcharts";
// require("highcharts/modules/exporting.js")(Highcharts);
// require("highcharts/modules/export-data.js")(Highcharts);
require("highcharts/modules/accessibility.js")(Highcharts);

HC_more(Highcharts);

class TestComponent extends Component {
  state = {
    chartOptions: {
        title: {
            text: '123456 Tool life by Location'
        },
        xAxis: {
            categories: ['T01', 'T02']
        },
        labels: {
            items: [{
                html: 'Some text',
                style: {
                    left: '50px',
                    top: '18px',
                    color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
                }
            }]
        },
        series: [{'data': [211, 550], 'type': 'column', 'name': 'Average'}]
    },
    options: {
        series: [
            {
              name: 'Females',
              data: [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4,
                -4.1, -4, -4.1, -3.4, -3.1, -2.8
              ]
            },
      {
        name: 'Males',
        data: [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5,
          3.9, 3.5, 3
        ]
      }
      ],
        chart: {
        type: 'rangeBar',
        height: 440,
        stacked: false
      },
      colors: ['#494444', '#9acd32'],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
          dataLabels: {
            position: 'top'
          }
      
        },
      },
      legend: {
          position: "top",
          horizontalAlign: "center",
        //   formatter: function(seriesName, opts) {
        //       return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
        //   }
      },
      dataLabels: {
        enabled: true,
        style: {
            colors: ['#333']
        },
        offsetX:30,
        formatter: function(val, opt) {
            console.log(opt);
            return val < 0 ? `(${Math.abs(val)})` : Math.abs(val);
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        show: false,
        min: -5,
        max: 5,
        title: {
          // text: 'Age',
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val
          }
        },
        y: {
          formatter: function (val) {
            return Math.abs(val)
          }
        }
      },
      title: {
        enabled: false
      },
      xaxis: {
        show: false,
        categories: ['85+', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59', '50-54',
          '45-49', '40-44', '35-39', '30-34', '25-29', '20-24', '15-19', '10-14', '5-9',
          '0-4'
        ],
        title: {
          text: 'Percent'
        },
        labels: {
          show: false,
          formatter: function (val) {
            return Math.abs(Math.round(val))
          }
        }
      },
      fill: {
        colors: [function({ value, seriesIndex, w }) {
          if(value > 2) {
              return '#9acd32'
          } else if (value >= 0.5) {
              return '#164666'
          } else {
              return '#000'
          }
        }]
      }
      },
      series: [
        {
          name: 'Females',
          data: [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4,
            -4.1, -4, -4.1, -3.4, -3.1, -2.8
          ]
        },{
      name: 'Males',
      data: [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5,
        3.9, 3.5, 3
      ]
    }
    ]
  };

  chartCallback = () => {
      console.log('running');
      for (var i = 0; i < Highcharts.charts.length; i++) {
        if (Highcharts.charts[i] !== undefined) {
            console.log(Highcharts.charts[i] );
          Highcharts.charts[i].reflow();
        }
      }
  }
  render() {
    const boxStyle = {
      border: "grey solid 2px",
      borderRadius: "10px",
      padding: "5px",
    };
    return (
    <Fragment>
            <HighchartsReact
                highcharts={Highcharts}
                options={this.state.chartOptions}
                callback = { this.chartCallback }
            />
            <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            // width="500"
            />
            <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }} >
              <div id="elem1" style={boxStyle}>
                hey
              </div>
              <p id="elem2" style={boxStyle}>
                hey2
              </p>
              <Xarrow
                start="elem1" //can be react ref
                end="elem2" //or an id
                path="smooth"
                color="#bdc7bf"
                // curveness={false}
                strokeWidth={20}
                headSize={2}
                label={{ start:"I'm start label",middle: "middleLabel",end:<div style={{ fontSize: "1.3em", fontFamily: "fantasy", fontStyle: "italic" }}>big end label</div> }}
              />
            </div>
    </Fragment>
    );
  }
}

export default TestComponent;
