import {
    FETCH_SETTING_SIDEBAR_DATA,
    FETCH_SETTING_SIDEBAR_DATA_LOADING,
    SET_OPEN_PANELS,
    SAVE_SETTING_UNITS_DATA,
    SAVE_SETTING_UNITS_DATA_LOADING,
    SET_EDIT_MODE,
    CLEAR_SETTING_UNITS_DATA,
    FETCH_SETTING_SIDEBAR_UNITS,
    SET_SELECTED_PROGRAM,
    FETCH_SETTING_SIDEBAR_UNITS_LOADING,
    SAVE_SETTING_UNITS_ORDER_DATA,
    SAVE_SETTING_UNITS_ORDER_DATA_LOADING,
} from "../../constant/actionTypes";

const settingsReducerDefaultState = {
    lists: {
        isLoading: false,
        error: false,
        dealerships: [],
        departments: [],
        roles: [],
    },
    units: {
        saveIsLoading: false,
        data: [],
    },
    openPanels: {
        main: "1",
        dealership: null,
        department: null,
        role: null,
        pathway: null,
        program: null,
    },
    order: {

    },
    editMode: false
};

const settingsReducer = (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_SETTING_SIDEBAR_DATA_LOADING:
        case FETCH_SETTING_SIDEBAR_DATA:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    ...action.payload
                },
                units: {
                    ...state.units,
                    ...(action.payload.units && { data: action.payload.units }),
                }
            }
        case SET_SELECTED_PROGRAM:
            return {
                ...state,
                selectedProgram: action.payload,
            }
        case SET_OPEN_PANELS:
            return {
                ...state,
                openPanels: {
                    ...state.openPanels,
                    ...action.payload
                }
            }
        case SET_EDIT_MODE:
            return {
                ...state,
                editMode: action.payload
            }
        case CLEAR_SETTING_UNITS_DATA:
            return {
                ...state,
                units: {
                    ...state.units,
                    data: []
                }
            }
        case FETCH_SETTING_SIDEBAR_UNITS_LOADING:
        case FETCH_SETTING_SIDEBAR_UNITS:
            return {
                ...state,
                order: { ...action.payload }
            }
        case SAVE_SETTING_UNITS_ORDER_DATA:
        case SAVE_SETTING_UNITS_ORDER_DATA_LOADING:
            return {
                ...state,
                order: {
                    ...state.order,
                    saveIsLoading: action.payload.isLoading,
                    ...(action.payload.newUnits && { programs: action.payload.newUnits })
                }

            }
        case SAVE_SETTING_UNITS_DATA:
        case SAVE_SETTING_UNITS_DATA_LOADING:
            return {
                ...state,
                units: {
                    ...state.units,
                    saveIsLoading: action.payload.isLoading,
                    ...(action.payload.newUnits && { data: action.payload.newUnits })
                }

            }
        default:
            return state;
    }
};

export default settingsReducer;