import _get from 'lodash/get';
import _has from 'lodash/has';
import _set from 'lodash/set';
import {
  VTT_SETTINGS_FETCH,
  VTT_SETTINGS_FETCH_LOADING,
  VTT_DATA_SOURCES_OPTIONS_FETCH,
  VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING,
  VTT_SETTINGS_ALTERNATION,
  VTT_SETTINGS_SAVE,
  VTT_SETTINGS_SAVE_LOADING,
  VTT_SET_DATA_SOURCES_SYNC_STATUS,
} from '../../../../constant/actionTypes';
import {
  settingsSections,
  syncStatuses,
} from '../../../../constant/vetTrack/analysis';

const defaultState = {
  selected: {
    isLoading: false,
  },
  saved: {
    isLoading: false,
  },
  data: {},
  drawerSelected: {
    isLoading: false,
    type: null,
    options: [],
  },
  syncData: {
    isSyncing: false,
    lastSyncStatus: syncStatuses.completed,
    lastSyncTime: '',
  },
};

const KEY = settingsSections.dataSources;

const dataSourcesReducer = (state = defaultState, action) => {
  const { path, section, payload, type } = action;

  switch (type) {
    case VTT_SETTINGS_FETCH_LOADING:
      if (section === KEY && path && _get(state, path, undefined)) {
        return { ..._set(state, path, payload) };
      }
      return state;

    case VTT_SETTINGS_FETCH:
      if (section === KEY && path && _get(state, path, undefined)) {
        return {
          ..._set({ ..._set(state, path, payload) }, `${path}2`, payload),
        };
      }
      return state;

    case VTT_SETTINGS_ALTERNATION:
      if (_has(state, path)) {
        return { ..._set(state, path, payload.data) };
      }
      return state;

    case VTT_SETTINGS_SAVE_LOADING:
    case VTT_SETTINGS_SAVE:
      if (section === KEY && path && _get(state, path, undefined)) {
        return { ..._set(state, path, payload) };
      }
      return state;

    case VTT_DATA_SOURCES_OPTIONS_FETCH:
    case VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING:
      return {
        ...state,
        drawerSelected: {
          ...state.drawerSelected,
          ...payload,
        },
      };
    case VTT_SET_DATA_SOURCES_SYNC_STATUS:
      return {
        ...state,
        syncData: action.payload,
      }
    default:
      return state;
  }
};

export default dataSourcesReducer;
