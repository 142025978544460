import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import accimage from "../../../assets/images/acclogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { setInterimUser } from "../../../actions/authentication.action";
import { Form, Input, Button } from "antd";

import ApiServices from "../../../services/tentacle";

class LoginComponent extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.emailRef = createRef();
    this.passwordRef = createRef();
  }

  componentDidMount() {
    this.setState({
      redirecTo:
        this.props.location.state && this.props.location.state.from
          ? `${process.env.PUBLIC_URL}${this.props.location.state.from.pathname}${this.props.location.state.from.search}`
          : null,
    });
  }

  getReturnUrl = (role) => {
    if (this.state.redirecTo) {
      return `${process.env.PUBLIC_URL}${this.state.redirecTo}`;
    } else {
      let dashboardURL = "";
      switch (role) {
        case "principal":
          dashboardURL = `${process.env.PUBLIC_URL}/dashboard/principal`;
          break;
        case "ceo":
          dashboardURL = `${process.env.PUBLIC_URL}/dashboard/ceo`;
          break;
      }
      return dashboardURL;
    }
  };

  loginAuth = async (loginFormValues) => {
    toast.dismiss();
    loginFormValues.userCategory = "interim";
    ApiServices.Auth.login(loginFormValues)
      .then((data) => {
        const { role, consortium, schoolIdArray } = data;
        const interimUser = {
          isLoggedIn: true,
          role: role,
          school: consortium,
          schoolIdArray: schoolIdArray,
          userId: 7,
        };
        this.props.dispatch(setInterimUser(interimUser));
        this.props.history.push(this.getReturnUrl(role));
      })
      .catch((err) => {
        console.log(err);
        // simplify error message
        switch (err) {
          case "Failed to fetch":
            err = "Couldn't reach login service.";
            break;
        }
        setTimeout(() => {
          toast.error(err);
        }, 200);
      });
  };

  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={accimage} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>LOGIN</h4>
                          <h6>Enter your Username and Password </h6>
                        </div>
                        <Form className="theme-form" onFinish={this.loginAuth}>
                          <div className="form-group">
                            <label className="col-form-label pt-0">Email</label>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your email!",
                                },
                                {
                                  type: "email",
                                  message: "Enter valid email!",
                                },
                              ]}
                            >
                              <Input placeholder="you@institute.com" />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your password!",
                                },
                              ]}
                            >
                              <Input.Password />
                            </Form.Item>
                          </div>
                          <div className="form-group form-row mt-3 mb-0">
                            <Button
                              className="btn btn-primary btn-block"
                              type="primary"
                              htmlType="submit"
                            >
                              Login
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication,
  };
};

export default withRouter(connect(mapStateToProps)(LoginComponent));
