import {
    SET_DM_LAYOUT_BREADCRUMB,
    SET_LAYOUT_MENU_SELECTED,
    SET_SELECTED_DM_LAYOUT_BREADCRUMB,
} from "../../constant/actionTypes";

// set main menu selected segment
export const setMenuSelected = payload => dispatch => {
    dispatch({
        type: SET_LAYOUT_MENU_SELECTED,
        payload,
    });
};

// set breadcrumb segments
export const setBreadcrumb = (payload) => dispatch => {
    dispatch({
        type: SET_DM_LAYOUT_BREADCRUMB,
        payload,
    });
};

// set selected href in breadcrumb
export const setSelectedBreadcrumbSegment = payload => dispatch => {
    dispatch({
        type: SET_SELECTED_DM_LAYOUT_BREADCRUMB,
        payload,
    });
};
