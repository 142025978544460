import {    
  DATA_PRIVACY_ACCESS_GRANTED_DATA,
  DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING,
    DATA_PRIVACY_SETTINGS_DATA,
    FETCHING_DATA_PRIVACY_SETTINGS_DATA,
    RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA,
    SET_DATA_PRIVACY_MANAGER_LOADING,
    SET_DATA_PRIVACY_MANAGER_STATUS,  
  } from '../../constant/actionTypes';
  
  const initialState = {
    dataPrivacy: {
      data:{},
      error:false,
      isLoading: false,
    },
    isLoading: false,
    dataPrivacyAccess: {
      data: {},
      error: false,
      isLoading: false,
    }
  };
  
  const dataPrivacyManagementReducer = (state = initialState, action) => {
    const { type, payload } = action;    
    switch (type) {
      case SET_DATA_PRIVACY_MANAGER_LOADING:
        return {
          ...state,
          isLoading: payload,
        };
      case SET_DATA_PRIVACY_MANAGER_STATUS:
        return {
          ...state,
          ...payload,
        };
      case FETCHING_DATA_PRIVACY_SETTINGS_DATA:
      case DATA_PRIVACY_SETTINGS_DATA:
        return {
          ...state,
          dataPrivacy: {
            ...action.payload
          }
        };
      case DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING:
        return {
          ...state,
          dataPrivacyAccess: {
            ...initialState.dataPrivacyAccess,
            ...action.payload,
          }
        };
      case DATA_PRIVACY_ACCESS_GRANTED_DATA:
        return {
          ...state,
          dataPrivacyAccess: {
            ...action.payload
          }
        };
      case RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA:
        return {
          ...state,
          dataPrivacyAccess: {
            ...initialState.dataPrivacyAccess,
          }
        };
      default:
        return state;
    };
  }
  
  export default dataPrivacyManagementReducer;
  