import React, { useCallback, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllowedCourses,
  getAllowCourseLoadingState,
  getLastAllowCoursesSearchValue
} from '../../../../../../selectors/userManagement/userManagement.selector';
import { getUserManagementCourses } from '../../../../../../actions/userManagement/userManagement.action';
import CourseSwitch from './courseSwitch';
import _debounce from "lodash/debounce";
import { get as _get } from 'lodash';
import Loader from "../../../../../../components/dashboardCommon/Loader";
import { dashboardConst } from '../../../../../../constant/dashboardManagement/commonDashboardConst';

const AllowedCourses = ({
  selectedUsersArray = [],
  onSwitchChange,
  searchCourseText,
  currentSelectedTab,
  previousSearchCourseText,
  fetchCourseData,
}) => {
  const allowedCourses = useSelector(getAllowedCourses);
  const tableDataLoading = useSelector(getAllowCourseLoadingState);
  const lastSearchQuery = useSelector(getLastAllowCoursesSearchValue);
  const accountId = _get(selectedUsersArray[0], "account_id", null);

  const dispatch = useDispatch();

  const onCourseSearch = useCallback(_debounce(({ value }) => {
    if (value.length > dashboardConst.minSearchTextLength) {
      const userIds = selectedUsersArray.map(user => user.user_id);
      const payload = {
        searchValue: value,
        accountId,
        userIds,
        tab: dashboardConst.tabKey.allow,
        ...dashboardConst.initialPagination,
      };

      dispatch(getUserManagementCourses(payload));
    }
  }, 500), [selectedUsersArray]);

  useEffect(() => {
    if (
      currentSelectedTab === dashboardConst.tabKey.allow &&
      previousSearchCourseText !== searchCourseText &&
      lastSearchQuery !== searchCourseText
    ) {
      onCourseSearch({ value: searchCourseText });
    }
  }, [searchCourseText, currentSelectedTab, previousSearchCourseText]);

  const onPaginationChange = (page, pageSize) => {
    fetchCourseData({
      pageLimit: pageSize,
      pageNo: page,
      tab: dashboardConst.tabKey.allow,
      searchValue: searchCourseText,
    });
  };

  const columns = [
    {
      dataIndex: 'courseId',
      render: (_, record) => <CourseSwitch
        record={record}
        onSwitchChange={onSwitchChange}
      />
    },
  ];

  return (
    <div>
      <Loader isLoading={tableDataLoading} />
      <Table
        columns={columns}
        dataSource={allowedCourses.data}
        pagination={{
          onChange: onPaginationChange,
          total: allowedCourses.count,
          pageSizeOptions: dashboardConst.contentPerPage,
        }}
      />
    </div>
  )
}

export default AllowedCourses;
