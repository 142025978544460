import _get from 'lodash/get';
import { createSelector } from 'reselect';

const userManagementData = state => _get(state, "userManagementReducer", {});

export const getUserManagementUserList = createSelector(
  userManagementData, data => _get(data, 'userList.data', [])
);

export const getUserManagementUserLoading = createSelector(
  userManagementData, data => _get(data, 'userList.isLoading', false)
);

export const getAddUserAccountsList = createSelector(
  userManagementData, data => _get(data, 'accountList.data', [])
);

export const getAddUserAccountsListLoading = createSelector(
  userManagementData, data => _get(data, 'accountList.isLoading', false)
);

const userManagementSearchUserData = state => _get(state, "userManagementReducer.searchUserData");

export const getIsUsersLoading = createSelector(
  userManagementSearchUserData, data => _get(data, 'isLoading', false)
);

export const getSearchedUsersList = createSelector(
  userManagementSearchUserData, data => _get(data, 'data', [])
);

const userManagementCourseData = state => _get(state, "userManagementReducer.courseList");

const initCourseData = {
  count: 0,
  data: [],
};

export const getLastAllCoursesSearchValue = createSelector(
  userManagementCourseData, data => _get(data, 'all.lastSearchValue', '')
);

export const getAllCourseLoadingState = createSelector(
  userManagementCourseData, data => _get(data, 'all.isLoading', false)
);

export const getAllCoursesDataFetched = createSelector(
  userManagementCourseData, data => _get(data, 'all.dataFetched', false)
);

export const getAllCourses = createSelector(
  userManagementCourseData, data => _get(data, 'all.data', initCourseData)
);

export const getLastAllowCoursesSearchValue = createSelector(
  userManagementCourseData, data => _get(data, 'allow.lastSearchValue', '')
);

export const getAllowCoursesDataFetched = createSelector(
  userManagementCourseData, data => _get(data, 'allow.dataFetched', false)
);

export const getAllowCourseLoadingState = createSelector(
  userManagementCourseData, data => _get(data, 'allow.isLoading', false)
);

export const getAllowedCourses = createSelector(
  userManagementCourseData, data => _get(data, 'allow.data', initCourseData)
);

export const getLastRestrictCoursesSearchValue = createSelector(
  userManagementCourseData, data => _get(data, 'restrict.lastSearchValue', '')
);

export const getRestrictedDataFetched = createSelector(
  userManagementCourseData, data => _get(data, 'restrict.dataFetched', false)
);

export const getRestrictedCourseLoadingState = createSelector(
  userManagementCourseData, data => _get(data, 'restrict.isLoading', false)
);

export const getRestrictedCourses = createSelector(
  userManagementCourseData, data => _get(data, 'restrict.data', initCourseData)
);

const userManagementValidateUser = state => _get(state, "userManagementReducer.validateUser");

export const getValidateUserLoadingState = createSelector(
  userManagementValidateUser, data => _get(data, 'isLoading', false)
);

export const getValidateUserData = createSelector(
  userManagementValidateUser, data => _get(data, 'data', {})
);

export const getValidateUserErrors = createSelector(
  userManagementValidateUser, data => _get(data, 'error', {})
);
