import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getIsEdited} from "../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";
import {getSettingBySections} from "../../../../selectors/jobReadyGeneric/settings/base.selector";
import {
    getRiAcademicDataFetchLoading,
    getRiAcademicSaveLoading,
    getRiFinancialDataFetchLoading,
    getRiFinancialSaveLoading,
    getRiComplianceDataFetchLoading,
    getRiComplianceSaveLoading,
} from "../../../../selectors/jobReadyGeneric/settings/riskIndicators.selector";
import {
    fetchSettings,
    resetEditMode,
    saveSettings,
    setSettings
} from "../../../../actions/jobReadyGeneric/settings/commonSettings.action";

import RiskIndicatorsView from "./RiskIndicatorsView";
import EditedWarning from "../partials/EditedWarning";

import {settingsSections, subSections} from "../../../../constant/jobReadyGeneric/constants";
import {initiateValidations} from "../partials/helperValidators";


const RiskIndicatorsContainer = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(null);
    const [showWarning, setShowWarning] = useState({state:false, key:null});

    // selectors
    const isEdited = useSelector(getIsEdited);
    const currentDataSet = useSelector(getSettingBySections("data", isEdited.section, isEdited.subSection))
    const currentInitialDataSet = useSelector(getSettingBySections("data2", isEdited.section, isEdited.subSection))

    const fetchAcademicDataLoading = useSelector(getRiAcademicDataFetchLoading);
    const saveAcademicDataLoading = useSelector(getRiAcademicSaveLoading);
    const fetchFinancialDataLoading = useSelector(getRiFinancialDataFetchLoading);
    const saveFinancialDataLoading = useSelector(getRiFinancialSaveLoading);
    const fetchComplianceDataLoading = useSelector(getRiComplianceDataFetchLoading);
    const saveComplianceDataLoading = useSelector(getRiComplianceSaveLoading);

    const isLoading = (
        fetchAcademicDataLoading || saveAcademicDataLoading ||
        fetchFinancialDataLoading || saveFinancialDataLoading ||
        fetchComplianceDataLoading || saveComplianceDataLoading
    )

    // effects
    useEffect(() => {
        setActiveTab(subSections.academic.key);
    }, [])

    useEffect(() => {
        // fetch settings data according to the selected tab
        if(activeTab){
            const subSectionKey = Object.keys(subSections).find(k => subSections[k].key === activeTab)
            dispatch(fetchSettings({}, settingsSections.riskIndicators, activeTab, subSections[subSectionKey].id));
        }
    }, [activeTab])

    const onTabChange = (key) => {
        if(isEdited.state){
            setShowWarning({state:true, key})
        }else{
            setActiveTab(key)
        }
    }

    const onSave = () => {
        // call for save settings api
        if(!initiateValidations(isEdited.section, currentDataSet)){
            const subSectionKey = Object.keys(subSections).find(k => subSections[k].key === isEdited.subSection)
            dispatch(saveSettings(currentDataSet, "draft", isEdited.section, isEdited.subSection, subSections[subSectionKey].id, false, () => {
                setShowWarning({state:false, key:null});
                dispatch(resetEditMode());
                setActiveTab(showWarning.key);
            }));
        }
    }

    const onDiscard = () => {
        // reset dataset from the initial fetched data
        if(currentInitialDataSet){
            dispatch(setSettings(currentInitialDataSet, "data", isEdited.section, isEdited.subSection));
        }
        setShowWarning({state:false, key:null});
        dispatch(resetEditMode());
        setActiveTab(showWarning.key);
    }


    return (
        <Fragment>
            <RiskIndicatorsView
                isLoading={isLoading}
                tabConfig={{activeTab, setActiveTab:onTabChange}}
            />
            <EditedWarning
                visible={showWarning.state}
                onCancel={() => setShowWarning({state:false, key:null})}
                onSave={onSave}
                onDiscard={onDiscard}
            />
        </Fragment>
    )
}

export default RiskIndicatorsContainer