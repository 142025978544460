import React from 'react';
import { Col, Row, Card } from 'antd';
import PropTypes from 'prop-types';

import ChartCard from './partials/chartCard/ChartCard';
import LineChartCard from '../../../../../common/lineChartCard/LineChartCard';
import SpeedometerChartCard from './partials/speedometerChartCard/SpeedometerChartCard';
import Loader from '../../../../../../../../common/layout/Loader';

const Overview = ({
  lineChartData,
  speedometerData,
  pieChartData,
  dataLoading,
}) => (
  <div>
    <Loader isLoading={dataLoading}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <LineChartCard
            title="Financial Risk Over Time"
            data={lineChartData}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className="overview-clickable-card" style={{ cursor: 'unset' }}>
            <SpeedometerChartCard
              name="Completed Payment Amount"
              min={0}
              max={speedometerData?.maxValue}
              value={speedometerData?.value}
              metaConfig={{
                name: 'Completed Payment',
                value: parseFloat(speedometerData?.value).toLocaleString('en'),
              }}
              enabled={speedometerData?.enabled}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={4}>
          <Card className="overview-clickable-card" style={{ cursor: 'unset' }}>
            <ChartCard
              name="Completed payments as a percentage of past Issued Invoices"
              percentage={pieChartData?.percentage}
              metaConfig={{
                name: 'VET Fee Help Eligible/ Applied',
                value: pieChartData?.displayValue,
              }}
              dataset={pieChartData?.data}
              enabled={pieChartData?.enabled}
            />
          </Card>
        </Col>
      </Row>
    </Loader>
  </div>
);

Overview.propTypes = {
  lineChartData: PropTypes.object,
  speedometerData: PropTypes.object,
  pieChartData: PropTypes.object,
  dataLoading: PropTypes.bool,
};

export default Overview;
