import React, {useEffect, useState, useCallback} from "react";
import _get from "lodash/get";

export const withStudentTableSearch = (Component) => ({...props}) => {
    const {students} = props;
    const [searchVal, setSearchVal] = useState(null);
    const [records, setRecords] = useState([]);

    const onSearch = useCallback(
        (searchValue) => {
            setSearchVal(searchValue);

            if (searchValue === "") return setRecords(students);

            setRecords(
                students.filter(rec => {
                    let valid = false
                    if (_get(rec, "studentDetails.name", "").toLowerCase().includes(searchValue.toLowerCase())){
                        valid = true
                    }
                    return valid
                })
            )
        },
        [students],
    );

    useEffect(() => {
        setRecords(students);
        setSearchVal(null);
    }, [students])

    return <Component
        {...props}
        students={records}
        searchConfig={{
            onSearch,
            setSearchVal,
            searchVal
        }}/>
}