import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

const VetTrackDrawer = ({ title, onClose, visible, children, width, ...rest }) => (
  <>
    <Drawer
      width={width}
      title={title}
      placement="right"
      onClose={onClose}
      visible={visible}
      {...rest}
    >
      {children}
    </Drawer>
  </>
);

VetTrackDrawer.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default VetTrackDrawer;
