import React, {useCallback} from 'react';
import _camelCase from "lodash/camelCase";
import _get from "lodash/get";
import {useSelector} from "react-redux";
import {resolveFromArrayByRange} from "../../../../utils/innovative/resolveSettings";
import {
    makeInnovativeStudentMainReportSummaryCardsResponseData
} from "../../../../selectors/innovative/student.selector";
import {makeInnovativeSettingsResponseData} from "../../../../selectors/innovative/setting.selector";
import StatCardGroup from "../../common/cards/StatCardGroup";

const flashIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/flash.svg`;
const questionMarkIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/question-mark.svg`;
const speedometerIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/speedometer.svg`;
const stopwatchIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/stopwatch.svg`;

const TopCardContainer = () => {
    // selectors
    const settings = useSelector(makeInnovativeSettingsResponseData);
    const summaries = useSelector(makeInnovativeStudentMainReportSummaryCardsResponseData);

    // a mapping for each card with settings
    const summaryMapping = {
        overallAveragePerformanceScore: {
            settingPath: "conditionalColors.performanceScore",
            img: speedometerIcon,
        },
        overallAverageEngagementScore: {
            settingPath: "conditionalColors.engagementScore",
            img: flashIcon,
        },
        averageNumberOfMissingSubmission: {
            settingPath: "conditionalColors.missingSubmission",
            img: questionMarkIcon,
        },
        averageNumberOfLateSubmission: {
            settingPath: "conditionalColors.lateSubmission",
            img: stopwatchIcon,
        }
    }

    // here we resolve colors for each summary and will merge with default values
    const getSummaries = useCallback(() => {
        return summaries.map(summary => {
            const mapping = summaryMapping[_camelCase(summary.title)];
            const selected = {
                ...mapping,
                ...summary
            };
            const item = _get(selected, 'content.datum', []);
            const resolved = resolveFromArrayByRange({
                value: item.value,
                settings: _get(settings, selected.settingPath, []),
            });
            // setting a default color if no color found
            selected.content.datum = item;
            selected.color = summary.color;
            return selected;
        });
    }, [settings, summaries]);

    return <StatCardGroup summaries={getSummaries()}/>
};

export default TopCardContainer;