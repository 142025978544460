import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Typography } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import {
  syncStatuses,
  syncDateFormat,
} from '../../../../../constant/vetTrack/analysis';

const { Paragraph } = Typography;

const SyncMessage = props => {
  const { syncData } = props;
  const { lastSyncStatus, lastSyncTime, isSyncing } = syncData;

  if (isSyncing) return null;

  if (!lastSyncTime) {
    return (
      <Paragraph className='sync-message warning'>
        <InfoCircleOutlined />
        No Sync data found
      </Paragraph>
    );
  }

  const momentTime  = moment(lastSyncTime).format(syncDateFormat);
  let statusClass = 'success';
  let messageIcon = <CheckCircleOutlined />;

  if (lastSyncStatus === syncStatuses.failed) {
    statusClass = 'failed';
    messageIcon = <ExclamationCircleOutlined />;
  }

  return (
    <Paragraph className={`sync-message ${statusClass}`}>
      {messageIcon}
      Last sync {lastSyncStatus} at {momentTime}
    </Paragraph>
  );
};

SyncMessage.defaultProps = { 
  syncData: {},
};

SyncMessage.propTypes = {
  syncData: PropTypes.object,
};

export default SyncMessage;
