

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter} from "react-router";
import { setAuthUser } from '../../../actions/authentication.action';

const tassLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tass_logo.png`;


class LoginComponent extends Component {
    state = { 
        email: "",
        password: "",
     }
     setEmail = (email) => {
        this.setState({
          email: email
        })
     }

     setPassword = (password) => {
      this.setState({
        password: password
      })
     }

    componentDidMount(){
      if(this.props.Authentication.authUser){
        this.props.history.push(`${process.env.PUBLIC_URL}/tassweb/financial_summary`);
      }
    }

    loginAuth = async (event) => {
        // event.preventDefault();
        if(this.state.email =="admin@ayra.com.au" && this.state.password =="Admin@123"){
          const authUser = true;
          this.props.dispatch(setAuthUser({authUser}));
          this.props.history.push(`${process.env.PUBLIC_URL}/tassweb/financial_summary`);
        }else{
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
        }
    }

    render() { 
    return (
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                {/* <!-- login page start--> */}
                <div className="authentication-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <div className="text-center">
                                        <img src={tassLogo} alt="" /></div>
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <h4>LOGIN</h4>
                                                <h6>Enter your Username and Password </h6>
                                            </div>
                                            <form className="theme-form" >
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">Your Name</label>
                                                    <input className="form-control" type="email" name="email"
                                                        value={this.state.email}
                                                        onChange={e => this.setEmail(e.target.value)}
                                                        placeholder="Email address"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Password</label>
                                                    <input className="form-control" type="password" name="password"
                                                        value={this.state.password}
                                                        onChange={e => this.setPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => this.loginAuth()} >Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {/* <!-- login page end--> */}
            </div>
        </div>
    );
    }
};

const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication
  }
}

export default withRouter(connect(mapStateToProps)(LoginComponent));