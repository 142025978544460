import React from 'react';
import SolidGauge from "../charts/SolidGauge";
import PropTypes from "prop-types";

const SolidGaugeCellRenderer = ({data}) => {
    const {score, cohort, color} = data;

    const chartOptions = {
        credits: {
            enabled: false
        },
        chart: {
            height: 100,
            width: 160,
        },
        pane: {
            center: ['60%', '80%'],
            size: '150%',
            startAngle: -90,
            endAngle: 90,
            background: {
                innerRadius: '86%',
                outerRadius: '98%',
                shape: 'arc'
            },
        },
        plotOptions: {
            solidgauge: {
                innerRadius: '85.5%',
            }
        },
        series: [{
            name: '',
            data: [
                {
                    y: score,
                    color: color
                }
            ],
            dataLabels: {
                format:
                    '<div style="text-align:center" class="gaugeLabels">' +
                    '<span style="font-size:16px" class="largeHeading">{y}%</span><br/>' +
                    `<span style="font-size:12px;opacity:0.5;" class="smallHeading">Cohort: ${cohort}%</span>` +
                    '</div>'
            },
        }],
    }

    return (
        <div className="tabeSolidGauge">
            <SolidGauge chartOptions={chartOptions}/>
        </div>
    );
};

SolidGaugeCellRenderer.propTypes = {
    data: PropTypes.object,
};

export default SolidGaugeCellRenderer;