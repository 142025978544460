import React from "react";

const tentacleIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentaclePoweredBy.jpg`;

const LayoutFooter = ({style}) => (
    <div>
        <div style={style} className="vw-layout-footer">
            <img src={tentacleIcon} alt="tentacle-logo"/>
        </div>
    </div>

)

export default LayoutFooter;