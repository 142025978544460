import React, { memo } from "react";
import PropTypes from "prop-types";
import SelectFilter from "../selectFilter";

const TermFilter = ({
  terms,
  termsLoading,
  reportLoading,
  termChange,
  selectedTerm,
  ...rest
}) => {
  return (
    <SelectFilter
      items={terms}
      itemChange={termChange}
      itemsLoading={termsLoading}
      selectedItem={selectedTerm}
      reportLoading={reportLoading}
      {...rest}
    />
  )
}

TermFilter.propTypes = {
  terms: PropTypes.array,
  termsLoading: PropTypes.bool,
  reportLoading: PropTypes.bool,
  termChange: PropTypes.func,
  selectedTerm: PropTypes.string
}

export default memo(TermFilter);
