import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import _isObject from 'lodash/isObject';

const SectionBreadcrumb = ({ items }) => {
  const renderItem = (item, index) => {
    if (_isObject(item)) {
      const { href, name, onClick } = item;
      return (
        <Breadcrumb.Item href={href} onClick={onClick} key={index}>
          {name}
        </Breadcrumb.Item>
      );
    }
    return <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>;
  };

  return (
    <Breadcrumb separator=">">
      {items.map((item, index) => renderItem(item, index))}
    </Breadcrumb>
  );
};

SectionBreadcrumb.propTypes = {
  items: PropTypes.array,
};

export default memo(SectionBreadcrumb);
