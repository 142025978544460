import React from "react";
import {Popover} from "antd";
import PropTypes from "prop-types";

const OutcomeTitleCell = ({title, description}) => {
    const renderContent = () => (
        <div dangerouslySetInnerHTML={{ __html: description}}/>
    )

    return (
       <Popover content={renderContent()} title={title} trigger="click">
            <span className="assignment-outcome-title">{title}</span>
        </Popover>
    )

}

OutcomeTitleCell.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
}

export default OutcomeTitleCell;