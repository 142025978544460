import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const PieChart = ({ chartOptions }) => {
  const defaultConfig = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'pie',
      height: '100%',
    },
    title: false,
    subTitle: false,
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter() {
        return `<b>${this.key}:</b> ${this.y}`;
      },
    },
    series: [
      {
        data: [],
      },
    ],
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      itemMarginBottom: 10,
    },
  };

  const config = Highcharts.merge(defaultConfig, chartOptions);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} immutable />
    </div>
  );
};

PieChart.propTypes = {
  chartOptions: PropTypes.object.isRequired,
};

export default PieChart;
