import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import RTOHeader from '../components/rtoHeader';

import '../../../../assets/scss/vetTrack/rto/onBoarding.scss';

const VetTrackRTOOnboardLayout = ({ children }) => (
  <Layout>
    <RTOHeader />
    <Layout>
      <Layout.Content className="vtt-rto-content-on-boarding">
        {children}
      </Layout.Content>
    </Layout>
  </Layout>
);

VetTrackRTOOnboardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default VetTrackRTOOnboardLayout;
