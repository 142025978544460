import React, {useCallback} from 'react';
import {makeInnovativeSettingByPath} from "../../../../../selectors/innovative/setting.selector";
import {useSelector} from "react-redux";
import {
    resolvePropertiesFromSettingArray,
    resolvePropertiesFromSettingObject
} from "../../../../../utils/innovative/resolveSettings";
import SideChartsView from "./SideChartsView";
import PropTypes from "prop-types";
import _get from 'lodash/get';

const SideChartsContainer = ({records, recordsLoading, configs, type, termsLoading}) => {
    const defaultConfig = {
        chart: {
            height: 200,
            marginLeft: 20,
        },
        legend: {
            width: 100,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
        },
    };

    // selectors
    const colorScheme = useSelector(makeInnovativeSettingByPath("colorScheme"));
    const masterColorScheme = useSelector(makeInnovativeSettingByPath("masterColorScheme"));

    const resolveOverallAssignmentSubmissions = useCallback(() => {
        let colors;
        if(type === "student"){
            const resolvedMasterColor = resolvePropertiesFromSettingObject(records["overall_assignment_submission"], masterColorScheme ? masterColorScheme.submission : {});
            
            if(resolvedMasterColor && resolvedMasterColor.colors.length > 0){
                //master setting colors
                colors = resolvedMasterColor.colors;
            } else {
                // load default colors
                colors = ["#CA562C", "#1AC0C6", "#DE8A5A"];
            }
        }else{
            // load course related colors
            const resolved = resolvePropertiesFromSettingObject(
            records["overall_assignment_submission"],
            colorScheme.submission);
            colors = resolved.colors
        }

        return {
            ...defaultConfig,
            series: [{data: records["overall_assignment_submission"]}],
            plotOptions: {
                pie: {
                    center: [30, 70],
                    colors: colors,
                    size: '120px',
                    left: '0',
                }
            },
            structuredData: [],
        };
    }, [records]);

    const resolveOverallCourseGrades = useCallback(() => {
        records["overall_course_grade"].sort((val1, val2) => val1[0].localeCompare(val2[0]))
        let colors;


        colors = ["#2F4B7C", "#FFA600", "#f95d6a", "#2F80ED", "#665191", "#FF7C43", "#F85699", "#A05195", "#28A514", "#003F5C", "#B4C8A8", "#CA562C", "#1AC0C6", "#DE8A5A", "#EDBB8A", "#FFDE6A"]

        let dataPoints = [];

        const grades = _get(records, 'overall_course_grade', []);

        grades.forEach(grade => {
            const randomColor = colors.pop();
            if (grade && grade.length) {
                dataPoints.push({
                    y: grade[1],
                    name: grade[0],
                    color: grade[2] || randomColor || colors [0],
                });
            }
        });
        return {
            ...defaultConfig,
            series: [{data: records["overall_course_grade"]}],
            plotOptions: {
                pie: {
                    center: [30, 70],
                    colors: colors,
                    size: '120px',
                    left: '0',
                }
            },
            structuredData: dataPoints,
        };
    }, [records]);

    const getConfig = () => {
        const resolverMapping = [
            resolveOverallCourseGrades,
            resolveOverallAssignmentSubmissions,
        ]

        if (!configs) {
            return [];
        }

        return configs.map((config, index) => {
            return {
                ...config,
                chartOptions: resolverMapping[index]()
            }
        })
    }

    return <SideChartsView
        getFirstPie={resolveOverallAssignmentSubmissions()}
        getSecondPie={resolveOverallCourseGrades()}
        isLoading={recordsLoading || termsLoading}
        configs={getConfig()}
    />
};

SideChartsContainer.defaultProps = {
    termsLoading: false,
};

SideChartsContainer.propTypes = {
    recordsLoading: PropTypes.bool,
    records: PropTypes.object,
    configs: PropTypes.object,
    type: PropTypes.string,
    termsLoading: PropTypes.bool,
};

export default SideChartsContainer;