import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setMenuSelected, setBreadcrumb } from '../../../actions/dashboardManagement/layout.action';
import { makeDashboardSettingByPath, makeDashboardSettingsLoading, makeDashboardSettingsSaveLoading } from '../../../selectors/dashboardManagement/settings.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import Loader from '../../innovative/common/layout/Loader';
import useSettingsHelpers from '../../../utils/innovative/settingHandler';
import ConfirmationPopupGlobal from '../../innovative/common/settingsComponents/confirmationPopupGlobal';
import { Col, Layout, Row } from 'antd';

const ConditionalColors = React.lazy(() => import('../../innovative/common/settingsComponents/conditionalColors'));

const ConditionalColorsContainer = () => {
  const dispatch = useDispatch();


  const { isModalVisible, setIsModalVisible, onResetDefault, onConfirmHandler, modalConfig, onPreSubmit } = useSettingsHelpers()

  const pageSettingsPath = "conditionalColors";

  const pageSettings = useSelector(makeDashboardSettingByPath(pageSettingsPath));


  const settingsLoading = useSelector(makeDashboardSettingsLoading);

  const settingsSaving = useSelector(makeDashboardSettingsSaveLoading);


  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.globalConfigurations.key,
      section: menuData.globalConfigurations.name
    },
    {
      key: menuData.conditionalColors.key,
      section: menuData.conditionalColors.name,
    },
  ];

  useEffect(() => {
    dispatch(setMenuSelected(menuData.conditionalColors.key));
    dispatch(setBreadcrumb(breadcrumbItems));
  }, [])


  return (
    <div>
      <ConfirmationPopupGlobal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onConfirm={onConfirmHandler}
        modalConfig={modalConfig}
      />
      <Layout>
        <Suspense fallback={<Loader isLoading={true} />}>
          <div className='in-smallContainer' >
            <Loader isLoading={settingsLoading || settingsSaving} />
            <Row gutter={[16, 16]} >
              <Col span={24} >
                <ConditionalColors
                  onSubmit={onPreSubmit}
                  onResetDefault={onResetDefault}
                  pageSettings={pageSettings}
                  pageSettingsPath={pageSettingsPath}
                />
              </Col>
            </Row>
          </div>
        </Suspense>
      </Layout>
    </div>
  )

}

export default ConditionalColorsContainer;