import React from "react";
import PropTypes from "prop-types";
import {CheckSquareTwoTone} from "@ant-design/icons";

const OutcomeCell = ({title, score, colorSettings, mastered}) => {


    return (
        <div className="outcomeCell">
            <div className="title" style={{textAlign:"center", display:"inline-block", backgroundColor:colorSettings}}>
                {title}
            </div>
            <div className="grade" style={{float:"right", display:"flex"}}>
                {score}
                {mastered &&
                <div style={{marginLeft: 10, display: "inline-block", verticalAlign: "middle"}}>
                    <CheckSquareTwoTone style={{fontSize: '16px'}} twoToneColor="#52c41a"/>
                </div>
                }
            </div>
        </div>
    )
}

OutcomeCell.propTypes = {
    title: PropTypes.string,
    gainedOutcome: PropTypes.string,
    totalOutcome: PropTypes.string,
    colorSettings: PropTypes.string,
    mastered: PropTypes.bool,
}
export default OutcomeCell;