import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { makeInnovativeSettingByPath } from "../../../../selectors/innovative/setting.selector";
import _get from "lodash/get";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";

const ColorScheme = React.lazy(() => import("../../common/settingsComponents/colorScheme"));

const ColorSchemeSettingsContainer = ({ onSubmit, onResetDefault }) => {

  const pageSettingsPath = "colorScheme";
  const pageSettings = useSelector(
    makeInnovativeSettingByPath(pageSettingsPath)
  );

  const pageSettingsPathMarking = "markingScheme";

  // selectors
  const pageSettingsMarking = useSelector(
    makeInnovativeSettingByPath(pageSettingsPathMarking)
  );

  return (
    <Suspense fallback={<Loader isLoading={true} />} >
      <ColorScheme onSubmit={onSubmit} onResetDefault={onResetDefault} pageSettings={pageSettings} pageSettingsPath={pageSettingsPath} pageSettingsMarking={pageSettingsMarking} />
    </Suspense>
  );
};

ColorSchemeSettingsContainer.propTypes = {
  onSubmit: PropTypes.func,
};

export default ColorSchemeSettingsContainer;
