import React from "react";
import {Col, Row, Card} from "antd";
import PropTypes from "prop-types";

import PieChartCard from "./partials/pieChartCard/PieChartCard";
import LineChartCard from "../../../../../common/lineChartCard/LineChartCard";
import BarChartCard from "./partials/barChartCard/BarChartCard";
import Loader from "../../../../../../../../common/layout/Loader";

const Overview = ({onPopupOpen, lineChartData, barChartData, pieChartData, dataLoading}) => {
    return (
        <div>
             <Loader isLoading={dataLoading}>
            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <LineChartCard title="Compliance Risk Over Time" data={lineChartData}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                    <div onClick={() => onPopupOpen("durationToExpire")} className="overview-clickable-card">
                        <BarChartCard
                            name="Duration to Expire"
                            data={barChartData}
                            enabled={barChartData?.enabled}
                        />
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                    <Card onClick={() => onPopupOpen("fileNotes")} className="overview-clickable-card">
                        <PieChartCard
                            name="File Notes (Priority)"
                            data={pieChartData?.data}
                        />
                    </Card>
                </Col>
            </Row>
             </Loader>
        </div>
    )
}

Overview.propTypes = {
    onPopupOpen: PropTypes.func,
    lineChartData: PropTypes.object,
    barChartData: PropTypes.object,
    pieChartData: PropTypes.object,
    dataLoading: PropTypes.bool,
}

export default Overview;