import _get from "lodash/get";
import {subSections} from "../../../constant/jobReadyGeneric/constants";

// return risk indicators data
export const getRiskIndicatorsData = state => _get(state, 'jrGeneric.settings.riskIndicators');

// ACADEMIC
export const getRiAcademicData = state => _get(getRiskIndicatorsData(state), subSections.academic.key);

export const getRiAcademicDataFetchLoading = state => _get(getRiAcademicData(state), 'selected.isLoading');
export const getRiAcademicSaveLoading = state => _get(getRiAcademicData(state), 'saved.isLoading');
export const getRiAcademicDataResponse = state => _get(getRiAcademicData(state), 'data');

// FINANCIAL
export const getRiFinancialData = state => _get(getRiskIndicatorsData(state), subSections.financial.key);

export const getRiFinancialDataFetchLoading = state => _get(getRiFinancialData(state), 'selected.isLoading');
export const getRiFinancialSaveLoading = state => _get(getRiFinancialData(state), 'saved.isLoading');
export const getRiFinancialDataResponse = state => _get(getRiFinancialData(state), 'data');

// COMPLIANCE
export const getRiComplianceData = state => _get(getRiskIndicatorsData(state), subSections.compliance.key);

export const getRiComplianceDataFetchLoading = state => _get(getRiComplianceData(state), 'selected.isLoading');
export const getRiComplianceSaveLoading = state => _get(getRiComplianceData(state), 'saved.isLoading');
export const getRiComplianceDataResponse = state => _get(getRiComplianceData(state), 'data');



// return risk calculations setting by path
export const getRiSettingsByPath = path => state => {
    const result = getRiskIndicatorsData(state);
    return _get(result, path, undefined);
};
