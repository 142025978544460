import {
    FETCH_PATHWAY_TIMELINE_DATA,
    FETCH_PATHWAY_TIMELINE_DATA_LOADING,
    FETCH_PATHWAY_STUDENTS_DATA,
    FETCH_PATHWAY_STUDENTS_DATA_LOADING,
    CLEAR_PATHWAY_TIMELINE_DATA,
} from "../../constant/actionTypes";

const qualificationPathwayReducerDefaultState = {
    elements: {
        list: {
            isLoading:false,
            error: false,
            data: {}
        },
        selected: {
            isLoading:false,
            error: false,
            data: {},
            elementSelected: false
        }
    }
};

const qualificationPathwayReducer = (state = qualificationPathwayReducerDefaultState, action) => {
    switch (action.type) {
        case CLEAR_PATHWAY_TIMELINE_DATA:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    list: qualificationPathwayReducerDefaultState.elements.list
                }
            }
        case FETCH_PATHWAY_TIMELINE_DATA:
        case FETCH_PATHWAY_TIMELINE_DATA_LOADING:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    list: {
                        ...state.elements.list,
                        ...action.payload,
                    }
                }
            }
        case FETCH_PATHWAY_STUDENTS_DATA_LOADING:
        case FETCH_PATHWAY_STUDENTS_DATA:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    selected: {
                        ...state.elements.selected,
                        ...action.payload,
                    }
                }
            }
        default:
            return state;
    }
};

export default qualificationPathwayReducer;