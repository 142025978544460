const generateArray = input => {
  let result = [];
  for (let i = 10; i <= input; i += 10) {
    result.push(i);
  }
  return result;
}

const getXLines = ({
  centerYCoordinates,
  max_y_axis_percentage,
  max_x_axis_percentage,
  middleColor = '#d9d9d9',
}) => (
  [...generateArray(max_y_axis_percentage), centerYCoordinates].map(line => {
    return {
      coordinates: [
        { engagement: 0, performance: line },
        { engagement: max_x_axis_percentage, performance: line }
      ],
      width: line === centerYCoordinates ? 2 : 0.5,
      color: line === centerYCoordinates ? middleColor : '#e6e6e6',
    }
  })
);

export const getPlotLines = ({
  max_y_axis_percentage,
  max_x_axis_percentage,
}) => {
  const centerXCoordinates = max_x_axis_percentage / 2;
  const centerYCoordinates = max_y_axis_percentage / 2;

  return [
    ...getXLines({
      max_y_axis_percentage,
      max_x_axis_percentage,
      centerYCoordinates,
    }),
    {
      coordinates: [
        { engagement: centerXCoordinates, performance: 0 },
        { engagement: centerXCoordinates, performance: max_y_axis_percentage }
      ],
      width: 2,
      color: '#d9d9d9'
    },
  ];
};

export const scatterPlotLines = ({
  max_y_axis_percentage,
  max_x_axis_percentage,
}) => {
  const centerXCoordinates = max_x_axis_percentage / 2;
  const centerYCoordinates = max_y_axis_percentage / 2;

  return [
    ...getXLines({
      centerYCoordinates,
      max_y_axis_percentage,
      max_x_axis_percentage,
      middleColor: '$333333',
    }),
    {
      coordinates: [
        { engagement: centerXCoordinates, performance: 0 },
        { engagement: centerXCoordinates, performance: max_y_axis_percentage }
      ],
      width: 2,
      color: '#333333'
    },
  ];
};
