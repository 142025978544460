import {combineReducers} from "redux";
import assignment from "./assignment.reducer";
import student from "./student.reducer";
import commonData from "./commonData.reducer";

const innovativeReducers = combineReducers({
    commonData,
    assignment,
    student,
});

export default innovativeReducers;