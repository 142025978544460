import React, {useEffect} from "react";
import {Space, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {getDashboardFiltersSelectedData} from "../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {
    getOverviewStudentsLoading,
    getOverviewStudentsData
} from "../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/overview.selector";
import {fetchOverviewStudents} from "../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/overview.action";

import StudentTable from "./StudentTable";
import ProgressBarCell from "../../../../common/ProgressBarCell";
import StudentDetailCell from "../../../../common/StudentDetailCell";
import Loader from "../../../../../../../common/layout/Loader";
import NoDataCell from "../../../../../../../common/partials/NoDataCell";

import {getUniqValuesForFilters} from "../../../../../../../utils/general";

const StudentTableContainer = () => {
    const dispatch = useDispatch();

    // selectors
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const students = useSelector(getOverviewStudentsData);
    const studentDataLoading = useSelector(getOverviewStudentsLoading);

    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters)){
            const data = {
                filters: selectedFilters,
            }
            dispatch(fetchOverviewStudents(data));
        }
    }, [selectedFilters])

    const renderProgressCellFilters = () => (
        [
            {
                text: "High",
                value: "High",
            },
            {
                text: "Medium",
                value: "Medium",
            },
            {
                text: "Low",
                value: "Low",
            },
        ]
    )

    const renderProgressCells = (data) => {
        if (data) {
            return (
                <ProgressBarCell
                    value={data.value}
                    displayValue={data.text}
                    color={data.color}
                    style={{width: "90%"}}
                />
            );
        }

        return <NoDataCell />;
    };

    const columns = [
        {
            title: 'Student Details',
            dataIndex: 'studentDetails',
            sorter: (a, b) => a.studentDetails.name && a.studentDetails.name.localeCompare(b.studentDetails.name),
            render: (value) => <StudentDetailCell title={value.name} subtitle={value.id} avatar={value.image}/>,
            onFilter: (value, record) => record.studentDetails.name === value,
            filters: getUniqValuesForFilters(students, "studentDetails.name", "studentDetails.name"),
        },
        {
            title: 'Academic',
            dataIndex: 'academic',
            sorter: (a, b) => parseFloat(a.academic?.value) - parseFloat(b.academic?.value),
            onFilter: (value, record) => record.academic?.text === value,
            render: (value) => renderProgressCells(value),
            filters: renderProgressCellFilters(),
        },
        {
            title: 'Financial',
            dataIndex: 'financial',
            sorter: (a, b) => parseFloat(a.financial?.value) - parseFloat(b.financial?.value),
            onFilter: (value, record) => record.financial?.text === value,
            render: (value) => renderProgressCells(value),
            filters: renderProgressCellFilters(),
        },
        {
            title: 'Compliance',
            dataIndex: 'compliance',
            sorter: (a, b) => parseFloat(a.compliance?.value) - parseFloat(b.compliance?.value),
            onFilter: (value, record) => record.compliance?.text === value,
            render: (value) => renderProgressCells(value),
            filters: renderProgressCellFilters(),
        },
        // Not in phase 01
        // {
        //     title: 'Well-being',
        //     dataIndex: 'wellBeing',
        //     sorter: (a, b) => parseFloat(a.wellBeing?.value) - parseFloat(b.wellBeing?.value),
        //     onFilter: (value, record) => record.wellBeing?.text === value,
        //     render: (value) => renderProgressCells(value),
        //     filters: renderProgressCellFilters(),
        // },
        // Not in phase 01
        // {
        //     title: "",
        //     align:"center",
        //     render: (value, record) => <Space>
        //         <Button size="small">View</Button>
        //         <Button size="small" type="primary" ghost>Take Action</Button>
        //     </Space>,
        // },
    ];




    return (
        <div className="overviewTable">
            <Loader isLoading={studentDataLoading}>
                <StudentTable
                    students={students}
                    columns={columns}
                />
            </Loader>
        </div>

    )
}

export default StudentTableContainer;