const dashboardConst = {
  initialPagination: {
    pageLimit: 10,
    pageNo: 1,
  },
  contentPerPage: [10, 20, 50, 100, 150, 200],
  minSearchTextLength: 2,
  tabKey: {
    all: "all",
    allow: "allow",
    restrict: "restrict",
  },
  allowedCourseStatus: "Allowed",
};

export { dashboardConst };
