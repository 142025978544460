import React, { Component, Fragment } from "react";

import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Modal from "react-responsive-modal";
import ApiServices from "../../../services/tentacle";
import accimage from "../../../assets/images/acclogo.png";
import boxplotimage from "../../../assets/images/boxplot.png";
import { withRouter } from "react-router";
import Helper from "../common/numberHelper";

import addStock from "highcharts/modules/stock";
import helper from "../common/helper";
import "./../../../assets/css/ceo-profile.css";
import { Switch } from "antd";

addStock(Highcharts);
hcMore(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class CEOProfile extends Component {

  constructor(props) {
      super(props);
      var queryx = new URLSearchParams(this.props.location.search);
      this.state = {
          titleFocus: React.createRef(),
          isFilterTermsLoaded:false,
          filterTermValue:0,
          filterTerms:[],
          logged_in: queryx.get("token"),
          token_with_id: queryx.get("t"),
          token: "Bearer " + queryx.get("t"),
          profile: queryx.get("profile"),
          isFirstTimeLoad:true,
          annualBudgetData: [],
          incomVsCostData: [],
          managementData: [],
          enrollmentData: [],
          enrollmentFilterData: [],
          filterSchoolValue: "",
          isFiltersFirstLoad: true,
          isFiltersLoadDone: false,
          modeFilterData: [],
          filterModeValue: "",
          enrollmentNewData: [],
          // leadCounts:[],
          months: ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"],
          // leadCountData: [],
          // leadCountDataOC: [],
          // leadCountDataDE: [],
          finaSchoolArr: [],
          mainSchoolArr: [],
          filteredSchoolArr: [],
          isModeLoadDone: false
      };
  }

  componentDidMount() {
    // this.filterTerms();
    this.getCommonSchools();
  }

  changeTermValue = (value) => {
    this.setState({filterTermValue:value}, () => {
      if(this.state.isFirstTimeLoad){
        this.setState({
          isFirstTimeLoad: false,
        });
        this.changeSchoolValue(this.state.filterSchoolValue, "initial");
        // this.budgetData();
        this.incomeVsCostData();
        this.managementData();
        this.enrollmentsData();
        
        // this.leadCounts("OC", "initial");
      }
    });
  }

  filterTerms = () => {
    try {
      this.setState({
          isFilterCoursesLoaded:false,
          isGoButtonDisabled: true
      });

      let elementResult = Helper.validateSpecialCharacterArray(this.state.mainSchoolArr);
      const params = { logged_in: this.state.logged_in, schools : elementResult };

      if(elementResult && elementResult.length > 0){
        ApiServices.AccFilterService.ceoFilterTerm(params).then((result) => {
          if(result.data && result.data.result !== "" && result.data.result.length > 0){
            this.setState({
              isFilterTermsLoaded: true, 
              filterTermValue:result.data.result[0].id,
              filterTerms:result.data.result
            }, () => {
              this.changeTermValue(result.data.result[0].id);
            });
          }
        },(error) => {
          this.setState({
            isFilterTermsLoaded: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  getCommonSchools = () => {
    try {
      const params = {logged_in: this.state.logged_in};
      ApiServices.ACCCeoProfileService.getSchools(params).then(res => res.json()).then((result) => {
        if(result.data && result.data.result && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            mainSchoolArr: result.data.result.main,
            filteredSchoolArr: result.data.result.filtered,
            enrollmentFilterData: result.data.result.main,
            filterSchoolValue: result.data.result.main[0].schoolId
          }, () => {
            if(this.state.filterSchoolValue !== "") {
              this.filterTerms();
              this.setState({
                isFiltersLoadDone: true
              }) ;
            }
          });
        }
      },(error) => {
        this.setState({
          isFilterTermsLoaded: false,
          error
        });
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  redrawCharts = () => {
    this.setState({
      isGoButtonDisabled: true
    }, () => {
      // this.budgetData();
      this.incomeVsCostData();
      this.managementData();
      this.enrollmentsData();
      // this.leadCounts();
    })
  }

  // budgetData = () => {
  //   this.setState({
  //     isFilterCoursesLoaded:false,
  //     isGoButtonDisabled: true,
  //     isCardDone: false
  //   });
    
  //   const params = {logged_in: this.state.logged_in, term: this.state.filterTermValue};
  //   ApiServices.ACCCeoProfileService.budgetsData(params).then(res => res.json()).then((result) => {
  //     if(Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
  //       this.setState({
  //         annualBudgetData:result.data.result
  //       }, () => {
  //         this.setState({
  //           isGoButtonDisabled: false,
  //           isCardDone: true
  //         })
  //       });
  //     }
  //   },
  //   (error) => {
  //     this.setState({
  //       isFilterTermsLoaded: true,
  //       error
  //     });
  //   });
  // }
    
  incomeVsCostData = () => {
    try {
      this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true,
        isIncomeVsCostDone: false
      });
  
      let elementResult = Helper.validateSpecialCharacterArray(this.state.mainSchoolArr);
      const params = { logged_in: this.state.logged_in, term: this.state.filterTermValue, schools: elementResult };
  
      if(elementResult && elementResult.length > 0 && !Helper.yearTermValidation(params.term)){
        ApiServices.ACCCeoProfileService.incomeVsCostData(params).then(res => res.json()).then((result) => {
          if(result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              incomVsCostData:result.data.result
            }, () => {
              this.setState({
                isGoButtonDisabled: false,
                isIncomeVsCostDone: true
              })
            });
          }
        },(error) => {
          this.setState({
            isIncomeVsCostDone: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  managementData = () => {
    try {
      this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true,
        isManagementIsDone: false
      });
  
      let elementResult = Helper.validateSpecialCharacterArray(this.state.mainSchoolArr);
      const params = {logged_in: this.state.logged_in, term: this.state.filterTermValue, school: elementResult };
  
      if(elementResult && elementResult.length > 0 && !Helper.yearTermValidation(params.term)){
        ApiServices.ACCCeoProfileService.managementData(params).then(res => res.json()).then((result) => {
          if(result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              managementData:result.data.result
            }, () => {
              this.setState({
                isGoButtonDisabled: false,
                isManagementIsDone: true
              })
            });
          }
        },(error) => {
          this.setState({
            isManagementIsDone: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  enrollmentsData = () => {
    try {
      this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true,
        isEnrollmentsDone: false,
        enrollmentData: []
      });
  
      let elementResult = Helper.validateSpecialCharacterArray(this.state.mainSchoolArr);
      const params = { logged_in: this.state.logged_in, term: this.state.filterTermValue, schools: elementResult };
  
      if(elementResult && elementResult.length > 0 && !Helper.yearTermValidation(params.term)){
        ApiServices.ACCCeoProfileService.enrollmentData(params).then(res => res.json()).then((result) => {
          if(result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              enrollmentData:result.data.result.projectionData
            }, () => {
              this.projectionOnChange("ALL");
              this.setState({
                isGoButtonDisabled: false,
                isEnrollmentsDone: true
              })
            });
          }
        },(error) => {
          this.setState({
            isEnrollmentsDone: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  projectionOnChange = (mode) => {
    this.setState({
      enrollmentTable: this.state.enrollmentData[mode],
      projectionMode:mode
    });
    
  }

  changeSchoolValue = (value, triggerVal) => {
    this.setState({filterSchoolValue:value}, () => {
      if(this.state.isFiltersFirstLoad){
        if(triggerVal === "initial" || (triggerVal === "filter1" || triggerVal === "filter2")){
          this.modeData(value, triggerVal);
        }
      }
    });
  }

  changeModeValue = (value) => {
    this.setState({filterModeValue:value}, () => {
      if(this.state.isFiltersFirstLoad){
        this.state.filterModeValue = value;
      }
    });
  }

  redrawMatrix = () => {
    this.setState({
      isGoButtonDisabled: false
    }, () => {
      if(this.state.filterModeValue){
        // this.runEnrollmets("redraw");
        this.newEnrollmentData(this.state.filterModeValue);
      }
    })
  }

  modeData = (school, modeTrigger) => {
    try {
      this.setState({
        isFilterCoursesLoaded:false,
        isModeLoadDone: false
      });
  
      const params = {logged_in: this.state.logged_in, schoolId: school};
  
      if(Helper.numberValidation(params.schoolId)){
        ApiServices.ACCCeoProfileService.modeData(params).then(res => res.json()).then((result) => {
          if(result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              isModeLoadDone: true,
              modeFilterData:result.data.result,
              filterModeValue: result.data.result[0].modeId,
              isModeButtonDisabled: false
            }, () => {
              // if(this.state.filterModeValue !== "" && modeTrigger === "initial"){
              //   this.runEnrollmets(modeTrigger);
              // }
              if(this.state.filterModeValue !== "" && (modeTrigger === "initial" || modeTrigger === "redraw")){
                this.newEnrollmentData(this.state.filterModeValue);
              }
            });
          }
        },(error) => {
          this.setState({
            isModeLoadDone: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  // runEnrollmets = (modeTrigger) => {
  //   if(this.state.filterModeValue !== "" && (modeTrigger === "initial" || modeTrigger === "redraw")){
  //     this.newEnrollmentData(this.state.filterModeValue);
  //   }
  // }

  newEnrollmentData = (modeValue) => {
    try {
      this.setState({
        isFilterCoursesLoaded:false
      });
  
      const params = {logged_in: this.state.logged_in, schoolId: this.state.filterSchoolValue, modeId: modeValue, term: this.state.filterTermValue};
  
      if(!Helper.yearTermValidation(params.term) && Helper.numberValidation(params.schoolId) && Helper.numberValidation(params.modeId)){
        ApiServices.ACCCeoProfileService.newEnrollmentData(params).then(res => res.json()).then((result) => {
          if(result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              isEnrollmentLoadDone: true,
              enrollmentNewData:result.data.result.confirmedEnrolments["OC"]
            }, () => {
              this.setState({
                isModeButtonDisabled: false
              })
            });
          }
        },(error) => {
          this.setState({
            isEnrollmentLoadDone: false,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  // leadCounts = (mode, trigger) => {
  //   this.setState({
  //     leadCountsMode : mode,
  //     leadCountsChartLoaded: false,
  //     leadCountData: [],
  //     leadCountDataOC: [],
  //     leadCountDataDE: [],
  //     finaSchoolArr: []
  //   });
  //   // var tempSchoolArr = [4,5,1,7,8];
  //   // var tempSchoolArr = [1];

  //   if(this.state.filteredSchoolArr && this.state.filteredSchoolArr.length > 0){
  //     for (var i = 0; i < this.state.filteredSchoolArr.length; i++) {
  //       const params = {logged_in: this.state.logged_in, term: this.state.filterTermValue, school: this.state.filteredSchoolArr[i]};
  //       ApiServices.ACCCeoProfileService.leadCount(params, this.state.token).then(res => res.json()).then((result) => {
  //           if(Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
  //             this.setState({
  //               leadCountData: [...this.state.leadCountData, ...result.data.result.leadCounts['ALL'] ],
  //               leadCountDataOC: [...this.state.leadCountDataOC, ...result.data.result.leadCounts['OC'] ],
  //               leadCountDataDE: [...this.state.leadCountDataDE, ...result.data.result.leadCounts['DE'] ],
  //               finaSchoolArr: this.state.finaSchoolArr.concat(result.data.result.leadCounts.school)
  //             }, () => {
  //               if(this.state.finaSchoolArr.length === this.state.filteredSchoolArr.length){
  //                 this.leadCountOnChange("ALL");
  //                 this.setState({
  //                   leadCountsChartLoaded: true
  //                 });
  //               }
  //             });
              
  //           }
  //       },(error) => {
  //         this.setState({
  //           leadCountsChartLoaded: false
  //         });
  //       }) ;
  //     }
  //   }
  // }

  // leadCountOnChange = (mode) => {
  //   if(mode == 'ALL'){
  //     this.setState({
  //       leadCountsTable: this.state.leadCountData,
  //       leadCountMode:mode
  //     });
  //   }

  //   if(mode == 'OC'){
  //     this.setState({
  //       leadCountsTable: this.state.leadCountDataOC,
  //       leadCountMode:mode
  //     });
  //   }

  //   if(mode == 'DE'){
  //     this.setState({
  //       leadCountsTable: this.state.leadCountDataDE,
  //       leadCountMode:mode
  //     });
  //   }
  // }

  // changeLeadCount = (mode) => {
  //   this.leadCounts(mode, "redraw");
  // };

  // drawLeadCountChart = () => {
  //   let gThis = this;
  //   const data = gThis.state.leadCountsChart;
    
  //   if(typeof data !== 'undefined'){
  //     if(data.categories.length > 0 && data.series.length > 0){
  //       this.setState({
  //         leadCountChartOptions: {
  //           title: {
  //             text: ""
  //           },
  //           chart: {
  //             events: {
  //               load() {
  //                 this.showLoading();
  //                 setTimeout(this.hideLoading.bind(this), 2000);
  //               }
  //             },
  //             type:"column",
  //             animation: true,
  //             style: {
  //               color: "#444444"
  //             },
  //             zoomType: "x"
  //           },
  //           plotOptions: {
  //             series: {
  //               linewidth:6,
  //               borderRadius: 1,
  //             },
  //             column: {
  //               stacking: "normal",
  //               dataLabels: {
  //                 enabled: true
  //               }
  //             }
  //           },
  //           xAxis: {
  //             gridLineWidth: 1,
  //             gridLineColor: "#F3F3F3",
  //             lineColor: "#F3F3F3",
  //             minorGridLineColor: "#F3F3F3",
  //             tickColor: "#F3F3F3",
  //             tickWidth: 1,
  //             categories: data.categories,
  //             title: {
  //               text: "SCHOOL"
  //             },
  //             scrollbar: {
  //               enabled: true
  //             }
  //           },
  //           yAxis: {
  //             gridLineColor: "#F3F3F3",
  //             lineColor: "#F3F3F3",
  //             minorGridLineColor: "#F3F3F3",
  //             tickColor: "#F3F3F3",
  //             tickWidth: 1,
  //             title: {
  //               text: "LEAD COUNT"
  //             }
  //           },
  //           legend:{
  //             enabled:true,
  //             symbolHeight: 12,
  //             symbolWidth: 20,
  //             symbolRadius: 0
  //           },
  //           tooltip: {
  //             animation: true,
  //             useHTML: true,
  //             formatter: function() {
  //               var chart = this.series.chart,
  //               x = this.x,
  //               stackName = this.series.userOptions.stack,
  //               leadLabels = "";
          
  //               chart.series.forEach(function(series) {
  //                 series.points.forEach(function(point) {
  //                   if (point.category === x && stackName === point.series.userOptions.stack) {
  //                     leadLabels += "<div style='color:"+ point.color +"; font-weight: bold;'>" + point.series.name + ": " + point.y + "<br/>"
  //                   }
  //                 })
  //               })
  //               return "<div style='font-weight:bold;'>" +x + "</div>" + "<br/></div>" + leadLabels + "";
  //             }
  //           },
  //           credits: {
  //             enabled: false
  //           },
  //           legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
  //           background2: "#505053",
  //           dataLabelsColor: "#B0B0B3",
  //           textColor: "#C0C0C0",
  //           contrastTextColor: "#F0F0F3",
  //           maskColor: "rgba(255,255,255,0.3)",
  //           series: data.series
  //         }
  //       }, () => {
  //         this.setState({
  //           leadCountsChartLoaded: true
  //         });
  //       });
  //     }
  //   }
  // }

  render() {
    return (
      <Fragment>
        <div className="container-fluid " > 
          <div className="card custom-card  " style={{backgroundColor:"#e0e0e0",marginTop: "90px"}}> 
            <div className="row ">
              <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
                <div className="row ">
                  <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <h5 className="m-0">YEAR</h5>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits "  value={this.state.filterTermValue}
                                  onChange={(e) => this.changeTermValue(e.target.value)}>
                                      {this.state.isFilterTermsLoaded
                                  ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                  : <option>Loading...</option>
                                  }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
                <div>
                  <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled}  style={{marginTop: "24px",width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                </div>
              </div>
            </div>
          </div>     
    
          <div className="user-profile"> 
            <div className="row">
              <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                <div className="card custom-card  ">
                  <div className="row">
                    <div className="col-2 col-sm-2">
                      <img  src={accimage}></img>
                    </div>
                    <div className="col-5 col-sm-5 with-border">
                      <div className="student-name">
                        <h5><span className="counter">CEO DASHBOARD</span></h5>
                        <h4>{this.state.isStudentNameLoaded
                            ? this.state.studentName
                            : this.state.loader}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          {/* <div className="ceo-profile">
            <div className="card">
              <div className="row mb-4">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>BUDGET DATA</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                  <div className="card-header card-header-blue card-bg">
                    <h5>THIS YEAR</h5>
                  </div>
                  {this.state.isCardDone ?
                  <Fragment>
                  <div className="row mt-3">
                    <div className="col-4">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">BUDGET</span> <span className="card-value">{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.totalBudgetAmount.budgetAnnual : "")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">ACTUAL</span> <span className="card-value">{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.totalBudgetAmount.ytd : "")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">BALANCE</span> <span className="card-value" style={{color:this.state.annualBudgetData.totalBudgetAmount.balance < 0 ? "red" : "green"}}>{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.totalBudgetAmount.balance : "")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>

                <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                  <div className="card-header card-header-blue card-bg">
                    <h5>PREVIOUS YEAR</h5>
                  </div>
                  {this.state.isCardDone ?
                  <Fragment>
                  <div className="row mt-3">
                    <div className="col-4">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">BUDGET</span> <span className="card-value">{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.prevTotalBudgetAmount.budgetAnnual : "")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">ACTUAL</span> <span className="card-value">{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.prevTotalBudgetAmount.total : "")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card shadow">
                        <div className="card-body card-custom-padding">
                          <span className="card-text">BALANCE</span> <span className="card-value" style={{color:this.state.annualBudgetData.prevTotalBudgetAmount.balance < 0 ? "red" : "green"}}>{Helper.nFormatter(this.state.isCardDone ? this.state.annualBudgetData.prevTotalBudgetAmount.balance : "")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div> */}

          <div className="ceo-profile">
            <div className="card">
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>INCOME VS COST</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-2 mr-2">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  {this.state.isIncomeVsCostDone ?
                  <Fragment>
                    <div className="row">
                      <table className="table table-bordered custom">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">INCOME ($)</th>
                            <th scope="col">COSTS ($)</th>
                            <th scope="col">PROFIT ($)</th>
                            <th scope="col">CASH ($)</th>
                            <th scope="col">DEBT PER STUDENT ($)</th>
                          </tr>
                        </thead>
                        <tbody>
                        { Object.keys(this.state.incomVsCostData.incomeVsCostData).length > 0 ?
                            this.state.incomVsCostData.incomeVsCostData.map((item) => 
                              item.schoolName !== "-" ?
                                <tr key={item.schoolName}>
                                  <th scope="row">{item.schoolName !== "-" ? item.schoolName : ""}</th>
                                  <td className="custom-td-center">{item.totalIncome.toLocaleString()}</td>
                                  <td className="custom-td-center">{item.totalExpense.toLocaleString()}</td>
                                  <td className="custom-td-center" style={{backgroundColor:item.netProfit < 0 ? "#FFBFBF" : ""}}>{item.netProfit.toLocaleString()}</td>
                                  <td className="custom-td-center" style={{backgroundColor:item.totalCashAvailable < 0 ? "#FFBFBF" : ""}}>{item.totalCashAvailable.toLocaleString()}</td>
                                  <td className="custom-td-center">{item.debtPerStudent.toLocaleString()}</td>
                                </tr>
                              : null
                            )
                          : <tr><td colSpan="6" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                        }
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="ceo-profile">
            <div className="card">
              <div className="row mb-4">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>MANAGEMENT DATA</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>STUDENT TO TEACHER RATIO BY SCHOOL <span>STUDENT TO TEACHER RATIO = STUDENT ENROLMENT / TEACHER FTE</span></h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-2 mr-2">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  {this.state.isManagementIsDone ?
                  <Fragment>
                    <div className="row">
                      <table className="table table-bordered custom">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">OC ACTUAL</th>
                            <th scope="col">DC ACTUAL</th>
                            <th scope="col">BUDGET</th>
                          </tr>
                        </thead>
                        <tbody>
                        { Object.keys(this.state.managementData.oeVsDeData).length > 0 ?
                            this.state.managementData.oeVsDeData.map((item) => 
                              item.schoolName !== "" ?
                                <tr key={item.schoolName}>
                                  <th scope="row">{item.schoolName !== "" ? item.schoolName :""}</th>
                                  <td className="custom-td-right">{item.ocActual !== "" ? item.ocActual : "-"}</td>
                                  <td className="custom-td-right">{item.deActual !== "" ? item.deActual : "-"}</td>
                                  <td className="custom-td-right">{item.actualBudget !== "" ? item.actualBudget : "-"}</td>
                                </tr>
                              : null
                            )
                          : <tr><td colSpan="6" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                        }
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div>

          {/* <div className="ceo-profile">
            <div className="card">
              <div className="row mb-4">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>ENROLMENT DATA</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>CURRENT LEAD COUNT BY SCHOOL
                    <span> OC <Switch onChange={() => this.leadCountOnChange("OC")} checked={this.state.leadCountMode == "OC" ? true : false} /> &nbsp; DE <Switch onChange={() => this.leadCountOnChange("DE")} checked={this.state.leadCountMode == "DE" ? true : false} /> &nbsp; ALL <Switch  onChange={() => this.leadCountOnChange("ALL")} checked={this.state.leadCountMode == "ALL" ? true : false} /></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="user-status table-responsive">
                        {this.state.leadCountsChartLoaded ?
                          <table className="table table-bordered custom">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">ENROLMENT APPLICATION (THIS YEAR)</th>
                                <th scope="col">PRINCIPAL INTERVIEW (THIS YEAR)</th>
                                <th scope="col">ENROLMENT CONFIRMATION (THIS YEAR)</th>
                                <th scope="col">ENROLMENT APPLICATION (NEXT YEAR)</th>
                                <th scope="col">ENROLMENT CONFIRMATION (NEXT YEAR)</th>
                              </tr>
                            </thead>
                            <tbody>
                            { Object.keys(this.state.leadCountsTable).length > 0 ?
                                this.state.leadCountsTable.map((item) => 
                                  item.schoolName !== "" ?
                                    <tr key={item.schoolName}>
                                      <th scope="row">{item.schoolName !== "" ? item.schoolName :""}</th>
                                      <td className="custom-td-center">{item.ThisYearEA !== "" ? item.ThisYearEA : "-"}</td>
                                      <td className="custom-td-center">{item.ThisYearPI !== "" ? item.ThisYearPI : "-"}</td>
                                      <td className="custom-td-center">{item.ThisYearEC !== "" ? item.ThisYearEC : "-"}</td>
                                      <td className="custom-td-center">{item.NextYearEA !== "" ? item.NextYearEA : "-"}</td>
                                      <td className="custom-td-center">{item.NextYearEC !== "" ? item.NextYearEC : "-"}</td>
                                    </tr>
                                  : null
                                )
                              : <tr><td colSpan="6" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                            }
                            </tbody>
                          </table>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="ceo-profile">
            <div className="card">
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>ENROLMENT BY SCHOOL</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-2 mr-2">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  {this.state.isManagementIsDone ?
                  <Fragment>
                    <div className="row">
                      <table className="table table-bordered custom">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">ACTUAL ENROLMENT <p> OC </p></th>
                            <th scope="col">BUDGETED ENROLMENT <p> OC </p></th>
                            <th scope="col">ACTUAL ENROLMENT <p> DE </p></th>                
                            <th scope="col">BUDGETED ENROLMENT <p> DE </p></th>
                          </tr>
                        </thead>
                        <tbody>
                        { Object.keys(this.state.managementData.enrollementBySchool).length > 0 ?
                            this.state.managementData.enrollementBySchool.map((item) => 
                              item.schoolName !== "" ?
                                <tr key={item.schoolName}>
                                  <th scope="row">{item.schoolName !== "" ? item.schoolName :""}</th>
                                  <td className="custom-td-center">{item.actualEnrollemntOc !== "" ? item.actualEnrollemntOc : "-"}</td>
                                  <td className="custom-td-center">{item.budgetEnrollemntOc !== "" ? item.budgetEnrollemntOc : "-"}</td>
                                  <td className="custom-td-center">{item.actualEnrollemntDe !== "" ? item.actualEnrollemntDe : "-"}</td>
                                  <td className="custom-td-center">{item.budgetEnrollemntDe !== "" ? item.budgetEnrollemntDe : "-"}</td>
                                </tr>
                              : null
                            )
                          : <tr><td colSpan="5" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                        }
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="ceo-profile">
            <div className="card">
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>STUDENT ENROLMENT PROJECTION
                    <span> OC <Switch onChange={() => this.projectionOnChange("OC")} checked={this.state.projectionMode == "OC" ? true : false} /> &nbsp; DE <Switch onChange={() => this.projectionOnChange("DE")} checked={this.state.projectionMode == "DE" ? true : false} /> &nbsp; ALL <Switch  onChange={() => this.projectionOnChange("ALL")} checked={this.state.projectionMode == "ALL" ? true : false} /></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-2 mr-2">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  {this.state.isEnrollmentsDone ?
                  <Fragment>
                    <div className="row">
                      <table className="table table-bordered custom">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">CURRENT ENROLMENT</th>
                            <th scope="col">WITHDRAWALS (THIS YEAR)</th>
                            <th scope="col">WITHDRAWALS (NEXT YEAR) + LEAVERS</th>
                            <th scope="col">FUTURE ENROLMENTS (THIS YEAR)</th>
                            {/* <th scope="col">FUTURE ENROLMENTS (NEXT YEAR)</th> */}
                            <th scope="col">PROJECTION (NEXT YEAR)</th>
                          </tr>
                        </thead>
                        <tbody>
                          { Object.keys(this.state.enrollmentTable).length > 0 ?
                            this.state.enrollmentTable.map((item) => 
                              item.schoolName !== "" ?
                                <tr key={item.schoolName}>
                                  <th scope="row">{item.schoolName !== "" ? item.schoolName :""}</th>
                                  <td className="custom-td-center">{item.totalCurrentEnrollment !== "" ? item.totalCurrentEnrollment : "-"}</td>
                                  <td className="custom-td-center">{item.currentYearWithdrawals !== "" ? item.currentYearWithdrawals : "-"}</td>
                                  <td className="custom-td-center">{item.combineColumn !== "" ? item.combineColumn : "-"}</td>
                                  <td className="custom-td-center">{item.currentYearEnrollments !== "" ? item.currentYearEnrollments : "-"}</td>
                                  {/* <td className="custom-td-center">{item.nextYearEnrollments !== "" ? item.nextYearEnrollments : "-"}</td> */}
                                  <td className="custom-td-center" style={{ fontWeight: "bold"}}>{item.nextMinProjection !== "" ? item.nextMinProjection : "-"}</td>
                                </tr>
                              : null
                            )
                            : <tr><td colSpan="10" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="ceo-profile">
            <div className="card">
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card-header card-header-blue">
                    <h5>NEW ENROLMENTS OVER THE TIME</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-2 mr-2">
                <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row ">
                    <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
                      <div className="row ">
                        <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                          <div className="card o-hidden custom-cardx">
                            <div className="card-body">
                              <div className="media static-top-widget">
                                <div className="media-body">
                                  <span className="m-0">SCHOOL</span>
                                  <h4 className="mb-0 counter">
                                    <select className="form-control digits "  value={this.state.filterSchoolValue} onChange={(e) => this.changeSchoolValue(e.target.value, "filter1")}>
                                      {this.state.isFiltersLoadDone
                                      ? this.state.enrollmentFilterData.map((filterSchool) => <option key={filterSchool.schoolId} value={filterSchool.schoolId}>{filterSchool.schoolName}</option>)
                                      : <option>Loading...</option>
                                      }
                                    </select>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                          <div className="card o-hidden custom-cardx">
                            <div className="card-body">
                              <div className="media static-top-widget">
                                <div className="media-body">
                                  <span className="m-0">MODE</span>
                                  <h4 className="mb-0 counter">
                                    <select className="form-control digits "  value={this.state.filterModeValue} onChange={(e) => this.changeModeValue(e.target.value, "filter2")}>
                                      {this.state.isModeLoadDone
                                      ? this.state.modeFilterData.map((modFilter) => <option key={modFilter.modeId} value={modFilter.modeId}>{modFilter.modeName}</option>)
                                      : <option>Loading...</option>
                                      }
                                    </select>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
                      <div>
                      <button disabled={this.state.isModeButtonDisabled} readOnly={this.state.isModeButtonDisabled}  style={{marginTop: "24px",width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawMatrix()}>GO</button>
                      </div>
                    </div>
                  </div>
                  
                  { this.state.isEnrollmentLoadDone ?
                    <Fragment>
                      <div className="row">
                        <table className="table table-bordered custom">
                          <thead>
                            <tr>
                              <th scope="col" key="GRADE"></th>
                              {
                                this.state.months.map(month => <th scope="col" key={month}>{month}</th>)
                              }
                              <th scope="col" key="TOTAL">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            { Object.keys(this.state.enrollmentNewData).length > 0 ?
                              this.state.enrollmentNewData.map((grade,index) => {
                              return <tr key={index}>
                                <th scope="row" key={grade.GRADE}>{grade.GRADE}</th>
                                  {
                                    this.state.months.map(month => <td className="custom-td-center" scope="col" key={month} >{grade[month]}</td>)
                                  }
                                <td className="custom-td-center" key={grade.TOTAL}>{grade.TOTAL}</td>
                              </tr>
                              })
                              : <tr><td colSpan="14" className="custom-nodata" >NO DATA AVAILABLE</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                    : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
}
export default withRouter(CEOProfile);