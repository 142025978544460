import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
    makeVwDealershipMainReportDataDepartmentResponseData,
    makeVwDealershipSidebarSelectDataResponse
} from "../../../../../selectors/vwDealership/dealership.selector";
import {
    makeVwDealershipOnlineModuleListsDataResponse,
    makeVwDealershipOnlineModuleSelectedDataResponse,
    makeVwDealershipOnlineModuleSelectedDataLoadingResponse,
    makeVwDealershipOnlineModuleSelectedDataSelectedModuleResponse
} from "../../../../../selectors/vwDealership/mandatoryOnline.selector";
import {fetchingModulesData, fetchStudentsData} from "../../../../../actions/vwDealership/mandatoryOnline.action";

import OnlineModule from "./OnlineModuleView";

const OnlineModuleContainer = () => {
    const [showDataTable, setShowDataTableData] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const dispatch = useDispatch();

    // selectors
    const departments = useSelector(makeVwDealershipMainReportDataDepartmentResponseData);
    const cards = useSelector(makeVwDealershipOnlineModuleListsDataResponse);
    const sidebarDataSelected = useSelector(makeVwDealershipSidebarSelectDataResponse);
    const studentsData = useSelector(makeVwDealershipOnlineModuleSelectedDataResponse);
    const studentsDataLoading = useSelector(makeVwDealershipOnlineModuleSelectedDataLoadingResponse);
    const selectedModule = useSelector(makeVwDealershipOnlineModuleSelectedDataSelectedModuleResponse);

    useEffect(() => {
        if (departments) {
            setSelectedDepartments(departments.map(department => department.departmentId));
        }
    }, [departments])

    const onCardClick = (data) => {
        dispatch(fetchStudentsData({...data, departments:selectedDepartments}));
    }

    const onShowDataTable = (data) => {
        onCardClick(data)
        setShowDataTableData(true);
    };

    const handleCancel = () => {
        setShowDataTableData(false);
    };

    const onDepartmentChange = (departments) => {
        const filteredDepartments =  departments.filter(item => item !== "all");
        const requestParams = {
            dealerships: sidebarDataSelected.dealership,
            departments: filteredDepartments
        }
        dispatch(fetchingModulesData(requestParams));
        setSelectedDepartments(filteredDepartments);
    }

    return (
        <OnlineModule
            cards={cards}
            departments={departments}
            dataTableConfig={{onShowDataTable, handleCancel}}
            showDataTable={showDataTable}
            onChange={onDepartmentChange}
            studentsDataLoading={studentsDataLoading}
            tableData={{name: `${selectedModule.name}(${selectedModule.id})`, students: studentsData}}
        />
    )
}

export default OnlineModuleContainer;