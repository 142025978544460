import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        return (
            localStorage.getItem('authUser')
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/tassweb/login', state: { from: props.location } }} />
        );
    }} />
)

const mapStateToProps = (state) => {
    return {
        Authentication: state.Authentication
    }
}

export default connect(mapStateToProps)(PrivateRoute);