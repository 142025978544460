import React from "react";
import {Col, List, Row, Typography, Button, Switch} from "antd";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";

const canvasLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/canvas-logo-only.svg`;
const mysqlLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/mysql.svg`;
const csvLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/csv.svg`;

const {Paragraph} = Typography;

const LiveConnections = () => {
    const connectionList = [
        {
            name: "Canvas Data Source 1",
            icon: canvasLogo,
            createdDate: "05/24/2021",
            ip: "10.200.50.5",
            port: "1033",
            platform: "MSSQL",
        },
        {
            name: "Canvas Data Source 1",
            icon: mysqlLogo,
            createdDate: "05/24/2021",
            ip: "10.200.50.5",
            port: "1033",
            platform: "MSSQL",
        },
        {
            name: "Canvas Data Source 1",
            icon: csvLogo,
            createdDate: "05/24/2021",
            ip: "10.200.50.5",
            port: "1033",
            platform: "MSSQL",
        },

    ]

    const renderConnectionItemButton = () => {
        return (
            <div className="dm-card-item-buttons">
                <span style={{marginRight: "5%"}}><Switch name="enableConnection"/> Enable</span>
                <Button type="primary" ghost className="item-button" icon={<EditOutlined/>}/>
                <Button danger ghost className="item-button" icon={<DeleteOutlined/>}/>
            </div>
        )
    }

    const renderConnectionItem = (connection) => {
        return (
            <div className="connection-item">
                <List.Item>
                    <div className="dm-card-list-item">
                        <Row justify="start" gutter={16}>
                            <Col sm={24} md={12} lg={17} xl={16} xxl={12}>
                                <img
                                    className="resource-image-logo"
                                    align="center"
                                    width={50}
                                    height={50}
                                    alt="connection item logo"
                                    src={connection.icon || null}
                                />

                                <div className="dm-ac-card-data">
                                    <Paragraph className="title" strong>{connection.name}</Paragraph>
                                    <p className="sub-data">Created Date: <span className="sub-data-value">{connection.createdDate}</span></p>
                                    <p className="sub-data">

                                        <span>IP: <span className="sub-data-value cell">{connection.ip}</span></span>
                                        <span>Port: <span className="sub-data-value cell">{connection.port}</span></span>
                                        <span>Platform: <span className="sub-data-value cell">{connection.platform}</span></span>

                                    </p>
                                </div>

                            </Col>
                            <Col md={12} lg={7} xl={8} xxl={12}>
                                <div>
                                    {renderConnectionItemButton()}
                                </div>
                            </Col>
                        </Row>
                    </div>

                </List.Item>
            </div>
        )

    }
    const renderConnectionList = () => {
        return (
            <List
                itemLayout="vertical"
                dataSource={connectionList}
                renderItem={connection => (
                    renderConnectionItem(connection)
                )}
            />
        )
    }
    return (
        <div>
            {renderConnectionList()}
        </div>
    )
}

export default LiveConnections;