import React, { useState, useEffect , Fragment} from 'react'
import ReactTooltip from 'react-tooltip';
import Helper from "./../common/Helper";

const CardComponent = (props) => {
    const [value] = useState(props.value);
    const [percentage] = useState(props.percentage);
    const [id] = useState(props.id);

    const [cardColor, setCardColor] = useState(value);
    const getCardColor = (percentage) => { 
        if(!isNaN(percentage)){          
            if(percentage<=0){
                setCardColor("card-red");
            }
            else if(0<percentage && percentage<40){
                setCardColor("card-yellow");
            }
            else if(percentage>=40){
                setCardColor("card-green");
            }
        }else{
            return setCardColor("");
        }
    }
    useEffect(() => getCardColor(props.percentage), [props.percentage]);
    return ( 
        <Fragment>
            {/* <div data-tip="React-tooltip" data-for={props.id} className="tooltip-box"></div>
                <ReactTooltip className='table-custom-tooltip' id={props.id} place="top" type="light" effect="float" data-html={true}>
                <ul>
                    <li>NEGATIVE VALUE<span className="tooltip-box card-red"></span></li>
                    <li style={{display:"inline-block"}}>VALUE BETWEEN 0 AND 40<span className="tooltip-box card-yellow"></span></li>
                    <li style={{display:"inline-block"}}>VALUE HIGHER THAN 40<span className="tooltip-box card-green"></span></li>
                </ul>
                </ReactTooltip>  */}
            <div className={`${cardColor} box textCard`} >
                {/* {!isNaN(value) ? <h1>{value < 0 ? `(${Helper.numberFormat(Math.abs(value),"NC")}%)`:`${Helper.numberFormat(value,"NC")}%`}</h1> : <h4>No Data</h4>} */}
                
                <div className="row">
                    <div className="col">
                        <div className="cardSegment">
                            <div className="row">
                                <div className="col-12 ">
                                     <h5>{id == 'NPM' ? "Net Profit Margin" : "Gross Profit Margin"} </h5>   
                                </div>
                                <div className="col-12 ">
                                <h4>{!isNaN(percentage) ? percentage < 0 ? `(${Helper.numberFormat(Math.abs(percentage),"NC")}%)`:`${Helper.numberFormat(percentage,"NC")}%` : `No Data`}</h4>   
                                </div>
                            </div>
                        </div>   
                        <div className="smallTextBlock">                  
                           
                            <h6>{id == 'NPM' ? "Net Profit" : "Gross Profit"} : {!isNaN(value) ? value < 0 ? `$(${Helper.numberFormat(Math.abs(value))})`:`$${Helper.numberFormat(value)}` : `No Data`}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default CardComponent;
