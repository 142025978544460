import {
    FETCH_DEALERSHIP_SIDEBAR_DATA,
    FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING,
    FETCH_DEALERSHIP_MAIN_REPORT_DATA,
    FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING,
    SET_DEALERSHIP_FILTERED_SIDEBAR_DATA,
    CLEAR_DEALERSHIP_SIDEBAR_DATA,
} from "../../constant/actionTypes";

import service from "../../utils/serviceHandlers/jobReady/customServices/vwDealership";

/** Clear Dealerships data **/
// clear dealerships data of sidebar
export const clearDealershipsData = () => dispatch => {
    dispatch({
        type: CLEAR_DEALERSHIP_SIDEBAR_DATA,
    });
};

/** Sidebar data fetch **/
// fetch sidebar data loading
const fetchingSidebarDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch sidebar data handler
const fetchSidebarDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_DEALERSHIP_SIDEBAR_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch sidebar data
export const fetchDealershipSidebarData = (data) => dispatch => {
    dispatch(fetchingSidebarDataLoading());

    service.make({
        service:`insights/jrp/vga/mandatory/fetchFilters`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchSidebarDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchSidebarDataStatus({
            error: error.message,
        }))
    })
};


/** Main report data fetch **/
// fetch main report data loading
const fetchingMainReportDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch main report data handler
const fetchMainReportDataStatus = (response, selected=null) => dispatch => {
    dispatch({
        type: FETCH_DEALERSHIP_MAIN_REPORT_DATA,
        payload: {
            isLoading: false,
            error: response.error,
             ...(response.data && {data: response.data}),
             ...(selected && {selected}),
        },
    });
};

// fetch main report data
export const fetchingMainReportData = (data) => dispatch => {
    dispatch(fetchingMainReportDataLoading());

    service.make({
        service:`insights/jrp/vga/mandatory/applyFilters`,
        method: "POST",
        data: {dealerships:data.dealership}
    }).then(response => {
        dispatch(fetchMainReportDataStatus({
            data: response.data
        }, data))
    }).catch(error => {
        dispatch(fetchMainReportDataStatus({
            error: error.message
        }, data))
    })
};

/** Sidebar filtered data set **/
// set filtered sidebar data
export const setFilteredSidebarData = (data, selected=false) => dispatch => {
    dispatch({
        type: SET_DEALERSHIP_FILTERED_SIDEBAR_DATA,
        payload: {
            data,
            ...(selected && {selected: data})
        },
    });
};


