import {
    FETCH_SETTINGS,
    FETCH_SETTINGS_LOADING,
    SAVE_SETTINGS,
    SAVE_SETTINGS_LOADING,
} from "../../constant/actionTypes";
import request from "../../utils/request";
import session from "../../utils/session";
import {showNotification} from "../../utils/general";
import {getAccessToken} from "../../utils/general";

// Fetch
// handle fetch loading state for settings fetch request
export const fetchingSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the settings fetch request
export const setSettingsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: FETCH_SETTINGS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle settings fetch request
export const fetchSettings = (payload) => dispatch => {
    dispatch(fetchingSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        setTimeout(
            () => dispatch(setSettingsFetchStatus(response)),
            500
        );
    }

    const requestOptions = {
        service: `insights/settings/fetch/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// Save / update
// handle save / update loading state for settings fetch request
export const savingSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the settings save request
export const setSettingsSaveStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: SAVE_SETTINGS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
  };
  
  // color/resetToDefault

export const resetDefault = (payload) => (dispatch) => {
  dispatch(savingSettings());

  const feedReducer = (response) => {
    dispatch(setSettingsSaveStatus(response));
    dispatch(
      fetchSettings({
        requestParams: payload,
      })
    );
    showNotification({
      message: "Success",
      description: "Settings set to default successfully",
    });
  };

  const requestOptions = {
    service: `insights/settings/resetToDefault/${getAccessToken()}`,
    method: "POST",
    data: { ...payload },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

// handle settings save request
export const saveSettings = (payload) => dispatch => {
    dispatch(savingSettings());

    const feedReducer = (response) => {
        dispatch(setSettingsSaveStatus(response));
        dispatch(fetchSettings({
            requestParams: payload.params
        }));

        if(!response.error){
            showNotification({
                message: 'Success',
                description: 'Settings saved successfully'
            })
        }

    }

    const requestOptions = {
        service: `insights/settings/save/${getAccessToken()}`,
        method: "POST",
        data: {...payload},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};
