import React, { useState } from "react";
import { Modal, Typography, Space, Button, Row, Radio } from "antd";
import { WarningFilled } from "@ant-design/icons"
import PropTypes from "prop-types";

const ConfirmationPopupGlobal = ({
  isModalVisible,
  setIsModalVisible,
  onConfirm,
  okText,
  cancelText,
  modalConfig,
}) => {
  const [value, setValue] = useState(1);

  const onChangeOption = (e) => {
    setValue(e.target.value);
  };
  return (
    <Modal title={modalConfig?.title} closable={false} visible={isModalVisible} footer={null} centered className="octo-confirm-popup">
      <Typography.Paragraph>{modalConfig.subtitle}</Typography.Paragraph>
      <Radio.Group onChange={onChangeOption} value={value}>
        <Space direction="vertical">
          <Radio value={1}>
            {modalConfig.optionOne}
            {value === 1 && (
              <div className="octo-error-messege">
                <WarningFilled style={{ color: '#ff0000' }} /> {modalConfig.messageLineOne}
              </div>
            )}
          </Radio>
          <Radio value={2}>
            {modalConfig.optionTwo}
            {value === 2 && (
              <div className="octo-error-messege">
                <WarningFilled style={{ color: '#ff0000' }} /> {modalConfig.messageLineTwo}
              </div>
            )}
          </Radio>
        </Space>
      </Radio.Group>


        <div className="button-section">
          <Space>
            <Button
              size="large"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              {cancelText}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                const tmpPayload = { overide: value === 1 }
                onConfirm(tmpPayload, modalConfig.type);
                setIsModalVisible(false);
              }}
            >
              {okText}
            </Button>
          </Space>
        </div>

    </Modal>
  );
};

ConfirmationPopupGlobal.defaultProps = {
  okText: "Apply",
  cancelText: "Cancel",
};

ConfirmationPopupGlobal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  onConfirm: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  modalConfig: PropTypes.object
};

export default ConfirmationPopupGlobal;
