import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = React.forwardRef((props, ref) => {
  return (
    <ReCAPTCHA
      ref={ref}
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      size="invisible"
      onErrored={(error) => {
        console.error("ReCAPTCHA Error:", error);
      }}
    />
  );
});

export default Recaptcha;
