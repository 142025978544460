import React from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const Loader = ({children, isLoading, ...rest}) => {
    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

    return (
        <Spin className={'jr-gen-loader'} indicator={antIcon} spinning={isLoading} {...rest}>
            {children}
        </Spin>
    )
}

export default Loader;