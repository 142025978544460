import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout, Row, Space, Tabs } from "antd";

import Sidebar from "./partials/sidebar";
import SidebarUnits from "./partials/sidebarUnits";
import UnitsTable from "./partials/unitsTable";
import UnitsTableOrder from './partials/unitsTableOrder';
import WarningMessage from "./partials/unitsTable/partials/Warning";
import Loader from "../common/layout/Loader";
import UnitTableBreadcrumb from "./partials/breadcrumb/Breadcrumb";
import LayoutFooter from "../common/layout/LayoutFooter";

const { Content } = Layout;
const { TabPane } = Tabs;

const DealershipSettingsView = ({ units, unitOrderList, warningConfig, breadcrumbConfig, isLoading, handleTabChange, activeKey }) => {
    return (
        <div>
            <Loader isLoading={isLoading} />
            <Tabs onChange={handleTabChange} className="vwSettingsTabs"  activeKey={activeKey}>
                <TabPane tab="Set Order" key="1">
                    <Layout style={{ minHeight: '100%' }}>
                        <SidebarUnits setWarningVisible={warningConfig.setVisible} />
                        <Layout className="site-layout dealership-main-container" style={{ backgroundColor: "white" }}>
                            <Content style={{ margin: '0 16px' }}>
                                <Space size={"large"} direction={"vertical"} style={{ width: "100%" }}>
                                    <UnitsTableOrder units={unitOrderList} />
                                </Space>
                            </Content>
                            <LayoutFooter />
                        </Layout>
                        <WarningMessage {...warningConfig} />
                    </Layout>
                </TabPane>
                <TabPane tab="Mark as Mandatory" key="2">
                    <Layout style={{ minHeight: '100%' }}>
                        <Sidebar setWarningVisible={warningConfig.setVisible} setBreadcrumb={breadcrumbConfig.setBreadcrumb} />
                        <Layout className="site-layout dealership-main-container" style={{ backgroundColor: "white" }}>
                            <Content style={{ margin: '0 16px' }}>
                                <Space size={"large"} direction={"vertical"} style={{ width: "100%" }}>
                                    <Row>
                                        <UnitTableBreadcrumb items={breadcrumbConfig.breadcrumb} />
                                    </Row>
                                    <UnitsTable units={units} />
                                </Space>
                            </Content>
                            <LayoutFooter />
                        </Layout>
                        <WarningMessage {...warningConfig} />
                    </Layout>
                </TabPane>
            </Tabs>

        </div>
    )

}

DealershipSettingsView.propTypes = {
    units: PropTypes.array,
    unitOrderList: PropTypes.array,
    isLoading: PropTypes.bool,
    warningConfig: PropTypes.object,
    breadcrumbConfig: PropTypes.object,
    handleTabChange: PropTypes.func,
    activeKey: PropTypes.any
}

export default memo(DealershipSettingsView);