import React from "react";
import {Modal, Table, Input, Row, Col} from "antd";
import PropTypes from "prop-types";

import Loader from "../../../common/layout/Loader";

const {Search} = Input;

const DataTable = ({visible, handleCancel, data, pagination, onSearch, columns, isLoading, handleChangeTable}) => {

    const renderTable = () => {

        return (
            <Table
                className="vw-data-table"
                columns={columns}
                dataSource={data.students}
                pagination={pagination}
                size="small"
                onChange={handleChangeTable}
            />
        )

    }

    const renderModalHeader = () => {
        if(data && !isLoading){
            return (
                <Row display="flex" justify="space-between" style={{marginRight:30}} gutter={[16, 16]}>
                    <Col xs={24} sm={24} lg={12} >
                        <div className="vw-title">Course: {data.name}</div>
                    </Col>
                    <Col xs={24} sm={24} lg={4}>
                        <Search style={{float:"right"}} onSearch={onSearch} onChange={onSearch} width={50} placeholder="Search Student"/>
                    </Col>
                </Row>
            )
        }
    }

    return (
        <Modal
            closable
            title={renderModalHeader()}
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            width={1500}
            maskClosable={false}
            style={{minWidth:"max-content"}}
        >
            <Loader isLoading={isLoading}/>
                {renderTable()}
        </Modal>
    )
}
DataTable.defaultProps = {
    isLoading: false,
}

DataTable.propTypes = {
    isModalVisible: PropTypes.bool,
    handleCancel: PropTypes.func,
    columns: PropTypes.array,
    data: PropTypes.object,
    onSearch: PropTypes.func,
    pagination: PropTypes.object,
    isLoading: PropTypes.bool,
    handleChangeTable: PropTypes.func,
}

export default DataTable;