import React from "react";
import PropTypes from "prop-types";
import {Typography} from "antd";

import LineChart from "../../../../../common/charts/LineChart";

import {lineChartConf} from "./chartConf";

const LineChartCard = ({title, data}) => {


    const customConf = {
        xAxis: {
            categories: data?.categories
        },
        series: data?.seriesData
    }

    return (
        <div>
            {title &&
            <Typography.Title level={5}>{title}</Typography.Title>
            }
            <LineChart chartOptions={{...lineChartConf, ...customConf}}/>
        </div>
    )
}

LineChartCard.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
}

export default LineChartCard;