export const resolveCohortVisibility = (role, data, config) => {
    const {compareVal, returnVal} = config;
    if (Array.isArray(data)){
        const rec = data.find(item => item[compareVal] === role);
        if(rec){
            return rec[returnVal];
        }else{
            return false;
        }

    }else{
        return data;
    }

}