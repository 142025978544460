import _get from "lodash/get";

// return innovative settings response
export const makeInnovativeSettingsResponse = state => state && state.innovative && state.innovative.settings && state.innovative.settings.selected;

// return innovative settings response loading status
export const makeInnovativeSettingsLoading = state => {
    const result = makeInnovativeSettingsResponse(state);
    return result && result.isLoading;
};

// return innovative settings response data
export const makeInnovativeSettingsResponseData = state => {
    const result = makeInnovativeSettingsResponse(state);
    return result && result.response && result.response.data;
};

// return innovative settings response error
export const makeInnovativeSettingsError = state => {
    const result = makeInnovativeSettingsResponse(state);
    return result.error;
};

// return innovative setting by path
export const makeInnovativeSettingByPath = path => state => {
    const result = makeInnovativeSettingsResponseData(state);
    return _get(result, path, undefined);
};


// return innovative settings response
export const makeInnovativeSettingsSaveResponse = state => state && state.innovative && state.innovative.settings && state.innovative.settings.saved;

// return innovative settings response loading status
export const makeInnovativeSettingsSaveLoading = state => {
    const result = makeInnovativeSettingsSaveResponse(state);
    return result && result.isLoading;
};

// return innovative settings response data
export const makeInnovativeSettingsSaveResponseData = state => {
    const result = makeInnovativeSettingsSaveResponse(state);
    return result && result.response && result.response.data;
};

// return innovative settings response error
export const makeInnovativeSettingsSaveError = state => {
    const result = makeInnovativeSettingsSaveResponse(state);
    return result.error;
};

// return innovative settings response data
export const makeInnovativeSettingsClusterEnableData = state => {
    const result = makeInnovativeSettingsResponse(state);
    return _get(result, 'response.data.cluster.cluster_enable');
};

// return Analyze Enable Status 
export const getAnalyzeEnableStatus = state => {
    const result = makeInnovativeSettingsResponse(state);
    return _get(result, 'response.data.cluster.cluster_analyzer_enable');
};