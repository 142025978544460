import {combineReducers} from "redux";
import layout from "./layout.reducer";
import settings from "./settings.reducer";
import course from "./course.reducer";
import assignment from "./assignment.reducer";
import student from "./student.reducer";
import generic from "./generic.reducer";

const innovativeReducers = combineReducers({
    layout,
    settings,
    course,
    assignment,
    student,
    generic,
});

export default innovativeReducers;