const data = [
    {
        id: 1,
        url:"widgets/general",
        img: require("../assets/images/ecommerce/product-table-1.png",),
        name: "Student Profile",
        note: "Student Engagement data",
        discription: "Review individual performance, workload and engagement. For Teachers Parents and Students",
        discountPrice: 350.00,
        status:"free",
        price: 260.00,
        stock: "In stock",
        review: "(250 review)",
        category: "Men",
        colors: [
            "green",
            "gray"
        ],
        size: [
            "M",
            "L",
            "XL"
        ],
        tags: [
            "Student Profile",
            
        ],
        variants: [
            {
                color: "White",
                images: require("../assets/images/ecommerce/01.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/04.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/02.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/03.jpg"),
            }
        ]
    } ,
    {
        id: 2,
        url:"widgets/general",
        img: require("../assets/images/ecommerce/product-table-2.png",),
        name: "Teacher Profile",
        note: "Teacher Dashboard",
        discription: "Review Class performance, workload and engagement. For Teachers, Heads of Department and Executives",
        discountPrice: 350.00,
        status:"free",
        price: 10.00,
        stock: "In stock",
        review: "(250 review)",
        category: "Men",
        colors: [
            "green",
            "gray"
        ],
        size: [
            "M",
            "L",
            "XL"
        ],
        tags: [
           "Teacher Dashboard"
        ],
        variants: [
            {
                color: "White",
                images: require("../assets/images/ecommerce/01.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/04.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/02.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/03.jpg"),
            }
        ]
    } ,
    {
        id: 3,
        url:"widgets/general",
        img: require("../assets/images/ecommerce/product-table-2.png",),
        name: "Students Profile New",
        note: "Students Dashboard New",
        discription: "Review Class performance, workload and engagement. For Teachers, Heads of Department and Executives",
        discountPrice: 350.00,
        status:"free",
        price: 10.00,
        stock: "In stock",
        review: "(250 review)",
        category: "Men",
        colors: [
            "green",
            "gray"
        ],
        size: [
            "M",
            "L",
            "XL"
        ],
        tags: [
           "Students Profile New"
        ],
        variants: [
            {
                color: "White",
                images: require("../assets/images/ecommerce/01.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/04.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/02.jpg"),
            },
            {
                color: "gray",
                images: require("../assets/images/ecommerce/03.jpg"),
            }
        ]
    } 
]

export default data;