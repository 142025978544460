export default {
    innovative_courses: {
        dashboard_layout: "insightsTeacherMain",
    },
    innovative_students: {
        dashboard_layout: "insightsTeacherStudent"
    },
    innovative_tasks: {
        dashboard_layout: "insightsTeacherTask",
    },
    insights_student_main: {
        dashboard_layout: "insightsStudentMain",
    },
    insights_student_tasks: {
        dashboard_layout: "insightsStudentTask",
    },
    insights_parent_main: {
        dashboard_layout: "insightsParentMain",
    },
    insights_parent_tasks: {
        dashboard_layout: "insightsParentTask",
    }
}