// return settings by path
import _get from "lodash/get";

export const getData = state => _get(state, 'jrGeneric.settings');

// this is a helper selector used to get data from the top level settings state.
// Pass the path, section and subsection to get data
export const getSettingBySections = (endpoint, section, subSection=null) => state => {
    let path = `${section}.${subSection ? `${subSection}.`: ""}${endpoint}`
    const result = getData(state);
    return _get(result, path, undefined);
};

// return setting by path
export const getBaseSettingByPath = path => state => {
    const result = getData(state);
    return _get(result, path, undefined);
};