import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Card, Typography, Tabs} from "antd";

import SettingHeader from "../../common/SectionHeader";
import SettingButtonGroup from "../partials/buttonGroup/ButtonGroup";
import SectionGroup from "./partials/SectionGroup";
import MainDrawer from "./partials/mainDrawer/MainDrawer";
import Loader from "../../common/layout/Loader";
import SyncingBanner from "../partials/hoc/syncingBanner/SyncingBannerView";

import {subSections, settingsSections} from "../../../../constant/jobReadyGeneric/constants";
import {menu} from "../../../../constant/jobReadyGeneric/menu";

const {TabPane} = Tabs;

const RiskCalculationsView = ({
                                  drawerConfig,
                                  academicSections,
                                  financialSections,
                                  complianceSections,
                                  onSectionDataChange,
                                  onScoreDelete,
                                  tabConfig,
                                  isLoading,
                                  onEditClick,
                                  onDrawerSave,
                                  isSyncing
                              }) => {

    const renderTitles = (title) => (
        <Fragment>
            <Typography.Title level={5} style={{color:"#6244BB"}}>Calculations</Typography.Title>
            <Typography.Paragraph strong>{title}</Typography.Paragraph>
        </Fragment>
    )

    const renderFormula = (topic, text) => (
        <Typography.Paragraph type="secondary" style={{marginTop:10}} >
            {topic}:<br/>
            {text}
        </Typography.Paragraph>
    )

    return (
        <div>
            <Loader isLoading={isLoading}/>
            <SettingHeader title="Risk Calculations" items={[{name: 'Risk Analysis', href: menu.riskAnalysis}, "Settings", "Risk Calculations"]}/>
            <Tabs activeKey={tabConfig.activeTab} onChange={tabConfig.setActiveTab}>
                <TabPane tab="Academic" key={subSections.academic.key}>
                    {tabConfig.activeTab === subSections.academic.key && isSyncing && <SyncingBanner/>}
                    <Card>
                        {renderTitles("Academic weighted Average calculation")}
                        <SectionGroup
                            sections={academicSections}
                            showDrawer={drawerConfig.showDrawer}
                            onSectionDataChange={onSectionDataChange}
                            subSection={subSections.academic.key}
                            onScoreDelete={onScoreDelete}
                            onEditClick={onEditClick}
                        />
                        {renderFormula("Academic Risk calculation", "Academic Risk =  (Unit progress weight * Score) + (Attendance weight * Score)+(Attendance Mode weight * Score)+(Employment Status weight * Score)+(Eligibility Exemption weight * Score)+(Eligibility Exemption weight * Score)+(Entry Requirement weight * Score)+(Study Reason weight * Score) / Sum of weights")}
                        <SettingButtonGroup section={settingsSections.riskCalculations} subSection={subSections.academic.key}/>
                    </Card>

                </TabPane>
                <TabPane tab="Financial" key={subSections.financial.key}>
                    {tabConfig.activeTab === subSections.financial.key && isSyncing && <SyncingBanner/>}
                    <Card>
                        {renderTitles("Financial weighted Average calculation")}
                        <SectionGroup
                            sections={financialSections}
                            showDrawer={drawerConfig.showDrawer}
                            onSectionDataChange={onSectionDataChange}
                            subSection={subSections.financial.key}
                            onScoreDelete={onScoreDelete}
                            onEditClick={onEditClick}
                        />
                        {renderFormula("Financial Risk calculation", "Financial Risk =  (Completed payment as a percentage of invoices issued * Score) +(Attendance Mode weight * Score)+(Employment Status weight * Score)+(Payment Plan weight * Score)+(Due Fee weight* Score)+(Payment Due Date weight* Score)+(VET Fee Help  weight * Score)+(Funding Source State * Score)+(Fee Exception Indicator weight * Score)+(Historical Payment Delays weight * Score) / Sum of weights")}
                        <SettingButtonGroup section={settingsSections.riskCalculations} subSection={subSections.financial.key}/>
                    </Card>
                </TabPane>
                <TabPane tab="Compliance" key={subSections.compliance.key}>
                    {tabConfig.activeTab === subSections.compliance.key && isSyncing && <SyncingBanner/>}
                    <Card>
                        {renderTitles("Compliance weighted Average calculation")}
                        <SectionGroup
                            sections={complianceSections}
                            showDrawer={drawerConfig.showDrawer}
                            onSectionDataChange={onSectionDataChange}
                            subSection={subSections.compliance.key}
                            onScoreDelete={onScoreDelete}
                            onEditClick={onEditClick}
                        />
                        {renderFormula("Compliance Risk calculation", "Compliance Risk = (Visa Expiration weight * Score) + (Passport Expiration weight * Score)+(Overseas Student Health Cover weight* Score)+(Attendance weight* Score)+(Visa Type  weight* Score)+( Visa Status weight * Score)+(Entry Requirement weight * Score)+(Study Reason weight * Score) / Sum of weights")}
                        <SettingButtonGroup section={settingsSections.riskCalculations} subSection={subSections.compliance.key}/>
                    </Card>
                </TabPane>
                {/* Not in phase 01 */}
                {/*<TabPane tab="Well-being" key={subSections.wellBeing.key}>*/}
                {/*    <Card>*/}
                {/*        /!* to be developed *!/*/}
                {/*        {renderTitles("Well-being weighted Average calculation")}*/}
                {/*        <SectionGroup*/}
                {/*            sections={[]}*/}
                {/*            showDrawer={drawerConfig.showDrawer}*/}
                {/*            onSectionDataChange={onSectionDataChange}*/}
                {/*            subSection={subSections.wellBeing.key}*/}
                {/*            onScoreDelete={onScoreDelete}*/}
                {/*            onEditClick={onEditClick}*/}
                {/*        />*/}
                {/*        {renderFormula("", "")}*/}
                {/*        <SettingButtonGroup section={settingsSections.riskCalculations} subSection={subSections.wellBeing.key}/>*/}
                {/*    </Card>*/}
                {/*</TabPane>*/}
            </Tabs>

            <MainDrawer drawerConfig={drawerConfig} onFinish={onDrawerSave}/>
        </div>
    )
}

RiskCalculationsView.propTypes = {
    drawerConfig: PropTypes.object,
    academicSections: PropTypes.array,
    financialSections: PropTypes.array,
    complianceSections: PropTypes.array,
    onSectionDataChange: PropTypes.func,
    onScoreDelete: PropTypes.func,
    tabConfig: PropTypes.object,
    isLoading: PropTypes.bool,
    onEditClick: PropTypes.func,
    onDrawerSave: PropTypes.func,
    isSyncing: PropTypes.bool,
}

export default RiskCalculationsView;