import React, { useEffect } from 'react';
import { Card, Table } from "antd";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";
import { withTablePaginator } from "../../common/table/hoc/withTablePaginator";

const StudentGridView = ({
    isLoading,
    onSearch,
    dataSource,
    columns,
    pagination,
    setSearchFunc,
}) => {
    useEffect(() => {
        dataSource && setSearchFunc(prev => onSearch);
    }, [dataSource])

    return (
        <Card
            className="tableCoverCard"
        >
            <Loader isLoading={isLoading} />
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ ...pagination, showSizeChanger: true }}
                scroll={{ x: "max-content" }}
            />
        </Card>
    );
};

StudentGridView.defaultProps = {
    isLoading: false,
};

StudentGridView.propTypes = {
    isLoading: PropTypes.bool,
    onSearch: PropTypes.func,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    setSearchFunc: PropTypes.func,
}

export default withTablePaginator(StudentGridView);