import React from "react";
import PropTypes from "prop-types";

// icons
const canvas_logo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/canvas-logo.png`;
const tass_logo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tass_logo.png`;

const IconCell = ({platform}) => {

    const getIconCell = () => {
        switch (platform) {
            case "tass":
                return tass_logo;
            case "readyPlus":
                return null;
            case "Canvas":
                return canvas_logo;
        }
    }

    return (
        <img
            className="img-fluid"
            src={getIconCell()}
            alt="logo"
            height={80}
            width={80}
        />
    )
}

IconCell.propTypes = {
    platform: PropTypes.string
}

export default IconCell;