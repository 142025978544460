import React from 'react';
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import PieChart from "./PieChart";

const SolidGauge = ({chartOptions}) => {
    highchartsMore(Highcharts);
    solidGauge(Highcharts);

    const defaultConfig = {
        chart: {
            type: 'solidgauge',
            height: "50%"
        },
        title: null,
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
                y: 16
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Speed ss',
            data: [
                {y: 0, color: "#BF0B23"}
            ],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}%</span><br/>' +
                    '<span style="font-size:12px;opacity:0.4">Cohort: 80%</span>' +
                    '</div>'
            },
        }]
    };

    const config = Highcharts.merge(defaultConfig, chartOptions);

    return <HighchartsReact
        highcharts={Highcharts}
        options={config}
    />
};

SolidGauge.propTypes = {
    chartOptions: PropTypes.object,
};

export default SolidGauge;