import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Tabs, Button, Space } from "antd";
import AllCourses from "./components/allCourses";
import Search from "../../../../innovative/common/form/Search";
import AllowedCourses from "./components/allowedCourses";
import RestrictedCourses from "./components/restrictedCourses";
import {
  getUserManagementCourses,
  resetCourseData,
  saveCourseChanges,
} from "../../../../../actions/userManagement/userManagement.action";
import PopUpModal from "../../../../common/popup-modal/popup-modal";
import {
  getAllCourseLoadingState,
  getAllCourses,
  getAllCoursesDataFetched,
  getAllowCoursesDataFetched,
  getRestrictedDataFetched,
} from "../../../../../selectors/userManagement/userManagement.selector";
import { usePrevious } from "../../../../../utils/innovative/customHooks";
import { get as _get } from 'lodash';
import { dashboardConst } from "../../../../../constant/dashboardManagement/commonDashboardConst";

const CourseAccessSideDrawer = ({
  sideDrawerEnabled,
  setEnableSideDrawer,
  selectedUsersArray,
  setSelectedUsersArray,
}) => {

  const dispatch = useDispatch();

  const allCourses = useSelector(getAllCourses);
  const allCoursesDataLoading = useSelector(getAllCourseLoadingState);
  const dataAllowFetched = useSelector(getAllowCoursesDataFetched);
  const dataAllFetched = useSelector(getAllCoursesDataFetched);
  const dataRestrictedFetched = useSelector(getRestrictedDataFetched);

  const [currentSelectedTab, setCurrentSelectedTab] = useState(dashboardConst.tabKey.all);
  const [searchCourseText, setCourseSearchText] = useState("");
  const [previousSearchCourseText, setPreviousSearchCourseText] = useState("");
  const [updateCourseArray, setUpdateCourseArray] = useState([]);
  const [initStateOfUpdateCourseArray, setInitStateOfUpdateCourseArray] = useState([]);
  const [openConfirmPopUpModal, setOpenConfirmPopUpModal] = useState(false);
  const [selectedActionButton, setSelectedActionButton] = useState("");
  const prevValues = usePrevious({ searchCourseText });

  useEffect(() => {
    if (selectedUsersArray.length && sideDrawerEnabled) {
      const userIds = selectedUsersArray.map(user => user.user_id);
      const accountId = selectedUsersArray[0].account_id;

      const payload = {
        accountId,
        userIds,
        tab: dashboardConst.tabKey.all,
        ...dashboardConst.initialPagination,
      };

      dispatch(getUserManagementCourses(payload));
    }

  }, [selectedUsersArray, sideDrawerEnabled]);

  useEffect(() => {
    setPreviousSearchCourseText(_get(prevValues, 'searchCourseText', ""));
  }, [searchCourseText]);

  const resetTempData = () => {
    setInitStateOfUpdateCourseArray([]);
    setUpdateCourseArray([]);
  }

  const onClose = () => {
    dispatch(resetCourseData());
    setEnableSideDrawer(false);
    setSelectedUsersArray([]);
    setUpdateCourseArray([]);
    setOpenConfirmPopUpModal(false);
    setCourseSearchText("");
  };

  const onCourseSearch = event => {
    const { value, type } = event.target;

    if (type === "click" || value.length === 0) {
      dispatch(resetCourseData());
      setCourseSearchText("");
      return fetchData(
        {
          activeKey: currentSelectedTab,
          defaultFetchActive: true,
          resetSearch: true,
        });
    }
    if (value.length > dashboardConst.minSearchTextLength) {
      setCourseSearchText(value);
    }
  }

  const onSwitchChange = ({ checked, record }) => {

    const switchState = () => record?.courseStatus === dashboardConst.allowedCourseStatus;

    const filterExistingStateArray = initStateOfUpdateCourseArray.filter(
      courseRecord => courseRecord.courseId !== record.courseId
    );

    const existingInitStateArray = [...filterExistingStateArray, { allowed: switchState(), ...record }];
    setInitStateOfUpdateCourseArray(existingInitStateArray);

    const filterExistingRecord = updateCourseArray.filter(
      courseRecord => courseRecord.courseId !== record.courseId
    );

    const updateArray = [...filterExistingRecord, { allowed: checked, ...record }];
    setUpdateCourseArray(updateArray);
  }

  const validateUpdateCourses = () => {
    if (updateCourseArray.length !== initStateOfUpdateCourseArray.length) {
      return [];
    }

    return updateCourseArray.filter(updatedCourseState => {
      const updatedCourses = initStateOfUpdateCourseArray.find(initCourse => (
        (initCourse.courseId === updatedCourseState.courseId) && (initCourse.allowed !== updatedCourseState.allowed)));
      if (updatedCourses) {
        return true;
      }
      return false;
    }
    );
  }

  const updateAccessManagement = () => {
    const filterUpdateCourse = validateUpdateCourses();
    const updateUserIds = selectedUsersArray.map(user => user.user_id);
    setCourseSearchText("");
    filterUpdateCourse.length && dispatch(saveCourseChanges({
      reqData: {
        requestType: "save",
        userIds: updateUserIds,
        accountId: filterUpdateCourse[0].accountId,
        courseData: [...filterUpdateCourse],
        tab: currentSelectedTab,
      },
      fetchPayload: {
        activeKey: currentSelectedTab,
        defaultFetchActive: true,
        resetSearch: false,
        requestType: "",
      },
      fetchCallBackFunction: fetchData,
      resetTempCallBack: resetTempData,
      resetCourseCallBack: resetCourseData,
    }));
  }

  const renderPopUpModal = () => {
    const statuses = [dashboardConst.tabKey.allow, dashboardConst.tabKey.restrict];
    if (!statuses.includes(selectedActionButton)) return null;

    const statusText = selectedActionButton === dashboardConst.tabKey.allow ? 'activated' : 'restricted';
    return (
      <div>
        {allCourses.count} Courses will be {statusText}. Continue to update.
      </div>
    );
  }

  const onTabActionButtonClick = () => {
    const updateUserIds = selectedUsersArray.map(user => user.user_id);
    if (selectedActionButton === dashboardConst.tabKey.allow) {
      dispatch(saveCourseChanges({
        reqData: {
          requestType: dashboardConst.tabKey.allow,
          userIds: updateUserIds,
          accountId: selectedUsersArray[0].account_id,
          allowAll: true,
          courseData: [],
        },
        fetchPayload: {
          activeKey: currentSelectedTab,
          defaultFetchActive: true,
          resetSearch: false,
          requestType: dashboardConst.tabKey.allow,
        },
        fetchCallBackFunction: fetchData,
        resetTempCallBack: resetTempData,
        resetCourseCallBack: resetCourseData,
      }));

      return setSelectedActionButton("");
    }

    if (selectedActionButton === dashboardConst.tabKey.restrict) {
      dispatch(saveCourseChanges({
        reqData: {
          requestType: dashboardConst.tabKey.restrict,
          userIds: updateUserIds,
          accountId: selectedUsersArray[0].account_id,
          allowAll: false,
          courseData: [],
        },
        fetchPayload: {
          activeKey: currentSelectedTab,
          defaultFetchActive: true,
          resetSearch: false,
          requestType: dashboardConst.tabKey.restrict,
        },
        fetchCallBackFunction: fetchData,
        resetTempCallBack: resetTempData,
        resetCourseCallBack: resetCourseData,

      }));
      return setSelectedActionButton("");
    }
  }

  const onTabChange = activeKey => {
    setCurrentSelectedTab(activeKey);

    if (!searchCourseText.length) {
      fetchData({
        activeKey,
        defaultFetchActive: false,
        resetSearch: false,
        requestType: "",
      });
    }
  };

  const fetchData = ({ activeKey, defaultFetchActive, resetSearch, requestType }) => {
    const userIds = selectedUsersArray.map(user => user.user_id);
    const accountId = _get(selectedUsersArray[0], "account_id", null);

    const getSearchValue = () => {
      if (resetSearch) {
        return "";
      }
      if (searchCourseText.length > dashboardConst.minSearchTextLength) {
        return searchCourseText;
      }
      return "";
    };

    switch (activeKey) {
      case dashboardConst.tabKey.all:
        if (!dataAllFetched || defaultFetchActive) {
          const payload = {
            requestType,
            accountId,
            userIds,
            tab: dashboardConst.tabKey.all,
            searchValue: getSearchValue(),
            ...dashboardConst.initialPagination,
          };

          dispatch(getUserManagementCourses(payload));
        }
        return;
      case dashboardConst.tabKey.restrict:
        if (!dataRestrictedFetched || defaultFetchActive) {
          const payload = {
            requestType,
            accountId,
            userIds,
            tab: dashboardConst.tabKey.restrict,
            searchValue: getSearchValue(),
            ...dashboardConst.initialPagination,
          };

          dispatch(getUserManagementCourses(payload));
        }
        return;
      case dashboardConst.tabKey.allow:
        if (!dataAllowFetched || defaultFetchActive) {
          const payload = {
            requestType,
            accountId,
            userIds,
            tab: dashboardConst.tabKey.allow,
            searchValue: getSearchValue(),
            ...dashboardConst.initialPagination,
          };

          dispatch(getUserManagementCourses(payload));
        }
        return;
      default: return null
    }
  }

  const fetchCourseData = (payload) => {
    if (selectedUsersArray.length) {
      const accountId = _get(selectedUsersArray[0], "account_id", null);
      const userIds = selectedUsersArray.map(user => user.user_id);
      dispatch(getUserManagementCourses({
        ...payload,
        accountId,
        userIds,
      }));
    }
  }

  const renderTabs = () => {
    const operations = <Space>
      <Button
        disabled={allCoursesDataLoading}
        onClick={() => {
          setOpenConfirmPopUpModal(true);
          setSelectedActionButton(dashboardConst.tabKey.allow);
          setCourseSearchText("");
        }}
      >
        Allow All
      </Button>
      <Button
        disabled={allCoursesDataLoading}
        onClick={() => {
          setOpenConfirmPopUpModal(true);
          setSelectedActionButton(dashboardConst.tabKey.restrict);
          setCourseSearchText("");
        }}
      >
        Restrict All
      </Button>
    </Space>

    return (
      <Tabs
        tabBarExtraContent={operations}
        defaultActiveKey={dashboardConst.tabKey.all}
        className="course-access-drawer"
        onChange={onTabChange}
      >
        <Tabs.TabPane
          tab="All"
          key={dashboardConst.tabKey.all}
        >
          <AllCourses
            previousSearchCourseText={previousSearchCourseText}
            searchCourseText={searchCourseText}
            selectedUsersArray={selectedUsersArray}
            onSwitchChange={onSwitchChange}
            currentSelectedTab={currentSelectedTab}
            fetchCourseData={fetchCourseData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Restricted"
          key={dashboardConst.tabKey.restrict}
        >
          <RestrictedCourses
            previousSearchCourseText={previousSearchCourseText}
            searchCourseText={searchCourseText}
            selectedUsersArray={selectedUsersArray}
            onSwitchChange={onSwitchChange}
            currentSelectedTab={currentSelectedTab}
            fetchCourseData={fetchCourseData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Allowed"
          key={dashboardConst.tabKey.allow}
        >
          <AllowedCourses
            previousSearchCourseText={previousSearchCourseText}
            searchCourseText={searchCourseText}
            selectedUsersArray={selectedUsersArray}
            onSwitchChange={onSwitchChange}
            currentSelectedTab={currentSelectedTab}
            fetchCourseData={fetchCourseData}
          />
        </Tabs.TabPane>
      </Tabs>
    )
  }

  return (
    <div >
      <Drawer
        destroyOnClose={true}
        title="Course Access Management"
        placement="right"
        className="courseAccessDrawer"
        onClose={onClose}
        visible={sideDrawerEnabled}
        maskClosable={false}
        width="700px"
      >
        <Search
          allowClear
          onSearch={value => onCourseSearch(value)}
        />
        {renderTabs()}
        <div className="drawerBottomBar">
          <Button
            disabled={!validateUpdateCourses().length}
            type={"primary"}
            onClick={updateAccessManagement}
          >
            Update
          </Button>
        </div>
      </Drawer>
      <PopUpModal
        title={"Confirm Action"}
        okText={"Update"}
        onPressOk={onTabActionButtonClick}
        setIsModalVisible={setOpenConfirmPopUpModal}
        isModalVisible={openConfirmPopUpModal}
        okButtonDisabled={false}
      >
        {renderPopUpModal()}
      </PopUpModal>
    </div>
  )
}

export default CourseAccessSideDrawer;
