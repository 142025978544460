import React, { useEffect, memo } from 'react';
import { Table } from "antd";
import Loader from '../../common/layout/Loader';
import { withTablePaginator } from '../../common/table/hoc/withTablePaginator';

const ModuleProgressGridView = ({
  isLoading,
  dataSource,
  columns,
  pagination,
  setSelectedTablePagination,
  setSearchFunc,
  onSearch,
}) => {

  useEffect(() => {
    dataSource && setSearchFunc(prev => onSearch);
}, [dataSource])

  setSelectedTablePagination(pagination?.current);
  
  return (
    <div className="moduleProgressGrid">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
        />
      }
    </div>
  );
};

ModuleProgressGridView.propTypes = {
};

export default memo(withTablePaginator(ModuleProgressGridView));