import _get from "lodash/get";

export const authentication = state => state && state.Authentication;

// get External user response
export const getExternalUserData = state => {
    const result = authentication(state);
    return result && result.externalUser;
};

// get External user login response
export const getExternalUserLoginData = state => {
    const result = getExternalUserData(state);
    return result && result.login;
};

// return External user response loading
export const getExternalUserLoading = state => {
    const result = getExternalUserLoginData(state)
    return result.isLoading
};

// return External user response data
export const getExternalUserResponseData = state => {
    const result = getExternalUserLoginData(state)
    return result && result.data
};

// get External user reset password response
export const getExternalUserResetPasswordData = state => {
    const result = getExternalUserData(state);
    return result && result.reset;
};

// return External user reset password loading
export const getExternalUserResetPasswordLoading = state => {
    const result = getExternalUserResetPasswordData(state)
    return result.isLoading
};

// return External user reset password data
export const getExternalUserResetPasswordResponseData = state => {
    const result = getExternalUserResetPasswordData(state)
    return result && result.data
};