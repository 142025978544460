import React, { Component, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import seven from '../../../assets/images/user-card/7.jpg';
import { Calendar, Users, MessageSquare, Navigation, Box, Layers, Server, Book, ArrowDown, ArrowUp } from 'react-feather';
import Breadcrumb from '../../common/breadcrumb';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import one from '../../../assets/images/user-card/1.jpg';
import three from '../../../assets/images/user-card/3.jpg';
import anglicanimage from '../../../assets/images/anglican.png';
import two from '../../../assets/images/user-card/2.jpg';
import sixteen from '../../../assets/images/avtar/16.jpg';
import eleven from '../../../assets/images/avtar/11.jpg';
import four from '../../../assets/images/avtar/4.png';

import url from '../../../assets/images/user/7.jpg';

import addHeatmap from 'highcharts/modules/heatmap'
addHeatmap(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class StudentProfile extends Component {
  constructor(props) {
    super(props);

    var queryx = new URLSearchParams(this.props.location.search);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      isOpen: false,
      url: "",
      isLoaded: true,
      items: [],
      isSubChartLoaded: false,
      subChartItem: [],
      isLateSubChartLoaded: false,
      lateSubmission: [],
      tempStudentName: "",
      studentName: "Student Name",
      avatar: four,
      workloadError:false,
      isFilterTermsLoaded: false,
      isFilterYearLevelsLoaded: false,
      isFilterStudentsLoaded: false,
      filterTermValue: 0,
      filterCourseValue: 0,
      filterStudentValue: 0,
      filterYearLevelValue: 0,
      filterTerms: [],
      filterYearLevels: [],
      filterStudents: [],
      filterCourses: [],
      isFeedbacksLoaded: false,
      feedbacks: [],
      isMarksByAssignmentsLoaded: false,
      marksByAssignments: [],
      isStudentNameLoaded: false,
      isWorkloadLoaded: false,
      workLoadItem: [],
      loader: 'Loading...',
      logged_in: queryx.get('token'),
      token: 'Bearer ' + queryx.get('t'),
      isActivityByTimeLoaded: false,
      activityByTimeData: [],
      isFirstTimeLoad: true,
      SubmissionModelOpen: false,
      onCloseModal: false,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: [],
      isGoButtonDisabled: false,
      headers: { 'Content-Type': 'application/json' }
    };


  }




  readUrl(event) {



  }

  filterTerms() {
    this.setState({
      isFilterYearLevelsLoaded: false,
    isFilterCoursesLoaded:false,
    isFilterStudentsLoaded:false,
    isFilterTermsLoaded:false

    });



    this.setState({
      isGoButtonDisabled: true
    });

    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }

    };
    //getting filter data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/filters/term/"+ this.state.logged_in , requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFilterTermsLoaded: true,
            filterTermValue: result.data.result[0].id,
            filterTerms: result.data.result
          });

          this.filterStudentsFunc(result.data.result[0].id);

        },

        (error) => {
          this.setState({
            isFilterTermsLoaded: true,
            error
          });
        }
      );

  }

  changeYearLevelFilterValue(value) {

    ////////////alert(value);
    this.setState({ filterYearLevelValue: value });
    this.filterStudentsFunc(value);

  }
  changeTermValue(value) {

    ////alert(value);
    this.setState({ filterTermValue: value });
    this.filterStudentsFunc(value);

  }
  changeStudentValue(value) {

    //////alert(value);
    this.setState({ filterStudentValue: value });
    this.filterCourses(value);

  }
  changeCourseValue(value) {
    //////alert(value);
    this.setState({ filterCourseValue: value });
    //  this.filterStudentsFunc();

  }


  redrawCharts() {

    this.setState({
      isSubChartLoaded: false,
      isFeedbacksLoaded: false,
      isLateSubChartLoaded: false,
      isMarksByAssignmentsLoaded: false,
      isStudentNameLoaded: false,
      isWorkloadLoaded: false,
      isStudentNameLoaded: false,
      isActivityByTimeLoaded: false


    });
    this.chart_marks_by_assignment();
    this.charts_submissiondata();
    this.table_feedbacks();
    this.basic_info();
    this.charts_workload();
    this.chart_activity_by_time();

  }

  basic_info() {
    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }

    };
    fetch(process.env.REACT_APP_APIURL + "studentprofile/student/"+ this.state.logged_in +"/" + this.state.filterStudentValue, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isStudentNameLoaded: true,
            studentName: result.data.result.name + " - " + result.data.result.id,
            avatar: result.data.result.avatar_url


          });

          if (this.state.studentName == "") {

            this.setState({
              studentName: "Student Name",
              avatar: four
            });
          }


        },

        (error) => {
          this.setState({
            isStudentNameLoaded: true,
            studentName: "Student Nme",
            avatar: four,
            error
          });
        }
      );
  }


  filterYearLevel(term = null) {

    this.setState({
      isFilterYearLevelsLoaded: false,
    isFilterCoursesLoaded:false,
    isFilterStudentsLoaded:false

    });

    this.setState({
      isGoButtonDisabled: true
    });
    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }

    };

    if (term == null) {

      var term = this.state.filterTermValue;
    }


    //getting filter data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/filters/yearlevel/" + this.state.logged_in + "/" + term, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFilterYearLevelsLoaded: true,
            filterYearLevels: result.data.result

          });

          if (result.data.result.length > 0) {

            this.setState({
              filterYearLevelValue: result.data.result[0].id,
              isGoButtonDisabled: true
            });

            this.filterStudentsFunc(result.data.result[0].id);
          } else {

            this.setState({
              filterYearLevels: [],
              ilterCourseValue: 0,
              filterCourses: [],
              filterStudentValue: 0,
              filterStudents: [],
              studentName: "",
              filterYearLevelValue: 0,
              isGoButtonDisabled: true
            });



    this.setState({
     
    isFilterCoursesLoaded:true,
    isFilterStudentsLoaded:true

    });
          }

        },

        (error) => {
          this.setState({
            isFilterYearLevelsLoaded: true,
            error
          });
        }
      );

  }

  filterStudentsFunc(yearlevel = null) {

    this.setState({
      
    isFilterCoursesLoaded:false,
    isFilterStudentsLoaded:false

    });

    this.setState({
      isGoButtonDisabled: true
    });

    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }

    };

    if (!yearlevel == null) {
      const yearlevel = this.state.filterYearLevelValue;
    }

    const term = this.state.filterTermValue;

    //getting filter data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/filters/students/" + this.state.logged_in + "/" + term + "/" + yearlevel, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFilterStudentsLoaded: true,
            filterStudents: result.data.result,

          });


          if (result.data.result.length > 0) {
            this.setState({
              filterStudentValue: result.data.result[0].id,
              studentName: result.data.result[0].name,
              isGoButtonDisabled: false
            });
            this.filterCourses(result.data.result[0].id);
          } else {

            this.setState({
              filterCourseValue: 0,
              filterStudents: [],
              filterCourses: [],
              filterStudentValue: 0,
              studentName: "",
              isGoButtonDisabled: true

            });

            this.setState({
      
              isFilterCoursesLoaded:true
             
          
              });


    
          }


        },

        (error) => {
          this.setState({
            isFilterStudentsLoaded: true,
            error
          });
        }
      );

  }

  filterCourses(student_id = null) {

    this.setState({
      
    isFilterCoursesLoaded:false
  
    });

    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }

    };

    const term = this.state.filterTermValue; //comes year
    const yearLevel = this.state.filterYearLevelValue;//comes year level

    if (student_id == null) {
      const student_id = this.state.filterStudentValue;
    }

    //getting filter data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/filters/studentcourses/" + this.state.logged_in + "/" + term + "/" + yearLevel + "/" + student_id, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFilterCoursesLoaded: true,
            filterCourses: result.data.result

          });

          if (result.data.result.length > 0) {
            this.setState({
              filterCourseValue: result.data.result[0].id,
              isGoButtonDisabled: false
            });

            if (this.state.isFirstTimeLoad) {

              this.setState({
                isFirstTimeLoad: false
              });
              this.redrawCharts();
            }

          } else {
            this.setState({
              filterCourseValue: 0,
              filterCourses: [],
              isGoButtonDisabled: true
            });

          }

        },

        (error) => {
          this.setState({
            isFilterStudentsLoaded: true,
            error
          });
        }
      );

  }


  chart_marks_by_assignment() {


    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/marks_by_assignment/data/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isMarksByAssignmentsLoaded: true,
            marksByAssignments: result.data.response.body,

          });


        },

        (error) => {
          this.setState({
            isMarksByAssignmentsLoaded: true,
            error
          });
        }
      );
  }


  chart_activity_by_time() {


    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, year: this.state.filterTermValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/activity_by_time/data/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isActivityByTimeLoaded: true,
            activityByTimeData: result.data.response.body,

          });


        },

        (error) => {
          this.setState({
            isActivityByTimeLoaded: true,
            error
          });
        }
      );
  }



  table_feedbacks() {

    const courseIds = [];

    for (var i = 0; i < this.state.filterCourses.length; i++) {

      courseIds[i] = this.state.filterCourses[i].id;
    }

    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ xxxX: "", courseIds: courseIds, studentId: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/feedback/data/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFeedbacksLoaded: true,
            feedbacks: result.data.result

          });


        },

        (error) => {
          this.setState({
            isFeedbacksLoaded: true,
            error
          });
        }
      );
  }



  charts_workload() {


    const courseIds = [];
    

    for (var i = 0; i < this.state.filterCourses.length; i++) {

      courseIds[i] = this.state.filterCourses[i].id;
    }

    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/workload/data/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isWorkloadLoaded: true,
            workLoadItem: result.data.result.body

            // studentName:  result.data.response.body.userName,
            //  avatar: result.data.response.body.avatar
          });


        },

        (error) => {

          
          this.setState({
            isWorkloadLoaded: true,
            workloadError:true,
           // workLoadItem: ["categories":]
            error
          });
        }
      );
  }



  charts_submissiondetails(courseId = 0) {



    this.setState({

      SubmissionModelOpen: true,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: []

    });

    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ term: this.state.filterTermValue, courseId: courseId, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/submissions/details/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isSubChartLoaded: true,
            subDetailsChartItems: result.data.response.body,
            isSubChartDetailsLoaded: true,
            // studentName:  result.data.response.body.userName,
            //  avatar: result.data.response.body.avatar
          });


        },

        (error) => {
          this.setState({
            isSubChartDetailsLoaded: true,
            error
          });
        }
      );
  }


  charts_submissiondata() {


    const courseIds = [];

    for (var i = 0; i < this.state.filterCourses.length; i++) {

      courseIds[i] = this.state.filterCourses[i].id;
    }

    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ term: this.state.filterTermValue, courseId: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "studentprofile/submissions/data/2", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isSubChartLoaded: true,
            subChartItem: result.data.response.body,
            isLateSubChartLoaded: true,
            // studentName:  result.data.response.body.userName,
            //  avatar: result.data.response.body.avatar
          });


        },

        (error) => {
          this.setState({
            isSubChartLoaded: true,
            error
          });
        }
      );
  }


  componentDidMount() {



    this.filterTerms();

    //this.redrawCharts();
    //this.table_feedbacks();

    //const response = await fetch('http://api.tentacle.com:801/api/1/health/check', requestOptions);



  }




  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }





  render() {

    var setModelopen = function (index) {



      gThis.charts_submissiondetails(index);

    };

    var closeModal = function () {

      gThis.setState({

        SubmissionModelOpen: false

      });
    };


    var easeOutBounce = function (pos) {
      if ((pos) < (1 / 2.75)) {
        return (7.5625 * pos * pos);
      }
      if (pos < (2 / 2.75)) {
        return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
      }
      if (pos < (2.5 / 2.75)) {
        return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
      }
      return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
    };

    Math.easeOutBounce = easeOutBounce;

    const gState = this.state;
    const gThis = this;

    const colors1 = ["#0266C8", "#F90101", "#F2B50F", "#00933B"];
    const colors = ["#164a90", "#3b3b8a", "#a80c03", "#d43504"];
    const submission_colors = ["#ff8800", "#228a3b", "#db043a", "#d43504"];

    let chart1 = {
      title: {
        text: ''
      },

      colors: colors,
      chart: {

        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 2000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {

          linewidth: 6,
          borderRadius: 1
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.marksByAssignments.categories,
        title: {
          text: 'ASSIGNMENT NAME'
          //align: 'center'
        }
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        min: 0,
        max: 100,
        title: {
          text: 'MARKS AS A PERCENTAGE'
          //align: 'center'
        }

      },
      tooltip: {
        formatter: function (tooltip) {


          if (gState.isMarksByAssignmentsLoaded) {


            return "Assignment name : <b>" + this.point.category + "<b/><br/>Marks : <b>" + this.y + "% (" + gState.marksByAssignments.tooltipData[this.point.x]["rawmark"] + "/" + gState.marksByAssignments.tooltipData[this.point.x]["outof"] + ") ";
          }




        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },

      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",

      series: this.state.marksByAssignments.data
    };


    let submission_details_chart = {
      title: {
        text: ''
      },

      colors: submission_colors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },

      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.subDetailsChartItems.categories,
        title: {
          text: "ASSIGNMENT NAME"
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",

      series: this.state.subDetailsChartItems.data
    };

    let chart2 = {
      title: {
        text: ''
      },

      colors: submission_colors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
        column: {
          events: {
            click: function (event) {
              var index = event.point.index;

              // alert(gState.subChartItem.categoryIds[index]);
              setModelopen(gState.subChartItem.categoryIds[index]);
            }
          }
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.subChartItem.categories,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",

      series: this.state.subChartItem.data
    };

    let late_submission = {
      title: {
        text: ''
      },

      colors: ["#164a90", "#2d9cdb"],
      chart: {

        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {

          linewidth: 6,
          borderRadius: 1
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.subChartItem.categories,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function (tooltip) {


          if (gState.isMarksByAssignmentsLoaded) {

            if (this.series.name == "COHORT") {

              return "<b>" + this.point.category + "<b/><br/>" + this.series.name + "<b> : " + gState.subChartItem.data2[0]["dataCounts"][this.point.x] + " (" + this.y + "%)";
            } else {

              return "<b>" + this.point.category + "<b/><br/>" + this.series.name + "<b> : " + gState.subChartItem.data2[1]["dataCounts"][this.point.x] + " (" + this.y + "%)";
            }




          }

        }
      },
      yAxis: {
        title: {
          text: ''
          //align: 'center'
        },
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }

      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",

      series: this.state.subChartItem.data2
    };

    let activity_by_time = {
      title: {
        text: ''
      },

      colors: colors,
      chart: {
        type: "line",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            radius: 9
          },
          lineWidth: 5
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.activityByTimeData.categories,
        title: { text: "TIME SLOT" }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function (tooltip) {


          return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during "+gState.activityByTimeData.data[this.point.x]+" days";

        //  return "Time Slot : <b>" + this.point.category + "<b/><br/>Count : <b>" + this.y + " ";


        }
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: { text: "NUMBER OF ACTIVITIES" }

      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",

      series: [{
        name: "Count",
        data: this.state.activityByTimeData.data,
        animation: {
          duration: 2000
          // Uses Math.easeOutBounce
          // easing: 'easeOutBounce'
        }
      }
      ]
    };
    let workload = {
      title: {
        text: ''
      },


      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1
      },

      colorAxis: {
        min: 0,
        minColor: "#ffffff",
        maxColor: colors[0]
      },
      title: {
        text: ''
      },

      xAxis: {
        categories: ["WEEK 1", "THIS WEEK", "NEXT WEEK", "2 WEEKS FROM NOW", "3 WEEKS FROM NOW"],
        title: {
          text: "NEXT FOUR WEEKS"
        }

      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.workLoadItem.categories,
        title: null,
        reversed: true,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      accessibility: {
        point: {
          descriptionFormatter: function (point) {

          }
        }
      },

      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280
      },

      tooltip: {
        /*
                              formatter: function () {                    
                            return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> Yearly Average: <b>$' +	
                            	Highcharts.numberFormat(this.series.chart.yAxis[1].categories[this.point.y],2) + '</b><br>' + 
                              'Average Labour Rate: <b>' + '$'+Highcharts.numberFormat(this.point.value, 2) + '</b><br>' + 
                              '<b>' + this.series.xAxis.categories[this.point.x];
                        }

       */
        formatter: function (tooltip) {

          if (gState.isWorkloadLoaded) {


            if (this.point.value > 0) {

              return gState.workLoadItem.allData[this.point.y].tooltips[this.point.x - 1];

            } else {

              return false;
            }


          } else {

            return "";
          }
        }
      },


      series: [{
        name: 'Work Load',
        borderWidth: 1,
        data: this.state.workLoadItem.data,
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      }],



      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                }
              }
            }
          }
        }]
      }
    };

    const settings = {
      className: "center",
      centerMode: true,
      speed: 500,
      arrows: false,
      centerPadding: "5px",
      slidesToShow: 1,
      slidesToScroll: 1
    };


    return (
      <Fragment>

        <div className="container-fluid " >
          <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
            <div className="card custom-card  " style={{ backgroundColor: "#e0e0e0", marginTop: '90px' ,paddingBottom:'10px', paddingTop:'10px'}}>
              <div className="row ">


                <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12">

                  <div className="row " style={{ marginTop: '20px' }} >
                    <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
                      <div className="card o-hidden custom-cardx">
                        <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                          <div className="media static-top-widget">

                            <div className="media-body">
                              <span className="m-0">YEAR</span>
                              <h4 className="mb-0 counter">
                                <select className="form-control digits " value={this.state.filterTermValue}
                                  onChange={(e) => this.changeTermValue(e.target.value)}>
                                  {this.state.isFilterTermsLoaded
                                    ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                    : <option>Loading...</option>

                                  }
                                </select>
                              </h4>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
                      <div className="card o-hidden custom-cardx">
                        <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                          <div className="media static-top-widget">

                            <div className="media-body"><span className="m-0">STUDENT NAME</span>
                              <h4 className="mb-0 counter">
                                <select className="form-control digits" value={this.state.filterStudentValue}
                                  onChange={(e) => this.changeStudentValue(e.target.value)}>
                                    {this.state.isFilterStudentsLoaded
                                  ? this.state.filterStudents.map((filterStudent) => <option key={filterStudent.id} value={filterStudent.id}>{filterStudent.name} - {filterStudent.id}</option>)
                                  : <option>Loading...</option>
                                  
                                    }
                                </select>
                              </h4>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-xs-12 col-xl-3 col-lg-3">
                      <div className="card o-hidden custom-cardx">
                        <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                          <div className="media static-top-widget">
                            <div className="media-body"><span className="m-0">COURSE</span>
                              <h4 className="mb-0 counter">
                                <select className="form-control digits" value={this.state.filterCourseValue}
                                  onChange={(e) => this.changeCourseValue(e.target.value)}>
                                     {this.state.isFilterCoursesLoaded
                                  ? this.state.filterCourses.map((filterCourses) => <option key={filterCourses.id} value={filterCourses.id}>{filterCourses.name}</option>)
                                  : <option>Loading...</option>
                                  
                                }    
                                </select>
                              </h4>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2">


                  <button disabled={this.state.isGoButtonDisabled} readonly={this.state.isGoButtonDisabled} style={{ marginTop: '48px', width: "100%" }} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>


                </div>
              </div>
            </div>
          </div>


          <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
            <div className="card custom-card  ">
              <div className="row">
                <div className="col-4 col-sm-4">
                  <img  src={anglicanimage}></img>
                </div>
                <div className="col-8 col-sm-8 with-border">
                  <div className="student-name">
                    <h5><span className="counter">STUDENT PROFILE</span></h5>
                    <h4>{this.state.isStudentNameLoaded
                      ? this.state.studentName
                      : this.state.loader}</h4>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div className="col-xl-12 col-sm-12 col-md-8 col-lg-12">
            <div className="card cust-card-2">
              <div className="card-header bg-custom-dark">
                <h5>MARKS BY ASSIGNMENT</h5>
              </div>
              <div className="card-body">
                <div className="user-status table-responsive">
                  <div height="260px">

                    {this.state.isMarksByAssignmentsLoaded
                      ? <HighchartsReact
                        highcharts={Highcharts}
                        options={chart1} />
                      : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                    }


                  </div>
                </div>
              </div>
            </div>
          </div>






          <div className="user-profile">

            <div className="row">


              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Submissions</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                      <div>
                        {this.state.isSubChartLoaded
                          ?
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={chart2}
                          />
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Late Submissions Comparison</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                      <div>
                        {this.state.isSubChartLoaded
                          ?
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={late_submission}
                          />
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Activity By Time Of The Day</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                      <div>


                        {this.state.isActivityByTimeLoaded
                          ? <HighchartsReact
                            highcharts={Highcharts}
                            options={activity_by_time}
                          />
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Workload By Week</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">

                      {this.state.isWorkloadLoaded
                        ?
                        <div style={{ height: 5 * 90 }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={workload}
                            containerProps={{ style: { height: 5 * 90 } }}
                          />
                        </div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                      }

                    </div>
                  </div>
                </div>
              </div>


              <div className="col-xl-12 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Latest Feedback</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">

                      <div style={{ textAlign: "center", display: this.state.isFeedbacksLoaded ? "none" : "block" }}><img src={preloaderGif} height="100px"></img></div>

                      <div className="table-responsive" style={{ display: this.state.isFeedbacksLoaded ? "block" : "none" }}>
                        <table className="table">
                          <thead>
                            <tr>

                              <th scope="col">Course</th>
                              <th scope="col">Assignment</th>
                              <th scope="col">Feedback</th>

                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isFeedbacksLoaded
                              ?
                              this.state.feedbacks.map((feedback) => <tr><td>{feedback.course}</td><td>{feedback.assignment}</td><td>{feedback.comment}</td></tr>)

                              :
                              "NO DATA AVAILABLE"


                            }
                          </tbody>
                        </table>
                      </div>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>



        <Modal open={this.state.SubmissionModelOpen} onClose={closeModal} className="custom-large-modal" >
          <div className="modal-body">
            <div className="product-modal row">

              <div className="user-profile cust-model-size">

                <div className="row">


                  <div className="col-xl-12 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSION BY  ASSIGNMENT</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive">
                          <div>
                            {this.state.isSubChartDetailsLoaded
                              ?
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={submission_details_chart}
                              />
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSIONS DETAILS</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive custom-table-class">

                          <div style={{ textAlign: "center", display: this.state.isSubChartDetailsLoaded ? "none" : "block" }}><img src={preloaderGif} height="100px"></img></div>

                          <div className="table-responsive" style={{ display: this.state.isSubChartDetailsLoaded ? "block" : "none" }}>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">ASSIGNMENT</th>
                                  <th scope="col">STATUS</th>
                                  <th scope="col">DUE DATE</th>
                                  <th scope="col">SUBMITTED DATE</th>
                                  <th scope="col">DAYS LATE</th>

                                </tr>
                              </thead>
                              <tbody>
                                {this.state.isSubChartDetailsLoaded
                                  ?
                                  this.state.subDetailsChartItems.table.map((subDetails) => <tr><td>{subDetails.assignment}</td><td>{subDetails.status}</td><td>{subDetails.dueDate}</td><td>{subDetails.submitted}</td><td>{subDetails.delay}</td></tr>)

                                  :
                                  "NO DATA AVAILABLE"
                                }
                              </tbody>
                            </table>
                          </div>
                          <div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </Modal>



      </Fragment>
    );
  }
}

export default StudentProfile;