import React, {Fragment, useState} from "react";
import {Button, PageHeader, Divider, Typography, Form, Input, Select, Tooltip} from "antd";
import PropTypes from "prop-types";
import {useHistory} from "react-router";

import menuData from "../../../../../layout/dashboardManagement/menuData";
import FormLayout from "../../../common/formLayout/FormLayout";
import ConfigCanvasModal from "./partials/ConfigCanvas";

const {Title, Paragraph} = Typography;
const {Option} = Select;

const ConnectionForm = ({form}) => {
    const history = useHistory();
    const [conType, setConType] = useState('canvas');
    const [visibleConfigCanvas, setVisibleConfigCanvas] = useState(false);

    const onChangeType = (e) => {
        setConType(e)
    }

    const onTestConnectionClick = () => {
        setVisibleConfigCanvas(true)
    }

    const onConfigCanvasOk = () => {
        onConfigCanvasCancel();
    }

    const onConfigCanvasCancel = () => {
        setVisibleConfigCanvas(false);
    }

    const renderForm = () => {
        return (
            <div className="dm-ac-form">
                <Title level={4}>Configure Live Connect</Title>
                <Paragraph className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.
                </Paragraph>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        label="Connection Type"
                        name="connectionType"
                        required={true}
                    >
                        <Select defaultValue="canvas" style={{ width: '100%' }} onChange={e => onChangeType(e)}>
                            <Option value="canvas">Canvas</Option>
                            <Option value="mysql">MySQL</Option>
                            <Option value="csv">CSV</Option>
                            <Option value="naplan">Naplan</Option>
                            <Option value="jobReady">Job Ready</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="API url"
                        name="apiUrl"
                        hidden={conType === 'naplan'}
                        rules={[
                            {
                                required: conType !== 'naplan',
                                message: 'Please input API URL',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Database"
                        name="database"
                        hidden={conType === 'naplan'}
                        rules={[
                            {
                                required: conType !== 'naplan',
                                message: 'Please input database',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Divider/>
                    <Button onClick={() => history.push(menuData.userLiveConnect.url)}>Back</Button>
                    <div className="dm-ac-form-buttons">
                        <Button type="primary" htmlType="submit" ghost onClick={onTestConnectionClick}>Test Connection</Button>
                        &nbsp;
                        <Button type="primary" htmlType="submit">Save</Button>
                    </div>

                </Form>
                <ConfigCanvasModal isModalVisible={visibleConfigCanvas} handleOk={onConfigCanvasOk} handleCancel={onConfigCanvasCancel}/>
            </div>
        )
    }

    const renderInfo = () => {
        return (
            <div>
                <Title level={4}>What is Live Connect</Title>
                <Paragraph className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.
                </Paragraph>
                <div className="videoCover">
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/AKU7CN4huYw"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
                </div>
            </div>
        )

    }

    return (
        <Fragment>
            <PageHeader
                className="site-page-header"
            />
            <FormLayout
                form={renderForm()}
                infoContent={renderInfo()}
            />
        </Fragment>
    )
}

ConnectionForm.propTypes = {
    form: PropTypes.object
}

export default ConnectionForm;