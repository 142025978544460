import React from "react";
import PropTypes from "prop-types";
import {InputNumber, Space, List} from "antd";
import _startCase from "lodash/startCase";

import SettingColorPicker from "../ColorPicker";


const OverallIndicatorsView  = ({subSection, data, onValueChange, hasPermission}) => {

    const renderMeasurementContent = (type) => {
        return (
            <List.Item>
                <div>
                    <Space>
                        <InputNumber
                            disabled={!hasPermission}
                            min={0}
                            max={100}
                            style={{width:70}}
                            onChange={value => onValueChange(type, "min", value)}
                            value={data && data[type].min}
                        />
                        <span>&amp;</span>
                        <InputNumber
                            disabled={!hasPermission}
                            min={0}
                            max={100}
                            style={{width:70}}
                            onChange={value => onValueChange(type, "max", value)}
                            value={data && data[type].max}
                        />
                    </Space>
                </div>
                <div>
                   <Space>
                        <span>{_startCase(type)}</span>
                    <div style={{width:70}}>
                            <SettingColorPicker
                                disabled={!hasPermission}
                                onChange={color => onValueChange(type, "color", color)}
                                value={data && data[type].color}
                            />
                    </div>
                   </Space>
                </div>
            </List.Item>
        )
    }


    return (
        <List
            size="small"
            header={
                <div className="ri-overall-header">
                    <div>{_startCase(subSection)} Risk Score Range</div>
                    <div>Colour</div>
                </div>
            }
            bordered
        >
            {renderMeasurementContent("high")}
            {renderMeasurementContent("medium")}
            {renderMeasurementContent("low")}
        </List>
    )
}

OverallIndicatorsView.propTypes = {
    subSection: PropTypes.string,
    data: PropTypes.object,
    onValueChange: PropTypes.func,
    hasPermission: PropTypes.bool,
}

export default  OverallIndicatorsView;