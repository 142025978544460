import {combineReducers} from "redux";

import common from "./common.reducer";
import dataSources from "./dataSources.reducer";
import riskCalculations from "./riskCalculations.reducer";
import riskIndicators from "./riskIndicators.reducer";

const jrGenericSettingReducer = combineReducers({
    common,
    dataSources,
    riskCalculations,
    riskIndicators,
});

export default jrGenericSettingReducer;