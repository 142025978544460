import React, {memo} from 'react';
import _startCase from "lodash/startCase";
import _camelCase from "lodash/camelCase";
import {
    resolveFromObjectByKeyWithCustomPath,
    SUBMISSIONS
} from "../../../../../utils/innovative/resolveSettings";
import StatCellRenderContainer from "../../../common/table/statCell";
import PropTypes from "prop-types";

const SubmissionsCellRenderer = ({params, settings}) => {
    const [, row] = params;

    return (
        <div className="statsCellCover">
            {
                SUBMISSIONS.map(type => <StatCellRenderContainer
                    params={{
                        label: _startCase(type),
                        key: _camelCase(type),
                        row,
                        settings: settings,
                        dataPath: `student_submission.${_startCase(type).replace(" ", "")}`,
                        resolver: resolveFromObjectByKeyWithCustomPath,
                    }}
                />)
            }
        </div>
    );
};

SubmissionsCellRenderer.propTypes = {
    params: PropTypes.array,
    settings: PropTypes.object,
};

export default memo(SubmissionsCellRenderer);