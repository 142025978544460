import {
  HELP_SUBMITTED_FEEDBACK,
  HELP_SUBMITTING_FEEDBACK,
} from '../../constant/actionTypes';
import request from '../../utils/request';
import { getAccessToken } from '../../utils/general';

export const submitHelpFeedBack = params => dispatch => {
  dispatch(isSubmittingFeedBack());

  const feedReducer = response => {
    dispatch(setSettingsFetchStatus(response));
  };


  const requestOptions = {
    service: `insights/helpSupport/support/${getAccessToken()}`,
    method: 'POST',
    data: params,
    authorized: true,
    onSuccess: feedReducer,
    parseData: false,
    headers: {
      Accept: '*/*',
      'Content-Type': 'unset',
    },
  };

  request.make(requestOptions);
};

export const setSettingsFetchStatus = data => dispatch => {
  const { error } = data;
  
  if (error) {
    dispatch({
      type: HELP_SUBMITTED_FEEDBACK,
      payload: {
        isSubmitting: false,
        submitError: error,
      },
    });
    return;
  }

  dispatch({
    type: HELP_SUBMITTED_FEEDBACK,
    payload: {
      isSubmitting: false,
      submitError: {},
      lastSubmitted: Date.now(),
    },
  });
};

export const isSubmittingFeedBack = (payload = true) => dispatch => {
  dispatch({
    type: HELP_SUBMITTING_FEEDBACK,
    payload,
  });
};
