import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from 'antd';

const UnitTableBreadcrumb = ({items}) => {
    return (
        <Breadcrumb separator=">" className="vw-unit-table-breadcrumb">
            {items.map(item => <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)}
        </Breadcrumb>
    )
}

UnitTableBreadcrumb.propTypes = {
    items: PropTypes.array
}

export default UnitTableBreadcrumb;