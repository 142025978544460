import React from "react";
import PropTypes from "prop-types";
import {Button, Form, Input, Typography} from "antd";

import ExternalLoginLayout from "../common/Layout";

const {Text} = Typography;

const ResetPasswordView = ({form, onsubmit, isLoading}) => {
    return (
        <ExternalLoginLayout
            title="Reset Password"
            isLoading={isLoading}
        >
            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                onFinish={onsubmit}
                autoComplete="off"
            >
                <Form.Item
                    label={<Text strong>New Password</Text>}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input new password!',
                        },
                        {
                            min: 8,
                            message: 'Password should have at least 8 letters',
                        },
                        {
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*\\s)(?=.*[!,@,#,$,%,^,&,*,(,),])",
                            message: "Password should contain at least one uppercase, lowercase letter, digit, symbol and cannot contain whitespaces",
                        },
                    ]}
                >
                    <Input.Password size="large" visibilityToggle/>
                </Form.Item>
                <Form.Item
                    label={<Text strong>Confirm Password</Text>}
                    name="re-password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter password again!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password size="large" visibilityToggle/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" style={{width:"100%"}} htmlType="submit" size="large">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </ExternalLoginLayout>
    )
}

ResetPasswordView.propTypes = {
    form: PropTypes.object,
    onsubmit: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default ResetPasswordView;