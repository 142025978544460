import React from "react";

import PricingTable from "./PricingTable";
import {LoadingOutlined} from "@ant-design/icons";
import {Card, PageHeader, Spin} from "antd";

import "../../../assets/css/onboarding/common.css";

const PricingTableListContainer = ({isLoading=false}) => {

    return (

        <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
            <div className="pricing-container">
                <PageHeader
                    className="site-page-header"
                    title="PRICING"
                />
                <Card>
                    <PricingTable/>
                </Card>
            </div>
        </Spin>


    )
}

export default PricingTableListContainer;