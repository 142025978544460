// return saml strategies from fetch response
export const makeSamlStrategiesFetchResponse = state => state && state.SamlAuthentication;

// return all login methods for user
export const getSamlStrategies = state => {
    const result = makeSamlStrategiesFetchResponse(state);
    return result && result.saml && result.saml.data.strategiesArray;
};

// return saml strategies request code loading
export const getSamlStrategiesIsLoading = state => {
    const result = makeSamlStrategiesFetchResponse(state);
    return result && result.samlLoading.isLoading;;
};

// return user data
export const getSamlUserData = state => {
    const result = makeSamlStrategiesFetchResponse(state);
    return result && result.samlUserData.data;
};