import React, { useCallback, useEffect } from 'react';
import _camelCase from "lodash/camelCase";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import TopCardView from "./TopCardView";
import {
  makeInnovativeSettingsLoading,
  makeInnovativeSettingsResponseData
} from "../../../../selectors/innovative/setting.selector";
import {
  resolveFromArrayByRange,
  resolvePropertiesFromSettingArray,
} from "../../../../utils/innovative/resolveSettings";
import {
  makeInnovativeCourseLoading,
  makeInnovativeCourseResponseData,
  makeInnovativeCourseMainReportsResponseData,
  makeInnovativeCoursesMainReportLoading,
} from "../../../../selectors/innovative/course.selector";
import session from '../../../../utils/session';

const flashIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/flash.svg`;
const questionMarkIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/question-mark.svg`;
const speedometerIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/speedometer.svg`;
const stopwatchIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/stopwatch.svg`;

const TopCardContainer = ({ switches }) => {
  // selectors
  const settings = useSelector(makeInnovativeSettingsResponseData);
  const areSettingsLoading = useSelector(makeInnovativeSettingsLoading);
  const isCourseLoading = useSelector(makeInnovativeCourseLoading);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const isMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading)
  const courses = useSelector(makeInnovativeCourseMainReportsResponseData);

  useEffect(() => {
    if (selectedCourse && selectedCourse.course_id) {
      session.setOrAppend(session.keys.innovativeMeta, { selectedCourseId: selectedCourse.course_id });
    }
  }, [JSON.stringify(selectedCourse)]);

  const summaries = courses["main_report_cards"];
  const riskChartOptions = courses["risk_indicator"];

  // a mapping for each card with settings
  const summaryMapping = {
    performanceScore: {
      settingPath: "conditionalColors.performanceScore",
      img: speedometerIcon,
    },
    engagementScore: {
      settingPath: "conditionalColors.engagementScore",
      img: flashIcon,
    },
    lateSubmission: {
      settingPath: "conditionalColors.lateSubmission",
      img: stopwatchIcon,
    },
    missingSubmission: {
      settingPath: "conditionalColors.missingSubmission",
      img: questionMarkIcon,
    }
  }

  // here we resolve colors for each summary and will merge with default values
  const getSummaries = useCallback(() => {
    return summaries.map(summary => {
      const mapping = summaryMapping[_camelCase(summary.title)];
      const selected = {
        ...mapping,
        ...summary
      };
      const list = _get(selected, 'content.list', []);
      list.map(item => {
        const resolved = resolveFromArrayByRange({
          value: item.value,
          settings: _get(settings, selected.settingPath, []),
        })
        // setting a default color if no color found
        item.color = _get(resolved, 'styles.color', "#262626");
        return item;
      })
      selected.content.list = list;
      selected.color = list[0].color;
      return selected;
    });
  }, [settings, summaries]);

  // resolve color settings for the pie chart
  const getRiskConditionOptions = useCallback(() => {
    const resolved = resolvePropertiesFromSettingArray(
      riskChartOptions,
      settings.conditionalColors.definingRiskCondition,
      [0, "risk_category"]
    );

    return {
      series: [{ data: riskChartOptions }],
      plotOptions: {
        pie: {
          colors: resolved.colors,
        }
      }
    };
  }, [settings, riskChartOptions]);


  return <TopCardView
    isSectionLoading={areSettingsLoading || isMainReportLoading || isCourseLoading}
    selectedCourse={selectedCourse}
    summaries={getSummaries()}
    riskChartOptions={getRiskConditionOptions()}
    switches={switches}
  />
};

TopCardContainer.propTypes = {
  switches: PropTypes.object
}

export default TopCardContainer;