import {
    SET_JR_LAYOUT_MENU_SELECTED,
    SET_JR_LAYOUT_BREADCRUMB,
} from "../../constant/actionTypes";

// set main menu selected segment
export const setMenuSelected = payload => dispatch => {
    dispatch({
        type: SET_JR_LAYOUT_MENU_SELECTED,
        payload,
    });
};

// set breadcrumb segments
export const setBreadcrumb = (payload) => dispatch => {
    dispatch({
        type: SET_JR_LAYOUT_BREADCRUMB,
        payload,
    });
};
