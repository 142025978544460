import { VTT_SET_SIDEBAR_DATA } from '../../../constant/actionTypes';

const defaultState = {
  sidebar: {
    isSidebar: false,
    collapsed: false,
  },
};

const layoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VTT_SET_SIDEBAR_DATA:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default layoutReducer;
