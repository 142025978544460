import React, {useCallback} from 'react';
import PieChart from "../charts/PieChart";
import PropTypes from "prop-types";
import Highcharts from "highcharts";

const ChartDonutCellRenderer = ({data, settings, resolver, customOptions, propMapping, customClasses}) => {

    const resolveCellData = useCallback(() => {
        if(propMapping){
            return resolver(data, settings, propMapping);
        }
        return resolver(data, settings);
    }, [data, settings, propMapping]);

    const resolved = resolveCellData();

    const chartOptions = {
        credits: {
            enabled: false
        },
        chart: {
            height: 100,
            // width: 180,
        },
        legend: {
            useHtml: true,
            align: 'right',
            verticalAlign:'middle',
            itemHeight: 100,
            symbolHeight: 5,
            y: -2,
            itemStyle: {
                font: '8pt Trebuchet MS, Verdana, sans-serif',
                fontWeight: '600',
             }
        },
        series: [{data}],
        plotOptions: {
            pie: {
                colors: resolved.colors,
                size: '60',
                left: '10',
            }
        }
    };

    const config = Highcharts.merge(chartOptions, customOptions);

    return (
        <div className={`tablePieChartCover ${customClasses}`}>
             <PieChart chartOptions={config} noDataShow={false} rowPieChart />
        </div>
    );
};

ChartDonutCellRenderer.defaultProps = {
    customClasses: ""
}

ChartDonutCellRenderer.propTypes = {
    data: PropTypes.array,
    resolver: PropTypes.func,
    customOptions: PropTypes.object,
    propMapping: PropTypes.array,
    customClasses: PropTypes.array,
};

export default ChartDonutCellRenderer;