import React from 'react';
import { Card, Tabs } from 'antd';
import PropTypes from 'prop-types';
import _upperFirst from 'lodash/upperFirst';
import _kebabCase from 'lodash/kebabCase';

import SettingHeader from '../../../common/SectionHeader';
import AcademicTabContainer from './tabs/academic';
import FinancialTabContainer from './tabs/financial';
import ComplianceTabContainer from './tabs/compliance';
import OverviewTabContainer from './tabs/overview';

import { subSections } from '../../../../../../constant/vetTrack/analysis';

const { TabPane } = Tabs;

const TabSection = ({ tabConfig, barDisabled }) => (
  <Card>
    <SettingHeader
      title={_upperFirst(_kebabCase(tabConfig.activeTab))}
      items={[
        {
          name: 'Risk Analysis',
          onClick: () => tabConfig.setActiveTab(subSections.overview.key),
        },
        _upperFirst(_kebabCase(tabConfig.activeTab)),
      ]}
    />
    <Tabs
      activeKey={tabConfig.activeTab}
      onChange={tabConfig.setActiveTab}
      destroyInactiveTabPane
    >
      <TabPane
        tab="Overview"
        key={subSections.overview.key}
        disabled={barDisabled}
      >
        <OverviewTabContainer />
      </TabPane>
      <TabPane
        tab="Academic"
        key={subSections.academic.key}
        disabled={barDisabled}
      >
        <AcademicTabContainer />
      </TabPane>
      <TabPane
        tab="Financial"
        key={subSections.financial.key}
        disabled={barDisabled}
      >
        <FinancialTabContainer />
      </TabPane>
      <TabPane
        tab="Compliance"
        key={subSections.compliance.key}
        disabled={barDisabled}
      >
        <ComplianceTabContainer />
      </TabPane>
    </Tabs>
  </Card>
);

TabSection.propTypes = {
  tabConfig: PropTypes.object,
  barDisabled: PropTypes.bool,
};

export default TabSection;
