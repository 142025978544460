import React, { memo } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import PieChart from '../../../../../../../../../../common/charts/PieChart';
import NAMessage from '../../../../../../../../NAMessage';

import { acedamicChartConf } from '../../../../../../../../../../../../../constant/vetTrack/configs';

const { Text } = Typography;

const ChartCard = ({ name, percentage, data, enabled }) => {
  const titleData = {
    title: {
      text: percentage && `${percentage}%`,
      verticalAlign: 'middle',
      style: {
        paddingTop: '3px',
      },
      useHTML: true,
    },
  };

  const seriesData = {
    series: [
      {
        colorByPoint: true,
        states: {
          hover: {
            enabled: false,
            brightness: 0,
          },
        },
        data,
      },
    ],
  };

  const getContent = () => {
    if (!enabled) {
      return <NAMessage contentStyles={{ padding: '49px 0' }} />;
    }

    return (
      <PieChart chartOptions={{ ...acedamicChartConf, ...titleData, ...seriesData }} />
    );
  };

  return (
    <div>
      <Text>{name}</Text>
      {getContent()}
    </div>
  );
};

ChartCard.defaultProps = {
  enabled: true,
};

ChartCard.propTypes = {
  name: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  data: PropTypes.array,
  enabled: PropTypes.bool,
};

export default memo(ChartCard);
