import React, { useCallback, useEffect, useState } from 'react';
import { Button } from "antd";
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import {
    makeInnovativeCourseMainReportStudentsResponseData,
    makeInnovativeCoursesMainReportLoading
} from "../../../../selectors/innovative/course.selector";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import StatCellRenderContainer from "../../common/table/statCell";
import {
    makeInnovativeSettingByPath,
} from "../../../../selectors/innovative/setting.selector";
import { resolveFromArrayByRange } from "../../../../utils/innovative/resolveSettings";
import { arrayToStringConvert } from "../../../../utils/general";
import StudentGridView from "./StudentGridView";
import { Link } from "react-router-dom";
import RiskIndicatorCellRenderer from "./cellrenderers/RiskIndicatorCellRenderer";
import SectionCellRenderer from "./cellrenderers/SectionCellRenderer";
import PropTypes from "prop-types";

const ADMIN_PATH = "/insights/admin/courses";

const StudentGridContainer = ({ courseId, selectedTerm, setSearchFunc }) => {
    const conditionalColorSettingsPath = "conditionalColors"

    const parsedUrl = new URL(window.location.href);
    const isAdminPath = parsedUrl.pathname === ADMIN_PATH;

    // selectors
    const conditionalColors = useSelector(makeInnovativeSettingByPath(conditionalColorSettingsPath));
    const studentDetails = useSelector(makeInnovativeCourseMainReportStudentsResponseData);
    const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);

    // state
    const [studentList, setStudentList] = useState([]);

    // student search from the array
    const onStudentSearch = useCallback(_debounce((keyword) => {
        if (keyword) {
            const filtered = studentDetails.filter(student => {
                return student['student_name'].toLowerCase().includes(keyword.toLowerCase());
            });
            return setStudentList(filtered);
        }

        return setStudentList(studentDetails);
    }, 500), [studentDetails]);

    // init debounced calls
    const debouncedCall = (event, callback) => {
        const { value } = event.target;
        callback(value);
    };

    // column definition for the table
    const getColumnDefinition = useCallback(() => {
        return [
            {
                title: "Student Details",
                dataIndex: "student_name",
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["student_sis_user_id"]}
                    avatar={row["student_avatar_url"]}
                    url={`/insights/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}`}
                />,
            }, {
                title: "",
                render: (value, row) => <Button htmlType="button">
                    <Link to={
                        isAdminPath ?  `/insights/admin/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}`:
                        `/insights/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}`
                    }>View</Link>
                </Button>
            },
            {
                title: "Section",
                dataIndex: "sections",
                sorter: (a, b) => arrayToStringConvert(a.sections.map(sec => sec.text)).localeCompare(arrayToStringConvert(b.sections.map(sec => sec.text))),
                render: (value) => <SectionCellRenderer sections={value} />
            },
            {
                title: "Performance Score",
                dataIndex: "student_performance_score",
                sorter: (a, b) => Number(a["student_performance_score"]) - Number(b["student_performance_score"]),
                render: (value, row) => <StatCellRenderContainer
                    params={{
                        value,
                        row,
                        settings: conditionalColors.performanceScore,
                        resolver: resolveFromArrayByRange,
                    }}
                />
            }, {
                title: "Engagement Score",
                dataIndex: "student_engagement_score",
                sorter: (a, b) => Number(a["student_engagement_score"]) - Number(b["student_engagement_score"]),
                render: (value, row) => <StatCellRenderContainer
                    params={{
                        value,
                        row,
                        settings: conditionalColors.engagementScore,
                        resolver: resolveFromArrayByRange,
                    }}
                />
            }, {
                title: "Late Submission",
                dataIndex: "student_late_submission",
                sorter: (a, b) => Number(a["student_late_submission"]) - Number(b["student_late_submission"]),
                render: (value, row) => <StatCellRenderContainer
                    params={{
                        value,
                        row,
                        settings: conditionalColors.lateSubmission,
                        resolver: resolveFromArrayByRange,
                    }}
                />
            }, {
                title: "Missing Submissions",
                dataIndex: "student_missing_submission",
                sorter: (a, b) => Number(a["student_missing_submission"]) - Number(b["student_missing_submission"]),
                render: (value, row) => <StatCellRenderContainer
                    params={{
                        value,
                        row,
                        settings: conditionalColors.missingSubmission,
                        resolver: resolveFromArrayByRange,
                    }}
                />
            }, {
                title: "Risk Indicator",
                dataIndex: "student_risk_indicator",
                sorter: (a, b) => a["student_risk_indicator"] ? a["student_risk_indicator"].localeCompare(b["student_risk_indicator"]) : 1,
                render: (value, row) => <RiskIndicatorCellRenderer
                    params={{
                        value,
                        row,
                        key: "risk_category",
                        settings: conditionalColors.definingRiskCondition,
                    }}
                />
            },
        ];
    }, [conditionalColors.definingRiskCondition, conditionalColors.engagementScore, conditionalColors.lateSubmission, conditionalColors.missingSubmission, conditionalColors.performanceScore, courseId, selectedTerm]);

    useEffect(() => {
        setStudentList(studentDetails);
    }, [studentDetails]);

    return (
        <StudentGridView
            isLoading={courseMainReportLoading}
            onSearch={e => debouncedCall(e, onStudentSearch)}
            dataSource={studentList}
            columns={getColumnDefinition()}
            setSearchFunc={setSearchFunc}
        />)
};

StudentGridContainer.propTypes = {
    courseId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    selectedTerm: PropTypes.string,
    setSearchFunc: PropTypes.func,
};

export default StudentGridContainer;