import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import StudentsTableHeader from '../../../../../common/table/StudentsTableHeader';
import { withStudentTableSearch } from '../../../../../common/table/SearchInTable';
import { withTablePaginator } from '../../../../../common/table/withPaginator';

const Students = ({ students, columns, searchConfig, pagination }) => (
  <div className="sectorTable">
    <StudentsTableHeader
      title="Students"
      searchEnabled
      dataSource={students}
      {...searchConfig}
    />
    <Table
      columns={columns}
      dataSource={students}
      pagination={pagination}
      scroll={{ x: 'max-content' }}
    />
  </div>
);

Students.propTypes = {
  students: PropTypes.array,
  pagination: PropTypes.object,
  columns: PropTypes.array,
  searchConfig: PropTypes.object,
};

export default withStudentTableSearch(withTablePaginator(Students));
