import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Typography} from "antd";

import TopCard from "../topCards/TopCard";
import StatChart from "../../common/Chart";

import {prepareMainChartOptions} from "../../chartOptions";

const {Title} = Typography;

const StatMainSection = ({data}) => {
    const {impressionViewers, impressionViews, uniqueViewers, dashboardWiseViews} = data;

    return (
        <Fragment>
            <Title style={{textAlign: "center"}} className="stat-header" level={5}>Views by Dashboard</Title>
            <div className="pageViewsWidget">
                <div>
                    <div className="stat-top-card-section">
                        {/* commented due to suggested change. Might need to enable in future if requested*/}
                        {/*<div>*/}
                        {/*    <TopCard title="Total Page Views" value={impressionViews}/>*/}
                        {/*</div>*/}
                        <div>
                            <TopCard title="Total Dashboard Views" value={impressionViewers}/>
                        </div>
                        <div>
                            <TopCard title="Unique Viewers" value={uniqueViewers}/>
                        </div>
                    </div>
                </div>
                <div style={{width:"100%"}}>
                    <StatChart chartOptions={prepareMainChartOptions(dashboardWiseViews)}/>
                </div>
            </div>
        </Fragment>
    )
}

StatMainSection.propTypes = {
    data: PropTypes.object
}

export default StatMainSection;