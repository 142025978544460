export const drawerTypes = {
    enrolment: "enrolment",
    invoice: "invoice",
    local: "local",
}

export const settingsSections = {
    dataSources: "dataSources",
    riskCalculations: "riskCalculations",
    riskIndicators: "riskIndicators",
}

export const calculationModes = {
    condition: "condition",
    category: "category",
}

export const subSections = {
    academic: {
        key:"academic",
        id: 1
    },
    compliance: {
        key:"compliance",
        id: 3
    },
    financial: {
        key:"financial",
        id: 2
    },
    wellBeing: {
        key:"wellBeing",
        id: 4
    },
    overview: {
        key:"overview",
        id: 5
    },
}

export const academicSections = {
    unitProgress: {
        key: "unitProgress",
        name: "Unit Progress(%)",
        mapper: true,
        multiSelect: true,
        mode: calculationModes.condition
    },
    attendance: {
        key: "attendance",
        name: "Attendance(%)",
        mapper: true,
        mode: calculationModes.condition
    },
    mainLang: {
        key: "mainLang",
        name: "Main Language",
        mapper: false,
        mode: calculationModes.category
    },
    employmentStatus: {
        key: "employmentStatus",
        name: "Employment Status",
        mapper: false,
        mode: calculationModes.category
    },
    eligibilityExemption: {
        key: "eligibilityExemption",
        name: "Eligibility Exemption",
        mapper: false,
        mode: calculationModes.category
    },
    entryReq: {
        key: "entryReq",
        name: "Entry Requirement Met",
        mapper: false,
        mode: calculationModes.condition
    },
    studyReason: {
        key: "studyReason",
        name: "Study Reasons",
        mapper: false,
        mode: calculationModes.category
    },
}

export const financialSections = {
    completedPayment: {
        key: "completedPayment",
        name: "Completed Payment(%)",
        mapper: false,
        mode: calculationModes.condition,
        colClassName: "ra-student-ryt-col",
        displayValModifier: (value) => value.toLocaleString()
    },
    employmentStatus: {
        key: "employmentStatus",
        name: "Employment Status",
        mapper: false,
        mode: calculationModes.category
    },
    dueFee: {
        key: "dueFee",
        name: "Due Fee($)",
        mapper: false,
        mode: calculationModes.condition,
        colClassName: "ra-student-ryt-col",
        displayValModifier: (value) => value.toLocaleString()
    },
    paymentDueDate: {
        key: "paymentDueDate",
        name: "Payment Due Date",
        mapper: false,
        mode: calculationModes.condition
    },
    vetFeeHelp: {
        key: "vetFeeHelp",
        name: "VET Fee Help",
        mapper: false,
        mode: calculationModes.category
    },
    fundingSourceState: {
        key: "fundingSourceState",
        name: "Funding Source State",
        mapper: false,
        mode: calculationModes.category
    },
    feeExceptionIndicator: {
        key: "feeExceptionIndicator",
        name: "Fee Exception Indicator",
        mapper: false,
        mode: calculationModes.category
    },
    historicalPaymentDelays: {
        key: "historicalPaymentDelays",
        name: "Historical Payment Delays",
        mapper: false,
        mode: calculationModes.condition
    },
}

export const complianceSections = {
    visaExpiration: {
        key: "visaExpiration",
        name: "Visa Expiration(in days)",
        mapper: true,
        mode: calculationModes.condition
    },
    passportExpiration: {
        key: "passportExpiration",
        name: "Passport Expiration(in days)",
        mapper: true,
        mode: calculationModes.condition
    },
    overseasStudentHealthCover: {
        key: "overseasStudentHealthCover",
        name: "OSHC Expiration(in days)",
        mapper: false,
        mode: calculationModes.condition
    },
    visaType: {
        key: "visaType",
        name: "Visa Type",
        mapper: false,
        mode: calculationModes.category
    },
    visaStatus: {
        key: "visaStatus",
        name: "Visa Status",
        mapper: false,
        mode: calculationModes.category
    }
}