import _get from "lodash/get";

// return sale admin data
export const getSaleAdminData = state => _get(state, 'dashboardManage.saleAdmin');


// return tenant admin list data
export const getTenantAdminListData = state => {
    const result = getSaleAdminData(state);
    return result && result.list;
};

