import {
    FETCH_SAML_STRATEGIES,
    FETCH_SAML_STRATEGIES_LOADING,
    SAML_USER_DATA
} from '../../constant/actionTypes';

export const samlAuthenticationReducerDefaultState = {
    saml: {
        isLoading: false,
        error: false,
        data: {},
    },
    samlLoading: {
        isLoading: false,
    },
    samlUserData: {
        isLoading: false,
        error: false,
        data: {},
    },
};

const SamlAuthenticationReducer = (state = samlAuthenticationReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_SAML_STRATEGIES:
            return {
                ...state,
                saml: {
                    ...state.saml.data.strategiesArray,
                    ...action.payload
                }
            }
        case FETCH_SAML_STRATEGIES_LOADING:
            return {
                ...state,
                samlLoading: {
                    isLoading: state.samlLoading.isLoading
                }
            }
        case SAML_USER_DATA:
            return {
                ...state,
                samlUserData: {
                    ...state.saml.data.samlUserData,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default SamlAuthenticationReducer;