import { notification } from "antd";
import session from "./session";
import { get as _get } from 'lodash';

export const queryStringToObject = (queryString) => {
    return Object.fromEntries(new URLSearchParams(queryString));
};

export const strToNumber = (value) => {
    return Number(value);
}

export const showNotification = (conf) => {
    const { type, ...rest } = conf;

    notification[type ? type : 'success']({
        placement: 'bottomRight',
        ...rest,
    });
}

export const getAccessToken = () => {
    return session.get([
        session.keys.innovativeMeta,
        "access"
    ])
}

export const arrayToStringConvert = (data, separator = ", ", startIndex = null, endIndex = null) => {
    if (Array.isArray(data)) {
        if (startIndex && endIndex) {
            return data.slice(startIndex, endIndex).join(separator);
        } else if (startIndex) {
            return data.slice(startIndex).join(separator);
        } else if (endIndex) {
            return data.slice(0, endIndex).join(separator);
        } else {
            return data.join(separator);
        }
    } else {
        return data;
    }
}

export const orderAlphabeticallyArrayData = (data, key, type = "asc") => {
    if (Array.isArray(data)) {
        if (type === "asc") {
            return data.sort((a, b) => a[key].localeCompare(b[key]))
        } else {
            data.sort((a, b) => b[key].localeCompare(a[key]))
            return data
        }
    } else {
        return data;
    }
}

export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

// this will return an array with values extracted from the given key from the object array
export const getElementArrayFromObjectArray = (array, key) => {
    if (Array.isArray(array)) {
        return array.map(arr => {
            if (arr && typeof arr === "object") {
                return arr[key]
            } else {
                return arr
            }
        })
    } else {
        return array;
    }
}

// compares 2 arrays with same length and return a true if both array contain same elements else return false
export const compareArrays = (array1, array2) => {
    return (array1.length === array2.length && array1.every(element => array2.includes(element)));
};

// check if is admin view
const ACCOUNT_MEMBERSHIP = "accountmembership";

export const isAdminDashboardView = ({ actAs, path, ADMIN_PATH }) => {
    return (
        checkIfVariableHasValueAndLength(actAs) &&
        actAs.toLowerCase() === ACCOUNT_MEMBERSHIP &&
        path === ADMIN_PATH);
};

// validateURL and return to Error Page
export const ValidateURL = ({ url, validateUrlParams = [] }) => {
    const currentURL = new URLSearchParams(url);
    const dashboardManagementData = session.get(session.keys.impressionMeta);
    const token = _get(dashboardManagementData, "token", "");

    const keyValidation = (searchParam, keyValue) => {
        if (searchParam === "token" && keyValue !== token) {
            return false;
        };
        return (keyValue.length > 0 && !keyValue.includes('""') && !keyValue.includes("''"));
    };

    return validateUrlParams.every(searchParam => currentURL.has(searchParam) && keyValidation(searchParam, currentURL.get(searchParam)));
};

// cehck if a variable has a value
export const checkIfVariableHasValueAndLength = variable => {
    if (variable !== undefined && variable !== null) {
        if (
            variable.length !== undefined &&
            variable.length !== null &&
            variable.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isValidDate = dateString => {
    if (dateString === 0) {
        return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
};
