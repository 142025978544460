import {
    JR_GEN_COMPLIANCE_CARDS_DATA_FETCH,
    JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH,
    JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH,
    JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
    JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
    JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH,
    JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING
} from "../../../constant/actionTypes";
import service from "../../../utils/serviceHandlers/jobReady/customServices/jrGeneric";
import {resolveResponseErrorFormat} from "../../../components/jobReadyGeneric/utils/general";

/** Fetch compliance cards data **/
// fetch compliance cards data loading
const fetchComplianceCardsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch compliance cards data handler
const fetchComplianceCardsDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_CARDS_DATA_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch compliance cards data
export const fetchComplianceCards = (data) => dispatch => {
    dispatch(fetchComplianceCardsLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchRiskCard`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchComplianceCardsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchComplianceCardsDataStatus(resolveResponseErrorFormat(error)))
    })
};

/** Fetch compliance students data **/
// fetch compliance students data loading
const fetchComplianceStudentsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch compliance students data handler
const fetchComplianceStudentsDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_STUDENTS_DATA_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch compliance students data
export const fetchComplianceStudents = (data) => dispatch => {
    dispatch(fetchComplianceStudentsLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchRiskStudents`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchComplianceStudentsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchComplianceStudentsDataStatus(resolveResponseErrorFormat(error)))
    })
};

/** Fetch compliance overview data **/
// fetch compliance overview data loading
const fetchComplianceOverviewLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch compliance overview data handler
const fetchComplianceOverviewDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_OVERVIEW_DATA_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch compliance overview data
export const fetchComplianceOverview = (data) => dispatch => {
    dispatch(fetchComplianceOverviewLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchRiskOverviewCharts`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchComplianceOverviewDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchComplianceOverviewDataStatus(resolveResponseErrorFormat(error)))
    })
};

/** Fetch compliance overview duration to expire data **/
// fetch compliance overview duration to expire data loading
const fetchComplianceOverviewDurationToExpireLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch compliance overview duration to expire data handler
const fetchComplianceOverviewDurationToExpireDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch compliance overview duration to expire data
export const fetchComplianceOverviewDurationToExpire = (data) => dispatch => {
    dispatch(fetchComplianceOverviewDurationToExpireLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchComplianceOverviewStackbarPopup`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchComplianceOverviewDurationToExpireDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchComplianceOverviewDurationToExpireDataStatus(resolveResponseErrorFormat(error)))
    })
};

/** Fetch compliance overview data file notes **/
// fetch compliance overview data file notes loading
const fetchComplianceOverviewFileNotesLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch compliance overview data file notes handler
const fetchComplianceOverviewFileNotesDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_COMPLIANCE_FILE_NOTES_DATA_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch compliance overview file notes data
export const fetchComplianceOverviewFileNotes = (data) => dispatch => {
    dispatch(fetchComplianceOverviewFileNotesLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchComplianceOverviewDonutPopup`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchComplianceOverviewFileNotesDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchComplianceOverviewFileNotesDataStatus(resolveResponseErrorFormat(error)))
    })
};