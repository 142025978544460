import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";

import {makeVwDealershipMainReportDataPathwayFilterResponseData} from "../../../../../../../selectors/vwDealership/dealership.selector";
import {makeVwDealershipSidebarSelectDataResponse} from "../../../../../../../selectors/vwDealership/dealership.selector";
import {fetchTimelineData, clearTimelineData} from "../../../../../../../actions/vwDealership/qualificationPathway.action";

import FilterForm from "./FilterView";
import {onError} from "../../../../../../../utils/notificationHandler";

const FilterFormContainer = ({setSelectedFiltersMeta}) => {
    const [selectedFilters, setSelectedFilters] = useState({
        roles: null,
        pathways: null,
        programs: null,
        students: [],
    })
    const dispatch = useDispatch();

    const [rolesList, setRolesList] = useState([]);
    const [pathwaysList, setPathwaysList] = useState([]);
    const [programsList, setProgramsList] = useState([]);
    const [studentsList, setStudentsList] = useState([]);

    // selectors
    const roles = useSelector(makeVwDealershipMainReportDataPathwayFilterResponseData('roles'));
    const pathways = useSelector(makeVwDealershipMainReportDataPathwayFilterResponseData('pathways'));
    const programs = useSelector(makeVwDealershipMainReportDataPathwayFilterResponseData('programs'));
    const students = useSelector(makeVwDealershipMainReportDataPathwayFilterResponseData('students'));
    const sidebarSelectedData = useSelector(makeVwDealershipSidebarSelectDataResponse);

    const levels = {
        roles:{data: roles, key: "roles", roles:false, pathways:false, programs:false, func: setRolesList},
        pathways:{data: pathways, key: "pathways", roles:true, pathways:false, programs:false, func: setPathwaysList},
        programs:{data: programs, key: "programs", roles:true, pathways:true, programs:false, func: setProgramsList},
        students:{data: students, key: "students", roles:true, pathways:true, programs:true, func: setStudentsList, isArr:true}
    };

    const getData = useCallback((filterState, list, roles, pathways, programs, upKey, setFunc, isArr=false) => {
        // this will check the current filter values and regenerate its successors' options and auto select the 1st option
        // conditions will check whether the particular item needs to be filtered or not.
        const dataset =  list.filter(item => {
            let checkVal = true;
            if(roles){
                checkVal = item.roles.includes(filterState.roles)
            }
            if(pathways){
                if (checkVal){
                    checkVal = item.pathways.includes(filterState.pathways)
                }
            }
            if(programs){
                if (checkVal) {
                    checkVal = item.programs.includes(filterState.programs)
                }
            }
            return checkVal;
        });
        filterState[upKey] = isArr ?  dataset.map(item => item.id) :dataset[0]?.id;
        setFunc(dataset);
    }, [roles])

    const setFilterItemData = (key, filterState, effectedLevels) => {
        // loop through effected filters and make them re-rendered with filtered options
        effectedLevels.map(level => {
            const levelObj = levels[level];
            getData(filterState, levelObj.data, levelObj.roles, levelObj.pathways, levelObj.programs, levelObj.key, levelObj.func, levelObj.isArr);
        })
        return filterState;
    }

    const setSelectedData = (key, value) => {
        let filterState = selectedFilters;
        filterState[key] = value;
        const levelKeys = Object.keys(levels);

        const currentLevel = levelKeys.indexOf(key);
        if(currentLevel + 1 !== levelKeys.length){
            const effectedLevels = levelKeys.slice(currentLevel +1 , levels.length);

            effectedLevels.map(level => {
                // reset the selected value
                filterState[level] = null;
            })

            // regenerate filter option and auto select
            const data = setFilterItemData(key, filterState, effectedLevels);
            setSelectedFilters(data)
        }

    }

    const onApplyClick = () => {
        if (selectedFilters.students.length){
            dispatch(fetchTimelineData({...selectedFilters, dealerships: sidebarSelectedData.dealership}));
            setSelectedFiltersMeta({...selectedFilters});
        }else{
            onError("Students cannot be empty!")
        }
    }

    const onFilterChange = (key, value) => {
        setSelectedData(key, value)
    }

    useEffect(() => {
        // we assume that if roles are changed, which means others are changed as well(data is being re-fetched)

        // reset selected data
        setRolesList([]);
        setStudentsList([]);
        setProgramsList([]);
        setPathwaysList([]);

        let dataset = {}
        if (roles && roles.length){
            dataset["roles"] = roles[0].id;
            setRolesList(roles);
        }

        if (pathways && pathways.length){
            getData(dataset, pathways, true, false, false, "pathways", setPathwaysList);
            getData(dataset, programs, true, true, false, "programs", setProgramsList);
            getData(dataset, students, true, true, true, "students", setStudentsList, true);
        }

        if (programs && programs.length){
            getData(dataset, programs, true, true, false, "programs", setProgramsList);
            getData(dataset, students, true, true, true, "students", setStudentsList, true);
        }
        if (students && students.length){
            getData(dataset, students, true, true, true, "students", setStudentsList, true);
        }
        setSelectedFilters(dataset);

        //clear the existing pathway records first
        dispatch(clearTimelineData());

        // run the api call if there are students to be selected
        if(dataset.students && dataset.students.length){
            dispatch(fetchTimelineData({...dataset, dealerships: sidebarSelectedData.dealership}));
            setSelectedFiltersMeta({...dataset})
        }
    }, [roles])

    return (
        <FilterForm
            roles={rolesList}
            pathways={pathwaysList}
            programs={programsList}
            students={studentsList}
            selected={selectedFilters}
            onFilterChange={onFilterChange}
            onApplyClick={onApplyClick}
        />
    )
}

FilterFormContainer.propTypes = {
    setSelectedFiltersMeta: PropTypes.func,
}

export default FilterFormContainer;