import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const SetPermissionModal = ({ isVisible, handleOk, handleCancel }) => (
  <Modal
    title="Set Permissions"
    visible={isVisible}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    {/* TODO: need to build */}
    <p>Some contents...</p>
    <p>Some contents...</p>
    <p>Some contents...</p>
  </Modal>
);

SetPermissionModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default SetPermissionModal;
