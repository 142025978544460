import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Radio, Input } from 'antd';

import Loader from '../../../../../common/layout/Loader';

const passwordPattern =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*\\s)(?=.*[!,@,#,$,%,^,&,*,(,),])';
const passwordError =
  'Password should contain at least one uppercase, lowercase letter, digit, symbol and cannot contain whitespaces';

const ResetPasswordModal = ({
  isVisible,
  handleOk,
  handleCancel,
  onFormSubmit,
  form,
  isLoading,
}) => (
  <Modal
    title="Reset Password"
    visible={isVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    okText="Reset Password"
  >
    <Loader isLoading={isLoading} />
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      layout="vertical"
      initialValues={{ resetType: 'email' }}
    >
      <Form.Item
        name="resetType"
        rules={[{ required: true, message: 'Select Type' }]}
      >
        <Radio.Group>
          <Radio value="email">Email</Radio>
          <Radio value="tempPassword">Temporary Password</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.resetType !== currentValues.resetType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('resetType') === 'tempPassword' && (
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true },
                  {
                    pattern: passwordPattern,
                    message: passwordError,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="password2"
                label="Re-type Password"
                rules={[
                  { required: true },
                  ({ filedValue }) => ({
                    validator(_, value) {
                      if (!value || filedValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </Form>
  </Modal>
);

ResetPasswordModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  form: PropTypes.object,
};

export default ResetPasswordModal;
