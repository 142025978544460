import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { LogoutOutlined } from '@ant-design/icons';
import { externalSignOut } from "../../../../actions/authentication.action";
import ConfirmBox from "../../../dashboardCommon/ConfirmBoxModal";
import localStore from "../../../../utils/localStorage";

const UserLogout = () => {
	const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const onLogoutClick = () => {
		dispatch(externalSignOut(() => {
			localStore.removeLsSlim(localStore.keys.authMeta);
			history.push("/tenant/login");
		}));
	}

	return (
		<Fragment>
			<button className="vtt-setting-icon" onClick={() => setShowLogoutConfirm(true)}>
				<span
					onKeyDown={() => { }}
					title="Sign out"
					role="button"
					tabIndex={0}
				>
					<div className="vtt-setting-btn-HList">
						<div className="vtt-setting-btn-VList">
							<p className="vtt-logout-userName-lbl">
								Replace with user name
							</p>
							<p className="vtt-logout-role-lbl">
								Replace with user role
							</p>
						</div>
						<div className="vtt-logOut-icon">
							<LogoutOutlined />
						</div>
					</div>
				</span>
			</button>

			<ConfirmBox
				topic="Logout Confirmation"
				message="Are you sure you want to log out?"
				confirmButtonColor="danger"
				confirmButtonText="Logout"
				show={showLogoutConfirm}
				onHide={() => setShowLogoutConfirm(false)}
				onConfirm={onLogoutClick} 
			/>
		</Fragment>
	)
}

export default UserLogout;
