import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ children, isLoading }) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin className={'in-loader'} indicator={antIcon} spinning={isLoading}>
            {children}
        </Spin>
    )
}

export default Loader;