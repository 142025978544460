import React, { memo } from "react";
import PropTypes from "prop-types";
import { Col, Row, Space } from "antd";

import Sidebar from "../../../common/sidebarLayout";
import CollapseSection from "./partials/CollapseSection";

const SettingSidebar = ({ dealerships, departments, roles, openPanels, setOpenPanels }) => {

    return (
        <Sidebar width={400}>
            <Space style={{ width: "100%" }} direction="vertical">
                <Row gutter={[16, 24]}>
                    <Col>
                        <h4>Unit Structure - Mark as Mandatory</h4>
                        <CollapseSection
                            dealerships={dealerships}
                            departments={departments}
                            roles={roles}
                            openPanels={openPanels}
                            setOpenPanels={setOpenPanels}
                        />
                    </Col>

                </Row>

            </Space>
        </Sidebar>
    )
}

SettingSidebar.propTypes = {
    dealerships: PropTypes.array,
    departments: PropTypes.array,
    roles: PropTypes.array,
    openPanels: PropTypes.object,
    setOpenPanels: PropTypes.func,
}

export default memo(SettingSidebar);