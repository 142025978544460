import React from "react";
import {Avatar, Typography} from "antd";
import { AntDesignOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";

const { Text } = Typography;

const StatTableNameCell = ({title, subtitle, icon, src}) => {

    return (
        <div style={{display:"flex", alignItems:"center"}}>
                <div style={{display:"inline-block", marginRight:10}}>
                    <Avatar
                        icon={icon}
                        src={src}
                    />
                </div>
                <div style={{display:"inline-block"}}>
                    <Text strong>{title}</Text>
                    <br/>
                     <Text type="secondary">{subtitle}</Text>

                </div>
        </div>
    )
}

StatTableNameCell.defaultProps = {
    icon: <AntDesignOutlined/>
}

StatTableNameCell.propTypes = {
    icon: PropTypes.object,
    title:PropTypes.string,
    subtitle:PropTypes.string,
    src:PropTypes.string,
}

export default StatTableNameCell