import React, { useCallback, memo } from "react";
import PropTypes from "prop-types";
import { Collapse } from 'antd';
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const CollapseSection = ({ dealerships, departments, roles, openPanels, setOpenPanels }) => {

    const renderPanels = useCallback((title, dataList, key, childSection = null) => {
        return (
            <Collapse
                accordion
                ghost
                destroyInactivePanel
                defaultActiveKey={"1"}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
            >
                <Panel header={title} key="1" style={{ fontWeight: "bold" }}>
                    <Collapse
                        activeKey={openPanels[key]}
                        accordion
                        ghost
                        destroyInactivePanel
                        style={{ fontWeight: "normal" }}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="site-collapse-custom-collapse"
                        onChange={(e) => (key !== "program" || e !== undefined) && setOpenPanels(key, e)}
                    >
                        {dataList.map(item =>
                            <Panel
                                header={<span style={{ color: openPanels[key] === item.id && "#0C5CA7" }}>{item.name}</span>}
                                key={item.id}
                                showArrow={!!item.childrenKey || !!childSection}
                            >
                                {childSection ? childSection : item.childrenKey && renderChildren(item)}
                            </Panel>
                        )}
                    </Collapse>
                </Panel>
            </Collapse>
        )
    }, [dealerships, departments, roles, openPanels, setOpenPanels])

    const renderChildren = (data) => {
        return renderPanels(data.childrenName, data.children, data.childrenKey)
    }

    const renderRoles = () => {
        return renderPanels("Roles", roles, "role")
    }

    const renderDepartments = () => {
        return renderPanels("Departments", departments, "department", renderRoles())
    }

    const renderDealerships = () => {

        return renderPanels("Dealerships", dealerships, "dealership", renderDepartments())
    }

    return <div className="setting-collapse">
        <Collapse
            activeKey={openPanels["main"]}
            onChange={(e) => setOpenPanels("main", e)}
            accordion
            ghost
            destroyInactivePanel
            defaultActiveKey={"1"}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
        >
            {renderDealerships()}
        </Collapse>
    </div>
}

CollapseSection.propTypes = {
    dealerships: PropTypes.array,
    departments: PropTypes.array,
    roles: PropTypes.array,
    openPanels: PropTypes.object,
    setOpenPanels: PropTypes.func,
}

export default memo(CollapseSection);