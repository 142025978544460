const controller = new AbortController();
const signal = controller.signal;

export default {
  getProfitAndLoss: async function (filters) {
    try {
      console.info(
        `Fetch profit and loss >> ${process.env.REACT_APP_APIURL}tassweb/profitandloss`
      );
      return fetch(`${process.env.REACT_APP_APIURL}tassweb/profitandloss`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });
    } catch (e) {
      console.log(e);
    }
  },
  getTwelveMonthData: async function (filters) {
    try {
      console.info(
        `Fetch overall year >> ${process.env.REACT_APP_APIURL}tassweb/twelvemonths`
      );
      return fetch(`${process.env.REACT_APP_APIURL}tassweb/twelvemonths`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });
    } catch (e) {
      console.log(e);
    }
  },
  getCurrentAndPrevious: async function (filters) {
    try {
      console.info(
        `Fetch current and previous >> ${process.env.REACT_APP_APIURL}tassweb/currentandprevious`
      );
      return fetch(
        `${process.env.REACT_APP_APIURL}tassweb/currentandprevious`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
        }
      );
    } catch (e) {
      console.log(e);
    }
  },
  getBalanceSheet: async function (filters) {
    try {
      console.info(
        `Fetch balance sheet >> ${process.env.REACT_APP_APIURL}tassweb/balancesheet`
      );
      return fetch(`${process.env.REACT_APP_APIURL}tassweb/balancesheet`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });
    } catch (e) {
      console.log(e);
    }
  },
  getOperatingExpenses: async function (filters) {
    try {
      console.info(
        `Fetch operating expenses >> ${process.env.REACT_APP_APIURL}tassweb/operatingexpenses`
      );
      return fetch(`${process.env.REACT_APP_APIURL}tassweb/operatingexpenses`, {
        method: "POST",
        signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });
    } catch (e) {
      console.log(e);
    }
  },
  cancelOperatingExpenses: async function (filters) {
    controller.abort();
  }
};
