import {
    FETCH_CONSUMER,
    FETCH_CONSUMER_LOADING,
    FETCH_CONSUMERS,
    FETCH_CONSUMERS_LOADING,
    SAVE_CONSUMER,
    SAVE_CONSUMER_LOADING,
    UPDATE_CONSUMER,
    UPDATE_CONSUMER_LOADING,
    REGEN_KEY,
    REGEN_KEY_LOADING,
    REGEN_KEY_RESET,
    LICENSE_MANAGER_PASSWORD_RESET_LOADING,
    LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING,
} from "../constant/actionTypes";
import request from "../utils/request";
import ApiServices from "../services/onboarding";

// Fetch
// handle fetch loading state for consumer fetch request
export const fetchingConsumer = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_CONSUMER_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the consumer fetch request
export const setConsumerFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: FETCH_CONSUMER,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle consumer fetch request
export const fetchConsumer = (token) => dispatch => {
    dispatch(fetchingConsumer());

    const feedReducer = (response) => {
        dispatch(setConsumerFetchStatus(response));
    }

    const requestOptions = {
        service: `consumer/${token}`,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};

// Fetch list
// handle fetch loading state for consumer fetch list request
export const fetchingConsumers = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_CONSUMERS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the consumer fetch list request
export const setConsumersFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: FETCH_CONSUMERS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle consumer fetch list request
export const fetchConsumers = (params = {}, partnerId) => dispatch => {
    dispatch(fetchingConsumers());

    const feedReducer = (response) => {
        dispatch(setConsumersFetchStatus(response));
    }

    let service = `consumer/all/${partnerId}`;
    const query =   new URLSearchParams(params.query).toString();
    if (query) {
        service += `?${query}`;
    }

    const requestOptions = {
        service,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};

// Save
// handle save loading state for consumer register request
export const savingConsumer = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_CONSUMER_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the consumer register request
export const setConsumerSaveStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: SAVE_CONSUMER,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle consumer register request
export const saveConsumer = (payload) => dispatch => {
    dispatch(savingConsumer());

    const feedReducer = (response) => {
        dispatch(setConsumerSaveStatus(response));
    }

    const requestOptions = {
        service: "consumer",
        method: "POST",
        data: payload.requestParams,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};

// update
// handle update loading state for consumer register request
export const updatingConsumer = (isLoading = true) => dispatch => {
    dispatch({
        type: UPDATE_CONSUMER_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the consumer register request
export const setConsumerUpdateStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: UPDATE_CONSUMER,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle consumer register request
export const updateConsumer = (payload) => dispatch => {
    dispatch(updatingConsumer());

    const feedReducer = (response) => {
        dispatch(setConsumerUpdateStatus(response));
    }

    const requestOptions = {
        service: `consumer/${payload.token}`,
        method: "PATCH",
        data: payload.requestParams,
        onSuccess: feedReducer,
        onError: feedReducer
    };

    request.make(requestOptions);
};

// delete
// handle delete loading state for consumer register request
export const deletingConsumer = (isLoading = true) => dispatch => {
    dispatch({
        type: UPDATE_CONSUMER_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the consumer delete request
export const setConsumerDeleteStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: UPDATE_CONSUMER,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle consumer delete request
export const deleteConsumer = (token, partnerId) => dispatch => {
    dispatch(deletingConsumer());

    const onError = (response) => {
        dispatch(setConsumerDeleteStatus(response));
    };

    const onSuccess = (response) => {
        dispatch(fetchConsumers({}, partnerId));
        dispatch(setConsumerDeleteStatus(response));
        console.log('deleted successfully')
    };

    const requestOptions = {
        service: `consumer/${token}`,
        method: "DELETE",
        onSuccess,
        onError,
    };

    request.make(requestOptions);
};

// key regenerate
// regenerate key loading
const regenKeyLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: REGEN_KEY_LOADING,
        payload: {
            isLoading
        }
    });
};

export const regenKeyReset = (response = {consumerKey: undefined, secretKey:undefined}) => dispatch => {
    dispatch({
        type: REGEN_KEY_RESET,
        payload: {
            response
        }
    });
};

// regenerate key process
const regenKeyStatus = data => dispatch => {
    const {isLoading, response} = data;
    const {consumer_key} = response.data;

    dispatch({
        type: REGEN_KEY,
        payload: {
            isLoading,
            response:{
                consumerKey:consumer_key || undefined,
            },
        }
    });
};

// handle regenerate key process
export const regenKey = (token, field, showErrNotification) => dispatch => {
    dispatch(regenKeyLoading());

    const onError = (response) => {
        showErrNotification("Regenerate Failed", response.error.toString())
        dispatch(regenKeyLoading(false));
    };

    const onSuccess = (response) => {
        dispatch(regenKeyStatus(response));
    };

    ApiServices.OnboardingConsumer.keyRegenerate(token, field, onSuccess, onError)
};

// send reset password link to email loading
export const sendResetPasswordEmailLoading = response => dispatch => {
    const {isLoading} = response;
    dispatch({
        type: LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING,
        payload: {
            isLoading,
        },
    });
};


// send reset password link to email
export const sendLicenseManagerPasswordResetEmail = (data, callback) => dispatch => {
    dispatch(sendResetPasswordEmailLoading({isLoading: true}));
    const feedReducer = (response) => {
        dispatch(sendResetPasswordEmailLoading(response));
        if(!response.error){
            callback();
        }
    };

    const requestOptions = {
        service:`/generateConsumerResetToken`,
        method: "POST",
        data,
        onSuccess: feedReducer,
        onError: feedReducer
    };
    request.make(requestOptions);
};

const licenseManagerResetPasswordDataLoading = (response) => dispatch => {
    const {isLoading} = response;
    dispatch({
        type: LICENSE_MANAGER_PASSWORD_RESET_LOADING,
        payload: {
            isLoading,
        },
    });
};

// license manager reset password
export const licenseManagerResetPassword = (data, callback) => dispatch => {
    dispatch(licenseManagerResetPasswordDataLoading({isLoading: true}));
    const feedReducer = (response) => {
        dispatch(licenseManagerResetPasswordDataLoading(response));
        if(!response.error){
            callback();
        }
    };
    const requestOptions = {
        service:`/licenseManagerSetPassword`,
        method: "POST",
        data,
        onSuccess: feedReducer,
        onError: feedReducer
    };
    request.make(requestOptions);
};