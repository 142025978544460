import React from "react";
import PropTypes from "prop-types";
import {Button, Row, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import AddUserModal from "./partials/addUser/AddUserModal";
import Loader from "../../../common/layout/Loader";

const DealershipTrainingUsers = ({
                                     columns,
                                     data,
                                     addUserConfig,
                                     showAddUser,
                                     addUserForm,
                                     isLoading,
                                     modalIsLoading,
                                     dealerships,
                                     roles
                                 }) => {

    return (
        <div>
            <Loader isLoading={isLoading}/>
            <div className="vw-dealership-train-main">
                <Table
                    bordered={true}
                    size="small"
                    columns={columns}
                    dataSource={data}
                    scroll={{x: "max-content"}}
                />
                <Row style={{marginTop:10}} display="flex" align="middle" justify="end">
                    <Button size="small" icon={<PlusOutlined/>} onClick={showAddUser}>Add New</Button>
                </Row>
            </div>
            <AddUserModal roles={roles} {...addUserConfig} form={addUserForm} isLoading={modalIsLoading} dealerships={dealerships}/>
        </div>
    )
}

DealershipTrainingUsers.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    addUserConfig: PropTypes.object,
    showAddUser: PropTypes.func,
    addUserForm: PropTypes.object,
    isLoading: PropTypes.bool,
    modalIsLoading: PropTypes.bool,
    dealerships: PropTypes.array,
    roles: PropTypes.object,
}

export default DealershipTrainingUsers;