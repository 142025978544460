import React, { memo } from "react";
import { Select } from "antd";

import Loader from "../layout/Loader";
import PropTypes from "prop-types";

const { Option } = Select;

const SelectFilter = ({
  title,
  items,
  itemsLoading,
  reportLoading,
  itemChange,
  selectedItem,
  wrapperId,
  ...rest
}) => {
  return (
    <div>
      {title?.length &&
        <h5>{title}</h5>
      }
      <Loader isLoading={itemsLoading} />
      {!itemsLoading &&
        <div id={wrapperId}>
          <Select
            data-testid={"select"}
            value={selectedItem}
            style={{ width: '100%' }}
            onChange={itemChange}
            disabled={!!reportLoading}
            {...rest}
            getPopupContainer={() => document.getElementById(wrapperId)}
          >
            {items && items.map(item => (
              <Option
                className={"select-option-dropdown"}
                data-testid={item._id}
                key={item._id}
                value={item._id}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      }
    </div >
  )
}

SelectFilter.defaultProps = {
  wrapperId: 'innovative-term-generic-select',
};

SelectFilter.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  itemsLoading: PropTypes.bool,
  reportLoading: PropTypes.bool,
  itemChange: PropTypes.func,
  selectedItem: PropTypes.string,
  wrapperId: PropTypes.string,
}

export default memo(SelectFilter);