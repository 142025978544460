import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Layout } from 'antd';
import { ImportOutlined, MenuOutlined } from '@ant-design/icons';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';

import { getDomainMetaData } from '../../../../selectors/tentacleMeta.selector';

import vttLogo from '../../../../assets/images/vtt-logo.png';
import VTTLogout from './logOutButton';

import '../../../../assets/css/vetTrack/vttDashboardManagement.css';
import '../dashboardManagement/main.css';

const { Header } = Layout;

const CommonHeader = ({ showVTTLogout }) => {
  const history = useHistory();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const domainData = useSelector(getDomainMetaData);
  const schoolLogo = _get(domainData, 'icon', '');

  const onLogoError = error => {
    error.target.src = vttLogo;
  };

  const returnToLogin = () => {
    history.push('/tenant/login');
  };

  return (
    <Header className="vtt-header withMenu" style={{ background: 'white' }}>
      <div className="mobileNav">
        <MenuOutlined
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        />
      </div>
      <div className="logo">
        <img onError={onLogoError} src={schoolLogo} alt="headerLogo" />
      </div>

      <div className='vtt-rto-return-home'>
        <Button icon={<ImportOutlined />} onClick={returnToLogin}>Login</Button>
        {showVTTLogout && <VTTLogout />}
      </div>
    </Header>
  );
};

CommonHeader.defaultProps = {
  showVTTLogout: false,
};

CommonHeader.propTypes = {
  showVTTLogout: PropTypes.bool,
};

export default CommonHeader;
