import React from 'react';
import { Modal, Button } from 'antd';

const ConfirmBox = ({message='Are You Sure?', topic="", confirmButtonText="Proceed", confirmButtonDanger="", onConfirm, show, onHide, ...props}) => {
    return (
        <Modal
            visible={show}
            title={topic}
            onOk={onConfirm}
            onCancel={onHide}
            footer={[
                <div>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                    <Button type="primary" onClick={onConfirm} danger={confirmButtonDanger}>
                        {confirmButtonText}
                    </Button>
                </div>,
            ]}
        >
            {message}
        </Modal>
    )
}

export default ConfirmBox;