import React from 'react';
import { Typography, Tooltip } from 'antd';
import {arrayToStringConvert} from "../../../../../utils/general";
import _startCase from "lodash/startCase";

const { Text } = Typography;

const SectionCellRenderer = ({sections}) => {

    const prepareSectionText = () => {
        if (sections){
            const formatData = sections.map(section => _startCase(section.text))
            if (sections.length > 0){
                return [arrayToStringConvert(formatData, ", "), true]
            }else {
                return ["NA", false]
            }
        }else {
            return ["NA", false]
        }

    }

    const renderContentComponent = () => {

        return (
            <div style={{margin:5}}>
                {sections.map((item) => {
                    const {text, state} = item;
                    return <p style={{color:"black"}}>{_startCase(text)}({_startCase(state)})</p>
                })}
            </div>
        )
    }

    const prepareSections = () => {
        const [sectionText, tooltipNeeded] = prepareSectionText();
        if(tooltipNeeded){
            return (
                <Tooltip trigger="click" color={"#fff"} title={renderContentComponent()}>
                    <Text style={{cursor:"pointer"}} strong>{sectionText}</Text>
                </Tooltip>
            )
        }else {
            return (
                <Text strong>{sectionText}</Text>
            )
        }
    }


    return (
        <div className="section-meta">
            {prepareSections()}
        </div>
    );
};

export default SectionCellRenderer;