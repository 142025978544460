import _get from "lodash/get";

const storage = window.sessionStorage;

const keys = {
  innovativeMeta: "innovative.meta",
  dashboardManageMeta: "dashboardManage.meta",
  impressionMeta: "impression.meta",
  userAccessMeta: "usr.acc.meta",
  vwDealershipMeta: "vw.dealership.meta",
}

const getStore = () => {
  return storage;
}

const set = (key, data) => {
  const encoded = JSON.stringify(data);
  getStore().setItem(key, encoded);
};

const remove = (key) => {
  getStore().removeItem(key);
};

const get = (key) => {
  if (Array.isArray(key)) {
    const first = key.shift();
    const data = getStore().getItem(first);
    const parsed = JSON.parse(data);
    const path = key.join('.');
    return _get(parsed, path, undefined);
  }

  if (key.length) {
    const data = getStore().getItem(key);
    return JSON.parse(data);
  }
  return {};
};

const setOrAppend = (key, data) => {
  const fetched = get(key);

  if (typeof fetched === "object") {
    return set(key, {
      ...fetched,
      ...data
    });
  }

  return set(key, data);
};

const getAuthToken = () => {
  const meta = get(keys.innovativeMeta);
  return ['Bearer', meta['t']].join(' ');
}

const getAuthTokenFromKey = (key) => {
  const meta = get(key);
  return ['Bearer', meta['t']].join(' ');
}

const session = {
  keys,
  get,
  getStore,
  getAuthToken,
  getAuthTokenFromKey,
  set,
  setOrAppend,
  remove,
};

export default session;