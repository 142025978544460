import {
  STUDENT_FETCH_STUDENT,
  STUDENT_FETCH_STUDENT_LOADING,
  STUDENT_FETCH_STUDENT_REPORT,
  STUDENT_FETCH_STUDENT_REPORT_LOADING,
  STUDENT_CLEAR_STUDENT_DATA,
  STUDENT_FETCH_DUE_ASSIGNMENTS,
  STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING,
  STUDENT_CLEAR_STUDENT_DETAILS,
  STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA,
  STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA,
  SET_STUDENT_COURSE_STATUS,
  RESET_STUDENT_COURSE_STATUS,
} from "../../constant/actionTypes";
import request from "../../utils/request";
import { getAccessToken } from "../../utils/general";
import _get from "lodash/get";

// clear last fetched chart data before new fetch
export const clearStudentReportChartData = () => dispatch => {
  dispatch({
    type: STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA,
  });
};

// set courseStatus filter values 
export const setCourseStatus = courseStatusList => dispatch => {
  dispatch({
    type: SET_STUDENT_COURSE_STATUS,
    payload: courseStatusList
  });
}

// reset courseStatus filter values 
export const resetCourseStatus = () => dispatch => {
  dispatch({
    type: RESET_STUDENT_COURSE_STATUS,
  });
}

/*** cohort and gauge data fetch ***/
// handle response of the student cohort fetch request
export const setStudentsChartDataFetchStatus = response => dispatch => {
  const data = _get(response, 'data.result.course_comparison')
  
  if (data) {
    // loop through the array of response to update the redux
    data.map(item => dispatch({
      type: STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA,
      payload: item
    }))
  }

};

// handle student cohort fetch request
export const fetchStudentsChartData = (data, signal) => dispatch => {

  const feedReducer = (response) => {
    dispatch(setStudentsChartDataFetchStatus(response));
  }

  return new Promise((resolve, reject) => {
    const requestOptions = {
      service: `insights/student_profile/report_details/cohorts/${getAccessToken()}`,
      method: "POST",
      authorized: true,
      signal,
      data,
      onSuccess: ({ response }) => {
        resolve(true);
        feedReducer(response);

      },
      onError: (response) => {
        reject(false);
      },
    };

    request.make(requestOptions);
  })
};

// clear last fetched data before new fetch
export const clearStudentReportData = () => dispatch => {
  dispatch({
    type: STUDENT_CLEAR_STUDENT_DATA,
  });
};

// clear last fetched data before new fetch
export const clearStudentDetails = () => dispatch => {
  dispatch({
    type: STUDENT_CLEAR_STUDENT_DETAILS,
  });
};

// handle fetch loading state for student report fetch request
export const fetchingStudentReport = (isLoading = true) => dispatch => {
  dispatch(clearStudentReportData())
  dispatch({
    type: STUDENT_FETCH_STUDENT_REPORT_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the student report fetch request
export const setStudentsReportFetchStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: STUDENT_FETCH_STUDENT_REPORT,
    payload: {
      isLoading,
      error,
      response,
    }
  });
};

// handle student report fetch request
export const fetchStudentsReport = (payload) => dispatch => {
  dispatch(fetchingStudentReport());

  const feedReducer = (response) => {
    dispatch(setStudentsReportFetchStatus(response));
  }

  const requestOptions = {
    service: `insights/student_profile/report_details/${getAccessToken()}`,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

// handle fetch loading state for due assignments fetch request
export const fetchingDueAssignmentsReport = (isLoading = true) => dispatch => {
  dispatch({
    type: STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the due assignments fetch request
export const setDueAssignmentsReportFetchStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: STUDENT_FETCH_DUE_ASSIGNMENTS,
    payload: {
      isLoading,
      error,
      response,
    }
  });
};

// handle due assignments fetch request
export const fetchDueAssignmentsReport = (payload) => dispatch => {
  dispatch(fetchingDueAssignmentsReport());

  const feedReducer = (response) => {
    dispatch(setDueAssignmentsReportFetchStatus(response));
  }


  const requestOptions = {
    service: `insights/student_profile/due_assignments/${getAccessToken()}`,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

// handle fetch loading state for student report fetch request
export const fetchingStudent = (isLoading = true) => dispatch => {
  dispatch(clearStudentDetails());
  dispatch({
    type: STUDENT_FETCH_STUDENT_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the student report fetch request
export const setStudentFetchStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: STUDENT_FETCH_STUDENT,
    payload: {
      isLoading,
      error,
      response,
    }
  });
};

// handle student report fetch request
export const fetchStudent = (payload) => dispatch => {
  dispatch(fetchingStudent());

  const feedReducer = (response) => {
    dispatch(setStudentFetchStatus(response));
  }

  const requestOptions = {
    service: `insights/student_profile/student_details/${getAccessToken()}`,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};