import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {Row, Empty, Col} from "antd";
import Modal from 'react-responsive-modal';
import seven from '../../assets/images/user-card/7.jpg';
import { Calendar, Users, MessageSquare, Navigation, Box, Layers, Server, Book, ArrowDown, ArrowUp } from 'react-feather';
import Breadcrumb from '../common/breadcrumb';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import one from '../../assets/images/user-card/1.jpg';
import three from '../../assets/images/user-card/3.jpg';
import anglicanimage from '../../assets/images/anglican.png';
import two from '../../assets/images/user-card/2.jpg';
import sixteen from '../../assets/images/avtar/16.jpg';
import eleven from '../../assets/images/avtar/11.jpg';
import four from '../../assets/images/avtar/4.png';
import learningDB from '../../data/learning/learningDB';
import prod1 from '../../assets/images/ecommerce/product-table-1.png';
import prod2 from '../../assets/images/ecommerce/product-table-2.png';
import prod3 from '../../assets/images/ecommerce/product-table-3.png';
import url from '../../assets/images/user/7.jpg';

import Loader from "../dashboardManagement/common/layout/Loader";
import dashboardConfig from "../../constant/dashboardManagement/dashboardConfig";

import {fetch2faCheck} from "../../actions/dashboardManagement/twoFaAuth.action";

import addHeatmap from 'highcharts/modules/heatmap'
import impressionHandler from "../../utils/impressionHandler/impressionHandler";
import session from "../../utils/session";
import {queryStringToObject} from "../../utils/general";
import WebSocketConnection from '../../utils/customServices/webSocketConnectino';
import {
  clearCourseData,
  clearCourseReport,
  clearTermsData,
  resetClusterSection,
} from '../../actions/innovative/course.action';

addHeatmap(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class DropdownComponent extends Component {
  constructor(props) {
    super(props);

    // add session data for impressions
    session.setOrAppend(session.keys.impressionMeta, queryStringToObject(this.props.location.search));
    session.setOrAppend(session.keys.innovativeMeta, queryStringToObject(this.props.location.search));

    var queryx = new URLSearchParams(this.props.location.search);
    this.state = {
      dropdownOpen: false,
      isOpen: false,
      url: "",
      isLoaded: true,
      items: [],
      isSubChartLoaded: false,
      subChartItem: [],
      isLateSubChartLoaded: false,
      lateSubmission: [],
      tempStudentName: "",
      studentName: "Student Name",
      avatar: four,
      workloadError:false,
      isFilterTermsLoaded: false,
      isFilterYearLevelsLoaded: false,
      isFilterStudentsLoaded: false,
      filterTermValue: 0,
      filterCourseValue: 0,
      filterStudentValue: 0,
      filterYearLevelValue: 0,
      filterTerms: [],
      filterYearLevels: [],
      filterStudents: [],
      filterCourses: [],
      isFeedbacksLoaded: false,
      feedbacks: [],
      isMarksByAssignmentsLoaded: false,
      marksByAssignments: [],
      isStudentNameLoaded: false,
      isWorkloadLoaded: false,
      workLoadItem: [],
      loader: 'Loading...',
      logged_in: queryx.get('token'),
      token: 'Bearer ' + queryx.get('t'),
      api_token:queryx.get('t'),
      client: queryx.get('school'),
      role: queryx.get('role'),
      isActivityByTimeLoaded: false,
      activityByTimeData: [],
      isFirstTimeLoad: true,
      SubmissionModelOpen: false,
      onCloseModal: false,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: [],
      isGoButtonDisabled: false,
      dashboards:[],
      isdashboardsLoaded:false,
      headers: { 'Content-Type': 'application/json' }
    };


  }


  getSharedDashboards() {


    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' },
      body: JSON.stringify({   user: this.state.logged_in, client: this.state.client, role: this.state.role})

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "tentacle/mydashboards/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isSubChartLoaded: true,
            dashboards: result.data,
            isdashboardsLoaded: true,
            // studentName:  result.data.response.body.userName,
            //  avatar: result.data.response.body.avatar
          });
        },

        (error) => {
          this.setState({
            isSubChartLoaded: true,
            error
          });
        }
      );
  }


  componentDidMount() {
    this.getSharedDashboards();
    const {resetCourseData} = this.props;
    resetCourseData();
    // remove admin session data if there is any
    session.remove(session.keys.dashboardManageMeta);
  }

  getFrontImage = (id) => {
    switch(id){
      case "STD" : return prod1; break;
      case "TED" : return prod2; break;
      case "PAD" : return prod3; break;
    }
    // data.name=="Student Dashboard New" ? prod1 : prod1
  }

  getDashboardData = (access_code) => {
    if(dashboardConfig[access_code]){
      return dashboardConfig[access_code]
    }
  }

  twoFactorAuth = (id, dashboard) => {
    const {history, check2fa} = this.props;
    check2fa(id).then(data => {
      const url = dashboard.url;
      if (data.isAuthorised){
        // since the DB contains the full URL, location.href needed.
        window.location.href = url
      }else{
        history.push(`${process.env.PUBLIC_URL}/dashboard/auth?email=${data.email}&returnUrl=${encodeURIComponent(url)}`)
      }
    }).catch(error => console.error(error));
  }

  onDashboardClick = (id) => {
    const { history } = this.props;
    const dashboard = this.state.dashboards.find(dashboard => dashboard.id === id);

    const path = new URL(dashboard.path);
    const navigateTo = path.pathname.concat(path.search);

    session.setOrAppend(session.keys.innovativeMeta, { access: dashboard.accessHash });

    setTimeout(() => {
      // send user access data for STATS
      impressionHandler.sendUserAccessDataAsync({
        dashboard: dashboard.access_code,
        accessCode: dashboard.accessHash
      }).then(() => (
        history.push(navigateTo)
      ))

      if (dashboard.otp_channel) {
        switch (dashboard.otp_channel) {
          case "email":
            this.twoFactorAuth(id, dashboard)
        }
      }
    }, 500);
  }

  renderEmpty = () => (
    <Row justify="center" align="middle" style={{ minHeight: '100vh', minWidth: '100vw' }}>
      <Col span={12}>
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              No Dashboards Available
            </span>
          }
        />
      </Col>
    </Row>
  )



  render() {
    return (
      <Fragment>
        <WebSocketConnection dashboardData={this.state.dashboards}/>
        <Loader isLoading={this.props.twoFaAuthCheck.isLoading}>
          <Row className="container-fluid" style={{ paddingTop: "50px" }}>
            {this.state.isdashboardsLoaded
              ? this.state.dashboards.length > 0 ? this.state.dashboards.map((data, i) => {
                const dashboardData = this.getDashboardData(data.access_code)
                return (
                  <div className="col-xl-3 col-lg-3  xl-50 col-sm-6 col-md-4">
                    <div className="card"><div className="blog-box blog-grid text-center product-box">
                      <div onClick={() => this.onDashboardClick(data.id)}>
                        <div className="product-img" style={{ maxHeight: 500, overflow: "hidden" }}>
                          <div className="mydashboard-image-container" >
                            <img className="img-fluid top-radius-blog" src={data.thumbnail} alt="dashboard-image" />
                          </div>
                          <div className="product-hover" style={{ padding: '15px' }}> </div>
                        </div>
                      </div>
                      <div className="blog-details-main">
                        <hr />
                        <h6 className="blog-bottom-details">{data?.dashboardName}</h6>
                      </div>
                    </div>
                    </div>
                  </div>
                )
              }
              )
                : this.renderEmpty()
              : <div style={{ display: 'flex', justifyContent: 'center', width: "100%", paddingTop: "50px" }} >
                <div style={{ display: "inline-block" }}>
                  <img src={preloaderGif} height="100px"></img>
                </div>
              </div>
            }
          </Row>
        </Loader>
      </Fragment>
    );
  }
}

const mapStateToProps = ({dashboardManage}) => {
  return {
    twoFaAuthCheck: dashboardManage.twoFaAuth.check,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    check2fa: (dashboardId) => dispatch(fetch2faCheck(dashboardId)),
    resetCourseData: () => {
      dispatch(resetClusterSection());
      dispatch(clearTermsData());
      dispatch(clearCourseData());
      dispatch(clearCourseReport());
    }
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DropdownComponent));