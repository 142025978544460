import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";

import CardElement from "./Card";

const CardList = ({items, onDataTableShowClick}) => {

    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 4,
            }}
            style={{ width: "100%" }}
            dataSource={items}
            renderItem={item => (
                <List.Item>
                    <CardElement item={item} onDataTableShowClick={onDataTableShowClick}/>
                </List.Item>
            )}
        />
    )
}

CardList.propTypes = {
    items: PropTypes.array,
    onDataTableShowClick: PropTypes.func,
}

export default CardList;