import React from "react";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {Button} from "antd";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import OutcomeGridView from "./OutcomeGrid";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import OutcomeCellGroup from "./partials/OutcomeCellGroup";

import {
    makeInnovativeStudentMainReportLoading,
    makeInnovativeStudentMainReportOutcomesResponseData
} from "../../../../selectors/innovative/student.selector";


const OutcomeGridContainer = () => {
    const {studentId, courseId, termId} = useParams();

    // selectors
    const courseOutcomes = useSelector(makeInnovativeStudentMainReportOutcomesResponseData);
    const courseReportLoading = useSelector(makeInnovativeStudentMainReportLoading);

    const assignOutcomeCellRenderer = (outcomes) => <OutcomeCellGroup outcomes={outcomes}/>

    const renderViewButtonCol = () => {
        return (
            {
                title: '',
                width: 100,
                fixed: 'right',
                render: (value, row) => <Button htmlType="button">
                    <Link to={`/insights/teacher/student-assignments/${studentId}/${row["course_id"]}/${termId}?current=outcomes`}>View</Link>
                </Button>,
            }
        )
    }

    // column definition for the table
    const getColumnDefinition = () => {
        return [
            {
                title: "Course",
                dataIndex: "course_name",
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["course_id"]}
                    avatar={row["course_image"]}
                />,
            }, {
                title: "Outcomes",
                dataIndex: "outcomes",
                 render: (value, row) => assignOutcomeCellRenderer(value)
            },
        ];
    }
    return (
        <OutcomeGridView
            isLoading={courseReportLoading}
            columns={getColumnDefinition().concat(renderViewButtonCol())}
            dataSource={courseOutcomes}
        />
    )
}
OutcomeGridContainer.propTypes = {
    setSearchFunc: PropTypes.func,
    courseId: PropTypes.string,
    selectedTerm: PropTypes.string,
};



export default OutcomeGridContainer;