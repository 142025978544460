import {
    FETCH_ONLINE_MODULES_DATA,
    FETCH_ONLINE_MODULES_DATA_LOADING,
    FETCH_ONLINE_STUDENTS_DATA,
    FETCH_ONLINE_STUDENTS_DATA_LOADING,
} from "../../constant/actionTypes";

import service from "../../utils/serviceHandlers/jobReady/customServices/vwDealership";

/** Modules data fetch **/
// fetch modules data loading
const fetchingModulesDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_ONLINE_MODULES_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch modules data handler
const fetchModulesDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_ONLINE_MODULES_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch modules data
export const fetchingModulesData = (data) => dispatch => {
    dispatch(fetchingModulesDataLoading());

    service.make({
        service:`insights/jrp/vga/mandatory/applyFilters`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchModulesDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchModulesDataStatus({
            error: error.message
        }))
    })
};

/** Students data fetch **/
// fetch students data loading
const fetchingStudentsDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_ONLINE_STUDENTS_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch students data handler
const fetchStudentsDataStatus = (response, selected) => dispatch => {
    dispatch({
        type: FETCH_ONLINE_STUDENTS_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data}),
            moduleSelected: selected
        },
    });
};

// fetch students data
export const fetchStudentsData = (data) => dispatch => {
    dispatch(fetchingStudentsDataLoading());

    service.make({
        service:`insights/jrp/vga/mandatory/listStudents`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchStudentsDataStatus({
            data: response.data
        }, data))
    }).catch(error => {
        dispatch(fetchStudentsDataStatus({
            error: error.message
        }, data))
    })
};

