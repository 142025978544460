import {
    JR_GEN_FETCH_USER_PERMISSIONS,
    JR_GEN_FETCH_USER_PERMISSIONS_LOADING,
    JR_GEN_SETTINGS_FETCH,
    JR_GEN_SETTINGS_FETCH_LOADING,
    JR_GEN_SETTINGS_ALTERNATION,
    JR_GEN_SETTINGS_SAVE,
    JR_GEN_SETTINGS_SAVE_LOADING,
    JR_GEN_SETTINGS_RESET_EDIT_MODE,
    JR_GEN_SETTINGS_DISCARD_DRAFT,
    JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING
} from "../../../constant/actionTypes";
import service from "../../../utils/serviceHandlers/jobReady/customServices/jrGeneric";
import {onSuccess} from "../../../utils/notificationHandler";

/** Fetch user permissions **/
// fetch user permission loading
const fetchUserPermissionLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_FETCH_USER_PERMISSIONS_LOADING,
        payload: {isLoading},
    });
};


// fetch user permission handler
const fetchUserPermissionDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_FETCH_USER_PERMISSIONS,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch user permission
export const fetchUserPermission = (data) => dispatch => {
    dispatch(fetchUserPermissionLoading());

    service.make({
        service:`insights/jrp/generic/settings/fetchSettingPermissions`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchUserPermissionDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchUserPermissionDataStatus({
            error: error.message
        }))
    })
};


/** Fetch Settings - this is a global setting fetch request handler **/
// fetch settings loading
const fetchSettingsLoading = (isLoading = true, section, subSection) => dispatch => {
    dispatch({
        section,
        path: `${subSection ? `${subSection}.`: ""}selected`,
        type: JR_GEN_SETTINGS_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch settings handler
const fetchSettingsDataStatus = (response, section, subSection) => dispatch => {
    dispatch({
        section,
        type: JR_GEN_SETTINGS_FETCH,
        path: `${subSection ? `${subSection}.`: ""}data`,
        payload: response.data,
    });
};

// fetch settings
export const fetchSettings = (data, section, subSection=null, subSectionId=null) => dispatch => {
    dispatch(fetchSettingsLoading(true, section, subSection));

    service.make({
        service:`insights/jrp/generic/settings/fetch`,
        method: "POST",
        data: {
            data,
            section,
            subSection,
            subSectionId:subSectionId,
        }
    }).then(response => {
        dispatch(fetchSettingsLoading(false, section, subSection));
        dispatch(fetchSettingsDataStatus({
                data: response.data,
            },
            section,
            subSection
        ))
    }).finally(() => {
        dispatch(fetchSettingsLoading(false, section, subSection));
    })
};

/** Setting Alternation global handler **/
// settings alternations
export const setSettings = (data, path, section, subSection=null) => dispatch => {
    dispatch({
        section,
        subSection,
        path: `${subSection ? `${subSection}.`: ""}${path}`,
        type: JR_GEN_SETTINGS_ALTERNATION,
        payload: {data},
    });
};

/** Save Settings - this is a global setting save request handler **/
// save settings loading
const saveSettingsLoading = (isLoading = true, section, subSection) => dispatch => {
    dispatch({
        section,
        path: `${subSection ? `${subSection}.`: ""}saved`,
        type: JR_GEN_SETTINGS_SAVE_LOADING,
        payload: {isLoading},
    });
};


// // save settings handler
// const saveSettingsDataStatus = (response, section, subSection) => dispatch => {
//     dispatch({
//         section,
//         type: JR_GEN_SETTINGS_FETCH,
//         path: `${subSection ? `${subSection}.`: ""}data`,
//         payload: response.data,
//     });
// };

// save settings
export const saveSettings = (data, mode, section, subSection=null, subSectionId=null, reFetchSection=true, callback=null) => dispatch => {
    dispatch(saveSettingsLoading(true, section, subSection));

    service.make({
        service:`insights/jrp/generic/settings/save`,
        method: "POST",
        data: {
            mode,
            data,
            section,
            subSection,
            subSectionId:subSectionId,
        }
    }).then((res) => {
        dispatch(saveSettingsLoading(false, section, subSection));
        onSuccess("Settings Applied Successfully!")
        dispatch(resetEditMode());
        reFetchSection && dispatch(fetchSettings({}, section, subSection, subSectionId));
        callback && callback();
    }).finally(() => {
        dispatch(saveSettingsLoading(false, section, subSection));
    })
};

/** Reset Edit mode **/
// reset edit mode
export const resetEditMode = () => dispatch => {
    dispatch({
        type: JR_GEN_SETTINGS_RESET_EDIT_MODE,
    });
};

/** Discard draft **/
// discard draft loading
const discardDraftLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING,
        payload: {isLoading},
    });
};


// discard draft handler
const discardDraftDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_SETTINGS_DISCARD_DRAFT,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// discard draft
export const discardDraft = (section, subSection, subSectionId) => dispatch => {
    dispatch(discardDraftLoading());

    service.make({
        service:`insights/jrp/generic/settings/discardDraft`,
        method: "POST",
        data: {
            section,
            subSection,
            subSectionId:subSectionId,
        }
    }).then(response => {
        dispatch(discardDraftDataStatus({
            data: response.data
        }))
        onSuccess("Draft Discarded Successfully!")
        dispatch(fetchSettings({}, section, subSection, subSectionId));
    }).catch(error => {
        dispatch(discardDraftDataStatus({
            error: error.message
        }))
    })
};
