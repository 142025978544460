export const lineChartConf = {
    title: {
        text: ""
    },
    legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
    },

    yAxis: {
      title: {
          text: ""
      }
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            shadow: true
        }
    },
}