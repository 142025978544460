import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Typography} from "antd";
import _startCase from "lodash/startCase";

import {getRiSettingsByPath} from "../../../../../../selectors/jobReadyGeneric/settings/riskIndicators.selector";
import {getPermissionDataByPath} from "../../../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";
import {setSettings} from "../../../../../../actions/jobReadyGeneric/settings/commonSettings.action";

import MeasurementIndicatorsView from "./MeasurmentIndicatorsView";

import {settingsSections} from "../../../../../../constant/jobReadyGeneric/constants";


const MeasurementIndicatorsContainer = ({subSection}) => {
    const dispatch = useDispatch();

    // selectors
    const factors = useSelector(getRiSettingsByPath(`${subSection}.data.factors`));
    const overallData = useSelector(getRiSettingsByPath(`${subSection}.data.overall`))
    const hasPermission = useSelector(getPermissionDataByPath(`${subSection}.saveColors`));

    const onValueChange = (factorKey, type, key, value) => {
        dispatch(setSettings(value, `data.factors.${factorKey}.${type}.${key}`, settingsSections.riskIndicators, subSection))
    }

    return (
        <div className="customAcordian">
            <Typography.Title level={5}>{_startCase(subSection)} Risk Measurement Indicators</Typography.Title>
            <MeasurementIndicatorsView
                onValueChange={onValueChange}
                factors={factors || []}
                overallData={overallData}
                subSection={subSection}
                hasPermission={hasPermission}
            />
        </div>
    )
}

MeasurementIndicatorsContainer.propTypes = {
    subSection: PropTypes.string
}

export default MeasurementIndicatorsContainer;