import React from "react";
import AccClassProfile from "./AccClassProfile";
import { withRouter } from 'react-router-dom'

const ClassProfile = (props) => {
    const params = new URLSearchParams(props.location.search);
    if(params.get('profile') == 'acc'){
        return (
            <AccClassProfile location={props.location}/>
        );
    }else{
    }
};

export default withRouter(ClassProfile);