import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DeleteOutlined, EditOutlined, SendOutlined} from "@ant-design/icons";
import {Space, Form, Popconfirm} from "antd";
import _startCase from "lodash/startCase";

import "../../../../../assets/css/jrDashboardManagement/vwDealershipUser.css";

import {
    makeVwDealershipMainDataResponse,
    makeVwDealershipSelectedDataLoading,
    makeVwDealershipSelectedDataResponseData,
    makeVwDealershipAddedDataLoading,
    makeVwDealershipDeletedDataLoading,
    makeVwDealershipUpdatedDataLoading,
    makeVwDealershipMainDataUsersResponseData,
    makeVwDealershipResDataDealershipsLoading,
    makeVwDealershipResDataDealershipsResponseData
} from "../../../../../selectors/jrDashboardManagement/userManagement/vwDealershipUserManagement.selector";
import {
    fetchUsersData,
    fetchUserData,
    saveUserData,
    deleteUserData,
    updateUserData,
    fetchDealershipsData,
    sendResetPasswordLink
} from "../../../../../actions/jrDashboardManagement/userManagement/vwUserDealership.action";

import DealershipTrainingUsers from "./DealershipTrainingUsers";

const ROLES = {
    manager: {key:"manager", name:"Manager"},
    dealershipManager: {key:"dealership_manager", name:"Dealerships Manager"}
}

const DealershipTrainingUsersContainer = () => {
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [modalState, setModalState] = useState(null);
    const [addUserForm] = Form.useForm();
    const dispatch = useDispatch();

    // selectors
    const mainResponse = useSelector(makeVwDealershipMainDataResponse);
    const users = useSelector(makeVwDealershipMainDataUsersResponseData);
    const selectedUserIsLoading = useSelector(makeVwDealershipSelectedDataLoading);
    const selectedUserData = useSelector(makeVwDealershipSelectedDataResponseData);
    const addUserLoading = useSelector(makeVwDealershipAddedDataLoading);
    const deleteUserLoading = useSelector(makeVwDealershipDeletedDataLoading);
    const updateUserLoading = useSelector(makeVwDealershipUpdatedDataLoading);
    const dealershipsLoading = useSelector(makeVwDealershipResDataDealershipsLoading);
    const dealershipsList = useSelector(makeVwDealershipResDataDealershipsResponseData);

    useEffect(() => {
        dispatch(fetchUsersData());
    }, [])

    useEffect(() => {
        if(selectedUserData){
            // set data for update user
            addUserForm.setFieldsValue({...selectedUserData, dealerships: selectedUserData.dealership?.map(d => d.id)})
        }
    }, [selectedUserData])

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (value) => _startCase(value)
        },
        {
            title: 'Dealership(s)',
            dataIndex: 'dealership',
            key: 'dealership',
            render: (value, record) => {
                const formatted = value.filter(val => val.name).map(val => val.name);
                return <div title={formatted.join("\n")} className="jr-vw-user-dealerships-cell">
                    {record.role === ROLES.manager.key? "All" :formatted.join(", ")}
                </div>
            }
        },
        {
            title: '',
            key: 'action',
            width:100,
            align: "center",
            render: (value, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Send password reset link?"
                        onConfirm={() => onSendConfirm({email: record.email})}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a title="Send Reset Password Link"><SendOutlined/></a>
                    </Popconfirm>
                    <Popconfirm
                        title="Are you sure to delete this user?"
                        onConfirm={() => onDeleteConfirm({email: record.email})}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a title="Delete User"><DeleteOutlined/></a>
                    </Popconfirm>
                    <a title="Edit User" onClick={() => showEditUser({email: record.email})}><EditOutlined/></a>
                </Space>
            ),
        },
    ];

    const onSendConfirm = (data) => {
        dispatch(sendResetPasswordLink(data));
    }

    const onDeleteConfirm = (data) => {
        dispatch(deleteUserData(data))
    }

    const showEditUser = (data) => {
        dispatch(fetchUserData(data));
        dispatch(fetchDealershipsData());
        setAddUserVisible(true);
        setModalState("update");
    }

    const showAddUser = () => {
        addUserForm.resetFields();
        dispatch(fetchDealershipsData());
        setAddUserVisible(true);
        setModalState("add");
    }

    const postProcessUsers = () => {
        setAddUserVisible(false);
        addUserForm.resetFields();
        dispatch(fetchUsersData());
    }

    const onAddUserSubmit = () => {
        const {dealerships, ...rest} = addUserForm.getFieldsValue();
        const filteredDealerships = dealerships.filter(item => item !== "all");
        const requestParams = {
            ...rest,
             dealerships:dealershipsList.filter(dealership => filteredDealerships.includes(dealership.id))
        }
        // dispatch relevant function
        switch (modalState) {
            case "update":
                return dispatch(updateUserData({...requestParams, newEmail: requestParams.email, email: selectedUserData.email}, postProcessUsers));
            case "add":
                return dispatch(saveUserData(requestParams, postProcessUsers));
        }
    }

    const onAddUserCancel = () => {
        setAddUserVisible(false);
        addUserForm.resetFields();
    }


    return (
        <DealershipTrainingUsers
            isLoading={mainResponse.isLoading || deleteUserLoading }
            columns={columns}
            data={users}
            showAddUser={showAddUser}
            addUserConfig={{
                isModalVisible: addUserVisible,
                handleOk: onAddUserSubmit,
                handleCancel: onAddUserCancel,
                modalState:modalState
            }}
            addUserForm={addUserForm}
            modalIsLoading={selectedUserIsLoading || dealershipsLoading || updateUserLoading || addUserLoading}
            dealerships={dealershipsList}
            roles={ROLES}
        />
    )
}

export default DealershipTrainingUsersContainer;