import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";
import {fetchDueAssignmentsReport} from "../../../../actions/innovative_student/student.action";
import {
    makeInnovativeStudentDueAssignmentsLoading,
    makeInnovativeStudentDueAssignmentsResponseData
} from "../../../../selectors/innovative_student/student.selector";
import MonthView from "./partials/MonthView";
import DayView from "./partials/DayView";
import 'react-day-picker/lib/style.css';

const Calendar = ({studentId}) => {
    const dispatch = useDispatch();

    // selectors
    const dueAssignments = useSelector(makeInnovativeStudentDueAssignmentsResponseData);
    const dueAssignmentsLoading = useSelector(makeInnovativeStudentDueAssignmentsLoading)

    // state
    const [view, setView] = useState('month');
    const [selectedDate, setSelectedDate] = useState(null);

    const legend = [
        {label: 1, value: 1, color: "#587ef7"},
        {label: 2, value: 2, color: "#7dd353"},
        {label: 3, value: 3, color: "#faae31"},
        {label: "4=<", value: 4, color: "#f3452e"},
    ]

    const onDayClick = (day) => {
        const key = new Date(Date.parse(day)).toISOString().slice(0, 10);
        if (dueAssignments[key]) {
            setSelectedDate(key);
            setView('day');
        }
    };

    const onMonthChange = (date) => {
        const payload = {
            requestParams: getRequestParams({date}),
        }
        dispatch(fetchDueAssignmentsReport(payload));
    };

    const resolveLegend = count => {
        const copied = [...legend];
        return copied
            .reverse()
            .find(item => item.value <= count);
    };

    const getAssignmentsOfTheDay = () => {
        return _get(dueAssignments, selectedDate, []);
    };

    const getModifiers = useCallback(() => {
        let modifiers = {};
        let modifiersStyles = {};
        const keys = Object.keys(dueAssignments);

        keys.forEach(key => {
            const firstDate = _get(dueAssignments, `${key}[0].due_at`, undefined);
            if (firstDate) {
                const selected = resolveLegend(dueAssignments[key].length);
                modifiers[key] = new Date(Date.parse(firstDate));
                modifiersStyles[key] = {
                    backgroundColor: selected.color,
                    border: '1px solid #fff',
                    color: '#fff'
                }
            }
        })

        return {modifiers, modifiersStyles};
    }, [dueAssignments]);

    const getDateTitle = () => {
        return [
            {day: 'numeric'},
            {month: 'long'},
            {year: 'numeric'},
        ].map(segment => new Intl.DateTimeFormat('en', segment)
            .format(new Date(Date.parse(selectedDate.toString()))))
            .join(" ");
    }

    const getRequestParams = (params = {}) => {
        const date = params.date ? params.date : new Date();

        return {
            student_id: studentId,
            "year": date.getFullYear(),
            "month": ('0' + (date.getMonth() + 1)).slice(-2),
        };
    };

    useEffect(() => {
        const payload = {
            requestParams: getRequestParams(),
        }
        if(studentId){
            setTimeout(() =>  dispatch(fetchDueAssignmentsReport(payload)), 1000)
        }
    }, [studentId]);

    const dayPickerParams = getModifiers();

    const renderContent = () => {
        if (view === "month") {
            return <MonthView
                dueAssignmentsLoading={dueAssignmentsLoading}
                onDayClick={onDayClick}
                onMonthChange={onMonthChange}
                legend={legend}
                dayPickerParams={dayPickerParams}
            />
        }

        return <DayView
            title={getDateTitle()}
            dueAssignmentsLoading={dueAssignmentsLoading}
            assignmentsOfTheDay={getAssignmentsOfTheDay()}
            onViewChange={setView}
        />
    }

    return <>
        {renderContent()}
    </>
};

Calendar.propTypes = {
    studentId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
}

export default Calendar;