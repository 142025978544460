import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { externalUserResetPassword, validateResetToken } from "../../../../actions/authentication.action";
import { getExternalUserResetPasswordLoading } from "../../../../selectors/authentication.selector";

import ResetPasswordView from "./ResetPasswordView";
import { onSuccess } from "../../../../utils/notificationHandler";
import { queryStringToObject } from "../../../../utils/general";
import { resetUserPassword } from "../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action";

const ResetPasswordContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const search = queryStringToObject(useLocation().search);
    const [resetPasswordForm] = Form.useForm();
    const [isVettrackPlatform, setIsVettrackPlatform] = useState(false);
    const [authorized, setIsAuthorized] = useState(false);
    const stringQ = window.location.search;
    const params = new URLSearchParams(stringQ);

    const userInfo = (user) => {
        if (user.error === false) {
            setIsAuthorized(true);
        }
        if (user.data.platform.name === "vettrak") {
            setIsVettrackPlatform(true);
        }
    }

    useEffect(() => {
        dispatch(validateResetToken({ resetToken: params.get("t"), userId: params.get("uid") }, userInfo));
    }, []);

    // selectors
    const isLoading = useSelector(getExternalUserResetPasswordLoading);

    const onsubmit = (values) => {
        const userObj = { 
            userId: search.uid || null,
            token: search.t || null,
            ...values,
        };

        if(isVettrackPlatform) { 
            dispatch(resetUserPassword(userObj, () => {
                onSuccess("Password has been reset successfully!");
                history.push("/tenant/login");
            })); 
            return;
        }
        
        dispatch(externalUserResetPassword(userObj, () => {
            onSuccess("Password has been reset successfully!");
            history.push("/tenant/login");
        }));
    }
    return (<>
        {
            authorized && <ResetPasswordView
                isLoading={isLoading}
                onsubmit={onsubmit}
                form={resetPasswordForm}
            />
        }
    </>
    )
}

export default ResetPasswordContainer;
