import React from "react";
import PropTypes from "prop-types";
import {Popover} from "antd";
import {CheckSquareTwoTone, RightOutlined} from "@ant-design/icons";

const OutcomeCellCard = ({outcome}) => {
    const {title, proficiencyRating, proficiencyRatingColor, description, mark, mastered} = outcome;
    const renderOutcomeDescription = () => {
        return (
            <div dangerouslySetInnerHTML={{ __html: description}}/>
        )
    }

    const renderPopoverTitle = () => <div style={{fontWeight:"bold"}}>{title}</div>

    return (
        <div className="outcome-cell-main">
            <div className="outcomeCell">
                <div className="title" style={{textAlign:"center", display:"inline-block", backgroundColor:proficiencyRatingColor}}>
                    {proficiencyRating}
                </div>
                <div className="grade" style={{float:"right", display:"flex"}}>
                    {mark}
                    {mastered &&
                    <div style={{marginLeft: 10, display: "inline-block", verticalAlign: "middle"}}>
                        <CheckSquareTwoTone style={{fontSize: '16px'}} twoToneColor="#52c41a"/>
                    </div>
                    }
                </div>
            </div>
            <div>
                <Popover content={renderOutcomeDescription()} title={renderPopoverTitle} trigger="click">
                    <div className="outcome-name" >
                        <div title={title}>
                            <div className="outcome-title">{title}</div> &nbsp;
                            <RightOutlined />
                        </div>
                    </div>
                </Popover>
            </div>
        </div>
    )
}

OutcomeCellCard.propTypes = {
    outcome: PropTypes.object,
}

export default OutcomeCellCard;