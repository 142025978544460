import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Card} from 'antd';
import { get as _get } from 'lodash';
import styled from "styled-components";
import {LockFilled} from "@ant-design/icons";

import Loader from "../../../dashboardCommon/Loader";

import {
    getDomainMetaData
} from "../../../../selectors/tentacleMeta.selector";
import {fetchDomainData} from "../../../../actions/tentacleMeta.action";

import "../../../../assets/css/auth/externalAuth.css";
import { domainCode as vttCode  } from "../../../../constant/vetTrack/analysis";
import vttLogo from "../../../../assets/images/vtt-logo.png"

const logo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/octo-logo-transparent.png`;


const ExternalLoginLayout = ({children, actions, isLoading, title}) => {
    const dispatch = useDispatch();

    // selectors
    const domainData = useSelector(getDomainMetaData);

    // Styled component named StyledCover
    const StyledCover = styled.div`
   &:before {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    content: "";
    position: fixed;
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
    background: url(${domainData && domainData.bg_image});
    background-size: cover;
    z-index: 1;
}
  }

`;

    useEffect(() => {
        dispatch(fetchDomainData(window.location.hostname));
    }, [])

    const domainCode = _get(domainData, 'code', '');
    const domainIcon = _get(domainData, 'icon', '');
    const vttClass = domainCode === vttCode ? 'vtt-login-form' : '';

    const handleImage = () => {
        if (!domainIcon && domainCode !== vttCode) {
            return <LockFilled style={{fontSize:50}}/>;
        }
        return <img src={domainIcon} alt="Domain Icon" onError={imageError} />;
    };

    const imageError = error => {
        if (domainCode !== vttCode) return;

        error.target.src = vttLogo;
    };

    return (
        <div className={`extLoginMain ${vttClass}`}>
            <StyledCover className="ext-auth-container extLoginCover">
                <Loader isLoading={isLoading}/>
                <div className="ext-auth-main">
                    <Card
                        className="ext-auth-card"
                        title={
                            <div className="customLoginHEading">
                                <div className="wideLogo">
                                    {handleImage()}
                                </div>
                                <span>{title}</span>
                            </div>
                        }
                        actions={actions}
                    >
                        <div>
                            {children}
                        </div>
                    </Card>
                    <div className="powered-by">
                    <span>Powered By:
                        <Link to={{pathname: "https://octopusbi.com"}} replace={true} target="_blank">
                            <img style={{height:24, marginLeft:5}} src={logo}/>
                        </Link>
                    </span>
                    </div>
                </div>

            </StyledCover>
        </div>
    );
};

ExternalLoginLayout.defaultProps = {
    isLoading: false
}

ExternalLoginLayout.propTypes = {
    isLoading: PropTypes.bool,
    actions: PropTypes.array,
    domainData: PropTypes.object,
}

export default ExternalLoginLayout;
