import React from "react";
import {Row, Tabs} from "antd";
import PropTypes from "prop-types";

import CustomerScale from "../customerScale/customerScale";
import Users from "../tabs/Users";
import View from "../tabs/Views";
import DashboardAdoption from "../tabs/DashboardAdoption";
import CustomerSuccess from "../tabs/CustomerSuccess";
import Product from "../tabs/Product";

const { TabPane } = Tabs;

const TabView = ({data,displayRows, filterData}) => {

    return (
        <div>
            <Row>
                <Tabs defaultActiveKey="view" style={{width: "100%"}}>
                    <TabPane tab="Views" key="view">
                       <View paginationDisplayRows={displayRows} data={data} filterData={filterData}/>
                    </TabPane>
                    {/*<TabPane tab="Users" key="users">*/}
                    {/*    <Users paginationDisplayRows={displayRows}/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Dashboard Adoption" key="dashboardAdoption">*/}
                    {/*    <DashboardAdoption paginationDisplayRows={displayRows}/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Customer Success" key="customerSuccess">*/}
                    {/*    <CustomerSuccess paginationDisplayRows={displayRows}/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Product" key="product">*/}
                    {/*    <Product paginationDisplayRows={displayRows}/>*/}
                    {/*</TabPane>*/}
                </Tabs>
            </Row>
        </div>
    )
}

TabView.propTypes = {
    displayRows: PropTypes.string,
    data: PropTypes.array,
    filterData: PropTypes.object,
}

export default TabView;