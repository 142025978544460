import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import {
  makeInnovativeStudentSearchLoading,
  makeInnovativeStudentSearchResponseData
} from '../../../../selectors/innovative/student.selector';
import { searchStudent } from '../../../../actions/innovative/student.action';
import { debounce as _debounce } from 'lodash';
import { getSelectedAccount } from '../../../../selectors/innovative/course.selector';

const MIN_SEARCH_TEXT_LENGTH = 3;

const StudentSearch = ({
  setSelectedStudent,
  selectedStudent,
  student,
}) => {
  const dispatch = useDispatch();
  const studentList = useSelector(makeInnovativeStudentSearchResponseData);
  const isSearchLoading = useSelector(makeInnovativeStudentSearchLoading);
  const [searchedStudent, setSearchedStudent] = useState([]);
  const [renderStudentList, setStudentList] = useState([]);
  const selectedAccount = useSelector(getSelectedAccount);

  useEffect(() => {
    if (Object.keys(student).length) {
      setSearchedStudent([student.student_name]);
    }
  }, []);

  useEffect(() => {
    searchedStudent.length && setSearchedStudent(searchedStudent);
  }, [selectedStudent]);

  useEffect(() => {
    setStudentList(studentList);
  }, [JSON.stringify(studentList)]);

  const handleSearch = useCallback(
    _debounce((value, selectedAccount) => {
      if (value.length < MIN_SEARCH_TEXT_LENGTH) {
        setStudentList([]);
      }
      if (value && value.length >= MIN_SEARCH_TEXT_LENGTH) {
        return dispatch(searchStudent({ 
          search_term: value,
          account_id: selectedAccount,
         }));
      }
      return null;
    }, 500), [],
  );

  const onSearch = value => {
    handleSearch(value, selectedAccount);
  }

  const handleStudentSelection = (value, options) => {
    setSelectedStudent(value);
    setSearchedStudent([options.label]);
    setStudentList([]);
  };

  const renderSearchResultArray = () => {
    return renderStudentList.map(student => ({
      value: student.student_id,
      label: student.student_name,
    }))
  }

  return (
    <Select
      showSearch
      value={searchedStudent}
      loading={isSearchLoading}
      placeholder={"Search student"}
      onChange={handleStudentSelection}
      onSearch={onSearch}
      options={renderSearchResultArray()}
      notFoundContent={null}
      defaultActiveFirstOption={false}
      filterOption={false}
    />
  );
};

export default StudentSearch;
