import React from "react";
import {Select} from "antd";
import PropTypes from "prop-types";

const {Option} = Select;

const Filter = ({dataset, onChange, selected, width}) => {
    return (
        <Select
            showSearch
            showArrow
            maxTagCount={2}
            listHeight={500}
            style={{ width: width }}
            optionFilterProp="children"
            onChange={onChange}
            value={selected}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {dataset && dataset.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
        </Select>
    )
}

Filter.defaultProps = {
    width: "100%"
}

Filter.propTypes = {
    dataset: PropTypes.array,
    onChange: PropTypes.func,
    selected: PropTypes.string,
    width: PropTypes.string,
}

export default Filter;