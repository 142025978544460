import React, {useRef} from "react";
import {Form, Input, Tooltip} from "antd";
import PropTypes from "prop-types";
import {CopyOutlined} from "@ant-design/icons";

const ConfigCanvasForm = ({form}) => {
    const consumerKeyRef = useRef(null);
    const secretKeyRef = useRef(null);

    const copyToClipboard = (reference) => {
        reference.current.select();
        document.execCommand("copy");
    }

    const renderCopyIndicator = (reference, title) => {
        return (
            <Tooltip title={`copy ${title}`}>
                <CopyOutlined onClick={() => copyToClipboard(reference)}/>
            </Tooltip>
        );
    };


    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item label="Consumer Key" name="consumerKey">
                <Input
                    ref={consumerKeyRef}
                    suffix={renderCopyIndicator(consumerKeyRef, "Consumer Key")}
                />
            </Form.Item>

            <Form.Item label="Secret Key" name="secretKey">
                <Input
                    ref={secretKeyRef}
                    suffix={renderCopyIndicator(secretKeyRef, "Secret Key")}
                />
            </Form.Item>
        </Form>
    )
}

ConfigCanvasForm.propTypes = {
    form: PropTypes.object
}

export default ConfigCanvasForm;