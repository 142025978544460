import {combineReducers} from "redux";

import common from "./common.reducer";
import overview from "./overview.reducer";
import academic from "./academic.reducer";
import financial from "./financial.reducer";
import compliance from "./compliance.reducer";

const jrGenericRiskAnalysisReducer = combineReducers({
    common,
    overview,
    academic,
    financial,
    compliance,
});

export default jrGenericRiskAnalysisReducer;