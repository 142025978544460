import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import MeasurementIndicatorsContainer from './measurementIndicators';
import OverallIndicatorsContainer from './overallIndicators';

const RITabSection = ({ subSection }) => (
  <Row gutter={[24, 16]}>
    <Col xs={24} sm={24} md={24} lg={12}>
      <MeasurementIndicatorsContainer subSection={subSection} />
    </Col>
    <Col xs={24} sm={24} md={24} lg={12}>
      <OverallIndicatorsContainer subSection={subSection} />
    </Col>
  </Row>
);

RITabSection.propTypes = {
  subSection: PropTypes.string,
};

export default RITabSection;
