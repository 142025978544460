import React, { Suspense } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { makeInnovativeSettingByPath } from "../../../../selectors/innovative/setting.selector";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";

const MarkingScheme = React.lazy(() => import("../../common/settingsComponents/markingScheme"))

const MarkingSchemeSettingsContainer = ({
  onSubmit,
  onResetDefault,
  onSectionSelect,
}) => {
  const [form] = Form.useForm();
  const pageSettingsPath = "markingScheme";

  // selectors
  const pageSettings = useSelector(
    makeInnovativeSettingByPath(pageSettingsPath)
  );
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <MarkingScheme pageSettings={pageSettings} onResetDefault={onResetDefault} onSectionSelect={onSectionSelect} onSubmit={onSubmit} pageSettingsPath={pageSettingsPath} />
    </Suspense>
  );
};

MarkingSchemeSettingsContainer.propTypes = {
  onSubmit: PropTypes.func,
};

export default MarkingSchemeSettingsContainer;
