import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setMenuSelected, setBreadcrumb } from '../../../actions/dashboardManagement/layout.action';
import { makeDashboardSettingByPath, makeDashboardSettingsLoading, makeDashboardSettingsSaveLoading } from '../../../selectors/dashboardManagement/settings.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import Loader from '../../innovative/common/layout/Loader';
import ConfirmationPopupGlobal from '../../innovative/common/settingsComponents/confirmationPopupGlobal';
import useSettingsHelpers from '../../../utils/innovative/settingHandler';
import { useHistory, useLocation } from 'react-router';
import { Col, Layout, Row } from 'antd';

const MarkingScheme = React.lazy(() => import('../../innovative/common/settingsComponents/markingScheme'));

const MarkingSchemeContainer = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const { isModalVisible, setIsModalVisible, onResetDefault, onConfirmHandler, modalConfig, onPreSubmit } = useSettingsHelpers();

  const pageSettingsPath = "markingScheme";

  const pageSettings = useSelector(makeDashboardSettingByPath(pageSettingsPath));


  const settingsLoading = useSelector(makeDashboardSettingsLoading);

  const settingsSaving = useSelector(makeDashboardSettingsSaveLoading);

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.globalConfigurations.key,
      section: menuData.globalConfigurations.name
    },
    {
      key: menuData.markingScheme.key,
      section: menuData.markingScheme.name,
    },
  ];

  const onSectionSelect = (section) => {
    history.push(menuData[section.key].url);
  }

  useEffect(() => {
    dispatch(setMenuSelected(menuData.markingScheme.key));
    dispatch(setBreadcrumb(breadcrumbItems));
  }, [])


  return (
    <div>
      <ConfirmationPopupGlobal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onConfirm={onConfirmHandler}
        modalConfig={modalConfig}
      />
      <Layout>
        <Suspense fallback={<Loader isLoading={true} />}>
          <div className="in-smallContainer">
            <Loader isLoading={settingsLoading || settingsSaving} />
            <Row gutter={[16, 16]}>
              <Col span={24} >
                <MarkingScheme
                  outcomeVisible={false}
                  onSubmit={onPreSubmit}
                  onResetDefault={onResetDefault}
                  onSectionSelect={onSectionSelect}
                  pageSettings={pageSettings}
                  pageSettingsPath={pageSettingsPath}
                />
              </Col>
            </Row>
          </div>
        </Suspense>
      </Layout>
    </div>
  )

}

export default MarkingSchemeContainer;