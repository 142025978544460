import { POST } from "./../../../utils/network";

export default {
  login: function (loginParams) {
    // console.info(`Login Initiate >> ${process.env.REACT_APP_APIURL}login`);
    return POST(`${process.env.REACT_APP_APIURL}login`, loginParams, "acc");
  },
  getAccessRole: (access,token) => {
    try {
      return fetch(process.env.REACT_APP_APIURL + `tentacle/access/role/${access}`, {
        credentials: "include",
        method: 'GET',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      })
    } catch (e) {
      console.log(e);
    }
  }
};
/*  */