import React, {useEffect} from 'react';
import {useHistory} from "react-router";
import {Layout, Menu, Divider, Button} from 'antd';
import {useDispatch} from "react-redux";

import {setSidebarData} from "../../../../actions/jobReadyGeneric/layout.action";
import {menu} from "../../../../constant/jobReadyGeneric/menu";

const {Sider} = Layout;

const JRSidebar = ({children}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setSidebarData({isSidebar:true, collapsed:false}))
        return () => dispatch(setSidebarData({isSidebar:false}))
    }, [])

    const onStaticMenuClick = (key) => {
        switch (key) {
            case "analysis":
                history.push(menu.riskAnalysis)
                return
            case "actionCenter":
                 return
        }
    }

    return (
        <Sider
            theme="light"
            style={{backgroundColor:"#F9F9F9"}}
            trigger={null}
        >
            <div className="mobile-only main-menu-items">
                <Button  block sucess onClick={(item) => onStaticMenuClick("analysis")} className='bg-success'> Risk Analysis</Button>
                <Button ghost block> Action Center</Button>
            </div>
            {children}

        </Sider>
    )
}

export default  JRSidebar