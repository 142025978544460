import React, { memo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, Radio, List, Button, Space, Tag, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { calculationModes } from '../../../../../../constant/vetTrack/analysis';
import {
  orEqualOperators,
  rangeOperators,
  greaterOperators,
  lessOperators
} from '../../../../../../constant/vetTrack/settings';

const ScoreSection = ({
  conditions,
  mode,
  openDrawer,
  section,
  onScoreDelete,
  subSection,
  onEditClick,
  hasPermission,
}) => {
  const renderConditionName = useCallback(
    logics => {
      if (!logics) return null;

      if (logics.length === 1) {
        const logic = logics[0];
        return (
          <span className="condition-name" style={{ fontSize: 12 }}>
            <Tag className="tag">{logic.name}</Tag>
            {logic.condition}&nbsp;{' '}
            {logic.value}&nbsp;
          </span>
        );
      }

      const rangeLogics = [];
      const nonRangeLogics = [];

      logics.forEach(logic => {
        if (rangeOperators.includes(logic.condition)) {
          rangeLogics.push(logic);
          return;
        }

        nonRangeLogics.push(logic); 
      });

      const nonRangeTags = nonRangeLogics.map((logic, index) => {
        return (
          <Fragment key={logic.id}>
            <Tag className="tag">{logic.name}</Tag>
            {logic.condition}&nbsp;{' '}
            {logic.value}&nbsp;
            { index + 1 !== nonRangeLogics.length && (
              <span className="combiner">&nbsp;AND&nbsp;&nbsp;</span>
            )} 
          </Fragment>
        );
      });
      
      if (rangeLogics.length === 1) {
        const logic = rangeLogics[0];
        return (
          <span className="condition-name" style={{ fontSize: 12 }}>
            <Tag className="tag">{logic.name}</Tag>
            {logic.condition}&nbsp;{' '}
            {logic.value}&nbsp;
            { nonRangeTags.length 
              ? <span className="combiner">&nbsp;AND&nbsp;&nbsp;</span>
              : 'null'
            }
            { nonRangeTags.length ?  nonRangeTags : null }
          </span>
        );
      }
      const max = rangeLogics.find(logic => lessOperators.includes(logic.condition));
      const min = rangeLogics.find(logic => greaterOperators.includes(logic.condition));

      return (
        <span className="condition-name" style={{ fontSize: 12 }}>
          {min.value}&nbsp;&nbsp;
          {orEqualOperators.includes(min.condition) ? '<=' : '<'}
          &nbsp;&nbsp;
          <Tag className="tag">{min.name}</Tag>&nbsp;
          {max.condition}&nbsp;&nbsp;
          {max.value}&nbsp;&nbsp;
          { nonRangeTags.length
            ? <span className="combiner">&nbsp;AND&nbsp;&nbsp;</span>
            : null
          }
          { nonRangeTags.length ? nonRangeTags : null }
        </span>
      );
    }, 
    [conditions],
  );

  const renderItemName = item => {
    if (mode === calculationModes.condition && item.logics) {
      return renderConditionName(item.logics);
    }

    if (mode === calculationModes.category) {
      return <div>{item.name}</div>;
    }
    return ''
  };

  const renderListItems = item => (
    <List.Item
      extra={
        <Space>
          <Typography.Text strong>Risk: {item.risk} %</Typography.Text>
          {hasPermission && (
            <>
              <Button
                size="small"
                type="primary"
                onClick={() => onEditClick(item.id, section, mode)}
                ghost
                icon={<EditOutlined />}
              />
              <Popconfirm
                title="Are you sure?"
                onConfirm={() =>
                  onScoreDelete(
                    section.id,
                    conditions,
                    item.id,
                    'scores',
                    subSection,
                  )
                }
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          )}
        </Space>
      }
    >
      {renderItemName(item)}
    </List.Item>
  );

  return (
    <div>
      <Typography.Paragraph strong>Score</Typography.Paragraph>
      <div>
        <Radio.Group value={mode} disabled>
          <Radio value="condition">By Condition</Radio>
          <Radio value="category">By Category</Radio>
        </Radio.Group>
      </div>
      <List
        size="small"
        style={{ marginTop: 20 }}
        bordered
        dataSource={conditions}
        renderItem={item => renderListItems(item)}
        className="logic-list"
      />
      <div style={{ marginTop: 5, textAlign: 'end' }}>
        <Button
          disabled={!hasPermission}
          type="primary"
          onClick={() => openDrawer(section, mode)}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

ScoreSection.propTypes = {
  conditions: PropTypes.array,
  mode: PropTypes.string.isRequired,
  openDrawer: PropTypes.func,
  section: PropTypes.object,
  onScoreDelete: PropTypes.func,
  subSection: PropTypes.string,
  onEditClick: PropTypes.func,
  hasPermission: PropTypes.bool,
};

export default memo(ScoreSection);
