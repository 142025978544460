import React, {useEffect, Fragment} from 'react';
import { Layout } from 'antd';
import {useDispatch, useSelector} from "react-redux";

import {fetchDashboardValidity} from "../../../actions/jrDashboardManagement/myDashboards.action";
import {
    makeDashboardValidityData,
    makeDashboardValidityLoading
} from "../../../selectors/jrDashboardManagement/myDashboard.selector";
import {getSidebarCollapsed} from "../../../selectors/jobReadyGeneric/layout.selector";

import JRHeader from "./Header";
import Loader from "../../jobReadyGeneric/common/layout/Loader";
import Maintenance from "../../jobReadyGeneric/common/Maintenance";

import '../../../assets/scss/jobReadyGeneric/layout.scss';
import '../../../assets/scss/jobReadyGeneric/riskAnalysis.scss';


const JRLayout = ({children}) => {
    const dispatch = useDispatch();

    // selectors
    const dashboardValidityLoading = useSelector(makeDashboardValidityLoading);
    const dashboardValidity = useSelector(makeDashboardValidityData);
    const sidebarCollapsed = useSelector(getSidebarCollapsed);

    useEffect(() => {
        dispatch(fetchDashboardValidity({accessCode: "JR_GEN_001"}))
    }, [])

    return (
        <Loader wrapperClassName="jr-loader-wrapper" isLoading={dashboardValidityLoading}>
            {dashboardValidity && dashboardValidity.active ?
                <Layout className={`jr-gen-layout ${sidebarCollapsed? "mobile-menu-opened": ""}`}>
                    <div className='jr-fader'></div>
                    <Fragment>
                        <JRHeader/>
                        <Layout data-testid="dashboard-content">
                            {children}
                        </Layout>
                    </Fragment>
                </Layout>:
                <Maintenance data-testid="maintenance"/>
            }
        </Loader>
    )
}

export default JRLayout;