
import React, { useEffect, memo } from "react";
import { useWebSocket } from "../innovative/useWebSocket";
import { get } from "lodash";

const WebSocketConnection = ({dashboardData}) => {
  
const schoolId = get(dashboardData, "[0].school", "");
const userID = get(dashboardData, "[0].commonId", "");

  const chartDataWebSocketConnection = useWebSocket({
    serverUrl: `${process.env.REACT_APP_SOCKET_CONNECTION_URL}`,
    initSendPayload: {
      action: "sendMessage",
      user_id: userID,
      platform: "canvas",
    }
  });

  useEffect(() => {
    if(schoolId && userID) {
      chartDataWebSocketConnection.createConnection({ school_id: schoolId });
    }
  }, [schoolId, userID])

  return <></>
}

export default memo(WebSocketConnection);

