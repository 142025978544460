import React from "react";
import { Modal, Typography, Space, Button, Row } from "antd";

const ConfirmationPopup = ({
  isModalVisible,
  setIsModalVisible,
  text1,
  text2,
  textBold,
  onConfirm,
  okText,
  cancelText,
}) => {
  return (
    <Modal closable={false} visible={isModalVisible} footer={null} centered>
      <Typography.Title
        level={5}
        style={{ fontWeight: 400, textAlign: "center" }}
      >
        {text1} <span style={{ fontWeight: 600 }}>{textBold}</span>
        <br />
        {text2}
      </Typography.Title>

      <Row justify="space-around" style={{ marginTop: 20 }}>
        <div className="button-section">
          <Space>
            <Button
              size="large"
              onClick={() => {
                onConfirm();
                setIsModalVisible(false);
              }}
            >
              {okText}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              {cancelText}
            </Button>
          </Space>
        </div>
      </Row>
    </Modal>
  );
};
export default ConfirmationPopup;
