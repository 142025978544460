import React, { memo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Layout, Row } from "antd";
import CourseStatusFilter from '../../../innovative/common/layout/sidebarPartials/CourseStatus';
import Filter from '../../common/filter';
import {
  adminVerificationLoading,
  getAdminAccounts,
  getIsApplicationAdmin,
  getSelectedAccount,
  makeInnovativeCourseStatus,
  makeInnovativeCoursesLoading,
  makeInnovativeCoursesMainReportLoading,
} from '../../../../selectors/innovative/course.selector';
import SelectFilter from '../../../innovative/common/selectFilter';
import AccountFilter from '../../../innovative/common/layout/sidebarPartials/AccountFilter';
import StudentSearch from './studentSearch';
import session from '../../../../utils/session';
import { fetchCourses, setSelectedAccount } from '../../../../actions/innovative/course.action';
import { makeInnovativeCourseStatus as makeAdminSelectedState } from '../../../../selectors/innovative_student/student.selector';
import { isAdminDashboardView } from '../../../../utils/general';
import { get as _get} from "lodash";

const ADMIN_PATH = "/insights/admin/student/profile";

const HeaderFilters = ({
  onFilterApply,
  terms,
  termsLoading,
  studentsReportLoading,
  termChange,
  selectedTerm,
  enrollmentOptions,
  onEnrollmentChanged,
  selectedEnrollment,
  setSelectedStudent,
  selectedStudent,
  actingRole,
  roles,
  students,
  studentsLoading,
  studentChange,
  student,
}) => {
  const dispatch = useDispatch();

  const selectedState = useSelector(makeInnovativeCourseStatus);
  const adminSelectedState = useSelector(makeAdminSelectedState);
  const accountList = useSelector(getAdminAccounts);
  const accountsLoading = useSelector(adminVerificationLoading);
  const selectedAccount = useSelector(getSelectedAccount);
  const areCoursesLoading = useSelector(makeInnovativeCoursesLoading);
  const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManagementData, "is_admin", false);

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  }

  const isAdminView = isAdminDashboardView({ actAs, path, ADMIN_PATH });
  const filterCourseStates = isAdminView ? adminSelectedState : selectedState;

  const handleAccountsChange = accountId => {
    dispatch(setSelectedAccount(accountId));
    dispatch(fetchCourses({
      existingRequest: false,
      requestParams: {
        "user_id": session
          .get([
            session.keys.innovativeMeta,
            "token"
          ]),
        accountId,
        filterCourseStates: filterCourseStates,
        ...adminPayload,
      }
    }))
  }

  return (
    <div className="search-header-enabled">
      <div className="student-profile-sec-header">
        <Layout.Header>
          <Row >
            <Col>
              <Row gutter={15}>
                {roles.parent.includes(actingRole) &&
                  <Col
                    span={6}
                    className="admin-header-col-item"
                  >
                    <Form.Item
                      colon={false}
                      label="Student"
                      className="header-form-item"
                    >
                      <Filter
                        dataList={students}
                        isLoading={studentsLoading}
                        reportLoading={studentsReportLoading}
                        onChange={studentChange}
                        selected={students.length ? selectedStudent : null}
                        filterConfig={{
                          key: "id",
                          value: "id",
                          label: "name",
                        }}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  isAdminView && <Col
                    span={6}
                    className="admin-header-col-item"
                  >
                    <Form.Item
                      colon={false}
                      label="Accounts"
                      className="header-form-item"
                    >
                      <AccountFilter
                        accounts={accountList}
                        accountChange={handleAccountsChange}
                        accountsLoading={accountsLoading}
                        reportLoading={areCoursesLoading || courseMainReportLoading}
                        selectedAccount={selectedAccount}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  isAdminView && <Col
                    span={6}
                    className="admin-header-col-item"
                  >
                    <Form.Item
                      label="Student"
                      colon={false}
                      className="header-form-item"
                    >
                      <StudentSearch
                        setSelectedStudent={setSelectedStudent}
                        selectedStudent={selectedStudent}
                        student={student}
                      />
                    </Form.Item>
                  </Col>
                }
                <Col
                  span={isAdminView ? 4 : 6}
                  className="admin-header-col-item"
                >
                  <Form.Item
                    colon={false}
                    label="Course Status"
                    className="header-form-item"
                  >
                    <CourseStatusFilter
                      onFilterApply={onFilterApply}
                      selectedState={filterCourseStates}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={isAdminView ? 4 : 6}
                  className="admin-header-col-item"
                >
                  <Form.Item
                    colon={false}
                    label="Term"
                    className="header-form-item"
                  >
                    <Filter
                      dataList={terms}
                      isLoading={termsLoading}
                      reportLoading={studentsReportLoading}
                      onChange={termChange}
                      selected={selectedTerm}
                      filterConfig={{
                        key: "_id",
                        value: "_id",
                        label: "name",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={isAdminView ? 4 : 6}
                  className="admin-header-col-item"
                >
                  <Form.Item
                    colon={false}
                    label="Enrollment Status"
                    className="header-form-item"
                  >
                    <SelectFilter
                      items={enrollmentOptions}
                      itemsLoading={false}
                      reportLoading={studentsReportLoading}
                      itemChange={onEnrollmentChanged}
                      selectedItem={selectedEnrollment}
                      wrapperId="innovative-term-parent-select"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout.Header >
      </div>
    </div>
  );
};

export default memo(HeaderFilters);
