import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const FeedbackSuccessModal = props => {
  const { openStatus, onClose } = props;

  const getTitle = () => (
    <h5>
      <strong>Send Feedback</strong>
    </h5>
  );

  return (
    <Modal
      title={getTitle()}
      visible={openStatus}
      onCancel={onClose}
      footer={null}
    >
      <Row className="feedback_success_icon_row">
        <CheckCircleOutlined style={{ fontSize: 60, color: '#73D13D' }} />
      </Row>
      <Row className="feedback_success_text_row">
        <p>
          Thank you for your feedback. We appriciate it and you will receive an
          email with a link to follow-up.
        </p>
      </Row>
      <Row className="feedback_success_icon_row">
        <Button onClick={onClose} type='primary'>Close</Button>
      </Row>
    </Modal>
  );
};

FeedbackSuccessModal.propTypes = {
  openStatus: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FeedbackSuccessModal;
