import {
    JR_GEN_SIDEBAR_FILTERS_FETCH,
    JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING,
    JR_GEN_SIDEBAR_FILTERS_SELECT,
    JR_GEN_SET_SELECTED_CARDS
} from "../../../constant/actionTypes";
import service from "../../../utils/serviceHandlers/jobReady/customServices/jrGeneric";

/** Fetch sidebar filters **/
// fetch sidebar filters loading
const fetchSidebarFiltersLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch sidebar filters handler
const fetchSidebarFiltersDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_SIDEBAR_FILTERS_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch sidebar filters
export const fetchSidebarFilters = () => dispatch => {
    dispatch(fetchSidebarFiltersLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchSidebarFilters`,
        method: "POST",
    }).then(response => {
        dispatch(fetchSidebarFiltersDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchSidebarFiltersDataStatus({
            error: error.message
        }))
    })
};


/** Select sidebar filters **/
// select sidebar filters
export const selectSidebarFilters = (data) => dispatch => {
    dispatch({
        type: JR_GEN_SIDEBAR_FILTERS_SELECT,
        payload: data,
    });
};

/** Set selected cards **/
// set selected cards
export const setSelectedCards = (data) => dispatch => {
    dispatch({
        type: JR_GEN_SET_SELECTED_CARDS,
        payload: data,
    });
};