import React from "react";
import { Statistic } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import PropTypes from "prop-types";

const StatCell = ({value, valueColor, type, precision}) => {
    const getIcon = () => {
        switch (type) {
            case "up":
                return CaretUpFilled;
            case "down":
                return CaretDownFilled;
            default:
                return false

        }
    }

    const renderStat = () => {
        const Icon = getIcon();
        return (
            <Statistic
            value={value}
            precision={precision}
            valueStyle={{ color: valueColor, fontSize:20, fontWeight:"bold" }}
            prefix={Icon && <Icon />}
            suffix={value === "NA" ? "" : "%"}
          />
        )

    }
    return (
        renderStat()
    )
}

StatCell.defaultProps = {
    precision:1
}

StatCell.propTypes = {
    value: PropTypes.number,
    valueColor: PropTypes.string,
    type: PropTypes.string
}

export default StatCell;