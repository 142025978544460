import {onError} from "../../../../utils/notificationHandler";
import {settingsSections} from "../../../../constant/jobReadyGeneric/constants";

const validateRiskCalculationData = (dataset) => {
    const totalWeight = Object.keys(dataset.factors).reduce((accum, factorKey) => {
        return accum + parseFloat(dataset.factors[factorKey].weight)
    },0);

    if(totalWeight !== 100) {
        onError("Total factor weight should be equal to 100")
        return true
    }
    return false
}

export const initiateValidations = (section, dataset) => {
    let isError = false;
    switch (section) {
        case settingsSections.riskCalculations:
            isError = validateRiskCalculationData(dataset);
            break;
    }
    return isError;
}