import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Tabs } from 'antd';

import SettingHeader from '../../common/SectionHeader';
import SettingButtonGroup from '../partials/buttonGroup/ButtonGroup';
import SectionGroup from './partials/SectionGroup';
import MainDrawer from './partials/mainDrawer/MainDrawer';
import Loader from '../../common/layout/Loader';
import SyncingBanner from '../partials/hoc/syncingBanner/SyncingBannerView';
import SyncMessage from '../partials/SyncMessage';

import {
  subSections,
  settingsSections,
} from '../../../../../constant/vetTrack/analysis';
import { menu } from '../../../../../constant/vetTrack/menu';

const { TabPane } = Tabs;
const { Paragraph, Title } = Typography;

const RiskCalculationsView = ({
  drawerConfig,
  academicSections,
  financialSections,
  complianceSections,
  onSectionDataChange,
  onScoreDelete,
  tabConfig,
  isLoading,
  onEditClick,
  onDrawerSave,
  isSyncing,
  syncObject,
}) => {
  const renderTitles = title => (
    <>
      <Title level={5} style={{ color: '#6244BB' }}>
        Calculations
      </Title>
      <div className="vtt-datasources-sub-header">
        <Paragraph strong>{title}</Paragraph>
        <SyncMessage syncData={syncObject} />
      </div>
    </>
  );

  const renderFormula = (topic, text) => (
    <Paragraph type="secondary" style={{ marginTop: 10 }}>
      {topic}:<br />
      {text}
    </Paragraph>
  );

  return (
    <div>
      <Loader isLoading={isLoading} />
      <SettingHeader
        title="Risk Calculations"
        items={[
          { name: 'Risk Analysis', href: menu.riskAnalysis },
          'Settings',
          'Risk Calculations',
        ]}
      />
      <Tabs activeKey={tabConfig.activeTab} onChange={tabConfig.setActiveTab}>
        <TabPane tab="Academic" key={subSections.academic.key}>
          {tabConfig.activeTab === subSections.academic.key && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
            {renderTitles('Academic weighted Average calculation')}
            <SectionGroup
              sections={academicSections}
              showDrawer={drawerConfig.showDrawer}
              onSectionDataChange={onSectionDataChange}
              subSection={subSections.academic.key}
              onScoreDelete={onScoreDelete}
              onEditClick={onEditClick}
            />
            {renderFormula(
              'Academic Risk calculation',
              'Academic Risk =  (Unit progress weight * Score) + (Attendance weight * Score)+(Attendance Mode weight * Score)+(Employment Status weight * Score)+(Eligibility Exemption weight * Score)+(Eligibility Exemption weight * Score)+(Entry Requirement weight * Score)+(Study Reason weight * Score) / Sum of weights',
            )}
            <SettingButtonGroup
              section={settingsSections.riskCalculations}
              subSection={subSections.academic.key}
            />
          </Card>
        </TabPane>
        <TabPane tab="Financial" key={subSections.financial.key}>
          {tabConfig.activeTab === subSections.financial.key && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
            {renderTitles('Financial weighted Average calculation')}
            <SectionGroup
              sections={financialSections}
              showDrawer={drawerConfig.showDrawer}
              onSectionDataChange={onSectionDataChange}
              subSection={subSections.financial.key}
              onScoreDelete={onScoreDelete}
              onEditClick={onEditClick}
            />
            {renderFormula(
              'Financial Risk calculation',
              'Financial Risk =  (Completed payment as a percentage of invoices issued * Score) +(Attendance Mode weight * Score)+(Employment Status weight * Score)+(Payment Plan weight * Score)+(Due Fee weight* Score)+(Payment Due Date weight* Score)+(VET Fee Help  weight * Score)+(Funding Source State * Score)+(Fee Exception Indicator weight * Score)+(Historical Payment Delays weight * Score) / Sum of weights',
            )}
            <SettingButtonGroup
              section={settingsSections.riskCalculations}
              subSection={subSections.financial.key}
            />
          </Card>
        </TabPane>
        <TabPane tab="Compliance" key={subSections.compliance.key}>
          {tabConfig.activeTab === subSections.compliance.key && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
            {renderTitles('Compliance weighted Average calculation')}
            <SectionGroup
              sections={complianceSections}
              showDrawer={drawerConfig.showDrawer}
              onSectionDataChange={onSectionDataChange}
              subSection={subSections.compliance.key}
              onScoreDelete={onScoreDelete}
              onEditClick={onEditClick}
            />
            {renderFormula(
              'Compliance Risk calculation',
              'Compliance Risk = (Visa Expiration weight * Score) + (Passport Expiration weight * Score)+(Overseas Student Health Cover weight* Score)+(Attendance weight* Score)+(Visa Type  weight* Score)+( Visa Status weight * Score)+(Entry Requirement weight * Score)+(Study Reason weight * Score) / Sum of weights',
            )}
            <SettingButtonGroup
              section={settingsSections.riskCalculations}
              subSection={subSections.compliance.key}
            />
          </Card>
        </TabPane>
      </Tabs>
      <MainDrawer drawerConfig={drawerConfig} onFinish={onDrawerSave} />
    </div>
  );
};

RiskCalculationsView.propTypes = {
  drawerConfig: PropTypes.object,
  academicSections: PropTypes.array,
  financialSections: PropTypes.array,
  complianceSections: PropTypes.array,
  onSectionDataChange: PropTypes.func,
  onScoreDelete: PropTypes.func,
  tabConfig: PropTypes.object,
  isLoading: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDrawerSave: PropTypes.func,
  isSyncing: PropTypes.bool,
  syncObject: PropTypes.object,
};

export default RiskCalculationsView;
