import React from "react";
import PropTypes from "prop-types";
import {Typography, Card, Select, Dropdown, Menu, Row, Space, Button} from "antd";
import {DownOutlined} from "@ant-design/icons";

import UserTableContainer from "./partials/userTable";
import Loader from "../common/layout/Loader";


const JRGenUserManagementView = ({dashboards, onDashboardChange, showUserModal, isLoading, selectedDashboard}) => {

    const renderOptions = () => {
        return dashboards && dashboards.map(dashboard => <Select.Option title={dashboard.name} value={dashboard.id}>{dashboard.name}</Select.Option>)
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={showUserModal}>
                Add New User
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="jr-gen-user">
            <Loader isLoading={isLoading}/>
                <Row justify="space-between">
                    <Typography.Title level={3}>User Management</Typography.Title>
                    <div>
                        <Space>
                            <Select data-testid="dashboard-select" onSelect={onDashboardChange} style={{width:200}} value={selectedDashboard}>
                                {renderOptions()}
                            </Select>
                            <Dropdown trigger={['click']} overlay={menu}>
                                <Button className="add-user-menu-btn">
                                    Add User <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </Row>
                <Card className="user-container">
                    <Typography.Title level={5}>Users</Typography.Title>
                    <UserTableContainer/>
                </Card>
        </div>
    )
}

JRGenUserManagementView.propTypes = {
    dashboards: PropTypes.array,
    onDashboardChange: PropTypes.func,
    showUserModal: PropTypes.func,
    isLoading: PropTypes.bool,
    selectedDashboard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default JRGenUserManagementView;