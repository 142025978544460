import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';

import {
  getRcSettingsByPath,
  getRcAcademicDataFetchLoading,
  getRcAcademicSaveLoading,
  getRcFinancialDataFetchLoading,
  getRcFinancialSaveLoading,
  getRcComplianceDataFetchLoading,
  getRcComplianceSaveLoading,
  getIsEdited,
  getSettingBySections,
  makeRCSyncStauses,
} from '../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchSettings,
  resetEditMode,
  saveSettings,
  setSettings,
  fetchSectionSyncStatus,
} from '../../../../../actions/vetTrack/analysis/settings/commonSettings.action';

import RiskCalculationsView from './RiskCalculationsView';
import EditedWarning from '../partials/EditedWarning';

import {
  academicSections,
  financialSections,
  complianceSections,
  settingsSections,
  subSections,
} from '../../../../../constant/vetTrack/analysis';
import { syncCheckInterval } from '../../../../../constant/vetTrack/settings';
import { usePrevious } from '../../../../../utils/customHooks';
import { initiateValidations } from '../partials/helperValidators';

const RiskCalculationsContainer = () => {
  const dispatch = useDispatch();
  const [academicSectionsState, setAcademicSections] = useState([]);
  const [financialSectionsState, setFinancialSections] = useState([]);
  const [complianceSectionsState, setComplianceSections] = useState([]);

  const [showWarning, setShowWarning] = useState({ state: false, key: null });
  const [activeTab, setActiveTab] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedConditionMeta, setSelectedConditionMeta] = useState({});
  const [lastSync, setLastSync] = useState(null);

  // refs
  const timer = useRef(null);

  const previousValues = usePrevious({ activeTab });

  // selectors
  const academicSectionData = useSelector(
    getRcSettingsByPath(`${subSections.academic.key}.data.factors`),
  );
  const financialSectionData = useSelector(
    getRcSettingsByPath(`${subSections.financial.key}.data.factors`),
  );
  const complianceSectionData = useSelector(
    getRcSettingsByPath(`${subSections.compliance.key}.data.factors`),
  );
  const syncData = useSelector(makeRCSyncStauses);

  const fetchAcademicDataLoading = useSelector(getRcAcademicDataFetchLoading);
  const saveAcademicDataLoading = useSelector(getRcAcademicSaveLoading);
  const fetchFinancialDataLoading = useSelector(getRcFinancialDataFetchLoading);
  const saveFinancialDataLoading = useSelector(getRcFinancialSaveLoading);
  const fetchComplianceDataLoading = useSelector(
    getRcComplianceDataFetchLoading,
  );
  const saveComplianceDataLoading = useSelector(getRcComplianceSaveLoading);

  const isEdited = useSelector(getIsEdited);
  const currentDataSet = useSelector(
    getSettingBySections('data', isEdited.section, isEdited.subSection),
  );
  const currentInitialDataSet = useSelector(
    getSettingBySections('data2', isEdited.section, isEdited.subSection),
  );

  const syncObject = syncData[activeTab] || {}
  const sectionIsSyncing = _get(syncData, `${activeTab}.isSyncing`, false);

  const isLoading =
    fetchAcademicDataLoading ||
    saveAcademicDataLoading ||
    fetchFinancialDataLoading ||
    saveFinancialDataLoading ||
    fetchComplianceDataLoading ||
    saveComplianceDataLoading;

  // effects
  useEffect(() => {
    if (academicSectionData) {
      const data = prepareSectionData(academicSectionData, academicSections);
      setAcademicSections(data);
    }
    // had to make the dependency as JSON string coz the setSettings change does not captured in useEffect
  }, [JSON.stringify(academicSectionData)]);

  useEffect(() => {
    if (financialSectionData) {
      const data = prepareSectionData(financialSectionData, financialSections);
      setFinancialSections(data);
    }
    // had to make the dependency as JSON string coz the setSettings change does not captured in useEffect
  }, [JSON.stringify(financialSectionData)]);

  useEffect(() => {
    if (complianceSectionData) {
      const data = prepareSectionData(
        complianceSectionData,
        complianceSections,
      );
      setComplianceSections(data);
    }
    // had to make the dependency as JSON string coz the setSettings change does not captured in useEffect
  }, [JSON.stringify(complianceSectionData)]);

  useEffect(() => {
    const defaultTab = _get(subSections, 'academic.key', '');
    const defaultId = _get(subSections, 'academic.id', 0);
    setActiveTab(defaultTab);
    fetchRCSyncStatus(defaultId);
    setLastSync(Date.now());
  }, []);

  useEffect(() => {
    if (sectionIsSyncing && lastSync) {
      const id  = _get(subSections, `${activeTab}.id`, 0);
      const timeout = setTimeout(() => {
        fetchRCSyncStatus(id);
        setLastSync(Date.now());
      }, syncCheckInterval);
      timer.current = timeout;
    }
    
    return () => {
      clearTimeout(timer.current);
    }

  }, [lastSync, sectionIsSyncing, activeTab]);

  useEffect(() => {
    // fetch settings data according to the selected tab
    if (activeTab) {
      const subSectionKey = Object.keys(subSections).find(
        key => subSections[key].key === activeTab,
      );
      dispatch(
        fetchSettings(
          {},
          settingsSections.riskCalculations,
          activeTab,
          subSections[subSectionKey].id,
        ),
      );

      // To Prevent calling this fetchSync status twice in redirecting
      const previousTab = _get(previousValues, 'activeTab', null);
      if (previousTab) {
        fetchRCSyncStatus(subSections[subSectionKey].id);
        setLastSync(Date.now());
        clearTimeout(timer.current);
      }
    }
  }, [activeTab]);

  const onTabChange = key => {
    if (isEdited.state) {
      setShowWarning({ state: true, key });
    } else {
      setActiveTab(key);
    }
  };

  const prepareSectionData = (dataset, constantData) => {
    // combine the fetched dataset and constant data set and returns union
    // NOTE: keys should need to be same in order to make a valid dataset
    // if some factors are missing in the UI, check the KEYS
    if (!dataset) return [];
    const sections = [];
    Object.keys(dataset)
      .sort()
      .forEach(obKey => {
        const item = dataset[obKey];
        const constantItemKey = Object.keys(constantData).find(
          key => constantData[key].key === obKey,
        );
        if (constantItemKey) {
          const { key, name, mapper, mode, multiSelect, notPercentage } = constantData[
            constantItemKey
          ];
          sections.push({
            ...item,
            id: key,
            name,
            mapper,
            mode,
            multiSelect: multiSelect || false,
            notPercentage: notPercentage || false,
          });
        }
      });
    return sections.filter(Boolean);
  };
  const showMainDrawer = (factor, mode) => {
    setSelectedConditionMeta({ factor, mode });
    setDrawerVisible(true);
  };
  const onCloseMainDrawer = () => {
    setSelectedConditionMeta({});
    setDrawerVisible(false);
  };

  const onSectionDataChange = (key, value, end, subSection) => {
    // all top-level section related changes are handled through here
    dispatch(
      setSettings(
        value,
        `data.factors.${key}.${end}`,
        settingsSections.riskCalculations,
        subSection,
      ),
    );
  };

  const onScoreDelete = (key, dataset, id, end, subSection) => {
    const filteredDataset = dataset.filter(i => i.id !== id);
    onSectionDataChange(key, filteredDataset, end, subSection);
  };

  const onSave = () => {
    // call for save settings api
    if (!initiateValidations(isEdited.section, currentDataSet)) {
      const subSectionKey = Object.keys(subSections).find(
        key => subSections[key].key === isEdited.subSection,
      );
      dispatch(
        saveSettings(
          currentDataSet,
          'draft',
          isEdited.section,
          isEdited.subSection,
          subSections[subSectionKey].id,
          false,
          () => {
            setShowWarning({ state: false, key: null });
            dispatch(resetEditMode());
            setActiveTab(showWarning.key);
          },
        ),
      );
    }
  };

  const onDiscard = () => {
    // reset dataset from the initial fetched data
    if (currentInitialDataSet) {
      dispatch(
        setSettings(
          currentInitialDataSet,
          'data',
          isEdited.section,
          isEdited.subSection,
        ),
      );
    }
    setShowWarning({ state: false, key: null });
    dispatch(resetEditMode());
    setActiveTab(showWarning.key);
  };

  const onEditClick = (id, factor, mode) => {
    setSelectedConditionMeta({ id, factor, mode });
    setDrawerVisible(true);
  };

  const onDrawerSave = factor => {
    dispatch(
      setSettings(
        factor.scores,
        `data.factors.${factor.id}.scores`,
        settingsSections.riskCalculations,
        activeTab,
      ),
    );
  };

  const fetchRCSyncStatus = id => {
    dispatch(fetchSectionSyncStatus({
      section: settingsSections.riskCalculations,
      subSectionId: id,
    }));
  };

  return (
    <>
      <RiskCalculationsView
        isLoading={isLoading}
        tabConfig={{ activeTab, setActiveTab: onTabChange }}
        academicSections={academicSectionsState}
        complianceSections={complianceSectionsState}
        financialSections={financialSectionsState}
        onSectionDataChange={onSectionDataChange}
        onScoreDelete={onScoreDelete}
        onEditClick={onEditClick}
        onDrawerSave={onDrawerSave}
        isSyncing={sectionIsSyncing}
        drawerConfig={{
          showDrawer: showMainDrawer,
          onClose: onCloseMainDrawer,
          drawerMeta: selectedConditionMeta,
          drawerVisible,
        }}
        syncObject={syncObject}
      />
      <EditedWarning
        visible={showWarning.state}
        onCancel={() => setShowWarning({ state: false, key: null })}
        onSave={onSave}
        onDiscard={onDiscard}
      />
    </>
  );
};

export default RiskCalculationsContainer;
