import {Space, Typography} from "antd";
import PropTypes from "prop-types";
import React from "react";

import SectionBreadcrumb from "./Breadcrumb";

const SectionHeader = ({title, items}) => (
    <div className="page-header" style={{display:"flex", alignItems:"center"}}>
        <Space>
            <Typography.Title level={3}>{title}</Typography.Title>
            <SectionBreadcrumb items={items}/>
        </Space>
    </div>
)

SectionHeader.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array
}

export default SectionHeader;