

export default {
    "IN_TCH_001": {
        name:"Teacher insights",
        description:"",
    },
    "CU_TCH_001": {
        name:"Teacher",
        description:"ACC Teacher",
    },
    "CU_STD_001": {
        name:"Student",
        description:"ACC Student",
    },
    "CU_PRN_001": {
        name:"Parent",
        description:"ACC Parent",
    },
    "IN_STD_001": {
        name:"Student insights",
        description:"",
    },
    "IN_PRN_001": {
        name:"Parent insights",
        description:"",
    },
    "CU_STDN_001": {
        name:"Student Dashboard",
        description:"ACC Student",
    }

}