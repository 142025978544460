export default {
  profile: async function (filters, token) {
    try {
      console.info(
        `Fetch Teacher Profile >> ${process.env.REACT_APP_APIURL}teacherprofile/teacher/${filters.logged_in}/${filters.course}/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/teacher/${filters.logged_in}/${filters.course}/${filters.access}`, {
        credentials: "include",
        method: 'GET',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      });
    } catch (e) {
      console.log(e);
    }
  },
  studentStats: async function (filters, token) {
    try {
      console.info(
        `Fetch student stats >> ${process.env.REACT_APP_APIURL}teacherprofile/student/stats_acc/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/student/stats_acc/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify(filters)

      });
    } catch (e) {
      console.log(e);
    }
  },
  gradeComparisanByAssignment: async function (filters, token) {
    try {
      console.info(
        `Fetch grade comparison by assignment >> ${process.env.REACT_APP_APIURL}teacherprofile/marks_by_assignment_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/marks_by_assignment_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, studentId: filters.studentId, term: filters.term, semester: filters.semester })

      })
    } catch (e) {
      console.log(e);
    }
  },
  latestPatFilters: async function (filters, token) {
    try {
      console.info(
        `Fetch Latest Pat Filters >> ${process.env.REACT_APP_APIURL}teacherprofile/filters/latest_pat_marks_acc/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/filters/latest_pat_marks_acc/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, year: filters.year })

      })
    } catch (e) {
      console.log(e);
    }
  },
  latestPatMarks: async function (filters, token) {
    try {
      console.info(
        `Fetch Latest Pat >> ${process.env.REACT_APP_APIURL}teacherprofile/latest_pat_marks_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/latest_pat_marks_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, year: filters.year, testId: filters.testId })

      })
    } catch (e) {
      console.log(e);
    }
  },
  latestNaPlanFilters: async function (filters, token) {
    try {
      console.info(
        `Fetch Latest NaPlan Filters >> ${process.env.REACT_APP_APIURL}teacherprofile/filters/latest_naplan_marks_acc/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/filters/latest_naplan_marks_acc/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, year: filters.year })

      })
    } catch (e) {
      console.log(e);
    }
  },
  latestNaPlanMarks: async function (filters, token) {
    try {
      console.info(
        `Fetch Latest NaPlan >> ${process.env.REACT_APP_APIURL}teacherprofile/latest_naplan_marks_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/latest_naplan_marks_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, year: filters.year, testId: filters.testId })

      })
    } catch (e) {
      console.log(e);
    }
  },
  activityByTime: async function (filters, token) {
    try {
      console.info(
        `Fetch Activity By Time >> ${process.env.REACT_APP_APIURL}teacherprofile/activity_by_time_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/activity_by_time_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, year: filters.year, mode: filters.mode, semester: filters.semester })

      })
    } catch (e) {
      console.log(e);
    }
  },
  submissionData: async function (filters, token) {
    try {
      console.info(
        `Fetch Submission Data >> ${process.env.REACT_APP_APIURL}teacherprofile/submissions_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/submissions_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ term: filters.term, courseId: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel, semester: filters.semester })

      })
    } catch (e) {
      console.log(e);
    }
  },
  submissionDetails: async function (filters, token) {
    try {
      console.info(
        `Fetch Submission Details >> ${process.env.REACT_APP_APIURL}teacherprofile/submissions_acc/details/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/submissions_acc/details/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ term: filters.term, courseId: filters.courseId, studentId: filters.studentId, yearLevel: filters.yearLevel, semester: filters.semester })

      })
    } catch (e) {
      console.log(e);
    }
  },
  workLoad: async function (filters, token) {
    try {
      console.info(
        `Fetch WorkLoad Data >> ${process.env.REACT_APP_APIURL}teacherprofile/workload_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/workload_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ term: filters.term, courseIds: filters.courseIds, studentId: filters.studentId, yearLevel: filters.yearLevel })

      })
    } catch (e) {
      console.log(e);
    }
  },
  engagementPerformance: async function (filters, token) {
    try {
      console.info(
        `Fetch WorkLoad Data >> ${process.env.REACT_APP_APIURL}teacherprofile/engagementvsperformance_acc/data/${filters.access}`
      );
      return fetch(process.env.REACT_APP_APIURL + `teacherprofile/engagementvsperformance_acc/data/${filters.access}`, {
        credentials: "include",
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseId: filters.courseId, term: filters.term, semester: filters.semester })

      })
    } catch (e) {
      console.log(e);
    }
  }
}