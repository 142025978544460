import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty as _isEmpty,
  get as _get,
} from 'lodash';
import {
  getRiAcademicDataFetchLoading,
  getRiAcademicSaveLoading,
  getRiFinancialDataFetchLoading,
  getRiFinancialSaveLoading,
  getRiComplianceDataFetchLoading,
  getRiComplianceSaveLoading,
  getSettingBySections,
  getIsEdited,
  getRiSettingsByPath,
  makeRISyncStauses,
} from '../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchSettings,
  resetEditMode,
  saveSettings,
  setSettings,
  fetchSectionSyncStatus,
} from '../../../../../actions/vetTrack/analysis/settings/commonSettings.action';

import RiskIndicatorsView from './RiskIndicatorsView';
import EditedWarning from '../partials/EditedWarning';

import {
  settingsSections,
  subSections,
} from '../../../../../constant/vetTrack/analysis';
import { syncCheckInterval } from '../../../../../constant/vetTrack/settings';
import { initiateValidations } from '../partials/helperValidators';
import { usePrevious } from '../../../../../utils/customHooks';

const RiskIndicatorsContainer = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [showWarning, setShowWarning] = useState({ state: false, key: null });
  const [lastSync, setLastSync] = useState(null);

  // selectors
  const isEdited = useSelector(getIsEdited);
  const currentDataSet = useSelector(
    getSettingBySections('data', isEdited.section, isEdited.subSection),
  );
  const currentInitialDataSet = useSelector(
    getSettingBySections('data2', isEdited.section, isEdited.subSection),
  );
  const syncData = useSelector(makeRISyncStauses);

  // refs
  const timer = useRef(null);
  const previousValues = usePrevious({ activeTab });

  const fetchAcademicDataLoading = useSelector(getRiAcademicDataFetchLoading);
  const saveAcademicDataLoading = useSelector(getRiAcademicSaveLoading);
  const fetchFinancialDataLoading = useSelector(getRiFinancialDataFetchLoading);
  const saveFinancialDataLoading = useSelector(getRiFinancialSaveLoading);
  const fetchComplianceDataLoading = useSelector(
    getRiComplianceDataFetchLoading,
  );
  const saveComplianceDataLoading = useSelector(getRiComplianceSaveLoading);
  const sectionErrors = useSelector(
    getRiSettingsByPath(`${activeTab}.errors`),
  );

  const isLoading =
    fetchAcademicDataLoading ||
    saveAcademicDataLoading ||
    fetchFinancialDataLoading ||
    saveFinancialDataLoading ||
    fetchComplianceDataLoading ||
    saveComplianceDataLoading;

  const syncObject = syncData[activeTab] || {}
  const sectionIsSyncing = _get(syncData, `${activeTab}.isSyncing`, false);

  // effects
  useEffect(() => {
    const defaultTab = _get(subSections, 'academic.key', '');
    const defaultId = _get(subSections, 'academic.id', 0);
    fetchRISyncStatus(defaultId);
    setActiveTab(defaultTab);
    setLastSync(Date.now());
  }, []);

  useEffect(() => {
    if (sectionIsSyncing && lastSync) {
      const id  = _get(subSections, `${activeTab}.id`, 0);
      const timeout = setTimeout(() => {
        fetchRISyncStatus(id);
        setLastSync(Date.now());
      }, syncCheckInterval);
      timer.current = timeout;
    }

    return () => {
      clearTimeout(timer.current);
    }

  }, [lastSync, sectionIsSyncing, activeTab]);

  useEffect(() => {
    // fetch settings data according to the selected tab
    if (activeTab) {
      const subSectionKey = Object.keys(subSections).find(
        k => subSections[k].key === activeTab,
      );
      dispatch(
        fetchSettings(
          {},
          settingsSections.riskIndicators,
          activeTab,
          subSections[subSectionKey].id,
        ),
      );

      // To Prevent calling this fetchSync status twice in redirecting
      const previousTab = _get(previousValues, 'activeTab', null);
      if (previousTab) {
        fetchRISyncStatus(subSections[subSectionKey].id);
        setLastSync(Date.now());
        clearTimeout(timer.current);
      }
    }
  }, [activeTab]);

  const onTabChange = key => {
    if (isEdited.state) {
      setShowWarning({ state: true, key });
    } else {
      setActiveTab(key);
    }
  };

  const onSave = () => {
    // call for save settings api
    if (!initiateValidations(isEdited.section, currentDataSet)) {
      const subSectionKey = Object.keys(subSections).find(
        key => subSections[key].key === isEdited.subSection,
      );
      dispatch(
        saveSettings(
          currentDataSet,
          'draft',
          isEdited.section,
          isEdited.subSection,
          subSections[subSectionKey].id,
          false,
          () => {
            setShowWarning({ state: false, key: null });
            dispatch(resetEditMode());
            setActiveTab(showWarning.key);
          },
        ),
      );
    }
  };

  const onDiscard = () => {
    // reset dataset from the initial fetched data
    if (currentInitialDataSet) {
      dispatch(
        setSettings(
          currentInitialDataSet,
          'data',
          isEdited.section,
          isEdited.subSection,
        ),
      );
    }
    setShowWarning({ state: false, key: null });
    dispatch(resetEditMode());
    setActiveTab(showWarning.key);
  };

  const fetchRISyncStatus = id => {
    dispatch(fetchSectionSyncStatus({
      section: settingsSections.riskIndicators,
      subSectionId: id,
    }));
  };

  return (
    <>
      <RiskIndicatorsView
        isLoading={isLoading}
        tabConfig={{ activeTab, setActiveTab: onTabChange }}
        isSyncing={sectionIsSyncing}
        syncObject={syncObject}
      />
      <EditedWarning
        visible={showWarning.state}
        onCancel={() => setShowWarning({ state: false, key: null })}
        onSave={onSave}
        onDiscard={onDiscard}
        hasErrors={!_isEmpty(sectionErrors)}
      />
    </>
  );
};

export default RiskIndicatorsContainer;
