import React from "react";
import {Col, Row, Steps} from "antd";
import PropTypes from "prop-types";

const Stepper = ({steps, activeStep}) => (
    <Row gutter={[10, 24]}>
        <Col span={24}>
            <Steps current={activeStep}>
                {steps.map(step => (
                    <Steps.Step key={step.title}/>
                ))}
            </Steps>
        </Col>
    </Row>
);

Stepper.propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number,
};

export default Stepper;
