export const commonLineChartConf = {
  title: {
    text: '',
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
  },

  yAxis: {
    title: {
      text: '',
    },
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      shadow: true,
    },
  },
};

export const topCardPieChartConf = {
  legend: {
    align: 'left',
    verticalAlign: 'middle',
    layout: 'vertical',
    margin: 8,
    width: '50%',
  },
  chart: {
    height: '150px',
  },
  plotOptions: {
    pie: {
      size: '100%',
      innerSize: '60%',
      minSize: 90,
      slicedOffset: 0,
      showInLegend: true,
    },
  },
};

export const acedamicChartConf = {
  chart: {
    height: '150px',
  },

  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    pie: {
      size: '100%',
      innerSize: '60%',
      minSize: 90,
      slicedOffset: 0,
      dataLabels: {
        enabled: false,
      },
      borderWidth: 0,
    },
  },

  tooltip: {
    enabled: false,
  },
};

export const finacialChartConf = {
  chart: {
    height: '150px',
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    pie: {
      size: '100%',
      innerSize: '60%',
      minSize: 90,
      slicedOffset: 0,
      dataLabels: {
        enabled: false,
      },
      borderWidth: 0,
    },
  },
  tooltip: {
    enabled: false,
  },
};

