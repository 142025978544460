import React, { Component, Fragment } from 'react';

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import ApiServices from '../../../../services/tentacle';
import accimage from '../../../../assets/images/acclogo.png';
import PrincipalProfileWrapper from "../principalProfileWrapper/PrincipalProfileWrapper";

import addStock from "highcharts/modules/stock";
import './../../../../assets/css/principal-profile.css'
import './../../../../assets/css/enrolment-profile.css'
import {withRouter} from "react-router";
import { Switch } from "antd";
import Helper from "../../common/numberHelper";

addStock(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class EnrolmentProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      titleFocus: React.createRef(),
      isFilterTermsLoaded:false,
      confirmedEnrolmentsTableLoaded: false,
      enrolmentProjectionTableLoaded: false,
      currentEnquiriesTableLoaded: false,
      summaryCardsLoaded: false,
      filterTermValue:0,
      filterTerms:[],
      confirmedEnrolmentsData:[],
      enrolmentProjectionData:[],
      currentEnquiriesData:[],
      leadCounts:[],
      isFirstTimeLoad:true,
      months: ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"],
      enrolmentProjectionColumns: ["CURRENT ENROLMENT","Withdrawals (This year)","Withdrawals (Next year)", "Future enrolments (This year)","Future enrolments (Next year)",
              "Final year leavers","Minimum projection (Next year)","Likely enrolments","Maximum projection (Next year)"],
      enProjectionDataIndexes:[0,1,2,3,4,5,6,7,8,9],
      filterSchoolValue: this.props.location.state.currentSchool
    }
  }

  componentDidMount() {
    this.filterTerms();
  }

  filterTerms = () => {
    this.setState({
      isFilterCoursesLoaded:false,
      isGoButtonDisabled: true
    });
    var params = { school: this.state.filterSchoolValue  !== '' ? this.state.filterSchoolValue  : this.props.schoolId }

    if(!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school)){
      ApiServices.AccFilterService.principalFilterTerm(params).then((data) => {
        if(data && data.data && Object.keys(data.data.result).length !== "" && Object.keys(data.data.result).length > 0){
          let termArray = data.data.result;
          this.setState({
            isFilterTermsLoaded: true, 
            filterTermValue:termArray[0].id,
            filterTerms:termArray
          }, () => {
            this.changeTermValue(termArray[0].id);
          });
        }
      },(error) => {
        this.setState({
          isFilterTermsLoaded: true,
          error
        });
      });
    }
  }

  changeTermValue = (value) => {
    this.setState({filterTermValue:value}, () => {
      if(this.state.isFirstTimeLoad){
        this.setState({
          isFirstTimeLoad: false,
        })
        this.drawCharts();
      }
    });
  }

  drawCharts = () => {
    this.summaryCards();
    this.confirmedEnrolments();
    this.enrolmentProjection();
    this.currentEnquiries('OC', "initial");
    this.leadCounts();
  }

  summaryCards = () => {
    this.setState({
      summaryCards : [],
      summaryCardsLoaded: false
    });
    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccEnrolmentProfileService.summmaryCards(params).then(res => res.json()).then((result) => {
        if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            summaryCards:result.data.result
          }, () => {
            this.setState({
              summaryCardsLoaded: true,
            });
          });
        }
      },(error) => {
        this.setState({
          summaryCardsLoaded: false,
          error
        });
      });
    }
  }

  confirmedEnrolments = () => {
    this.setState({
        confirmedEnrolmentsData : [],
        confirmedEnrolmentsTableLoaded: false
    });
    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccEnrolmentProfileService.confirmedEnrolments(params).then(res => res.json()).then((result) => {
        if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            confirmedEnrolmentsData:result.data.result
          }, () => {
            this.confirmedEnrolmentsOnChange("OC");
            this.setState({
              confirmedEnrolmentsTableLoaded: true,
            });
          });
        }
      },(error) => {
        this.setState({
          confirmedEnrolmentsTableLoaded: false,
          error
        });
      });
    }
  }

  confirmedEnrolmentsOnChange = (mode) => {
    this.setState({
      confirmedEnrolmentsTable: this.state.confirmedEnrolmentsData.confirmedEnrolments[mode],
      enrolmentMode:mode
    });
  }

  enrolmentProjection = () => {
    this.setState({
      enrolmentProjectionData : [],
      enrolmentProjectionTableLoaded: false
    });

    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccEnrolmentProfileService.enrolmentProjection(params).then(res => res.json()).then((result) => {
        if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            enrolmentProjectionData:result.data.result.enrolmentProjection
          }, () => {
            this.projectionOnChange("ALL");
            this.setState({
              enrolmentProjectionTableLoaded: true,
            });
          });
        }
      },(error) => {
        this.setState({
          enrolmentProjectionTableLoaded: false,
          error
        });
      });
    }
  }

  projectionOnChange = (mode) => {
    this.setState({
      enrollmentProjectionTable: this.state.enrolmentProjectionData[mode],
      projectionMode:mode
    });
  }

  currentEnquiries = (mode, trigger) => {
    this.setState({
      currentEnquiriesData : trigger == "initial" ? [] : this.state.currentEnquiriesData,
      currentEnquiriesTableLoaded: trigger == "initial" ? false: this.state.currentEnquiriesTableLoaded,
      typeBySourceChartLoaded: false,
      monthlyBreakDownChartLoaded: false,
      enquiryMode: mode,
    });

    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccEnrolmentProfileService.enquiries(params).then(res => res.json()).then((result) => {
        if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            currentEnquiriesData:result.data.result,
            tbsChart: result.data.result.charts.typeBySource[mode],
            mbdChart: result.data.result.charts.monthlyBreakDown[mode],
          }, () => {
            if(this.state.tbsChart.data.length > 0){
              this.setState({
                currentEnquiriesTableLoaded: true
              });
              this.drawTypeBySourceChart();
            } else {
              this.setState({
                currentEnquiriesTableLoaded: true,
                typeBySourceChartLoaded: false
              });
            }
  
            if(this.state.mbdChart.data.length > 0){
              this.setState({
                currentEnquiriesTableLoaded: true
              });
              this.drawMonthlyBreakDownChart();
            } else {
              this.setState({
                currentEnquiriesTableLoaded: true,
                monthlyBreakDownChartLoaded: false
              });
            }
          });
        }
      },(error) => {
        this.setState({
          currentEnquiriesTableLoaded: false,
          error
        });
      });
    }
  }

  currentEnquiriesOnChange = (mode) => {
    // const data = this.state.currentEnquiriesData;
    // this.setState({
      // tbsChart: data.charts.typeBySource[mode],
      // mbdChart: data.charts.monthlyBreakDown[mode],
      // enquiryMode: mode,
    // }, () => {
        // this.drawTypeBySourceChart();
        // this.drawMonthlyBreakDownChart();
    // });
    this.currentEnquiries(mode, "redraw");
  }

  drawTypeBySourceChart = () => {
    let gThis = this;
    const data = gThis.state.tbsChart;
    this.setState({
      typeBySourceChartOptions: {
        title: {
          text: ''
        },
        chart: {
          events: {
            load() {
              // this.showLoading();
              // setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:data.categories,
          title: {
            text: 'ENQUIRY TYPE'
          },
          min: 0,
          max: data.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: data.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'ENQUIRY COUNT'
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          formatter: function () {
            return `<b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>${this.y}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: data.data,
      }
    }, () => {
      this.setState({
        typeBySourceChartLoaded: true
      });
    });
  }

  drawMonthlyBreakDownChart = () => {
    let gThis = this;
    const data = gThis.state.mbdChart;
    this.setState({
      monthlyBreakdownChartOptions: {
        title: {
          text: ''
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:data.categories,
          title: {
            text: 'MONTH'
          },
          min: 0,
          max: data.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: data.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'ENQUIRY COUNT'
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          formatter: function () {
            return `<b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>${this.y}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: data.data,
      }
    }, () => {
      this.setState({
        monthlyBreakDownChartLoaded: true
      });
    })
  }

  leadCounts = () => {
    this.setState({
      leadCounts : [],
      leadCountsChartLoaded: false,
      leadCountChartLoad: true
    });

    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccEnrolmentProfileService.leadCount(params).then(res => res.json()).then((result) => {
        if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
          this.setState({
            leadCounts:result.data.result.leadCounts
          }, () => {
            if(this.state.leadCounts.series.length > 0){
              this.setState({
                leadCountsChartLoaded: true,
                leadCountChartLoad: true
              });
              this.drawLeadCountsChart();
            } else {
              this.setState({
                leadCountChartLoad: false
              });
            }
            
          });
        }
      },(error) => {
        this.setState({
          leadCountsChartLoaded: false,
          error
        });
      });
    }
  }

  drawLeadCountsChart = () => {
    let gThis = this.state;
    this.setState({
      leadCountsChartOptions: {
        title: {
          text: ''
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          }
      },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.leadCounts.categories,
          title: {
            text: 'STAGE'
          },
          min: 0,
          max: this.state.leadCounts.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.leadCounts.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'LEAD COUNT'
          }
          
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          formatter: function () {
            return `<b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>${this.y}</b>`
          }

        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.leadCounts.series,
      }
    }, () => {
      this.setState({
        leadCountsChartLoaded: true
      });
    })
  }


  render() {
    return (
      <Fragment>
        <div className="container-fluid " >    
          <div className="user-profile"> 
            <div className="row">
              <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                <div className="card custom-card  ">
                  <div className="row">
                    <div className="col-2 col-sm-2">
                      <img  src={accimage}></img>
                    </div>
                    <div className="col-5 col-sm-5 with-border">
                      <div className="student-name">
                        <h5><span className="counter">ENROLMENT ANALYSIS</span></h5>
                        <h4>{this.state.isStudentNameLoaded
                          ? this.state.studentName
                          : this.state.loader}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="principal-profile enrolment-profile">
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>SUMMARY</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.summaryCardsLoaded ?
                        <div className="row enrolment-summary-row">
                          {this.state.summaryCards.map((card,index) => {
                            return <div className="col-1" key={index}>
                              <div className="card">
                                <div className="card-header summary-header">
                                  <span>{card.label}</span>
                                </div>
                                <div className="card-body card-shadow card-custom-padding">
                                  <span className="card-value">{card.value}</span>
                                </div>
                              </div>
                            </div>
                          })}
                        </div>
                        : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>12 MONTH CONFIRMED ENROLMENTS</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.confirmedEnrolmentsTableLoaded ?
                        <Fragment>
                          <div className="row">
                            <div className="col-6">
                              <div className="card">
                                <div className={`card-body card-shadow card-custom-padding cursor-pointer ${this.state.enrolmentMode == "OC" ? "active-btn" : null }`} onClick={() => this.confirmedEnrolmentsOnChange("OC")}>
                                  <span className="card-text">OC ENROLMENTS</span> <span className="card-value">{this.state.confirmedEnrolmentsData.ocEnrolments}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card">
                                <div className={`card-body card-shadow card-custom-padding cursor-pointer ${this.state.enrolmentMode == "DE" ? "active-btn": null }`} onClick={() => this.confirmedEnrolmentsOnChange("DE")}>
                                  <span className="card-text">DE ENROLMENTS</span> <span className="card-value">{this.state.confirmedEnrolmentsData.deEnrolments}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table table-bordered enrolment-table enrolment-table-border">
                                  <thead>
                                    <tr>
                                      <th scope="col" key="GRADE">GRADE</th>
                                      {this.state.months.map(month => <th scope="col" key={month}>{month}</th>)}
                                      <th scope="col" key="TOTAL">TOTAL</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.confirmedEnrolmentsTable.map((grade,index) => {
                                          return <tr key={index}>
                                            <td className="blue-cell" key={grade.GRADE}>{grade.GRADE}</td>
                                            {
                                              this.state.months.map(month => <td scope="col" key={month} >{grade[month]}</td>)
                                            }
                                            <td key={grade.TOTAL}>{grade.TOTAL}</td>
                                          </tr>
                                      })
                                    }
                                    </tbody>
                                  </table>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                          : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STUDENT ENROLMENT PROJECTION 
                      <span> OC <Switch onChange={() => this.projectionOnChange("OC")} checked={this.state.projectionMode == "OC" ? true : false} /> &nbsp; DE <Switch onChange={() => this.projectionOnChange("DE")} checked={this.state.projectionMode == "DE" ? true : false} /> &nbsp; ALL <Switch  onChange={() => this.projectionOnChange("ALL")} checked={this.state.projectionMode == "ALL" ? true : false} /></span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.enrolmentProjectionTableLoaded ?
                        <Fragment>
                          <div className="row">
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table table-bordered enrolment-table enrolment-table-border">
                                  <thead>
                                    <tr>
                                      <th scope="col">GRADE</th>
                                      {this.state.enrolmentProjectionColumns.map(column => <th scope="col" key={column}>{column}</th>)}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.enrollmentProjectionTable.map((enrolment, ind) => {
                                      return <tr key={ind}>
                                        {this.state.enProjectionDataIndexes.map(index => 
                                          <td scope="col" key={index} className={`${index == 0 ? "blue-cell":null}`}>{enrolment[index] !== "" ? enrolment[index] : "-"}</td>
                                        )}
                                      </tr>
                                    })}
                                  </tbody>
                                </table>
                              </div>
                           </div>
                          </div>
                        </Fragment>
                          : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>CURRENT ENQUIRIES</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.currentEnquiriesTableLoaded ?
                        <Fragment>
                          <div className="row">
                            <div className="col-6">
                              <div className="card">
                                <div className={`card-body card-shadow card-custom-padding cursor-pointer ${this.state.enquiryMode == "OC" ? "active-btn" : null }`} onClick={() => this.currentEnquiriesOnChange("OC")}>
                                  <span className="card-text">OC ENQUIRIES YTD</span> <span className="card-value">{this.state.currentEnquiriesData.OCYTD}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card">
                                <div className={`card-body card-shadow card-custom-padding cursor-pointer ${this.state.enquiryMode == "DE" ? "active-btn": null }`} onClick={() => this.currentEnquiriesOnChange("DE")}>
                                  <span className="card-text">DE ENQUIRIES YTD</span> <span className="card-value">{this.state.currentEnquiriesData.DEYTD}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="card">
                                <div className="card-header card-header-blue">
                                  <h5>ENQUIRY TYPES BY SOURCE</h5>
                                </div>
                                <div className="card-body">
                                  <div className="user-status">
                                    <div className="col-12">
                                      {this.state.typeBySourceChartLoaded ?
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={this.state.typeBySourceChartOptions}
                                          updateArgs={[true]}
                                        />
                                      : this.state.typeBySourceChartLoaded ?
                                        <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                                        :<div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header card-header-blue">
                                  <h5>ENQUIRIES - MONTHLY BREAKDOWN</h5>
                                </div>
                                <div className="card-body">
                                  <div className="user-status">
                                    <div className="col-12">
                                      {this.state.monthlyBreakDownChartLoaded ?
                                        <HighchartsReact
                                          highcharts={Highcharts}
                                          options={this.state.monthlyBreakdownChartOptions}
                                        />
                                      : this.state.monthlyBreakDownChartLoaded ?
                                        <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                          : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>CURRENT LEAD COUNTS</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.leadCountsChartLoaded ?
                        <Fragment>
                          <div className="col-12">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.leadCountsChartOptions}
                            />
                          </div>
                        </Fragment>
                        :this.state.leadCountChartLoad ?
                          <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default PrincipalProfileWrapper(withRouter(EnrolmentProfile));