import React, { useEffect, useState } from "react";
import { Tag, Form, Checkbox, Row, Col, Button } from "antd";
import { useDispatch } from "react-redux";
import PopUpModal from "../../../common/popup-modal/popup-modal";
import {
  onAddUserSubmit,
  searchedUserData,
  validateUserLoading,
} from "../../../../actions/userManagement/userManagement.action";
import UserSearch from "./userSearch";
import SelectAccount from "./selectAccount";

const AddUserPopUpModal = ({ isModalVisible, setIsModalVisible }) => {
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [consentGranted, setConsentGranted] = useState(null);

  useEffect(() => {
    isModalVisible && dispatch(validateUserLoading(false));
  }, [isModalVisible]);

  const emptySearchData = () => {
    setConsentGranted(null);
    setValidationModalOpen(false);
    dispatch(searchedUserData({
      response: {
        data: {
          data: [],
        },
      }
    }));
    setSelectedUsers([]);
  }

  const onModalSubmit = () => {
    if (selectedUsers.length) {
      dispatch(onAddUserSubmit({ 
        selectedUsers,
        consentGranted,
      }));
    }
    emptySearchData();
  };

  const onDeselectUserTag = removedUser => {
    const removedSelectedUserArray = selectedUsers.filter(user =>
      user.userId !== removedUser.userId);
    setSelectedUsers(removedSelectedUserArray);
  };

  const renderTag = data => {
    if (data.sis_id) {
      return `${data.name}(${data.sis_id})`
    }

    return data.name;
  };

  const handleSubmit = () => {
    onModalSubmit();
    setIsModalVisible(false);
  };

  const RenderCustomFooter = () => {
    return ([
      <Col>
        <Row>
          <Col
            span={2}
            className="addUserPopUpFooter"
          >
            <Checkbox
              checked={consentGranted}
              defaultChecked={false}
              onChange={(e) => setConsentGranted(e.target.checked)}
            />
          </Col>
          <Col
            span={16}
            className="addUserPopUpFooter"
          >
            I am aware that changes to Canvas roles may not be updated in the application.
          </Col>
          <Col
            span={6}
            className="addUserPopUpFooterButtonCol"
          >
            <Button
              onClick={() => handleSubmit()}
              disabled={(!selectedUsers.length || !consentGranted)}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Col>
    ])
  }

  return (
    <PopUpModal
      title={"Add Users"}
      setIsModalVisible={setIsModalVisible}
      isModalVisible={isModalVisible}
      onCancel={emptySearchData}
      footerComponent={RenderCustomFooter}
    >
      <div className="addUserPopup">
        <Form.Item label="Select Account" >
          <SelectAccount
            setSelectedAccount={setSelectedAccount}
          />
        </Form.Item>
        <Form.Item label="Select Users" >
          <UserSearch
            selectedAccount={selectedAccount}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            isModalVisible={isModalVisible}
            validationModalOpen={validationModalOpen}
            setValidationModalOpen={setValidationModalOpen}
          />
          <div className="tagList">
            {selectedUsers && selectedUsers.map(data => (
              <Tag
                key={data.userId}
                closable
                onClose={e => { onDeselectUserTag(data) }}>
                {renderTag(data)}
              </Tag>
            )
            )}
          </div>
        </Form.Item>
      </div>
    </PopUpModal >
  )
}

export default AddUserPopUpModal;
