import service from "../customServices/impression";
import impressionData from "./impressionData";
import session from "../session";

const getImpressionData = (key) => {

    const values = impressionData[key];

    if (!values){
        return console.warn("Invalid Impression Key")
    }

    return values;
}

const sendImpressionData = (key) => {
    // impression data send handler.
    // used in dashboards to send impressions
    const data = getImpressionData(key);
    const userToken = session.get(session.keys.userAccessMeta);

    if(data){
        service.make({
            service:`statistics/impression/save`,
            authorized: true,
            method: "POST",
            data:{...data, ...userToken},
            showErrorNotification: false
        }).catch(error => {
            console.error(`Impression send failed: ${error}`)
        })
    }else{
        console.warn("No impression data to send")
    }
}

const sendUserAccessData = (data, resolve=false, reject=false) => {
    // user access data send handler
    // used to record accessed users in the database
    service.make({
        service:`statistics/access/save`,
        authorized: true,
        method: "POST",
        data,
        showErrorNotification: false
    }).then(response => {
        session.set(session.keys.userAccessMeta, {token: response.data?.token});
        resolve && resolve()
    }).catch(error => {
        console.error(`Impression send failed: ${error}`)
        reject && reject()
    })
}

const sendUserAccessDataAsync = (data) => {
    // Async user access data send handler
    // used to record accessed users in the database
    return new Promise((resolve, reject) => {
        sendUserAccessData(data, resolve, reject)
    } )
}

const impressionHandler = {
    sendImpressionData,
    sendUserAccessData,
    sendUserAccessDataAsync,
}

export default impressionHandler;