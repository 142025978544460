import React, {Fragment} from "react";
import {Card, Col, PageHeader, Row, Typography} from "antd";


import ScoreToGradeConversion from "./partials/ScoreToGradeConversion";

const {Title} = Typography;

const ConfigurationView = () => {

    return (
        <Fragment>
            <Row gutter={100}>
                <Col span={24}>
                    <PageHeader
                        className="site-page-header"
                    />
                    <Card>
                        <Title level={3}>Config Report</Title>
                        <br/>
                        <div>
                            <Title level={5}>Config Measurements</Title>
                            <Card>
                                <ScoreToGradeConversion/>
                            </Card>
                        </div>
                        <br/>

                    </Card>
                </Col>
            </Row>
        </Fragment>
    )

}

export default ConfigurationView;