import React from "react";

import PillTabs from "./partials/Tabs";

// dashboards
import DealershipTrainingUsers from "./partials/dealershipTraning";

const VwUserManagementView = () => {

    return (
        <div>
            <PillTabs tabs={[DealershipTrainingUsers]}/>
        </div>
    )
}

export default VwUserManagementView;