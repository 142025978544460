import React, {useEffect} from "react";
import {Form} from "antd";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";

import StatDataView from "../../stats/partials/statDataView/StatDataView";
import menuData from "../../../layout/dashboardManagement/menuData";

import {setBreadcrumb} from "../../../../actions/dashboardManagement/layout.action"
import {fetchStatsDataSales} from "../../../../actions/dashboardManagement/stats.action";
import {getStatsReportData, getStatsReportDataLoading} from "../../../../selectors/dashboardManagement/stats.selector";

const AdminStatView = () => {
    const {viewId} = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const historyState = useLocation().state;

    //selectors
    const reportData = useSelector(getStatsReportData);
    const reportDataLoading = useSelector(getStatsReportDataLoading);

    const breadcrumbItems = [
        {
            key: menuData.manageTenantAdmin.key,
            section: menuData.manageTenantAdmin.name,
            href: menuData.manageTenantAdmin.url
        }, {
            key: "stats",
            section: "Stats",
        },{
            key: menuData.statsDataViewSales.key,
            section: menuData.statsDataViewSales.name,
        }
    ]



    const fetchStatData = () => {
        const {dateRange} = form.getFieldsValue();
        const statParams = {
            school_id:viewId,
            start_date:dateRange[0].format("YYYY/MM/DD"),
            end_date:dateRange[1].format("YYYY/MM/DD")
        }
        dispatch(fetchStatsDataSales(statParams))
    }

    const filtersOnSubmit = () => {
        fetchStatData();
    }

    //set breadcrumb
    useEffect(() => {
        form.setFieldsValue({
            dateRange: [moment(historyState && historyState.start), moment(historyState && historyState.end)],
        });
        fetchStatData();
        dispatch(setBreadcrumb(breadcrumbItems))
    }, [])

    return (
        <StatDataView filterForm={form} filterSubmit={filtersOnSubmit} reportData={reportData} reportDataLoading={reportDataLoading}/>
    )
}

export default AdminStatView;