import {
    JR_GEN_FETCH_USER_PERMISSIONS,
    JR_GEN_FETCH_USER_PERMISSIONS_LOADING,
    JR_GEN_SETTINGS_ALTERNATION,
    JR_GEN_SETTINGS_RESET_EDIT_MODE,
    JR_GEN_SETTINGS_DISCARD_DRAFT,
    JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING
} from "../../../constant/actionTypes";

const defaultState = {
    isEdited: {
        state: false,
        section: null,
        subSection: null
    },
    permissions: {
        isLoading: false,
        data: {}
    },
    discardDraft: {
        isLoading: false,
    }
}

const commonReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_FETCH_USER_PERMISSIONS:
        case JR_GEN_FETCH_USER_PERMISSIONS_LOADING:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    ...action.payload
                }
            }
        case JR_GEN_SETTINGS_DISCARD_DRAFT:
        case JR_GEN_SETTINGS_DISCARD_DRAFT_LOADING:
            return {
                ...state,
                discardDraft: {
                    ...state.discardDraft,
                    ...action.payload
                }
            }
        case JR_GEN_SETTINGS_ALTERNATION:
            return {
                ...state,
                isEdited: {
                    state: true,
                    section: action.section,
                    subSection: action.subSection
                }
            }
        case JR_GEN_SETTINGS_RESET_EDIT_MODE:
            return {
                ...state,
                isEdited: {...defaultState.isEdited}
            }
        default:
            return state
    }
}

export default commonReducer;