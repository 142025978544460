import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    makeCanvasKeysError,
    makeCanvasKeysLoading,
    makeCanvasKeysResponseData
} from "../../../../../../selectors/canvasSetup.selector";
import {setupCanvas} from "../../../../../../actions/signup.action";
import StepUrlConfig from "../../views/StepUrlConfig";

const UrlConfigContainer = forwardRef((
    {
        goToNext,
        setIsFormLoading,
        stepStatus,
        updateStepStatus
    },
    ref
) => {
    const dispatch = useDispatch();
    const {token} = useParams();
    const keysLoading = useSelector(makeCanvasKeysLoading);
    const keysResult = useSelector(makeCanvasKeysResponseData);
    const keysError = useSelector(makeCanvasKeysError);
    const [setupRequestStatus, setSetupRequestStatus] = useState(false);

    useImperativeHandle(ref, () => ({
        onSubmit,
    }));

    useEffect(() => {
        if (setupRequestStatus) {
            setTimeout(() => setIsFormLoading(false), 500);

            if (!keysError && !keysLoading && setupRequestStatus) {
                updateStepStatus(true);
                goToNext();
            }
        }
    }, [keysResult, keysLoading, keysError]);

    const onSubmit = ({values}) => {
        //encode the URL before pass to backend
        if(values.url){
            values.url = encodeURIComponent(values.url);
        }
        setIsFormLoading(true);
        setSetupRequestStatus(true);
        const {url} = values;

        // if this step is completed already, then skip request and go to next page
        if (stepStatus) {
            setIsFormLoading(false);
            return goToNext();
        }

        dispatch(setupCanvas({
            requestParams: {
                token,
                url
            }
        }));
    };

    return <StepUrlConfig readOnly={stepStatus}/>
});

UrlConfigContainer.displayName = "UrlConfigContainer";

UrlConfigContainer.propTypes = {
    goToNext: PropTypes.func,
    setIsFormLoading: PropTypes.func,
    stepStatus: PropTypes.bool,
    updateStepStatus: PropTypes.func,
};

export default UrlConfigContainer;