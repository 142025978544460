import React from "react";
import {Row, Space} from "antd";
import PropTypes from "prop-types";

import CardList from "./partials/cardList/CardList";
import DataTable from "../dataTable";
import CustomSelector from "../../../common/CustomSelect";


const OnlineModule = ({departments, onChange, cards, showDataTable, dataTableConfig, studentsDataLoading, tableData}) => {

    return (
        <div className="online-module-main">
            <Space style={{ width: "100%" }} direction="vertical" size="large">
                <Row>
                    <div>
                        <h5>Department</h5>
                        <CustomSelector
                            showSearch
                            showArrow
                            allowClear
                            mode="multiple"
                            maxTagCount={1}
                            listHeight={500}
                            onChange={onChange}
                            value="departmentName"
                            paramKey="departmentId"
                            width={200}
                            datalist={departments}
                            setDefaultData={true}
                        />
                    </div>
                </Row>
                <Row style={{ width: "100%" }}>
                    <CardList items={cards} onDataTableShowClick={dataTableConfig.onShowDataTable}/>
                </Row>
            </Space>
            <DataTable
                visible={showDataTable}
                handleCancel={dataTableConfig.handleCancel}
                isLoading={studentsDataLoading}
                data={tableData}
            />
        </div>
    )
}

OnlineModule.propTypes = {
    departments: PropTypes.array,
    onChange: PropTypes.func,
    cards: PropTypes.array,
    showDataTable: PropTypes.bool,
    dataTableConfig: PropTypes.object,
    studentsDataLoading: PropTypes.bool,
    tableData: PropTypes.object,
}

export default OnlineModule;