import { useLayoutEffect, useState, useEffect, useRef } from "react";
import * as d3 from 'd3';

export const useWindowSize = (breakWidth) => {
  const [size, setSize] = useState([0, 0, false]);
  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isMobile = window.innerWidth < breakWidth

      setSize([width, height, isMobile]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useD3 = (renderChartFn, dataCheck, labelStatus, fullWidth) => {
  const ref = useRef();
  // need to handel this useEffect perfectly because it re-render the entire chart
  useEffect(() => {
    const svgViewPort = d3.select(ref.current);
    svgViewPort.selectAll('*').remove();
    renderChartFn(svgViewPort);
    return () => {};
  }, [JSON.stringify(dataCheck), labelStatus, fullWidth]);
  return ref;
};