import React from "react";

export const AI_QUESTION_FORMATS = "Question Formats";
export const AI_GUID_INFO_ARRAY = [
  {
    title: "Parameters",
    info: [
      <p>&#x2022; Sections: <span className="section-highlight">#section_name</span></p>,
      <p>&#x2022; Assignments: <span className="assignment-highlight">@assignment_name</span></p>,
      <p>&#x2022; Students: <span className="student-highlight">@student_name</span></p>,
      <p>&#x2022; Course: <span className="course-highlight">#course_name</span></p>,
    ]
  },
  {
    title: AI_QUESTION_FORMATS,
    info: [
      <p>1. Course-Related: Include <span className="course-highlight">#course_name</span>.</p>,
      <p>2. Student-Course-Focused: Add <span className="course-highlight">#course_name</span>, <span className="student-highlight">@student1</span>, and <span className="student-highlight">@student2</span>.</p>,
      <p>3. Section-Course-Focused: Use <span className="course-highlight">#course_name</span>, <span className="section-highlight">#section1</span>, and <span className="section-highlight">#section2</span>.</p>,
      <p>4. Assignment-Course-Focused: Mention <span className="course-highlight">#course_name</span>, <span className="assignment-highlight">@assignment1</span>, and <span className="assignment-highlight">@assignment2</span>.</p>,
    ]
  },
  {
    title: "Examples",
    info: [
      <p>&#x2022; General: "What's the exam format for <span className="course-highlight">#course_name</span>?</p>,
      <p>&#x2022; Students: "How is <span className="student-highlight">@student1</span> doing in <span className="course-highlight">#course_name</span>?</p>,
      <p>&#x2022; Sections: "What topics are covered in <span className="section-highlight">#section1</span> of <span className="course-highlight">#course_name</span>?</p>,
      <p>&#x2022; Assignments: "What are the guidelines for <span className="assignment-highlight">@assignment1</span> in <span className="course-highlight">#course_name</span>?</p>,
    ]
  }
];

const SUPPORT_EMAIL = process.env.REACT_APP_CLUSTER_ANALYZER_SUPPORT_EMAIL;
export const AI_DISCLAIMER_INFO_ARRAY = [
  { title: "AI Generator Usage:", info: "The Application incorporates an AI generator that generates text-based content, including but not limited to responses, suggestions, and recommendations. This content is generated based on patterns and data processed by the AI model.", },
  { title: "Accuracy and Reliability:", info: "While we strive to provide accurate and reliable content generated by our AI model, it may not always be error-free or up to date. Users should exercise discretion and verify information obtained from the AI generator, as it may not always reflect the most current data or context." },
  { title: "No Legal or Professional Advice:", info: "The content generated by the AI generator is not a substitute for professional advice or any other specialized expertise. Users should consult with qualified professionals for specific guidance & verification and not solely rely on the AI-generated content." },
  { title: "User Responsibility:", info: "Users of the Application are solely responsible for the use and interpretation of content generated by the AI. Octopus BI shall not be held liable for any actions, decisions, or consequences resulting from the use of the AI-generated content." },
  { title: "Privacy and Data Responsibility:", info: "Users are responsible for the data they input into the Application and any consequences arising from sharing sensitive or personal information. While Octopus BI is committed to protecting user privacy, users must exercise caution when providing personal data in the AI. Furthermore, Octopus BI strongly suggest users to not to enter any inputs that contains sensitive or personal information." },
  { title: "Updates and Changes:", info: "Octopus BI reserves the right to modify, update, or discontinue the AI generator or the Application at any time, without prior notice. We are not obligated to provide ongoing access to the AI generator." },
  { title: "Acceptance of Terms:", info: "By using the Application, users acknowledge and accept the terms and conditions of this Disclaimer. If you do not agree with any part of this Disclaimer, we advise against using the Application." },
  {
    title: "Contact Information:", info: <p>For questions, concerns, or inquiries related to this Disclaimer or the Application, please contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
      <br /><br />By using the Application and the AI generator, you agree to be bound by the terms and conditions outlined in this Disclaimer. We recommend reviewing this Disclaimer periodically for any updates or changes.<br /><br />Last updated: {process.env.REACT_APP_CLUSTER_ANALYZER_DISCLAIMER_LAST_UPDATE}
    </p>
  },
];
