import _get from "lodash/get";

// return jr gen user response
export const makeJrGenUserDataResponse = state => _get(state, 'jrDashboardManage.user.jrGenUser');

// return dashboard selections
export const makeDashboardSelections = state => {
    const result = makeJrGenUserDataResponse(state);
    return result && result.dashboards;
};

// return dashboard selections loading
export const makeDashboardSelectionsLoading = state => {
    const result = makeDashboardSelections(state);
    return result && result.isLoading;
};

// return dashboard selections data
export const makeDashboardSelectionsData = state => {
    const result = makeDashboardSelections(state);
    return result && _get(result, "data.dashboards", []);
};

// return selected dashboard
export const makeSelectedDashboard = state => {
    const result = makeDashboardSelections(state);
    return result && result.selected;
};

// return user list
export const makeUserList = state => {
    const result = makeJrGenUserDataResponse(state);
    return result && result.list;
};

// return user list loading
export const makeUserListLoading = state => {
    const result = makeUserList(state);
    return result && result.isLoading;
};

// return user list data
export const makeUserListData = state => {
    const result = makeUserList(state);
    return result && _get(result, "data.users", []);
};

// return add user
export const makeAddUser = state => {
    const result = makeJrGenUserDataResponse(state);
    return result && result.added;
};

// return add user loading
export const makeAddUserLoading = state => {
    const result = makeAddUser(state);
    return result && result.isLoading;
};

// return delete user
export const makeDeleteUser = state => {
    const result = makeJrGenUserDataResponse(state);
    return result && result.deleted;
};

// return delete user loading
export const makeDeleteUserLoading = state => {
    const result = makeDeleteUser(state);
    return result && result.isLoading;
};

// return reset user password
export const makeResetUserPassword = state => {
    const result = makeJrGenUserDataResponse(state);
    return result && result.resetPassword;
};

// return reset user password loading
export const makeResetUserPasswordLoading = state => {
    const result = makeResetUserPassword(state);
    return result && result.isLoading;
};




