import {
    JR_GEN_CATEGORY_OPTIONS_FETCH,
    JR_GEN_CATEGORY_OPTIONS_FETCH_LOADING
} from "../../../constant/actionTypes";
import service from "../../../utils/serviceHandlers/jobReady/customServices/jrGeneric";

/** Fetch risk calculations category drawer options **/
// fetch risk calculations category drawer options loading
const fetchRcCategoryOptionsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_CATEGORY_OPTIONS_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch risk calculations category drawer options handler
const fetchRcCategoryOptionsDataStatus = (response, type) => dispatch => {
    dispatch({
        type: JR_GEN_CATEGORY_OPTIONS_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && response.data),
            type
        },
    });
};

// fetch risk calculations category drawer options
export const fetchRcCategoryOptions = (data) => dispatch => {
    dispatch(fetchRcCategoryOptionsLoading(true, data));

    service.make({
        service:`insights/jrp/generic/settings/fetchCategoricalFactor`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchRcCategoryOptionsDataStatus({
            data: response.data
        }, data))
    }).catch(error => {
        dispatch(fetchRcCategoryOptionsDataStatus({
            error: error.message,
        }, data))
    })
};