import {
  VTT_SET_SELECTED_DASHBOARD,
  VTT_FETCH_DASHBOARD_SELECTIONS,
  VTT_FETCH_DASHBOARD_SELECTIONS_LOADING,
  VTT_FETCH_DASHBOARD_USERS,
  VTT_FETCH_DASHBOARD_USERS_LOADING,
  VTT_ADD_NEW_DASHBOARD_USER,
  VTT_ADD_NEW_DASHBOARD_USER_LOADING,
  VTT_DELETE_DASHBOARD_USER,
  VTT_DELETE_DASHBOARD_USER_LOADING,
  VTT_USER_RESET_PASSWORD,
  VTT_USER_RESET_PASSWORD_LOADING,
} from '../../../../constant/actionTypes';

const initialState = {
  dashboards: {
    isLoading: false,
    error: false,
    data: {},
    selected: false,
  },
  list: {
    isLoading: false,
    error: false,
    data: [],
  },
  selected: {
    isLoading: false,
    error: false,
    data: {},
  },
  added: {
    isLoading: false,
  },
  deleted: {
    isLoading: false,
  },
  updated: {
    isLoading: false,
  },
  resetPassword: {
    isLoading: false,
  },
  setPermissions: {
    isLoading: false,
  },
};

const vttUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case VTT_SET_SELECTED_DASHBOARD:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          selected: action.payload,
        },
      };
    case VTT_FETCH_DASHBOARD_SELECTIONS:
    case VTT_FETCH_DASHBOARD_SELECTIONS_LOADING:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          ...action.payload,
        },
      };
    case VTT_FETCH_DASHBOARD_USERS:
    case VTT_FETCH_DASHBOARD_USERS_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    case VTT_ADD_NEW_DASHBOARD_USER:
    case VTT_ADD_NEW_DASHBOARD_USER_LOADING:
      return {
        ...state,
        added: {
          ...state.added,
          ...action.payload,
        },
      };
    case VTT_DELETE_DASHBOARD_USER:
    case VTT_DELETE_DASHBOARD_USER_LOADING:
      return {
        ...state,
        deleted: {
          ...state.deleted,
          ...action.payload,
        },
      };
    case VTT_USER_RESET_PASSWORD:
    case VTT_USER_RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default vttUserReducer;
