import {
    FETCH_DASHBOARD_2FA_CHECK_LOADING,
    FETCH_DASHBOARD_2FA_CHECK,
    FETCH_DASHBOARD_OTP_VALIDATE_LOADING,
    FETCH_DASHBOARD_OTP_VALIDATE,
} from "../../constant/actionTypes";

const twoFaAuthReducerDefaultState = {
    check: {
        isLoading: false,
        error: false,
    },
    auth: {
        isLoading: false,
        error: false,
    },
};

const twoFaAuthReducer = (state = twoFaAuthReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_2FA_CHECK_LOADING:
            return {
                ...state,
                check: {
                    ...state.check,
                    ...action.payload
                }
            }
        case FETCH_DASHBOARD_2FA_CHECK:
            return {
                ...state,
                check: {
                    ...state.check,
                    ...action.payload
                }
            }
        case FETCH_DASHBOARD_OTP_VALIDATE_LOADING:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...action.payload
                }
            }
        case FETCH_DASHBOARD_OTP_VALIDATE:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default twoFaAuthReducer;