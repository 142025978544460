import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, InputNumber, Space, List, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import _startCase from 'lodash/startCase';

import SettingColorPicker from '../ColorPicker';

import {
  subSections,
  academicSections,
  financialSections,
  complianceSections,
} from '../../../../../../../constant/vetTrack/analysis';

const { Panel } = Collapse;
const { Text } = Typography;

const MeasurementIndicatorsView = ({
  factors,
  subSection,
  onValueChange,
  hasPermission,
  overallData,
  errors,
}) => {
  const configMapper = {
    [subSections.academic.key]: academicSections,
    [subSections.financial.key]: financialSections,
    [subSections.compliance.key]: complianceSections,
    [subSections.wellBeing.key]: {},
  };

  const getFieldStyles = (factorKey, type, key) => {
    const errorKey = `${factorKey}_${type}_${key}`;

    if (errors[errorKey]) {
      return { 
        width: 70,
        border: '1px solid #f5222d',
      };
    }

    return { width: 70 };
  }

  const renderMeasurementContent = (type, factor) => (
    <List.Item>
      <div>
        <Space>
          <InputNumber
            disabled={!hasPermission}
            min={0}
            max={100}
            style={getFieldStyles(factor.key, type, 'min')}
            onChange={value => onValueChange(factor.key, type, 'min', value)}
            value={factor[type]?.min}
          />
          <span>&amp;</span>
          <InputNumber
            disabled={!hasPermission}
            min={0}
            max={100}
            style={getFieldStyles(factor.key, type, 'max')}
            onChange={value => onValueChange(factor.key, type, 'max', value)}
            value={factor[type]?.max}
          />
        </Space>
      </div>
      <div>
        <Space>
          <div style={{ width: 70 }}>
            <SettingColorPicker 
              disabled
              value={overallData[type]?.color}
              text={_startCase(type)}
            />
          </div>
        </Space>
      </div>
    </List.Item>
  );

  const renderPanelBody = factor => (
    <div>
      <List
        size="small"
        header={
          <div className="ri-measurement-header">
            <div>{factor.name} Range</div>
            <div>Colour</div>
          </div>
        }
        bordered
      >
        {renderMeasurementContent('low', factor)}
        {renderMeasurementContent('medium', factor)}
        {renderMeasurementContent('high', factor)}
      </List>
    </div>
  );

  const getPannelHeader = factor => {
    const { key, name } = factor;
    const errorKeys = Object.keys(errors); 
    const hasErrors = errorKeys.find(errorKey => errorKey.includes(key));

    if (hasErrors) {
      return (
        <div>
          <Text strong>{name}</Text>
          <WarningOutlined 
            style={{ color: '#f5222d', float: 'right', marginTop: '3px' }} 
          />
        </div>
      );
    }

    return <Text strong>{name}</Text>;
  };

  return (
    <Collapse expandIconPosition="right" accordion >
      {Object.keys(factors)
        .sort()
        .map(key => {
          const constDataKey = Object.keys(configMapper[subSection]).find(
            secKey => configMapper[subSection][secKey].key === key,
          );
          const factorData = {
            ...factors[key],
            ...configMapper[subSection][constDataKey],
          };
          return (
            <Panel
              header={getPannelHeader(factorData)}
              key={factorData.factor_id}
            >
              {renderPanelBody(factorData)}
            </Panel>
          );
        })}
    </Collapse>
  );
};

MeasurementIndicatorsView.propTypes = {
  factors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  subSection: PropTypes.string,
  onValueChange: PropTypes.func,
  hasPermission: PropTypes.bool,
  overallData: PropTypes.object,
  errors: PropTypes.object,
};

export default MeasurementIndicatorsView;
