import {
  VTT_FETCH_MY_DASHBOARDS_LOADING,
  VTT_FETCH_MY_DASHBOARDS,
  VTT_SAVE_DASHBOARD,
  VTT_SAVE_DASHBOARD_LOADING,
  VTT_FETCH_DASHBOARD_VALIDITY,
  VTT_FETCH_DASHBOARD_VALIDITY_LOADING,
} from '../../../constant/actionTypes';
import service from '../../../utils/serviceHandlers/jobReady/customServices/jrDashboardManagement';
import { onSuccess } from '../../../utils/notificationHandler';

/** My Dashboards fetch * */
// fetch dashboards
const fetchingDashboardsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FETCH_MY_DASHBOARDS_LOADING,
    payload: { isLoading },
  });
};

// fetch dashboards handler
const fetchDashboardsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FETCH_MY_DASHBOARDS,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch dashboards
export const fetchDashboards = data => dispatch => {
  dispatch(fetchingDashboardsLoading());

  service
    .make({
      service: `insights/jrp/tenant_admin/dashboards`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchDashboardsDataStatus({
          data: response,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchDashboardsDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Dashboard save * */
// save dashboard loading
const savingDashboardsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_SAVE_DASHBOARD_LOADING,
    payload: { isLoading },
  });
};

// save dashboard handler
const saveDashboardDataStatus = response => dispatch => {
  dispatch({
    type: VTT_SAVE_DASHBOARD,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// save dashboard
export const saveDashboard = data => dispatch => {
  dispatch(savingDashboardsLoading());

  service
    .make({
      service: `insights/jrp/tenant_admin/dashboard/save`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        saveDashboardDataStatus({
          data: response,
        }),
      );
      onSuccess('Saved Successfully!');
      dispatch(fetchDashboards());
    })
    .catch(error => {
      dispatch(
        saveDashboardDataStatus({
          error: error.message,
        }),
      );
      onSuccess('Something went wrong.');
    });
};

/** Fetch Dashboard Validity * */
// fetch dashboard validity loading
const fetchingDashboardValidityLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_VALIDITY_LOADING,
    payload: { isLoading },
  });
};

// fetch dashboard validity handler
const fetchDashboardValidityStatus = response => dispatch => {
  dispatch({
    type: VTT_FETCH_DASHBOARD_VALIDITY,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch dashboard validity
export const fetchDashboardValidity = data => dispatch => {
  dispatch(fetchingDashboardValidityLoading());

  service
    .make({
      service: `insights/jrp/tenant_admin/dashboard/validity`,
      method: 'POST',
      redirection: false,
      data,
    })
    .then(response => {
      dispatch(
        fetchDashboardValidityStatus({
          data: response,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchDashboardValidityStatus({
          error: error.message,
        }),
      );
    });
};
