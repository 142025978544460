import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Form } from "antd";
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { deleteDeveloperKey } from "../../../../../../actions/dashboardManagement/dashboard.action";
import PopUpModal from "../../../../../common/popup-modal/popup-modal";

const ClearDevKeyModal = ({ setIsModalVisible, isModalVisible ,consumerId }) => {
  const dispatch = useDispatch();
  const payload ={
    "type": "devToken",
    "consumer": consumerId,
}
  const OnClearDeveloperKey = () => { 
    dispatch(deleteDeveloperKey(payload));
  };

    return (
        <PopUpModal
            title={"Alert"}
            okText={"OK"}
            onPressOk={OnClearDeveloperKey}
            cancelText={"Cancel"}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
        >
            <div className="addDeveloperKey">   
               <p>Are you sure to clear developer keys ?</p>
            </div>
        </PopUpModal>
    )
}

export default ClearDevKeyModal;
