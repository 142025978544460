import React, {memo} from "react";
import {Layout} from "antd";
import PropTypes from "prop-types";

import Sidebar from "./partials/sidebar";
import PillTabs from "./partials/Tabs";
import OnlineModule from "./partials/onlineModule";
import QualificationPathway from "./partials/qualificationPathway";
import Loader from "../common/layout/Loader";

import LayoutFooter from "../common/layout/LayoutFooter";

const { Content } = Layout;

const DealershipView = ({isLoading}) => {
    return (
        <div>
            <Loader isLoading={isLoading}/>
                <Layout style={{ minHeight: '100%' }}>
                    <Sidebar/>
                    <Layout className="site-layout dealership-main-container" style={{backgroundColor:"white"}}>
                        <Content style={{ margin: '0 16px' }}>
                            <span className="vw-title">Dealership Training Dashboard</span>
                            <PillTabs tabs={[OnlineModule, QualificationPathway]}/>
                        </Content>
                        <LayoutFooter/>
                    </Layout>
                </Layout>
        </div>
    )
}

DealershipView.propTypes = {
    isLoading: PropTypes.bool
}

export default memo(DealershipView);