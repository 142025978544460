import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Button, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {getDashboardFiltersSelectedData} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {
    getAcademicOverviewAttendanceResponse,
    getAcademicOverviewData,
    getAcademicOverviewDisabilitiesResponse,
    getAcademicOverviewLoading,
    getAcademicOverviewRequirementMetResponse,
    getAcademicOverviewTargetNotCompletedResponse,
    getAcademicOverviewUnitProgressResponse
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/academic.selector";
import {
    fetchAcademicDisabilitiesOverview,
    fetchAcademicOverview,
    fetchAcademicOverviewAttendance,
    fetchAcademicOverviewTargetNotCompleted,
    fetchAcademicOverviewUnitProgress,
    fetchAcademicRequirementMetOverview
} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/academic.action";

import Overview from "./Overview";
import PopupTable from "../../../../../common/popupTable/PopupTable";
import StudentDetailCell from "../../../../../common/StudentDetailCell";
import ProgressBarCell from "../../../../../common/ProgressBarCell";

import {getUniqValuesForFilters} from "../../../../../../../../utils/general";
import {subSections} from "../../../../../../../../../../constant/jobReadyGeneric/constants";

const OverviewTab = () => {
    const dispatch = useDispatch();
    const [selectedKey, setSelectedKey] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState({});

    // selectors
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const overviewDataLoading = useSelector(getAcademicOverviewLoading);
    const overviewData = useSelector(getAcademicOverviewData);
    const disabilitiesData = useSelector(getAcademicOverviewDisabilitiesResponse);
    const requirementMetData = useSelector(getAcademicOverviewRequirementMetResponse);
    const targetNotCompletedData = useSelector(getAcademicOverviewTargetNotCompletedResponse);
    const unitProgressData = useSelector(getAcademicOverviewUnitProgressResponse);
    const attendanceData = useSelector(getAcademicOverviewAttendanceResponse);

    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading){
            const {academic} = subSections;
            const data = {
                filters: selectedFilters,
                subSectionId: academic.id
            }
            dispatch(fetchAcademicOverview(data));
        }
    }, [selectedFilters])

    const onPopupOpen = (key) => {
        setShowPopup(true);
        setSelectedKey(key);
        const selectedData = tableConfigMapper[key];

        const {academic} = subSections;
        const data = {
            filters: selectedFilters,
            subSectionId: academic.id,
        }

        selectedData.dispatcher && dispatch(selectedData.dispatcher(data));
        setSelectedConfig(selectedData);
    }

    const onPopupClose = () => {
        setShowPopup(false);
        setSelectedKey(null);
        setSelectedConfig({});
    }

    const renderProgressCellFilters = () => (
        [
            {
                text: "High",
                value: "High",
            },
            {
                text: "Medium",
                value: "Medium",
            },
            {
                text: "Low",
                value: "Low",
            },
        ]
    )

    const renderProgressCells = (data) => (
        data && <ProgressBarCell
            value={data.value}
            displayValue={data.value}
            color={data.color}
        />
    )

    const getDataSource= useCallback(() => {
        if (selectedKey){
            switch (selectedKey) {
                case "disabilities":
                    return disabilitiesData;
                case "requirementMet":
                    return requirementMetData;
                case "targetNotCompleted":
                    return targetNotCompletedData;
                case "unitProgress":
                    return unitProgressData;
                case "attendance":
                    return attendanceData;
            }
        }
        return {}
    }, [selectedKey, disabilitiesData, requirementMetData, targetNotCompletedData, unitProgressData, attendanceData])

    const renderPopupColumns = (progress=false) =>  {
        let dataSource = getDataSource().data?.list;
        return (
            [
                {
                    title: 'Student Details',
                    dataIndex: 'studentDetails',
                    sorter: (a, b) => a.studentDetails.name && a.studentDetails.name.localeCompare(b.studentDetails.name),
                    render: (value) => <StudentDetailCell title={value.name} subtitle={value.id} avatar={value.image}/>,
                    onFilter: (value, record) => record.studentDetails.name === value,
                    filters: getUniqValuesForFilters(dataSource, "studentDetails.name", "studentDetails.name"),
                },
                {
                    title: 'Course',
                    dataIndex: ['course','name'],
                    sorter: (a, b) => a.course.name && a.course.name.localeCompare(b.course.name),
                    onFilter: (value, record) => record.course.name === value,
                    filters: getUniqValuesForFilters(dataSource, "course.name", "course.name"),
                },
                ...(progress ? progress.map(p => (
                    {
                        title: p.title,
                        dataIndex: p.dataIndex,
                        sorter: (a, b) => parseFloat((a[p.dataIndex]? a[p.dataIndex].value : null) || 0) - parseFloat((b[p.dataIndex]? b[p.dataIndex].value : null) || 0),
                        onFilter: (value, record) => record[p.dataIndex]? record[p.dataIndex].value : null === value,
                        render: (value) => renderProgressCells(value),
                        filters: getUniqValuesForFilters(dataSource, `${p.dataIndex}.value`, `${p.dataIndex}.value`),
                    }
                )): []),

                // Not in phase 01
                // {
                //     title: "",
                //     align:"center",
                //     render: (value, record) => <Space>
                //         <Button size="small">View</Button>
                //     </Space>
                // },
            ]
        )
    }

    let tableConfigMapper = {
        disabilities: {
            columnConfig: null,
            title: "Students with disabilities",
            dispatcher: (data) => fetchAcademicDisabilitiesOverview(data),
            searchableColumns: ["studentDetails.name", "course.name"]
        },
        requirementMet: {
            columnConfig: null,
            title: "Entry requirement met",
            dispatcher: (data) => fetchAcademicRequirementMetOverview(data),
            searchableColumns: ["studentDetails.name", "course.name"]
        },
        targetNotCompleted: {
            columnConfig: null,
            title: "Not completed on target date",
            dispatcher: (data) => fetchAcademicOverviewTargetNotCompleted(data),
            searchableColumns: ["studentDetails.name", "course.name"]
        },
        unitProgress: {
            columnConfig: [{title:"Current Unit Progress", dataIndex:"currentUnitProgress"}, {title:"Expected Unit Progress", dataIndex:"expectedUnitProgress"}],
            title: "Unit Progress",
            dispatcher: (data) => fetchAcademicOverviewUnitProgress(data),
            searchableColumns: ["studentDetails.name", "course.name"]
        },
        attendance:  {
            columnConfig: [{title:"Attendance", dataIndex:"attendance"}],
            title: "Attendance",
            dispatcher: (data) => fetchAcademicOverviewAttendance(data),
            searchableColumns: ["studentDetails.name", "course.name"]
        },
    }

    return (
        <Fragment>
            <Overview
                onPopupOpen={onPopupOpen}
                dataLoading={overviewDataLoading}
                overviewData={overviewData}
            />

            <PopupTable
                isLoading={getDataSource().isLoading}
                visible={showPopup}
                onCancel={onPopupClose}
                searchEnabled={true}
                title={selectedConfig.title}
                columns={renderPopupColumns(selectedConfig.columnConfig)}
                dataSource={getDataSource().data?.list}
                searchableColumns={selectedConfig.searchableColumns}
            />
        </Fragment>
    )
}

export default OverviewTab