import React, { useCallback, useState, useEffect } from "react";
import { Breadcrumb as AntBreadcrumb, Button } from "antd";
import { Link } from "react-router-dom";
import {
  RightOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  makeInnovativeLayoutBreadcrumb,
  makeInnovativeLayoutTopNav,
} from "../../../../selectors/innovative/layout.selector";
import { setSelectedNavigationType } from "../../../../actions/innovative/layout.action";
import { useWindowSize } from "../../../../utils/innovative/customHooks";

const Breadcrumb = (props) => {
  const { hideBackButton, history } = props;
  const dispatch = useDispatch();
  const breadcrumb = useSelector(makeInnovativeLayoutBreadcrumb);
  const topNav = useSelector(makeInnovativeLayoutTopNav);
  const [width, height, isMobile] = useWindowSize();

  const handleGoBack = useCallback(() => {
    dispatch(setSelectedNavigationType("back"));
    history.goBack();
  });


  const handleBreadCrumbClick = (key, location) => {
    dispatch(setSelectedNavigationType(key));
  };

  const renderBreadcrumbItems = () => {
    if (!breadcrumb && breadcrumb.segments && breadcrumb.segments.length) {
      return null;
    }

    return breadcrumb.segments.map(({ section, href, key, state, searchQuery, ...rest }) => {
      if (!href) {
        return (
            <AntBreadcrumb.Item
                {...rest}
                onClick={() => handleBreadCrumbClick(key)}
            >
              {section}
            </AntBreadcrumb.Item>
        );
      }

      const location = {
        pathname: href,
        search: searchQuery,
        state
      }

      return (
          <AntBreadcrumb.Item {...rest}>
            <Link to={location} onClick={() => handleBreadCrumbClick(key, location)}>
              {section}
            </Link>
          </AntBreadcrumb.Item>
      );
    });
  };

  return (
      <div>
        {!hideBackButton && (
            <div className="backLink">
              <Button
                  size="small"
                  icon={<ArrowLeftOutlined />}
                  ghost
                  onClick={handleGoBack}
              ></Button>
            </div>
        )}
        {!isMobile && <AntBreadcrumb separator={<RightOutlined />}>
          {renderBreadcrumbItems()}
        </AntBreadcrumb>}
        {topNav.settings ? (
            <Link
                to={`/insights/settings/${topNav.settings.courseId}`}
                className="settingIcon"
            >
              <SettingOutlined />
            </Link>
        ) : null}
      </div>
  );
};

Breadcrumb.propTypes = {
  // React Router bindings
  history: PropTypes.object.isRequired,

  // Parent Props
  hideBackButton: PropTypes.bool,
};

Breadcrumb.defaultProps = { hideBackButton: false };

export default withRouter(Breadcrumb);
