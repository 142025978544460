import {
  ACC_SET_STUDENT_PROFILE,
  ACC_SET_PARENT_STUDENTS,
  ACC_SET_SUBJECT_ANALYSIS,
  ACC_SET_PARENT_DASHBOARD_LOADING,
  ACC_SET_CURRENT_STUDENT_LOADING,
  ACC_SET_SUBJECT_ANALYSIS_LOADING,
  ACC_OVERRIDE_SUBJECT_ANALYSIS, ACC_SET_SELECTED_ITEMS_LOADING,
} from '../../constant/actionTypes';

const initialState = {
  isDashboardLoading: false,
  filterData: {
    years: [],
    semesters: [],
    courses: {},
    students: [],
  },
  filterTerms: [],
  currentStudent: {},
  isLoadingCurrentStudent: false,
  subjectAnalysis: [],
  isLoadingSubjectAnalysis: false,
};

const parentDashboardReducer = (state = initialState, action) =>{
  const { type, payload } = action;

  switch (type) {
    case ACC_SET_PARENT_DASHBOARD_LOADING:
      return  {
        ...state,
        isDashboardLoading: payload,
      };
    case ACC_SET_PARENT_STUDENTS:
      return {
        ...state,
        ...payload
      };
    case ACC_SET_CURRENT_STUDENT_LOADING:
      return {
        ...state,
        isLoadingCurrentStudent: payload,
      };
    case ACC_SET_STUDENT_PROFILE:
      return {
        ...state,
        currentStudent: payload
      };
    case ACC_SET_SUBJECT_ANALYSIS_LOADING:
      return {
        ...state,
        isLoadingSubjectAnalysis: payload,
      };
    case ACC_SET_SUBJECT_ANALYSIS:
      return {
        ...state,
        subjectAnalysis : {
          ...state.subjectAnalysis,
          ...payload
        },
      }
    case ACC_SET_SELECTED_ITEMS_LOADING:
      let subjectAnalysis = { ...state.subjectAnalysis };

      if (payload && payload.length) {
        payload.forEach(id => {
          if (subjectAnalysis[id]) {
            subjectAnalysis[id].isLoading = true;
          }
        })
      }

      return {
        ...state,
        subjectAnalysis : {
          ...state.subjectAnalysis,
          ...subjectAnalysis
        },
      }
    case ACC_OVERRIDE_SUBJECT_ANALYSIS:
      return {
        ...state,
        subjectAnalysis : {
          ...payload
        },
      }
    default:
      return state;
  };
};

export default parentDashboardReducer;