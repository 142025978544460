import React, { Fragment , useState , useEffect } from 'react';

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

const Loader = () => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
          }, 1000);
        
    },[show]);
    return (
        <Fragment>
            <div className={`loader-wrapper ${show ? '' : 'loderhide'}`} >
                <div className="loader bg-white">
                    <div className="whirly-xx"><img src ={preloaderGif} height="40px" /> </div> 
                </div>
            </div>
        </Fragment>
    );
};

export default Loader;