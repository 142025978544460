import React from 'react';
import {Avatar, Typography} from "antd";
import PropTypes from "prop-types";

const StudentDetailCell = ({title, avatar, subtitle}) => {

    return (
        <div className='studentCard'>
            <div>
                <Avatar src={avatar} alt={title}/>
            </div>
            <div>
                <Typography.Text className='name'><b>{title}</b></Typography.Text>
                <Typography.Text>{subtitle ? <small className="meta-subtitle">{subtitle}</small> : null}</Typography.Text>
            </div>
        </div>
    );
};


StudentDetailCell.propTypes = {
    title: PropTypes.string,
    avatar: PropTypes.string,
    subtitle: PropTypes.string,
};

export default StudentDetailCell;