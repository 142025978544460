import {
    SET_LAYOUT_BREADCRUMB,
    APPEND_TO_LAYOUT_BREADCRUMB,
    REPLACE_LAYOUT_BREADCRUMB_SEGMENT,
    SET_LAYOUT_QUERY_PARAMS,
    SET_TOP_NAV_PARAM,
    SET_SELECTED_NAVIGATION_TYPE,
    SET_INNOVATIVE_SIDEBAR,
} from "../../constant/actionTypes";

// set sidebar data
export const setSidebar = payload => dispatch => {
    dispatch({
        type: SET_INNOVATIVE_SIDEBAR,
        payload,
    });
};

// set breadcrumb segments
export const setBreadcrumb = payload => dispatch => {
    dispatch({
        type: SET_LAYOUT_BREADCRUMB,
        payload,
    });
};

// set selected navigation Type
export const setSelectedNavigationType = payload => dispatch => {
    dispatch({
        type: SET_SELECTED_NAVIGATION_TYPE,
        payload,
    });
};

// append a segment to the end of the breadcrumb
export const appendToBreadcrumb = payload => dispatch => {
    dispatch({
        type: APPEND_TO_LAYOUT_BREADCRUMB,
        payload,
    });
}

// replace a segment of the breadcrumb
export const replaceBreadcrumbSegment = payload => dispatch => {
    dispatch({
        type: REPLACE_LAYOUT_BREADCRUMB_SEGMENT,
        payload,
    });
}

// set / update query params
export const cacheQueryParams = (queryParams, tag) => dispatch => {
    dispatch({
        type: SET_LAYOUT_QUERY_PARAMS,
        payload: {
            queryParams,
            tag
        },
    });
}

export const setTopNavParam = (tag, value) => dispatch => {
    dispatch({
        type: SET_TOP_NAV_PARAM,
        payload: {
            tag,
            value
        }
    });
}