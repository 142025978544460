import React from "react";
import {Collapse, Space, Switch, Typography} from "antd";

import ReadyTecSectionContainer from "./readyTecSection";

const {Panel} = Collapse;

const SectionGroup = () => {

    const renderSectionHeader = (title, switchDisabled, value) => (
        <div>
            <Space style={{display:"flex", alignItems:"center"}}>
                <Switch
                    size="small"
                    checked={value}
                    disabled={switchDisabled}
                    onClick={(value, event) => {
                        event.stopPropagation()
                    }}/>
                <Typography.Text strong>{title}</Typography.Text>
            </Space>
        </div>
    )
    return (
        <Collapse expandIconPosition="right" activeKey="1" className="customAcordian">
            <Panel header={renderSectionHeader("ReadyTech", true, true)} key="1">
                <ReadyTecSectionContainer/>
            </Panel>
            {/* COMMENTED: NOT TO BE DEVELOPED IN PHASE 01 */}
            {/*<Panel collapsible="disabled" header={renderSectionHeader("Canvas LMS", true, false)} key="2">*/}
            {/*    <p>Canvas LMS</p>*/}
            {/*</Panel>*/}
            {/*<Panel collapsible="disabled" header={renderSectionHeader("Live Spring", true, false)} key="3">*/}
            {/*    <p>Live Spring</p>*/}
            {/*</Panel>*/}
        </Collapse>
    )
}

export default SectionGroup;