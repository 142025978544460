import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const dataPrivacyManagementData = state =>
  _get(state, 'dashboardManage.dataPrivacyManagementReducer.dataPrivacy', {});

export const makeSettingsDataPrivacyManagerLoading = createSelector(
    dataPrivacyManagementData, data => _get(data, 'isLoading', false),
);

//fetch cluster enabled status
export const makeSettingsDataPrivacyManagerStatus = createSelector(
  dataPrivacyManagementData, data => _get(
    data,
    'data.data_privacy_management_setting_enable',
    false)
);

const dataPrivacyManagementAccessData = state =>
  _get(state, 'dashboardManage.dataPrivacyManagementReducer.dataPrivacyAccess', {});

export const dataPrivacyAccessGranted = createSelector(
  dataPrivacyManagementAccessData, data => _get(data, 'data.grant', false),
);

export const dataPrivacyAccessLoading = createSelector(
  dataPrivacyManagementAccessData, data => _get(data, 'isLoading', false),
);
