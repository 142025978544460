import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, DatePicker, Form } from "antd";
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { addAdminToken } from "../../../../../../actions/dashboardManagement/dashboard.action";
import PopUpModal from "../../../../../common/popup-modal/popup-modal";
import moment from "moment";

const AddTokenModal = ({ setIsModalVisible, isModalVisible }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [accountToken, setAccountToken] = useState('');
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    if (name.length && expireDate.length && accountToken.length) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [name, expireDate, accountToken]);

  const disabledDate = (current) => {
    // Disabled selecting past dates including toady
    return current && current < moment();
  };

  const onSubmit = () => {
    const payload = {
      token_name: name,
      expire: expireDate,
      api_key: accountToken,
    }
    dispatch(addAdminToken(payload))
  }

  return (
    <PopUpModal
      title={"Add Developer API Key"}
      okText={"OK"}
      onPressOk={onSubmit}
      cancelText={"Cancel"}
      setIsModalVisible={setIsModalVisible}
      isModalVisible={isModalVisible}
      okButtonDisabled={isSubmitButtonDisabled}

    >
      <div className="addDeveloperKey">
        <Form.Item name="name" label="Name" >
          <input
            size="large"
            type="text"
            name="Name"
            placeholder="Token name here"
            onChange={(event) => {
              setName(event.target.value);
            }}
            className="ant-input" />
        </Form.Item>
        <Form.Item name="Expire Date" label="Expire Date" >
          <DatePicker
            className="form-control input-number"
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            onChange={(date, dateString) => {
              console.log(moment(dateString).format('YYYY-MM-DD HH:mm:ss.SSS'))
              setExpireDate(moment(dateString).format('YYYY-MM-DD HH:mm:ss.SSS'))
            }}
          />
        </Form.Item>
        <Form.Item name="Account Token" label="Account Token" >
          <a onClick={() => { }} target="_blank" className="tokenHelpLink"><QuestionCircleOutlined />  How to get a token?</a>
          <input
            type="text"
            name="accToken"
            className="ant-input"
            onChange={(event) => {
              setAccountToken(event.target.value);
            }}
          />
        </Form.Item>
        <Row className="instruction-card" wrap={false}>
          <div className="instruction-card-icon">
            <InfoCircleOutlined />
          </div>
          <p className="instruction-card">
            Click "+ Add Token" to start. Name the token as you prefer and add the expiration date matching Canvas creation. Place the generated access token in the "Account Token" section. Finish by clicking "Okay" to complete the token addition.
          </p>
        </Row>
      </div>
    </PopUpModal>
  )
}

export default AddTokenModal;
