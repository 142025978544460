import {notification} from "antd";

export const destroyCurrentNotifications = () => {
    notification.destroy();
}

export const onSuccess = (message="Success", description="", postFunction) => {
    if (postFunction){
        postFunction()
    }
    notification.success({
        message,
        description,
        duration: 5,
        top:60
    });
}
export const onError = (message="Failed", description="", postFunction) => {

    destroyCurrentNotifications();

    if (postFunction){
        postFunction()
    }
    notification.error({
        message,
        description,
        duration: 5,
        top:60
    });
}