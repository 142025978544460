import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import classnames from 'classnames';
import NoDataOverlay from '../../course/NoDataOverlay';
import nodataImage from '../../../../images/other-images/no-data-chart.png';

const PieChart = ({chartOptions, noDataShow, rowPieChart}) => {
  const defaultConfig = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'pie',
      height: "100%"
    },
    title: false,
    subTitle: false,
    plotOptions: {
      pie: {
        size: "110%",
        innerSize: "50%",
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    tooltip: {
      formatter: function() {
        return `<b>${this.key}:</b> ${this.y}`
      }
    },
    series: [{
      data: []
    }],
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      labelFormatter: function () {
        return `${this.name} ${Math.round(this.percentage)} %`;
      }
    },
  };

  const config = Highcharts.merge(defaultConfig, chartOptions);

  const chartSeries = config?.series;
  const isSeriesDataEmpty = config?.series?.[0]?.data?.length === 0;
  const chartData = chartSeries?.map(item => item?.data);
  const concatDataInitialData = chartData?.reduce((a, b) => a?.concat(b), []);
  const concatAllData = concatDataInitialData?.reduce((a, b) => a?.concat(b), []);
  const onlyNumbers = concatAllData?.filter(x => typeof x === "number");
  const totalCount = onlyNumbers?.length > 0 && onlyNumbers?.reduce((a, b) => a + b) || 0;
  const isEmptyData = totalCount === 0;

  const getConfig = () => {
    const { structuredData, ...restConfig } = config;

    if (!structuredData || !structuredData.length) {
      return restConfig;
    }

    return {
      ...restConfig,
      series: [{data: structuredData}],
    }
  };

  return (
    <div className="pie-chart-Wrapper">
      {isEmptyData && noDataShow && (
        <NoDataOverlay
          subClass="in-side-chart-no-data"
          message="No Data"
          imageWidth="40px"
          overlayImage={nodataImage}
        />
      )}
      {(!isEmptyData || rowPieChart) &&
        <HighchartsReact
          highcharts={Highcharts}
          options={getConfig()}
        />
      }
    </div>
  )
};

PieChart.propTypes = { chartOptions: PropTypes.object.isRequired, noDataShow: PropTypes.bool, rowPieChart: PropTypes.bool };
PieChart.defaultProps = { noDataShow: true, rowPieChart: false };

export default PieChart;