import React from "react";
import PropTypes from "prop-types";
import {Collapse, InputNumber, Space, List, Typography} from "antd";
import _startCase from "lodash/startCase";

import SettingColorPicker from "../ColorPicker";

import {
    subSections,
    academicSections,
    financialSections,
    complianceSections
} from "../../../../../../constant/jobReadyGeneric/constants";

const {Panel} = Collapse;


const MeasurementIndicatorsView  = ({factors, subSection, onValueChange, hasPermission, overallData}) => {

    const configMapper = {
        [subSections.academic.key]: academicSections,
        [subSections.financial.key]: financialSections,
        [subSections.compliance.key]: complianceSections,
        [subSections.wellBeing.key]: {},
    }

    const renderMeasurementContent = (type, factor) => {
        return (
            <List.Item>
                <div>
                    <Space>
                        <InputNumber
                            disabled={!hasPermission}
                            min={0}
                            max={100}
                            style={{width:70}}
                            onChange={value => onValueChange(factor.key, type, "min", value)}
                            value={factor[type]?.min}
                        />
                        <span>&amp;</span>
                        <InputNumber
                            disabled={!hasPermission}
                            min={0}
                            max={100}
                            style={{width:70}}
                            onChange={value => onValueChange(factor.key, type, "max", value)}
                            value={factor[type]?.max}
                        />
                    </Space>
                </div>
                <div>
                    <Space>
                        <span>{_startCase(type)}</span>
                        <div style={{width:70}}>
                            <SettingColorPicker
                                disabled={true}
                                value={overallData[type]?.color}
                            />
                        </div>
                    </Space>
                </div>
            </List.Item>
        )
    }

    const renderPanelBody = (factor) => {
        return (
            <div>
                <List
                    size="small"
                    header={
                        <div className="ri-measurement-header">
                            <div>{factor.name} Range</div>
                            <div>Colour</div>
                        </div>
                    }
                    bordered
                >
                    {renderMeasurementContent("high", factor)}
                    {renderMeasurementContent("medium", factor)}
                    {renderMeasurementContent("low", factor)}
                </List>
            </div>
        )
    }

    return (
        <Collapse expandIconPosition="right">
            {Object.keys(factors).sort().map(key => {
                const constDataKey =  Object.keys(configMapper[subSection]).find(secKey => configMapper[subSection][secKey].key === key);
                const factorData = {...factors[key], ...configMapper[subSection][constDataKey]}
                return (
                    <Panel header={<Typography.Text strong>{factorData.name}</Typography.Text>} key={factorData.key}>
                        {renderPanelBody(factorData)}
                    </Panel>
                )
            })}
        </Collapse>
    )
}

MeasurementIndicatorsView.propTypes = {
    factors: PropTypes.object,
    subSection: PropTypes.string,
    onValueChange: PropTypes.func,
    hasPermission: PropTypes.bool,
    overallData: PropTypes.object
}

export default  MeasurementIndicatorsView;