import React, { Fragment } from "react";
import { Layout, Menu } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Search from "../form/Search";
import {
  makeInnovativeCourseResponse,
  makeInnovativeCourseLoading,
} from "../../../../selectors/innovative/course.selector";
import TermFilter from "../termFilter";

const Sidebar = ({
  sidebarData,
  setSidebar,
  title,
  items,
  mapping,
  onSearch,
  onItemClick,
  suppressHeader,
  termFilter = false,
  terms,
  termsLoading,
  termChange,
  reportLoading,
  selectedTerm,
  activeSection,
}) => {
  const selectedCoursesLoading = useSelector(makeInnovativeCourseLoading);
  const selectedCourseItem = useSelector(makeInnovativeCourseResponse);
  const selectedCourseItemData = selectedCourseItem?.response?.data?.result;
  const renderSearch = () => {
    if (typeof onSearch === "function") {
      return <Search onSearch={onSearch} />;
    }

    return null;
  };
  const renderTermFilter = () => {
    if (termFilter) {
      return (
        <Fragment>
          <TermFilter
            terms={terms}
            termsLoading={termsLoading}
            reportLoading={reportLoading}
            termChange={termChange}
            selectedTerm={selectedTerm}
            dropdownClassName="course-term-filter"
            wrapperId="innovative-term-side-bar-select"
          />
          <br />
        </Fragment>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (title) {
      return <h5>{title}</h5>;
    }

    return null;
  };

  const renderHeader = () => {
    if (!suppressHeader) {
      const className = `searchFilter ${typeof onSearch !== "function" ? "no-search" : ""
        }`;

      return (
        <div className={className}>
          {renderTermFilter()}

          {renderTitle()}
          {renderSearch()}
        </div>
      );
    }

    return null;
  };

  const onBreakPoint = (broken) => {
    if (broken) {
      !sidebarData.collapsed && setSidebar(true);
    } else {
      sidebarData.collapsed && setSidebar(false);
    }
  }
  return (
    <Layout.Sider
      width={250}
      breakpoint="md"
      collapsedWidth="0"
      trigger={null}
      collapsed={sidebarData.collapsed}
      onBreakpoint={onBreakPoint}
    >
      {renderHeader()}
      <div className="menuScroller">
        <Menu
          defaultSelectedKeys={items && items[0] && items[0][mapping.key]}
          mode="inline"
        >
          {items.map((item) => {
            const selectedMenu =
              parseInt?.(selectedCourseItemData?.course_id) ===
              parseInt?.(item?.course_id) && !selectedCoursesLoading;

            return (
              <Menu.Item
                onClick={({ ...params }) => onItemClick({ ...params, item })}
                key={item[mapping.key]}
                className={selectedMenu ? "selected-menu" : ""}
                icon={<NotificationOutlined />}
              >
                {item[mapping.title]}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </Layout.Sider>
  );
};

Sidebar.defaultProps = {
  termFilter: false,
};

Sidebar.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  onSearch: PropTypes.func,
  onItemClick: PropTypes.func,
  suppressHeader: PropTypes.bool,
  termFilter: PropTypes.bool,
  terms: PropTypes.array,
  termsLoading: PropTypes.bool,
  reportLoading: PropTypes.bool,
  termChange: PropTypes.func,
  selectedTerm: PropTypes.string,
};

export default Sidebar;
