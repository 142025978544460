import React, {Fragment} from "react";

import StudentsTab from "./tabs/studentsTab";
import OverviewTab from "./tabs/overviewTab";
import {Tabs} from "antd";

const {TabPane} = Tabs;

const InnerTabSection = ({tabConfig}) => {
    return (
        <Fragment>
            <Tabs
                activeKey={tabConfig.activeTab}
                onChange={tabConfig.setActiveTab}
                destroyInactiveTabPane={true}
            >
                <TabPane tab="Students" key="students">
                    <StudentsTab/>
                </TabPane>
                <TabPane tab="Overview" key="overview">
                    <OverviewTab/>
                </TabPane>
            </Tabs>
        </Fragment>
    )
}

export default InnerTabSection