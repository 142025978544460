import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import UserPanel from './userPanel';
import { MENUITEMS } from '../../../../constant/menu';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import configDB from '../../../../data/customizer/config';
import { connect } from "react-redux";

const tassLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tass_logo.png`;
const logo_compact = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle-logo-old-compact.png`;

const Sidebar = (props) => {
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const wrapper = configDB.data.settings.sidebar.wrapper;
    const layout = useSelector(content => content.Customizer.layout);

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize();

        var currentUrl = window.location.pathname;

        // eslint-disable-next-line
        mainmenu.filter(items => {
            if (items.path === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            // eslint-disable-next-line
            items.children.filter(subItems => {
                if (subItems.path === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                // eslint-disable-next-line
                subItems.children.filter(subSubItems => {
                    if (subSubItems.path === currentUrl)
                        setNavActive(subSubItems)
                })
            })
        })

        setTimeout(() => {
            const elmnt = document.getElementById("myDIV");
            const menuWidth = elmnt.offsetWidth;
            // setMenuWidth(menuWidth)
            if (menuWidth > window.innerWidth) {
                setHideRightArrow(false);
                setHideLeftArrowRTL(false);
            } else {
                setHideRightArrow(true);
                setHideLeftArrowRTL(true);
            }
        }, 500)

        return () => {
            // eslint-disable-next-line
            window.addEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth - 310);
    }

    const setNavActive = (item) => {
        // eslint-disable-next-line
        MENUITEMS.filter(menuItem => {
            // eslint-disable-next-line
            if (menuItem != item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                // eslint-disable-next-line
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })

    }

    // Click Toggle menu
    const toggletNavActive = (item) => {
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
        }
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += (-width));
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }


    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        // Checking condition for remaing margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }


    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo text-center">
                        <Link to="#">
                            <img width="60%" className="blur-up lazyloaded" src={logo_compact} alt="" />
                            <img width='60%' className="blur-up lazyloaded" src={tassLogo} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? layout === 'rtl' ?
                            { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                        <li className={props.sideBarActive == 'financial_summary' ? "active" : ""}>
                            <a className="sidebar-header " href="/tassweb/financial_summary">
                                <span>Financial Summary</span>
                            </a>
                        </li>
                        <li className={props.sideBarActive == 'profitability' ? "active" : ""}>
                            <a className="sidebar-header " href="/tassweb/profitability">
                                <span>Profitability</span>
                            </a>
                        </li>
                        <li className={props.sideBarActive == 'solvency' ? "active" : ""}>
                            <a className="sidebar-header " href="/tassweb/solvency">
                                <span>Solvency</span>
                            </a>
                        </li>
                        <li className={props.sideBarActive == 'sustainability' ? "active" : ""}>
                            <a className="sidebar-header " href="/tassweb/sustainability">
                                <span>Sustainability</span>
                            </a>
                        </li>
                        <li className={props.sideBarActive == 'staffing' ? "active" : ""}>
                            <a className="sidebar-header " href="/tassweb/staffing">
                                <span>Staffing</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};


const mapStateToProps =(state) => {
  return {
    sideBarActive: state.Authentication.sideBarActive
  }
}

export default translate(connect(mapStateToProps)(Sidebar));

