import {
  SET_STUDENT_CLUSTER_ANALYZER_STATUS,
  SET_STUDENT_CLUSTER_ANALYZER_LOADING,
  CLUSTER_SETTINGS_DATA,
  FETCHING_CLUSTER_SETTINGS_DATA,
} from '../../constant/actionTypes';

const initialState = {
  cluster: {},
  isLoading: false,
};

const studentClusterAnalyzerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // must CHNAGE THISE 
  switch (type) {
    case SET_STUDENT_CLUSTER_ANALYZER_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_STUDENT_CLUSTER_ANALYZER_STATUS:
      return {
        ...state,
        ...payload,
      };
    case FETCHING_CLUSTER_SETTINGS_DATA:
    case CLUSTER_SETTINGS_DATA:
      return {
        ...state,
        cluster:{
          data:{
            cluster: {
              ...state.cluster?.data?.cluster,
              ...payload.data?.cluster
            }
          }
        },
      };
    default:
      return state;
  };
}

export default studentClusterAnalyzerReducer;
