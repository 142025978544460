const {GET, POST} = require('./../../../utils/network')

export default {
    studentFilters: async function (filters,token) {
      try {
        // console.info(
        //   `Fetch filters >> ${process.env.REACT_APP_APIURL}studentprofile/filters_acc/${filters.logged_in}/${filters.access}`
        // );
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/filters_acc/${filters.logged_in}/${filters.access}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    classProfileFilters: async function (filters,token) {
      try {
        // console.info(
        //   `Fetch filters >> ${process.env.REACT_APP_APIURL}teacherprofile/filters_acc/${filters.logged_in}/${filters.observer?'O':'T'}/${filters.access}`
        // );
        return fetch(process.env.REACT_APP_APIURL+`teacherprofile/filters_acc/${filters.logged_in}/${filters.observer?'O':'T'}/${filters.access}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    /** Not use */
    studentFilterTerm: async function (filters,token) {
      try {
        // console.info(
        //   `Fetch filters term >> ${process.env.REACT_APP_APIURL}studentprofile/filters/term/${filters.logged_in}`
        // );
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/filters/term/${filters.logged_in}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    studentCoursesBySememster: async function (filters, token){
      try {
        // console.info(
        //   `Fetch filters term >> ${process.env.REACT_APP_APIURL}studentprofile/filters/coursesbySemester/${filters.logged_in}/${filters.term}/${filters.yearLevel}/${filters.semester}`
        // );
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/filters/coursesbySemester/${filters.logged_in}/${filters.term}/${filters.yearLevel}/${filters.semester}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    students: async function (filters,token) {
      try {
        // console.info(
        //   `Fetch filter students >> ${process.env.REACT_APP_APIURL}parentprofile/filters/observees/`
        // );
        return fetch(process.env.REACT_APP_APIURL+"parentprofile/filters/observees/",{
            credentials: "include",
            method: 'POST',
            headers:{  'Authorization': token,'Content-Type': 'application/json' },
            body: JSON.stringify(filters)
          
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    parentStudentCoursesBySemester: async function (filters, token) {
      // http://apijs.tentacle.com:4000/api/1/parentprofile/filters/coursesbySemester/7250/2020/0/2
      try {
        // console.info(
        //   `Fetch filters term >> ${process.env.REACT_APP_APIURL}parentprofile/filters/coursesbySemester/${filters.id}/${filters.role}`
        // );
        return fetch(process.env.REACT_APP_APIURL+`parentprofile/filters/coursesbySemester/${filters.id}/${filters.role}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    studentsByCourse: async function (filters, token) {
      try {
        // console.info(
        //   `Fetch Students by Course >> ${process.env.REACT_APP_APIURL}studentprofile/filters/studentsbycourse/${filters.logged_in}/${filters.term}/${filters.course}//${filters.access}`
        // );
        return fetch(process.env.REACT_APP_APIURL + `studentprofile/filters/studentsbycourse/${filters.logged_in}/${filters.term}/${filters.course}/${filters.access}`, {
          credentials: "include",
          method: 'GET',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' }
        })
      } catch (e) {
        console.log(e.message);
      }
    },
    principalFilterTerm: function (filters){
      try {
        return POST(
          `${process.env.REACT_APP_APIURL}principalprofile/filters/term`,
          {
            schoolId: parseInt(filters.school),
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    ceoFilterTerm: async function (filters){
      try {
        // console.info(
        //   `Fetch filters term >> ${process.env.REACT_APP_APIURL}ceoprofile/filters/term/${filters.logged_in}`,
        // );
        // return fetch(process.env.REACT_APP_APIURL + `ceoprofile/filters/term/${filters.logged_in}`, {
        //   credentials: "include",
        //   method: 'GET',
        // })   
        return POST(
          `${process.env.REACT_APP_APIURL}ceoprofile/filters/term`,
          {
            schools: filters.schools,
          }
        ); 
      } catch (e) {
        console.log(e.message);
      }
    },
    getSchoolData: async function () {
      try {
        return GET(`${process.env.REACT_APP_APIURL}principalprofile/filters/schools`, {
          credentials: "include",
          method: 'GET',
        });
      } catch (e) {
        console.log(e.message)
      }
      
    },
    individualStudentFilters: async function (filters,token) {
      try {
        return fetch(process.env.REACT_APP_APIURL+`studentprofile/student_filters_acc/${filters.logged_in}/${filters.access}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e.message);
      }
    }
}