import React, {useCallback} from 'react';
import StatCellRenderer from "./StatCellRenderer";
import PropTypes from "prop-types";

const StatCellRenderContainer = ({params}) => {
    const {label, resolver, ...rest} = params;

    const resolveCellData = useCallback(() => {
        return resolver(rest);
    }, [resolver, params.settings, params.value]);

    const resolved = resolveCellData();

    return (<StatCellRenderer label={label} styles={resolved.styles} value={resolved.value}/>);
};

StatCellRenderContainer.propTypes = {
    params: PropTypes.object,
};

export default StatCellRenderContainer;