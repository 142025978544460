import React from 'react';
import {Layout, Button, Space} from 'antd';
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {SettingOutlined, MenuOutlined, CloseOutlined, LogoutOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTachometerAlt} from "@fortawesome/free-solid-svg-icons";

import {setSidebarData} from "../../../actions/jobReadyGeneric/layout.action";
import {getSidebarCollapsed, getSidebarEnabled} from "../../../selectors/jobReadyGeneric/layout.selector";

import UserLogout from "../../auth/externalAuth/Logout/Logout";

import {menu} from "../../../constant/jobReadyGeneric/menu";
import menuData from "../jrDashboardManagement/jrMenuData";

const {Header} = Layout;

const logo = `${process.env.REACT_APP_S3_BUCKET_URL}/jobready/public/jr_plus.png`;

const JRHeader = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlPathName = window.location.href;

    // selectors
    const sidebarCollapsed = useSelector(getSidebarCollapsed);
    const isSidebarEnabled = useSelector(getSidebarEnabled);

    const toggleSidebarComponent = () => {
        dispatch(setSidebarData({collapsed:!sidebarCollapsed}))
    }

    const backToDashboards = () => {
        return history.push(menuData.manageDashboard.url)
    }

    return (
        <Header className="site-layout-sub-header-background">
            {isSidebarEnabled &&
            <a href='#_' className='mobile-menu-button'>
                <MenuOutlined onClick={toggleSidebarComponent}/>
                <CloseOutlined onClick={toggleSidebarComponent}/>
            </a>
            }
            <div className="logo">
                <img src={logo} alt="headerLogo" className='header-logo' />
            </div>
            <div className="button-section">
                {!urlPathName?.includes(menu.riskAnalysis) &&
                <Button className='bg-success' primary='true' onClick={() => history.push(menu.riskAnalysis)}>Risk
                    Analysis</Button>
                }
                {/* Not in Phase 01*/}
                {/*<Button type='link'>Action Center</Button>*/}
            </div>
            <div className="end-div">
                <Space >
                    <Button  type="primary" onClick={backToDashboards} icon={<FontAwesomeIcon icon={faTachometerAlt} />} className="dashboard-button">&nbsp; Dashboards</Button>
                    <SettingOutlined onClick={() => history.push(menu.settings)} style={{fontSize:22, color:"#6244BB"}}/>
                    <UserLogout button={
                        ({onClick}) => <span title="Sign out" onClick={onClick} className="jr-setting-icon" style={{border: "none", cursor: "pointer"}}>
                            <LogoutOutlined/>
                        </span>
                    }/>
                </Space>
            </div>
        </Header>
    )
}

export default JRHeader;