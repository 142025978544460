import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getFinancialCardsData,
    getFinancialCardsLoading
} from "../../../../../../../selectors/jobReadyGeneric/riskAnalysis/financial.selector";
import {getDashboardFiltersSelectedData} from "../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {fetchFinancialCards} from "../../../../../../../actions/jobReadyGeneric/riskAnalysis/financial.action";

import SubSectionTopCards from "../../common/topCards/subSectionTopCards";
import InnerTabSection from "./innerTabSection/InnerTabSection";
import _isEmpty from "lodash/isEmpty";

import {subSections} from "../../../../../../../constant/jobReadyGeneric/constants";


const FinancialTabContainer = () => {
    const [activeTab, setActiveTab] = useState(null);
    const dispatch = useDispatch();

    // selectors
    const cardData = useSelector(getFinancialCardsData);
    const cardDataLoading = useSelector(getFinancialCardsLoading);
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);

    // effects
    useEffect(() => {
        setActiveTab("students");
    }, [])

    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters)){
            const {financial} = subSections;
            const data = {
                filters: selectedFilters,
                subSectionId: financial.id
            }
            dispatch(fetchFinancialCards(data));
        }
    }, [selectedFilters])

    const onTabChange = (key) => {
        setActiveTab(key)
    }

    return (
        <div>
            <SubSectionTopCards
                cardData={cardData?.cards}
                cardLoading={cardDataLoading}
                chartData={cardData?.chart}
                subSection={subSections.financial.key}
            />
            <br/>
            <InnerTabSection tabConfig={{activeTab, setActiveTab:onTabChange}}/>
        </div>
    )
}

export default FinancialTabContainer;