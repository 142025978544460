import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';

import { getSelectedCardsByPath } from '../../../../../../../../../selectors/vetTrack/analysis.selector';
import { setSelectedCards } from '../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/common.action';

import { hex2rgba } from '../../../../../../../../../utils/general';

const TopCountCard = ({ name, value, mode, color, subSection, backColor }) => {
  const dispatch = useDispatch();

  // selectors
  const sectionSelectedCard = useSelector(getSelectedCardsByPath(subSection));

  const onCardClick = () => {
    // need to check if there is an active card. if there is, we cannot active another one
    if (subSection) {
      let sectionValue = null;
      if (sectionSelectedCard !== mode) {
        sectionValue = mode;
      }

      // if total count card is clicked, we need to reset if any filters applied.
      if (mode === 'total' && sectionSelectedCard !== null) {
        sectionValue = null;
      }
      dispatch(setSelectedCards({ [subSection]: sectionValue }));
    }
  };

  const getIcon = () => {
    switch (mode) {
      case 'total':
      case 'high':
        return 'https://media.dev.tentacleinsights.com/jobready/public/speedometer_high.svg';
      case 'low':
        return 'https://media.dev.tentacleinsights.com/jobready/public/speedometer_low.svg';
      case 'medium':
        return 'https://media.dev.tentacleinsights.com/jobready/public/speedometer_mid.svg';
      default:
        return '';
    }
  };

  const renderIcon = () => (
    <div style={{ backgroundColor: color }} className="top-card-icon">
      <img src={getIcon()} alt="top card icon" />
    </div>
  );

  const bgMode = mode === sectionSelectedCard && mode !== 'total' ? 1 : 0.1;
  const boderMode = mode === sectionSelectedCard && mode !== 'total' ? 1 : 0.52;

  return (
    <Card
      onClick={onCardClick}
      className={`top-count-card top-count-clickable
            ${mode === sectionSelectedCard &&
              mode !== 'total' &&
              'top-card-active'}
            `}
      style={{
        backgroundColor: backColor ? hex2rgba(color, bgMode) : '',
        border: `1px solid ${backColor ? hex2rgba(color, boderMode) : ''}`,
      }}
    >
      <div>{renderIcon()}</div>
      <div className="meta">
        <div>{name}</div>
        <Typography.Title
          level={1}
          style={{
            color:
              mode === sectionSelectedCard && mode !== 'total' ? '#fff' : color,
            textAlign: 'end',
          }}
        >
          {value}
        </Typography.Title>
      </div>
    </Card>
  );
};

TopCountCard.defaultProps = {
  backColor: true,
};

TopCountCard.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['high', 'medium', 'low', 'total']),
  color: PropTypes.string,
  backColor: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subSection: PropTypes.string,
};

export default TopCountCard;
