import {
    SET_SELECTED_DASHBOARD,
    FETCH_DASHBOARD_SELECTIONS,
    FETCH_DASHBOARD_SELECTIONS_LOADING,
    FETCH_DASHBOARD_USERS,
    FETCH_DASHBOARD_USERS_LOADING,
    ADD_NEW_DASHBOARD_USER,
    ADD_NEW_DASHBOARD_USER_LOADING,
    DELETE_DASHBOARD_USER,
    DELETE_DASHBOARD_USER_LOADING,
    JR_GEN_USER_RESET_PASSWORD,
    JR_GEN_USER_RESET_PASSWORD_LOADING
} from "../../../constant/actionTypes";

const jrGenUserDefaultState = {
    dashboards: {
        isLoading:false,
        error: false,
        data: {},
        selected: false
    },
    list: {
        isLoading:false,
        error: false,
        data: {},
    },
    selected: {
        isLoading:false,
        error: false,
        data: {},
    },
    added: {
        isLoading:false,
    },
    deleted: {
        isLoading:false,
    },
    updated: {
        isLoading:false,
    },
    resetPassword: {
        isLoading:false,
    },
    setPermissions: {
        isLoading:false,
    }
};

const jrGenUserReducer = (state=jrGenUserDefaultState, action) => {
    switch (action.type) {
        case SET_SELECTED_DASHBOARD:
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    selected: action.payload
                }
            }
        case FETCH_DASHBOARD_SELECTIONS:
        case FETCH_DASHBOARD_SELECTIONS_LOADING:
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    ...action.payload
                }
            }
        case FETCH_DASHBOARD_USERS:
        case FETCH_DASHBOARD_USERS_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    ...action.payload
                }
            }
        case ADD_NEW_DASHBOARD_USER:
        case ADD_NEW_DASHBOARD_USER_LOADING:
            return {
                ...state,
                added: {
                    ...state.added,
                    ...action.payload
                }
            }
        case DELETE_DASHBOARD_USER:
        case DELETE_DASHBOARD_USER_LOADING:
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    ...action.payload
                }
            }
        case JR_GEN_USER_RESET_PASSWORD:
        case JR_GEN_USER_RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    ...action.payload
                }
            }
        default:
            return state

    }
}

export default jrGenUserReducer;