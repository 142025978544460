import React, { useEffect, useState } from 'react';
import { Row, Space, Switch } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { dashboardConst } from '../../../../../../constant/dashboardManagement/commonDashboardConst';

const CourseSwitch = ({ record, onSwitchChange }) => {

  const switchState = () => record?.courseStatus === dashboardConst.allowedCourseStatus;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(switchState());
  }, []);

  const renderToggleText = isCourseActive => {
    if (!isCourseActive) {
      return (
        <div className='restricted-course-label'>
          <Space>
            <LockOutlined />
            <div>
              Access Restricted
            </div>
          </Space>
        </div>
      )
    }

    return null;
  }

  return (
    <Row justify={"space-between"}>
      {record.courseName}
      <Space>
        {renderToggleText(isChecked)}
        <Switch
          size="small"
          checked={isChecked}
          onChange={checked => {
            setIsChecked(checked);
            onSwitchChange({ checked, record });
          }}
        />
      </Space>
    </Row>
  );
}

export default CourseSwitch;
