import React, { Component, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import seven from '../../../assets/images/user-card/7.jpg';
import { Calendar, Users, MessageSquare, Navigation, Box, Layers, Server, Book, ArrowDown, ArrowUp } from 'react-feather';
import Breadcrumb from '../../common/breadcrumb';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Button } from 'reactstrap';
//import Highcharts from 'highcharts';
 import Highcharts from 'highcharts/highstock'
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { faBook } from '@fortawesome/free-solid-svg-icons';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import ApiServices from './../../../services/tentacle';
 
import one from '../../../assets/images/user-card/1.jpg';
import three from '../../../assets/images/user-card/3.jpg';
import accimage from '../../../assets/images/acclogo.png';
import boxplotimage from '../../../assets/images/boxplot.png';
import two from '../../../assets/images/user-card/2.jpg';
import sixteen from '../../../assets/images/avtar/16.jpg';
import eleven from '../../../assets/images/avtar/11.jpg';
import four from '../../../assets/images/avtar/4.png';

import url from '../../../assets/images/user/7.jpg';

import '../../../assets/css/student-profile.css';

import addHeatmap from 'highcharts/modules/heatmap'
import helper from '../common/helper';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
var moment = require('moment-timezone');

addHeatmap(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;
 
/**
 * Acc Student profile
 */

class AccStudentProfile extends Component {
  constructor(props) {
    super(props);

    var queryx = new URLSearchParams(this.props.location.search);

    this.toggle = this.toggle.bind(this);
    this.state = {
      testdata:["ada","Adas","Asd"],
      dropdownOpen: false,
      isOpen: false,
      url: "",
      isLoaded: true,
      items: [],
      isSubChartLoaded: false,
      subChartItem: [],
      isLateSubChartLoaded: false,
      lateSubmission: [],
      tempStudentName: "",
      studentName: "",
      avatar: four,
      workloadError: false,
      isFilterTermsLoaded: false,
      isFilterYearLevelsLoaded: false,
      isFilterStudentsLoaded: false,
      isFilterSemestersLoaded: false,
      filterTermValue: 0,
      filterParamTermValue : queryx.get('year') ? queryx.get('year')  : 0,
      filterCourseValue: 0,
      filterStudentValue: 0,
      filterParamStudentValue: queryx.get('student') ? queryx.get('student')  : 0,
      filterYearLevelValue: 0,
      filterSemesterValue: 0,
      filterParamSemesterValue: queryx.get('semester') ? queryx.get('semester')  : 0,
      filterTerms: [],
      filterYearLevels: [],
      couseAnalysisChartsObjects:[],
      filterStudents: [],
      filterSemesters: [],
      isFilterStudentsLoaded: false,
      isFeedbacksLoaded: false,
      feedbacks: [],
      isMarksByAssignmentsLoaded: false,
      marksByAssignments: [],
      isNaplanLoaded: false,
      naplan: [],
      isPatLoaded: false,
      pat: [],
      courseAnalysisData:[],
      isCourseAnalysisLoaded:false,
      isCourseAnalysisByAssignmentLoaded: false,
      courseAnalysisByAssignments: [],
      isStudentNameLoaded: false,
      isWorkloadLoaded: false,
      workLoadItem: [],
      loader: 'Loading...',
      logged_in: queryx.get('token'),
      token: 'Bearer ' + queryx.get('t'),
      role: queryx.get('role'),
      isActivityByTimeLoaded: false,
      activityByTimeData: [],
      isFirstTimeLoad: true,
      SubmissionModelOpen: false,
      onCloseModal: false,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: [],
      isGoButtonDisabled: false,
      courseModalOpen:false,
      headers: { 'Content-Type': 'application/json' },
      filterActivityByTimeMode: 'M',
      isSubChartLoading: true,
      isFeedbacksLoading: true,
      isLateSubChartLoading: true,
      isMarksByAssignmentsLoading: true,
      isWorkloadLoading: true,
      isActivityByTimeLoading: true,
      isNaplanLoading: true,
      isPatLoading: true,
      isCourseAnalysisByAssignmentLoading: true,
      isCourseAnalysisLoading: true,
      isLateSubmission: false,
      isLateSubmissionLoaded: false,
      lateSubmission: [],
      isLateSubmissionLoading: true,
      role_access: queryx.get('access'),
    };
  }

  readUrl(event) {
  }

  filterTerms() {
    this.setState({
      isFilterYearLevelsLoaded: false,
      isFilterCoursesLoaded: false,
      isFilterStudentsLoaded: false,
      isFilterTermsLoaded: false,
      isGoButtonDisabled: true
    });

    const params = {logged_in: this.state.logged_in, access: this.state.role_access};
    ApiServices.AccFilterService.studentFilters(params, this.state.token).then(res => res.json()).then((result) => {
      this.setState({
        filterData: result.data,
        filterTerms: result.data.years
      }, ()=> {
        if(this.state.filterTerms.length > 0){
          if(this.state.role && this.state.role === "O"){
            if(this.state.filterTerms.some(year => parseInt(year.id) === parseInt(this.state.filterParamTermValue))){
              this.setState({
                isFilterTermsLoaded: true,
                filterTermValue: this.state.filterParamTermValue,
              }, () => {
                this.changeTermValue(this.state.filterTermValue);
              });
            } else {
              this.handleNoDataInFilters();
            }
          } else {
            this.setState({
              isFilterTermsLoaded: true,
              filterTermValue: this.state.filterTerms[0].id,
            }, () => {
              this.changeTermValue(this.state.filterTermValue);
            });
          }
        } else {
          this.handleNoDataInFilters();
        }
      });
    },(error) => {
      this.setState({
        isFilterTermsLoaded: true,
        error
      });
    });
  }

  handleNoDataInFilters(){
    this.setState({
      isFilterTermsLoaded: true,
      isFilterSemestersLoaded: true,
      isFilterCoursesLoaded: true,
      isFilterStudentsLoaded: true,
      isStudentNameLoaded: true,
      filterTerms: [{name: "No Data", id: ""}],
      filterSemesters: [{name: "No Data", id: ""}],
      filterCourses: [{name: "No Data", id: ""}],
      filterStudents: [{name: "No Data", id: ""}],
    }, ()=>{
      this.setState({
        isSubChartLoading: false,
        isFeedbacksLoading: false,
        isLateSubChartLoading: false,
        isMarksByAssignmentsLoading: false,
        isWorkloadLoading: false,
        isActivityByTimeLoading: false,
        isNaplanLoading: false,
        isPatLoading: false,
        isCourseAnalysisByAssignmentLoading: false,
        isCourseAnalysisLoading: false,

      });
    });
  }

  changeSemesterValue(value) {
    this.setState({ filterSemesterValue: value }, () => {
      if(this.state.role && this.state.role === "O"){
        this.filterStudentsFuncOnObserver();
      } else {
        this.setState({
          isStudentNameLoaded: false
        });
        this.filterCourses();
      }
    });
  }

  changeYearLevelFilterValue(value) {
    this.setState({ filterYearLevelValue: value });
    this.filterStudentsFunc(value);
  }

  changeTermValue(value) {
    this.setState({ filterTermValue: value }, () => {
      this.filterSemestersFunc(value);
    });
  }

  changeStudentValue(value) {
    this.setState({ filterStudentValue: value },() => {
      this.basic_info();
      if(this.state.role && this.state.role === "O"){
        this.filterCoursesOnObserver();
      } else {
        if (this.state.isFirstTimeLoad) {
          this.setState({
            isFirstTimeLoad: false
          });
          this.redrawCharts();
        }
      }
    });
  }

  changeCourseValue(value) {
    this.setState({ filterCourseValue: value }, () => {
      if(this.state.role && this.state.role === "O"){
        if (this.state.isFirstTimeLoad) {
          this.setState({
            isFirstTimeLoad: false
          });
          this.redrawCharts();
        }
      } else {
        this.filterStudentsFunc(value);
      }
    });
  }

  activityByTimeMode = (mode) => {
    this.setState({
      isActivityByTimeLoaded: false,
      filterActivityByTimeMode: mode
    },() => {
      this.chart_activity_by_time();
    });
  }

  redrawCharts() {
    this.setState({
      isSubChartLoaded: false,
      isFeedbacksLoaded: false,
      isLateSubChartLoaded: false,
      isMarksByAssignmentsLoaded: false,
      isWorkloadLoaded: false,
      isActivityByTimeLoaded: false,
      isNaplanLoaded: false,
      isPatLoaded: false,
      isCourseAnalysisByAssignmentLoaded:false,
      isCourseAnalysisLoaded:false,
      isSubChartLoading: true,
      isFeedbacksLoading: true,
      isLateSubChartLoading: true,
      isMarksByAssignmentsLoading: true,
      isWorkloadLoading: true,
      isActivityByTimeLoading: true,
      isNaplanLoading: true,
      isPatLoading: true,
      isCourseAnalysisByAssignmentLoading: true,
      isCourseAnalysisLoading: true,
      isLateSubmission: false,
      isLateSubmissionLoaded: false,
      isLateSubmissionLoading: true
    });

    this.chart_marks_by_assignment();

    if(this.state.role && this.state.role == "O"){
    } else {
      this.chart_course_analysis_by_assignment();
      this.charts_submissiondata();
      this.late_submission_chart();
    }

    this.table_feedbacks();
    // this.charts_workload();
    // this.chart_naplan();
    // this.chart_pat();
    this.chart_activity_by_time();
  }

  basic_info() {
    this.setState({
      isStudentNameLoaded: false
    });
    
    const params = {logged_in: this.state.logged_in, studentId: this.state.filterStudentValue, course: this.state.filterCourseValue, access: this.state.role_access};
    if(this.state.role === "O"){
      ApiServices.AccParentProfile.studentProfile(params, this.state.token).then(res => res.json()).then((result) => {
        if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result){
          this.setState({
            isStudentNameLoaded: true,
            studentName:result.data.result.name + " - " + result.data.result.id,
            avatar:result.data.result.avatar_url
          });
        }
      }).catch(error =>{
        this.setState({
          isStudentNameLoaded: true,
          studentName: "Student Nme",
          avatar: four,
          error
        });
      });
    }else{
      ApiServices.AccStudentProfileService.profile(params, this.state.token).then(res => res.json()).then((result) => {
        if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result && Object.keys(result.data.result).length > 0){
          this.setState({
            isStudentNameLoaded: true,
            studentName: result.data.result.name + " - " + result.data.result.id,
            avatar: result.data.result.avatar_url
          });

          if (this.state.studentName == "") {
            this.setState({
              studentName: "Student Name",
              avatar: four
            });
          }
        } else {
          this.setState({
            isStudentNameLoaded: true,
            studentName: "Student Name",
            avatar: four
          });
        }
      },(error) => {
        this.setState({
          isStudentNameLoaded: true,
          studentName: "Student Nme",
          avatar: four,
          error
        });
      });
    }
  }

  filterYearLevel(term = null) {
    this.setState({
      isFilterYearLevelsLoaded: false,
      isFilterCoursesLoaded: false,
      isFilterStudentsLoaded: false
    });

    this.setState({
      isGoButtonDisabled: true
    });

    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers: { 'Authorization': this.state.token, 'Content-Type': 'application/json' }
    };

    if (term == null) {
      var term = this.state.filterTermValue;
    }

    fetch(process.env.REACT_APP_APIURL + "studentprofile/filters/yearlevel/" + this.state.logged_in + "/" + term, requestOptions)
      .then(res => res.json())
      .then((result) => {
        this.setState({
          isFilterYearLevelsLoaded: true,
          filterYearLevels: result.data.result
        });

        if (result.data.result.length > 0) {
          this.setState({
            filterYearLevelValue: result.data.result[0].id,
            isGoButtonDisabled: true
          }, () => {
            this.filterStudentsFunc(result.data.result[0].id);
          });
        } else {
          this.setState({
            filterYearLevels: [],
            ilterCourseValue: 0,
            filterCourses: [],
            filterStudentValue: 0,
            filterStudents: [],
            studentName: "",
            filterYearLevelValue: 0,
            isGoButtonDisabled: true
          });

          this.setState({
            isFilterCoursesLoaded: true,
            isFilterStudentsLoaded: true
          });
        }
      },(error) => {
        this.setState({
          isFilterYearLevelsLoaded: true,
          error
        });
      });
  }

  filterSemestersFunc(year=null) {
    this.setState({
      filterYearLevels: [],
      ilterCourseValue: 0,
      filterCourses: [],
      filterStudentValue: 0,
      filterStudents: [],
      studentName: "",
      filterYearLevelValue: 0,
      isGoButtonDisabled: true
    });

    this.setState({
      isFilterSemestersLoaded: true,
      filterSemesters: this.state.filterData.semesters,
      filterSemesterValue: this.state.role && this.state.role === "O" ? this.state.filterParamSemesterValue : 1
    }, () => {
      // if(this.state.role && this.state.role === "O"){
      //   this.filterStudentsFuncOnObserver();
      // }else{
      //   this.filterCourses(1,year);
      // }
      this.changeSemesterValue(this.state.filterSemesterValue)
    });
  }

  filterStudentsFuncOnObserver = () => {
    this.setState({
      isFilterStudentsLoaded: false,
      isFilterCoursesLoaded: false,
      filterStudents: [],
      filterCourses: [],
      isGoButtonDisabled: true
    });

    var students = this.state.filterData.students;

    if(students && students.length > 0){   
      this.setState({
        isFilterStudentsLoaded: true, 
        filterStudents: students
      }, () => {
        this.changeStudentValue(this.state.filterParamStudentValue);
      });
    } else {
      this.setState({
        isFilterStudentsLoaded: true, 
        isStudentNameLoaded: true,
        studentName:"",
        filterStudents: [{name: "No Data", id: ""}],
        isGoButtonDisabled: true
      }, () => {
        this.setNoCoursesOnObserver();
      });
    }
  }

  filterCoursesOnObserver = () => {
      this.setState({
        filterCourses: [],
        isGoButtonDisabled: true,
        isFilterCoursesLoaded: false
      });

      let courses = [];
      try{
        courses = this.state.filterData.courses[this.state.filterStudentValue][this.state.filterTermValue][this.state.filterSemesterValue];
      }catch(e){
      }
        

      if(courses && courses.length > 0){
        this.setState({
          filterCourses: courses
        }, () => {
          this.setState({
            isFilterCoursesLoaded: true,
            filterCourseValue: courses[0].id,
            isGoButtonDisabled: false
          }, () => {
            this.changeCourseValue(this.state.filterCourseValue);
          });
        });
      } else {
        this.setNoCoursesOnObserver();
      }
  }

  setNoCoursesOnObserver = () => {

    this.setState({
      filterCourses: [{name: "No Data", id:0}],
      isFilterCoursesLoaded: true,
      isGoButtonDisabled: true
    }, () => {
      this.setState({
        isSubChartLoading: false,
        isFeedbacksLoading: false,
        isLateSubChartLoading: false,
        isMarksByAssignmentsLoading: false,
        isWorkloadLoading: false,
        isActivityByTimeLoading: false,
        isNaplanLoading: false,
        isPatLoading: false,
        isCourseAnalysisByAssignmentLoading: false,
        isCourseAnalysisLoading: false,
        isMarksByAssignmentsLoaded: false
      });
    });
  }

  filterStudentsFunc(course = null) {
    const params = {logged_in: this.state.logged_in, term: this.state.filterTermValue, course: this.state.filterCourseValue, access: this.state.role_access};
    ApiServices.AccFilterService.studentsByCourse(params, this.state.token).then(res => res.json()).then((result) => {
      if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result.length > 0){
        this.setState({
          isFilterStudentsLoaded: true,
          filterStudents: result.data.result,
        });

        this.setState({
          filterStudentValue: result.data.result[0].id,
          isGoButtonDisabled: false
        }, () => {
          this.changeStudentValue(this.state.filterStudentValue);
        });
      } else {
        this.setNoStudents();
      }
    },(error) => {
      this.setState({
        isFilterStudentsLoaded: true,
        error
      });
    });
  }

  setNoStudents = () => {
    this.setState({
      isFilterStudentsLoaded: true,
      isStudentNameLoaded:true,
      filterStudents: [{name: "No Data", id: ""}],
      filterStudentValue: 0,
      studentName: "",
      isGoButtonDisabled: true
    }, () =>{
      this.setState({
        isSubChartLoading: false,
        isFeedbacksLoading: false,
        isLateSubChartLoading: false,
        isMarksByAssignmentsLoading: false,
        isWorkloadLoading: false,
        isActivityByTimeLoading: false,
        isNaplanLoading: false,
        isPatLoading: false,
        isCourseAnalysisByAssignmentLoading: false,
        isCourseAnalysisLoading: false
      });
    });
  }

  filterCourses(semester = null,term=null) {
    this.setState({
      isFilterStudentsLoaded: false,
      filterCourses: [],
      filterStudentValue: 0,
      filterStudents: [],
      studentName: "",
      isGoButtonDisabled: true,
      isFilterCoursesLoaded: false
    });

    var courses = this.state.filterData.courses[this.state.filterTermValue][this.state.filterSemesterValue];

    if(courses && courses.length > 0){ 
      this.setState({
        filterCourses: courses
      }, () => {
        this.setState({
          isFilterCoursesLoaded: true,
          filterCourseValue: courses[0].id,
          isGoButtonDisabled: false
        }, () => {
          this.changeCourseValue(this.state.filterCourseValue);
        });
      });
    } else {
      this.setState({
        isFilterCoursesLoaded: true,
        filterCourseValue: 0,
        filterCourses: [{name: "No Data", id: 0}],
        isGoButtonDisabled: true
      }, () => {
        this.setNoStudents();
      });
    }
  }

  // chart_pat() {
  //   const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue };
  //   ApiServices.AccStudentProfileService.latestPatMarks(params, this.state.token).then(res => res.json()).then((result) => {
  //     if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.response)){
  //       this.setState({
  //         isPatLoaded: true,
  //         pat: result.data.response.body,
  //       });
  //     } else {
  //       this.setState({
  //         isPatLoaded: true,
  //         pat: [],
  //       });
  //     }
  //   },(error) => {
  //     this.setState({
  //       isPatLoaded: true,
  //       error
  //     });
  //   });
  // }

  // chart_naplan() {
  //   const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue };
  //   ApiServices.AccStudentProfileService.latestNaPlanMarks(params, this.state.token).then(res => res.json()).then((result) => {
  //     if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.response)){
  //       this.setState({
  //         isNaplanLoaded: true,
  //         naplan: result.data.response.body,
  //       });
  //     }else{
  //       this.setState({
  //         isNaplanLoaded: true,
  //         naplan: [],
  //       });
  //     }
  //   },(error) => {
  //     this.setState({
  //       isNaplanLoaded: true,
  //       error
  //     });
  //   });
  // }

  chart_course_analysis_by_assignment() {
    const courseIds = [];
    var status = '';
    
    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds.push(this.state.filterCourses[i].id);
    }
    
    const params = { courseIds: courseIds, studentId: this.state.filterStudentValue, term: this.state.filterTermValue, semester: this.state.filterSemesterValue };
    ApiServices.AccStudentProfileService.CourseAnalysisByAssignment(params, this.state.token).then(res => res.json()).then((result) => {
      if(result.data.response && result.data.response.status !== 0){
        this.setState({
          isCourseAnalysisByAssignmentLoaded: true,
          courseAnalysisByAssignments: result.data.response.body,
        });
      } else {
        this.setState({
          isCourseAnalysisByAssignmentLoading: false
        });
      }
    },(error) => {
      this.setState({
        isCourseAnalysisByAssignmentLoaded: false,
        error
      });
    });
  }

  chart_marks_by_assignment() {
    const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, term: this.state.filterTermValue, semester: this.state.filterSemesterValue, access: this.state.role_access};
    ApiServices.AccStudentProfileService.marksByAssignment(params, this.state.token).then(res => res.json()).then((result) => {
      if(result.data.response && result.data.response.status !== 0){
        this.setState({
          isMarksByAssignmentsLoaded: true,
          marksByAssignments: result.data.response.body,
        });
      } else {
        this.setState({
          isMarksByAssignmentsLoading: false
        });
      }
    },(error) => {
      this.setState({
        isMarksByAssignmentsLoaded: false,
        error
      });
    });
  }

  chart_activity_by_time() {
    const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, year: this.state.filterTermValue, mode: this.state.filterActivityByTimeMode, semester:this.state.filterSemesterValue, term:this.state.filterTermValue, access: this.state.role_access};
    ApiServices.AccStudentProfileService.activityByTime(params, this.state.token).then(res => res.json()).then((result) => {
      if(result.data.response && result.data.response.status !== 0){
        this.setState({
          activityByTimeData: result.data.response.body,
        },() => {
            this.drawActivityByTimeChart();
        });
      } else {
        this.setState({
          isActivityByTimeLoading: false
        });
      }
    },(error) => {
      this.setState({
        isActivityByTimeLoaded: false,
        error
      });
    });
  }

  drawActivityByTimeChart(){
    this.setState({
      activity_by_time: {
        title: {
          text: ''
        },
        colors: ["#219653", "#FF5C5C", "#0D1D71", "#d43504"],
        chart: {
          type: "column",
          animation: true,
          style: {
            // fontFamily: "Roboto",
            color: "#444444"
          }
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          column: {
          //   stacking: 'percent',
          //   linewidth: 6,
          //   borderRadius: 1,
          //   pointPadding: 0,
          //   groupPadding: 0.2,
            // pointWidth: 20,
          //   padding: 5,
          },
          series: {
            stacking: 'normal',
            linewidth: 6,
            borderRadius: 1,
            // pointWidth: 40,
            marker: {
              radius: 9
            },
            lineWidth: 5
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.activityByTimeData.categories,
          title: { text: "TIME SLOT" },
          // min: 6,
          min: 0,
          max: this.state.activityByTimeData.categories.length > 5 ? 5 :this.state.activityByTimeData.categories.length - 1,
          scrollbar: {
            enabled: true
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          // formatter: function (tooltip) {
          //   console.log(this);
          //   // return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during " + this.state.activityByTimeData.categories[this.point.x] + " days";
          // }
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true
        },
        yAxis: {
          tickInterval: 1,
          minRange: 1,
          allowDecimals: false,
          startOnTick: true,
          endOnTick: true,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: { text: "NUMBER OF ACTIVITIES" }
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        // series: [{
        //   name: "Count",
        //   data: this.state.activityByTimeData.data,
        //   animation: {
        //     duration: 2000
        //     // Uses Math.easeOutBounce
        //     // easing: 'easeOutBounce'
        //   }
        // }
        // ]
        series: this.state.activityByTimeData.data,
      }
    },() => {
      this.setState({
        isActivityByTimeLoaded: true
      });
    });
  }

  table_feedbacks() {
    var tempCourseVal = this.state.filterCourseValue;
    var status = '';
    if(tempCourseVal.length > 1){
     
      this.setState({
        isCoursIdLoad: true
      })
    } 
    
    const params = { xxxX: "", courseIds: tempCourseVal, studentId: this.state.filterStudentValue, status: status, 
      semester:this.state.filterSemesterValue, term:this.state.filterTermValue , access: this.state.role_access};
    ApiServices.AccStudentProfileService.studentFeedbacks(params, this.state.token).then(res => res.json()).then((result) => {
      if(result.data.result.length > 0){
        this.setState({
          isFeedbacksLoaded: true,
          feedbacks: result.data.result
        });
      } else {
        this.setState({
          isFeedbacksLoading: false
        });
      }
    },(error) => {
      this.setState({
        isFeedbacksLoaded: false,
        error
      });
    });
  }

  // charts_workload() {
  //   const courseIds = [];

  //   for (var i = 0; i < this.state.filterCourses.length; i++) {
  //     courseIds[i] = this.state.filterCourses[i].id;
  //   }

  //   const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue };
  //   ApiServices.AccStudentProfileService.workLoad(params, this.state.token).then(res => res.json()).then((result) => {
  //     this.setState({
  //       isWorkloadLoaded: true,
  //       workLoadItem: result.data.result.body
  //     });
  //   },(error) => {
  //     this.setState({
  //       isWorkloadLoaded: true,
  //       workloadError: true,
  //       // workLoadItem: ["categories":]
  //       error
  //     });
  //   });
  // }

  course_anaysis_load(){
    this.setState({
      courseModalOpen: true 
    });

    this.loadCourseAnalaysis();
  }

  loadCourseAnalaysis(courseId = 0) {
    const courseIds = [];
    var statusRole = 'TE';
    
    for (var i = 0; i < this.state.filterCourses.length; i++) {
        courseIds.push(this.state.filterCourses[i].id);
    }

    this.setState({
      isCourseAnalysisLoaded: false,
      courseAnalysisData: []
    });

    const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue, status: statusRole, semester: this.state.filterSemesterValue, access: this.state.role_access };
    ApiServices.AccStudentProfileService.subjectAnalysis(params, this.state.token).then(res => res.json()).then((result) => {
      if(result.data.response){
        if(Object.keys(result.data.response.body.data).length > 0){
          this.setState({
            isCourseAnalysisLoaded: true,
            courseAnalysisData: result.data.response.body.data
          });

          let mainarr = [];
          for(let i=0;i<result.data.response.body.data.length;i++){
            let visual = {
              chart: {
                inverted: true,
                type: 'boxplot'
              },
              title: {
                text: ''
              },
              legend: {
                enabled: false
              },
              xAxis: {
                categories: result.data.response.body.data[i].name,
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                },
              },
              plotOptions: {
                boxplot: {
                  lineWidth: 2
                }
              },
              yAxis: {
                title: {
                  text: ''
                }
              },
              credits: {
                enabled: false
              },
              exporting: { enabled: false },
              series: [{
                name: 'COHORT',
                color:"#002b80",
                data: result.data.response.body.data[i].boxplot
              }, {
                name: 'STUDENT',
                color: "#801100",
                type: 'scatter',
                data: [result.data.response.body.data[i].scatter],
                tooltip: {
                  pointFormat: 'STUDENT: {point.y}'
                }
              }]
            };
            mainarr.push(visual);
          }
          this.setState({
            couseAnalysisChartsObjects: mainarr,
          }); 
        } else {
          this.setState({
            isCourseAnalysisLoading: false,
          });
        }
      }
    },(error) => {
      this.setState({
        isCourseAnalysisLoaded: false,
        error
      });
    });
  }

  charts_submissiondetails(courseId = 0) {
    this.setState({
      SubmissionModelOpen: true,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: []
    });

    const params = { term: this.state.filterTermValue, courseId: courseId, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue, semester: this.state.filterSemesterValue };
    ApiServices.AccStudentProfileService.submissionDetails(params, this.state.token).then(res => res.json()).then((result) => {
      this.setState({
        isSubChartLoaded: true,
        subDetailsChartItems: result.data.response.body,
        isSubChartDetailsLoaded: true
      });
    },(error) => {
      this.setState({
        isSubChartDetailsLoaded: false,
        error
      });
    });
  }

  charts_submissiondata() {
    const courseIds = [];
    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds.push(this.state.filterCourses[i].id);
    }

    const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue, semester: this.state.filterSemesterValue };
    ApiServices.AccStudentProfileService.submissionData(params, this.state.token).then(res => res.json()).then((result) => {
      this.setState({
        isSubChartLoaded: true,
        subChartItem: result.data.response.body,
        isLateSubChartLoaded: true
      });
    },(error) => {
      this.setState({
        isSubChartLoaded: false,
        error
      });
    });
  }

  late_submission_chart() {
    const courseIds = [];
    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds.push(this.state.filterCourses[i].id);
    }
    
    const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, semester: this.state.filterSemesterValue };
    ApiServices.AccStudentProfileService.lateSubmissionData(params, this.state.token).then(res => res.json()).then((result) => {
      this.setState({
        isLateSubmission: true,
        lateSubmission: result.data.response.body,
        isLateSubmissionLoaded: true
      });
    },(error) => {
      this.setState({
        isLateSubmission: false,
        error
      });
    });
  }

  componentDidMount() {
    this.filterTerms();
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  getRenderedHtml = (value) => {
    if(value === "PENDING")
      return <i>{value}</i>
    return value
  }

  generatepdf = () => {
    const input = document.getElementById('collddd');
    html2canvas(input)
        .then((canvas) => {
          const { width, height } = canvas;
          var pdf = new jsPDF({
            orientation: width > height? 'l': 'p',
            unit: "mm",
            format: [(height + 20) * 0.2645833333, (width + 20) * 0.2645833333]
          });
          const imgData = canvas.toDataURL('image/png');

          pdf.addImage(imgData, 'PNG', 2, 0);
          pdf.save("Student_Profile_" + moment().tz('Australia/Lindeman').format('YYYY-MM-DD hh:mm:ss'));
        });
  }

  render() {
    var setModelopen = function (index) {
      gThis.charts_submissiondetails(index);
    };

    var closeModal = function () {
      gThis.setState({
        SubmissionModelOpen: false
      });
    };

    var closeModalCourse = function () {
      gThis.setState({
        courseModalOpen: false
      });
    };

    var easeOutBounce = function (pos) {
      if ((pos) < (1 / 2.75)) {
        return (7.5625 * pos * pos);
      }
      if (pos < (2 / 2.75)) {
        return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
      }
      if (pos < (2.5 / 2.75)) {
        return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
      }
      return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
    };

    Math.easeOutBounce = easeOutBounce;

    const gState = this.state;
    const gThis = this;

    const colors1 = ["#0266C8", "#F90101", "#F2B50F", "#00933B"];
    const colors = ["#164a90", "#3b3b8a", "#a80c03", "#d43504"];
    const submission_colors = ["#ff8800", "#228a3b", "#db043a", "#d43504"];
    const subjectassignmentComparisonColors = ["#1d6a38", "#33a759", "#47dd7c", "#0d1d71", "#295790", "#5267db", "#f43737", "#f55c5d", "#f78d8d", "#f67706", "#f68e33", "#f0a767", "#5b5a5a", "#787878", "#aba1a1"];
    
    let chart1NP = {
      title: {
        text: ''
      },
      colors: colors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 2000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.marksByAssignments.categories,
        title: {
          text: 'ASSIGNMENT NAME'
          //align: 'center'
        }
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        min: 0,
        max: 100,
        title: {
          text: 'MARKS AS A PERCENTAGE'
          //align: 'center'
        }
      },
      tooltip: {
        formatter: function (tooltip) {
          if (gState.isMarksByAssignmentsLoaded) {
            //    return "Assignment name : <b>" + this.point.category + "<b/><br/>Marks : <b>" + this.y + "% (" + gState.marksByAssignments.tooltipData[this.point.x]["rawmark"] + "/" + gState.marksByAssignments.tooltipData[this.point.x]["outof"] + ") ";
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.marksByAssignments.data
    };

    let chart1 = {
      title: {
        text: ''
      },
      colors: subjectassignmentComparisonColors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        autoRotation: false,
        categories: this.state.marksByAssignments.categories,
        title: {
          text: "ASSIGNMENT NAME"
        },
        min: 0,
        max: this.state.marksByAssignments.categories ? this.state.marksByAssignments.categories.length > 16 ? 16 :this.state.marksByAssignments.categories.length - 1 : '',
        scrollbar: {
          enabled: true
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "STUDENT PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.marksByAssignments.data
    };

    let courseAnalysisChart = {
      title: {
        text: ''
      },
      colors: subjectassignmentComparisonColors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.courseAnalysisByAssignments.categories,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "GRADES PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.courseAnalysisByAssignments.data
    };

    let submission_details_chart = {
      title: {
        text: ''
      },
      colors: submission_colors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.subDetailsChartItems.categories,
        title: {
          text: "ASSIGNMENT NAME"
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.subDetailsChartItems.data
    };

    let chart2 = {
      title: {
        text: ''
      },
      colors: submission_colors,
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        },
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          stacking: "percent",
          linewidth: 6,
          borderRadius: 1
        },
        column: {
          events: {
            click: function (event) {
              var index = event.point.index;
              setModelopen(gState.subChartItem.categoryIds[index]);
            }
          }
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.subChartItem.categories,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
        shared: true
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.subChartItem.data
    };

    let late_submission = {
      title: {
        text: ''
      },
      colors: ["#022d73", "#01783f"],
      chart: {
        type: "column",
        animation: true,
        style: {
          // fontFamily: "Roboto",
          color: "#444444"
        }
      },
      plotOptions: {
        series: {
          linewidth: 6,
          borderRadius: 1
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: this.state.lateSubmission.categories,
        title: {
          text: "COURSE NAME"
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function (tooltip) {
          if (gState.isMarksByAssignmentsLoaded) {
            if (this.series.name == "COHORT") {
              return "<b>" + this.point.category + "<b/><br/>" + this.series.name + "<b> : " + gState.lateSubmission.data2[0]["dataCounts"][this.point.x] + " (" + this.y + "%)";
            } else {
              return "<b>" + this.point.category + "<b/><br/>" + this.series.name + "<b> : " + gState.lateSubmission.data2[1]["dataCounts"][this.point.x] + " (" + this.y + "%)";
            }
          }
        }
      },
      yAxis: {
        title: {
          text: ''
          //align: 'center'
        },
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "SUBMISSIONS AS A PERCENTAGE"
        }
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      series: this.state.lateSubmission.data2
    };

    // let activity_by_time = {
    //     title: {
    //       text: ''
    //     },

    //     colors: ["#164a90", "#3b3b8a", "#a80c03", "#d43504"],
    //     chart: {
    //       type: "column",
    //       animation: true,
    //       style: {
    //         // fontFamily: "Roboto",
    //         color: "#444444"
    //       }
    //     },
    //     legend: {
    //       enabled: false
    //     },
    //     plotOptions: {
    //       // column: {
    //       //   stacking: 'percent',
    //       //   linewidth: 6,
    //       //   borderRadius: 1,
    //       //   pointPadding: 0,
    //       //   groupPadding: 0.2,
    //       //   pointWidth: 20,
    //       //   padding: 5,
    //       // },
    //       series: {
    //         stacking: 'percent',
    //         linewidth: 6,
    //         borderRadius: 1,
    //         pointWidth: 40,
    //         marker: {
    //           radius: 9
    //         },
    //         lineWidth: 5
    //       }
    //     },
    //     xAxis: {
    //       gridLineWidth: 1,
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       categories: gState.activityByTimeData.categories,
    //       title: { text: "TIME SLOT" },
    //       // min: 6,
    //       min: 0,
    //       max: gState.activityByTimeData.categories.length - 1,
    //       scrollbar: {
    //         enabled: true
    //       }
    //     },
    //     credits: {
    //       enabled: false
    //     },
    //     tooltip: {
    //       formatter: function (tooltip) {
    //         console.log(this);
    //         // return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during " + this.state.activityByTimeData.categories[this.point.x] + " days";
    //       }
    //     },
    //     yAxis: {
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       title: { text: "NUMBER OF ACTIVITIES" }

    //     },
    //     legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //     background2: "#505053",
    //     dataLabelsColor: "#B0B0B3",
    //     textColor: "#C0C0C0",
    //     contrastTextColor: "#F0F0F3",
    //     maskColor: "rgba(255,255,255,0.3)",

    //     // series: [{
    //     //   name: "Count",
    //     //   data: this.state.activityByTimeData.data,
    //     //   animation: {
    //     //     duration: 2000
    //     //     // Uses Math.easeOutBounce
    //     //     // easing: 'easeOutBounce'
    //     //   }
    //     // }
    //   // ]
    //     series: gState.activityByTimeData.data,
    //   };

    let patchart = {
      chart: {
        type: 'boxplot'
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: this.state.pat.categories,
        title: {
          text: 'SKILL'
        }
      },
      plotOptions: {
        boxplot: {
            lineWidth: 2
          // medianColor: '#0C5DA5',
          //  medianDashStyle: 'ShortDot',
          //  medianWidth: 3,
          // stemColor: '#A63400',
          //  stemDashStyle: 'dot',
          //  stemWidth: 1,
        //   whiskerColor: '#3D9200',
          //  whiskerLength: '20%',
          //  whiskerWidth: 3
        }
      },
      yAxis: {
        title: {
          text: 'SCALED SCORE'
        } 
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'PAT',
        color:"#002b80",
        data: this.state.pat.data
      }, {
        name: 'STUDENT',
        color: "#801100",
        type: 'scatter',
        data: this.state.pat.scatterData,
        tooltip: {
          pointFormat: 'STUDENT: {point.y}'
        }
      }]
    };

  let naplanchart = {
    chart: {
      type: 'boxplot'
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: this.state.naplan.categories,
      title: {
        text: 'SKILL'
      }
    },
    plotOptions: {
      boxplot: {
          lineWidth: 2
        // medianColor: '#0C5DA5',
        //  medianDashStyle: 'ShortDot',
        //  medianWidth: 3,
        // stemColor: '#A63400',
        //  stemDashStyle: 'dot',
        //  stemWidth: 1,
      //   whiskerColor: '#3D9200',
        //  whiskerLength: '20%',
        //  whiskerWidth: 3
      }
    },
    yAxis: {
      title: {
        text: 'SCALED SCORE'
      } 
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'NAPLAN',
      color:"#002b80",
      data: this.state.naplan.data
    }, {
      name: 'STUDENT',
      color: "#801100",
      type: 'scatter',
      data: this.state.naplan.scatterData,
      tooltip: {
        pointFormat: 'STUDENT: {point.y}'
      }
    }]
  };

  let workload = {
    title: {
      text: ''
    },
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1
    },
    colorAxis: {
      min: 0,
      minColor: "#ffffff",
      maxColor: colors[0]
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: ["WEEK 1", "THIS WEEK", "NEXT WEEK", "2 WEEKS FROM NOW", "3 WEEKS FROM NOW"],
      title: {
        text: "NEXT FOUR WEEKS"
      }
    },
    yAxis: {
      gridLineColor: "#F3F3F3",
      lineColor: "#F3F3F3",
      minorGridLineColor: "#F3F3F3",
      tickColor: "#F3F3F3",
      tickWidth: 1,
      categories: this.state.workLoadItem.categories,
      title: null,
      reversed: true,
      title: {
        text: "COURSE NAME"
      }
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        descriptionFormatter: function (point) {
        }
      }
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },
    tooltip: {
      // formatter: function () {                    
      //   return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> Yearly Average: <b>$' +	
      //           Highcharts.numberFormat(this.series.chart.yAxis[1].categories[this.point.y],2) + '</b><br>' + 
      //           'Average Labour Rate: <b>' + '$'+Highcharts.numberFormat(this.point.value, 2) + '</b><br>' + 
      //           '<b>' + this.series.xAxis.categories[this.point.x];
      // }
      formatter: function (tooltip) {
        if (gState.isWorkloadLoaded) {
          if (this.point.value > 0) {
            return gState.workLoadItem.allData[this.point.y].tooltips[this.point.x - 1];
          } else {
            return false;
          }
        } else {
          return "";
        }
      }
    },
    series: [{
      name: 'Work Load',
      borderWidth: 1,
      data: this.state.workLoadItem.data,
      dataLabels: {
        enabled: true,
        color: '#000000'
      }
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 400
        },
        chartOptions: {
          yAxis: {
            labels: {
              formatter: function () {
                return this.value.charAt(0);
              }
            }
          }
        }
      }]
    }
    };

  const settings = {
    className: "center",
    centerMode: true,
    speed: 500,
    arrows: false,
    centerPadding: "5px",
    slidesToShow: 1,
    slidesToScroll: 1
  };

  let courseAnalysisHtml = "";

  return (
    <Fragment>
      <div className="container-fluid " >
        <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
          <div className="card custom-card  " style={{ backgroundColor: "#e0e0e0", marginTop: '90px', paddingBottom: '10px', paddingTop: '10px' }}>
            <div className="row ">
              <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                <div className="row " style={{ marginTop: '20px' }} >
                  <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <span className="m-0">YEAR</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits " value={this.state.filterTermValue} onChange={(e) => this.changeTermValue(e.target.value)}>
                                {this.state.isFilterTermsLoaded ? 
                                  this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                        <div className="media static-top-widget">
                          <div className="media-body"><span className="m-0">SEMESTER</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits" value={this.state.filterSemesterValue} onChange={(e) => this.changeSemesterValue(e.target.value)}>
                                {this.state.isFilterSemestersLoaded ? 
                                  this.state.filterSemesters.map((semester) => <option key={semester.id} value={semester.id}>{semester.name}</option>)
                                  : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    
                  {this.state.role && this.state.role == 'O' ?
                    <Fragment>
                      <div className="col-sm-3 col-md-3 col-xs-12 col-xl-3 col-lg-3">
                        <div className="card o-hidden custom-cardx">
                          <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                            <div className="media static-top-widget">
                              <div className="media-body"><span className="m-0">STUDENT</span>
                                <h4 className="mb-0 counter">
                                  <select className="form-control digits" value={this.state.filterStudentValue} onChange={(e) => this.changeStudentValue(e.target.value)}>
                                    {this.state.isFilterStudentsLoaded ? 
                                      this.state.filterStudents.map((filterStudents) => <option key={filterStudents.id} value={filterStudents.id}>{filterStudents.name} {filterStudents.id !==0 && filterStudents.id !== "" ? "-" : ""} {filterStudents.id}</option>)
                                      : <option>Loading...</option>
                                    }
                                  </select>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-sm-3 col-md-3 col-xs-12 col-xl-3 col-lg-3">
                        <div className="card o-hidden custom-cardx">
                          <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                            <div className="media static-top-widget">
                              <div className="media-body"><span className="m-0">COURSE</span>
                                <h4 className="mb-0 counter">
                                  <select className="form-control digits" value={this.state.filterCourseValue} onChange={(e) => this.changeCourseValue(e.target.value)}>
                                    {this.state.isFilterCoursesLoaded
                                      ? this.state.filterCourses.map((filterCourses) => <option key={filterCourses.id} value={filterCourses.id}>{filterCourses.name}</option>)
                                      : <option>Loading...</option>
                                    }
                                  </select>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                    :       
                    <Fragment>
                      <div className="col-sm-3 col-md-3 col-xs-12 col-xl-3 col-lg-3">
                        <div className="card o-hidden custom-cardx">
                          <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                            <div className="media static-top-widget">
                              <div className="media-body"><span className="m-0">COURSE</span>
                                <h4 className="mb-0 counter">
                                  <select className="form-control digits" value={this.state.filterCourseValue} onChange={(e) => this.changeCourseValue(e.target.value)}>
                                    {this.state.isFilterCoursesLoaded
                                      ? this.state.filterCourses.map((filterCourses) => <option key={filterCourses.id} value={filterCourses.id}>{filterCourses.name}</option>)
                                      : <option>Loading...</option>
                                    }
                                  </select>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-sm-3 col-md-3 col-xs-12 col-xl-3 col-lg-3">
                        <div className="card o-hidden custom-cardx">
                          <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                            <div className="media static-top-widget">
                              <div className="media-body"><span className="m-0">STUDENT</span>
                                <h4 className="mb-0 counter">
                                  <select className="form-control digits" value={this.state.filterStudentValue} onChange={(e) => this.changeStudentValue(e.target.value)}>
                                    {this.state.isFilterStudentsLoaded
                                      ? this.state.filterStudents.map((filterStudents) => <option key={filterStudents.id} value={filterStudents.id}>{filterStudents.name} - {filterStudents.id}</option>)
                                      : <option>Loading...</option>
                                    }
                                  </select>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                    }
                </div>
              </div>

              <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2">
                <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled} style={{ marginTop: '48px', width: "100%" }} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
          <div className="card custom-card  ">
            <div className="row">
              <div className="col-2 col-sm-2">
                <img src={accimage}></img>
              </div>
              <div className="col-5 col-sm-5 with-border">
                <div className="student-name">
                  <h5><span className="counter">STUDENT PROFILE</span></h5>
                    {this.state.isStudentNameLoaded ? 
                      <Fragment>
                          <h4><b>{this.state.studentName} </b></h4>
                          <h6>{`${this.state.filterTermValue} - Semester ${this.state.filterSemesterValue}`}</h6>
                          <h6 className="printed-on">Printed on {moment().format("DD/MM/YYYY")}</h6>
                      </Fragment>
                    : this.state.loader
                    }
                </div>
              </div>

              <div className="col-5 col-sm-5 ">
                {this.state.role && this.state.role == 'O' ? null :
                  <button  style={{ marginTop: '48px', width: "80%",backgroundColor:"#003f7a",color:"#ffffff"}} className="btn btn-lg"  onClick={(e) => this.course_anaysis_load()}><FontAwesomeIcon icon={faBook} /> SUBJECT ANALYSIS &gt;&gt;</button>
                }

                <div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="student-profile">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-md-8 col-lg-12">
              <div className="card  ">
                <div className="card-header bg-custom-dark">
                  <h5>GRADES COMPARISON BY ASSIGNMENT</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive">
                    <div height=" ">
                      {this.state.isMarksByAssignmentsLoaded ? 
                        <Fragment>
                          {this.state.role && this.state.role == "O" ? null : <h5><span className="counter">STUDENT</span></h5> }
                            <div className="table-responsive" style={{ height:"300px", display: "block" ,marginBottom:"20px"}}>
                              {this.state.marksByAssignments.studentGrades.length > 0 ? 
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th style={{ border:"1px solid black"}} >Assignment</th>
                                      {
                                        this.state.marksByAssignments.studentGrades.map((assignmentData) =><th key={assignmentData.name} style={{ border:"1px solid black"}}>{assignmentData.name}</th>)
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td style={{ border:"1px solid black"}}>GRADE</td>
                                      {
                                        this.state.marksByAssignments.studentGrades.map((assignmentData, index) =><td key={index}  style={{ color:"#ffffff",padding:"4px",border:"1px solid black",backgroundColor: subjectassignmentComparisonColors[assignmentData.colorIndex],fontWeight:"bold",textAlign:"center" }}>{assignmentData.grade}</td> )
                                      }
                                    </tr>
                                  </tbody>
                                </table>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                              }
                            </div>
                    
                          {this.state.role && this.state.role == "O" ? null :
                            <div>
                              <h5><span className="counter">COHORT</span></h5>
                              {this.state.isMarksByAssignmentsLoaded
                                ? <HighchartsReact
                                  highcharts={Highcharts}
                                  options={chart1} />
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              }
                            </div>
                          }
                        </Fragment>
                        : this.state.isMarksByAssignmentsLoading ?
                          <div style={{ textAlign: "center", display: this.state.isMarksByAssignmentsLoaded ? "none" : "block" }}><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

              {this.state.role && this.state.role == "O" ? null :
                <Fragment>
                  <div className="col-xl-12 col-sm-12 col-md-8 col-lg-12">
                    <div className="card  ">
                      <div className="card-header bg-custom-dark">
                        <h5>COURSE ANALYSIS BY ASSIGNMENT</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive">
                          <div height=" ">
                            {this.state.isCourseAnalysisByAssignmentLoaded
                              ? <HighchartsReact
                                highcharts={Highcharts}
                                options={courseAnalysisChart} />
                              : 
                              this.state.isCourseAnalysisByAssignmentLoading ? 
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  <div className="col-xl-6 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSIONS BY COURSE</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive">
                          <div>
                            {this.state.isSubChartLoaded
                              ?
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={chart2}
                              />
                              : this.state.isSubChartLoading ?
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>Late Submissions Comparison</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive">
                          <div>
                            {this.state.isLateSubmissionLoaded ?
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={late_submission}
                              />
                              : this.state.isLateSubmissionLoading ? 
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              }

              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Activity By Time Of The Day</h5>
                  </div>
                  <div className="card-body">
                    {
                      this.state.isActivityByTimeLoading ?
                      <div className="row">
                        <div className="col-xl-9">
                          <div className="user-status table-responsive">
                            {this.state.isActivityByTimeLoaded ? 
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.activity_by_time}
                              />
                              : this.state.isActivityByTimeLoading ?
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                            }
                          </div>
                        </div>
                        <div className="col-xl-3 d-flex flex-column justify-content-start stp-activity-filter">
                          <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'M' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('M')}><span>MONTH WISE</span></div>{' '}
                          <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'W' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('W')}><span>WEEK WISE</span></div>{' '}
                          <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'D' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('D')}><span>DAY WISE</span></div>{' '}
                        </div>
                      </div>
                      : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                    }
                    
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Workload By Week</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">

                      {this.state.isWorkloadLoaded
                        ?
                        <div style={{height:this.state.workLoadItem.categories.length*50}}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={workload}
                            containerProps={{ style: { height:this.state.workLoadItem.categories.length*50} }}
                          />
                        </div>
                        : this.state.isWorkloadLoading ? 
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }

                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Latest Naplan</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">

                      {this.state.isNaplanLoaded
                        ?
                        <div style={{ height: 5 * 90 }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={naplanchart}
                            containerProps={{ style: { height: 5 * 90 } }}
                          />
                        </div>
                        : this.state.isNaplanLoading ?
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }

                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Latest Pat</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">

                      {this.state.isPatLoaded
                        ?
                        <div style={{ height: 5 * 90 }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={patchart}
                            containerProps={{ style: { height: 5 * 90 } }}
                          />
                        </div>
                        : this.state.isPatLoading ?
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }

                    </div>
                  </div>
                </div>
              </div> */}


              <div className="col-xl-12 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Latest Feedback</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                      {this.state.isFeedbacksLoaded ? 
                        <Fragment>
                          <div className="table-responsive" style={{ display: this.state.isFeedbacksLoaded ? "block" : "none" }}>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Course</th>
                                  <th scope="col">Assignment</th>
                                  <th scope="col">Feedback</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.isFeedbacksLoaded ?
                                  this.state.feedbacks.map((feedback, index) => 
                                    <tr key={index}>
                                      <td>{feedback.course}</td>
                                      <td>
                                        {
                                          this.state.isCoursIdLoad ?
                                            <a href={feedback.url} target="_blank">{feedback.assignment}</a>
                                          :  feedback.assignment 
                                        }
                                      </td>
                                      <td>{feedback.comment}</td>
                                    </tr>
                                  )
                                  : "NO DATA AVAILABLE"
                                }
                              </tbody>
                            </table>
                          </div>
                        </Fragment>
            
                        : this.state.isFeedbacksLoading ?    
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.SubmissionModelOpen} onClose={closeModal} classNames={{
          modal: 'student-profile-sbmissions-customModal',
        }}>
          <div className="modal-body">
            <div className="product-modal row">
              <div className="student-profile cust-model-size">
                <div className="row">
                  <div className="col-xl-12 xl-100 mt-4">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSIONS DETAILS</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive custom-table-class">
                          {this.state.isSubChartDetailsLoaded ? 
                            <Fragment>
                              <div className="table-responsive" style={{ display: this.state.isSubChartDetailsLoaded ? "block" : "none" }}>
                                <table className="table table-bordered submission-details-table">
                                  <thead>
                                    <tr>
                                      <th scope="col">ASSIGNMENT</th>
                                      <th scope="col">STATUS</th>
                                      <th scope="col">DUE DATE</th>
                                      <th scope="col">SUBMITTED DATE</th>
                                      <th scope="col">DAYS LATE</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.isSubChartDetailsLoaded ?
                                      this.state.subDetailsChartItems.table.map((subDetails, index) => 
                                        <tr key={index}>
                                          <td>{subDetails.assignment}</td>
                                          <td>{subDetails.status}</td>
                                          <td>{subDetails.dueDate}</td>
                                          <td>{subDetails.submitted}</td>
                                          <td>{subDetails.delay}</td>
                                        </tr>
                                      )
                                      : "NO DATA AVAILABLE"
                                    }
                                  </tbody>
                            </table>
                          </div>
                            </Fragment>
                            : this.state.isSubChartLoaded ?    
                              <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal open={this.state.courseModalOpen} onClose={closeModalCourse}   classNames={{modal: 'modal-90w'}} >
        <div class="modal-header">
              <div class="ml-auto">
                <button disabled={!this.state.isCourseAnalysisLoaded} readOnly={this.state.isCourseAnalysisLoaded} style={{marginTop: '24px', width: "100%",}} className="btn btn-secondary btn-lg" onClick={(e) => this.generatepdf()}>Export to PDF</button>             
              </div>
        </div>
          <div className="modal-body" id="collddd">
            <div className="product-modal row">
              <div className="user-profile cust-model-size">
                <div className="row" style={{width: "100%"}}>
                  <div className="col-md-4 col-lg-4 ">
                    <h6 style={{ textAlign: "center"}}>SUBJECT</h6>
                  </div>
                  <div className="col-lg-2 col-md-2">
                    <h6 style={{ textAlign: "center"}}>SUBJECT GRADE</h6>
                  </div>
                  {/* <div className="col-lg-2 col-md-2">
                    <h6 style={{ textAlign: "center"}}>ENGAGEMENT GRADE</h6>
                  </div> */}
                  <div className="col-lg-4 col-md-4 ">
                    <h6 style={{ textAlign: "center"}}>MARKS DISTRIBUTION</h6>
                  </div>
                  <div className="col-lg-2 col-md-2 ">
                    <h6 style={{ textAlign: "center"}}>COHORT AVERAGE</h6>
                  </div>
                </div>               
                {this.state.isCourseAnalysisLoaded ? 
                  this.state.courseAnalysisData.map((course, index) => (           
                    <div>            
                      <div className="row" style={{height:"200px",marginBottom:"20px"}}>
                        <div className=" col-lg-4 col-md-4 " style={{margin:"auto"}}>
                          <div className="card-body" style={{backgroundColor:"#003470",color:"#ffffff",height:"100%",marginBottom:"10px"}}>
                            <div className="user-status table-responsive">
                              <div style={{textAlign:"center",marginTop:"auto",marginBottom:"auto", fontSize:"18px"}}>
                                { course.name }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-lg-2 col-md-2 " style={{margin:"auto"}}>
                          <div style={{textAlign:"center",marginTop:"auto",marginBottom:"auto"}}>
                            <h4>{this.getRenderedHtml(course.subject_grade)}&ensp;({course.finalMark})</h4>
                          </div>
                        </div>
                        {/* <div className=" col-lg-2 col-md-2" style={{margin:"auto"}} >
                          <div style={{textAlign:"center",marginTop:"auto",marginBottom:"auto"}}>
                            <h4> {this.getRenderedHtml(course.engagement_grade) }</h4>
                          </div>
                        </div>          */}
                        <div className=" col-lg-4 col-md-4 " style={{margin:"auto"}}>
                          <div style={{height:"130px"}} >
                            <HighchartsReact  
                              highcharts={Highcharts}
                              options={this.state.couseAnalysisChartsObjects[index]}
                              containerProps={{ style: { height: 130 } }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 " style={{margin:"auto"}}>
                          <div style={{textAlign:"center",marginTop:"auto",marginBottom:"auto"}} >
                            <h4>{ this.getRenderedHtml(course.cohort_average) }</h4>
                          </div>     
                        </div>        
                      </div>  
                    </div>    
                  ))
                  : this.state.isCourseAnalysisLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                  : <div style={{ display: 'flex', justifyContent: 'center', padding: '100px' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                }
 

                <div className="col-xl-12 xl-100" style={{margin:"auto",textAlign:"center"}}>
                  <img src={boxplotimage} />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default AccStudentProfile;