import React, {memo} from 'react';
import {Col} from "antd";
import StatsCard from "./StatsCard";
import PropTypes from "prop-types";

const StatCardGroup = ({summaries, isCohortVisible}) => {
    return summaries.map(summary => (
        <Col sm={24} md={12} lg={12} xl={6} key={summary.title}>
            <StatsCard summary={summary} isCohortVisible={isCohortVisible}/>
        </Col>
    ))
};

StatCardGroup.propTypes = {
    summaries: PropTypes.array,
    isCohortVisible: PropTypes.bool,
};

export default memo(StatCardGroup);