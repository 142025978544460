import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Row, Tooltip } from "antd";
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteAdminToken } from "../../../../../../actions/dashboardManagement/dashboard.action";
import PopUpModal from "../../../../../common/popup-modal/popup-modal";

const AdminTokenCard = ({ isDeleteDisabled = false, tokenInfo: { tokenName, created, expireIn, isExpired = true, apiKey, credentialId, status,consumerId } }) => {
    const [isModalVisible, setPopupModalVisible] = useState(false);
    const dispatch = useDispatch();

    const onDelete = () => {
        const payload = {
            type: "adminToken",
            consumer: consumerId,
            fetchId: credentialId,
        }
        dispatch(deleteAdminToken(payload));
    }

    const tokenStatus = status.charAt(0).toUpperCase() + status.slice(1);

    const statsClassNameExtension = () => {
        switch (status) {
            case "active":
                return "status-active"
            case "inactive":
                return "status-inactive"
            case "expired":
                return "status-expired"
            default:
                return ""
        }
    }

    return (
        <Fragment>
            {isModalVisible &&
                <PopUpModal
                    title={"View admin token"}
                    okText={"Done"}
                    setIsModalVisible={setPopupModalVisible}
                    isModalVisible={isModalVisible}
                >
                    <Col>
                        <p>Token</p>
                        <input
                            className="form-control input-number"
                            type="text"
                            value={apiKey}
                            readOnly={true} />
                    </Col>
                </PopUpModal>
            }
            <Card className="userInfoCard">
                <Row>
                    <Col md={24} lg={12}>

                        <h6>{tokenName?.length ? tokenName : "Default"}</h6>
                        <div className="infoRow">
                            <p>Created: <Tooltip title={created}>{created}</Tooltip></p>
                            <p>Expired date: <Tooltip title={expireIn}>{expireIn}</Tooltip></p>
                        </div>
                    </Col>
                    <Col md={24} lg={12}>
                        <div className="actionButtons">
                            <div className="statusChip">
                                {
                                    status &&
                                    <div
                                        className={statsClassNameExtension()}
                                    >
                                        <p>{tokenStatus}</p>
                                    </div>
                                }
                            </div>
                            <Button
                                className="copy-btn"
                                type="primary" ghost
                                onClick={() => {
                                    setPopupModalVisible(true);
                                }}
                                icon={<EyeOutlined />}
                            >
                                View token
                            </Button>
                            <Button
                                danger
                                ghost
                                icon={<DeleteOutlined />}
                                disabled={isDeleteDisabled}
                                onClick={() => { onDelete() }}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    )
}

export default AdminTokenCard;
