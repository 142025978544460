import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import _camelCase from "lodash/camelCase";
import _get from "lodash/get";
import StatCardGroup from "../../common/cards/StatCardGroup";

import {resolveFromArrayByRange} from "../../../../utils/innovative/resolveSettings";
import {
    makeActingRoleResponseData,
    makeInnovativeMasterSettingByPath,
    makeInnovativeStudentSelectedCourseSettingsData
} from "../../../../selectors/innovative_student/commonData.selector";
import {
    makeInnovativeAssignmentsSummaryCardsResponseData
} from "../../../../selectors/innovative_student/assignment.selector";
import {resolveCohortVisibility} from "../../../../utils/innovative_student/resolveSettings";

const flashIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/flash.svg`;
const questionMarkIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/question-mark.svg`;
const speedometerIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/speedometer.svg`;
const stopwatchIcon = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/stopwatch.svg`;


const TopCardContainer = () => {
    // selectors
    const settings = useSelector(makeInnovativeStudentSelectedCourseSettingsData);
    const summaries = useSelector(makeInnovativeAssignmentsSummaryCardsResponseData);
    const actingRole = useSelector(makeActingRoleResponseData);
    const cohortVisibility = useSelector(makeInnovativeMasterSettingByPath("features.cohortVisibility"));

    const resolveCohortVisible = useCallback(() => {
        return resolveCohortVisibility(actingRole, cohortVisibility, {compareVal: "role", returnVal: "access"})
    }, [actingRole, cohortVisibility]);

    const isCohortVisible = resolveCohortVisible();

    // a mapping for each card with settings
    const summaryMapping = {
        performanceScore: {
            settingPath: "conditionalColors.performanceScore",
            img: speedometerIcon,
        },
        engagementScore: {
            settingPath: "conditionalColors.engagementScore",
            img: flashIcon,
        },
        missingSubmissions: {
            settingPath: "conditionalColors.missingSubmission",
            img: questionMarkIcon,
        },
        lateSubmissions: {
            settingPath: "conditionalColors.lateSubmission",
            img: stopwatchIcon,
        }
    };

    // here we resolve colors for each summary and will merge with default values
    const getSummaries = useCallback(() => {
        return summaries.map(summary => {
            const mapping = summaryMapping[_camelCase(summary.title)];
            const selected = {
                ...mapping,
                ...summary
            };
            const item = _get(selected, 'content.datum', []);
            const resolved = resolveFromArrayByRange({
                value: item.value,
                settings: _get(settings, selected.settingPath, []),
            });
            // setting a default color if no color found
            item.color = _get(resolved, 'styles.color', "#262626");
            selected.content.datum = item;
            selected.color = item.color;
            return selected;
        });
    }, [settings, summaries]);

    return <StatCardGroup summaries={getSummaries()} isCohortVisible={isCohortVisible}/>
};

export default TopCardContainer;