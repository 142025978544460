import React from "react";
import {Col, Row, Space, Typography, Card} from "antd";
import PropTypes from "prop-types";

import ValueCard from "./partials/ValueCard";
import ChartCard from "./partials/chartCard/ChartCard";
import LineChartCard from "../../../../../common/lineChartCard/LineChartCard";
import Loader from "../../../../../../../../common/layout/Loader";

const Overview = ({onPopupOpen, dataLoading, overviewData}) => {
    const {lineChartData, disabilities, requirementMet, targetNotCompleted, currentUnitProgress, expectedUnitProgress, attendance} = overviewData;
    return (
        <div>
            <Loader isLoading={dataLoading}>
                <Row gutter={[16,16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <LineChartCard title="Academic Risk Over Time" data={lineChartData}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} >
                        <Space direction="vertical" style={{width: "100%"}} className="overviewVisuals">
                            <Row gutter={16} className="topRow">
                                <Col span={8}>
                                    <ValueCard
                                        title="Students with disabilities"
                                        value={disabilities?.value}
                                        color={disabilities?.color}
                                        onClick={() => onPopupOpen("disabilities")}
                                        className="overview-clickable-card"
                                    />
                                </Col>
                                <Col span={8}>
                                    <ValueCard
                                        onClick={() => onPopupOpen("requirementMet")}
                                        className="overview-clickable-card"
                                        title="Entry requirement met"
                                        value={requirementMet?.value}
                                        color={requirementMet?.color}
                                        enabled={requirementMet?.enabled}
                                    />
                                </Col>
                                <Col span={8}>
                                    <ValueCard
                                        onClick={() => onPopupOpen("targetNotCompleted")}
                                        className="overview-clickable-card"
                                        title="Not completed on target date"
                                        value={targetNotCompleted?.value}
                                        color={targetNotCompleted?.color}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={16}>
                                    <Card onClick={() => onPopupOpen("unitProgress")} className="overview-clickable-card">
                                        <Typography.Paragraph className="cardHeading">Unit Progress</Typography.Paragraph>
                                        <Space align="center" style={{display:"flex", justifyContent:"space-around"}}>
                                            <ChartCard
                                                name="Current Unit Progress"
                                                percentage={currentUnitProgress?.percentage}
                                                data={currentUnitProgress?.data}
                                                enabled={currentUnitProgress?.enabled}
                                            />
                                            <ChartCard
                                                name="Expected Unit Progress"
                                                percentage={expectedUnitProgress?.percentage}
                                                data={expectedUnitProgress?.data}
                                                enabled={expectedUnitProgress?.enabled}
                                            />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card onClick={() => onPopupOpen("attendance")} className="overview-clickable-card">
                                        <Typography.Paragraph className="cardHeading">Attendance</Typography.Paragraph>
                                        <ChartCard
                                            name="Course Attendance"
                                            percentage={attendance?.percentage}
                                            data={attendance?.data}
                                            enabled={attendance?.enabled}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </Loader>
        </div>
    )
}

Overview.defaultProps = {
    overviewData: {}
}

Overview.propTypes = {
    onPopupOpen: PropTypes.func,
    dataLoading: PropTypes.bool,
    overviewData: PropTypes.bool,
}

export default Overview;