import React from "react";
import {useSelector} from "react-redux";

import {
    makeVwDealershipSidebarListsDataResponse,
    makeVwDealershipMainReportDataResponseLoading
} from "../../../selectors/vwDealership/dealership.selector";
import {makeVwDealershipOnlineModuleListsLoadingResponse} from "../../../selectors/vwDealership/mandatoryOnline.selector";
import {makeVwDealershipPathwayElementsListsLoadingResponse} from "../../../selectors/vwDealership/qualificationPathway.selector";
import {getExternalUserLoading} from "../../../selectors/authentication.selector";

import DealershipView from "./DealershipView";

const DealershipContainer = () => {

    // selectors
    const sidebarData = useSelector(makeVwDealershipSidebarListsDataResponse);
    const mainReportLoading = useSelector(makeVwDealershipMainReportDataResponseLoading);
    const cardLoading = useSelector(makeVwDealershipOnlineModuleListsLoadingResponse);
    const pathwayLoading = useSelector(makeVwDealershipPathwayElementsListsLoadingResponse);
    const signOutLoading = useSelector(getExternalUserLoading);

    return (
        <DealershipView
            isLoading={sidebarData.isLoading || mainReportLoading || cardLoading || pathwayLoading || signOutLoading}
        />
    )
}

export default DealershipContainer;