import _get from "lodash/get";
import {subSections} from "../../../constant/jobReadyGeneric/constants";

// return risk calculations data
export const getRiskCalculationsData = state => _get(state, 'jrGeneric.settings.riskCalculations');

export const getCategoryOptionSelected = state => _get(getRiskCalculationsData(state), "categoryOptions");
export const getCategoryOptionSelectedLoading = state => _get(getCategoryOptionSelected(state), "isLoading");
export const getCategoryOptionSelectedData = state => _get(getCategoryOptionSelected(state), "options", []);

// ACADEMIC
export const getRcAcademicData = state => _get(getRiskCalculationsData(state), subSections.academic.key);

export const getRcAcademicDataFetchLoading = state => _get(getRcAcademicData(state), 'selected.isLoading');
export const getRcAcademicSaveLoading = state => _get(getRcAcademicData(state), 'saved.isLoading');
export const getRcAcademicDataResponse = state => _get(getRcAcademicData(state), 'data');

// FINANCIAL
export const getRcFinancialData = state => _get(getRiskCalculationsData(state), subSections.financial.key);

export const getRcFinancialDataFetchLoading = state => _get(getRcFinancialData(state), 'selected.isLoading');
export const getRcFinancialSaveLoading = state => _get(getRcFinancialData(state), 'saved.isLoading');
export const getRcFinancialDataResponse = state => _get(getRcFinancialData(state), 'data');

// COMPLIANCE
export const getRcComplianceData = state => _get(getRiskCalculationsData(state), subSections.compliance.key);

export const getRcComplianceDataFetchLoading = state => _get(getRcComplianceData(state), 'selected.isLoading');
export const getRcComplianceSaveLoading = state => _get(getRcComplianceData(state), 'saved.isLoading');
export const getRcComplianceDataResponse = state => _get(getRcComplianceData(state), 'data');



// return risk calculations setting by path
export const getRcSettingsByPath = path => state => {
    const result = getRiskCalculationsData(state);
    return _get(result, path, undefined);
};
