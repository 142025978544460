import React from "react";
import {Form, Card, Input, Button} from "antd";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router";

import Loader from "../common/layout/Loader";
import {onError} from "../../../utils/notificationHandler";

import {fetchOtpValidate} from "../../../actions/dashboardManagement/twoFaAuth.action";
import {getOtpAuthData} from "../../../selectors/dashboardManagement/twoFaAuth.selector";

const octoLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/logo-l.png`;
const loginBg = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/students.jpeg`;

const TwoFactorAuth = () => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const returnUrl = new URLSearchParams(search).get('returnUrl');

    //selectors
    const otpAuth = useSelector(getOtpAuthData);


    const onOtpSubmit = (values) => {
        const {otp} = values;
        dispatch(fetchOtpValidate(otp)).then(data => {
            if(data.isOtpAuthorised){
                window.location.href = decodeURIComponent(returnUrl);
            }else{
                return onError("OTP verification failed")
            }
        })
    }

    const renderOtpForm = () => {
        return (
            <Form
                name="basic"
                onFinish={onOtpSubmit}
            >
                <Form.Item
                    name="otp"
                    rules={[{ required: true, message: 'Please input the OTP' }]}
                >
                    <Input placeholder="Enter Your OTP" size="large" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" block>
                        Submit
                    </Button>
                </Form.Item>
                <div className="resendOtp">
                    Didn't receive OTP ? <Button type="link">Resend OTP</Button>
                </div>
            </Form>
        )
    }

    return (
        <div className="vcenter">
            <div className="bgImages">
                <img src={loginBg} alt="login background" />
            </div>
            <Card className="otpFomCover" >
                <div className="logoCover"><img src={octoLogo} alt=""/></div>
                <h3>Please enter the one-time password to proceed to the dashboard</h3>
                <p>One time password has been sent to <span className="emailAddress">{email}</span></p>
                <Loader isLoading={otpAuth.isLoading}>
                    <div >
                        {renderOtpForm()}
                    </div>
                </Loader>
            </Card>
        </div>
    )
}

export default TwoFactorAuth;