import React from 'react';
import {Layout} from "antd";


const JRInnerContent = ({children}) => {
    return (
        <Layout.Content className='inner-layout'>
            {children}
        </Layout.Content>
    )
}

export default JRInnerContent;