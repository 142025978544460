import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, Col, Layout, Row, Tabs} from "antd";
import PropTypes from "prop-types";
import StudentProfileCard from "../common/cards/StudentProfileCard";
import AssignmentComparisonGrid from "./assignmentComparisonGrid";
import TopCard from "./topCard";
import SideChartsContainer from "../common/cards/sideCharts";
import OutcomeGridContainer from "./outcomeGrid/OutcomeGridContainer";
import TaskFilterSection from '../common/taskFilterSection';
import PieChart from "../common/charts/PieChart";
import Loader from "../common/layout/Loader";
import {CheckSquareTwoTone} from "@ant-design/icons";
import { FILTER_ASSIGNMENT_GROUP } from '../../../utils/innovative/constants';

const { TabPane } = Tabs;

const StudentAssignmentView = ({
                                   student,
                                   assignmentsReport,
                                   assignmentsLoading,
                                   sideChartsConfigs,
                                   currentViewTab,
                                   outcomeEnabled,
                                   outcomeConfig,
                                   masteryConfig,
                                   setBreadcrumb,
                                   tasksEnabled,
                                   settingsLoading,
                                   applyFilter,
                                   setTaskTablePagination,
                               }) => {
    const [currentTab, setCurrentTab] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(FILTER_ASSIGNMENT_GROUP);

    useEffect(() => {
        if (currentViewTab){
            setCurrentTab(currentViewTab);
        }
    }, [currentViewTab])

    useEffect(() => {
        return () => setCurrentTab(null)
    }, [])

    useEffect(() => {
        if (!tasksEnabled){
            onTabChange("outcomes")
        }else{
            onTabChange("tasks")
        }
    }, [tasksEnabled])

    const onTabChange = (e) => {
        setCurrentTab(e);
        setBreadcrumb(e);
    }

    const masteredOutcomeRenderer = () => {
        return (
            currentTab === "outcomes" &&
            <div style={{display:"flex", alignItems: "center"}}>
                <CheckSquareTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a"/>
                <span style={{ fontSize: 14 }}>&nbsp; The student has mastered in {assignmentsReport.student_mastery_count}/{assignmentsReport.outcomes.length} outcomes</span>
            </div>
        )
    }

    const getGridSection = () => {
        const bothEnabled = tasksEnabled && outcomeEnabled;

        if (bothEnabled) {
            return (
                <Tabs
                    defaultActiveKey={currentTab}
                    tabBarStyle={{fontSize:40}}
                    activeKey={currentTab}
                    className="customTabs"
                    onChange={onTabChange}
                    tabBarExtraContent={masteredOutcomeRenderer()}
                >
                    <TabPane tab="Tasks" key="tasks">
                        <div className="taskFilterInTab">
                            <TaskFilterSection 
                                applyFilter={applyFilter}
                                setCurrentFilter={setCurrentFilter}
                            />
                        </div>
                        <AssignmentComparisonGrid 
                            currentFilter={currentFilter}
                            setTaskTablePagination={setTaskTablePagination}
                        />
                    </TabPane>
                    <TabPane tab="Outcomes" key="outcomes">
                        <OutcomeGridContainer />
                    </TabPane>
                </Tabs>
            );
        }

        if (tasksEnabled) {
            return (
                <>
                    <div className="taskFilterHeading">
                        <h4>Tasks</h4>
                        <TaskFilterSection 
                            applyFilter={applyFilter}
                            setCurrentFilter={setCurrentFilter}
                        />
                    </div>
                    <div className="student-assignment-table">
                        <AssignmentComparisonGrid 
                            currentFilter={currentFilter}
                            setTaskTablePagination={setTaskTablePagination}
                        />
                    </div>
                </>
            );
        }

        if (outcomeEnabled) {
            return (
                <OutcomeGridContainer/>
            );
        }

        return null;
    }

    return (
        <Layout.Content
            className="site-layout-background singleColumnLayout"
        >
            <Row gutter={[12, 0]}>
                <Col md={24} xl={6}>
                    <Row gutter={[16, 0]}>
                        <Col md={24} xl={24}>
                            <StudentProfileCard
                                src={student["student_avatar_url"]}
                                name={student["student_name"]}
                                id={student["student_sis_user_id"]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col md={24} xl={24}>
                            <div className="in-sectionCover side-charts">
                                <SideChartsContainer
                                    configs={sideChartsConfigs}
                                    records={assignmentsReport}
                                    recordsLoading={assignmentsLoading}
                                    type="task"
                                />
                                <Loader isLoading={assignmentsLoading}>
                                    <h5>Overall Outcomes</h5>
                                    <PieChart chartOptions={outcomeConfig}/>
                                    <h5>Mastery Status</h5>
                                    <PieChart chartOptions={masteryConfig}/>
                                </Loader>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col  md={24} xl={18} style={{display: "flex",flexFlow: "column"}}>
                    <div className="in-sectionCover studentDetails">
                        <Row gutter={[16, 16]}>
                            <TopCard/>
                        </Row>
                    </div>
                    <div className="in-sectionCover assignmentCover">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Loader isLoading={settingsLoading}/>
                                {getGridSection()}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Layout.Content>
    );
};

StudentAssignmentView.defaultProps = {
    student: PropTypes.object,
    assignmentsReport: PropTypes.object,
    assignmentsLoading: PropTypes.bool,
    sideChartsConfigs: PropTypes.array,
    setTaskTablePagination: PropTypes.func,
}

StudentAssignmentView.propTypes = {
    currentViewTab: PropTypes.string,
    outcomeEnabled: PropTypes.bool,
    tasksEnabled: PropTypes.bool,
    outcomeConfig: PropTypes.object,
    masteryConfig: PropTypes.object,
    setBreadcrumb: PropTypes.func,
    settingsLoading: PropTypes.bool,
}

export default memo(StudentAssignmentView);