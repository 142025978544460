import React from "react";
import {Drawer} from "antd";
import PropTypes from "prop-types";

const JRDrawer = ({title, onClose, visible, children, width, ...rest}) => {

    return (
        <>
            <Drawer
                width={width}
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
                {...rest}
            >
                {children}
            </Drawer>
        </>
    );
}

JRDrawer.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool.isRequired,
}

export default JRDrawer;