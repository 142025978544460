import React, { useState, useEffect } from 'react'
import { Card, Typography, Button, Layout } from 'antd';

const PolicyAgreement = ({ agreedTerms }) => {
    const { Paragraph } = Typography;
    const { Header, Content } = Layout;
    const [showAccept, setShowAccept] = useState(false);

    const handleScroll = (event) => {
        const elementHeight = event.target.scrollHeight - 1000;
        var element = document.querySelector('.trigger-point');
        var position = element.getBoundingClientRect();
        if (event.target.scrollTop >= elementHeight) {
            setShowAccept(true);
        }
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
            setShowAccept(true);
        }
        if (position.top < window.innerHeight && position.bottom >= 0) {
            setShowAccept(true);
        }
        else {
            setShowAccept(false);
        }
    }

    return (
        <Layout className='policy-agreement-backdrop'>
            <Card className='policy-agreement-wrapper'>
                <Card className='policy-agreement' onScroll={handleScroll} style={{ overflowY: 'scroll', height: '90vh' }}  >
                    <Header className='policy-agreement-header'>Policy Form</Header>
                    <Paragraph>This privacy policy applies to the information that we obtain through the use of our OctopusBI Offerings via a device (i.e. desktop computers, tablets, smart speakers, and smartphones are all considered devices) or when an Organization-User otherwise interacts with us. It does not apply to uses of products or services for which a separate privacy policy is provided, or to products or services provided by a third party.</Paragraph>
                    <Paragraph>The policies of third-party products and services should be reviewed to ascertain how they collect and use information.</Paragraph>
                    <Paragraph>In this privacy policy, the following words have the following meanings unless the context otherwise requires:</Paragraph>
                    <Paragraph><strong>“Controller”</strong> has the meaning as defined under the EU Data Protection Legislation.</Paragraph>
                    <Paragraph><strong>“EEA”</strong> means European Economic Area.</Paragraph>
                    <Paragraph><strong>“EU Data Protection Legislation”</strong> means the General Data Protection Regulation, Regulation (EU) 2016/679 and any legislation and/or regulation implementing or made pursuant to it or which amends or replaces any of them, as it applies to the EEA)</Paragraph>
                    <Paragraph><strong>“Personal Information”</strong> means any information relating to an identified or identifiable natural person or can be linked to you through a personal identifier like your address, e-mail address, phone number, or location. It does not include data where the identity has been removed (anonymous data). This definition is given here for this notice only, and some laws may use a different definition. If you are asserting your rights under law, the applicable legal definition governs your rights.</Paragraph>
                    <Paragraph><strong>“OctopusBI Offering”</strong> means our applications, Services, and/or Website.</Paragraph>
                    <Paragraph><strong>"Website"</strong> means any of our website that references or links to this policy.</Paragraph>
                    <Paragraph><strong>"Services"</strong> means our products and services, as well as data blending by means of pulling data from different sources and creating a single, unique, dataset for visualization and analysis.</Paragraph>                    <Paragraph><strong>“OctopusBI”, “we”</strong> and <strong>“us”</strong> refers to Ayra Group Pty Ltd.</Paragraph>
                    <Paragraph><strong>“Organization”</strong> means the organization (s) that have entered into an agreement for Services with us, including free trial and evaluation users.</Paragraph>
                    <Paragraph><strong>“Organization-User”</strong> means any person that uses the relevant OctopusBI Offering as a teacher, administrator, or other representative of an Organization with the Organization's authorization from time to time.</Paragraph>
                    <Paragraph><strong>“Processor”</strong> has the meaning as defined under the EU Data Protection Legislation.</Paragraph>
                    <Paragraph><strong>“User”</strong> means a user of any OctopusBI Offering including an Organization-User.</Paragraph>
                    <Paragraph>The purpose of this privacy policy is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Services immediately.</Paragraph>
                    <Paragraph><strong>Please read this privacy policy carefully, as it will help you understand what we do with the information that we collect.</strong>  </Paragraph>
                    <h1>Table of Contents</h1>
                    <ul className='table-of-contents ul-common'>
                        <span>Table of Contents</span>
                        <li>1.0 DATA CONTROLLER</li>
                        <li>2.0 DATA PROCESSOR</li>
                        <li>3.0 DATA PROVIDED BY ORGANIZATIONS TO US</li>
                        <li>4.0 WHAT INFORMATION DO WE COLLECT?</li>
                        <li>5.0 HOW DO WE USE YOUR INFORMATION?</li>
                        <li>6.0 WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
                        <li>7.0 WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
                        <li>8.0 DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                        <li>9.0 IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
                        <li>10. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
                        <li>11.0 HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                        <li>12.0 HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                        <li>13.0 WHAT ARE YOUR PRIVACY RIGHTS?</li>
                        <li>14.0 CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                        <li>15.0 DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                        <li>16.0 DO WE MAKE UPDATES TO THIS NOTICE?</li>
                        <li>17.0 HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                        <li>18.0 HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                    </ul>
                    <h2><span>1.0 DATA CONTROLLER</span></h2>
                    <Paragraph><em>We are a Data Processor under the GDPR</em></Paragraph>
                    <Paragraph>We provide our Service which consists of a business intelligence and analytics tools via a software-as-a-service (SaaS) model. Organizations  use our Service to analyze and create dashboards, and provides a view of student, classroom, and business operations. For compliance with the European Union’s General Data Protection Regulation (GDPR) and similar laws, in cases where we have access to Personal Information found in an Organization’s data, the Organization is the Controller of that Personal Information. We are the Processor carrying out data processing activities and instructions on behalf of each Controller.</Paragraph>
                    <Paragraph>Please contact the Organization who has directed us to process the particular information, if you have any questions about our processing of Personal Information.</Paragraph>
                    <h2><span>2.0 DATA PROCESSOR</span></h2>
                    <Paragraph><em>We are a Data Controller under the GDPR</em></Paragraph>
                    <Paragraph>We may collect Personal Information for our business purposes of carrying out our relationship with Organizations. For the purposes of compliance with the GDPR and similar laws, we are a Data Controller of the Personal Information provided by you directly as an Organization-User.</Paragraph>
                    <h2><span>3.0 DATA PROVIDED BY ORGANIZATIONS TO US</span></h2>
                    <Paragraph><em><strong>In Short: </strong>Organizations are responsible for ensuring the privacy of Personal Information contained in their data.</em></Paragraph>
                    <Paragraph>Our customers, the Organizations , access and use any of our OctopusBI Offerings via their IT systems, websites, software applications, and other methods.</Paragraph>
                    <Paragraph>Because the relevant Organizations  is the controller of Personal Information contained in their data, Organizations  are responsible for ensuring the privacy of Personal Information contained in their data.</Paragraph>
                    <Paragraph>We are not liable for any information disclosed by Organizations  as a result of their usage of any of our OctopusBI Offerings. It is the duty of the Organizations  to maintain your privacy rights when we have access to or process your Personal Information on their behalf.</Paragraph>
                    <Paragraph>If you are concerned about your privacy while interacting with an Organization's products and services, you should contact them. If you contact us regarding information belonging to an Organization, we may forward your requests or inquiries to the relevant Organization.</Paragraph>
                    <h2><span>4.0 WHAT INFORMATION DO WE COLLECT?</span></h2>
                    <Paragraph><strong>Personal Information made available by Organizations</strong></Paragraph>
                    <Paragraph><em><strong>In Short: </strong>We collect Personal Information provided to us by the Organization.</em></Paragraph>
                    <Paragraph>We may have access to your Personal Information if an Organization has data on you and uses our Services. We do not preview, screen, or review the Organization's data (other than for performing data analysis), when the Organization uses the Service for a variety of business analytics purposes.</Paragraph>
                    <Paragraph>Our access depends on the configurations controlled by the Organization and what the Organization discloses to us as part of the Services.</Paragraph>
                    <Paragraph>Therefore, we do not know what categories of Personal Information an Organization may collect and disclose about you in its data.</Paragraph>
                    <Paragraph><strong>Personal Information you disclose to us</strong></Paragraph>
                    <Paragraph><em><strong>In Short: </strong> We collect Personal Information that you provide to us.</em></Paragraph>
                    <Paragraph>We may request Personal Information that you voluntarily provide us if you are an Organization-User.</Paragraph>
                    <Paragraph>We collect such Personal Information that you voluntarily provide to us when you:</Paragraph>
                    <div className='ul-common'>
                        <ul>
                            <li>input it into the Services.</li>
                            <li>register on the Website.</li>
                            <li>express an interest in obtaining information about us or our products and Services.</li>
                            <li>when you participate in activities on the Website.</li>
                            <li>otherwise, when you contact us.</li>
                        </ul>
                    </div>
                    <Paragraph>The Personal Information that we collect depends on the context of your interactions with us and Services, the choices you make and the products and features you use. The Personal Information we collect may include the following:</Paragraph>
                    <Paragraph><strong>Personal Information Provided by you, as an Organization-User</strong></Paragraph>
                    <Paragraph>We collect information about you, as the Organization-User, when the Organization-User registers for an account, creates or modifies his or her profile, set preferences, signs-up for or make purchases through a OctopusBI Offering.</Paragraph>
                    <Paragraph>For example, we collect names; phone numbers; email addresses; mailing addresses; job titles; usernames; passwords; contact preferences; contact or authentication data; billing addresses; and other similar information.</Paragraph>
                    <Paragraph>All Personal Information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such Personal Information.</Paragraph>
                    <Paragraph><strong>Information automatically collected</strong></Paragraph>
                    <Paragraph><em><strong>In Short: </strong>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our OctopusBI Offerings.</em></Paragraph>
                    <Paragraph>We automatically collect certain information when you visit, use or interact with the OctopusBI Offering. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use the OctopusBI Offering and other technical information. This information is primarily needed to maintain the security and operation of the OctopusBI Offerings, and for our internal analytics and reporting purposes.</Paragraph>
                    <Paragraph>Like many businesses, we also collect information through cookies and similar technologies.</Paragraph>
                    <Paragraph>The information we collect includes:</Paragraph>
                    <div className='ul-common'>
                        <ul>
                            <li><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use any OctopusBI Offering and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity with any OctopusBI Offering (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</li>
                            <li><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or other device you use to access any OctopusBI Offering. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.</li>
                            <li><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access any OctopusBI Offering. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of a OctopusBI Offering.</li>
                        </ul>
                    </div>
                    <Paragraph><strong>Information collected from other sources</strong></Paragraph>
                    <Paragraph><em><strong>In Short: </strong> We may collect limited data from public databases, marketing partners, and other outside sources.</em></Paragraph>
                    <Paragraph>To enhance our ability to provide relevant marketing, offers and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, as well as from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, for purposes of targeted advertising and event promotion.</Paragraph>
                    <h2><span>5.0 HOW DO WE USE YOUR INFORMATION?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></Paragraph>
                    <Paragraph>We use Personal Information collected via our OctopusBI Offerings for a variety of business purposes described below. We process your Personal Information for these purposes in reliance on our legitimate business interests, to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</Paragraph>
                    <Paragraph>We use the information we collect or receive:</Paragraph>
                    <div className='ul-common'>
                        <ul>
                            <li><strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to a third-party account (such as your Microsoft and Google account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.</li>
                            <li><strong>To post testimonials.</strong> We post testimonials on our Website that may contain Personal Information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at support@octopusbi.com and be sure to include your name, testimonial location, and contact information.</li>
                            <li><strong>Request feedback.</strong> We may use your information to request feedback and to contact you about your use of our OctopusBI Offerings.</li>
                            <li><strong>To enable user-to-user communications.</strong> We may use your information to enable user-to-user communications with each user's consent.</li>
                            <li><strong>To manage user accounts.</strong> We may use your information for the purposes of managing our account and keeping it in working order.</li>
                            <li><strong>To send administrative information to you.</strong> We may use your Personal Information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</li>
                            <li><strong>To protect our Services.</strong> We may use your information as part of our efforts to keep our OctopusBI Offerings safe and secure (for example, for fraud monitoring and prevention).</li>
                            <li><strong>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</strong></li>
                            <li><strong>To respond to legal requests and prevent harm.</strong> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond. We might use your personal information to defend us in legal proceedings in relation to or as a result of your use of any OctopusBI Offering, following a court order, abiding by any law, regulation or governmental request, cooperating with law enforcement, cooperating in fraud investigations of third parties, safeguarding national security, defense, public security.</li>
                            <li><strong>Fulfill and manage your orders.</strong> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made via an OctopusBI Offering.</li>
                            <li><strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested service.</li>
                            <li><strong>To provide notices:</strong> We may use your contact information to provide you with notices related to your use of the OctopusBI Offerings.</li>
                            <li><strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our OctopusBI Offerings.</li>
                            <li><strong>To send you marketing and promotional communications if you gave us your Personal Information as an Organization-User.</strong> We and/or our third-party marketing partners may use the Personal Information of Organization-Users send to us for our marketing purposes, if this is in accordance with the Organization-User's marketing preferences. For example, when expressing an interest in obtaining information about us or our OctopusBI Offerings, subscribing to marketing or otherwise contacting us, we will collect Personal Information from you as the Organization-User. You can opt-out of our marketing emails at any time (see the <strong>"WHAT ARE YOUR PRIVACY RIGHTS?" </strong> <em>below</em>). <strong>We do not engage in direct marketing to parents and the Organization’s students.</strong></li>
                            <li><strong>Personalize the OctopusBI Offerings.</strong> We may use your information to help personalize the OctopusBI Offerings experience for you (such as, remembering your information so you will not have to enter it each time you use the Product).</li>
                            <li><strong>Deliver targeted advertising to you, if you gave us your Personal Information as an Organization-User.</strong> We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
                            <li><strong>For other business purposes.</strong> We may use your information, for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our OctopusBI Offerings, products, marketing and your experience. We may use and store this information in aggregated and anonymize form so that it is not associated with individual end users and does not include Personal Information.</li>
                        </ul>
                    </div>
                    <h2><span>6.0 WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></h2>
                    <Paragraph><strong><em>In Short: </em></strong><em>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em></Paragraph>
                    <Paragraph>If you gave us your Personal Information as an Organization-User, we may use this information to manage the business relationship with the Organization, such as providing service notices and billing, and to send you business offers and promotional information, subject to your right to withdraw consent to marketing use.</Paragraph>
                    <Paragraph>If an Organization uses Personal Information about you with any OctopusBI Offering, we may disclose your Personal Information to the Organization with the Organization‘s instructions.</Paragraph>
                    <Paragraph>We may process or share your Personal Information as an Organization-User that we hold based on the following legal basis:</Paragraph>
                    <div className='ul-common'>
                        <ul>
                            <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your Personal Information for a specific purpose.</li>
                            <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                            <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your Personal Information to fulfill the terms of our contract.</li>
                            <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                            <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                        </ul>
                    </div>

                    <Paragraph style={{ paddingBottom: "5px" }}>More specifically, we may need to process your Personal Information as an Organization-User in the following situations:</Paragraph>

                    <div className='ul-common'>
                        <ul>
                            <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the OctopusBI Offerings, which will enable them to collect data on our behalf about how you interact with our OctopusBI Offerings over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors, which are designed to help safeguard your Personal Information. This means that they cannot do anything with your Personal Information unless we have instructed them to do it. They will also not share your Personal Information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.</li>
                            <li><strong>Third-Party Advertisers.</strong> We may use third-party advertising companies to serve ads when you visit or use our OctopusBI Offerings. These companies may use information about your visits to our OctopusBI Offering and other websites that are contained in web cookies and other tracking technologies to provide advertisements about goods and services of interest to you.</li>
                            <li><strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
                            <li><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services or promotions.</li>
                        </ul>
                    </div>

                    <h2><span>7.0 WHO WILL YOUR INFORMATION BE SHARED WITH?</span></h2>
                    <Paragraph><strong><em>In Short: </em></strong><em>We only share information with the following third parties.</em></Paragraph>
                    <Paragraph>We only share and disclose your information with third parties, which can be found <a href='https://octopus-bi.atlassian.net/wiki/spaces/OL/pages/1603436714/Sub+Processors' target="_blank" rel="noreferrer">here</a></Paragraph>
                    <Paragraph>If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled <strong>"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".</strong></Paragraph>
                    <h2><span>8.0 DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>We may use cookies and other tracking technologies to collect and store your information.</em></Paragraph>
                    <Paragraph>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. If you want to block all cookies, you may be able to do this through the settings in your browser for each browser you use and each device you use to access the internet. Please be aware that some areas of our Website may not work if your browser does not accept cookies.</Paragraph>
                    <Paragraph>Where you fail to provide us information or where the information provided is incomplete and/or inaccurate, or you choose not to provide us with the information that we have requested, it may affect our or the Organization’s ability to provide you with our products and services.</Paragraph>
                    <h2><span>9.0 IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>We may transfer, store, and process your information in countries other than your own.</em></Paragraph>
                    <Paragraph>Our servers are located in Australia and the USA.  If you are accessing our OctopusBI Offering from outside Australia, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your Personal Information (see <strong><em>"WILL YOUR INFORMATION BE SHARED WITH ANYONE?"</em></strong> above), in Australia and elsewhere.</Paragraph>
                    <Paragraph>If you are a resident in the EEA or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your Personal Information in accordance with this privacy policy and applicable law.</Paragraph>
                    <Paragraph>We rely on the following provisions of the EU Data Protection Legislation (when transferring your data to our servers in Australia:</Paragraph>
                    <div className='dot-ul'>
                        <ul>
                            <li>(<strong>Article 28(3)</strong>): the entry into a data protection agreement with the relevant Controller;</li>
                            <li>(<strong>Article 49(1)(a)</strong>): at the time of submitting the data, you explicitly consented to the transfer of your data outside of the EEA and into Australia/USA;</li>
                            <li>(<strong>Article 49(1)(b)</strong>): the transfer of your data into Australia is necessary in order to perform the contract you are entering into with the Organization (which is the Controller) when you submit your data;</li>
                            <li>(<strong>Article 49(1)(c)</strong>): the transfer of your data into Australia/USA is necessary to conclude or perform a contract concluded between you and the Organization (which is the Controller), created when you submitted the data; and</li>
                            <li>(<strong>Article 1</strong>): to otherwise pursue our legitimate business interests outlined in this policy, which includes us acting as Processor for the Institution as Controller.</li>
                        </ul>
                    </div>
                    <Paragraph><strong><u>European Commission's Standard Contractual Clauses:</u></strong></Paragraph>
                    <Paragraph>We have implemented measures to protect your Personal Information, including by using the European Commission's Standard Contractual Clauses for transfers of Personal Information between our group companies and between us and our third- party providers. These clauses require all recipients to protect all Personal Information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. We have entered into a <a href='https://octopus-bi.atlassian.net/wiki/spaces/OL/pages/1607499790' target="_blank" rel="noreferrer">Data Processing Agreement</a> with the Organization that include Standard Contractual Clauses for the processing of student personal information. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.</Paragraph>
                    <h2><span>10. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></h2>
                    <Paragraph><em><strong>In Short :</strong>We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our OctopusBI Offerings.</em></Paragraph>
                    <Paragraph>Any OctopusBI Offering may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from any OctopusBI Offering. You should review the policies of such third parties and contact them directly to respond to your questions.</Paragraph>
                    <h2><span>11.0 HOW LONG DO WE KEEP YOUR INFORMATION?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</em></Paragraph>
                    <Paragraph>We will only keep your Personal Information for as long as it is necessary for the purposes set out in this privacy policy i.e. as long as the Organization utilizes a OctopusBI Offering, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).</Paragraph>
                    <Paragraph>When we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymise such information, or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.</Paragraph>
                    <h2><span>12.0 HOW DO WE KEEP YOUR INFORMATION SAFE?</span></h2>
                    <Paragraph><em><strong>In Short: </strong> We aim to protect your Personal Information through a system of organizational and technical security measures.</em></Paragraph>
                    <Paragraph>We have implemented appropriate technical and organizational security measures designed to protect the security of any Personal Information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your Personal Information, transmission of Personal Information to and from our OctopusBI Offerings is at your own risk. You should only access any OctopusBI Offering within a secure environment.</Paragraph>
                    <h2><span>13.0 WHAT ARE YOUR PRIVACY RIGHTS?</span></h2>
                    <Paragraph><em><strong>In Short: </strong> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your Personal Information. You may review, change, or terminate your account at any time.</em></Paragraph>
                    <Paragraph>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. <strong>In cases where we control your information, and you gave us permission to share your information these rights</strong> may include the right (i) to request access and obtain a copy of your Personal Information, (ii) to request rectification or erasure; (iii) to restrict the processing of your Personal Information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your Personal Information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</Paragraph>
                    <Paragraph><strong>If we are relying on your consent to process your Personal Information, you have the right to withdraw your consent at any time.</strong> Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your Personal Information conducted in reliance on lawful processing grounds other than consent.</Paragraph>
                    <Paragraph>If you are a resident in the EEA or UK and you believe we are unlawfully processing your Personal Information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.' target="_blank" rel="noreferrer">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a></Paragraph>
                    <Paragraph>If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href='https://www.edoeb.admin.ch/edoeb/en/home.html' target="_blank" rel="noreferrer">https://www.edoeb.admin.ch/edoeb/en/home.html.</a></Paragraph>
                    <Paragraph>If you have questions or comments about your privacy rights, you may email us at support@octopusbi.com</Paragraph>
                    <Paragraph><strong>In cases where we are acting as an agent (data processor) for an Organization, your choices are determined according to your relationship between you and that Organization, and you should direct your inquiries to that Organization.</strong></Paragraph>
                    <Paragraph><strong>Account Information</strong></Paragraph>
                    <Paragraph>If you would at any time like to review or change the information in your account or terminate your account, you can:</Paragraph>
                    <Paragraph>Log in to your account settings and update your user account.</Paragraph>
                    <Paragraph>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</Paragraph>
                    <Paragraph><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our OctopusBI Offerings. For more information about cookies, and how to disable cookies, visit <a href='http://www.allaboutcookies.org' target="_blank" rel="noreferrer">http://www.allaboutcookies.org</a></Paragraph>
                    <Paragraph><strong>Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</Paragraph>
                    <div className='dot-ul'>
                        <ul>
                            <li>Access your account settings and update your preferences.</li>
                            <li>Contact us using the contact information provided.</li>
                        </ul>
                    </div>
                    <h2><span>14.0 CONTROLS FOR DO-NOT-TRACK FEATURES</span></h2>
                    <Paragraph>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</Paragraph>
                    <h2><span>15.0 DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>Yes, if you are a resident of California, you are granted specific rights regarding access to your Personal Information.</em></Paragraph>
                    <Paragraph>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of Personal Information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared Personal Information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</Paragraph>
                    <Paragraph>If you are under 18 years of age, reside in California, and have a registered account with the any OctopusBI Offering, you have the right to request removal of unwanted data that you publicly post on any OctopusBI Offering. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on any OctopusBI Offering, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</Paragraph>
                    <h2><span>16.0 DO WE MAKE UPDATES TO THIS NOTICE?</span></h2>
                    <Paragraph><em><strong>In Short: </strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></Paragraph>
                    <Paragraph>We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently.</Paragraph>
                    <h2><span>17.0 HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></h2>
                    <Paragraph>If you have questions or comments about this notice, you may contact our Data Protection Officer <strong>(DPO)</strong>,</Paragraph>
                    <Paragraph>Company Name : Ayra Group Pty Ltd</Paragraph>
                    <Paragraph>DPO : Hansa Wijayasundara</Paragraph>
                    <Paragraph>Address : Level 2/11 York St, Sydney NSW 2000, Australia</Paragraph>
                    <Paragraph>Email : hansa@octopusbi.com</Paragraph>
                    <Paragraph>+61 410 999 302</Paragraph>
                    <Paragraph>If you have any further questions or comments, you may also contact us by post at the following corporate address:</Paragraph>
                    <Paragraph class="trigger-point">Ayra Group Pty Ltd (Australian Company Number 611 902 106) Level 2/11 York St, Sydney NSW 2000, Australia; Phone: +61 1300 414 200</Paragraph>
                    <h2><span>18.0 HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></h2>
                    <Paragraph style={{ marginBottom: "140px" }}>Based on the applicable laws of your country, you may have the right to request access to the Personal Information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your Personal Information, please send a request via email to <a href='#support'>support@octopusbi.com</a></Paragraph>
                </Card>
                <Layout className={`policy-agreement-take-consent-box-wrapper ${showAccept ? 'policy-agreement-take-consent-box-wrapper-show' : ''}`}>
                <Content>
                    <Card className='policy-agreement-take-consent-box'>
                        I agree to these terms and conditions
                        <Button onClick={agreedTerms} className='policy-agreement-accept-btn'>Accept</Button>
                    </Card>
                </Content>
            </Layout>
            </Card>
            
        </Layout>
    )
}

export default PolicyAgreement