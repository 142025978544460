import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Checkbox, Row, Col, Tooltip, Empty} from 'antd';

const SelectorList = ({items, checkedList, setCheckedList}) => {
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if(items && items.length && checkedList && checkedList.length){
            if (items.length === checkedList.length){
                setCheckAll(true);
                setIndeterminate(false);
            }
        }
    }, [items, checkedList])

    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < items.length);
        setCheckAll(list.length === items.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? items.map(item => item.id) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const renderItems = () => {
        return items.map(item => (
            <Col key={item.id}>
                <Tooltip title={item.name}>
                    <Checkbox className="selector-list-col" value={item.id}>{item.name}</Checkbox>
                </Tooltip>
            </Col>
        ))
    }

    return (
        <div className="selector-list">
            {items && items.length ?
            <Checkbox className="selector-list-col" indeterminate={indeterminate} onChange={onCheckAllChange}
                      checked={checkAll}>Select All </Checkbox>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
            <Checkbox.Group style={{ width: '100%', display: "flex", whiteSpace:"nowrap", maxWidth:180, textOverflow:"ellipsis" }} value={checkedList} onChange={onChange}>
                <Row>
                    {renderItems()}
                </Row>
            </Checkbox.Group>
        </div>
    )
}

SelectorList.propTypes = {
    items: PropTypes.array
}

export default memo(SelectorList);