import React from "react";
import {Button, Card, Col, PageHeader, Row, Typography} from "antd";
import {useHistory} from "react-router";

import UserGrid from "./partials/list/UserGrid";
import menuData from "../../../../layout/dashboardManagement/menuData";
import {PlusOutlined} from "@ant-design/icons";

const {Title} = Typography;

const UserImportView = () => {
    const history = useHistory();

    return (
        <div className="in-container userImport">
                <Row justify="space-between">
                    <Col span={12}>
                        <Title level={4}>User Import</Title>
                    </Col>
                    <Col span={12} style={{textAlign:"end"}}>
                        <Button type="primary" onClick={() => history.push(menuData.createUserImport.url)} icon={<PlusOutlined/>}>Import</Button>
                    </Col>
                </Row>
                <UserGrid/>
        </div>
    )

}

export default UserImportView;