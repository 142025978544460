import _get from 'lodash/get';
import _has from 'lodash/has';
import _set from 'lodash/set';
import {
  VTT_SETTINGS_FETCH,
  VTT_SETTINGS_FETCH_LOADING,
  VTT_SETTINGS_ALTERNATION,
  VTT_SETTINGS_SAVE,
  VTT_SETTINGS_SAVE_LOADING,
  VTT_CATEGORY_OPTIONS_FETCH,
  VTT_CATEGORY_OPTIONS_FETCH_LOADING,
  VTT_RC_SET_SYNC_STATUS,
} from '../../../../constant/actionTypes';
import {
  settingsSections,
  subSections,
  syncStatuses,
} from '../../../../constant/vetTrack/analysis';

const academic = _get(subSections, 'academic.key', 'academic');
const financial = _get(subSections, 'financial.key', 'financial');
const compliance = _get(subSections, 'compliance.key', 'compliance');

const defaultState = {
  [academic]: {
    selected: {
      isLoading: false,
    },
    saved: {
      isLoading: false,
    },
    data: {},
  },
  [financial]: {
    selected: {
      isLoading: false,
    },
    saved: {
      isLoading: false,
    },
    data: {},
  },
  [compliance]: {
    selected: {
      isLoading: false,
    },
    saved: {
      isLoading: false,
    },
    data: {},
  },
  categoryOptions: {
    isLoading: false,
    options: [],
  },
  syncData: {
    [academic]: {
      isSyncing: false,
      lastSyncStatus: syncStatuses.completed,
      lastSyncTime: '',
    },
    [financial]: {
      isSyncing: false,
      lastSyncStatus: syncStatuses.completed,
      lastSyncTime: '',
    },
    [compliance]: {
      isSyncing: false,
      lastSyncStatus: syncStatuses.completed,
      lastSyncTime: '',
    },
  },
};

const KEY = settingsSections.riskCalculations;

const riskCalculationsReducer = (state = defaultState, action) => {
  const { type, path, section, payload } = action;

  switch (type) {
    case VTT_SETTINGS_FETCH_LOADING:
      if (section === KEY && path && _get(state, path, undefined)) {
        return { ..._set(state, path, payload) };
      }
      return state;

    case VTT_SETTINGS_FETCH:
      if (section === KEY && path && _get(state, path, undefined)) {
        return {
          ..._set({ ..._set(state, path, payload) }, `${path}2`, payload),
        };
      }
      return state;

    case VTT_SETTINGS_ALTERNATION:
      if (_has(state, path)) {
        return { ..._set(state, path, payload.data) };
      }
      return state;

    case VTT_SETTINGS_SAVE_LOADING:
    case VTT_SETTINGS_SAVE:
      if (section === KEY && path && _get(state, path, undefined)) {
        return { ..._set(state, path, payload) };
      }
      return state;

    case VTT_CATEGORY_OPTIONS_FETCH:
    case VTT_CATEGORY_OPTIONS_FETCH_LOADING:
      return {
        ...state,
        categoryOptions: {
          ...state.categoryOptions,
          ...payload,
        },
      };
    case VTT_RC_SET_SYNC_STATUS:
      return {
        ...state,
        syncData: {
          ...state.syncData,
          [section]: payload,
        },
      };
    default:
      return state;
  }
};

export default riskCalculationsReducer;
