import React from "react";
import { Tag } from "antd";

const SelectedUserView = ({ selectedUserArray, onRemoveSelectedUser }) => {

  const renderTag = user => {
    if (user.sis_id) {
      return `${user.name} (${user.sis_id})`;
    }
    return `${user.name}`;
  }

  return (
    <div className="tagList">
      {selectedUserArray.length > 0 && selectedUserArray.map(user => (
        <Tag
          key={user.sis_id}
          closable
          onClose={e => {
            e.preventDefault();
            onRemoveSelectedUser(user);
          }}
        >
          <div className="tag-user-name-text">
            {renderTag(user)}
          </div>
        </Tag>
      ))}
    </div>
  )
}

export default SelectedUserView;
