import React from 'react';
import {Badge, Card, Space} from "antd";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import Loader from "../../../common/layout/Loader";

const MonthView = ({dueAssignmentsLoading, onDayClick, onMonthChange, legend, dayPickerParams}) => {
    return (
        <Card className="text-center in-CustomCard" title={"Due Tasks"}>
            <Loader isLoading={dueAssignmentsLoading}/>
            <DayPicker
                {...dayPickerParams}
                onDayClick={onDayClick}
                onMonthChange={onMonthChange}
            />
            <Space>
                {
                    legend.map(item => <Badge
                        color={item.color}
                        text={item.label}
                    />)
                }
            </Space>
        </Card>
    );
};

MonthView.propTypes = {
    dueAssignmentsLoading: PropTypes.bool,
    onDayClick: PropTypes.func,
    onMonthChange: PropTypes.func,
    legend: PropTypes.array,
    dayPickerParams: PropTypes.object,
}

export default MonthView;