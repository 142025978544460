import React from "react";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// require("highcharts/modules/exporting.js")(Highcharts);
// require("highcharts/modules/export-data.js")(Highcharts);
require("highcharts/modules/accessibility.js")(Highcharts);

HC_more(Highcharts);

const HighChartComponent = ({ chartOptions }) => (
  <HighchartsReact highcharts={Highcharts} options={chartOptions} />
);

export default HighChartComponent;
