import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {makeCanvasKeysResponseData} from "../../../../../../selectors/canvasSetup.selector";
import StepKeyConfig from "../../views/StepKeyConfig";

const KeyConfigContainer = forwardRef(({form, formData}, ref) => {
    const keysResult = useSelector(makeCanvasKeysResponseData);

    useImperativeHandle(ref, () => ({
        // functions/ variables for parent access goes here
    }));

    useEffect(() => {
        if (keysResult) {
            setTimeout(() => form.setFieldsValue({
                consumerKey: keysResult.consumer_key,
                secretKey: keysResult.secret,
            }));
        }
    }, [keysResult]);

    const getUrl = () => {
        const {url} = formData;
        const host = new URL(url).origin;
        return [host, "login/canvas"].join("/");
    };

    return <StepKeyConfig
        url={getUrl()}
        hideSecret={false}
    />;
});

KeyConfigContainer.displayName = "KeyConfigContainer";

KeyConfigContainer.propTypes = {
    form: PropTypes.object,
    formData: PropTypes.object,
};

export default KeyConfigContainer;