import {faTachometerAlt, faUserAlt} from "@fortawesome/free-solid-svg-icons";

export default {
    // main menu
    manageDashboard: {
        name: "My Dashboards",
        key: "dashboard",
        icon: faTachometerAlt,
        url: "/jr/dashboard/manage",
    },
    manageUsers: {
        name: "Users",
        key: "users",
        icon: faUserAlt,
        url: "/jr/user/manage",
    },


}