export default {
    summmaryCards: async function (filters) {
      try{
        return fetch(`${process.env.REACT_APP_APIURL}enrolmentprofile/summary_cards`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      }catch(e){
        console.log(e.message);
      }
    },
    confirmedEnrolments: async function (filters) {
      try {
        return fetch(`${process.env.REACT_APP_APIURL}enrolmentprofile/confirmed_enrolments`, {
          credentials: "include",
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      } catch (e) {
        console.log(e.message);
      }
    },
    enrolmentProjection: async function (filters) {
      try {
        return fetch(`${process.env.REACT_APP_APIURL}enrolmentprofile/enrolment_projection`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      } catch (e) {
        console.log(e.message);
      }
    },
    enquiries: async function (filters) {
      try {
        return fetch(`${process.env.REACT_APP_APIURL}enrolmentprofile/enquiries`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      } catch (e) {
        console.log(e.message);
      }
    },
    leadCount: async function (filters) {
      try {
        return fetch(`${process.env.REACT_APP_APIURL}enrolmentprofile/lead_count`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      } catch (e) {
        console.log(e.message);
      }
    }
}