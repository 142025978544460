import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography,
  Card,
  Select,
  Dropdown,
  Menu,
  Row,
  Space,
  Button,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import UserTableContainer from './partials/userTable';
import Loader from '../common/layout/Loader';
import Breadcrumb from '../common/layout/Breadcrumb';
import menuData from '../../../layout/vetTrack/dashboardManagement/vetTrackMenuData';
import { setBreadcrumb } from '../../../../actions/vetTrack/dashboardManagement/layout.action';

const VetTrackUserManagementView = ({
  dashboards,
  onDashboardChange,
  showUserModal,
  isLoading,
  selectedDashboard,
  isTemporaryHidden,
}) => {
  const dispatch = useDispatch();

  const breadcrumbItems = [
    {
      key: 'home',
      section: 'Home',
    },
    {
      key: menuData.manageUsers.key,
      section: menuData.manageUsers.name,
    },
  ];

  // set breadcrumb
  useEffect(() => {
    dispatch(setBreadcrumb(breadcrumbItems));
  }, []);

  const renderOptions = () =>
    dashboards &&
    dashboards.map(dashboard => (
      <Select.Option title={dashboard.name} value={dashboard.id}>
        {dashboard.name}
      </Select.Option>
    ));

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showUserModal}>
        Add New User
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="container">
      <div className="vtt-gen-user">
        <Loader isLoading={isLoading} />
        <Row justify="space-between">
          <div>
            <Row justify='space-between' align='middle'>
              <Typography.Title level={4}>User Management</Typography.Title>

            </Row>
          </div>

          <div>
            <Space>
              {!isTemporaryHidden && <Select
                data-testid="dashboard-select"
                onSelect={onDashboardChange}
                style={{ width: 200 }}
                value={selectedDashboard}
              >
                {renderOptions()}
              </Select>}
              <Dropdown trigger={['click']} overlay={menu}>
                <Button className="add-user-menu-btn">
                  Add User <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
        </Row>
        <Card className="user-container">
          <UserTableContainer isTemporaryHidden={isTemporaryHidden} />
        </Card>
      </div>
    </div>
  );
};

VetTrackUserManagementView.propTypes = {
  dashboards: PropTypes.array,
  onDashboardChange: PropTypes.func,
  showUserModal: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedDashboard: PropTypes.oneOfType([PropTypes.bool]),
};

export default VetTrackUserManagementView;
