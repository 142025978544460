import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDomainMetaData} from "../../selectors/tentacleMeta.selector";

import VwUserManagementContainer from "./userManagement";
import JRGenUserManagementContainer from "./jrGenUserManagement";
import MissingPage from "./common/pages/MissingPage";

import {setBreadcrumb, setMenuSelected} from "../../actions/jrDashboardManagement/layout.action";

import menuData from "../layout/jrDashboardManagement/jrMenuData";


const UserManagementWrapper = () => {
    const dispatch = useDispatch();

    const domainData = useSelector(getDomainMetaData);

    const breadcrumbItems = [
        {
            key: 'home',
            section: "Home",
        },
        {
            key: menuData.manageUsers.key,
            section: menuData.manageUsers.name,
        },
    ]

    //set breadcrumb
    useEffect(() => {
        dispatch(setBreadcrumb(breadcrumbItems))
    }, [])


    // set menu item
    useEffect(() => {
        dispatch(setMenuSelected(menuData.manageUsers.key));
    }, [])

    const resolveView = () => {
        if(domainData && domainData.code){
            switch (domainData.code) {
                case "jr_vga":
                    return <VwUserManagementContainer/>
                case "jr_gen_01":
                    return <JRGenUserManagementContainer/>
                default:
                    return <MissingPage/>
            }
        }else{
            return <MissingPage/>
        }
    }

    return (
        resolveView()
    )

}

export default UserManagementWrapper;