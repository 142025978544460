import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {saveConsumer, updateConsumer} from "../../../../../../actions/consumer.action";
import {
    makeConsumerSaveError,
    makeConsumerSaveLoading,
    makeConsumerSaveResponseData,
    makeConsumerUpdateLoading,
    makeConsumerUpdateResponseData,
    makeConsumerUpdateError
} from "../../../../../../selectors/consumer.selector";
import {useParams} from "react-router-dom";
import StepUrlConfig from "../../views/StepUrlConfig";

const UrlConfigContainer = forwardRef((
    {
        formData,
        goToNext,
        setIsFormLoading,
        stepDone,
        updateStepStatus,
        editMode,
    },
    ref
) => {
    const dispatch = useDispatch();
    const {token} = useParams();

    const consumerSaveLoading = useSelector(makeConsumerSaveLoading);
    const consumerSaveResult = useSelector(makeConsumerSaveResponseData);
    const consumerSaveError = useSelector(makeConsumerSaveError);

    const consumerUpdateLoading = useSelector(makeConsumerUpdateLoading);
    const consumerUpdateResult = useSelector(makeConsumerUpdateResponseData);
    const consumerUpdateError = useSelector(makeConsumerUpdateError);

    const [setupRequestStatus, setSetupRequestStatus] = useState(false);
    const fieldMapping = {
        url: {field: "api_url"},
    };

    useImperativeHandle(ref, () => ({
        fieldMapping,
        onSubmit,
    }));

    useEffect(() => {
        if (setupRequestStatus && !editMode) {
            setTimeout(() => setIsFormLoading(false), 500);

            if (!consumerSaveError && !consumerSaveLoading && setupRequestStatus) {
                updateStepStatus(true);
                goToNext();
            }
        }
    }, [consumerSaveResult, consumerSaveLoading, consumerSaveError]);

    useEffect(() => {
        if (setupRequestStatus && editMode) {
            setTimeout(() => setIsFormLoading(false), 500);

            if (!consumerUpdateError && !consumerUpdateLoading && setupRequestStatus) {
                updateStepStatus(true);
                goToNext();
            }
        }
    }, [consumerUpdateResult, consumerUpdateLoading, consumerUpdateError]);

    const onSubmit = ({values}) => {
        //encode the URL before pass to backend
        if(values.url){
            values.url = encodeURIComponent(values.url);
        }
        if(formData.url){
            formData.url = encodeURIComponent(formData.url);
        }
        setIsFormLoading(true);
        setSetupRequestStatus(true);

        if (editMode) {
            return dispatch(updateConsumer({
                token,
                requestParams: {
                    ...values,
                    ...formData
                }
            }));
        }

        // if this step is completed already, then skip request and go to next page (only for save)
        if ((stepDone && !editMode) || editMode) {
            setIsFormLoading(false);
            return goToNext();
        }

        return dispatch(saveConsumer({
            requestParams: {
                ...values,
                ...formData
            }
        }));
    };

    return <StepUrlConfig />
});

UrlConfigContainer.displayName = "UrlConfigContainer";

UrlConfigContainer.propTypes = {
    formData: PropTypes.object,
    goToNext: PropTypes.func,
    setIsFormLoading: PropTypes.func,
    stepDone: PropTypes.bool,
    updateStepStatus: PropTypes.func,
    editMode: PropTypes.bool,
};

export default UrlConfigContainer;