import {
    FILTER_BRAND,
    FILTER_COLOR,
    FILTER_PRICE,
    SORT_BY,
    SEARCH_BY, FILTER_HEADER
} from '../constant/actionTypes';

const filtersReducerDefaultState = {
    brand: ["Student Profile", "Teacher Dashboard", "Lee"],
    value: { min: 0, max: 950 },
    sortBy: "",
    searchBy:"",
    header: {
        maxYear: new Date().getFullYear(),
        year: new Date().getFullYear() - 3,
        period: new Date().getMonth() + 1,
        previous_period: new Date().getMonth()
    }
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case FILTER_BRAND:
            return {
                ...state,
                brand: action.brand
            };
        case FILTER_COLOR:
            return {
                ...state,
                color: action.color
            };
        case FILTER_PRICE:
            return {
                ...state,
                value: { min: action.value.value.min, max: action.value.value.max }
            };
        case SORT_BY:
            return {
                ...state,
                sortBy: action.sort_by
            };
            case SEARCH_BY:
                return {
                    ...state,
                    searchBy: action.search
            };
        case FILTER_HEADER:
            return {
                ...state,
                header: {
                    ...state.header,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default filtersReducer;