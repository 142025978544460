import React from 'react';
// To-Do Change
const loder = `${process.env.REACT_APP_S3_BUCKET_URL}/jobready/public/jr-sync.gif`;
const SyncingBanner = () => (
  <div className="vtt-loading-cover">
    <div className="vtt-sync-loader">
      <div className="vtt-loading-image">
        <img src={loder} alt="" />
      </div>
      <div className="vtt-loading-text">Data synchronization is in progress...</div>
    </div>
  </div>
);

export default SyncingBanner;
