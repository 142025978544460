import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
    getDataSourcesFetchDataLoading,
    getDataSourcesSaveLoading,
    getDataSourcesDataResponse
} from "../../../../selectors/jobReadyGeneric/settings/dataSources.selector";
import {
    fetchSettings,
    saveSettings
} from "../../../../actions/jobReadyGeneric/settings/commonSettings.action";
import {getPermissionDataByPath} from "../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";

import {settingsSections} from "../../../../constant/jobReadyGeneric/constants";

import DataSourcesView from "./DataSourcesView";


const DataSourcesContainer = () => {
    const dispatch = useDispatch();

    // selectors
    const fetchDataLoading = useSelector(getDataSourcesFetchDataLoading);
    const saveDataLoading = useSelector(getDataSourcesSaveLoading);
    const dataSources = useSelector(getDataSourcesDataResponse);
    const isAdmin = useSelector(getPermissionDataByPath("isAdmin"));


    const onSave = () => {
        dispatch(saveSettings(dataSources, "all", settingsSections.dataSources, null, 0))
    }

    // effects
    useEffect(() => {
        dispatch(fetchSettings({}, settingsSections.dataSources));
    }, [])

    return (
        <DataSourcesView
            isLoading={fetchDataLoading || saveDataLoading}
            onSave={onSave}
            isAdmin={isAdmin}
            isSyncing={dataSources.isSyncing}
        />
    )
}

export default DataSourcesContainer