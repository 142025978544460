import React, {memo} from "react";
import {Alert, Form, Modal} from "antd";
import PropTypes from "prop-types";

import ConfigCanvasForm from "./ConfigCanvasForm";

const ConfigCanvasModal = ({isModalVisible, handleOk, handleCancel}) => {
    const [form] = Form.useForm();

    const getAlertDescription = () => (
        <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat nulla elit, egestas scelerisque augue mattis at.
        </span>
    )

    return (
        <Modal title="Config Canvas" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <ConfigCanvasForm form={form}/>
            <Alert
                message=""
                description={getAlertDescription()}
                type="info"
                banner={true}
                showIcon
            />
        </Modal>
    )
}

ConfigCanvasModal.propTypes = {
    isModalVisible: PropTypes.bool,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
}

export default memo(ConfigCanvasModal)