import {
  VTT_SIDEBAR_FILTERS_FETCH,
  VTT_SIDEBAR_FILTERS_FETCH_LOADING,
  VTT_SIDEBAR_FILTERS_SELECT,
  VTT_SET_SELECTED_CARDS,
} from '../../../../constant/actionTypes';
import { subSections } from '../../../../constant/vetTrack/analysis';

const defaultState = {
  filters: {
    fetched: {
      isLoading: false,
      data: {},
    },
    selected: {},
  },
  selectedCards: {
    [subSections.academic.key]: null,
    [subSections.financial.key]: null,
    [subSections.compliance.key]: null,
    [subSections.wellBeing.key]: null,
  },
};

const commonReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VTT_SIDEBAR_FILTERS_FETCH:
    case VTT_SIDEBAR_FILTERS_FETCH_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          fetched: {
            ...state.filters.fetched,
            ...action.payload,
          },
        },
      };
    case VTT_SIDEBAR_FILTERS_SELECT:
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...action.payload,
          },
        },
      };
    case VTT_SET_SELECTED_CARDS:
      return {
        ...state,
        selectedCards: {
          ...state.selectedCards,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default commonReducer;
