import React, {Fragment, memo} from "react";
import {Card, PageHeader, Typography, Switch, Space, Row, Col, Button, Tag} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import {useHistory} from "react-router";

import menuData from "../../../../layout/dashboardManagement/menuData";

import OverviewCard from "./partials/OverviewCard";

const {Title, Paragraph} = Typography;

const UserOverview = () => {
    const history = useHistory();

    const renderOverviewCards = () => {
        return (
            <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card className="overview-card">
                            <div className="overview-card-des-div">
                                <Paragraph  strong>User Import</Paragraph>
                                <span style={{fontSize:"40px", fontWeight:"bold"}}>
                                    {/*{10000.toLocaleString()}*/}
                                    10,000
                                </span>
                            </div>
                            <div className="overview-card-icon-div">
                                <Button className="icon" onClick={() => history.push(menuData.userImport.url)} icon={<SettingOutlined/>} size="large" type="link" ghost/>
                            </div>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="overview-card">
                            <div className="overview-card-des-div">
                                <Paragraph  strong>Live Connect</Paragraph>
                                <span>
                                     <Tag style={{fontSize:"15px"}} color="green">Connected</Tag>
                                </span>
                            </div>
                            <div className="overview-card-icon-div">
                                <Button className="icon" onClick={() => history.push(menuData.userLiveConnect.url)} icon={<SettingOutlined/>} size="large" type="link" ghost/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    return (
        <div className="in-container userOverview">
                <Card className="authMethod">
                    <Title level={3}>Select Authentication Method</Title>
                    <Paragraph className="overview-card-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.
                    </Paragraph>
                    <div className="overview-switch-container">
                        <span style={{marginRight:"50px"}}><Switch /> User Import</span>
                        <span><Switch /> Live Connect</span>
                    </div>
                </Card>

                <div>
                    {renderOverviewCards()}
                </div>
        </div>
    )
}

export default memo(UserOverview);