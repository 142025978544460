import React, { memo } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import SolidGaugeChart from '../../../../../../../../../../common/charts/SolidGaugeChart';
import ChartMeta from '../MetaCard';
import NAMessage from '../../../../../../../../NAMessage';

const currency = '$';
const { Paragraph } = Typography;

const SpeedometerChartCard = ({
  name,
  min,
  max,
  metaConfig,
  value,
  enabled,
}) => {
  const chartConf = {
    chart: {
      height: '200px',
    },

    pane: {
      size: '150%',
      center: ['50%', '95%'],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },

    legend: {
      enabled: false,
    },

    yAxis: {
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        y: 18,
        format: `${currency} {value:,.0f}`,
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
        },
      },
      min,
      max,
    },
    series: [
      {
        data: [value],
      },
    ],
  };

  const getContent = () => {
    if (!enabled) {
      return <NAMessage />;
    }

    return (
      <>
        <SolidGaugeChart chartOptions={chartConf} />
        <div className="visualInfoBox">
          <ChartMeta {...metaConfig} currency={currency} />
        </div>
      </>
    );
  };

  return (
    <div>
      <Paragraph strong className="cardHeading">
        {name}
      </Paragraph>
      {getContent()}
    </div>
  );
};

SpeedometerChartCard.defaultProps = {
  enabled: false,
};

SpeedometerChartCard.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metaConfig: PropTypes.object,
  value: PropTypes.any,
  enabled: PropTypes.bool,
};

export default memo(SpeedometerChartCard);
