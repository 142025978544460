class CountdownTimer {
  constructor({
    onCountDownComplete = () => { },
    setTimeLeft = () => { },
    countDownRenderer = () => { },
  }) {
    this.intervalId = null;
    this.timeoutId = null;
    this.endTime = null;
    this.onCountDownComplete = onCountDownComplete;
    this.setTimeLeft = setTimeLeft;
    this.countDownRenderer = countDownRenderer;
    this.isActive = false;
  }

  intervalCallback = () => {
    const currentTime = new Date().getTime();
    const remainingTime = Math.max(
      0,
      Math.floor((this.endTime - currentTime) / 1000)
    );
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    this.setTimeLeft({ minutes, seconds });
    this.countDownRenderer({ minutes, seconds });
  };

  start = ({ endTime }) => {
    this.endTime = endTime;
    const timeRemaining = endTime - new Date().getTime();

    if (timeRemaining > 0) {
      const interval = setInterval(() => this.intervalCallback({
        endTime,
      }), 1000);

      const timeout = setTimeout(() => {
        this.cleanTimeOut({ intervalId: interval, timeoutId: timeout });
      }, timeRemaining);

      this.isActive = true;
      this.intervalId = interval;
      this.timeoutId = timeout;
      return;
    }
  };

  stop = () => {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
    this.reset();
  };

  cleanTimeOut = ({ intervalId, timeoutId }) => {
    this.isActive = false;
    clearInterval(intervalId);
    clearTimeout(timeoutId);
    this.reset();
  };

  reset = () => {
    this.onCountDownComplete();
    this.intervalId = null;
    this.timeoutId = null;
    this.endTime = null;
    this.isActive = false;
  };

  isTimerActive = () => this.isActive;
};

export default CountdownTimer;
