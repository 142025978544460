import localStore from "../../localStorage";
import _get from "lodash/get";

/**
 * This will look the local store and return the requested parameters from the store
 *
 * @param   {Object[]} parameters    array localstorage payload params
 * @param   {string} parameters.key    array localstorage payload params key
 * @param   {string|boolean|array|Object} parameters.default    array localstorage payload params default value
 *
 */
export const getDataFromLocalStore = (parameters) => {
    // This will look the local store and
    const localData = localStore.getLsSlim(localStore.keys.authMeta, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET);
    if(localData){
        let data = {};
        parameters.map(param => (data[param.key]= _get(localData, param.key, param.default)));
        return data;
    }else{
        return {}
    }

}