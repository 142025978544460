import React from "react";
import {Form} from "antd";
import {useDispatch} from "react-redux";

import {
    addUser,
    fetchDashboardUsers
} from "../../../../../actions/jrDashboardManagement/userManagement/jrGenUser.action";

import UserModal from "./UserModal";

const UserModalContainer = ({handleOk, handleCancel, ...rest}) => {
    const [userForm] = Form.useForm();
    const dispatch = useDispatch();

    const postProcessUsers = () => {
        handleCancel()
        userForm.resetFields();
        dispatch(fetchDashboardUsers());
    }

    const handleFormSubmit = (values) => {
        dispatch(addUser(values, postProcessUsers));
    }

    const onFormSubmit = () => {
        userForm.validateFields()
            .then(values => {
                handleFormSubmit(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });


    }

    const onHandleCancel = () => {
        userForm.resetFields();
        handleCancel();
    }

    return (
        <UserModal {...rest} form={userForm} handleOk={onFormSubmit} handleCancel={onHandleCancel}/>
    )
}

export default UserModalContainer;