import React, { Component, Fragment } from "react";
import ReactTooltip from 'react-tooltip';

class ProfitAndLoss extends Component {
  render() {
    return (
      <Fragment>
        <div className="table-responsive table-border-radius">
          <table className="table table-styling table-bordered table-financial">
            <thead className="table-dark">
              <tr>
                <th scope="col" width="38%">
                  ACCOUNT GROUP
                </th>
                <th scope="col" width="18%">
                  ACTUAL ($)
                </th>
                <th scope="col" width="26%" className="table-small-font">
                  BOARD APPROVED BUDGET ($)
                </th>
                <th scope="col" width="18%">
                  VARIANCE ($) 
                  {/* <div data-tip="React-tooltip" data-for="forPLVariance" className="tooltip-box"></div>
                  <ReactTooltip className='table-custom-tooltip' id="forPLVariance" place="top" type="light" effect="float" data-html={true}>
                    <ul>
                      <li>NEGATIVE VARIANCE<span className="tooltip-box cell-red"></span></li>
                      <li style={{display:"inline-block"}}>POSITIVE VARIANCE<span className="tooltip-box cell-green"></span></li>
                      </ul>
                  </ReactTooltip>  */}
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {this.props.data && this.props.data.length > 0
                ? this.props.data.map(function (row, trindex) {
                    return (
                      <tr key={trindex}>
                        <td className="cell-dark">{row.name}</td>
                        <td className="cell-white right-align-text">
                          {row.actual}
                        </td>
                        <td className="cell-white right-align-text">
                          {row.bab}
                        </td>
                        <td
                          className={`cell-white ${row.variance_class} right-align-text`}
                        >
                          {row.variance}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default ProfitAndLoss;
