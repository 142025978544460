import React from "react";
import AccEnrolmentProfile from "./AccEnrolmentProfile";
import { withRouter } from 'react-router-dom'
import {useSelector} from "react-redux";

import {interimUserSchool} from "../../../../selectors/tentacle/auth.selector";

const EnrolmentProfile = (props) => {
    //selectors
    const interimUserSchoolData = useSelector(interimUserSchool);

    if(interimUserSchoolData === "acc"){
        return (
            <AccEnrolmentProfile location={props.location}/>
        );
    }else{
    }
};

export default withRouter(EnrolmentProfile);