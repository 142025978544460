import {
    FETCH_TENANT_ADMIN_LOADING,
    FETCH_TENANT_ADMIN
} from "../../constant/actionTypes";
import service from "../../utils/customServices/impression";

// fetch tenant admin list loading
const fetchingTenantAdmins = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_TENANT_ADMIN_LOADING,
        payload: {isLoading},
    });
};


// fetch tenant admin handler
const fetchTenantAdminStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_TENANT_ADMIN,
        payload: {
            isLoading: false,
            error: response.error,
            data: response.data
        },
    });
};

// fetch tenant admins
export const fetchTenantAdminList = (filters) => dispatch => {
    dispatch(fetchingTenantAdmins());

    service.make({
        service:`statistics/impression/fetch`,
        method: "POST",
        data: filters
    }).then(response => {
        dispatch(fetchTenantAdminStatus({
            data: response.data
        }))
    }).catch(error => {
        console.log(error)
        dispatch(fetchTenantAdminStatus({
            error: error.message
        }))
    })
};
