import {Button, Col, List, Row, Space, Switch} from "antd";
import PropTypes from "prop-types";
import {EyeOutlined, SettingOutlined} from "@ant-design/icons";
import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router";

import {onError} from "../../../../utils/notificationHandler";
import impressionHandler from "../../../../utils/impressionHandler/impressionHandler";
import dashboardConfig from "../../../../constant/jrDashboardManagement/dashboardConfig";

const CardItem = ({dashboard, activeChange}) => {
    const [dashboardData, setDashboardData] = useState({});
    const history = useHistory();
    const returnUrl = () => window.location.href = dashboard.path;


    const getDashboardData = () => {
        return dashboardConfig[dashboard.access_code]
    }

    // get dashboard configurations from config file
    useEffect(() => {
        if(dashboard){
            setDashboardData(getDashboardData())
        }
    }, [dashboard])


    const viewDashboard = (url) => {
        if (url){
            impressionHandler.sendUserAccessDataAsync({
              dashboard:dashboard.access_code,
              accessCode:dashboard.accessHash
            })
                .then(res => (
                    returnUrl()
                )).catch(() =>  returnUrl())
        }else{
            onError("No Url Specified");
        }
    }

    const viewDashboardSettings = () => {
        if (dashboardData.settingsUrl){
            impressionHandler.sendUserAccessDataAsync({
                  dashboard:dashboard.access_code,
                  accessCode:dashboard.accessHash
            })
                .then(res => (
                    history.push(dashboardData.settingsUrl)
                )).catch(() =>  history.push(dashboardData.settingsUrl))
        }else{
            onError("No Settings Url Specified");
        }
    }

    const renderActiveSwitch = () => {
        if(dashboardData.showActive){
            return (
                <Fragment>
                    <br/>
                    <br/>
                    <Switch size="small" checked={dashboard.maintenance === false} onChange={(value) =>activeChange(dashboard.id, value)}/> &nbsp; <span>Active</span>
                </Fragment>
            )
        }
    }


    return (
        <List.Item
            key={dashboard.id}
            className="jr-dashboard-list-item"
        >
            <Row gutter={[24, 24]}>
                <Col xs={24} md={12} lg={6} xl={4} >
                    <img
                        className="image"
                        alt="dashboard thumbnail"
                        src={dashboard.thumbnail}
                    />
                </Col>
                <Col md={12} lg={12} xl={16}>
                    <List.Item.Meta
                        title={dashboard.name}
                        className="metaDetails"
                        description={<span>{dashboardData && dashboardData.description}
                            {renderActiveSwitch()}
                        </span>}
                    />
                </Col>
                <Col md={24} lg={6} xl={4} className="btn-group">
                    <div className="dashboard-button-group">
                        <Space>
                            <Button icon={<EyeOutlined />} onClick={() => viewDashboard(dashboard.path)} size="large">View</Button>
                            {dashboardData && dashboardData.settingsUrl &&
                            <Button icon={<SettingOutlined/>} onClick={() => viewDashboardSettings()}
                                    size="large">Settings</Button>
                            }
                        </Space>
                    </div>
                </Col>
            </Row>

        </List.Item>
    )
}

CardItem.propTypes = {
    dashboard: PropTypes.object,
    activeChange: PropTypes.func,
}

export default CardItem;