import React from "react";
import {Row, Col, Button, Space} from "antd";
import PropTypes from "prop-types";

import SelectorList from "./partials/SelectorList";
import Sidebar from "../../../common/sidebarLayout";

const DashboardSidebar = ({filterConfig, onSubmit, sidebarData}) => {
    return (
        <Sidebar>
            <Space style={{width:"100%"}} className="vw-db-sidebar-space" direction="vertical">
                <Row gutter={[16,24]}>
                    {/* Remove dummy data */}
                    <Col>
                        <h5>National</h5>
                        <SelectorList items={sidebarData.national} {...filterConfig.national}/>
                    </Col>
                    <Col>
                        <h5>State</h5>
                        <SelectorList items={sidebarData.state} {...filterConfig.state}/>
                    </Col>
                    <Col>
                        <h5>Brand Category</h5>
                        <SelectorList items={sidebarData.brand} {...filterConfig.brand}/>
                    </Col>
                    <Col>
                        <h5>Dealership</h5>
                        <SelectorList items={sidebarData.dealership} {...filterConfig.dealership}/>
                    </Col>
                </Row>
                <Row display="flex" justify="end">
                    <Col span={7}>
                        <Button style={{float:"right"}} type="primary" onClick={onSubmit}>Apply</Button>
                    </Col>
                </Row>
            </Space>
        </Sidebar>
    )
}

DashboardSidebar.propTypes = {
    filterConfig: PropTypes.object,
    onSubmit: PropTypes.func,
    sidebarData: PropTypes.object,
}

export default DashboardSidebar;