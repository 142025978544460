import React from "react";

import AddApiKey from "../../views/AddApiKey";

const AddApiKeyContainer = () => {

    return (
        <AddApiKey/>
    )
}

export default AddApiKeyContainer