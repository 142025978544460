import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudentClusterDataLoading,
  getIsChatMessagesLoading,
  getIsMessageSending,
  getRetryChatAfter,
  makeClusterDataError,
  getChatCourseId,
  makeInnovativeCourseResponseData,
  getChatMessages,
  makeInnovativeCourseMainReportStudentsResponseData,
  getSelectedClusterSectionsIds,
  getStudentClusterData,
  chartRateLimitExceededAt,
  chartRateLimitStatus,
} from "../../../../selectors/innovative/course.selector";
import ChatCard from "./chatCard";
import chatLoadingGif from "../../../../assets/images/chatGPT/chat-loading.gif";
import SendChatInput from "./partials/sendChatInput";
import {
  fetchChatHistory,
  resetChatCountDownTimer,
} from "../../../../actions/innovative/course.action";
import { isValidDate } from "../../../../utils/general";

const MIN_CHAT_HISTORY_ARRAY_LENGTH = 1;

const ChatBox = ({ isClusterCountDownOn }) => {
  const scrollToEndOfChatRef = useRef(null);
  const dispatch = useDispatch();

  const [startCountDownTimer, setStartCountDownTimer] = useState(false);
  const [isChatRelatedToCourse, setIsChatRelatedToCourse] = useState(false);
  const [chatDisabled, setChatDisabled] = useState(false);
  const [lastChatId, setLastChatId] = useState("");

  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const isMessagesLoading = useSelector(getIsChatMessagesLoading);
  const isMessageSending = useSelector(getIsMessageSending);
  const rateLimitStatus = useSelector(chartRateLimitStatus);
  const retryAt = useSelector(getRetryChatAfter);
  const clusterDataError = useSelector(makeClusterDataError);
  const chatCourseId = useSelector(getChatCourseId);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const chatDataArray = useSelector(getChatMessages);
  const studentDetails = useSelector(makeInnovativeCourseMainReportStudentsResponseData);
  const selectedSectionFilters = useSelector(getSelectedClusterSectionsIds);
  const clusterData = useSelector(getStudentClusterData);
  const limitReset = useSelector(chartRateLimitExceededAt);

  const countDownTime = () => {
    if (isValidDate(retryAt)) {
      return retryAt;
    }
    if (isValidDate(limitReset)) {
      return limitReset;
    }

    return 0;
  };

  const onCountDownComplete = () => {
    setStartCountDownTimer(false);
    dispatch(resetChatCountDownTimer());
    if (chatDataArray && !chatDataArray.length) {
      dispatch(fetchChatHistory({
        filters: {
          sections: selectedSectionFilters,
        },
      }));
    }
  }

  useEffect(() => {
    if (chatDataArray.length > MIN_CHAT_HISTORY_ARRAY_LENGTH) {
      setLastChatId(chatDataArray[chatDataArray.length - 1].message_id);
    }
  }, [JSON.stringify(chatDataArray)]);

  useEffect(() => {
    chatCourseId && setIsChatRelatedToCourse(chatCourseId === selectedCourse.course_id);
  }, [chatCourseId]);

  useEffect(() => {
    if (
      rateLimitStatus.status === 429 &&
      isValidDate(retryAt)
    ) {
      setStartCountDownTimer(true);
    }
  }, [
    rateLimitStatus,
    retryAt,
    clusterDataError,
    chatDataArray,
    isChatRelatedToCourse,
    isMessageSending,
    clusterData,
  ]);

  useEffect(() => {
    if (isValidDate(limitReset)) {
      setStartCountDownTimer(true);
    }
  }, [limitReset]);

  useEffect(() => {
    if (!isMessageSending) {
      setChatDisabled(false);
    }
  }, [isMessageSending]);

  const formattedStudentList = () => {
    return studentDetails.map(student => {
      return {
        key: `student_id(${student.student_id})`,
        display: student.student_name,
      };
    });
  }

  const formattedSelectedCourse = () => {
    return {
      key: `course_id(${selectedCourse.course_id})`,
      display: selectedCourse.course_name,
    };
  }

  const renderMessages = () => {
    return (
      <div className={chatDisabled ? "chatDisabled" : ""}>
        <div >
          {chatDataArray.map((chatData, index) => {
            if (chatData.role === "assistant") {
              return (
                <ChatCard
                  scrollToEndOfChatRef={scrollToEndOfChatRef}
                  key={index}
                  chatData={chatData}
                  align={"left"}
                  studentList={formattedStudentList()}
                  selectedCourse={formattedSelectedCourse()}
                  setChatDisabled={setChatDisabled}
                />
              )
            }

            return (
              <ChatCard
                chatData={chatData}
                studentList={formattedStudentList()}
                selectedCourse={formattedSelectedCourse()}
                setChatDisabled={setChatDisabled}
                isLastElement={lastChatId === chatData.message_id}
              />
            )
          })}
        </div>
        <SendChatInput
          isDisabled={isMessageSending}
          scrollToEndOfChatRef={scrollToEndOfChatRef}
          setStartCountDownTimer={setStartCountDownTimer}
          startCountDownTimer={startCountDownTimer}
          retryAt={retryAt}
          onCountDownComplete={onCountDownComplete}
        />
      </div>
    )
  }

  const renderCard = () => {
    if (
      chatDataArray.length &&
      !clusterDataError &&
      isChatRelatedToCourse
    ) {
      return (
        <div>
          {renderMessages()}
        </div>
      );
    }

    return (
      <div className="chatContentWrapper">
        {isMessagesLoading && (
          <div className="chatLoading">
            <img src={chatLoadingGif} alt="loading..." />
          </div>
        )}
        {
          <SendChatInput
            isDisabled={true}
            scrollToEndOfChatRef={scrollToEndOfChatRef}
            setStartCountDownTimer={setStartCountDownTimer}
            startCountDownTimer={startCountDownTimer}
            retryAt={countDownTime()}
            onCountDownComplete={onCountDownComplete}
          />
        }
      </div>
    )
  }

  return (
    <div className="chatWrapper">
      {(!isClusterDataLoading && !isClusterCountDownOn) && renderCard()}
    </div>
  )
}

export default ChatBox;
