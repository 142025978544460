import React, { useCallback, useRef, useEffect, useState } from 'react';
import ModuleProgressGridView from './ModuleProgressGridView';
import ModuleProgressCard from './moduleProgressDropdown/components/moduleProgressCard';
import {
  makeInnovativeCourseMainReportStudentsResponseData,
  makeInnovativeCoursesMainReportLoading,
  makeModuleProgress,
} from '../../../../selectors/innovative/course.selector';
import { useSelector, useDispatch } from "react-redux";
import { fetchSelectedCourseProgress } from '../../../../actions/innovative/course.action';
import ChartDataFetch from '../../../innovative_student/studentProfile/chartDataFetch';
import { usePrevious } from '../../../../utils/customHooks';
import { get as _get } from 'lodash';
import _debounce from "lodash/debounce";

const MODULE_COMPARISON_FETCH_ARRAY_SIZE = 3;

const ModuleProgressGrid = ({ courseId, selectedTerm, isTabSelected, setSearchFunc }) => {
  const dispatch = useDispatch();
  const control = useRef();

  const [chartDataController, setChartDataController] = useState(null);
  const [fetchModuleProgressData, setFetchModuleProgressData] = useState(false);
  const [selectedTablePagination, setSelectedTablePagination] = useState(0);
  const [studentList, setStudentList] = useState([]);

  // selectors
  const studentDetailsArray = useSelector(makeInnovativeCourseMainReportStudentsResponseData);
  const isStudentDataLoaded = useSelector(makeInnovativeCoursesMainReportLoading);
  const moduleData = useSelector(makeModuleProgress);

  const prevValues = usePrevious({ studentDetailsArray });

  useEffect(() => {
    setStudentList(studentDetailsArray);
  }, [studentDetailsArray]);

  // student search from the array
  const onStudentSearch = useCallback(_debounce((keyword) => {
    if (keyword) {
      const filtered = studentDetailsArray.filter(student => {
        return student['student_name'].toLowerCase().includes(keyword.toLowerCase());
      });

      return setStudentList(filtered);
    }

    return setStudentList(studentDetailsArray);
  }, 500), [studentDetailsArray]);

  // init debounced calls
  const debouncedCall = (event, callback) => {
    const { value } = event.target;
    callback(value);
  };

  useEffect(() => {
    const preVisibleStatus = _get(prevValues, 'studentDetailsArray', []);

    if (!isStudentDataLoaded && (JSON.stringify(preVisibleStatus) !== JSON.stringify(studentDetailsArray))) {
      setFetchModuleProgressData(true);
    }
  }, [prevValues, isStudentDataLoaded]);

  useEffect(() => {
    // create abort controller instance for abort fetch calls
    control.current = new AbortController();

    return () => {
      // component will unmount
      control.current.abort()
    };
  }, []);

  useEffect(() => {
    // initiate fetching chart data after main report data loaded
    if (isTabSelected && control.current && fetchModuleProgressData && studentDetailsArray.length) {
      const studentIds = studentDetailsArray.map(student => student.student_id)
      if (studentIds.length) {
        fetchChartData(studentIds)
      }
      setFetchModuleProgressData(false);
    }
  }, [control.current, fetchModuleProgressData, studentDetailsArray, isTabSelected]);

  useEffect(() => {
    if (moduleData && moduleData.length && chartDataController) {
      chartDataController.validateArray(moduleData.map(data => data.studentId), selectedTablePagination)
    }
  }, [chartDataController, moduleData, selectedTablePagination])

  const fetchChartData = async (studentIds) => {
    // create object of data handler and initiate chart data fetching
    const chartController = new ChartDataFetch(
      MODULE_COMPARISON_FETCH_ARRAY_SIZE,
      studentIds,
      dispatchChartData
    );

    setChartDataController(chartController);
    chartController.initiateFetch();
  }

  const dispatchChartData = (studentId) => {
    const data = {
      "course_id": courseId,
      "student_id": studentId,
    }
    if (studentId && courseId) {
      dispatch(fetchSelectedCourseProgress({ ...data }, control.current.signal))
    }
  }

  const getColumnDefinition = () => {
    return [
      {
        key: "module_cards",
        dataIndex: 'module_cards',
        render: (value, row, index) =>
          <ModuleProgressCard
            data={row}
            index={index + 1}
            cardData={moduleData.filter((module) => module.studentId === row.student_id)?.[0]}
            courseId={courseId}
            selectedTerm={selectedTerm}
          />,
      },
    ];
  };

  return <ModuleProgressGridView
    isLoading={false}
    dataSource={studentList}
    columns={getColumnDefinition()}
    onSearch={e => debouncedCall(e, onStudentSearch)}
    setSelectedTablePagination={setSelectedTablePagination}
    setSearchFunc={setSearchFunc}
  />
};

export default ModuleProgressGrid;