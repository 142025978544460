const defaultCss = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    width: "100%",
    height: "34px",
    borderRadius: "38px",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },

  // In future if multiline input is needed use below properties to change the css.
  // '&multiLine': {
  //   control: {
  //     fontFamily: 'monospace',
  //     minHeight: 63,
  //   },
  //   highlighter: {
  //     padding: 9,
  //     border: '1px solid transparent',
  //   },
  //   input: {
  //     padding: 9,
  //     border: '1px solid silver',
  //   },
  // },

  '&singleLine': {
    display: 'flex',
    width: "100%",
    borderRadius: "38px",
    justifyContent: "center",
    alignItems: "center",
    height: "34px",
    paddingLeft: "10px",

    highlighter: {
      borderRadius: "38px",
      height: "34px", 
      paddingTop: "6px",
    },
    input: {
      borderRadius: "38px",
      height: "34px",
      paddingLeft: "10px",
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const studentCss = {
  backgroundColor: '#bccefc',
};

const assignmentsMentionCss = {
  backgroundColor: '#fcbefc',
};

const courseMentionCss = {
  backgroundColor: '#ffd5ba',
};

const sectionMentionCss = {
  backgroundColor: '#c5fcbe',
};

export { 
  defaultCss, 
  studentCss,
  assignmentsMentionCss,
  courseMentionCss,
  sectionMentionCss,
 };
