import _get from 'lodash/get';

// VETTRACK_DB_MGT_USER_MANAGEMENT_USER
const dashboardMgtUser = 'vetTrack.dashboardManagement.user.vttUser';
export const makeJrGenUserDataResponse = state =>
  _get(state, dashboardMgtUser, {});

export const makeDashboardSelections = state =>
  _get(state, `${dashboardMgtUser}.dashboards`, {});

export const makeDashboardSelectionsLoading = state =>
  _get(state, `${dashboardMgtUser}.dashboards.isLoading`, false);

export const makeDashboardSelectionsData = state =>
  _get(state, `${dashboardMgtUser}.dashboards.data.dashboards`, []);

export const makeSelectedDashboard = state =>
  _get(state, `${dashboardMgtUser}.dashboards.selected`, false);

export const makeUserList = state =>
  _get(state, `${dashboardMgtUser}.list`, {});

export const makeUserListLoading = state =>
  _get(state, `${dashboardMgtUser}.list.isLoading`, false);

export const makeUserListData = state =>
  _get(state, `${dashboardMgtUser}.list.data.users`, []);

export const makeFetchUserListData = state =>
  _get(state, `${dashboardMgtUser}.list.data`, []);
  
export const makeAddUser = state =>
  _get(state, `${dashboardMgtUser}.added`, {});

export const makeAddUserLoading = state =>
  _get(state, `${dashboardMgtUser}.added.isLoading`, false);

export const makeDeleteUser = state =>
  _get(state, `${dashboardMgtUser}.deleted`, {});

export const makeDeleteUserLoading = state =>
  _get(state, `${dashboardMgtUser}.deleted.isLoading`, false);

export const makeResetUserPassword = state =>
  _get(state, `${dashboardMgtUser}.resetPassword`, {});

export const makeResetUserPasswordLoading = state =>
  _get(state, `${dashboardMgtUser}.resetPassword.isLoading`, false);
// VETTRACK_DB_MGT_USER_MANAGEMENT_USER_END

// VETTRACK_DB_MGT_LAYOUT
export const getSelectedMenu = state =>
  _get(state, 'vetTrack.dashboardManagement.layout.menu.selected', false);

export const getBreadcrumb = state =>
  _get(state, 'vetTrack.dashboardManagement.layout.breadcrumb', {});
// VETTRACK_DB_MGT_LAYOUT_END

// VETTRACK_DB_MGT_MY_DASHBOARD
const dashboardPath = 'vetTrack.dashboardManagement.dashboards';
export const myDashboardState = state => _get(state, dashboardPath, {});

export const makeDashboardListData = state =>
  _get(state, `${dashboardPath}.list.data`, []);

export const makeDashboardListLoading = state =>
  _get(state, `${dashboardPath}.list.isLoading`, false);

export const makeDashboardSavedDataLoading = state =>
  _get(state, `${dashboardPath}.saved.isLoading`, false);

export const makeDashboardValidityLoading = state =>
  _get(state, `${dashboardPath}.dashboardValidity.isLoading`, false);

export const makeDashboardValidityData = state =>
  _get(state, `${dashboardPath}.dashboardValidity.data`, {});
// VETTRACK_DB_MGT_MY_DASHBOARD_END
