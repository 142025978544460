import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { get as _get, startCase } from 'lodash';
import {
  fetchStudent,
  fetchStudentsReport,
  clearStudentReportData,
  fetchStudentsChartData,
  clearStudentReportChartData,
  clearStudentDetails,
  resetCourseStatus,
} from "../../../actions/innovative_student/student.action";
import {
  fetchStudentsTerms,
  fetchActingRole,
  fetchStudentList,
  fetchMasterSettings,
  clearStudentTermsData,
} from "../../../actions/innovative_student/commonData.action";
import {
  makeInnovativeStudentMainReportLoading,
  makeInnovativeStudentMainReportResponseData,
  makeInnovativeStudentResponseData,
  makeInnovativeStudentGaugeDataResponse,
  makeInnovativeStudentMainReportCourseComparisonResponseData,
  makeInnovativeCourseStatus as makeAdminSelectedState,
} from "../../../selectors/innovative_student/student.selector";
import {
  makeInnovativeStudentTermsLoading,
  makeInnovativeStudentTermsResponseData,
  makeActingRoleLoading,
  makeActingRoleResponseData,
  makeStudentListLoading,
  makeStudentListResponseData,
  makeInnovativeStudentSelectedMasterSettingsLoading,
  makeInnovativeMasterSettingByPath,
} from "../../../selectors/innovative_student/commonData.selector";
import StudentProfileView from "./StudentProfileView";
import { resolveCohortVisibility } from "../../../utils/innovative_student/resolveSettings";
import { setBreadcrumb } from "../../../actions/innovative/layout.action";
import useDidMountEffect from "../../../utils/innovative/customHooks";
import { ValidateURL, checkIfVariableHasValueAndLength, queryStringToObject } from "../../../utils/general";
import impressionHandler from "../../../utils/impressionHandler/impressionHandler";
import ChartDataFetch from "./chartDataFetch";
import session from "../../../utils/session";
import { ACTING_ROLES as ROLES } from "../../../constant/innovative_student";
import { ENROLLMENT_STATUSES } from '../../../utils/innovative/constants';
import {
  getAdminAccounts,
  getIsApplicationAdmin,
  getSelectedAccount,
  makeInnovativeCourseStatus,
} from '../../../selectors/innovative/course.selector';
import { setSelectedAccount, updateApplicationLevelAdmin, verifyAdminStatus } from '../../../actions/innovative/course.action';
import { resetSearchedStudents } from '../../../actions/innovative/student.action';
import Error403 from "../../dashboardCommon/errorPages/Error403";

const CHART_FETCH_ARRAY_SIZE = 5;
const ADMIN_PATH = "/insights/admin/student/profile";
const SEARCH_PARAMS_KEYS = ["token", "t", "access"];

const StudentProfileContainer = ({ history }) => {
  const [actingRoleState, setActingRoleState] = useState("");
  const [selectedTablePagination, setSelectedTablePagination] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [chartDataController, setChartDataController] = useState(null);
  const [selectedEnrollment, setSelectedEnrollment] = useState(ENROLLMENT_STATUSES[0])
  const control = useRef()
  const [switchFormValues, setSwitchFormValues] = useState({ published: true, unpublished: false });
  const dispatch = useDispatch();
  const search = queryStringToObject(useLocation().search);
  const state = queryStringToObject(useLocation().state);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const isAdminPath = path === ADMIN_PATH;
  const innovativeMetaData = session.get(_get(session, "keys.innovativeMeta", {}));
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin = _get(dashboardManagementData, "is_admin", false);

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  };

  // selectors
  const student = useSelector(makeInnovativeStudentResponseData);
  const studentReport = useSelector(
    makeInnovativeStudentMainReportResponseData
  );
  const studentReportLoading = useSelector(
    makeInnovativeStudentMainReportLoading
  );
  const terms = useSelector(makeInnovativeStudentTermsResponseData);
  const courseStatus = useSelector(makeInnovativeCourseStatus);
  const adminCourseStatus = useSelector(makeAdminSelectedState);
  const termsLoading = useSelector(makeInnovativeStudentTermsLoading);
  const gaugeData = useSelector(makeInnovativeStudentGaugeDataResponse);
  const courseComparison = useSelector(makeInnovativeStudentMainReportCourseComparisonResponseData);
  const actingRoleLoading = useSelector(makeActingRoleLoading);
  const studentsLoading = useSelector(makeStudentListLoading);
  const students = useSelector(makeStudentListResponseData);
  const masterSettingsLoading = useSelector(makeInnovativeStudentSelectedMasterSettingsLoading);
  const cohortVisibility = useSelector(makeInnovativeMasterSettingByPath("features.cohortVisibility"));
  const accountList = useSelector(getAdminAccounts);
  const selectedAccountId = useSelector(getSelectedAccount);
  const actingRole = useSelector(makeActingRoleResponseData);

  const getSearchParams = () => {
    if (applicationAdmin) {
      return [...SEARCH_PARAMS_KEYS, "actAs"];
    }
    return SEARCH_PARAMS_KEYS;
  };

  const validateURL = ValidateURL({
    url: window.location.search,
    validateUrlParams: getSearchParams(),
  });

  const filterCourseState = isAdminPath ? adminCourseStatus : courseStatus;

  const sideChartsConfigs = [
    { title: "Overall Course Grades" },
    { title: "Overall Task Submissions" },
  ];
  const entrollmentOptions = ENROLLMENT_STATUSES.map(status => {
    return { _id: status, name: startCase(status) };
  });



  const getRole = ({ actAs, actingRoleState, actingRole }) => {
    if (checkIfVariableHasValueAndLength(actAs)) {
      return actAs.toLowerCase();
    }
    if (checkIfVariableHasValueAndLength(actingRoleState)) {
      return actingRoleState;
    }
    if (checkIfVariableHasValueAndLength(actingRole)) {
      return actingRole;
    }
    return "";
  };

  useEffect(() => {
    setActingRoleState(getRole({ actAs, actingRoleState, actingRole }));
  }, [actAs, actingRoleState, actingRole]);

  const termChange = (e) => {
    abortCallsClearChartData();

    setSelectedTerm([e])
  }

  useEffect(() => {
    return () => {
      dispatch(resetSearchedStudents());
      dispatch(clearStudentTermsData());
      dispatch(clearStudentDetails());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(student).length) {
      const studentId = _get(student, 'student_id', null);
      if (!ROLES.parent.includes(actingRole) && isAdminPath) {
        setSelectedStudent(studentId);
      }
    }
  }, [JSON.stringify(student)]);

  const studentChange = (e) => {
    setSelectedStudent(e);
  }

  const onEnrollmentChanged = value => {
    abortCallsClearChartData();
    setSelectedEnrollment(value);
  };

  const resolveCohortVisible = useCallback(() => {
    if(actingRoleState.length){
      return resolveCohortVisibility(actingRoleState, cohortVisibility, { compareVal: "role", returnVal: "access" })
    }
   
  }, [actingRoleState, cohortVisibility]);

  const isCohortVisible = resolveCohortVisible();

  // component did mount calls
  useEffect(() => {
    //Set impression data
    impressionHandler.sendImpressionData("insights_student_main");

    // Set impression data
    setTimeout(() => {
      dispatch(fetchActingRole());
      dispatch(fetchMasterSettings());
    }, 1000)
  }, []);

  useEffect(() => {
    const initStudentId = session.get([
      session.keys.innovativeMeta,
      "token"
    ]);

    const checkRole = getRole({ actAs, actingRoleState, actingRole });

    if (
      checkRole.length &&
      !actingRoleLoading &&
      ROLES.student.includes(checkRole) &&
      !isAdminPath
    ) {
      setSelectedStudent(parseInt(initStudentId));
    }
  }, [actingRoleState, actingRoleLoading]);

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs.toLowerCase(),
    }));
  }, [actAs]);

  useEffect(() => {
    // Fetch terms after selecting a student
    if (selectedStudent) {
      setSelectedEnrollment(ENROLLMENT_STATUSES[0]);
      dispatch(resetCourseStatus());
      let payload = {
        student_id: selectedStudent,
      };

      if (applicationAdmin) {
        payload = {
          ...payload,
          is_admin: applicationAdmin,
        };
      };

      dispatch(
        fetchStudent({
          requestParams: payload
        })
      );
      dispatch(fetchStudentsTerms(payload));
    }
  }, [selectedStudent]);

  useDidMountEffect(() => {
    if (accountList.length && accountList[0]) {
      dispatch(setSelectedAccount(selectedAccountId ? selectedAccountId : accountList[0]._id))
    }
  }, [accountList]);

  useEffect(() => {
    const innovativeAccess = _get(innovativeMetaData, "access", "");
    if (actingRoleState && innovativeAccess.length) {
      // check the user's acting role and initiate fetch calls
      if (ROLES.student.includes(actingRoleState)) {
        if (path === ADMIN_PATH) {
          dispatch(verifyAdminStatus(adminPayload));
        }
      } else if (ROLES.parent.includes(actingRoleState)) {
        // Set impression data
        impressionHandler.sendImpressionData("insights_parent_main");
        const payload = applicationAdmin ? { is_admin: applicationAdmin } : {};
        dispatch(fetchStudentList(payload));
      }
    }
  }, [actingRoleState, JSON.stringify(innovativeMetaData)])

  useEffect(() => {
    // create abort controller instance for abort fetch calls
    control.current = new AbortController();

    dispatch(clearStudentReportData())
    dispatch(clearStudentReportChartData())

    return () => {
      // component will unmount
      control.current.abort()
    };
  }, []);

  useEffect(() => {
    // initiate fetching chart data after main report data loaded
    if (courseComparison && !studentReportLoading && isCohortVisible) {
      const courseIds = courseComparison.map(course => course.course_id)
      if (courseIds.length > 0) {
        fetchChartData(courseIds)
      }
    }
  }, [courseComparison, studentReportLoading, isCohortVisible])

  useDidMountEffect(() => {
    if (terms && terms[0]) {
      if (state && state.termId) {
        return setSelectedTerm([state.termId])
      }
      setSelectedTerm([terms[0]._id])
    }
  }, [terms]);

  useDidMountEffect(() => {
    if (ROLES.parent.includes(actingRole) && students && students[0]) {
      setSelectedStudent(students[0].id);
    } else if (students && students[0]) {
      if (state && state.studentId) {
        setSelectedStudent(state.studentId);
      }
      else {
        setSelectedStudent(students[0].id);
      }
    }
  }, [students, actingRole]);

  useEffect(() => {
    if (
      checkIfVariableHasValueAndLength(actingRole) ||
      checkIfVariableHasValueAndLength(actingRoleState)
    ) {
      if (selectedTerm) {
        let payload = {
          filter_course_states: filterCourseState,
          student_id: selectedStudent,
          term_ids: selectedTerm,
          course_states: ["published"],
          enrollment_status: selectedEnrollment,
        };

        if (applicationAdmin) {
          payload = {
            ...payload,
            is_admin: applicationAdmin,
          };
        };

        dispatch(fetchStudentsReport({
          requestParams: payload
        }));
      }
    }
  }, [
    JSON.stringify(selectedStudent),
    JSON.stringify(selectedTerm),
    actingRoleState,
    actingRole,
    selectedEnrollment,
    JSON.stringify(filterCourseState)])

  // setting breadcrumb
  useEffect(() => {
    if (Object.keys(student).length) {
      const studentId = _get(student, 'student_id', null);
      if (ROLES.student.includes(actingRoleState) && !isAdminPath) {
        setSelectedStudent(studentId);
      }

      let breadCrumb = [
        { key: "home", section: "Home" },
      ];
      if (selectedStudent) {
        breadCrumb.push({ key: "student", section: student["student_name"] });
      }
      dispatch(setBreadcrumb(breadCrumb));
    }
  }, [JSON.stringify(student)]);

  // init breadcrumb
  useEffect(() => {
    const breadCrumb = [
      { key: "home", section: "Home" },
    ];
    dispatch(setBreadcrumb(breadCrumb));
  }, []);

  useEffect(() => {
    if (gaugeData && gaugeData.length > 0 && chartDataController) {
      chartDataController.validateArray(gaugeData.map(data => data.courseId), selectedTablePagination)
    }
  }, [gaugeData, selectedTablePagination])

  const fetchChartData = async (courseIds) => {
    // create object of data handler and initiate chart data fetching
    const chartController = new ChartDataFetch(CHART_FETCH_ARRAY_SIZE, courseIds, dispatchChartData);
    setChartDataController(chartController);
    chartController.initiateFetch();
  }

  const dispatchChartData = (course) => {
    let data = {
      course_ids: [course],
      student_id: selectedStudent,
      term_ids: selectedTerm,
      course_states: ["published"],
      filter_course_states: filterCourseState,
    };

    if (applicationAdmin) {
      data = {
        ...data,
        is_admin: applicationAdmin,
      };
    };

    if (course && selectedStudent && selectedTerm) {
      dispatch(fetchStudentsChartData({ courseId: course, ...data }, control.current.signal)).then(() => { }).catch(() => { })
    }
  }

  const abortChartFetchCalls = () => {
    control.current.abort()

    control.current = new AbortController();

  }

  const abortCallsClearChartData = () => {
    // remove ongoing chart data fetching calls
    abortChartFetchCalls();
    // clear current fetched chart data
    dispatch(clearStudentReportChartData());
  }

  if (!validateURL) {
    return <Error403
      onClick={() => history.goBack()}
      showBackButton={false}
    />
  };

  return <StudentProfileView
    studentsReport={studentReport}
    studentsReportLoading={studentReportLoading || actingRoleLoading || masterSettingsLoading}
    student={student}
    studentId={selectedStudent}
    sideChartsConfigs={sideChartsConfigs}
    terms={terms}
    termsLoading={termsLoading}
    termChange={termChange}
    students={students}
    studentsLoading={studentsLoading}
    studentChange={studentChange}
    selectedStudent={selectedStudent}
    selectedTerm={selectedTerm}
    currentViewTab={search.current}
    switchFormValues={switchFormValues}
    setSwitchFormValues={setSwitchFormValues}
    actingRole={getRole({ actAs, actingRoleState, actingRole })}
    roles={ROLES}
    selectedEnrollment={selectedEnrollment}
    entrollmentOptions={entrollmentOptions}
    onEnrollmentChanged={onEnrollmentChanged}
    setSelectedTablePagination={setSelectedTablePagination}
    setSelectedStudent={setSelectedStudent}
    isAdminPath={isAdminPath}
  />
};

export default StudentProfileContainer;
