import { get as _get } from 'lodash';
import {
  VTT_FETCH_USER_PERMISSIONS,
  VTT_FETCH_USER_PERMISSIONS_LOADING,
  VTT_SETTINGS_FETCH,
  VTT_SETTINGS_FETCH_LOADING,
  VTT_SETTINGS_ALTERNATION,
  VTT_SETTINGS_SAVE_LOADING,
  VTT_SETTINGS_RESET_EDIT_MODE,
  VTT_SETTINGS_DISCARD_DRAFT,
  VTT_SETTINGS_DISCARD_DRAFT_LOADING,
  VTT_SET_SETTINGS_ERRORS,
  VTT_SET_DATA_SOURCES_SYNC_STATUS,
  VTT_RI_SET_SYNC_STATUS,
  VTT_RC_SET_SYNC_STATUS,
} from '../../../../constant/actionTypes';
import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';
import { onSuccess } from '../../../../utils/notificationHandler';
import { subSections, settingsSections } from '../../../../constant/vetTrack/analysis';

/** Fetch user permissions * */
// fetch user permission loading
const fetchUserPermissionLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FETCH_USER_PERMISSIONS_LOADING,
    payload: { isLoading },
  });
};

// fetch user permission handler
const fetchUserPermissionDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FETCH_USER_PERMISSIONS,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch user permission
export const fetchUserPermission = data => dispatch => {
  dispatch(fetchUserPermissionLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/settings/fetchSettingPermissions`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchUserPermissionDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchUserPermissionDataStatus({
          error: error.message,
        }),
      );
    });
};

/** Fetch Settings - this is a global setting fetch request handler * */
// fetch settings loading
const fetchSettingsLoading = (
  isLoading = true,
  section,
  subSection,
) => dispatch => {
  dispatch({
    section,
    path: `${subSection ? `${subSection}.` : ''}selected`,
    type: VTT_SETTINGS_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch settings handler
const fetchSettingsDataStatus = (response, section, subSection) => dispatch => {
  dispatch({
    section,
    type: VTT_SETTINGS_FETCH,
    path: `${subSection ? `${subSection}.` : ''}data`,
    payload: response.data,
  });
};

// fetch settings
export const fetchSettings = (
  data,
  section,
  subSection = null,
  subSectionId = null,
) => dispatch => {
  dispatch(fetchSettingsLoading(true, section, subSection));

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/settings/fetch`,
      method: 'POST',
      data: {
        data,
        section,
        subSection,
        subSectionId,
      },
    })
    .then(response => {
      dispatch(fetchSettingsLoading(false, section, subSection));
      dispatch(
        fetchSettingsDataStatus(
          {
            data: response.data,
          },
          section,
          subSection,
        ),
      );
      dispatch(setSetttingError(subSection, {}));
    })
    .finally(() => {
      dispatch(fetchSettingsLoading(false, section, subSection));
    });
};

/** Setting Alternation global handler * */
// settings alternations
export const setSettings = (
  data,
  path,
  section,
  subSection = null,
) => dispatch => {
  dispatch({
    section,
    subSection,
    path: `${subSection ? `${subSection}.` : ''}${path}`,
    type: VTT_SETTINGS_ALTERNATION,
    payload: { data },
  });
};

/** Save Settings - this is a global setting save request handler * */
// save settings loading
const saveSettingsLoading = (
  isLoading = true,
  section,
  subSection,
) => dispatch => {
  dispatch({
    section,
    path: `${subSection ? `${subSection}.` : ''}saved`,
    type: VTT_SETTINGS_SAVE_LOADING,
    payload: { isLoading },
  });
};

// save settings
export const saveSettings = (
  data,
  mode,
  section,
  subSection = null,
  subSectionId = null,
  reFetchSection = true,
  callback = null,
) => dispatch => {
  dispatch(saveSettingsLoading(true, section, subSection));

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/settings/save`,
      method: 'POST',
      data: {
        mode,
        data,
        section,
        subSection,
        subSectionId,
      },
    })
    .then(res => {
      dispatch(saveSettingsLoading(false, section, subSection));
      onSuccess('Settings Applied Successfully!');
      dispatch(resetEditMode());

      if (reFetchSection) {
        dispatch(fetchSettings({}, section, subSection, subSectionId));
      }

      if (callback) {
        callback();
      }
    })
    .finally(() => {
      dispatch(saveSettingsLoading(false, section, subSection));
      dispatch(fetchSectionSyncStatus({ section, subSectionId }));
    });
};

/** Reset Edit mode * */
// reset edit mode
export const resetEditMode = () => dispatch => {
  dispatch({
    type: VTT_SETTINGS_RESET_EDIT_MODE,
  });
};

/** Discard draft * */
// discard draft loading
const discardDraftLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_SETTINGS_DISCARD_DRAFT_LOADING,
    payload: { isLoading },
  });
};

// discard draft handler
const discardDraftDataStatus = response => dispatch => {
  dispatch({
    type: VTT_SETTINGS_DISCARD_DRAFT,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// discard draft
export const discardDraft = (section, subSection, subSectionId) => dispatch => {
  dispatch(discardDraftLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/settings/discardDraft`,
      method: 'POST',
      data: {
        section,
        subSection,
        subSectionId,
      },
    })
    .then(response => {
      dispatch(
        discardDraftDataStatus({
          data: response.data,
        }),
      );
      onSuccess('Draft Discarded Successfully!');
      dispatch(fetchSettings({}, section, subSection, subSectionId));
    })
    .catch(error => {
      dispatch(
        discardDraftDataStatus({
          error: error.message,
        }),
      );
    });
};

export const setSetttingError = (subSection, errors) => dispatch => {
  dispatch({
    type: VTT_SET_SETTINGS_ERRORS,
    payload: {
      subSection,
      errors,
    },
  });
};

export const fetchSectionSyncStatus = data => dispatch => {
  const { section, subSectionId } = data;
  const values = Object.values(subSections);
  const subSectionObj = values.find(item => item.id === subSectionId);
  const subSection = _get(subSectionObj, 'key', '');

  let actionType = '';
  switch (section) {
    case settingsSections.dataSources:
      actionType = VTT_SET_DATA_SOURCES_SYNC_STATUS;
      break;
    case settingsSections.riskCalculations:
      actionType = VTT_RC_SET_SYNC_STATUS;
      break;
    case settingsSections.riskIndicators:
      actionType = VTT_RI_SET_SYNC_STATUS;
      break;
    default:
      actionType = '';
  };

  service.make({
    service: `insights/vettrak/generic/risk-analysis/settings/fetchSyncStatus`,
    method: 'POST',
    data: {
      section,
      subSectionId,
    },
  }).then(response => {
    const data = _get(response, 'data', {});
    dispatch(setSyncStatus(actionType, subSection, data));
  }).catch(error => {});
};

const setSyncStatus = (type, section, data) => dispatch => {
  const { section: mainSection, subSectionId, ...rest } = data;
  dispatch({
    type,
    section,
    payload: rest,
  });
};
