import React, {memo, useEffect} from 'react';
import {Card, Drawer, Table, Tabs} from "antd";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    makeInnovativeAssignmentInformationLoading,
    makeInnovativeAssignmentInformationResponseData
} from "../../../../selectors/innovative/assignment.selector";

import Loader from "../../common/layout/Loader";
import {withTablePaginator} from "../../common/table/hoc/withTablePaginator";
import {fetchAssignmentsInformation} from "../../../../actions/innovative/assignment.action";

const { TabPane } = Tabs;

const AssignmentComparisonGridView = (
    {
        dataSource,
        columns,
        isLoading,
        pagination,
        assignmentDrawerVisibility,
        assignmentDrawerData,
        setAssignmentDrawerMeta,
        assignmentId,
        setTaskTablePagination,
    }
) => {
    const {studentId, courseId} = useParams();
    const dispatch = useDispatch();
    const assignmentInformation = useSelector(makeInnovativeAssignmentInformationResponseData);
    const assignmentInformationLoading = useSelector(makeInnovativeAssignmentInformationLoading);

    useEffect(() => {
        if (
            setTaskTablePagination &&
            typeof setTaskTablePagination === 'function'
        ) {
            setTaskTablePagination(pagination?.current);
        }
    }, [JSON.stringify(pagination)]);

    useEffect(() => {
        if (assignmentId && courseId && studentId) {
            dispatch(fetchAssignmentsInformation({
                requestParams: {
                    course_id: courseId,
                    student_id: studentId,
                    assignment_id: assignmentId
                }
            }));
        }
        
    }, [assignmentId, courseId, studentId]);

    return (
        <>
            <Card
                className="tableCoverCard studentDetailTable"
            >
                <Loader isLoading={isLoading}/>
                <Table dataSource={dataSource}
                       columns={columns}
                       pagination={pagination}
                       scroll={{x: "max-content"}}/>
            </Card>
            <Drawer
                width={640}
                placement="right"
                onClose={() => setAssignmentDrawerMeta(null)}
                visible={assignmentDrawerVisibility}
                className="assignment-drawer"
            >
                <p className="site-description-item-profile-p main-title-tab">
                    View Task
                </p>
                <Loader isLoading={assignmentInformationLoading}/>
                <div className="assignments-tab-view">
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab="Task" key="1">
                            <div className="tab-name main-title-tab">
                                {assignmentInformation?.name || 'name'}
                            </div>
                            <div className="tab-desc" dangerouslySetInnerHTML={{ __html: assignmentInformation?.description || 'No data to show' }}>
                            </div>
                        </TabPane>
                        <TabPane tab="Feedback" key="2">
                            {
                                assignmentInformation?.comments?.length > 0 ? assignmentInformation?.comments?.map(item => (
                                    <div>
                                        <div>{item?.comment}</div>
                                        <div>Updated at: {item?.updated_at}</div>
                                    </div>
                                ))
                                :
                                <div> No feedback to show </div>
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </Drawer>
        </>
    );
};

AssignmentComparisonGridView.propTypes = {
    isLoading: PropTypes.bool,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    pagination: PropTypes.object,
    assignmentDrawerVisibility: PropTypes.bool,
    assignmentDrawerData: PropTypes.object,
    setAssignmentDrawerMeta: PropTypes.func,
}

export default memo(withTablePaginator(AssignmentComparisonGridView));