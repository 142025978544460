import {
    FETCH_STUDENT,
    FETCH_STUDENT_LOADING,
    FETCH_STUDENT_REPORT,
    FETCH_STUDENT_REPORT_LOADING,
    CLEAR_STUDENT_DATA,
    FETCH_DUE_ASSIGNMENTS,
    FETCH_DUE_ASSIGNMENTS_LOADING,
    FETCH_STUDENT_TERMS_LOADING,
    FETCH_STUDENT_TERMS,
    CLEAR_STUDENT_TERMS_DATA,
    CLEAR_STUDENT_DETAILS,
    FETCH_STUDENT_PROFILE_CHART_DATA,
    CLEAR_STUDENT_PROFILE_CHART_DATA,
    SEARCH_STUDENT,
    SEARCH_STUDENT_LOADING,
    RESET_SEARCH_STUDENT_LOADING,
} from "../../constant/actionTypes";
import request from "../../utils/request";
import { getAccessToken } from "../../utils/general";
import _get from "lodash/get";

// clear last fetched chart data before new fetch
export const clearStudentReportChartData = () => dispatch => {
    dispatch({
        type: CLEAR_STUDENT_PROFILE_CHART_DATA,
    });
};

/*** cohort and gauge data fetch ***/
// handle response of the student cohort fetch request
export const setStudentsChartDataFetchStatus = response => dispatch => {
    const data = _get(response, 'data.result.course_comparison')

    if (data) {
        // loop through the array of response to update the redux
        data.map(item => dispatch({
            type: FETCH_STUDENT_PROFILE_CHART_DATA,
            payload: item
        }))
    }

};

// handle student cohort fetch request
export const fetchStudentsChartData = (data, signal) => dispatch => {

    const feedReducer = (response) => {
        dispatch(setStudentsChartDataFetchStatus(response));
    }

    return new Promise((resolve, reject) => {
        const requestOptions = {
            service: `insights/teacher_student_profile/report_details/cohorts/${getAccessToken()}`,
            method: "POST",
            authorized: true,
            signal,
            data,
            onSuccess: ({ response }) => {
                resolve(true);
                feedReducer(response);

            },
            onError: (response) => {
                reject(false);
            },
        };

        request.make(requestOptions);
    })
};


// clear last fetched data before new fetch
export const clearStudentReportData = () => dispatch => {
    dispatch({
        type: CLEAR_STUDENT_DATA,
    });
};

// clear last fetched data before new fetch
export const clearStudentDetails = () => dispatch => {
    dispatch({
        type: CLEAR_STUDENT_DETAILS,
    });
};

// clear last fetched data before new fetch
export const clearStudentTermsData = () => dispatch => {
    dispatch({
        type: CLEAR_STUDENT_TERMS_DATA,
    });
};

// Student report
// handle fetch loading state for student terms fetch request
export const fetchingStudentTerms = (isLoading = true) => dispatch => {
    dispatch(clearStudentTermsData())
    dispatch({
        type: FETCH_STUDENT_TERMS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the student terms fetch request
export const setStudentsTermsFetchStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: FETCH_STUDENT_TERMS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle student terms fetch request
export const fetchStudentsTerms = (data) => dispatch => {
    dispatch(fetchingStudentTerms());

    const feedReducer = (response) => {
        dispatch(setStudentsTermsFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/teacher_student_profile/filter_terms/${getAccessToken()}`,
        method: "POST",
        authorized: true,
        data: data,
        onSuccess: feedReducer,
        onError: feedReducer,
    };

    request.make(requestOptions);
};


// handle fetch loading state for student report fetch request
export const fetchingStudentReport = (isLoading = true) => dispatch => {
    dispatch(clearStudentReportData())
    dispatch({
        type: FETCH_STUDENT_REPORT_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the student report fetch request
export const setStudentsReportFetchStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: FETCH_STUDENT_REPORT,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle student report fetch request
export const fetchStudentsReport = (payload) => dispatch => {
    dispatch(fetchingStudentReport());

    const feedReducer = (response) => {
        dispatch(setStudentsReportFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/teacher_student_profile/report_details/${getAccessToken()}`,
        method: "POST",
        data: { ...payload.requestParams },
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// handle fetch loading state for due assignments fetch request
export const fetchingDueAssignmentsReport = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DUE_ASSIGNMENTS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the due assignments fetch request
export const setDueAssignmentsReportFetchStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: FETCH_DUE_ASSIGNMENTS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle due assignments fetch request
export const fetchDueAssignmentsReport = (payload) => dispatch => {
    dispatch(fetchingDueAssignmentsReport());

    const feedReducer = (response) => {
        dispatch(setDueAssignmentsReportFetchStatus(response));
    }


    const requestOptions = {
        service: `insights/teacher_student_profile/due_assignments/${getAccessToken()}`,
        method: "POST",
        data: { ...payload.requestParams },
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// handle fetch loading state for student report fetch request
export const fetchingStudent = (isLoading = true) => dispatch => {
    dispatch(clearStudentDetails())
    dispatch({
        type: FETCH_STUDENT_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the student report fetch request
export const setStudentFetchStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: FETCH_STUDENT,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle student report fetch request
export const fetchStudent = (payload) => dispatch => {
    dispatch(fetchingStudent());

    const feedReducer = (response) => {
        dispatch(setStudentFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/course_profile/student_details/${getAccessToken()}`,
        method: "POST",
        data: { ...payload.requestParams },
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// reset search student data
export const resetSearchedStudents = () => dispatch => {
    dispatch({
        type: RESET_SEARCH_STUDENT_LOADING,
    });
};

// handle search student loading state for search student request
export const searchingStudent = () => dispatch => {
    dispatch({
        type: SEARCH_STUDENT_LOADING,
        payload: {
            isLoading: true,
        }
    });
};

// handle response of the search student request
export const SearchStudentStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: SEARCH_STUDENT,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle search student request
export const searchStudent = payload => dispatch => {
    dispatch(searchingStudent());
    const feedReducer = response => {
        dispatch(SearchStudentStatus(response));
    }
    const requestOptions = {
        service: `insights/teacher_student_profile/search_students/${getAccessToken()}`,
        method: "POST",
        data: payload,
        authorized: true,
        onSuccess: feedReducer,
        onError: feedReducer
    };
    request.make(requestOptions);
};
