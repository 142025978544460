import React from "react";
import {List, Space} from 'antd';
import PropTypes from "prop-types";

import CardItem from "./partials/CardItem";

const MyDashboards = ({dashboards, isLoading, onActiveChange}) => {
    return (
        <div>
            <Space direction="vertical" size="large" style={{width: "100%"}}>
                <h4>My Dashboards</h4>
                <List
                    grid={{gutter:16, column: 1 }}
                    itemLayout="horizontal"
                    dataSource={dashboards}
                    loading={isLoading}
                    renderItem={dashboard => (
                        <CardItem dashboard={dashboard} activeChange={onActiveChange}/>
                    )}
                />
            </Space>
        </div>
    )
}

MyDashboards.propTypes = {
    dashboards: PropTypes.array,
    isLoading: PropTypes.bool,
    onActiveChange: PropTypes.func,
}

export default MyDashboards;