/***
 * This class is used to handle the chart data fetching functionality in student profile in Innovative dashboard
 * In this, array based fetching is based as the main fetching handler and data is fetched in sequence manner
***/

class ChartDataFetch {
    constructor(arraySize, courseIds, dispatchFunc) {
        this.courseArray = [];
        this.arraySize = arraySize;
        this.courseIds = courseIds;
        this.refCourseArray = [];
        this.dispatchFunc = dispatchFunc;
    }

    initiateFetch = () => {
        // add to array
        this.courseArray.push(...this.courseIds.slice(0, this.arraySize));
        this.refCourseArray = this.courseIds.slice(this.arraySize);
        // Initiate fetch call for courses
        this.courseArray.map(course => this.dispatchFunc(course));
    }

    isArrayCompleted = () => this.courseIds.length === this.courseArray.length;


    isDataRelevantToCurrentPagination = (sliceArrayFrom, sliceArrayTo, selectedTablePagination) => {
        const currentIndex = this.courseIds.length - (this.refCourseArray.length);

        if (selectedTablePagination) {
            if (currentIndex >= sliceArrayFrom && currentIndex <= sliceArrayTo) {
                return true;
            }
            return false;
        }
        return true;
    }

    validateArray = (fetchedCourses, selectedTablePagination) => {
        const sliceArrayFrom = (selectedTablePagination === 1 ? 0 : (selectedTablePagination - 2) * 10);
        const sliceArrayTo = (selectedTablePagination * 10 - 1);

        this.courseArray.map(course => {
            if (fetchedCourses.includes(course)) {
                const nextCourse = this.getNextCourse(sliceArrayFrom, sliceArrayTo, selectedTablePagination);
                if (nextCourse) this.updateArray(nextCourse, course);
            }
        })

    }

    getNextCourse = (sliceArrayFrom, sliceArrayTo, selectedTablePagination) => {
        if (!this.isArrayCompleted() && this.isDataRelevantToCurrentPagination(sliceArrayFrom, sliceArrayTo, selectedTablePagination)) {
            const newCourse = this.refCourseArray.reverse().pop();
            this.refCourseArray.reverse();

            return newCourse;
        } else {
            return false;
        }
    }

    updateArray = (newCourse, oldCourse) => {
        this.courseArray = this.courseArray.filter(item => item !== oldCourse);
        this.courseArray.push(newCourse);

        this.dispatchFunc(newCourse);
    }

}

export default ChartDataFetch;