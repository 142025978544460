import React from "react";
import {Layout, Button} from "antd";
import { ArrowLeftOutlined, SettingFilled, LogoutOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router';
import {Link} from "react-router-dom";
import {faTachometerAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import UserLogout from "../../auth/externalAuth/Logout/Logout";

import menuData from "../jrDashboardManagement/jrMenuData";
import logo from '../../../assets/images/sqLogo.png';
import {getDataFromLocalStore} from "../../../utils/platformBaseHelpers/jobReady/general";

import "../../../assets/css/vwDealership/layout.css";
import "../../../assets/css/vwDealership/dealershipDashboard.css";

const CommonLayout = ({children}) => {
    const {isAdmin} = getDataFromLocalStore([{key:"isAdmin", default: false}]);
    const history = useHistory();
    const isSettingPage = window.location.href?.includes('/dealership-settings');

    const backToDashboards = () => {
        return history.push(menuData.manageDashboard.url)
    }

    return (
        <Layout>
            <Layout.Header className="vw-header" style={{background: "white"}}>
                <div className="logo">
                    <img src={logo} alt="headerLogo"/>
                </div>
                {isSettingPage &&
                <div className="back-btn">
                    <Link to={"dealership"}> <Button icon={<ArrowLeftOutlined />} size="small"/></Link>
                </div>
                }
                <div className="header-button-group">
                    <Button type="primary" onClick={backToDashboards} icon={<FontAwesomeIcon icon={faTachometerAlt} />}>&nbsp; Dashboards</Button>
                    {isAdmin &&
                    //    allow only admin level users to setting page
                    <Link className="jr-setting-icon" to={"dealership-settings"}
                          title="Settings"><SettingFilled/></Link>
                    }
                    <UserLogout button={
                        ({onClick}) => <span title="Sign out" onClick={onClick} className="jr-setting-icon" style={{border: "none", cursor: "pointer"}}>
                            <LogoutOutlined/>
                        </span>
                    }/>
                </div>
            </Layout.Header>
            <Layout className="site-layout-background vw-content">
                <div className="vw-content-container">
                    {children}
                </div>
            </Layout>
        </Layout>
    );
};

export default CommonLayout;