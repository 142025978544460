import {
    STUDENT_CLEAR_STUDENT_TERMS_DATA,
    STUDENT_FETCH_ACTING_ROLE,
    STUDENT_FETCH_ACTING_ROLE_LOADING,
    STUDENT_FETCH_COURSE,
    STUDENT_FETCH_COURSE_LOADING,
    STUDENT_FETCH_COURSE_SETTINGS,
    STUDENT_FETCH_COURSE_SETTINGS_LOADING,
    STUDENT_FETCH_STUDENT_LIST,
    STUDENT_FETCH_STUDENT_LIST_LOADING,
    STUDENT_FETCH_STUDENT_TERMS,
    STUDENT_FETCH_STUDENT_TERMS_LOADING,
    STUDENT_FETCH_MASTER_SETTINGS,
    STUDENT_FETCH_MASTER_SETTINGS_LOADING
} from "../../constant/actionTypes";
import request from "../../utils/request";
import {getAccessToken} from "../../utils/general";

/*** fetch terms ***/
// clear last fetched data before new fetch
export const clearStudentTermsData = () => dispatch => {
    dispatch({
        type: STUDENT_CLEAR_STUDENT_TERMS_DATA,
    });
};

// Student report
// handle fetch loading state for student terms fetch request
export const fetchingStudentTerms = (isLoading = true) => dispatch => {
    dispatch(clearStudentTermsData())
    dispatch({
        type: STUDENT_FETCH_STUDENT_TERMS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the student terms fetch request
export const setStudentsTermsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_STUDENT_TERMS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle student terms fetch request
export const fetchStudentsTerms = (data) => dispatch => {
    dispatch(fetchingStudentTerms());

    const feedReducer = (response) => {
        dispatch(setStudentsTermsFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/student_profile/filter_terms/${getAccessToken()}`,
        method: "POST",
        authorized: true,
        data: data,
        onSuccess: feedReducer,
        onError: feedReducer,
    };

    request.make(requestOptions);
};

/*** Fetch Student List for Parent ***/
// handle fetch loading state for student list fetch request
export const fetchingStudentList = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_STUDENT_LIST_LOADING,
        payload: {
            isLoading
        }
    });
};


// handle response of the student list fetch request
export const setStudentListFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_STUDENT_LIST,
        payload: {
            isLoading,
            error,
            response,
        }
    });

};

// handle student list fetch request
export const fetchStudentList = (data, signal) => dispatch => {
    dispatch(fetchingStudentList());

    const feedReducer = (response) => {
        dispatch(setStudentListFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/student_profile/filter_observees/${getAccessToken()}`,
        method: "POST",
        authorized: true,
        signal,
        data,
        onSuccess: feedReducer,
        onError: feedReducer,
    };

    request.make(requestOptions);

};

/*** Acting Role Fetch ***/
// handle fetch loading state for acting role fetch request
export const fetchingActingRole = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_ACTING_ROLE_LOADING,
        payload: {
            isLoading
        }
    });
};


// handle response of the acting role fetch request
export const setActingRoleFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_ACTING_ROLE,
        payload: {
            isLoading,
            error,
            response,
        }
    });

};

// handle acting role fetch request
export const fetchActingRole = (data, signal) => dispatch => {
    dispatch(fetchingActingRole());

    const feedReducer = (response) => {
        dispatch(setActingRoleFetchStatus(response));
    }

    const requestOptions = {
        service: `tentacle/access/role/${getAccessToken()}`,
        authorized: true,
        signal,
        data,
        onSuccess: feedReducer,
        onError: feedReducer,
    };

    request.make(requestOptions);

};


/*** fetch course ***/
// Fetch selected course
// handle fetch loading state for course fetch request
export const fetchingCourse = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_COURSE_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the course fetch request
export const setCourseFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_COURSE,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle course fetch request
export const fetchCourse = (payload) => dispatch => {
    dispatch(fetchingCourse());

    const feedReducer = (response) => {
        dispatch(setCourseFetchStatus(response));
    }

    const requestOptions = {
        service: `mainreport/course_description/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

/*** fetch course settings ***/
// Fetch
// handle fetch loading state for settings fetch request
export const fetchingSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_COURSE_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the settings fetch request
export const setSettingsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_COURSE_SETTINGS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle settings fetch request
export const fetchSettings = (payload) => dispatch => {
    dispatch(fetchingSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        setTimeout(
            () => dispatch(setSettingsFetchStatus(response)),
            500
        );
    }

    const requestOptions = {
        service: `insights/settings/fetch/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

/*** fetch master settings ***/
// Fetch
// handle fetch loading state for settings fetch request
export const fetchingMasterSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_MASTER_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the settings fetch request
export const setMasterSettingsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_MASTER_SETTINGS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle settings fetch request
export const fetchMasterSettings = (payload) => dispatch => {
    dispatch(fetchingMasterSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        setTimeout(
            () => dispatch(setMasterSettingsFetchStatus(response)),
            500
        );
    }

    const requestOptions = {
        service: `insights/settings/fetchGlobal/${getAccessToken()}`,
        method: "POST",
        data: payload,
        authorized: true,
        onSuccess: feedReducer,
        // onError: feedReducer,
    };

    request.make(requestOptions);
};

