import React from 'react';
import {Layout, Row} from "antd";
import 'antd/dist/antd.css';
import '../../../assets/css/tentacle-management/main.css';
import logo from '../../../assets/images/octo-logo.png';
import SignOutContainer from "../../connections/signout/SignOutContainer";


const DefaultLayoutWithLogin = ({children}) => {
    return (
        <Layout>
            <Layout.Header>
                <div className="logo">

                    <Row justify={"space-between"} gutter={10} style={{margin: "0 5px"}}>
                        <div><img src={logo}/></div>
                        <SignOutContainer/>
                    </Row>
                </div>
            </Layout.Header>

            <Layout>
                <Layout.Content className="site-layout-background">
                    <div className="wideContainer">
                        {children}
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default DefaultLayoutWithLogin;