import React, { memo } from 'react';
import { Avatar, Card, Col, Image, Row } from "antd";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";
import PieChart from "../../common/charts/PieChart";
import StatCardGroup from "../../common/cards/StatCardGroup";

const blurAvatar = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/blur-avatar.jpg`;

const TopCardView = ({ isSectionLoading, riskChartOptions, selectedCourse, summaries, switches: Switches }) => {
  return (
    <Row gutter={[35, 0]}>
      <Loader isLoading={isSectionLoading} />
      <Col md={24} lg={16} xl={18}>
        <Row gutter={[20, 0]}>
          <Col span={24}>
            <Switches />
            <Card.Meta
              className="mainMetaDescription"
              title={selectedCourse['course_name']}
              description={selectedCourse['public_description']}
              avatar={
                <Avatar
                  size={75}
                  src={
                    <Image
                      preview={false}
                      width={75}
                      height={75}
                      src={selectedCourse['image_url']}
                      placeholder={
                        <Image
                          preview={false}
                          width={80}
                          height={80}
                          src={blurAvatar}
                        />
                      }
                    />
                  }
                />
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <StatCardGroup summaries={summaries} />
        </Row>
      </Col>
      <Col md={24} lg={8} xl={6}>
        <div className="chartRiskCondition">
          <h5>Risk Indicators</h5>
          <PieChart chartOptions={riskChartOptions} />
        </div>
      </Col>
    </Row>
  );
};

TopCardView.propTypes = {
  isSectionLoading: PropTypes.bool,
  selectedCourse: PropTypes.object,
  summaries: PropTypes.array,
  riskChartOptions: PropTypes.object,
};

export default memo(TopCardView);