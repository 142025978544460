import React, {Fragment, useState} from "react";
import {
    Form,
    Input,
    Button,
    Card,
    Row,
    Col,
    PageHeader,
    Typography,
    Checkbox,
    Spin,
} from "antd";

import {onSuccess, onError} from "../../../utils/notificationHandler";
import Recaptcha from "../../common/recaptcha";
import {LoadingOutlined} from "@ant-design/icons";
import ApiServices from "../../../services/onboarding";
import email from "../../../../src/assets/images/email/email.png";
import "../../../assets/css/onboarding/common.css";

const {Link} = Typography;

const SignupSuccessWidget = () => {
    return (
        <div className="successMessageDiv">
        <span><img className="successMessageIcon" src={email}></img></span>
        <h1 className="successMessageHeading">Thank you!</h1>
        <p className="successMessagepara">Welcome to Octopus Insights. Please check your inbox for all the details you need to get your account up and running.</p>
            <Button
                type={"primary"}
                size={"large"}
                onClick={() => (window.location.href = "https://octopusbi.com/tentacle/")}
            >
                Return Home
            </Button>
        </div>
    );
};

const DirectSignUpForm = () => {
    const recaptchaRef = React.useRef();
    const [form] = Form.useForm();
    const [signupLoading, setSignupLoading] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);

    const onFinish = (data) => {
        recaptchaRef.current.executeAsync().then(
            // captacha success
            (token) => {
                setSignupLoading(true);
                ApiServices.OnboardingConsumer.directSignUp(data, (response) => {
                    setSignupLoading(false);
                    !response.error && setSignupSuccess(true);
                    !response.error && form.resetFields();
                });
            }
        );
    };

    const renderForm = () => {
        return (
            <Form
                form={form}
                name="directSignup-disable-search"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={{ xs: 0, sm: 0, md: 24, lg: 32 }}>
                    <Col md={12} sm={24}>
                    <Form.Item
                    required
                    label="First Name"
                    name={"firstName"}
                    // style={{display: "inline-block", width: "49%"}}
                    rules={[{required: true, message: "Please input your first name!"}]}
                >
                    <Input size="middle"/>
                </Form.Item>
                    </Col>
                
                <Col md={12} sm={24}>
                <Form.Item
                    required
                    label="Last Name"
                    name={"lastName"}
                    // style={{display: "inline-block", width: "49%", marginLeft: "2%"}}
                    rules={[{required: true, message: "Please input your last name!"}]}
                >
                    <Input size="middle" data-lpignore="true"/>
                </Form.Item>
                    </Col>
                
                </Row>
                <Form.Item
                    required
                    name={"email"}
                    label="Email"
                    rules={[
                        {required: true, message: "Please input your Email!"},
                        {type: "email", message: "Please enter valid Email!"},
                    ]}
                >
                    <Input size="middle"/>
                </Form.Item>
                <Form.Item
                    required
                    label="Phone Number"
                    name={"contactNumber"}
                    rules={[
                        {required: true, message: "Please input your phone number"},
                    ]}
                >
                    <Input size="middle"/>
                </Form.Item>
                <Form.Item
                    required
                    name={"schoolName"}
                    label=" Organisation/ Institute Name"
                    rules={[
                        {
                            required: true,
                            message: "Please input your Organisation/ Institute Name!",
                        },
                    ]}
                >
                    <Input size="middle"/>
                </Form.Item>
                <Typography.Text type="secondary">
                    <ul style={{textAlign: "justify", fontSize: "0.9em"}}>
                        <li>
                            We are committed to protecting and respecting your privacy, and
                            we’ll only use your data to administer your account and to provide
                            the products and services you request. From time to time, we would
                            like to contact you regarding our products and services, as well
                            as other content that may be of interest to you.
                        </li>
                        <li>
                            In order to provide you the content requested, we need to store
                            and process your personal data.
                        </li>
                        <li>
                            You can unsubscribe from these communications at any time. For
                            more information on how to unsubscribe, our privacy practices, and
                            how we are committed to protecting and respecting your privacy,
                            please review our Privacy Policy.
                        </li>
                    </ul>
                </Typography.Text>
                <br/>
                <Form.Item>
                    <Checkbox defaultChecked={true} name="subscribe">
                        I agree to receive other communications from Octopus BI.
                    </Checkbox>
                    <br/>
                    <Checkbox required>
                        By clicking submit, you agree that you are authorised by your organisation to create an
                        OctopusBI account and agree that you have read and will be bound by our&nbsp;
                        <Link href="https://octopusbi.com/terms/" target="_blank">
                            Terms, Conditions
                        </Link> and &nbsp;
                        <Link href="https://octopusbi.com/privacy-policy/" target="_blank">
                            Privacy Policy
                        </Link>. You also agree that in order to provide the service, OctopusBI will need to
                        access, process and store some of your personal data.
                    </Checkbox>
                </Form.Item>
                <Button
                    type={"primary"}
                    size={"large"}
                    htmlType={"submit"}
                    loading={signupLoading}
                >
                    Submit
                </Button>
                <Recaptcha ref={recaptchaRef}/>
            </Form>
        );
    };

    return (
        <Fragment>
            <Spin spinning={signupLoading} indicator={<LoadingOutlined/>}>
                <Row gutter={100} justify={"center"}>
                    <Col md={15} sm={24}>
                        <br/>
                        {signupSuccess && <SignupSuccessWidget/>}
                        {signupSuccess === false && (
                            <Card
                                title={
                                    <span>
                    <b>Sign Up</b> | Facinating insights are right ahead!
                  </span>
                                }
                                bordered={false}
                                bodyStyle={{
                                    boxShadow: "0 2px 4px #d4d4d4",

                                }}
                                headStyle={{
                                    background: "#4A44E5",
                                    color: "#fff",
                                }}
                                className="sign-up-form-container"
                            >
                                {renderForm()}
                            </Card>
                        )}
                        <br/>
                    </Col>
                </Row>
            </Spin>
        </Fragment>
    );
};

export default DirectSignUpForm;
