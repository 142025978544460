import React from "react";
import PropTypes from "prop-types";
import {Menu, Typography} from "antd";

import JRSidebar from "../../common/layout/Sidebar";


const SettingSidebar = ({onChange, sections, selectedSection}) => {
    const renderMenuItem = (item) => (
        <Menu.Item key={item.key} >
            {item.name}
        </Menu.Item>
    )

    const renderMenu = () => {
        return (
            <div>
                <Typography.Paragraph strong className="jr-gen-setting-sidebar-header">Manage Risk Settings</Typography.Paragraph>
                <Menu mode="inline" selectedKeys={[selectedSection && selectedSection.key]}  onClick={onChange}>
                    {sections.map(section => renderMenuItem(section))}
                </Menu>
            </div>
        )
    }

    return (
        <JRSidebar>
            {renderMenu()}
        </JRSidebar>
    )
}

SettingSidebar.propTypes = {
    onChange: PropTypes.func,
    sections: PropTypes.array,
    selectedSection: PropTypes.object,
}

export default SettingSidebar;