// return consumer fetch response
import {makeUserTokenValidationResponse} from "../userTokenValidate.selector";

export const authentication = state => state && state.Authentication;

// get Sub User
export const getSubUser = state => {
    const result = authentication(state);
    return result && result.subUser;
};

// return Sub user response loading
export const getSubUserLoading = state => {
    const result = getSubUser(state)
    return result.isLoading
};

// return Sub user response data
export const getSubUserResponse = state => {
    const result = getSubUser(state)
    return result && result.response
};

// return Sub user IsloggedIn
export const getSubUserIsLoggedIn = state => {
    const result = getSubUserResponse(state)
    return result && result.isLoggedIn
};


// return Sub user verified
export const getSubUserIsVerified = state => {
    const result = getSubUserResponse(state)
    return result && result.verified
};

// return Sub user Partner
export const getSubUserPartnerId = state => {
    const result = getSubUserResponse(state)
    return result && result.partnerId
};

