import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty as _isEmpty,
  upperFirst as _upperFirst,
} from 'lodash';

import {
  getDashboardFiltersSelectedData,
  getOverviewStudentsLoading,
  getOverviewStudentsData,
} from '../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchOverviewStudents } from '../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/overview.action';
import {
  isEmptyValue,
  onFilterColumn,
  sortDataColumn,
  getUniqValuesForFilters
} from '../../../../../../../utils/general';
import { noDataValue } from '../../../../../../../../../../constant/vetTrack/analysis';

import StudentTable from './StudentTable';
import ProgressBarCell from '../../../../common/ProgressBarCell';
import StudentDetailCell from '../../../../common/StudentDetailCell';
import Loader from '../../../../../../../common/layout/Loader';
import NoDataCell from '../../../../../../../common/partials/NoDataCell';

const StudentTableContainer = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const students = useSelector(getOverviewStudentsData);
  const studentDataLoading = useSelector(getOverviewStudentsLoading);

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters)) {
      const data = {
        filters: selectedFilters,
        subSectionId: 0,
      };
      dispatch(fetchOverviewStudents(data));
    }
  }, [selectedFilters]);

  const renderProgressCellFilters = () => [
    {
      text: 'High',
      value: 'high',
    },
    {
      text: 'Medium',
      value: 'medium',
    },
    {
      text: 'Low',
      value: 'low',
    },
    {
      text: 'No Data',
      value: noDataValue,
    },
  ];

  const renderProgressCells = data => {
    if (!_isEmpty(data) && !isEmptyValue(data.value)) {
      return (
        <div className={`overview-progress-cell-${data.text}`}>
          <ProgressBarCell
            value={data.value}
            displayValue={_upperFirst(data.text)}
            color={data.color}
            style={{ width: '90%' }}
          />
        </div>
      );
    }

    return <NoDataCell />;
  };

  const columns = [
    {
      title: 'Student Details',
      dataIndex: 'studentDetails',
      sorter: (value1, value2) =>
        value1.studentDetails.name &&
        value1.studentDetails.name.localeCompare(value2.studentDetails.name),
      render: value => (
        <StudentDetailCell
          title={value.name}
          subtitle={value.id}
          avatar={value.image}
        />
      ),
      onFilter: (value, record) => record.studentDetails.name === value,
      filters: getUniqValuesForFilters(
        students,
        'studentDetails.name',
        'studentDetails.name',
      ),
    },
    {
      title: 'Academic',
      dataIndex: 'academic',
      sorter: (row1, row2) => sortDataColumn(row1, row2, 'academic'),
      onFilter: (value, record) => onFilterColumn(value, record, 'academic'),
      render: value => renderProgressCells(value),
      filters: renderProgressCellFilters(),
    },
    {
      title: 'Financial',
      dataIndex: 'financial',
      sorter: (row1, row2) => sortDataColumn(row1, row2, 'financial'),
      onFilter: (value, record) => onFilterColumn(value, record, 'financial'),
      render: value => renderProgressCells(value),
      filters: renderProgressCellFilters(),
    },
    {
      title: 'Compliance',
      dataIndex: 'compliance',
      sorter: (row1, row2) => sortDataColumn(row1, row2, 'compliance'),
      onFilter: (value, record) => onFilterColumn(value, record, 'compliance'),
      render: value => renderProgressCells(value),
      filters: renderProgressCellFilters(),
    },
  ];

  return (
    <div className="overviewTable">
      <Loader isLoading={studentDataLoading}>
        <StudentTable students={students} columns={columns} />
      </Loader>
    </div>
  );
};

export default StudentTableContainer;
