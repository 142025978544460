import {
    JR_GEN_SIDEBAR_FILTERS_FETCH,
    JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING,
    JR_GEN_SIDEBAR_FILTERS_SELECT,
    JR_GEN_SET_SELECTED_CARDS
} from "../../../constant/actionTypes";
import {subSections} from "../../../constant/jobReadyGeneric/constants";

const defaultState = {
    filters: {
        fetched: {
            isLoading: false,
            data: {}
        },
        selected: {
        }
    },
    selectedCards: {
        [subSections.academic.key]: null,
        [subSections.financial.key]: null,
        [subSections.compliance.key]: null,
        [subSections.wellBeing.key]: null,

    }
}

const raCommonReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_SIDEBAR_FILTERS_FETCH:
        case JR_GEN_SIDEBAR_FILTERS_FETCH_LOADING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    fetched: {
                        ...state.filters.fetched,
                        ...action.payload
                    }
                }
            }
        case JR_GEN_SIDEBAR_FILTERS_SELECT:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selected: {
                        ...action.payload
                    }
                }
            }
        case JR_GEN_SET_SELECTED_CARDS:
            return {
                ...state,
                selectedCards: {
                    ...state.selectedCards,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default raCommonReducer;