import React, {useEffect} from "react";
import {Card, Table, Empty} from "antd";
import PropTypes from "prop-types";

import Loader from "../../common/layout/Loader";


const OutcomeGridView = ({isLoading, outcomeDataLoaded, dataSource, columns, pagination, setSearchFunc, onSearch}) => {
    // set search bar
    useEffect(() => {
        dataSource && setSearchFunc(prev =>onSearch);
    }, [dataSource])

    return (
        <Card className="tableCoverCard">
            <Loader isLoading={isLoading}/>
            {outcomeDataLoaded ?
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                    scroll={{x: "max-content"}}
                />
                :
                <Empty />
            }
        </Card>
    )
}

OutcomeGridView.defaultProps = {
    isLoading: false,
};

OutcomeGridView.propTypes = {
    isLoading: PropTypes.bool,
    onSearch: PropTypes.func,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    setSearchFunc: PropTypes.func,
    outcomeDataLoaded: PropTypes.bool,
}

export default OutcomeGridView;