import React, {useEffect} from 'react';
import {Form, Modal, notification, Button} from 'antd';
import StepKeyConfig from "../../../signup/steps/views/StepKeyConfig";

import {regenKey, regenKeyReset} from "../../../../../actions/consumer.action";
import {useDispatch, useSelector} from "react-redux";
import {makeKeygenLoading, makeKeygenResponseData} from "../../../../../selectors/consumer.selector";

const RegenerateKeyModal = ({config, data}) => {
    const [form] = Form.useForm();
    const {key} = data;
    const dispatch = useDispatch();
    const keygenResponse = useSelector(makeKeygenResponseData);

    useEffect(() => {
        form.setFieldsValue({
            consumerKey: undefined,
            secretKey:  "****************",
        });
        if(keygenResponse && keygenResponse.consumerKey){
            form.setFieldsValue({
                consumerKey: keygenResponse.consumerKey
            });
        }
    }, [keygenResponse])

    useEffect(() => {
        if (key) {
            console.log('after key changed', key);
        }
        dispatch(regenKeyReset())
    }, [key]);


    const showErrNotification = (title, message) => {
        notification.error({
            message: title,
            description:message,
            duration: 2
        });
    }



    const onKeyRegenerate = (field) => {
        dispatch(regenKey(key, field, showErrNotification))
    }

    return (
        <Modal
            okButtonProps={{ style: { display: 'none' } }}
            cancelText={"Close"}
            {...config}
        >
            <Form name="keyRegenerateModal" layout="vertical" form={form}>
                <StepKeyConfig hasRegenerate={true} onKeyRegenerate={onKeyRegenerate}/>
            </Form>
        </Modal>
    );
};

export default RegenerateKeyModal;