import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ children, isLoading, iconComponent, loadingText }) => {
    const antIcon = iconComponent ? iconComponent : <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <div>
            <Spin className={'ds-loader'} indicator={antIcon} spinning={isLoading}>
                {children}
            </Spin>
            {
                isLoading && <div style={{ fontSize: 24 }}>
                    {loadingText}
                </div>
            }
        </div>
    )
}
Loader.defaultProps = {
    isLoading: false
}
export default Loader;