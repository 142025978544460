import React from "react";
import AccPrincipalProfile from "./AccPrincipalProfile";
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Error403 from "../../dashboardCommon/errorPages/Error403";
import { getInterimUser } from "../../../selectors/tentacle/auth.selector";

const PrincipalProfile = ({ Authentication, ...props }) => {
    const interimUser = useSelector(getInterimUser);
    const goToHome = () => {
        props.history.go(-1);
    }

    if (interimUser.school === "acc") {
        if (interimUser.role === "ceo" || interimUser.role === "principal") {
            return <AccPrincipalProfile location={props.location} userRole={interimUser.role}/>
        } else {
            return (
                <div><Error403 onClick={goToHome} text="Back Home"/></div>
            )
        }
    } else {
        return (
            <div><Error403 onClick={goToHome} text="Back Home"/></div>
        )
    }
};


export default withRouter(PrincipalProfile);