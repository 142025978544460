import React, {Fragment, useEffect} from "react";
import {Card, Col, PageHeader, Row, Typography, Form, Button} from "antd";
import PropTypes from "prop-types";
import {SaveOutlined} from "@ant-design/icons";

import TwoFaAuthentication from "./partials/TwoFaAuth";
import AccessUserRoles from "./partials/AccessUserRoles";

const {Title, Paragraph} = Typography;

const ShareOptionsView = ({userRoles, sharedUserRoles, form, onFormSubmit, dashboard}) => {

     const formatSharedGroupsGetData = () => {
         // format backend data as per the structure of the TreeSelect
         let formattedGroups = {};
         sharedUserRoles && sharedUserRoles.map(a => {
             if(formattedGroups[`userRoles.${a.account_id}`]){
                 formattedGroups[`userRoles.${a.account_id}`] = [...formattedGroups[`userRoles.${a.account_id}`], a.name]
             }else{
                 formattedGroups[`userRoles.${a.account_id}`] = [a.name]
             }
         });

         return formattedGroups;
    }

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
             ...formatSharedGroupsGetData(),
            "2faAuth":  dashboard && dashboard.otp_channel === "email",
        });
    }, [dashboard, sharedUserRoles])

    const renderForm  = () => {
        return (
            <Form
                id="shareDashboard"
                layout="vertical"
                name="basic"
                form={form}
                onFinish={onFormSubmit}
            >
                {/* TODO: enable this once the implementation is completed */}
                {/*<TwoFaAuthentication name="2faAuth"/>*/}
                <br/>

                <Title level={5}>Select Access Groups</Title>
                <AccessUserRoles userRoles={userRoles}/>
                <br/>

                <Row justify={"end"} className="bottomButtonList">
                    <Col span={4}>
                        <Form.Item style={{float:'right'}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    return (
        <div className="in-container">
            <Card className="shareOptions">
                <Title level={3}>Share Options</Title>
                {/* TODO: need to add proper description */}
                {/*<Paragraph>*/}
                {/*    Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
                {/*    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,*/}
                {/*    when an unknown printer took a galley of type and scrambled it to make a type specimen book*/}
                {/*</Paragraph>*/}

                {renderForm()}

            </Card>
        </div>
    )

}

ShareOptionsView.propTypes = {
    userRoles: PropTypes.array,
    form: PropTypes.object,
    onFormSubmit: PropTypes.func,
    sharedUserRoles: PropTypes.array,
    dashboard: PropTypes.object,
}

export default ShareOptionsView;