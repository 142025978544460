import {
  VTT_FETCH_MY_DASHBOARDS_LOADING,
  VTT_FETCH_MY_DASHBOARDS,
  VTT_SAVE_DASHBOARD,
  VTT_SAVE_DASHBOARD_LOADING,
  VTT_FETCH_DASHBOARD_VALIDITY,
  VTT_FETCH_DASHBOARD_VALIDITY_LOADING,
} from '../../../constant/actionTypes';

const initialState = {
  list: {
    isLoading: false,
    data: [],
    error: false,
  },
  saved: {
    isLoading: false,
    error: false,
  },
  // used to store active status of the selected dashboard
  dashboardValidity: {
    isLoading: false,
    data: { active: true },
  },
};

const myDashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case VTT_FETCH_MY_DASHBOARDS_LOADING:
    case VTT_FETCH_MY_DASHBOARDS:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    case VTT_SAVE_DASHBOARD_LOADING:
    case VTT_SAVE_DASHBOARD:
      return {
        ...state,
        saved: {
          ...state.saved,
          ...action.payload,
        },
      };
    case VTT_FETCH_DASHBOARD_VALIDITY_LOADING:
    case VTT_FETCH_DASHBOARD_VALIDITY:
      return {
        ...state,
        dashboardValidity: {
          ...state.dashboardValidity,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default myDashboardsReducer;
