import React, {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {LogoutOutlined} from '@ant-design/icons';

import {externalSignOut} from "../../../../actions/authentication.action";

import ConfirmBox from "../../../dashboardCommon/ConfirmBoxModal";
import {Button} from "antd";

import localStore from "../../../../utils/localStorage";

const UserLogout = ({button: Component}) => {
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const onLogoutClick = () => {
        dispatch(externalSignOut(() => {
            localStore.removeLsSlim(localStore.keys.authMeta);
            history.push("/tenant/login");
        }))
    }

    return (
        <Fragment>
            {Component ?
                <Component onClick={() => setShowLogoutConfirm(true)}/>
                :
                <Button type="info" onClick={() => setShowLogoutConfirm(true)} icon={<LogoutOutlined/>}>Logout</Button>
            }
            <ConfirmBox
                topic="Logout Confirmation"
                message="Are you sure you want to log out?"
                confirmButtonColor="danger"
                confirmButtonText="Logout"
                show={showLogoutConfirm}
                onHide={() => setShowLogoutConfirm(false)}
                onConfirm={onLogoutClick}/>
        </Fragment>

    )
}


export default UserLogout;