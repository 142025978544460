import _get from "lodash/get";
import _uniqWith from "lodash/uniqWith";
import _isEqual from "lodash/isEqual";
import _remove from "lodash/remove";

import {calculationModes} from "../../../constant/jobReadyGeneric/constants";

export const getSubSectionDataByKey = (key, sectionData) => {
    const factorKey = Object.keys(sectionData).find(k => sectionData[k].key === key);
    return factorKey? sectionData[factorKey] : null;
}

// this can be used to get an object array for filters in data table
export const getUniqValuesForFilters = (dataset, valueKey, displayKey) => {
    let dataArr;

    if (typeof dataset === 'object') {
        dataArr = dataset.map(item => ({text: _get(item, displayKey), value: _get(item, valueKey)}));
    }else {
        return false;
    }

    return _remove(_uniqWith(dataArr, _isEqual), n => ![undefined, null].includes(n.value));
}

// this provides the correct sorting method for table columns in student tables.
export const getSortMethod = (factorType, sortingValuePosition) => {
    return (a, b) => {
        const valueA = _get(a, sortingValuePosition, undefined);
        const valueB = _get(b, sortingValuePosition, undefined);

        switch (factorType) {
            case calculationModes.condition:
                return valueA - valueB
            case calculationModes.category:
                return valueA && valueA.localeCompare(valueB)
        }
    }
}

// this is a generic response error format for reducers
export const resolveResponseErrorFormat = (error) => {
    return ({
            data: {},
            error: error.message
        })
}