import React from "react";
import AccParentProfile from "./AccParentProfile";
import { withRouter } from 'react-router-dom'
import './styles.css';


const ParentProfile = (props) => {
    const params = new URLSearchParams(props.location.search);
    if(params.get('profile') == 'acc'){
        return (
            <AccParentProfile location={props.location}/>
        );
    }else{
    }
};

export default withRouter(ParentProfile);