import {
    FETCH_DASHBOARD_2FA_CHECK_LOADING,
    FETCH_DASHBOARD_2FA_CHECK,
    FETCH_DASHBOARD_OTP_VALIDATE_LOADING,
    FETCH_DASHBOARD_OTP_VALIDATE,
} from "../../constant/actionTypes";
import service from "../../utils/service";

// fetch dashboard 2fa check loading
const fetching2faLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD_2FA_CHECK_LOADING,
        payload: {isLoading},
    });
};


// fetch dashboard 2fa check handler
const fetch2faStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD_2FA_CHECK,
        payload: {
            isLoading: false,
            error: response.error,
        },
    });
};

// fetch dashboard 2fa check
export const fetch2faCheck = (dashboardId) => dispatch => {
    dispatch(fetching2faLoading());

    const serviceMaker2fa = (resolve=false, reject=false) => {
        service.make({
            service:`dashboard/request_otp?dashboard_id=${dashboardId}`,
            authorized: true,
        }).then(response => {
            dispatch(fetch2faStatus({
                data: response.data
            }))
            resolve(response.data);
        }).catch(error => {
            console.log(error)
            dispatch(fetch2faStatus({
                error: error.message
            }))
            reject()
        })
    }

    return new Promise((resolve, reject) => {
        serviceMaker2fa(resolve, reject);
    })

};

// fetch dashboard otp validate loading
const fetchingOtpValidateLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD_OTP_VALIDATE_LOADING,
        payload: {isLoading},
    });
};

// fetch dashboard otp validate handler
const fetchOtpValidateStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD_OTP_VALIDATE,
        payload: {
            isLoading: false,
            error: response.error,
        },
    });
};

// fetch dashboard otp validate
export const fetchOtpValidate = (otp) => dispatch => {
    dispatch(fetchingOtpValidateLoading());

    const serviceMakerOtp = (resolve=false, reject=false) => {
        service.make({
            service:`dashboard/authorize_otp`,
            authorized: true,
            method: "POST",
            data: {otp}
        }).then(response => {
            dispatch(fetchOtpValidateStatus({
                data: response.data
            }))
            resolve(response.data);
        }).catch(error => {
            console.log(error)
            dispatch(fetchOtpValidateStatus({
                error: error.message
            }))
            reject()
        })
    }

    return new Promise((resolve, reject) => {
        serviceMakerOtp(resolve, reject);
    })

};
