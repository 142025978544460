import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import {
    makeVwDealershipEditModeDataResponse,
    makeVwDealershipSidebarOrderPrograms,
} from "../../../../../selectors/vwDealership/setting.selector";
import {
    fetchingUnitsSetOrder,
    setSelectedProgram
} from "../../../../../actions/vwDealership/settings.action";

import Sidebar from "./Sidebar";

const SidebarContainer = ({ setWarningVisible }) => {
    const dispatch = useDispatch();

    // Selectors
    const programs = useSelector(makeVwDealershipSidebarOrderPrograms);
    const editMode = useSelector(makeVwDealershipEditModeDataResponse);

    useEffect(() => {
        dispatch(fetchingUnitsSetOrder({ "key": "program" }));
    }, [])

    const handleProgramOnChange = (key) => {
        if (editMode) {
            return setWarningVisible(true);
        }
        dispatch(setSelectedProgram(key));
    }

    return (
        <Sidebar
            programs={programs}
            onChangeHandler={handleProgramOnChange}
        />
    )
}

SidebarContainer.propTypes = {
    setWarningVisible: PropTypes.func,
}

export default SidebarContainer;
