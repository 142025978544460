import React, {Fragment, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Button, Popconfirm, Space, Tag} from "antd";

import {makeUserListData} from "../../../../../selectors/jrDashboardManagement/userManagement/jrGenUser.selector";
import {
    deleteUser,
    fetchDashboardUsers
} from "../../../../../actions/jrDashboardManagement/userManagement/jrGenUser.action";
import {onSuccess} from "../../../../../utils/notificationHandler";

import UsersTable from "./UsersTable";
import ResetPasswordModal from "./partials/resetPassword/index";
import SetPermissionModal from "./partials/setPermissions/SetPermissionModal";

const TableContainer = () => {
    const [isResetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [isSetPermissionVisible, setSetPermissionVisible] = useState(false);
    const dispatch = useDispatch();

    // selectors
    const users = useSelector(makeUserListData);

    const showResetPasswordModal = (id) => {
        setResetPasswordVisible(true);
    };

    const handleResetPasswordOk = () => {
        setResetPasswordVisible(false);
    };

    const handleResetPasswordCancel = () => {
        setResetPasswordVisible(false);
    };

    const showSetPermissionModal = (id) => {
        setSetPermissionVisible(true);
    };

    const handleSetPermissionOk = () => {
        setSetPermissionVisible(false);
    };

    const handleSetPermissionCancel = () => {
        setSetPermissionVisible(false);
    };

    const onUserDeleteConfirm = (email) => {
        dispatch(deleteUser(email, () => {
            onSuccess("User deleted successfully!");
            dispatch(fetchDashboardUsers());
        }))
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Permissions',
            key: 'permissions',
            dataIndex: 'permissions',
            render: permissions => (
                <>
                    {permissions && permissions.map(permission => {
                        return (
                            <Tag color="#57409C">
                                {permission}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" onClick={() => showResetPasswordModal(record.id)}>Reset Password</Button>
                    <Button size="small" onClick={() => showSetPermissionModal(record.id)}>Set Permissions</Button>
                    <Popconfirm
                        title="Are you sure to delete this user?"
                        onConfirm={() => onUserDeleteConfirm({email: record.email})}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small" danger type="primary">Remove</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Fragment>
            <UsersTable
                users={users}
                columns={columns}
            />
            <ResetPasswordModal isVisible={isResetPasswordVisible} handleCancel={handleResetPasswordCancel} handleOk={handleResetPasswordOk}/>
            <SetPermissionModal isVisible={isSetPermissionVisible} handleCancel={handleSetPermissionCancel} handleOk={handleSetPermissionOk}/>
        </Fragment>
    )
}

export default TableContainer;