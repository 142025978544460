import {
    FETCH_JR_MY_DASHBOARDS_LOADING,
    FETCH_JR_MY_DASHBOARDS,
    SAVE_JR_DASHBOARD,
    SAVE_JR_DASHBOARD_LOADING,
    FETCH_DASHBOARD_VALIDITY,
    FETCH_DASHBOARD_VALIDITY_LOADING
} from "../../constant/actionTypes";

const jrMyDashboardsReducerDefaultState = {
    list: {
        isLoading: false,
        data: [],
        error: false,
    },
    saved: {
        isLoading: false,
        error: false
    },
    // used to store active status of the selected dashboard
    dashboardValidity: {
        isLoading: false,
        data: {active:true}
    }
};

const jrMyDashboardsReducer = (state = jrMyDashboardsReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_JR_MY_DASHBOARDS_LOADING:
        case FETCH_JR_MY_DASHBOARDS:
            return {
                ...state,
                list: {
                    ...state.list,
                    ...action.payload
                },
            };
        case SAVE_JR_DASHBOARD_LOADING:
        case SAVE_JR_DASHBOARD:
            return {
                ...state,
                saved: {
                    ...state.saved,
                    ...action.payload
                },
            };
        case FETCH_DASHBOARD_VALIDITY_LOADING:
        case FETCH_DASHBOARD_VALIDITY:
            return {
                ...state,
                dashboardValidity: {
                    ...state.dashboardValidity,
                    ...action.payload
                },
            };
        default:
            return state;
    }
};

export default jrMyDashboardsReducer;