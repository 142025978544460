import {
  VTT_ACADEMIC_CARDS_DATA_FETCH,
  VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING,
  VTT_ACADEMIC_STUDENTS_DATA_FETCH,
  VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING,
  VTT_ACADEMIC_OVERVIEW_DATA_FETCH,
  VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING,
  VTT_ACADEMIC_DISABILITIES_DATA_FETCH,
  VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING,
  VTT_ACADEMIC_REQUIREMENT_MET_DATA_FETCH,
  VTT_ACADEMIC_REQUIREMENT_MET_DATA_FETCH_LOADING,
  VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH,
  VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING,
  VTT_ACADEMIC_ATTENDANCE_DATA_FETCH,
  VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING,
} from '../../../../constant/actionTypes';
import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';
import { resolveResponseErrorFormat } from '../../../../components/vetTrack/analysis/utils/general';

/** Fetch academic cards data * */
// fetch academic cards data loading
const fetchAcademicCardsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic cards data handler
const fetchAcademicCardsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_CARDS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic cards data
export const fetchAcademicCards = data => dispatch => {
  dispatch(fetchAcademicCardsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskCard`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicCardsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(fetchAcademicCardsDataStatus(resolveResponseErrorFormat(error)));
    });
};

/** Fetch academic students data * */
// fetch academic students data loading
const fetchAcademicStudentsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic students data handler
const fetchAcademicStudentsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_STUDENTS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic students data
export const fetchAcademicStudents = data => dispatch => {
  dispatch(fetchAcademicStudentsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskStudents`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicStudentsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchAcademicStudentsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch academic overview data * */
// fetch academic overview data loading
const fetchAcademicOverviewLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic overview data handler
const fetchAcademicOverviewDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_OVERVIEW_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic overview data
export const fetchAcademicOverview = data => dispatch => {
  dispatch(fetchAcademicOverviewLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewCharts`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicOverviewDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchAcademicOverviewDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch academic overview disabilities data * */
// fetch academic overview disabilities data loading
const fetchAcademicOverviewDisabilitiesLoading = (
  isLoading = true,
) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic overview disabilities data handler
const fetchAcademicOverviewDisabilitiesDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_DISABILITIES_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic overview disabilities data
export const fetchAcademicDisabilitiesOverview = data => dispatch => {
  dispatch(fetchAcademicOverviewDisabilitiesLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewPopups`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicOverviewDisabilitiesDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchAcademicOverviewDisabilitiesDataStatus(
          resolveResponseErrorFormat(error),
        ),
      );
    });
};

/** Fetch academic overview unit progress data * */
// fetch academic overview unit progress data loading
const fetchAcademicOverviewUnitProgressDataLoading = (
  isLoading = true,
) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic overview unit progress data handler
const fetchAcademicOverviewUnitProgressDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic overview unit progress data
export const fetchAcademicOverviewUnitProgress = data => dispatch => {
  dispatch(fetchAcademicOverviewUnitProgressDataLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewPopups`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicOverviewUnitProgressDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchAcademicOverviewUnitProgressDataStatus(
          resolveResponseErrorFormat(error),
        ),
      );
    });
};

/** Fetch academic overview attendance data * */
// fetch academic overview attendance data loading
const fetchAcademicOverviewAttendanceDataLoading = (
  isLoading = true,
) => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch academic overview attendance data handler
const fetchAcademicOverviewAttendanceDataStatus = response => dispatch => {
  dispatch({
    type: VTT_ACADEMIC_ATTENDANCE_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch academic overview attendance data
export const fetchAcademicOverviewAttendance = data => dispatch => {
  dispatch(fetchAcademicOverviewAttendanceDataLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewPopups`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchAcademicOverviewAttendanceDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchAcademicOverviewAttendanceDataStatus(
          resolveResponseErrorFormat(error),
        ),
      );
    });
};
