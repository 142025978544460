import React, { memo, useEffect, useState } from 'react';
import { Col, Layout, Row, Tabs } from "antd";
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import StudentProfileCard from "../common/cards/StudentProfileCard";
import Calendar from "./calendar";
import CourseComparisonGrid from "./courseComparisonGrid";
import OutcomeGridContainer from "./outcomeGrid/OutcomeGridContainer";
import TopCard from "./topCard";
import SideCharts from "../common/cards/sideCharts";
import { setCourseStatus as setAdminCourseStatus } from '../../../actions/innovative_student/student.action';
import HeaderFilters from './filters/headerFilters';
import NoStudentSelectView from './components/noStudentSelectView';
import { setCourseStatus } from '../../../actions/innovative/course.action';
import NoStudentAvailable from './components/noStudentAvailable';
import { ACTING_ROLES as ROLES } from "../../../constant/innovative_student";

const { TabPane } = Tabs;

const StudentProfileView = ({
  student,
  studentId,
  studentsReport,
  studentsReportLoading,
  sideChartsConfigs,
  terms,
  termsLoading,
  termChange,
  students,
  studentsLoading,
  studentChange,
  selectedStudent,
  selectedTerm,
  selectedCourse,
  currentViewTab,
  actingRole,
  roles,
  entrollmentOptions,
  onEnrollmentChanged,
  selectedEnrollment,
  setSelectedTablePagination,
  switchFormValues,
  setSelectedStudent,
  isAdminPath,
}) => {
  const [currentTab, setCurrentTab] = useState("courses");

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentViewTab) {
      setCurrentTab(currentViewTab);
    }
  }, [currentViewTab])

  const onTabChange = e => {
    setCurrentTab(e)
  }

  const onFilterApply = data => {
    dispatch(setAdminCourseStatus(data));
    dispatch(setCourseStatus(data));
  }

  const renderContent = () => {
    if (
      !studentsLoading &&
      !students.length &&
      !studentsReportLoading &&
      ROLES.parent.includes(actingRole) &&
      !isAdminPath
    ) {
      return (<NoStudentAvailable />)
    }

    return (<>
      {selectedStudent && (
        <Row gutter={[12, 12]}>
          <Col md={24} xl={6}>
            <Row gutter={[16, 0]}>
              <Col span={24} >
                <Row gutter={[16, 0]}>
                  <Col span={24} >
                    <StudentProfileCard
                      src={student["student_avatar_url"]}
                      name={student["student_name"]}
                      id={student["student_sis_user_id"]}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col sm={24} md={24} xl={24}>
                    <div className="in-sectionCover">
                      <SideCharts
                        configs={sideChartsConfigs}
                        records={studentsReport}
                        recordsLoading={studentsReportLoading}
                        type="student"
                        termsLoading={termsLoading}
                      />
                    </div>
                  </Col>
                  <Col sm={24} md={24} xl={24}>
                    <div className="in-sectionCover assignmentCalenderCover">
                      <Calendar studentId={studentId} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={24} xl={18} style={{ display: "flex", flexFlow: "column" }}>
            <div className="in-sectionCover studentDetails">
              <Row gutter={[16, 16]}>
                <TopCard />
              </Row>
            </div>
            <div className="in-sectionCover courseComparison">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey={currentTab}
                    tabBarStyle={{ fontSize: 40 }}
                    activeKey={currentTab}
                    onChange={onTabChange}
                    className="customTabs"
                  >
                    <TabPane tab="Course Comparison" key="courses">
                      <CourseComparisonGrid
                        courseId={selectedCourse}
                        termId={selectedTerm}
                        studentId={studentId}
                        setSelectedTablePagination={setSelectedTablePagination}
                      />
                    </TabPane>
                    <TabPane tab="Outcomes" key="outcomes">
                      <OutcomeGridContainer termId={selectedTerm} studentId={studentId} />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>)
      }
      <NoStudentSelectView
        isAdminPath={isAdminPath}
        selectedStudent={selectedStudent}
      />
    </>)
  }

  return (
    <>
      <div className={"center-header"}>
        <HeaderFilters
          onFilterApply={onFilterApply}
          terms={terms}
          termsLoading={termsLoading}
          studentsReportLoading={studentsReportLoading}
          termChange={termChange}
          selectedTerm={selectedTerm}
          enrollmentOptions={entrollmentOptions}
          onEnrollmentChanged={onEnrollmentChanged}
          selectedEnrollment={selectedEnrollment}
          switchFormValues={switchFormValues}
          setSelectedStudent={studentChange}
          roles={roles}
          actingRole={actingRole}
          students={students}
          studentsLoading={studentsLoading}
          studentChange={studentChange}
          selectedStudent={selectedStudent}
          student={student}
        />
      </div>
      <div className='student-profile-content'>
        <Layout.Content className="site-layout-background singleColumnLayout">
          {renderContent()}
        </Layout.Content>
      </div>
    </>
  );
};

StudentProfileView.propTypes = {
  getPieChartOptions: PropTypes.object,
  student: PropTypes.object,
  studentId: PropTypes.string,
  sideChartsConfigs: PropTypes.array,
  terms: PropTypes.array,
  termsLoading: PropTypes.bool,
  termChange: PropTypes.func,
  selectedTerm: PropTypes.array,
  currentViewTab: PropTypes.string,
  onChangeCourseState: PropTypes.func,
  switchFormValues: PropTypes.object,
  setSwitchFormValues: PropTypes.func,
  setSelectedTablePagination: PropTypes.func,
}

export default memo(StudentProfileView);
