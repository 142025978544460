import React from 'react'
import Error403 from '../../../../../dashboardCommon/errorPages/Error403'

const SamlError403 = (props) => {
  const { history } = props;
  const goBack = () => {
    history.go(-1);
  } 
  return (
    <Error403 onClick={goBack} text="Go Back"/>
  )
}

export default SamlError403