import React, { Fragment } from "react";

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

const Loader = () => {
  return (
    <Fragment>
      <div style={{ display: "flex", padding: "15px 0", justifyContent: "center" }}>
        <img src={preloaderGif} height="40px"></img>
      </div>
    </Fragment>
  );
};

export default Loader;
