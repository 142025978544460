import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDashboardFiltersSelectedData,
  getComplianceCardsData,
  getComplianceCardsLoading,
} from '../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchComplianceCards } from '../../../../../../../../actions/vetTrack/analysis/riskAnalysis/compliance.action';

import SubSectionTopCards from '../../common/topCards/subSectionTopCards';
import InnerTabSection from './innerTabSection/InnerTabSection';

import { subSections } from '../../../../../../../../constant/vetTrack/analysis';

const ComplianceTabContainer = () => {
  const [activeTab, setActiveTab] = useState(null);
  const dispatch = useDispatch();

  // selectors
  const cardData = useSelector(getComplianceCardsData);
  const cardDataLoading = useSelector(getComplianceCardsLoading);
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);

  // effects
  useEffect(() => {
    setActiveTab('students');
  }, []);

  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters)) {
      const { compliance } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: compliance.id,
      };
      dispatch(fetchComplianceCards(data));
    }
  }, [selectedFilters]);

  const onTabChange = key => {
    setActiveTab(key);
  };

  return (
    <div>
      <SubSectionTopCards
        cardData={cardData?.cards}
        cardLoading={cardDataLoading}
        chartData={cardData?.charts}
        subSection={subSections.compliance.key}
      />
      <br />
      <InnerTabSection tabConfig={{ activeTab, setActiveTab: onTabChange }} />
    </div>
  );
};

export default ComplianceTabContainer;
