import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    fetchUserPermission,
    saveSettings,
    setSettings,
    resetEditMode
} from "../../../actions/jobReadyGeneric/settings/commonSettings.action";
import {
    getUserPermissionsLoading,
    getIsEdited,
    getDiscardDraftLoading
} from "../../../selectors/jobReadyGeneric/settings/commonSettings.selector";
import {getSettingBySections} from "../../../selectors/jobReadyGeneric/settings/base.selector";

import SettingSidebar from "./partials/Sidebar";
import JRInnerContent from "../common/layout/InnerContent";
import DataSourcesContainer from "./dataSources";
import RiskCalculationsContainer from "./riskCalculations";
import RiskIndicatorsContainer from "./riskIndicators";
import EditedWarning from "./partials/EditedWarning";
import Loader from "../common/layout/Loader";

import {initiateValidations} from "./partials/helperValidators";
import {settingsSections, subSections} from "../../../constant/jobReadyGeneric/constants";

import "../../../assets/scss/jobReadyGeneric/settings.scss";

const JRGenericSettingsContainer = () => {
    const [activeSection, setActiveSection] = useState(null);
    const [showWarning, setShowWarning] = useState({state:false, key:null});
    const dispatch = useDispatch();

    // selectors
    const permissionLoading = useSelector(getUserPermissionsLoading);
    const discardDraftLoading = useSelector(getDiscardDraftLoading);
    const isEdited = useSelector(getIsEdited);
    const currentDataSet = useSelector(getSettingBySections("data", isEdited.section, isEdited.subSection))
    const currentInitialDataSet = useSelector(getSettingBySections("data2", isEdited.section, isEdited.subSection))

    const sections = [
        {
            key: "dataSources",
            name: "Data Sources",
            component: DataSourcesContainer,
            editedDataSaveMode: "all"
        },
        {
            key: "riskCalculations",
            name: "Risk Calculations",
            component: RiskCalculationsContainer,
            editedDataSaveMode: "draft"
        },
        {
            key: "riskIndicators",
            name: "Risk Indicators",
            component: RiskIndicatorsContainer,
            editedDataSaveMode: "draft"
        },
    ];

    useEffect(() => {
        setActiveSection(sections[0]);
        dispatch(fetchUserPermission());
    }, [])

    const setSection = (key) => {
        setActiveSection(sections.find(section => section.key === key))
    }

    const onChangeSection = ({key}) => {
        if(isEdited.state){
            setShowWarning({state:true, key})
        }else{
            setSection(key);
        }
    }

    const onSave = () => {
        // call for save settings api
        if(!initiateValidations(isEdited.section, currentDataSet)) {
            const subSectionKey = Object.keys(subSections).find(k => subSections[k].key === isEdited.subSection)
            let subSectionId = isEdited.subSection && subSections[subSectionKey].id;
            if(isEdited.section === settingsSections.dataSources) subSectionId = 0
            dispatch(saveSettings(currentDataSet, activeSection.editedDataSaveMode, isEdited.section, isEdited.subSection, subSectionId, false, () => {
                setShowWarning({state: false, key: null});
                dispatch(resetEditMode());
                setSection(showWarning.key);
            }));
        }
    }

    const onDiscard = () => {
        // reset dataset from the initial fetched data
        if(currentInitialDataSet){
            dispatch(setSettings(currentInitialDataSet, "data", isEdited.section, isEdited.subSection));
        }
        setShowWarning({state:false, key:null});
        dispatch(resetEditMode());
        setSection(showWarning.key);
    }

    return (
        <Fragment>
            <Loader isLoading={permissionLoading || discardDraftLoading}/>
            <SettingSidebar
                selectedSection={activeSection}
                sections={sections}
                onChange={onChangeSection}
            />
            <JRInnerContent>
                {activeSection && <activeSection.component/>}
            </JRInnerContent>
            <EditedWarning
                visible={showWarning.state}
                onCancel={() => setShowWarning({state:false, key:null})}
                onSave={onSave}
                onDiscard={onDiscard}
            />
        </Fragment>
    )
}

export default JRGenericSettingsContainer;