import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    makeActingRoleResponseData,
    makeInnovativeMasterSettingByPath,
    makeInnovativeStudentSelectedCourseSettingsData,
} from "../../../../selectors/innovative_student/commonData.selector";
import SubmissionsCellRenderer from "./cellRenderers/SubmissionsCellRenderer";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import ChartDonutCellRenderer from "../../common/table/ChartDonutCellRenderer";
import { resolvePropertiesFromCohortData } from "../../../../utils/innovative/resolveSettings";
import CourseComparisonGridView from "./CourseComparisonGridView";
import {
    makeInnovativeStudentMainReportCourseComparisonResponseData,
    makeInnovativeStudentMainReportLoading
} from "../../../../selectors/innovative_student/student.selector";
import StatCellRenderer from "../../common/table/statCell/StatCellRenderer";
import SolidGaugeCellRenderer from "../../common/table/SolidGaugeCellRenderer";
import { Button } from "antd";
import {
    makeInnovativeStudentCohortDataResponse,
    makeInnovativeStudentGaugeDataResponse
} from "../../../../selectors/innovative_student/student.selector";
import ChartWithLoader from "./cellRenderers/ChartWithLoader";
import { resolveCohortVisibility } from "../../../../utils/innovative_student/resolveSettings";
import { checkIfVariableHasValueAndLength, isAdminDashboardView } from '../../../../utils/general';

const ADMIN_PATH = "/insights/admin/student/profile";

const CourseComparisonGrid = ({ termId, courseId, studentId, setSelectedTablePagination }) => {
    // selectors
    const [actingRoleState, setActingRoleState] = useState();
    const settings = useSelector(makeInnovativeStudentSelectedCourseSettingsData);
    const courseComparison = useSelector(makeInnovativeStudentMainReportCourseComparisonResponseData);
    const courseComparisonLoading = useSelector(makeInnovativeStudentMainReportLoading);
    const cohortData = useSelector(makeInnovativeStudentCohortDataResponse);
    const gaugeData = useSelector(makeInnovativeStudentGaugeDataResponse);
    const actingRole = useSelector(makeActingRoleResponseData);
    const cohortVisibility = useSelector(makeInnovativeMasterSettingByPath("features.cohortVisibility"));

    const parsedUrl = new URL(window.location.href);
    const actAs = parsedUrl.searchParams.get("actAs");
    const path = parsedUrl.pathname;

    const getRole = ({ actAs, actingRoleState, actingRole }) => {
        if (checkIfVariableHasValueAndLength(actAs)) {
            return actAs.toLowerCase();
        }
        if (checkIfVariableHasValueAndLength(actingRoleState)) {
            return actingRoleState;
        }
        if (checkIfVariableHasValueAndLength(actingRole)) {
            return actingRole;
        }
        return "";
    };

    useEffect(() => {
        setActingRoleState(getRole({ actAs, actingRoleState, actingRole }));
    }, [actAs, actingRoleState, actingRole]);

    const radioOptions = [
        {value: 'all', label: 'All'},
        {value: 'archived', label: 'Archived'},
        {value: 'nonArchived', label: 'Non Archived'},
        {value: 'extraCurricular', label: 'Extra Curricular'},
    ];

    const getCourseChartData = (course, data, dataFormatter=null) => {
        // find the chart data of the particular course and format if needed
        // since the cohort and gauge chart data fetched separately, we need this
        // interim function to slice data
        const courseData = data.find(item => item && item.courseId === parseInt(course))
        if(courseData){
            return {data:dataFormatter? {courseId, data: dataFormatter(courseData.data)} : courseData, isLoading:false}
        }else{
            return {data: null, isLoading: true}
        }
    }

    const resolveCohortVisible = useCallback(() => {
        return resolveCohortVisibility(actingRoleState, cohortVisibility, {compareVal: "role", returnVal: "access"})
    }, [actingRoleState, cohortVisibility]);

    const isCohortVisible = resolveCohortVisible();

    const getColumnDefinition = () => {
        return [
            {
                key: "course_name",
                title: 'Course',
                dataIndex: 'course_name',
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["course_id"]}
                    avatar={row["course_image"]}
                />,
            }, {
                key: "student_grade",
                title: "Student Grade",
                dataIndex: "student_grade",
                render: (value, row) => <StatCellRenderer
                    label="Grade"
                    value={value}
                    styles={{color: row["student_grade_color"]}}
                />
            }, {
                key: "grade_cohort",
                title: "Cohort",
                dataIndex: "grade_cohort",
                width: 220,
                className: "cohortChart",
                hidden: !isCohortVisible,
                render: (data, row) => <ChartWithLoader
                    resolver={resolvePropertiesFromCohortData}
                    component={ChartDonutCellRenderer}
                    {...getCourseChartData(row.course_id,cohortData)}
                    customOptions={{legend:{itemWidth: 100}, chart:{height: 120, marginTop:20}}}
                />
            }, {
                key: "engagement",
                title: 'Engagement',
                dataIndex: 'engagement',
                width: "200",
                className: "enagagementChart",
                hidden:!isCohortVisible,
                render: (data, row) => <ChartWithLoader
                    component={SolidGaugeCellRenderer}
                    {...getCourseChartData(row.course_id,gaugeData, data => {
                        return {score:data[0], cohort:data[1], color:data[2]}
                    } )}
                />
            }, {
                key: "student_submission",
                title: 'Submissions',
                dataIndex: 'student_submission',
                render: (...params) => <SubmissionsCellRenderer
                    params={params}
                    settings={settings.colorScheme.submission}
                />
            }, {
                key: "actions",
                title: "",
                fixed: 'right',
                render: (value, row) => <Button htmlType="button">
                    <Link to={
                        isAdminDashboardView({actAs, path, ADMIN_PATH}) ? `/insights/admin/student/assignments/${studentId}/${row["course_id"]}/${termId}` :
                            `/insights/student/assignments/${studentId}/${row["course_id"]}/${termId}`}
                    >
                        View
                    </Link>
                </Button>
            },
        ].filter(item => !item.hidden);
    };


    return <CourseComparisonGridView
        radioOptions={radioOptions}
        isLoading={courseComparisonLoading}
        dataSource={courseComparison}
        columns={getColumnDefinition()}
        setSelectedTablePagination={setSelectedTablePagination}
    />
};

CourseComparisonGrid.propTypes = {
    termId: PropTypes.string,
    courseId: PropTypes.string,
    studentId: PropTypes.string,
    setSelectedTablePagination: PropTypes.func,
}

export default CourseComparisonGrid;