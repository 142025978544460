import React, { memo } from "react";
import PropTypes from "prop-types";
import { Col, Row, Space } from "antd";

import Sidebar from "../../../common/sidebarLayout";
import CollapseSection from "./partials/CollapseSection";

const SettingSidebar = ({ programs, onChangeHandler }) => {

    return (
        <Sidebar width={400}>
            <Space style={{ width: "100%" }} direction="vertical">
                <Row gutter={[16, 24]}>
                    <Col>
                        <h4>Dealerships - Unit Structure</h4>
                        <CollapseSection
                            programs={programs}
                            onChangeHandler={onChangeHandler}
                        />
                    </Col>

                </Row>

            </Space>
        </Sidebar>
    )
}

SettingSidebar.propTypes = {
    programs: PropTypes.array,
    onChangeHandler: PropTypes.func,
}

export default memo(SettingSidebar);