import React, {memo} from "react";
import {Card, Typography} from "antd";
import PropTypes from "prop-types";

import NAMessage from "../../../../../../../NAMessage";

const ValueCard = ({title, value, color, enabled, className,  ...rest}) => {

    return (
        <Card {...rest} className={`value-card ${className}`}>
            <Typography.Paragraph className="cardHeading">{title}</Typography.Paragraph>
            {/* if backend breaks, this will display N/A */}
            {enabled && value?
                <Typography.Title style={{color}} className="value-card-value">{`${value}%`}</Typography.Title>
                :
                <NAMessage contentStyles={{marginTop: "1.2em", textAlign: "left", padding:0}}/>
            }
        </Card>
    )
}

ValueCard.defaultProps = {
    enabled: true,
}

ValueCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string
}

export default memo(ValueCard);