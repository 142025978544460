import {
    VTT_ACADEMIC_CARDS_DATA_FETCH,
    VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING,
    VTT_ACADEMIC_STUDENTS_DATA_FETCH,
    VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING,
    VTT_ACADEMIC_OVERVIEW_DATA_FETCH,
    VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING,
    VTT_ACADEMIC_DISABILITIES_DATA_FETCH,
    VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING,
    VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH,
    VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING,
    VTT_ACADEMIC_ATTENDANCE_DATA_FETCH,
    VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING,
  } from '../../../../constant/actionTypes';
  
  const defaultState = {
    cards: {
      isLoading: false,
      data: {},
    },
    students: {
      isLoading: false,
      data: {},
    },
    overview: {
      isLoading: false,
      data: {
        lineChartData: null,
        disabilities: null,
        requirementMet: null,
        targetNotCompleted: null,
        currentUnitProgress: null,
        expectedUnitProgress: null,
        attendance: null,
      },
    },
    disabilities: {
      isLoading: false,
      data: {},
    },
    unitProgress: {
      isLoading: false,
      data: {},
    },
    attendance: {
      isLoading: false,
      data: {},
    },
  };
  
  const academicReducer = (state = defaultState, action) => {
    switch (action.type) {
      case VTT_ACADEMIC_CARDS_DATA_FETCH:
      case VTT_ACADEMIC_CARDS_DATA_FETCH_LOADING:
        return {
          ...state,
          cards: {
            ...state.cards,
            ...action.payload,
          },
        };
      case VTT_ACADEMIC_STUDENTS_DATA_FETCH:
      case VTT_ACADEMIC_STUDENTS_DATA_FETCH_LOADING:
        return {
          ...state,
          students: {
            ...state.students,
            ...action.payload,
          },
        };
      case VTT_ACADEMIC_OVERVIEW_DATA_FETCH:
      case VTT_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING:
        return {
          ...state,
          overview: {
            ...state.overview,
            ...action.payload,
          },
        };
      case VTT_ACADEMIC_DISABILITIES_DATA_FETCH:
      case VTT_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING:
        return {
          ...state,
          disabilities: {
            ...state.disabilities,
            ...action.payload,
          },
        };
      case VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH:
      case VTT_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING:
        return {
          ...state,
          unitProgress: {
            ...state.unitProgress,
            ...action.payload,
          },
        };
      case VTT_ACADEMIC_ATTENDANCE_DATA_FETCH:
      case VTT_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING:
        return {
          ...state,
          attendance: {
            ...state.attendance,
            ...action.payload,
          },
        };
      default:
        return state;
    }
  };
  
  export default academicReducer;
  