import { Button, Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const statuses = ["all", "created", "claimed", "available", "completed", "deleted"];

const CourseStatusFilter = ({
  onFilterApply,
  selectedState,
}) => {

  const [selectedStatuses, setSelectedStatuses] = useState([...statuses]);
  const [open, setOpen] = useState(false)
  const [placeholder, setPlaceholder] = useState("All");

  useEffect(() => {
    if (selectedState[0] === "all") {
      setPlaceholder("All");
    } else {
      mapPlaceholder();
    }
  }, [JSON.stringify(selectedState)]);

  const initializeData = () => {
    if (selectedState.includes("all")) {
      setSelectedStatuses(statuses);
    }
    else {
      setSelectedStatuses(selectedState);
    }
  }

  const handleOnClick = () => {
    if (open === false) {
      initializeData();
    }
    setOpen(!open);
    if (open) {
      setTimeout(() => { initializeData(); }, 200)
    }
  }

  const handleOnChange = (e) => {
    const { value, checked } = e.target;
    if (value === "all") {
      if (checked) {
        setSelectedStatuses(statuses);
      }
      if (!checked) {
        setSelectedStatuses([]);
      }
    }
    if (value !== "all") {
      if (checked) {
        setSelectedStatuses(state => [...state, value]);
      }
      if (!checked) {
        const newStatuses = selectedStatuses.filter(item => {
          return (item !== value && item !== 'all');
        });
        setSelectedStatuses(newStatuses);
      }
    }
  }

  const firstWordCapitalizer = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const mapPlaceholder = () => {
    const capitalizedWords = selectedStatuses.map(word => firstWordCapitalizer(word));
    const stringForPlaceholder = capitalizedWords.join(', ');

    setPlaceholder(stringForPlaceholder);
    onFilterApply(selectedStatuses);
  }

  const handleOnApply = () => {
    handleOnClick();
    if (selectedStatuses.includes("all")) {
      setPlaceholder("All");
      onFilterApply(["all"]);
      return;
    }

    mapPlaceholder();
  }

  const getDropDown = menu => (
    <div className="al-custom-dropdown">
      {statuses.map(status => {
        return (
          <>
            <Checkbox
              key={status}
              onChange={handleOnChange}
              checked={selectedStatuses.includes(status)}
              value={status}
            >
              {firstWordCapitalizer(status)}
            </Checkbox>
            <br />
          </>
        )
      })}
      <Button
        style={{ marginTop: 10, width: "100%" }}
        type='primary' onClick={handleOnApply}
        disabled={!(selectedStatuses && selectedStatuses.length)}
      >
        Apply
      </Button>
    </div>
  )

  return (
    <div id="innovative-course-statue-select">
      <Select
        style={{ width: '100%' }}
        value={placeholder}
        open={open}
        onDropdownVisibleChange={handleOnClick}
        dropdownRender={getDropDown}
        options={[]}
        getPopupContainer={
          () => document.getElementById('innovative-course-statue-select')
        }
      />
    </div>
  )
}

export default CourseStatusFilter; 