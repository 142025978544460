import React from 'react';
const preloaderGif =
  `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

const AccLoader = ({styles}) => {
  const newStyles = styles ?? {}
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img src={preloaderGif}
        height="100px"
        alt="preloader"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 'auto',
          ...newStyles
        }}
      />
    </div>
  );
};

export default AccLoader;