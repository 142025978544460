import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import StudentsTableHeader from '../../../../common/table/StudentsTableHeader';
import { withStudentTableSearch } from '../../../../common/table/SearchInTable';
import { withTablePaginator } from '../../../../common/table/withPaginator';

const StudentTable = ({ students, columns, searchConfig, pagination }) => {
  return (
    <div>
      <StudentsTableHeader
        title="Students"
        searchEnabled
        dataSource={students}
        {...searchConfig}
      />
      <Table columns={columns} dataSource={students} pagination={pagination} />
    </div>
  );
};

StudentTable.propTypes = {
  students: PropTypes.array,
  pagination: PropTypes.object,
  columns: PropTypes.array,
  searchConfig: PropTypes.object,
};

export default withStudentTableSearch(withTablePaginator(StudentTable));
