import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { drawerTypes } from '../../../../../../../constant/vetTrack/analysis';

const ReadyTecSection = ({
  enrolmentStatuses,
  invoiceStatuses,
  localStatuses,
  openDrawer,
  onDelete,
  isAdmin,
}) => {
  const renderListItems = (item, type) => (
    <List.Item
      extra={
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => onDelete({ id: item.id }, type)}
          okText="Yes"
          cancelText="No"
        >
          {isAdmin && <Button size="small" danger icon={<DeleteOutlined />} />}
        </Popconfirm>
      }
    >
      {item.name}
    </List.Item>
  );

  return (
    <div>
      <div>
        <Typography.Paragraph strong>
          Valid Enrollment Status
        </Typography.Paragraph>
        <List
          size="small"
          bordered
          dataSource={enrolmentStatuses}
          renderItem={item => renderListItems(item, drawerTypes.enrolment)}
        />
        {isAdmin && (
          <div style={{ marginTop: 5, textAlign: 'end' }}>
            <Button
              type="primary"
              onClick={() => openDrawer(drawerTypes.enrolment)}
            >
              Add
            </Button>
          </div>
        )}
      </div>
      <div>
        <Typography.Paragraph strong>Valid Local Status</Typography.Paragraph>
        <List
          size="small"
          bordered
          dataSource={localStatuses}
          renderItem={item => renderListItems(item, drawerTypes.local)}
        />
        {isAdmin && (
          <div style={{ marginTop: 5, textAlign: 'end' }}>
            <Button
              type="primary"
              onClick={() => openDrawer(drawerTypes.local)}
            >
              Add
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

ReadyTecSection.propTypes = {
  enrolmentStatuses: PropTypes.array,
  invoiceStatuses: PropTypes.array,
  localStatuses: PropTypes.array,
  openDrawer: PropTypes.func,
  onDelete: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default ReadyTecSection;
