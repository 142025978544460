import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty as _isEmpty,
  get as _get,
} from 'lodash';

import {
  getFinancialOverviewData,
  getFinancialOverviewLoading,
  getDashboardFiltersSelectedData,
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchFinancialOverview } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/financial.action';

import Overview from './Overview';

import { subSections } from '../../../../../../../../../../../constant/vetTrack/analysis';

const OverviewTab = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const overviewDataLoading = useSelector(getFinancialOverviewLoading);
  const overviewData = useSelector(getFinancialOverviewData);

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading) {
      const data = {
        filters: selectedFilters,
        subSectionId: subSections.financial.id,
      };
      dispatch(fetchFinancialOverview(data));
    }
  }, [selectedFilters]);

  return (
    <>
      <Overview
        lineChartData={_get(overviewData, 'lineChartData', {})}
        pieChartData={_get(overviewData, 'completedPaymentPercentage', {})}
        speedometerData={_get(overviewData, 'completedPaymentAmount', {})}
        dataLoading={overviewDataLoading}
      />
    </>
  );
};

export default OverviewTab;
