import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import MarkingSchemeSettingsContainer from "./markingScheme";
import ColorSchemeSettingsContainer from "./colorScheme";
import ConditionalColorsSettingsContainer from "./conditionalColors";
import ConfirmationPopup from "../common/settingsComponents/confirmationPopup";
import SettingsView from "./SettingsView";
import {
  fetchSettings,
  saveSettings,
} from "../../../actions/innovative/settings.action";
import {
  makeInnovativeSettingsLoading,
  makeInnovativeSettingsResponseData,
  makeInnovativeSettingsSaveLoading,
} from "../../../selectors/innovative/setting.selector";
import { 
  getIsApplicationAdmin,
  makeInnovativeCoursesResponseData 
} from "../../../selectors/innovative/course.selector";
import { setBreadcrumb, setSidebar } from "../../../actions/innovative/layout.action";
import { resetDefault } from "../../../actions/innovative/settings.action";
import session from "../../../utils/session";
import { makeInnovativeSidebar } from "../../../selectors/innovative/layout.selector";
import { getAccessToken } from "../../../utils/general";
import {get as _get} from "lodash";

const CourseSettingsContainer = () => {
  let { courseId } = useParams();
  const dispatch = useDispatch();

  // selectors
  const settings = useSelector(makeInnovativeSettingsResponseData);
  const settingsFetching = useSelector(makeInnovativeSettingsLoading);
  const settingsSaving = useSelector(makeInnovativeSettingsSaveLoading);
  const courseList = useSelector(makeInnovativeCoursesResponseData);
  const sidebarData = useSelector(makeInnovativeSidebar);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const dashboardManageMetaData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManageMetaData, "is_admin", false);
  const impressionMetaData = session.get(_get(session, "keys.impressionMeta", ""));

  // state
  const [activeSection, setActiveSection] = useState(0);
  const [modalName, setModalName] = useState("");
  const [requestPayload, setRequestPayload] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const sections = [
    {
      key: "markingScheme",
      name: "Marking Scheme",
      component: MarkingSchemeSettingsContainer,
    },
    {
      key: "colorScheme",
      name: "Color Scheme",
      component: ColorSchemeSettingsContainer,
    },
    {
      key: "conditionalColors",
      name: "Conditional Colors",
      component: ConditionalColorsSettingsContainer,
    },
  ];

  // set sidebar data
  const setSidebarData = (value) => {
    dispatch(setSidebar({ collapsed: value }))
  }

  const subjectName = courseList.find(
    (course) => parseInt(course.course_id, 10) === parseInt(courseId, 10)
  );

  const onSectionSelect = (params) => {
    const index = sections.findIndex((item) => item.key === params.key);
    setActiveSection(index);
  };

  const getRequestParams = () => {
    return {
      subject_id: courseId,
      school_id: session.get([session.keys.innovativeMeta, "school_id"]),
    };
  };

  const onSubmit = (values) => {
    const section = Object.keys(values)[0];
    let saveObj = {
      ...settings,
    };

    if (section) {
      const subSections = Object.keys(values[section]);

      // overriding current settings with applied changes
      subSections.forEach((subSection) => {
        saveObj[section][subSection] = values[section][subSection];
      });
    }

    saveObj.params = getRequestParams();
    dispatch(saveSettings(saveObj));
  };

  const onResetDefault = (tmpPayload) => {
    let saveObj = { ...tmpPayload };
    let settingType = "";
    switch (tmpPayload.whichSettingToGetDefault) {
      case "markingScheme":
        settingType = "Marking Scheme";
        break;
      case "colorScheme":
        settingType = "Color Scheme";
        break;
      case "conditionalColor":
        settingType = "Conditional Colors";
        break;
      default:
        return;
    }
    const subject = getRequestParams();
    setRequestPayload({ ...saveObj, ...subject });
    setIsModalVisible(true);
    setModalName(settingType);
  };

  const onConfirm = () => {
    dispatch(resetDefault(requestPayload));
  };

  useEffect(() => {
    const payload = {
      requestParams: getRequestParams(),
    };

    const breadCrumb = [
      {
        key: "home",
        section: "Home",
        // href: "/insights/teacher/courses",
      },
      {
        key: "subject",
        section: applicationAdmin ? "Courses" : subjectName.course_name,
        href: applicationAdmin ? "/insights/admin/courses": "/insights/teacher/courses",
        searchQuery: `?token=${impressionMetaData.token}&t=${impressionMetaData.t}&access=${getAccessToken()}&actAs=${isApplicationAdminData.role}`,
      },
      {
        key: "settings",
        section: "settings",
      },
    ];

    dispatch(fetchSettings(payload));
    dispatch(setBreadcrumb(breadCrumb));
  }, []);

  return (
    <>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onConfirm={onConfirm}
        text1={"You are resetting"}
        text2={"to its default values."}
        textBold={modalName}
        okText={"Confirm"}
        cancelText={"Cancel"}
      />
      <SettingsView
        activeSection={activeSection}
        sections={sections}
        onSectionSelect={onSectionSelect}
        onSubmit={onSubmit}
        isLoading={settingsFetching || settingsSaving}
        onResetDefault={onResetDefault}
        sidebarData={sidebarData}
        setSidebar={setSidebarData}
      />
    </>
  );
};

export default CourseSettingsContainer;
