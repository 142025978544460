import React from 'react';
import { useD3 } from '../../../../utils/customHooks';
import * as d3 from 'd3';

import { scatterPlotLines as plotLines } from './utils/ChartData';

const fullWidth = 850;
const fullHeight = 500;
const margin = { top: 50, right: 20, bottom: 30, left: 55 };
const width = fullWidth - margin.left - margin.right;
const height = fullHeight - margin.top - margin.bottom;

const ScatterChart = ({ data, axisData }) => {
  const { max_x_axis_percentage, max_y_axis_percentage } = axisData;

  const svgRef = useD3((svg) => {
    const xScale = d3
      .scaleLinear()
      .range([margin.left, width])
      .domain([0, 100]);

    const yScale = d3
      .scaleLinear()
      .range([height, margin.top])
      .domain([0, 100]);

    const line = d3
      .line()
      .x((d) => xScale(d.engagement))
      .y((d) => yScale(d.performance));

    // plotLines
    plotLines({
      max_y_axis_percentage,
      max_x_axis_percentage,
    }).forEach(lineObj => {
      svg
        .append('path')
        .datum(lineObj.coordinates)
        .attr('fill', 'none')
        .attr('stroke', lineObj.color)
        .attr('class', 'plot-line')
        .attr('stroke-width', lineObj.width)
        .attr('d', line)
    });

    // x-Axis
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .attr('class', 'axis-line')
      .call(
        d3.axisBottom(xScale)
          .tickFormat(value => `${value} %`)
      );

    svg
      .append('text')
      .attr(
        'transform',
        `translate(${width - margin.left}, ${fullHeight - margin.bottom})`,
      )
      .text('ENGAGEMENT')
      .attr('y', 0)
      .attr('x', -(width - (margin.right + margin.left)) / 2)
      .attr('class', 'axis-label');

    // y-Axis
    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'axis-line')
      .call(
        d3.axisLeft(yScale).tickFormat(value => `${value} %`)
      );

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', -width / 4)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .attr('class', 'axis-label')
      .text('PERFORMANCE');

    data.forEach(subject => {
      const { id, value, name } = subject;
      svg
        .selectAll(`.subject-${id}`)
        .data(value)
        .enter()
        .append('circle')
        .attr('class', `subject-${id}`)
        .attr('r', 5)
        .attr('cx', (d) => xScale(d.engagement))
        .attr('cy', (d) => yScale(d.performance))
        .style('fill', '#2F80ED');

      svg.append("foreignObject")
        .attr("x", xScale(value[0].engagement) - 50)
        .attr("y", yScale(value[0].performance) + 10)
        .attr("class", 'name-tag')
        .html(`<span>${name}</span>`);
    });
  },
    JSON.stringify(data),
    true,
  );

  return (
    <div className='chart-section'>
      <svg
        ref={svgRef}
        width={fullWidth}
        height={fullHeight}
        style={{ display: 'block', position: 'relative' }}
        className='chart-container'
      />
    </div>
  );
};

export default ScatterChart;