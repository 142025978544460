import { numberFormat } from "highcharts";

export default {
  numberFormat: (x,mode) => {
    if(x || x == 0){
      if(typeof mode !== 'undefined'){
        if(x == 0)
          return '0.00';
        return x.toFixed(2);
      }else{
        if(x == 0)
        return '0.00';
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } 
  },

  setMetricSuffix: (num, format="K", digits= 1) => {
    if(format == 'AF')
      return numberFormat(num);
    
    // let si = [{ value: 1, symbol: "" }];
    let si = [];
    if(format == 'K')
      si.push({ value: 1E3, symbol: "k" });
    if(format == 'M'){
      si.push({ value: 1E6, symbol: "M" });
      digits = 3;
    }
      // { value: 1E9, symbol: "G" },
      // { value: 1E12, symbol: "T" },
      // { value: 1E15, symbol: "P" },
      // { value: 1E18, symbol: "E" }

    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
}