import React, { useState, Fragment } from "react";
import Header from './../components/tass-web/common/header-component/header';
import Sidebar from './../components/tass-web/common/sidebar-component/sidebar';
// import RightSidebar from './common/right-sidebar';
import Footer from "./common/footer";
// import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../assets/css/tass-web/tass.css";
import Loader from "./common/loader";

const TassWebApp = ({ children }) => {
  return (
    <div id='tassWrapper' className="tass-web">
        <Loader />
        <div className="page-wrapper default">
            <div className="page-body-wrapper default">
              {localStorage.getItem('authUser') ?
                <Fragment>
                    <Header />
                    <Sidebar sidebar-layout="default-sidebar"/> 
                </Fragment>: null }
                <div className="page-body">
                    {children}
                </div>
                <Footer />
            </div>
        </div>
        <ToastContainer />
    </div>
  );
};

export default TassWebApp;
