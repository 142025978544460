export default {
    studentProfile: async function (filters,token) {
      try {
        console.info(
          `Fetch Teacher Profile >> ${process.env.REACT_APP_APIURL}parentprofile/student/${filters.logged_in}/${filters.studentId}`
        );
        return fetch(process.env.REACT_APP_APIURL+`parentprofile/student/${filters.logged_in}/${filters.studentId}`,{
            credentials: "include",
            method: 'GET',
            headers:{  'Authorization': token,'Content-Type': 'application/json' }
        });
      } catch (e) {
        console.log(e);
      }
    }
}