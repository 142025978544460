import React,{Fragment} from "react";
import {List, Typography, Col, Row, Select, Button} from "antd";

const canvasLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/canvas-logo-only.svg`;
const mysqlLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/mysql.svg`;
const csvLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/csv.svg`;

const {Option} = Select;
const {Title, Paragraph} = Typography;

const DataSources = () => {
    const sourceList = [
        {
            name: "Canvas Data Source 1",
            icon: canvasLogo,
            type: "sourceOnly"
        },
        {
            name: "Canvas Data Source 2",
            icon: canvasLogo,
            type: "sourceOnly"
        },
        {
            name: "My Sql",
            icon: mysqlLogo,
            type: "sync"
        },
        {
            name: "CSV 1",
            icon: csvLogo,
            type: "upload"
        }
    ]

    const sourceItemTypeWidgetRender = (type) => {
        switch (type) {
            case "sync":
                return (
                    <Fragment>
                        <Select size="large" defaultValue="source1" style={{ width: '50%' }}>
                            <Option value="source1">Source 1</Option>
                            <Option value="source2">Source 2</Option>
                        </Select>
                        &nbsp;
                        <Button size="large">Sync</Button>
                    </Fragment>
                )
            case "sourceOnly":
                return (
                    <Fragment>
                        <Select size="large" defaultValue="source1" style={{ width: '100%' }}>
                            <Option value="source1">Source 1</Option>
                            <Option value="source2">Source 2</Option>
                        </Select>
                    </Fragment>
                )
            case "upload":
                return (
                    <Fragment>
                        <Button size="large">Update</Button>
                    </Fragment>
                )
        }
    }

    const renderSourceItem = (source) => {
        return (
            <div className="source-item">
                <List.Item style={{padding:0}}>
                    <Row justify="start" gutter={16}>
                        <Col sm={24} md={12} lg={17} xl={16} xxl={12}>
                            <img
                                width={50}
                                height={50}
                                alt="source item logo"
                                src={source.icon || null}
                            />
                            <Paragraph className="title" strong>{source.name}</Paragraph>
                        </Col>
                        <Col md={12} lg={7} xl={8} xxl={12}>
                            <div>
                                {sourceItemTypeWidgetRender(source.type)}
                            </div>
                        </Col>
                    </Row>
                </List.Item>
            </div>
        )

    }

    const renderSourceList = () => {
        return (
            <List
                itemLayout="vertical"
                pagination={{
                    pageSize: 5,
                }}
                dataSource={sourceList}
                renderItem={source => (
                    renderSourceItem(source)
                )}
            />
        )
    }

    return (
        <div className="data-source-config">
            {renderSourceList()}
        </div>
    )

}

export default DataSources;