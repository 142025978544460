import React, { memo } from 'react';
import { Table } from "antd";
import Loader from '../../common/layout/Loader';
import { withTablePaginator } from '../../common/table/hoc/withTablePaginator';

const ModuleProgressGridView = ({
  isLoading,
  dataSource,
  columns,
  pagination,
  setSelectedTablePagination
}) => {
  setSelectedTablePagination(pagination?.current);
  
  return (
    <div className="tableCoverCard moduleProgressTable">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
        />
      }
    </div>
  );
};

ModuleProgressGridView.propTypes = {
};

export default memo(withTablePaginator(ModuleProgressGridView));
