
function GET(url){

    return new Promise((resolve, reject)=>{
        fetch(url, {
            method: 'get',
            credentials: 'include',
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
            // fetch complete
            .then((response)=>{
                // handle http errors
                if(!response.ok){
                    if ([401, 403].includes(response.status)){
                        // redirect to login
                        window.location.href = `/setup/login`;
                    }
                    return response.text().then((errorMsg) => {
                        reject(errorMsg);
                    });
                }
                // http 200
                response.json()
                    .then((jsonBody)=>resolve(jsonBody));
            })
            // handle network errors
            .catch((error)=>{
                reject(error.message)
            })
    })
}

function POST(url, params, mode = false){

    return new Promise((resolve, reject)=>{
        fetch(url, {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify(params),
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
            // fetch complete
            .then((response)=>{
                // handle http errors
                if(!response.ok){
                    if ([401, 403].includes(response.status)){
                        // redirect to login
                        if(!mode) {
                            window.location.href = `/setup/login`;
                        }
                    }
                    return response.text().then((errorMsg) => {
                        reject(errorMsg);
                    });
                }
                // http 200
                response.json()
                    .then((jsonBody)=>resolve(jsonBody));
            })
            // handle network errors
            .catch((error)=>{
                reject(error.message)
            })
    })
}


exports.GET = GET
exports.POST = POST