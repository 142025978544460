import React from "react";
import PropTypes from "prop-types";
import {Button, Card, Typography} from "antd";

import SettingHeader from "../../common/SectionHeader";
import SectionGroup from "./partials/SectionGroup";
import Loader from "../../common/layout/Loader";
import {withSyncingBanner} from "../partials/hoc/syncingBanner/withSyncingBanner";

import {menu} from "../../../../constant/jobReadyGeneric/menu";

const DataSourcesView = ({isLoading, onSave, isAdmin}) => {
    return (
        <div>
            <Loader isLoading={isLoading}/>
            <SettingHeader title="Activation" items={[{name: 'Risk Analysis', href: menu.riskAnalysis}, "Settings", "Active Data Sources"]}/>
            <Card>
                <Typography.Paragraph strong>Active Data Sources</Typography.Paragraph>
                <SectionGroup/>
            </Card>
            {isAdmin &&
            <div style={{float:"right", marginTop:10}}>
                <Button type="primary" onClick={onSave}>Apply all</Button>
            </div>
            }
        </div>
    )
}

DataSourcesView.propTypes = {
    isLoading: PropTypes.bool,
    onSave: PropTypes.func,
    isAdmin: PropTypes.bool,
}

export default withSyncingBanner(DataSourcesView);