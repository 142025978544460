import {
    SEARCH_USER_BY_USERNAME_LOADING,
    SEARCH_USER_BY_USERNAME,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";
import { onError, onSuccess } from "../../utils/notificationHandler";
import { fetchDashboards } from "./dashboard.action";

// fetch user data by search loading
const searchingUser = () => dispatch => {
    dispatch({
        type: SEARCH_USER_BY_USERNAME_LOADING,
    });
};

// fetch user data by search data handler
const searchUserResponse = response => dispatch => {
    const { data , error = '' } = response;
    dispatch({
        type: SEARCH_USER_BY_USERNAME,
        payload: {
            isLoading: false,
            error,
            data: data?.userArr?.length && [...data.userArr],
        },
    });
};

// reset user data by search data handler
export const resetUserSearchData = () => dispatch => {
    dispatch({
        type: SEARCH_USER_BY_USERNAME,
        payload: {
            data: [],
        },
    });
};

// fetch user data by search
export const searchUserByIdOrName = payload => dispatch => {
    dispatch(searchingUser());

    service.make({
        service: `tentacle/getCanvasUsers`,
        method: "POST",
        data: {...payload},
        authorized: true
    }).then(response => {
        dispatch(searchUserResponse(response));
    }).catch(error => {
        dispatch(searchUserResponse({
            error: error.message
        }));
    });
};

export const setAppTestingUsers = payload => dispatch => {
    service.make({
        service: `tenant_admin/dashboards/user/share`,
        method: "POST",
        data: {...payload},
        authorized: true
    }).then(response => {
        dispatch(fetchDashboards());
        onSuccess("Action Successful!");
        dispatch(fetchDashboards());
    }).catch(error => {
        onError("Action Failed!");
    });
};