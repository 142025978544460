import { Result, Button } from 'antd';
import PropTypes from "prop-types";

import React from 'react';

const Error403 = ({onClick, text, showBackButton = true}) => {
    return <div style={{ paddingTop: '18vh' }}>
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={showBackButton ? <Button onClick={onClick} type="primary">{text}</Button> : <></>}
        />,
    </div>;
};

Error403.defaultProps = {
    text: "Go Back",
}

Error403.propTypes = {
    text: PropTypes.string
}

export default Error403;
