import React from "react";
import PropTypes from "prop-types";
import {Card, Tabs} from "antd";

import SettingHeader from "../../common/SectionHeader";
import SettingButtonGroup from "../partials/buttonGroup/ButtonGroup";
import RITabSection from "./partials/TabContent";
import Loader from "../../common/layout/Loader";

import {settingsSections, subSections} from "../../../../constant/jobReadyGeneric/constants";
import {menu} from "../../../../constant/jobReadyGeneric/menu";

const {TabPane} = Tabs;

const RiskIndicatorsView = ({isLoading, tabConfig}) => {

    return (
        <div>
            <Loader isLoading={isLoading}/>
            <SettingHeader title="Risk Indicators" items={[{name: 'Risk Analysis', href: menu.riskAnalysis}, "Settings", "Risk Indicators"]}/>
            <Tabs activeKey={tabConfig.activeTab} onChange={tabConfig.setActiveTab}>
                    <TabPane tab="Academic" key={subSections.academic.key}>
                        <Card>
                            <RITabSection subSection={subSections.academic.key}/>
                            <SettingButtonGroup section={settingsSections.riskIndicators} subSection={subSections.academic.key}/>
                        </Card>
                    </TabPane>

                    <TabPane tab="Financial" key={subSections.financial.key}>
                        <Card>
                            <RITabSection subSection={subSections.financial.key}/>
                            <SettingButtonGroup section={settingsSections.riskIndicators} subSection={subSections.financial.key}/>
                        </Card>
                    </TabPane>

                    <TabPane tab="Compliance" key={subSections.compliance.key}>
                        <Card>
                            <RITabSection subSection={subSections.compliance.key}/>
                            <SettingButtonGroup section={settingsSections.riskIndicators} subSection={subSections.compliance.key}/>
                        </Card>
                    </TabPane>

                    {/* Not in phase 01 */}
                    {/*<TabPane tab="Well-being" key={subSections.wellBeing.key}>*/}
                    {/*    <Card>*/}
                    {/*        <RITabSection subSection={subSections.wellBeing.key}/>*/}
                    {/*        <SettingButtonGroup section={settingsSections.riskIndicators} subSection={subSections.wellBeing.key}/>*/}
                    {/*    </Card>*/}
                    {/*</TabPane>*/}
                </Tabs>
        </div>
    )
}

RiskIndicatorsView.propTypes = {
    isLoading: PropTypes.bool,
    tabConfig: PropTypes.object
}

export default RiskIndicatorsView;