import React, { Fragment, useRef, useEffect } from "react";
import { Alert, Form, Input, Spin, Tooltip, Space, Row } from "antd";
import { CopyOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";


import { makeKeygenLoading } from "../../../../../selectors/consumer.selector";

const StepKeyConfig = ({ url, hasRegenerate, onKeyRegenerate, hideSecret = true }) => {
    const consumerKeyRef = useRef(null);
    const secretKeyRef = useRef(null);
    const appCenterUrlRef = useRef(null);
    const canvasCustomFieldRef = useRef(null);
    const keygenLoading = useSelector(makeKeygenLoading);


    const copyToClipboard = (reference) => {
        reference.current.select();
        document.execCommand("copy");
    }

    const copyTextAreaToClipboard = (elementId) => {
        let textarea = document.getElementById(elementId);
        textarea.select();
        document.execCommand("copy");
    }

    const renderCopyIndicator = (reference, title, isTextArea, elementId) => {
        return (
            <Tooltip title={`copy ${title}`} className="copyIconClass">
                <CopyOutlined style={isTextArea ? { fontSize: '16px' } : {}} onClick={() => isTextArea ? copyTextAreaToClipboard(elementId) : copyToClipboard(reference)} />
            </Tooltip>
        );
    };

    const renderLabel = (field, title) => {
        if (!hasRegenerate) {
            return title;
        }

        return (
            <div>
                <div>{title}</div>
                {field !== 'secretKey' &&
                    <div onClick={() => onKeyRegenerate(field)}>
                        <ReloadOutlined />
                        &nbsp;Re-Generate
                    </div>
                }
            </div>
        );
    }

    const getAlertDescription = () => {
        return (
            <span>
                Make sure to copy and save these keys in a secured place.
                The Secret Key is only generated once.
                If you lose this key please contact support
                (<a href='mailto: support@octopusbi.com'>support@octopusbi.com</a>).
            </span>
        )
    }

    return (
        <Fragment>
            <Spin spinning={keygenLoading} indicator={<LoadingOutlined />}>
                <Form.Item className="customReadOnlyInput" name={"consumerKey"} label={renderLabel("consumerKey", "Consumer Key")}>
                    <Input
                        ref={consumerKeyRef}
                        readOnly
                        size={"large"}
                        suffix={renderCopyIndicator(consumerKeyRef, "Consumer Key")} />
                </Form.Item>
                {!hideSecret &&
                    <Form.Item className="customReadOnlyInput" name={"secretKey"} label={renderLabel("secretKey", "Secret Key")}>
                        <Input
                            ref={secretKeyRef}
                            readOnly={"readonly"}
                            size={"large"}
                            suffix={!hideSecret && renderCopyIndicator(secretKeyRef, "Secret Key")} />
                    </Form.Item>
                }
                <Form.Item className="customReadOnlyInput" name={"eduAppCenterUrl"} label={"Eduappcenter URL"}>
                    <Input
                        ref={appCenterUrlRef}
                        readOnly
                        size={"large"}
                        defaultValue={"https://www.eduappcenter.com/configurations/jogobojei7ga4gac.xml"}
                        suffix={renderCopyIndicator(appCenterUrlRef, "Eduappcenter URL")} />
                </Form.Item>
                <Form.Item name={"canvasCustomField"} label={"Canvas Custom Field"}>
                    <div className="canvasCustomField" >
                        <Input.TextArea
                            id="canvasCustomFieldId"
                            ref={canvasCustomFieldRef}
                            readOnly
                            autoSize={true}
                            defaultValue={"canvas_user_id=$Canvas.user.id \ncanvas_account_id=$Canvas.account.id \ncanvas_root_id=$Canvas.rootAccount.id"}
                        />
                        {renderCopyIndicator(canvasCustomFieldRef, "Canvas Custom Field", true, "canvasCustomFieldId")}
                    </div>
                </Form.Item>
                <Alert
                    message=""
                    description={getAlertDescription()}
                    type="info"
                    showIcon
                />
                {url ? <a href={url} target="_blank">Go to Application Page</a> : null}
            </Spin>

        </Fragment>
    );
}

StepKeyConfig.propTypes = {
    url: PropTypes.string,
    hasRegenerate: PropTypes.bool,
    onKeyRegenerate: PropTypes.func,
};

export default StepKeyConfig;