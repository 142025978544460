import {
  CLEAR_TERMS_DATA,
  CLEAR_COURSE,
  FETCH_COURSE,
  FETCH_COURSE_LOADING,
  FETCH_COURSE_REPORT,
  FETCH_COURSE_REPORT_LOADING,
  FETCH_COURSES,
  FETCH_COURSES_LOADING,
  FETCH_TERMS,
  FETCH_TERMS_LOADING,
  SET_TERM,
  FETCH_IS_ADMIN_LEVEL_USER_LOADING,
  FETCH_IS_ADMIN_LEVEL_USER,
  SET_SELECTED_ACCOUNT,
  CLEAR_COURSE_REPORT,
  SET_COURSE_FILTER,
  SET_SWITCH_FORM_VALUES,
  FETCH_COURSE_PROGRESS,
  FETCH_STUDENT_CLUSTER_DATA,
  FETCH_STUDENT_CLUSTER_DATA_LOADING,
  FETCH_CHAT_HISTORY_DATA,
  FETCH_CHAT_HISTORY_DATA_LOADING,
  SEND_CHAT_MESSAGE_LOADING,
  UPDATE_SEND_CHAT,
  RESET_ADMIN_LEVEL_DATA,
  CLEAR_COURSES,
  UPDATE_APPLICATION_LEVEL_ADMIN_USER,
  FETCH_CHAT_HISTORY_ERROR_HANDEL,
  FETCH_MODULE_ITEM_DATA_LOADING,
  FETCH_MODULE_ITEM_DATA,
  RESET_MODULE_PROGRESS_DATA,
  RESET_STUDENT_CLASSIFICATION_DATA,
  RESET_CHAT_COUNT_DOWN_TIMER,
  UPDATE_FILTER_RADIO_VALUE,
  UPDATE_SECTION_FILTER_VALUES,
  RESET_SECTION_FILTER_VALUES,
  UPDATE_CLUSTER_MESSAGE_VISIBILITY,
  FETCH_CLUSTER_MASSAGES,
  RESET_CLUSTER_MASSAGES,
  UPDATE_FETCH_COURSE_TIME_FILTERS,
  RESET_FETCH_COURSE_TIME_FILTERS,
  FETCH_NEW_COURSE_DATA,
} from "../../constant/actionTypes";
import _get from "lodash/get";

const DEFAULT_SELECTION = "Default";

const courseReducerDefaultState = {
  selected: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {},
      },
    }
  },
  list: {
    isLoading: false,
    error: false,
    response: {
      data: {
        data: [],
      },
    }
  },
  mainReport: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {
          student_details: [],
          outcome_details: [],
          outcome_list: [],
          main_report_cards: [],
          risk_indicator: [],
          clusterStatus: {},
        },
      },
    }
  },
  adminStatus: {
    isLoading: false,
    response: {
      isAdmin: ""
    },
    selected: ""
  },
  applicationAdmin: {
    is_admin: "",
    role: ""
  },
  courseFilters: ["all"],
  terms: {
    selected: false,
    isLoading: false,
    error: false,
    response: {
      data: [],
    }
  },
  switchFormValues: {
    active: true,
    completed: false,
  },
  moduleProgressDataArray: [],
  moduleProgressItemsObj: {},
  sectionFilter: {
    filterArray: [{
      "text": "Default",
      "state": "default",
      "id": "all",
    }],
    selectedValueArray: [],
    selectedIdArray: [],
  },
  clusterData: {
    isLoading: false,
    error: {},
    response: {
      data: [],
      assignmentsData: []
    }
  },
  clusterMessage: {
    isVisible: false,
    fetchMessages: true,
  },
  messageData: {
    isLoading: false,
    error: {},
    response: {
      data: {
        retryAfter: 0,
        historyMessages: []
      }
    }
  },
  chatMessageSending: {
    isLoading: false,
  },
  selectedRadioFilterValue: "",
  dateFilter:{},
  fetchNewCourseData: false,
};

const getSectionFilterIdArray = selectedSectionsArray => {
  if (selectedSectionsArray.map(section => section.text).includes(DEFAULT_SELECTION)) {
    return [];
  }

  return selectedSectionsArray.map(section => section.id);
};

const courseReducer = (state = courseReducerDefaultState, action) => {
  switch (action.type) {
    case FETCH_NEW_COURSE_DATA:
      return {
        ...state,
        fetchNewCourseData: action.payload
      }
    case UPDATE_FETCH_COURSE_TIME_FILTERS:
      return {
        ...state,
        dateFilter: { 
          ...state.dateFilter,
          ...action.payload,
        }
      }
    case RESET_FETCH_COURSE_TIME_FILTERS:
      return {
        ...state,
        dateFilter: { ...courseReducerDefaultState.dateFilter }
      }
    case UPDATE_FILTER_RADIO_VALUE:
      return {
        ...state,
        selectedRadioFilterValue: action.payload.value,
      }
    case RESET_ADMIN_LEVEL_DATA:
      return {
        ...state,
        adminStatus: { ...courseReducerDefaultState.adminStatus }
      }
    case FETCH_IS_ADMIN_LEVEL_USER_LOADING:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          isLoading: action.payload.isLoading
        }
      }
    case FETCH_IS_ADMIN_LEVEL_USER:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          ...action.payload,
          selected: action.payload.accounts?.[0]._id
        }
      }
    case CLEAR_TERMS_DATA:
      return {
        ...state,
        terms: { ...courseReducerDefaultState.terms }
      };
    case FETCH_TERMS:
      return {
        ...state,
        terms: { ...state.terms, ...action.payload }
      };
    case FETCH_TERMS_LOADING:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: action.payload.isLoading
        }
      };
    case SET_TERM:
      return {
        ...state,
        terms: {
          ...state.terms,
          selected: action.payload
        }
      };
    case SET_COURSE_FILTER:
      return {
        ...state,
        courseFilters: [...action.payload]
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        adminStatus: {
          ...state.adminStatus,
          selected: action.payload
        }
      };
    case FETCH_COURSE:
      return {
        ...state,
        selected: { ...action.payload }
      };
    case CLEAR_COURSE:
      const chatArray = _get(state, 'messageData.response.data.historyMessages', []);
      const historyMessages = chatArray.length ?
        [
          ...chatArray,
          { ...action.payload }]
        :
        [{ ...action.payload }];

      return {
        ...state,
        selected: { ...courseReducerDefaultState.selected },
        messageData: {
          ...state.messageData,
          response: {
            data: {
              retryAfter: _get(courseReducerDefaultState, 'messageData.response.data.retryAfter', 0),
              historyMessages: historyMessages
            }
          }
        },
      };
    case FETCH_COURSE_LOADING:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: action.payload.isLoading,
        },
        messageData: {
          ...courseReducerDefaultState.messageData,
        },
      };
    case FETCH_COURSES:
      return {
        ...state,
        list: { ...action.payload },
        fetchNewCourseData: false,
      };
    case FETCH_COURSES_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: action.payload.isLoading
        }
      };
    case FETCH_COURSE_REPORT:
      return {
        ...state,
        mainReport: action.payload
      };
    case CLEAR_COURSES:
      return {
        ...state,
        list: courseReducerDefaultState.list
      }
    case CLEAR_COURSE_REPORT:
      return {
        ...state,
        mainReport: courseReducerDefaultState.mainReport,
      }
    case FETCH_COURSE_REPORT_LOADING:
      return {
        ...state,
        mainReport: {
          ...courseReducerDefaultState.mainReport,
          isLoading: action.payload.isLoading
        }
      };
    case SET_SWITCH_FORM_VALUES:
      return {
        ...state,
        switchFormValues: action.payload
      };
    case RESET_MODULE_PROGRESS_DATA:
      return {
        ...state,
        moduleProgressDataArray: courseReducerDefaultState.moduleProgressDataArray,
        moduleProgressItemsObj: courseReducerDefaultState.moduleProgressItemsObj
      }
    case FETCH_COURSE_PROGRESS:
      return {
        ...state,
        moduleProgressDataArray: [...state.moduleProgressDataArray, { ...action.payload.data }]
      };
    case FETCH_MODULE_ITEM_DATA_LOADING:
      return {
        ...state,
        moduleProgressItemsObj: { ...state.moduleProgressItemsObj, ...action.payload }
      };
    case FETCH_MODULE_ITEM_DATA:
      return {
        ...state,
        moduleProgressItemsObj: { ...state.moduleProgressItemsObj, ...action.payload }
      };
    case FETCH_STUDENT_CLUSTER_DATA:
      const { response } = action.payload;
      const clusterData = _get(response, 'data.clusterData', []);
      const filterArray = _get(response, 'data.filters.sections', []);
      const assignmentsData = _get(response, 'data.assignments', []);
      const clusterChart = _get(response, 'data.clusterChart', []);

      const selectedSections = [
        ...courseReducerDefaultState.sectionFilter.filterArray,
        ...filterArray,
      ];

      const getStateChange = () => {
        const currentSelections = state.sectionFilter.selectedValueArray
        if (state.sectionFilter.selectedValueArray.length) {
          return currentSelections;
        }
        return selectedSections;
      };

      const sectionArray = getStateChange();
      return {
        ...state,
        clusterData: {
          ...action.payload,
          response: {
            data: clusterData,
            assignmentsData,
            axisInfo: clusterChart,
          },
        },
        sectionFilter: {
          filterArray: selectedSections,
          selectedValueArray: sectionArray,
          selectedIdArray: getSectionFilterIdArray(sectionArray),
        }
      };
    case RESET_STUDENT_CLASSIFICATION_DATA:
      return {
        ...state,
        messageData: {
          ...courseReducerDefaultState.messageData,
        },
        clusterData: {
          ...courseReducerDefaultState.clusterData,
        }
      };
    case FETCH_STUDENT_CLUSTER_DATA_LOADING:
      return {
        ...state,
        clusterData: {
          ...courseReducerDefaultState.clusterData,
          isLoading: action.payload.isLoading
        }
      };
    case FETCH_CHAT_HISTORY_DATA:
      const { error, isLoading, response: { data } } = action.payload;

      const errorValidation = () => {
        const error = _get(data, 'error', false);
        const currentHistoryMessages = _get(state, 'messageData.response.data.historyMessages', []);

        const responseMessageData = [{
          ..._get(data, 'historyMessages[0]', {}),
          error
        }];

        if (!currentHistoryMessages.length) {
          return responseMessageData;
        }

        if (error) {
          return currentHistoryMessages.map(msgObj => {
            if (msgObj.message_id === data.message_id) {
              return {
                ...msgObj,
                error: true,
                loading: false,
              };
            }
            return {
              ...msgObj,
              loading: false,
            };
          });
        }

        return [...currentHistoryMessages, ...responseMessageData].map(msgObj => {
          if (msgObj.message_id === data.message_id) {
            return {
              ...msgObj,
              error: false,
              loading: false,
            };
          }
          return {
            ...msgObj,
            loading: false,
          };
        });
      };

      return {
        ...state,
        messageData: {
          error,
          isLoading,
          response: {
            data: {
              retryAfter: _get(state, 'messageData.response.data.retryAfter', 0),
              courseId: data.courseId,
              historyMessages: errorValidation(),
            }
          }
        }
      };
    case FETCH_CHAT_HISTORY_ERROR_HANDEL:
      const retryAfter = _get(action.payload, 'response.data.retryAfter', 0);

      const retryLastQuestion = () => {
        const message_id = _get(action.payload, 'response.data.message_id', "");
        const stateHistoryMsgArray = _get(state, 'messageData.response.data.historyMessages', []);
        return [...stateHistoryMsgArray].map(question => {
          if (question.message_id === message_id) {
            return {
              ...question,
              error: true,
              loading: false,
            };
          }
          return question;
        });
      };

      return {
        ...state,
        messageData: {
          ...action.payload,
          response: {
            data: {
              courseId: _get(action.payload, 'response.data.courseId', null),
              retryAfter,
              historyMessages: retryAfter ?
                retryLastQuestion()
                :
                [
                  ..._get(state, 'messageData.response.data.historyMessages', []),
                  ..._get(action.payload, 'response.data.historyMessages', [])
                ]
            }
          },
        }
      };
    case FETCH_CHAT_HISTORY_DATA_LOADING:
      return {
        ...state,
        messageData: {
          ...courseReducerDefaultState.messageData,
          isLoading: action.payload.isLoading
        }
      };
    case RESET_CHAT_COUNT_DOWN_TIMER:
      return {
        ...state,
        messageData: {
          ...state.messageData,
          response: {
            data: {
              courseId: _get(action.payload, 'courseId', 0),
              retryAfter: _get(courseReducerDefaultState, 'messageData.response.data.retryAfter', 0),
              historyMessages: [
                ..._get(state, 'messageData.response.data.historyMessages', []),
              ]
            }
          }
        }
      };
    case SEND_CHAT_MESSAGE_LOADING:
      const isRetryMassageLoading = _get(action.payload, 'isMassageLoading', false);
      const messageId = _get(action.payload, 'message_id', "");
      const messages = _get(state.messageData, 'response.data.historyMessages', []);
      const loading = _get(action.payload, 'isLoading', false);

      if (isRetryMassageLoading) {
        const statusUpdatedHistoryArray = messages.map(message => {
          if (message.message_id === messageId) {
            return {
              ...message,
              loading: true
            };
          }

          return message;
        })

        return {
          ...state,
          chatMessageSending: {
            isLoading: loading,
          },
          messageData: {
            ...state.messageData,
            response: {
              data: {
                ...state.messageData.data,
                courseId:  _get(action.payload, 'courseId', null),
                historyMessages: statusUpdatedHistoryArray,
              }
            }
          },
        };
      };

      return {
        ...state,
        chatMessageSending: {
          isLoading: loading,
        }
      };
    case UPDATE_SEND_CHAT:
      const newMessageObj = _get(action.payload, 'data', {});

      return {
        ...state,
        messageData: {
          ...state.messageData,
          response: {
            data: {
              ...state.messageData.data,
              courseId: _get(action.payload, 'courseId', null),
              retryAfter: _get(state, 'messageData.response.data.retryAfter', 0),
              historyMessages: [
                ..._get(state, 'messageData.response.data.historyMessages', []),
                { ...newMessageObj }
              ]
            }
          }
        }
      };
    case UPDATE_APPLICATION_LEVEL_ADMIN_USER:
      return {
        ...state,
        applicationAdmin: {
          ...state.applicationAdmin,
          ...action.payload
        }
      };
    case UPDATE_SECTION_FILTER_VALUES:
      return {
        ...state,
        sectionFilter: {
          ...state.sectionFilter,
          selectedValueArray: action.payload.selectedSectionArray,
          selectedIdArray: getSectionFilterIdArray(action.payload.selectedSectionArray),
        }
      };
    case RESET_SECTION_FILTER_VALUES:
      // on selecting a new course
      return {
        ...state,
        sectionFilter: {
          ...courseReducerDefaultState.sectionFilter,
        },
        clusterMessage: {
          ...courseReducerDefaultState.clusterMessage,
        },
      };
    case UPDATE_CLUSTER_MESSAGE_VISIBILITY: {
      return {
        ...state,
        clusterMessage: {
          ...state.clusterMessage,
          isVisible: action.payload.isVisible,
        },
      };
    }
    case FETCH_CLUSTER_MASSAGES: {
      return {
        ...state,
        clusterMessage: {
          ...state.clusterMessage,
          fetchMessages: action.payload.fetchMessages,
        },
      };
    }
    case RESET_CLUSTER_MASSAGES: {
      return {
        ...state,
        clusterMessage: {
          ...courseReducerDefaultState.clusterMessage,
        },
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
