import _get from "lodash/get";

// return ra academic data
export const getDashboardAcademicData = state => _get(state, 'jrGeneric.riskAnalysis.academic');

export const getAcademicCardsLoading = state => _get(getDashboardAcademicData(state), 'cards.isLoading');
export const getAcademicCardsData = state => _get(getDashboardAcademicData(state), 'cards.data');

export const getAcademicStudentsLoading = state => _get(getDashboardAcademicData(state), 'students.isLoading');
export const getAcademicStudentsData = state => _get(getDashboardAcademicData(state), 'students.data.students',[]);
export const getAcademicFactorsData = state => _get(getDashboardAcademicData(state), 'students.data.enabledFactors');

export const getAcademicOverviewLoading = state => _get(getDashboardAcademicData(state), 'overview.isLoading');
export const getAcademicOverviewData = state => _get(getDashboardAcademicData(state), 'overview.data');

export const getAcademicOverviewDisabilitiesResponse = state => _get(getDashboardAcademicData(state), 'disabilities');
export const getAcademicOverviewRequirementMetResponse = state => _get(getDashboardAcademicData(state), 'requirementMet');
export const getAcademicOverviewTargetNotCompletedResponse = state => _get(getDashboardAcademicData(state), 'targetNotCompleted');
export const getAcademicOverviewUnitProgressResponse = state => _get(getDashboardAcademicData(state), 'unitProgress');
export const getAcademicOverviewAttendanceResponse = state => _get(getDashboardAcademicData(state), 'attendance');