import { VTT_RTO_SIGN_UP_DATA_SAVING } from '../../../constant/actionTypes';
import service from '../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';

const signUpDataSavingStatus = payload => dispatch => {
  dispatch({
    type: VTT_RTO_SIGN_UP_DATA_SAVING,
    payload,
  });
};

export const signUp = (data, callback) => dispatch => {
  signUpDataSavingStatus({
    isDataSaving: true,
  });

  service
    .make({
      service: `rto/vettrak/on-boarding/sign-up`,
      method: 'POST',
      data,
      returnError: true,
    })
    .then((response) => {
      const { statusCode,  message } = response;

      if (statusCode === 201) {
        dispatch(
          signUpDataSavingStatus({
            isDataSaving: false,
            lastSave: Date.now(),
          })
        );
        callback(false);
        return;
      }

      dispatch(
        signUpDataSavingStatus({
          isDataSaving: false,
        }),
      );
      callback(message);
    })
    .catch(error => {
      dispatch(
        signUpDataSavingStatus({
          isDataSaving: false,
        }),
      );
      callback(error.message);
    });
};