import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import _isEmpty from 'lodash/isEmpty';

import {
  getDashboardFiltersSelectedData,
  getOverviewChartsData,
  getOverviewChartsLoading,
} from '../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchOverviewCharts } from '../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/overview.action';

import TopCard from '../../../../common/topCards/subSectionTopCards/topCardPie/TopCardPie';
import Loader from '../../../../../../../common/layout/Loader';

const TopCards = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const chartsData = useSelector(getOverviewChartsData);
  const chartsDataLoading = useSelector(getOverviewChartsLoading);

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters)) {
      const data = {
        filters: selectedFilters,
        subSectionId: 0,
      };
      dispatch(fetchOverviewCharts(data));
    }
  }, [selectedFilters]);

  return (
    <Loader isLoading={chartsDataLoading}>
      <Row gutter={[16, 16]}>
        <Col sm={12} md={12} lg={12} xl={8}>
          <TopCard
            name="Academic Risk"
            data={chartsData && chartsData.academicRisk}
          />
        </Col>
        <Col sm={12} md={12} lg={12} xl={8}>
          <TopCard
            name="Financial Risk"
            data={chartsData && chartsData.financialRisk}
          />
        </Col>
        <Col sm={12} md={12} lg={12} xl={8}>
          <TopCard
            name="Compliance Risk"
            data={chartsData && chartsData.complianceRisk}
          />
        </Col>
      </Row>
    </Loader>
  );
};

export default TopCards;
