import React, {memo} from "react";
import {Select} from "antd";

import Loader from "../layout/Loader";
import PropTypes from "prop-types";

const { Option } = Select;

const Filter = ({name, dataList, isLoading, reportLoading, onChange, selected, filterConfig}) => {
    return (
        <div>
            {name && name.length && <h5>{name}</h5>}
            <Loader isLoading={isLoading}/>
            {!isLoading &&
            <Select value={selected} style={{width: '100%'}} onChange={onChange} disabled={!!reportLoading}>
                {dataList && Array.isArray(dataList) && dataList.map(item => (
                    <Option key={item[filterConfig.key]} value={item[filterConfig.value]}>{item[filterConfig.label]}</Option>
                ))}
            </Select>
            }
        </div>
    )
}

Filter.propTypes = {
    name: PropTypes.string,
    dataList: PropTypes.array,
    isLoading: PropTypes.bool,
    reportLoading: PropTypes.bool,
    onChange: PropTypes.func,
    selected: PropTypes.string,
    filterConfig: PropTypes.object
}

export default memo(Filter);