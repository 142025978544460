import React from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Select, Checkbox, Row } from 'antd';

const { Option } = Select;
const MAX_TAG_COUNT = 1;

const SectionSelect = props => {
  const {
    section,
    width,
    size,
    onClickSelect,
    onSelectionChanged,
    disabled,
    subSection,
    showArrow,
    showSearch,
    maxTagCount,
    label,
    placeholder,
  } = props;
  const dataPath = 'dataMapper.selected';
  const sectionMapper = _get(section, 'mapper', false);
  const isMultiSelect = _get(section, 'multiSelect', false);
  const defaultValue = isMultiSelect ? [] : '';
  const sectionId = _get(section, 'id', '');
  const selected = _get(section, 'dataMapper.selected', defaultValue);
  const options = _get(section, 'dataMapper.options', []);

  const onSelect = value => {
    const currentValue = _get(section, 'dataMapper.selected', defaultValue);

    const updatedValues = isMultiSelect ? [...currentValue, value] : value;
    onSelectionChanged(sectionId, updatedValues, dataPath, subSection);
  };

  const getOptions = () =>
    options.map(option => {
      const { id, name } = option;

      if (!isMultiSelect) {
        return (
          <Option value={id} key={`${id}_${name}`}>
            {name}
          </Option>
        );
      }

      const checked = selected.includes(id);

      return (
        <Option value={id} key={`${id}_${name}`}>
          <div className="select-unit-progess-item">
            <Checkbox checked={checked} />
            <p>{name}</p>
          </div>
        </Option>
      );
    });

  const onDeSelect = value => {
    if (!isMultiSelect) return value;

    const currentValue = _get(section, 'dataMapper.selected', defaultValue);
    const filteredValues = currentValue.filter(item => item !== value);
    onSelectionChanged(sectionId, filteredValues, dataPath, subSection);
  };

  const tagRender = tag => {
    const tagValue = _get(tag, 'value', '');
    const option = options.find(item => item.id === tagValue);

    if (!option) return null;

    const tagText = selected.length > 1 ? `${option.name},` : `${option.name}`;
    return (
      <p
        className={
          selected.length > 1
            ? 'section-selected-many'
            : 'section-selected-text'
        }
      >
        {tagText}
      </p>
    );
  };

  const dropdownRender = currentNode => {
    if (!(isMultiSelect && options.length)) return currentNode;

    const checked = selected.length === options.length;
    let indeterminate = !!selected.length;

    if (checked) {
      indeterminate = false;
    }

    return (
      <div>
        <Row className="section-select-dropdown-row">
          <Checkbox
            className="select-all-check-box"
            checked={checked}
            indeterminate={indeterminate}
            onChange={() => onSelectAllChange(checked)}
          />
          <span
            onClick={() => {
              onSelectAllChange(checked);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            Select All
          </span>
        </Row>
        <div>{currentNode}</div>
      </div>
    );
  };

  const onSelectAllChange = checked => {
    if (checked) {
      onSelectionChanged(sectionId, [], dataPath, subSection);
      return;
    }

    const values = options.map(item => item.id);
    onSelectionChanged(sectionId, values, dataPath, subSection);
  };

  if (!sectionMapper) return null;

  return (
    <>
      {label && <label className="section-select-title">{label}</label>}
      <Select
        placeholder={placeholder}
        disabled={disabled}
        size={size}
        style={{ width }}
        onClick={onClickSelect}
        onSelect={onSelect}
        onDeselect={onDeSelect}
        value={selected}
        mode={isMultiSelect ? 'multiple' : null}
        showArrow={showArrow}
        showSearch={showSearch}
        menuItemSelectedIcon={null}
        tagRender={tagRender}
        dropdownRender={dropdownRender}
        maxTagCount={maxTagCount}
      >
        {getOptions()}
      </Select>
    </>
  );
};

SectionSelect.defaultProps = {
  onClickSelect: event => event.stopPropagation(),
  size: 'small',
  width: 120,
  showArrow: true,
  showSearch: false,
  maxTagCount: MAX_TAG_COUNT,
  label: '',
  placeholder: 'Select values...',
};

SectionSelect.propTypes = {
  section: PropTypes.object,
  width: PropTypes.number,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  onClickSelect: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  disabled: PropTypes.bool,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  subSection: PropTypes.string,
  label: PropTypes.string,
  maxTagCount: PropTypes.number,
  placeholder: PropTypes.string,
};

export default SectionSelect;
