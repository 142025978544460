import request from "../../utils/request";

export default {
    keyRegenerate: async function (token, field, onSuccess, onError) {
        const requestOptions = {
            service: `user/onboarding/keygen/${token}/${field}`,
            method: "PATCH",
            onSuccess: onSuccess,
            onError: onError,

        };

        request.make(requestOptions);
    },
    addApiKey: async function (token, accountId, apikey, onSuccess, onError) {
        const requestOptions = {
            service: `consumer/key`,
            method: "POST",
            onSuccess: onSuccess,
            onError: onError,
            data: {api_key:apikey, token, account_id: accountId}
        };

        request.make(requestOptions);
    },

    sendInfoEmail: async function (token, pricingPackage, onSuccess, onError) {
        const requestOptions = {
            service: `consumer/${token}/pricing/info`,
            method: "POST",
            onSuccess: onSuccess,
            onError: onError,
            data: {pricingPackage}
        };

        request.make(requestOptions);
    },

    directSignUp: async function (data, onSuccess) {
        const requestOptions = {
            service: `user/`,
            method: "POST",
            onSuccess: onSuccess,
            data: {...data}
        };

        request.make(requestOptions);
    },

}