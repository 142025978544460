import _get from "lodash/get";

// return ra compliance data
export const getDashboardComplianceData = state => _get(state, 'jrGeneric.riskAnalysis.compliance');

export const getComplianceCardsLoading = state => _get(getDashboardComplianceData(state), 'cards.isLoading');
export const getComplianceCardsData = state => _get(getDashboardComplianceData(state), 'cards.data');

export const getComplianceStudentsLoading = state => _get(getDashboardComplianceData(state), 'students.isLoading');
export const getComplianceStudentsData = state => _get(getDashboardComplianceData(state), 'students.data.students', []);
export const getComplianceFactorsData = state => _get(getDashboardComplianceData(state), 'students.data.enabledFactors');

export const getComplianceOverviewLoading = state => _get(getDashboardComplianceData(state), 'overview.isLoading');
export const getComplianceOverviewData = state => _get(getDashboardComplianceData(state), 'overview.data');

export const getComplianceOverviewDurationToExpire = state => _get(getDashboardComplianceData(state), 'durationToExpire');
export const getComplianceOverviewFileNotes = state => _get(getDashboardComplianceData(state), 'fileNotes');