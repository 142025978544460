import { SEARCH_USER_BY_USERNAME, SEARCH_USER_BY_USERNAME_LOADING } from "../../constant/actionTypes";

const appTestingReducerDefaultState = {
  searchResultList: {
    isLoading: false,
    error: false,
    data: [],
  },
};

const appTestingReducer = (state = appTestingReducerDefaultState, action) => {
  switch (action.type) {
    case SEARCH_USER_BY_USERNAME_LOADING:
      return {
        ...state,
        searchResultList: {
          isLoading: true,
          error: false,
          data: [],
        }
      }
    case SEARCH_USER_BY_USERNAME:
      return {
        ...state,
        searchResultList: {
          ...state.searchResultList,
          ...action.payload,
        }
      }
    default:
      return state;
  }
};

export default appTestingReducer;
