import React from 'react';
import {Form, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const Search = ({onSearch, searchRef, ...rest}) => {
    return (
        <Form className="courseSearch">
            <Form.Item>
                <Input
                    size="large"
                    addonAfter={<SearchOutlined/>}
                    onChange={onSearch}
                    ref={searchRef}
                    {...rest}/>
            </Form.Item>
        </Form>
    );
};

Search.defaultProps = {
    placeholder: "Search"
};

Search.propTypes = {
    onSearch: PropTypes.func,
};

export default Search;