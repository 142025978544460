import React, {memo} from "react";
import {Typography, Card} from "antd";
import PropTypes from "prop-types";

import BarChart from "../../../../../../../../../../common/charts/BarChart";
import NAMessage from "../../../../../../../../NAMessage";


const BarChartCard = ({name, data, enabled}) => {
    const chartConf = {
        title: {
            text: ""
        },

        legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: 'horizontal',
        },
        xAxis: {
            categories: data?.categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: "Number of Students"
            },
            tickInterval: 5
        },

        plotOptions: {
            series: {
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: false,
                        brightness:0,
                    }
                },
            },
        },
        series: data?.data
    }


    return (
        <Card className="">
            <Typography.Paragraph strong className="cardHeading">{name}</Typography.Paragraph>
            {enabled?
                <BarChart chartOptions={chartConf}/>
                :
                <NAMessage/>
            }
        </Card>
    )
}

BarChartCard.defaultProps = {
    enabled: true
}

BarChartCard.propTypes = {
    name: PropTypes.string,
    data: PropTypes.object,
}

export default memo(BarChartCard);