import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import {
  getAcademicStudentsData,
  getAcademicStudentsLoading,
  getAcademicFactorsData,
  getDashboardFiltersSelectedData,
  getSelectedCardsByPath,
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchAcademicStudents } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/academic.action';
import { setSelectedCards } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/common.action';

import Students from './Students';
import StudentDetailCell from '../../../../../common/StudentDetailCell';
import ProgressBarCell from '../../../../../common/ProgressBarCell';
import Loader from '../../../../../../../../common/layout/Loader';
import { filterStudentByCard } from '../../../../../common/topCards/subSectionTopCards';

import {
  academicSections,
  subSections,
} from '../../../../../../../../../../../constant/vetTrack/analysis';
import {
  getUniqValuesForFilters,
  isEmptyValue,
  onFilterColumn,
  sortDataColumn,
} from '../../../../../../../../utils/general';
import { prepareRiskMeasurementsColumns } from '../../../../../../../../utils/studentTable';
import NoDataCell from '../../../../../../../../common/partials/NoDataCell';

const StudentsTab = () => {
  const dispatch = useDispatch();
  const [filteredStudents, setFilteredStudents] = useState([]);

  // selectors
  const students = useSelector(getAcademicStudentsData);
  const enabledFactors = useSelector(getAcademicFactorsData);
  const studentsLoading = useSelector(getAcademicStudentsLoading);
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const selectedCard = useSelector(
    getSelectedCardsByPath(subSections.academic.key),
  );

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !studentsLoading) {
      const { academic } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: academic.id,
      };
      dispatch(setSelectedCards({ [subSections.academic.key]: null }));
      dispatch(fetchAcademicStudents(data));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (students) {
      setFilteredStudents(students);
    }
  }, [students]);

  useEffect(() => {
    filterStudentByCard(
      selectedCard,
      setFilteredStudents,
      students,
      subSections.academic.key,
    );
  }, [selectedCard]);

  const renderProgressCells = data => {
    if (!_isEmpty(data) && !isEmptyValue(data.value)) {
      return (
        <div className={`overview-progress-cell-${data.text}`}>
          <ProgressBarCell
            value={data.value}
            displayValue={`${data.value}`}
            color={data.color}
          />
        </div>
      );
    }

    return <NoDataCell />;
  };

  const textCellRender = value => {
    if (value === null || value === undefined) {
      return <NoDataCell />;
    }

    return value;
  };

  const columns = [
    {
      title: '',
      className: 'table-remove-left',
      children: [
        {
          title: 'Student Details',
          dataIndex: 'studentDetails',
          sorter: (value1, value2) =>
            value1.studentDetails.name &&
            value1.studentDetails.name.localeCompare(value2.studentDetails.name),
          render: value => (
            <StudentDetailCell
              title={value.name}
              subtitle={value.id}
              avatar={value.image}
            />
          ),
          onFilter: (value, record) => record.studentDetails.name === value,
          filters: getUniqValuesForFilters(
            students,
            'studentDetails.name',
            'studentDetails.name',
          ),
          fixed: 'left',
        },
        {
          title: 'Course',
          dataIndex: ['course', 'name'],
          sorter: (value1, value2) =>
            value1.course.name && value1.course.name.localeCompare(value2.course.name),
          onFilter: (value, record) => onFilterColumn(value, record, 'course', 'name'),
          filters: getUniqValuesForFilters(
            students,
            'course.name',
            'course.name',
          ),
          render: textCellRender,
        },
        {
          title: 'Academic Risk Score',
          dataIndex: 'academic',
          sorter: (row1, row2) => sortDataColumn(row1, row2, 'academic'),
          onFilter: (value, record) => onFilterColumn(value, record, 'academic', 'value'),
          render: value => renderProgressCells(value),
          filters: getUniqValuesForFilters(
            students,
            'academic.value',
            'academic.value',
          ),
        },
      ],
    },
    {
      title: 'Risk Measurements',
      children: prepareRiskMeasurementsColumns(
        enabledFactors,
        students,
        academicSections,
      ),
    },
  ];

  return (
    <Loader isLoading={studentsLoading}>
      <Students students={filteredStudents} columns={columns} />
    </Loader>
  );
};

export default StudentsTab;
