import React, {Fragment, useEffect, useState} from "react";
import {Card, Col, Row, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {validateUserToken} from "../../../actions/signup.action";
import {
    makeUserTokenValidationLoading,
    makeUserTokenValidationResponseData
} from "../../../selectors/userTokenValidate.selector";
import Stepper from "./partials/Stepper";
import FormWrapper from "./partials/FormWrapper";
import UrlConfigContainer from "./steps/containers/canvas/UrlConfigContainer";
import KeyConfigContainer from "./steps/containers/canvas/KeyConfigContainer";

import "../../../assets/css/onboarding/common.css";
import {showNotification} from "../../../utils/general";

const brandLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/canvas-logo.png`;

const DirectSignupContainer = () => {
    const tokenValidating = useSelector(makeUserTokenValidationLoading);
    const validated = useSelector(makeUserTokenValidationResponseData);
    const dispatch = useDispatch();
    const {token} = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const type = "canvas";
    const steps = {
        canvas: [
            {title: "urlConfiguration", Component: UrlConfigContainer},
            {title: "keyConfiguration", Component: KeyConfigContainer},
        ]
    };

    useEffect(() => {
        dispatch(validateUserToken(token));
    }, []);

    useEffect(() => {
        setTimeout(() => setIsLoading(tokenValidating), 500);
    }, [tokenValidating]);

    const getSelectedStep = () => {
        return steps[type][activeStep];
    };

    const triggerEndAction = () => {
        showNotification({
            type: 'info',
            message: 'Now go to application page and enter consumer and secret keys to setup Tentacle Insights',
            duration: 10
        })
    };

    const renderContent = () => {
        if (!validated && tokenValidating) {
            return (
                <Col span={24}>
                    <div style={{height: "500px"}}>&nbsp;</div>
                </Col>
            );
        }

        if (validated && validated.tokenStatus === "valid") {
            return (
                <Fragment>
                    <Row gutter={75} className={"layout-row"} wrap={true}>
                        <Col flex="1 1 50%"  className={"layout-video"}>
                            <h5>Welcome</h5>
                            <h1>{`${validated.dataValues.first_name} ${validated.dataValues.last_name}`}</h1>
                            <h5>You are logged in through a secure link</h5>
                            <div className="connectionLogo">
                                <img src={brandLogo} />
                            </div>
                            <h3>How to connect octopus to canvas</h3>
                            <p>
                                Click the "Settings" link in the left sidebar of the course or account where you want to add the app. Click the "External Tools" tab and click "Add External Tool".
                            </p>
                            <p>
                                Enter the name, consumer key and shared secret for the app. For "configuration type" select "By URL" and paste in the full URL of the link configuration (You will receive this via email).
                            </p>
                            <p>
                                After the app is saved you should see it appear as configured in the course our account content. Depending on the integration type, the app may appear different places, but most apps will appear under "External Tools" when adding items to a module.
                            </p>
                            <div className="videoCover">
                                <iframe
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/AKU7CN4huYw"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                            </div>
                        </Col>
                        <Col flex="1 1 50%"  className={"layout-form"}>
                            <Card className={"layout-card"}>
                                <Stepper steps={steps[type]} activeStep={activeStep}/>
                                <FormWrapper
                                    type={type}
                                    totalSteps={steps[type].length}
                                    setActiveStep={setActiveStep}
                                    activeStep={activeStep}
                                    selected={getSelectedStep()}
                                    previous={false}
                                    triggerEndAction={triggerEndAction}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
            );
        }

        return (
            <Col span={24}>
                <h1>Token is either invalid or expired</h1>
            </Col>
        )
    };

    return (
        <Fragment>
            <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
                <div className="wizardContainr">
                    {renderContent()}
                </div>
            </Spin>
        </Fragment>
    );
};

export default DirectSignupContainer;