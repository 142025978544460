import React, { useEffect, useState } from "react";
import { Layout, Button, Popover, Row, Col } from "antd";
import { MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router';
import { ValidateURL, queryStringToObject } from "../../../utils/general";
import Breadcrumb from "../../innovative/common/layout/Breadcrumb";
import session from "../../../utils/session";
import logo from '../../../assets/images/sqLogo.png';
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';

import { setSidebar } from "../../../actions/innovative/layout.action";
import { submitHelpFeedBack } from "../../../actions/innovative/generic.action";
import { makeInnovativeSidebar } from "../../../selectors/innovative/layout.selector";

import menuData from "../dashboardManagement/menuData";
import './innovative.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './innovative.scss';

import HelpContents from "./HelpContents";
import FeedbackModal from "./HelpContents/FeedbackModal";
import CustomHeader from "../../innovative/common/layout/customHeader";
import { get as _get } from 'lodash';
import { getIsApplicationAdmin } from "../../../selectors/innovative/course.selector";

const SEARCH_PARAMS_KEYS = ["token", "t", "access"];
const ADMIN_PATH = "/insights/admin/courses";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const FluidLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlPathName = window.location.href;
  const isHomePage = urlPathName?.includes(
    '/insights/teacher/courses') ||
    urlPathName?.includes('/insights/admin/courses');
  const [feedBackModalStatus, setFeedBackModalStatus] = useState(false);

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;

  // selectors
  const sidebarData = useSelector(makeInnovativeSidebar)
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);

  // store initial query params for future usage like auth token in actions
  // set impression data
  useEffect(() => {
    session.setOrAppend(session.keys.innovativeMeta, queryStringToObject(location.search));
  }, [location.search]);

  const backToAdminPanel = () => {
    return history.push(menuData.manageDashboard.url)
  }

  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManagementData, "is_admin", false);
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  
  const isAdminDashboardView = () => {
    return (path === ADMIN_PATH &&
      (isApplicationAdminData.role.toLowerCase() === ACCOUNT_MEMBERSHIP ||
        actAs.toLowerCase() === ACCOUNT_MEMBERSHIP));
  };

  const isAdminDashboard = isAdminDashboardView();
  const submitFeedBack = formData => {
    dispatch(submitHelpFeedBack(formData));
  };
  const className = isHomePage ? "search-header-enabled" : "search-header-disabled";

  const getSearchParams = () => {
    if (applicationAdmin || isAdminDashboard) {
      return [...SEARCH_PARAMS_KEYS, "actAs"];
    }
    return SEARCH_PARAMS_KEYS;
  };

  const validateURL = ValidateURL({
    url: window.location.search,
    validateUrlParams: getSearchParams(),
  });

  return (
    <Layout className={className}>
      <Layout.Header className="in-header withMenu" style={{ background: "white" }}>
        <div className="pri-header">
          <div className="mobileNav">
            <MenuOutlined onClick={() => dispatch(setSidebar({ collapsed: !sidebarData.collapsed }))} />
          </div>
          <div className="logo">
            <img src={logo} alt="headerLogo" />
          </div>
          <Breadcrumb hideBackButton={isHomePage} />
          {/* check if the user comes via dashboard sharing view*/}
          {/*TODO: if we merge the sessions in to one session, might need to change this*/}
          {dashboardManagementData && dashboardManagementData.is_admin &&
            <div className={"dashboard-admin-center-button"}>
              <Button
                type="primary"
                onClick={backToAdminPanel}
                icon={<FontAwesomeIcon icon={faTachometerAlt} />}
              >
                &nbsp; Admin Center
              </Button>
            </div>
          }
          <Popover
            placement="bottomRight"
            content={() => <HelpContents setModalStatus={setFeedBackModalStatus} />}
            zIndex={5000}
            arrowPointAtCenter
          >
            <QuestionCircleOutlined
              style={{
                fontSize: 24,
                float: "right",
                paddingTop: '12px',
                marginRight: '10px',
                color: '#5849f1',
                cursor: 'pointer'
              }}
            />
          </Popover>
        </div>
        {
          (isHomePage && validateURL) &&
          <div className="sec-header">
            <CustomHeader />
          </div>
        }
      </Layout.Header>
      <Layout>
        {children}
        <FeedbackModal
          openStatus={feedBackModalStatus}
          setModalStatus={setFeedBackModalStatus}
          submit={submitFeedBack}
        />
      </Layout>
    </Layout>
  );
};

export default FluidLayout;
