export default {
    studentToTeacherRatio: async function (filters) {
      try{
        return fetch(`${process.env.REACT_APP_APIURL}managementprofile/student_teacher_ratio`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      }catch(e){
        console.log(e.message);
      }
    },
    classroomCapacity: async function (filters, schoolId) {
      try{
        return fetch(`${process.env.REACT_APP_APIURL}managementprofile/classroom_capacity?schoolId=${schoolId}`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term)})
    
        })
      }catch(e){
        console.log(e.message);
      }
    },
    churnRate: async function (filters) {
      try{
        return fetch(`${process.env.REACT_APP_APIURL}managementprofile/churn_rate`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      }catch(e){
        console.log(e.message);
      }
    },
    studentEnrolmentLength: async function (filters) {
      try{
        return fetch(`${process.env.REACT_APP_APIURL}managementprofile/student_enrolment_length`, {
          credentials: "include",
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({term: parseInt(filters.term), schoolId: filters.school})
    
        })
      }catch(e){
        console.log(e.message);
      }
    }
}