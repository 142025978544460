import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from 'highcharts/modules/drilldown';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import classnames from "classnames";
import _isEmpty from "lodash/isEmpty";


drilldown(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.setOptions({
    lang: {
        drillUpText: '< Back'
    },
    colors: ['#323F9F', '#2B79EE', '#6F7886', '#C4C4C4', '#C5A8B1', '#8794DB']
});

const StatChart = ({chartOptions, containerProps, immutable}) => {
    const defaultConfig = {
        credits: {
            enabled: false
        },
        chart: {
            type: "pie",
        },

        title: false,
        subTitle: false,
        legend: {enabled: true},

        plotOptions: {
            pie: {
                // size: "20%",
                // innerSize: "50%",
                dataLabels: {
                    enabled: false
                },
                slicedOffset: 0
            },
        },
        tooltip:{enabled: false}
    }

    const config = Highcharts.merge(defaultConfig, chartOptions);

    // const configSeries =  config?.series;
    // const isEmptyData = configSeries?.find(item => !_isEmpty(item?.data));

    return (
        <Fragment>
            <div className="stat-chart-wrapper">
                {/*{ isEmptyData && <span className={classnames("no-data-wrapper")}>No Data</span> }*/}
                <HighchartsReact
                    containerProps={containerProps}
                    highcharts={Highcharts}
                    options={config}
                    immutable={immutable}
                />
            </div>

        </Fragment>
    )
}
StatChart.defaultProps = {
    immutable: false
}

StatChart.propTypes = {
    chartOptions: PropTypes.object,
    containerProps: PropTypes.object,
    immutable: PropTypes.bool
}

export default StatChart;