import React, {memo, useState} from 'react';
import {Popover} from "antd";
import { SketchPicker } from 'react-color'
import PropTypes from "prop-types";

const SettingColorPicker = ({value, onChange, disabled, text}) => {
    const [pickerEnabled, setPickerEnabled] = useState(false);
    const styles = {
        color: {
            width: 'auto',
            display: 'block',
            height: '20px',
            borderRadius: '2px',
            textAlign:"center",
            background: `${value}`,
        },
        swatch: {
            padding: '2px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'block',
            cursor: disabled ? "default" : 'pointer',
        },
    };

    const handleClick = () => {
        if (!disabled) {
            setPickerEnabled(!pickerEnabled);
        }
    };

    const handleChange = (color) => {
        onChange(color.hex);
    };

    return (
        <div>
            <Popover
                content={
                    <SketchPicker color={value} onChange={handleChange}/>
                }
                trigger="click"
                visible={pickerEnabled}
                onVisibleChange={handleClick}
            >
                <div style={styles.swatch} >
                    <div style={styles.color}>{text}</div>
                </div>
            </Popover>
        </div>
    );
};

SettingColorPicker.propTypes = {
    value: PropTypes.string,
    onchange: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.string,
};

export default memo(SettingColorPicker);