import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, Col, Layout, Row, Tabs} from "antd";
import PropTypes from "prop-types";
import StudentProfileCard from "../common/cards/StudentProfileCard";
import AssignmentComparisonGrid from "./assignmentComparisonGrid";
import TopCard from "./topCard";
import SideChartsContainer from "../common/cards/sideCharts";
import OutcomeGridContainer from "./outcomeGrid/OutcomeGridContainer";
import PieChart from "../common/charts/PieChart";
import Loader from "../common/layout/Loader";
import {CheckSquareTwoTone} from "@ant-design/icons";
import Filter from "../common/filter";
import TaskFilterSection from '../../innovative/common/taskFilterSection';
import {ACTING_ROLES as ROLES} from "../../../constant/innovative_student";
import { FILTER_ASSIGNMENT_GROUP } from '../../../utils/innovative/constants';

const { TabPane } = Tabs;

const StudentAssignmentView = ({
                                   student,
                                   courseId,
                                   assignmentsReport,
                                   assignmentsLoading,
                                   sideChartsConfigs,
                                   currentViewTab,
                                   outcomeEnabled,
                                   outcomeConfig,
                                   masteryConfig,
                                   setBreadcrumb,
                                   studentFilterConfig,
                                   termFilterConfig,
                                   courseFilterConfig,
                                   actingRole,
                                   currentTab,
                                   setCurrentTab,
                                   applyFilter,
                                   setTaskTablePagination,
                               }) => {

    useEffect(() => {
        if (currentViewTab){
            setCurrentTab(currentViewTab);
        }
    }, [currentViewTab])
    const [currentFilter, setCurrentFilter] = useState(FILTER_ASSIGNMENT_GROUP);

    const onTabChange = (e) => {
        setCurrentTab(e);
        setBreadcrumb(e);
    }

    const masteredOutcomeRenderer = () => {
        return (
            currentTab === "outcomes" &&
            <div style={{display:"flex", alignItems: "center"}}>
                <CheckSquareTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a"/>
                 <span style={{ fontSize: 14 }}>&nbsp; The student has mastered in {assignmentsReport.student_mastery_count}/{assignmentsReport.outcomes.length} outcomes</span>
            </div>
        )
    }

    const getTabGridContent = () => {
        if (outcomeEnabled) {
            return (
                <Tabs
                    defaultActiveKey={currentTab}
                    tabBarStyle={{fontSize:40}}
                    activeKey={currentTab}
                    className="customTabs"
                    onChange={onTabChange}
                    tabBarExtraContent={masteredOutcomeRenderer()}
                >

                    <TabPane tab="Tasks" key="tasks">
                        <div className="taskFilterInTab">
                            <TaskFilterSection 
                                applyFilter={applyFilter} 
                                setCurrentFilter={setCurrentFilter}
                            />
                        </div>
                        <AssignmentComparisonGrid
                            courseId={courseId}
                            studentId={student.student_id}
                            setTaskTablePagination={setTaskTablePagination}
                        />
                    </TabPane>
                    <TabPane tab="Outcomes" key="outcomes">
                        <OutcomeGridContainer/>
                    </TabPane>
                </Tabs>
            );
        }

        return (
            <>
                <div className="taskFilterHeading">
                    <h4>Tasks</h4>
                    <TaskFilterSection 
                        applyFilter={applyFilter} 
                        setCurrentFilter={setCurrentFilter}
                    />
                </div>
                <AssignmentComparisonGrid
                    setTaskTablePagination={setTaskTablePagination}
                />
            </>
        );

    };

    return (
        <Layout.Content
            className="site-layout-background singleColumnLayout"
        >
            <Row gutter={[12, 0]}>
                <Col md={24} xl={6}>
                    <Row gutter={[16, 0]}>
                        <Col md={24} xl={24}>
                            <StudentProfileCard
                                src={student["student_avatar_url"]}
                                name={student["student_name"]}
                                id={student["student_sis_user_id"]}
                            />
                        </Col>
                    </Row>
                    {ROLES.parent.includes(actingRole) &&
                    <Row gutter={[16, 0]}>
                        <Col>
                            <div className="in-sectionCover">
                                <Filter
                                    name="Student"
                                    reportLoading={assignmentsLoading}
                                    {...studentFilterConfig}
                                    filterConfig={{
                                        key: "id",
                                        value: "id",
                                        label: "name",
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    }
                    <Row  gutter={[16, 0]}>
                        <Col>
                            <div className="in-sectionCover">
                                <Filter
                                    name="Term"
                                    reportLoading={assignmentsLoading}
                                    {...termFilterConfig}
                                    filterConfig={{
                                        key: "_id",
                                        value: "_id",
                                        label: "name",
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row  gutter={[16, 0]} justify={"center"}>
                        <Col>
                            <div className="in-sectionCover">
                                <Filter
                                    name="Course"
                                    reportLoading={assignmentsLoading}
                                    {...courseFilterConfig}
                                    filterConfig={{
                                        key: "course_id",
                                        value: "course_id",
                                        label: "course_name",
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col md={24} xl={24}>
                            <div className="in-sectionCover">
                                <SideChartsContainer
                                    configs={sideChartsConfigs}
                                    records={assignmentsReport}
                                    recordsLoading={assignmentsLoading}
                                    type="task"
                                />
                                <Loader isLoading={assignmentsLoading}>
                                    <h5>Overall Outcomes</h5>
                                    <PieChart chartOptions={outcomeConfig}/>
                                    <h5>Mastery Status</h5>
                                    <PieChart chartOptions={masteryConfig}/>
                                </Loader>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col  md={24} xl={18} style={{display: "flex",flexFlow: "column"}}>
                    <div className="in-sectionCover studentDetails">
                        <Row gutter={[16, 16]}>
                            <TopCard/>
                        </Row>
                    </div>
                    <div className="in-sectionCover assignmentCover">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {getTabGridContent()}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Layout.Content>
    );
};

StudentAssignmentView.defaultProps = {
    student: PropTypes.object,
    assignmentsReport: PropTypes.object,
    assignmentsLoading: PropTypes.bool,
    sideChartsConfigs: PropTypes.array,
}

StudentAssignmentView.propTypes = {
    currentViewTab: PropTypes.string,
    outcomeEnabled: PropTypes.bool,
    outcomeConfig: PropTypes.object,
    masteryConfig: PropTypes.object,
    setBreadcrumb: PropTypes.func,
    studentFilterConfig: PropTypes.object,
    termFilterConfig: PropTypes.object,
    courseFilterConfig: PropTypes.object,
    actingRole: PropTypes.string,
    courseId: PropTypes.string,
    currentTab: PropTypes.string,
    setCurrentTab: PropTypes.func
}

export default memo(StudentAssignmentView);