import {
    FETCH_STUDENT,
    FETCH_STUDENT_LOADING,
    FETCH_STUDENT_REPORT,
    FETCH_STUDENT_REPORT_LOADING,
    CLEAR_STUDENT_DATA,
    FETCH_DUE_ASSIGNMENTS,
    FETCH_DUE_ASSIGNMENTS_LOADING,
    FETCH_STUDENT_TERMS_LOADING,
    FETCH_STUDENT_TERMS,
    CLEAR_STUDENT_TERMS_DATA,
    CLEAR_STUDENT_DETAILS,
    FETCH_STUDENT_PROFILE_CHART_DATA,
    CLEAR_STUDENT_PROFILE_CHART_DATA,
    SEARCH_STUDENT,
    SEARCH_STUDENT_LOADING,
    RESET_SEARCH_STUDENT_LOADING,
} from "../../constant/actionTypes";

const studentReducerDefaultState = {
    selected: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {},
            },
        }
    },
    mainReport: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {
                    course_comparison: [],
                    course_outcomes: [],
                    course_cards: [],
                    overall_course_grade: [],
                    overall_assignment_submission: [],
                    submissionColor: []
                },
            },
        }
    },
    terms: {
        isLoading: false,
        error: false,
        response: {
            data: [],
        }
    },
    dueAssignments: {
        isLoading: false,
        error: false,
        response: {
            data: {}
        }
    },
    cohortData: [],
    gaugeData: [],
    searchStudents: {
        isLoading: false,
        error: false,
        response: {
            data: [],
        }
    },
};

const studentReducer = (state = studentReducerDefaultState, action) => {
    switch (action.type) {
        case CLEAR_STUDENT_PROFILE_CHART_DATA:
            return {
                ...state,
                cohortData: studentReducerDefaultState.cohortData,
                gaugeData: studentReducerDefaultState.gaugeData
            };
        case FETCH_STUDENT_PROFILE_CHART_DATA:
            const { course_id, engagement, grade_cohort } = action.payload;
            return {
                ...state,
                cohortData: [...state.cohortData, { courseId: course_id, data: grade_cohort }],
                gaugeData: [...state.gaugeData, { courseId: course_id, data: engagement }]
            };
        case CLEAR_STUDENT_TERMS_DATA:
            return {
                ...state,
                terms: studentReducerDefaultState.terms
            };
        case CLEAR_STUDENT_DETAILS:
            return {
                ...state,
                selected: studentReducerDefaultState.selected
            };
        case FETCH_STUDENT_TERMS:
            return {
                ...state,
                terms: action.payload
            };
        case FETCH_STUDENT_TERMS_LOADING:
            return {
                ...state,
                terms: {
                    ...state.terms,
                    isLoading: action.payload.isLoading
                }
            };
        case FETCH_STUDENT:
            return {
                ...state,
                selected: action.payload
            };
        case FETCH_STUDENT_LOADING:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    isLoading: action.payload.isLoading
                }
            };
        case FETCH_STUDENT_REPORT:
            return {
                ...state,
                mainReport: action.payload
            };
        case FETCH_STUDENT_REPORT_LOADING:
            return {
                ...state,
                mainReport: {
                    ...state.mainReport,
                    isLoading: action.payload.isLoading
                }
            };
        case CLEAR_STUDENT_DATA:
            return {
                ...state,
                mainReport: studentReducerDefaultState.mainReport
            };
        case FETCH_DUE_ASSIGNMENTS:
            return {
                ...state,
                dueAssignments: action.payload
            };
        case FETCH_DUE_ASSIGNMENTS_LOADING:
            return {
                ...state,
                dueAssignments: {
                    ...state.dueAssignments,
                    isLoading: action.payload.isLoading
                }
            };
        case SEARCH_STUDENT:
            return {
                ...state,
                searchStudents: action.payload
            };
        case SEARCH_STUDENT_LOADING:
            return {
                ...state,
                searchStudents: {
                    ...studentReducerDefaultState.searchStudents,
                    isLoading: action.payload.isLoading,
                }
            };
        case RESET_SEARCH_STUDENT_LOADING:
            return {
                ...state,
                searchStudents: {
                    ...studentReducerDefaultState.searchStudents,
                },
            }
        default:
            return state;
    }
};

export default studentReducer;