import {
    SET_AUTHUSER,
    SET_SIDEBAR_ACTIVE,
    SET_SUB_USER,
    REMOVE_SUB_USER,
    SET_INTERIM_USER,
    REMOVE_INTERIM_USER,
    REMOVE_INTERIM_USER_LOADING,
    REMOVE_INTERIM_USER_ERROR,
    USER_SIGN_IN,
    USER_SIGN_IN_LOADING,
    USER_SIGN_OUT,
    USER_SIGN_OUT_LOADING,
    USER_RESET_PASSWORD,
    USER_RESET_PASSWORD_LOADING
} from '../constant/actionTypes';
import {reactLocalStorage} from 'reactjs-localstorage';

export const authenticationReducerDefaultState = {
    authUser : localStorage.getItem('authUser') || false,
    interimUser : reactLocalStorage.getObject('interimUser', {
        isLoading: false,
        isLoggedIn: false,
        role: null,
        school: null,
        schoolIdArray: [],
    }),
    sideBarActive: 'financial_summary',
    subUser: {
        isLoading: false,
        response: reactLocalStorage.getObject('subUser'),
    },
    externalUser: {
        login: {
            isLoading: false,
            data: {},
        },
        reset: {
            isLoading: false,
            data: {},
        }
    }
};

const AuthenticationReducer = (state = authenticationReducerDefaultState, action) => {
    switch (action.type) {
        case USER_SIGN_IN:
        case USER_SIGN_IN_LOADING:
        case USER_SIGN_OUT:
        case USER_SIGN_OUT_LOADING:
            return {
                ...state,
                externalUser: {
                    ...state.externalUser,
                    login: {
                        ...state.externalUser.login,
                        ...action.payload
                    }
                }
            };
        case USER_RESET_PASSWORD:
        case USER_RESET_PASSWORD_LOADING:
            return {
                ...state,
                externalUser: {
                    ...state.externalUser,
                    reset: {
                        ...state.externalUser.reset,
                        ...action.payload
                    }
                }
            };
        case SET_AUTHUSER:
            // for tassweb
            localStorage.setItem('authUser', action.payload.authUser);
            return {
                ...state,
                authUser: action.payload.authUser
            };
        case SET_INTERIM_USER:
            reactLocalStorage.setObject('interimUser', action.payload);
            return {
                ...state,
                interimUser: action.payload
            };
        case REMOVE_INTERIM_USER_LOADING:
        case REMOVE_INTERIM_USER_ERROR:
            return {
                ...state,
                interimUser: {
                    ...state.interimUser,
                    ...action.payload
                }
            };
        case REMOVE_INTERIM_USER:
            reactLocalStorage.remove('interimUser');
            return {
                ...state,
                interimUser: {...authenticationReducerDefaultState.interimUser, ...action.payload}
            };
        case SET_SIDEBAR_ACTIVE:
            return {
                ...state,
                sideBarActive: action.payload.sideBarActive
            }
        case SET_SUB_USER:
            reactLocalStorage.setObject('subUser', action.payload.response);
            return {
                ...state,
                subUser: action.payload
            };
        case REMOVE_SUB_USER:
            reactLocalStorage.remove('subUser');
            reactLocalStorage.remove('subUserVerified');
            return {
                ...state,
                subUser: {
                    isLoading: false,
                    response: reactLocalStorage.getObject('subUser'),
                }
            };
        default:
            return state;
    }
}

export default AuthenticationReducer;