import React, {useState} from "react";
import PropTypes from "prop-types";
import {Tabs} from "antd";

const {TabPane} = Tabs;
const TAB_NAMES= ["Mandatory Online Modules", "Qualification Pathway"]

const Pill = ({index, setActiveTab, activeTab, children}) => {

    return <div
        {...children.props}
        className={`pilltab ${activeTab === index && "pilltab-active"}`}
        style={{width:"50%", margin:2}}
        onClick={() => {
            setActiveTab(index);
            children.props.onClick();
        }}
    >{TAB_NAMES[index-1]}</div>
}

const PillTabs = ({tabs}) => {
    const [activeTab, setActiveTab] = useState("1");

    const renderTabBar = (props, DefaultTabBar) => (
        <DefaultTabBar {...props}>
            {node => (
                <Pill key={node.key} index={node.key} setActiveTab={setActiveTab} activeTab={activeTab}>
                    {node}
                </Pill>
            )}
        </DefaultTabBar>
    );

    return (
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} className="pilltabs" size="large">
            {tabs.map((Component, index) =>
                <TabPane key={index+1}>
                    <Component/>
                </TabPane>)
            }
        </Tabs>
    )

}

PillTabs.propTypes = {
    tabs: PropTypes.array
}

export default PillTabs;