import _get from 'lodash/get';
import { createSelector } from 'reselect';

const studentClusterSettings = state =>
  _get(state, 'dashboardManage.studentClusterAnalyzer', {});

export const makeSettingsStudentClusterAnalyzerLoading = createSelector(
  studentClusterSettings, data => _get(data, 'isLoading', false),
);

//fetch cluster enabled status
export const makeSettingsStudentClusterAnalyzerStatus = createSelector(
  studentClusterSettings, data => _get(data, 'cluster.data.cluster'),
);
