import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDashboardValidity } from '../../../../actions/vetTrack/dashboardManagement/myDashboards.action';
import {
  makeDashboardValidityData,
  makeDashboardValidityLoading,
} from '../../../../selectors/vetTrack/dashboardManagement.selector';
import { getSidebarCollapsed } from '../../../../selectors/vetTrack/analysis.selector';

import VetTrackHeader from './Header';
import Loader from '../../../vetTrack/analysis/common/layout/Loader';
import Maintenance from '../../../vetTrack/analysis/common/Maintenance';

import { vttValidityCode, axlValidityCode } from '../../../../constant/vetTrack/analysis';

import '../../../../assets/scss/vetTrack/analysis/layout.scss';
import '../../../../assets/scss/vetTrack/analysis/riskAnalysis.scss';
import { menu } from '../../../../constant/vetTrack/menu';

const VetTrackLayout = ({ children }) => {
  const dispatch = useDispatch();
  
  const urlPathName = window.location.href;
  const parsedUrl = new URL(urlPathName);
  const hostName = parsedUrl.host;

  // selectors
  const dashboardValidityLoading = useSelector(makeDashboardValidityLoading);
  const dashboardValidity = useSelector(makeDashboardValidityData);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  const [validityCode, setValidityCode] = useState(null);

  useEffect(() => {
    if (hostName?.includes(menu.routs.vettrak)) {
      setValidityCode(vttValidityCode);
    } else if (hostName?.includes(menu.routs.axcelerate)) {
      setValidityCode(axlValidityCode);
    } else {
      setValidityCode(vttValidityCode);
    }
  }, []);

  useEffect(() => {
    validityCode && dispatch(fetchDashboardValidity({ accessCode: validityCode }));
  }, [validityCode]);

  const getContent = () => {
    if (dashboardValidity && dashboardValidity.active) {
      return (
        <Layout
          className={`vtt-gen-layout ${sidebarCollapsed ? 'mobile-menu-opened' : ''}`}
        >
          <div className="vtt-fader"></div>
          <>
            <VetTrackHeader />
            <Layout data-testid="dashboard-content">{children}</Layout>
          </>
        </Layout>
      );
    }

    return <Maintenance data-testid="maintenance" />;
  };

  return (
    <Loader
      wrapperClassName="vtt-loader-wrapper"
      isLoading={dashboardValidityLoading}
    >
      {getContent()}
    </Loader>
  );
};

export default VetTrackLayout;
