import _get from "lodash/get";

// return vw dealership response
export const makeVwDealershipDataResponse = state => _get(state, 'jrDashboardManage.user.vwUserDealership');

// return vw user dealership main data response
export const makeVwDealershipMainDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.main;
};

// return vw user dealership main data loading
export const makeVwDealershipMainDataLoading = state => {
    const result = makeVwDealershipMainDataResponse(state);
    return result && result.isLoading;
};

// return vw user dealership main data response
export const makeVwDealershipMainDataResponseData = state => {
    const result = makeVwDealershipMainDataResponse(state);
    return result && result.data;
};

// return vw user dealership main data users response
export const makeVwDealershipMainDataUsersResponseData = state => {
    const result = makeVwDealershipMainDataResponseData(state);
    return result && _get(result, 'response.users', []);
};

// return vw user dealership selected data response
export const makeVwDealershipSelectedDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.selected;
};

// return vw user dealership selected data loading
export const makeVwDealershipSelectedDataLoading = state => {
    const result = makeVwDealershipSelectedDataResponse(state);
    return result && result.isLoading;
};

// return vw user dealership selected data response
export const makeVwDealershipSelectedDataResponseData = state => {
    const result = makeVwDealershipSelectedDataResponse(state);
    return result && _get(result, 'data.response', false);
};

// return vw user dealership added data response
export const makeVwDealershipAddedDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.added;
};

// return vw user dealership added data loading
export const makeVwDealershipAddedDataLoading = state => {
    const result = makeVwDealershipAddedDataResponse(state);
    return result && result.isLoading;
};

// return vw user dealership deleted data response
export const makeVwDealershipDeletedDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.deleted;
};

// return vw user dealership deleted data loading
export const makeVwDealershipDeletedDataLoading = state => {
    const result = makeVwDealershipDeletedDataResponse(state);
    return result && result.isLoading;
};

// return vw user dealership updated data response
export const makeVwDealershipUpdatedDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.updated;
};

// return vw user dealership updated data loading
export const makeVwDealershipUpdatedDataLoading = state => {
    const result = makeVwDealershipUpdatedDataResponse(state);
    return result && result.isLoading;
};

/** dealerships data **/
// return vw user dealership res data response
export const makeVwDealershipResDataResponse = state => {
    const result = makeVwDealershipDataResponse(state);
    return result && result.resData;
};

// return vw user dealership res data dealerships loading
export const makeVwDealershipResDataDealershipsLoading = state => {
    const result = makeVwDealershipResDataResponse(state);
    return result && _get(result, 'dealerships.isLoading', false);
};

// return vw user dealership res data dealerships response
export const makeVwDealershipResDataDealershipsResponseData = state => {
    const result = makeVwDealershipResDataResponse(state);
    return result && _get(result, 'dealerships.data.dealerships', []);
};


