const { POST } = require("./../../../utils/network");

export default {
  financialData: async function (filters) {
    try {
      return POST(
        `${process.env.REACT_APP_APIURL}principalprofile/financial/data`,
        {
          schoolId: filters.school,
          term: parseInt(filters.term),
        }
      );
    } catch (e) {
      console.log(e.message);
    }
    
  },
  staffFTEData: async function (filters) {
    try {
      return POST(
        `${process.env.REACT_APP_APIURL}principalprofile/staffFTE/data`,
        {
          schoolId: filters.school,
          term: parseInt(filters.term),
        }
      );
    } catch (e) {
      console.log(e.message);
    }
    
  },
};
