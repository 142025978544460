import React, {Fragment, useState} from "react";
import {Row, Col, Typography, Button, Card, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";

import PricingTableCard from "./PricingTableCard";
import {onSuccess, onError} from "../../../utils/notificationHandler";
import ApiService from "../../../services/onboarding";

const { Meta } = Card;

const PRICING = [
    {
        id: 1,
        name: "Basic",
        description: "Minimum Features Included",
        price: "XXX",
        color: "#111d2c",
    },
    {
        id: 2,
        name: "Platinum",
        description: "All Features Included",
        price: "XXX",
        color: "#cdd5e0",
    },
    {
        id: 3,
        name: "Gold",
        description: "Standard Features Included",
        price: "XXX",
        color: "#D4AF37",
    }
]

const PricingTable = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {token} = useParams();

    const sendInformationEmail = (packageId) => {
        setIsLoading(true);
        ApiService.OnboardingConsumer.sendInfoEmail(token, packageId,
            () => {
                onSuccess("Email has been sent to your inbox")
                setIsLoading(false);
            },
            () => {
                onError("Something went wrong", "Please try again");
                setIsLoading(false);
            }
        )
    }

    return (
        <Fragment>

                <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
                    <Row type="flex" style={{ alignItems: "center" }} gutter={16} justify={"center"}>
                        {PRICING.map(item => (
                            <Col span={8}>
                            <PricingTableCard item={item} sendEmail={sendInformationEmail} />
                        </Col>
                        ))}
                    </Row>
                </Spin>
        </Fragment>
    )
}

export default PricingTable;