import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const UsersTable = ({ users, columns }) => (
  <div className="vtt-user-table">
    <Table columns={columns} dataSource={users} />
  </div>
);

UsersTable.propTypes = {
  columns: PropTypes.array,
  users: PropTypes.array,
};

export default UsersTable;
