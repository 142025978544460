import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import _startCase from 'lodash/startCase';

import {
  getRiSettingsByPath,
  getPermissionDataByPath,
} from '../../../../../../../selectors/vetTrack/analysis.selector';
import {
  setSettings,
  setSetttingError,
} from '../../../../../../../actions/vetTrack/analysis/settings/commonSettings.action';

import MeasurementIndicatorsView from './MeasurmentIndicatorsView';

import { settingsSections } from '../../../../../../../constant/vetTrack/analysis';

const MeasurementIndicatorsContainer = ({ subSection }) => {
  const dispatch = useDispatch();

  // selectors
  const factors = useSelector(
    getRiSettingsByPath(`${subSection}.data.factors`),
  );
  const overallData = useSelector(
    getRiSettingsByPath(`${subSection}.data.overall`),
  );
  const hasPermission = useSelector(
    getPermissionDataByPath(`${subSection}.saveColors`),
  );
  
  const sectionErrors = useSelector(
    getRiSettingsByPath(`${subSection}.errors`),
  );

  const onValueChange = (factorKey, type, key, value) => {
    console.log(factorKey, type, key, value);
    const currentErrors = sectionErrors || {};
    let updated = {
      ...currentErrors,
    };
    const error = `${factorKey}_${type}_${key}`;
    delete updated[error];

    if ( value === null || value === '' || value === undefined) {
      updated = {
        ...currentErrors,
        [error]: 'Empty value',
      };
    }

    dispatch(setSetttingError(subSection, updated));

    dispatch(
      setSettings(
        value,
        `data.factors.${factorKey}.${type}.${key}`,
        settingsSections.riskIndicators,
        subSection,
      ),
    );
  };

  return (
    <div className="customAcordian">
      <Typography.Title level={5}>
        {_startCase(subSection)} Risk Measurement Indicators
      </Typography.Title>
      <MeasurementIndicatorsView
        onValueChange={onValueChange}
        factors={factors || []}
        overallData={overallData}
        subSection={subSection}
        hasPermission={hasPermission}
        errors={sectionErrors}
      />
    </div>
  );
};

MeasurementIndicatorsContainer.propTypes = {
  subSection: PropTypes.string,
};

export default MeasurementIndicatorsContainer;
