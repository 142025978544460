import React, {Fragment, useState} from "react";
import {Button, PageHeader, Divider, Typography, Form, Input, Select, Upload, Steps, Card} from "antd";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import { InboxOutlined } from '@ant-design/icons';

import menuData from "../../../../../../layout/dashboardManagement/menuData";
import ResultNotificationModal from "../../../../../common/resultNotification/ResultNotificationModal";
import FormLayout from "../../../../common/formLayout/FormLayout";
import FieldMappingForm from "./partials/FieldMappingForm";

const {Title, Paragraph} = Typography;
const {Option} = Select;
const { Dragger } = Upload;
const { Step } = Steps;

const SourceForm = ({form}) => {
    const history = useHistory();
    const [current, setCurrent] = useState(0);
    const [visibleResult, setVisibleResult] = useState({status:false, data:{}});

    const nextStep = () => {
        setCurrent(current + 1);
    };

    const prevStep = () => {
        setCurrent(current - 1);
    };

    const onTestConnectionClick = () => {
        setVisibleResult({status:true, data:{
                status:"success",
                title: "Connection Success",
                subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.",
                primaryButtonText: "Done",
                primaryButtonClick: () => {setVisibleResult({status: false, data: {}})},
                secondaryButtonProps: {style:{display:"none"}},
            }})
    }

    const renderForm = () => {
        return (
            <div className="dm-ac-form">
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        label="Connection Name"
                        name="connectionName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input connection name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Connection Type"
                        name="connectionType"
                        required={true}
                    >
                        <Select defaultValue="canvas" style={{ width: '100%' }}>
                            <Option value="canvas">Canvas</Option>
                            <Option value="mysql">MySQL</Option>
                            <Option value="csv">CSV</Option>
                            <Option value="naplan">Naplan</Option>
                            <Option value="jobReady">Job Ready</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="API url"
                        name="apiUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Please input API URL',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Database"
                        name="database"
                        rules={[
                            {
                                required: true,
                                message: 'Please input database',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>



                </Form>
                <ResultNotificationModal isModalVisible={visibleResult.status} {...visibleResult.data}/>
            </div>
        )
    }

    const renderInfo = () => (
        <div>
            <Title level={4}>How to Import Users</Title>
            <Paragraph className="description">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
            </Paragraph>
            <div className="videoCover">
                <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/AKU7CN4huYw"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
            </div>
        </div>
    )

    const renderStepOne = () => (
        <div>
            <FormLayout
                cardStyles={{}}
                form={renderForm()}
                infoContent={renderInfo()}
            />

        </div>
    )

    const renderStepTwo = () => (
        <div>
            <FieldMappingForm/>
        </div>
    )

    const steps = [
        {
            key: 1,
            content: renderStepOne(),
        },
        {
            key: 2,
            content: renderStepTwo(),
        },

    ];

    return (
        <Fragment>
            <PageHeader
                className="site-page-header"
            />
            <Card style={{margin: "1% 10%"}}>
                <div style={{ textAlign:"center", margin:"auto"}}>
                    <Title level={4}>Add Data Source</Title>
                    <div style={{width: "60%", margin:"auto"}}>
                        <Steps style={{width: "40%", margin:"auto"}} current={current}>
                            {steps.map(item => (
                                <Step key={item.key} />
                            ))}
                        </Steps>
                    </div>
                    <br/>
                    <div style={{ textAlign:"left"}} className="steps-content">{steps[current].content}</div>

                    <Divider/>
                    <div className="steps-action">
                        <div style={{float:"left"}}>
                            {current > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={prevStep}>
                                    Back
                                </Button>
                            )}
                            {current === 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => history.push(menuData.userImport.url)} >
                                    Back
                                </Button>
                            )}
                        </div>
                        <div className="dm-ac-form-buttons">
                            {current === 0 && (
                            <Button type="primary" htmlType="submit" ghost onClick={onTestConnectionClick}>Test Connection</Button>
                            )}

                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={nextStep}>
                                    Next
                                </Button>
                            )}

                            {current === steps.length - 1 && (
                                <Button type="primary" >
                                    Import
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

            </Card>


        </Fragment>
    )
}

SourceForm.propTypes = {
    form: PropTypes.object
}

export default SourceForm;