import {
    FETCH_STATS_REPORT_DATA_LOADING,
    FETCH_STATS_REPORT_DATA,
} from "../../constant/actionTypes";

const statsReducerDefaultState = {
    reportData: {
        isLoading:false,
        data: [],
        error: false,

    },
};

const statsReducer = (state = statsReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_STATS_REPORT_DATA_LOADING:
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    ...action.payload
                }
            }
        case FETCH_STATS_REPORT_DATA:
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default statsReducer;