import React from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';

const Loader = ({children, isLoading, isLoaderText, loaderText}) => {
    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
    return (
        <div>
            <Spin className={'in-loader'} indicator={antIcon} spinning={isLoading}>
                {children}
            </Spin>
            {isLoaderText && <div className={'in-loader-text'}>{loaderText}</div>}
        </div>
    )
}

Loader.defaultProps = {
    isLoading: false,
    isLoaderText: false,
    loaderText: ""
}

Loader.propTypes = {
    isLoading: PropTypes.bool,
    isLoaderText: PropTypes.bool,
    loaderText: PropTypes.string
}

export default Loader;