import {
    FETCH_DEALERSHIP_SIDEBAR_DATA,
    FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING,
    FETCH_DEALERSHIP_MAIN_REPORT_DATA,
    FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING,
    SET_DEALERSHIP_FILTERED_SIDEBAR_DATA,
    CLEAR_DEALERSHIP_SIDEBAR_DATA,
} from "../../constant/actionTypes";

const dealershipReducerDefaultState = {
    sidebar: {
        lists: {
            isLoading:false,
            error: false,
            data: {
                dealerships: []
            }
        },
        filtered: {
            data: {
                national: [],
                state: [],
                dealership: [],
                brand: [],
            }
        },
        selected: {
            data: {
                national: [],
                state: [],
                dealership: [],
                brand: [],
            },
        },
    },

    mainReport: {
        isLoading:false,
        error: false,
        data: {}
    }
};

const dealershipReducer = (state = dealershipReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_DEALERSHIP_MAIN_REPORT_DATA_LOADING:
            return {
                ...state,
                mainReport: {
                    ...state.mainReport,
                    ...action.payload
                }
            }
        case FETCH_DEALERSHIP_MAIN_REPORT_DATA:
            let payload;
            if(action.payload.data){
                 const {mandatoryModules, ...rest} = action.payload.data;
                 payload = rest;
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    selected: {
                        data: {...action.payload.selected}
                    }
                },
                mainReport: {
                    ...state.mainReport,
                    ...(action.payload.data && {data: {...payload}}),
                    isLoading: action.payload.isLoading
                }
            }
        case FETCH_DEALERSHIP_SIDEBAR_DATA_LOADING:
        case FETCH_DEALERSHIP_SIDEBAR_DATA:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    lists: {
                        ...state.sidebar.lists,
                        ...action.payload
                    }
                }
            }
        case CLEAR_DEALERSHIP_SIDEBAR_DATA:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    lists: {
                        ...state.sidebar.lists,
                        data: {
                            dealerships: []
                        }
                    }
                }
            }
        case SET_DEALERSHIP_FILTERED_SIDEBAR_DATA:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    filtered: {
                        ...state.sidebar.filtered,
                        data: {
                            ...state.sidebar.filtered.data,
                            ...action.payload.data
                        }
                    },
                    ...(action.payload.selected && {
                        selected: {
                            ...state.sidebar.selected,
                            data: {...action.payload.selected}
                        }
                    })
                }
            }
        default:
            return state;
    }
};

export default dealershipReducer;