import { combineReducers } from 'redux';
import analysis from './analysis';
import dashboardManagement from './dashboardManagement';
import rto from './rto.reducer';

const vetTrackReducers = combineReducers({
  analysis,
  dashboardManagement,
  rto,
});

export default vetTrackReducers;