import {notification} from "antd";
import session from "./session";
import {reactLocalStorage} from "reactjs-localstorage";
import impressionHandler from "./impressionHandler/impressionHandler";

const make = (requestOptions) => {
    const {
        service,
        baseUrl,
        onSuccess,
        onError,
        data,
        headers,
        authorized = false,
        redirection = true,
        parseData = true,
        ...rest
    } = requestOptions;
    const url = baseUrl ? baseUrl + service : process.env.REACT_APP_APIURL + service;
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
        },
        body: data && parseData ? JSON.stringify(data) : data,
        credentials: "include",
        ...rest
    };

    if (authorized) {
        options.headers.Authorization = session.getAuthToken();
    }

    if (headers && headers['Content-Type'] === 'unset') {
        delete options.headers['Content-Type']
    }

    fetch(url, options).then(response => {
        if ([401, 403].includes(response.status)){
            // redirect to login
            reactLocalStorage.remove('subUser');
            if(redirection){
                window.location.href = `/setup/login`;
            }
            throw new Error(response.statusText)
        }
        return response.json();
    }).then(parsed => {
        const {error} = parsed
        if (error) {
            showError(parsed);
        }
        onSuccess && onSuccess({
            isLoading: false,
            error,
            response: parsed
        });
    }).catch(error => {
        onError && onError({
            isLoading: false,
            error,
            response: undefined,
        });
    })

}

const showError = (response) => {

    let title = response.message;
    let description = response.errors && response.errors[0] && response.errors[0].msg;

    // translate errors for humans
    switch (description) {
      // account already exists
      case "email already exists":
        title = "You have already registered using this email.";
        description =
          "Please contact Octopus BI support (support@octopusbi.com) to complete your registration.";
        break;
    }

    notification.error({message: title, description});
}

const request = {
    make,
};

export default request;