import React, {Fragment} from "react";
import CountUp from "react-countup";
import { DollarSign, Tag } from "react-feather";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Xarrow from "react-xarrows";
import Helper from "./../common/Helper";

HC_more(Highcharts);

const AreaGraphComponent = ({
  id,
  chartOptions,
  previousValue,
  value,
  difference,
  valueFormat
}) => {
  const boxStyle = {
    border: "grey solid 2px",
    borderRadius: "10px",
    padding: "5px",
  };

  const isNegative = (previous, current, mode) => {
    if(previous > current){
      if(["IN","AS"].indexOf(id) > -1){
        return true;
      }else{
        if(mode == "arrow") return true; else return false;
      }
    }else{
      if(["IN","AS"].indexOf(id) > -1){
        return false;
      }else {
        if(mode == "arrow") return false; else return true;
      }
    }
  }

  return (
    <div className="chart-widget-dashboard">
      {!difference && difference !== 0 ? <div className={`text-center box`}><h4>No Data</h4></div> : 
      <Fragment>
      <div className="media">
        <div className="media-body">
          <div className="row">
            <div className="col-xl-6">
                  <span className="prev-month-value low-hilite">
                    <p className="headingSmall">PREVIOUS MONTH:</p>
                    <h5 className="mt-0 mb-10 f-w-600">
                      <DollarSign />
                      {/* <span className="counter" data-toggle="tooltip" data-placement="top" title={previousValue < 0 ? `$(${Helper.numberFormat(Math.abs(previousValue),)})`: `$${Helper.numberFormat(previousValue)}`}> */}
                      <span className="counter">
                        {previousValue < 0 ? `(${Helper.setMetricSuffix(Math.abs(previousValue), valueFormat)})` : Helper.setMetricSuffix(previousValue, valueFormat)}
                      </span>
                    </h5>
                  </span>
              </div>
              <div className="col-xl-6">
                  <span className={`prev-month-value right-text ${isNegative(previousValue, value, "value") ? 'down' : ''}`}>
                    <p className="headingSmall">THIS MONTH:</p>
                    <h5 className="mt-0 mb-10 f-w-600 ">
                      <DollarSign />
                      {/* <span className="counter" data-toggle="tooltip" data-placement="top" title={value < 0 ? `$(${Helper.numberFormat(Math.abs(value))})` : `$${Helper.numberFormat(value)}`}> */}
                      <span className="counter">
                        {value < 0 ? `(${Helper.setMetricSuffix(Math.abs(value), valueFormat)})` : Helper.setMetricSuffix(value, valueFormat)}
                      </span>
                      <i className={`fa ${isNegative(previousValue, value, "arrow") ? 'fa-arrow-down' : 'fa-arrow-up' }`}></i>
                    </h5>
                    <p className="diffrence"> <span>({difference > 0 ? `+${Helper.setMetricSuffix(difference, valueFormat)}` : Helper.setMetricSuffix(difference, valueFormat)})</span></p>
                  </span>
              </div>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          height={240}
        />
      </div>
      </Fragment> }
    </div>
  );
};

export default AreaGraphComponent;
