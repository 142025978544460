import React from "react";
import {Form, Button, Select} from "antd";
import PropTypes from "prop-types";

import CustomRangePicker from "../../common/CustomRangePicker";
import menuData from "../../../../../layout/dashboardManagement/menuData";

const { Option } = Select;

const StatFilters = ({form, filterSubmit}) => {
    const urlPathName = window.location.href;
    const isSalesAdminUrl = urlPathName?.includes(menuData.statsDataViewSales.url);

    return (
        <div className="stat-data-view-filters">
            <Form
            layout="inline"
            form={form}
            onFinish={filterSubmit}
        >

            <CustomRangePicker label="Date Range" required={true}/>
            {/*{isSalesAdminUrl &&*/}
            {/*<Form.Item label="Project" name="project">*/}
            {/*    <Select defaultValue="all" style={{width: 120}}>*/}
            {/*        <Option value="all">All</Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}
            {/*}*/}
            {/*<Form.Item label="School" name="school">*/}
            {/*    <Select defaultValue="all" style={{ width: 120 }}>*/}
            {/*        <Option value="all">All</Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item label="Dashboard" name="dashboard">*/}
            {/*    <Select defaultValue="all" style={{ width: 120 }}>*/}
            {/*        <Option value="all">All</Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}
            <Form.Item>
                <Button type="primary" htmlType="submit">Apply</Button>
            </Form.Item>
        </Form>
        </div>
    )
}

StatFilters.propTypes = {
    form: PropTypes.object,
    filterSubmit: PropTypes.func,
}
export default StatFilters;