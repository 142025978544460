import {
    SET_LAYOUT_BREADCRUMB,
    APPEND_TO_LAYOUT_BREADCRUMB,
    REPLACE_LAYOUT_BREADCRUMB_SEGMENT,
    SET_LAYOUT_QUERY_PARAMS,
    SET_TOP_NAV_PARAM,
    SET_SELECTED_NAVIGATION_TYPE,
    SET_INNOVATIVE_SIDEBAR,
} from "../../constant/actionTypes";

const layoutReducerDefaultState = {
    breadcrumb: {
        updatedAt: new Date().getTime(),
        segments: [{
            key: 'home',
            section: "Home"
        }]
    },
    topNav: {
        settings: null
    },
    queryParams: {},
    selectedNavigationType: 'home',
    sidebar:{
        collapsed: false,
    }
};

const layoutReducer = (state = layoutReducerDefaultState, action) => {
    switch (action.type) {
        case SET_INNOVATIVE_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...action.payload
                },
            };
            case SET_LAYOUT_BREADCRUMB:
            return {
                ...state,
                breadcrumb: {
                    updatedAt: new Date().getTime(),
                    segments: action.payload,
                },
            };
        case SET_SELECTED_NAVIGATION_TYPE:
            return {
                ...state,
                selectedNavigationType: action.payload,
            };
        case APPEND_TO_LAYOUT_BREADCRUMB:
            return {
                ...state,
                breadcrumb: {
                    updatedAt: new Date().getTime(),
                    segments: [
                        ...state.breadcrumb.segments,
                        action.payload,
                    ]
                }
            }
        case REPLACE_LAYOUT_BREADCRUMB_SEGMENT:
            // if index is not mentioned specifically, replace the last
            const index = action.payload.index ?? (state.breadcrumb.segments.length - 1);
            let segments = state.breadcrumb.segments;
            segments[index] = action.payload.segment;
            return {
                ...state,
                breadcrumb: {
                    updatedAt: new Date().getTime(),
                    segments,
                }
            }
        case SET_LAYOUT_QUERY_PARAMS:
            let queryParams = {};
            queryParams[action.payload.tag] = {
                ...state.queryParams[action.payload.tag],
                ...action.payload.queryParams,
            }
            return {
                ...state,
                queryParams
            }
        case SET_TOP_NAV_PARAM:
            return {
                ...state,
                topNav: {
                    ...state.topNav,
                    [action.payload.tag]: action.payload.value
                }
            }
        default:
            return state;
    }
};

export default layoutReducer;