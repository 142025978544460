import React from "react";
import {Form, Input, Modal, Row, Col, Typography} from "antd";
import PropTypes from "prop-types";

const UserModal = ({isVisible, handleOk, handleCancel, form, onFormSubmit}) => {

    return (
        <Modal title="User Management" visible={isVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false} okText="Add">
            <Form
                form={form}
                name="basic"
                onFinish={onFormSubmit}
                layout="vertical"
                initialValues={{ resetType: 'email' }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                                { required: true, message:"First Name is required"},
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true, message:"Email is required"},
                                { type: "email", message:"Not a valid email" },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                                { required: true, message:"Last Name is required"},
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>



                <Typography.Title level={5}>Set Temporary Password</Typography.Title>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        { required: true, message:"Password is required"},
                        {
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*\\s)(?=.*[!,@,#,$,%,^,&,*,(,),])",
                            message: "Password should contain at least one uppercase, lowercase letter, digit, symbol and cannot contain whitespaces",
                        }
                    ]}>
                    <Input.Password />
                </Form.Item>

                <Typography.Title level={5}>Set Permissions</Typography.Title>
                {/*  TODO: need to build  */}

            </Form>
        </Modal>
    )
}

UserModal.propTypes = {
    isVisible: PropTypes.bool,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
}

export default UserModal;