import React, {Fragment, useState} from "react";
import {Modal, Radio, Space} from "antd";
import PropTypes from "prop-types";

const SettingApplyPopup = ({showModal, setIsModalVisible, isModalVisible, saveSettingsData}) => {
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const [applyScope, setApplyScope] = useState("me");

    const handleMainPopupOk = () => {
        switch (applyScope) {
            case "me":
                saveSettingsData("me");
                break;
            case "all":
               showWarningModal()
        }
        setIsModalVisible(false)
    }

    const showWarningModal = () => {
        setIsWarningVisible(true);
    };

    const handleSubmit = () => {
        saveSettingsData("all")
        setIsWarningVisible(false);
    };

    const handleWarningCancel = () => {
        setApplyScope("me");
        setIsWarningVisible(false);
        showModal();
    };

    return (
        <Fragment>
            <Modal
                title="Apply Settings"
                visible={isModalVisible}
                onOk={handleMainPopupOk}
                okText="Apply"
                onCancel={() => setIsModalVisible(false)}
            >
                <Radio.Group onChange={e => setApplyScope(e.target.value)} defaultValue={applyScope}>
                    <Space direction="vertical">
                        <Radio value="me">Apply these settings to this account only</Radio>
                        <Radio value="all">Apply these settings to all accounts</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
            <Modal
                title="Do you wish to proceed?"
                visible={isWarningVisible}
                onOk={handleSubmit}
                onCancel={handleWarningCancel}
                okText="Yes"
                cancelText="No"
            >
                These changes will be applied to all users. Your changes will be overridden if another user changes these settings while the sync is in progress
            </Modal>
        </Fragment>

    )
}

SettingApplyPopup.propTypes = {
    showModal: PropTypes.func,
    setIsModalVisible: PropTypes.func,
    isModalVisible: PropTypes.bool,
    saveSettingsData: PropTypes.func
}

export default SettingApplyPopup;