export const FILTER_ASSIGNMENT_GROUP = 'assignment_group';
export const FILTER_MODULES = 'modules';
export const ASSIGNMENT_FILTERS = [
  { value: FILTER_ASSIGNMENT_GROUP, label: 'Assignment Group' },
  { value: FILTER_MODULES, label: 'Modules' },
];

// Help Center
export const HELP_CENTER_URL =
  'https://octopus-bi.atlassian.net/wiki/spaces/CL/overview?homepageId=1791623407';
export const HELP_FEEDBACK_MAX_ATTACHMENT_COUNT = 1;
export const HELP_FEEDBACK_ATTACHMENT_MAX_SIZE = 5242880;
export const HELP_FEEDBACK_SUPPORTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
];
export const ENROLLMENT_STATUSES = [
  'active',
  'completed',
];

export const STUDENT_CLUSTER_ANALYZER_DESCRIPTION =
  `This advanced feature is powered by OctopusAI. Student Insights utilizes natural 
  language processing to extract valuable patterns and trends from anonymized student data. 
  By analyzing and interpreting this data, we gain a deeper understanding of student 
  behaviors and needs. Privacy is our top priority, and we strictly adhere to data protection 
  protocols. No personally identifiable information (PII) is shared with AI services during this process.`;

export const STUDENT_CLUSTER_ANALYZER_DATA_USE =
  `It's important to emphasize that we do not provide any personally identifiable information (PII) to OctopusAI. 
  We handle all data securely and comply with data protection regulations, ensuring the highest data standards of 
  privacy and confidentiality are maintained. Through responsible data usage, we provide personalized support 
  while safeguarding individual privacy.`;

export const STUDENT_CLUSTER_ANALYZER_VIDEO =
  'https://www.youtube.com/embed/MxVQPr0Ma6k';