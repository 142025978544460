import React from "react";
import AccStudentProfile from "./AccStudentProfile";
import StudentProfile from "./StudentProfile";
import { withRouter } from 'react-router-dom'

const Student = (props) => {
    const params = new URLSearchParams(props.location.search);
    if(params.get('profile') == 'acc'){
        return (
            <AccStudentProfile location={props.location}/>
        );
    }else{
        return (
            <StudentProfile location={props.location}/>
        );
    }
};

export default withRouter(Student);