import {
  VTT_DATA_SOURCES_OPTIONS_FETCH,
  VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING,
} from '../../../../constant/actionTypes';
import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';

/** Fetch data sources drawer options * */
// fetch data sources drawer options loading
const fetchDrawerOptionsLoading = (isLoading = true, type) => dispatch => {
  dispatch({
    type: VTT_DATA_SOURCES_OPTIONS_FETCH_LOADING,
    payload: { isLoading, type },
  });
};

// fetch data sources drawer options handler
const fetchDrawerOptionsDataStatus = (response, type) => dispatch => {
  dispatch({
    type: VTT_DATA_SOURCES_OPTIONS_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && response.data),
      type,
    },
  });
};

// fetch data sources drawer options
export const fetchDrawerOptions = data => dispatch => {
  dispatch(fetchDrawerOptionsLoading(true, data.statusType));

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/settings/fetchStatus`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchDrawerOptionsDataStatus(
          {
            data: response.data,
          },
          data.statusType,
        ),
      );
    })
    .catch(error => {
      dispatch(
        fetchDrawerOptionsDataStatus(
          {
            error: error.message,
            data: { options: [] },
          },
          data.statusType,
        ),
      );
    });
};
