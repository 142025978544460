import { combineReducers } from 'redux';
import layout from './layout.reducer';
import user from './userManagement/index';
import dashboards from './myDashboards.reducer';

const dashboardManagementReducers = combineReducers({
  layout,
  dashboards,
  user,
});

export default dashboardManagementReducers;
