import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import one from '../../../../assets/images/ecommerce/product-table-2.png';
import two from '../../../../assets/images/ecommerce/02.jpg';
import three from '../../../../assets/images/ecommerce/03.jpg';

class Carousal extends Component {
  render() {
    var settings = {
      slidesToShow: 1,
      swipeToSlide: false,
      arrows: true,
      dots: false,
    };

    return (
      <div>
        <hr />
        <h6 className="f-w-600">New Dashboards</h6>
        <div className="product-filter pb-0 new-products">
          <div className="owl-carousel owl-theme" id="testimonial">
            <Slider {...settings}>
              <div className="item">
                <div className="product-box row">
                  <div className="product-img col-md-6">
                    <img className="img-fluid" src={one} alt="" data-original-title="" title="" />
                  </div>
                  <div className="product-details col-md-6 text-left">
                    <span>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning"></i>
                    </span>
                    <p className="mb-0">Student Profile</p>
                    <div className="product-price"> </div>
                  </div>
                </div>
                <div className="product-box row">
                  <div className="product-img col-md-6">
                    <img className="img-fluid" src={one} alt="" data-original-title="" title="" />
                  </div>
                  <div className="product-details col-md-6 text-left">
                    <span>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning"></i>
                    </span>
                    <p className="mb-0">Parent Dashboard</p>
                    <div className="product-price"></div>
                  </div>
                </div>
                <div className="product-box row">
                  <div className="product-img col-md-6">
                    <img className="img-fluid" src={one} alt="" data-original-title="" title="" />
                  </div>
                  <div className="product-details col-md-6 text-left">
                    <span>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning mr-1"></i>
                      <i className="fa fa-star font-warning"></i>
                    </span>
                    <p className="mb-0">Student Engagement Dashboard</p>
                    <div className="product-price"></div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="product-box row">
                  <div className="product-img col-md-6"><img className="img-fluid" src={one} alt="" data-original-title="" title="" /></div>
                  <div className="product-details col-md-6 text-left"><span><i className="fa fa-star font-warning mr-1"></i><i className="fa fa-star font-warning mr-1"></i><i className="fa fa-star font-warning mr-1"></i><i className="fa fa-star font-warning mr-1"></i><i className="fa fa-star font-warning"></i></span>
                    <p className="mb-0">Student Dashboard</p>
                    <div className="product-price"></div>
                  </div>
                </div>
                
               
              </div>
            </Slider>

          </div>
        </div>
      </div>
    );
  }
}

export default Carousal