import React, {memo} from "react";
import {Typography} from "antd";
import PropTypes from "prop-types";

import PieChart from "../../../../../../../../../../common/charts/PieChart";
import NAMessage from "../../../../../../../../NAMessage";

import {chartConf} from "./chartConf";

const ChartCard = ({name, percentage, data, enabled}) => {
    const titleData = {title: {
            text: percentage && `${percentage}%`,
            verticalAlign: 'middle',
            style: {
                paddingTop: "3px"
            },
            useHTML:true
        }}

    const seriesData = {
        series: [{
            colorByPoint: true,
            states: {
                hover: {
                    enabled: false,
                    brightness:0,
                }
            },
            data
        }]
    }

    return (
        <div>
            <Typography.Text>{name}</Typography.Text>
            {enabled?
                <PieChart chartOptions={{...chartConf, ...titleData, ...seriesData}}/>
                :
                <NAMessage contentStyles={{padding: "49px 0"}}/>
            }
        </div>
    )
}

ChartCard.defaultProps = {
    enabled: true,
}

ChartCard.propTypes = {
    name: PropTypes.string.isRequired,
    percentage: PropTypes.string.isRequired,
    data: PropTypes.array,
}

export default memo(ChartCard);