import React from 'react';
import { useHistory } from "react-router-dom";
import {Button, Modal, Space} from "antd";
import {DeleteOutlined, DownloadOutlined, EditOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const ActionCellRenderer = ({row, setKeyRegenerateModalStatus, onDeleteConfirm}) => {
    const history = useHistory();
    const getLicenseButtonValue = (status) => {
        switch (status){
            case 0:
                // status -> Trial
                return "Go Premium"
            case 2:
                // status -> Trail end
                return "Upgrade"
            case 3:
                // status -> premium end
                return "Renew"
        }
    }
    return (
        <Space>
            {row["license_status"] !== 1 &&
            <Button
                htmlType="button"
                onClick={() => history.push(`pricing/${row["consumer_guid"]}`)}>
                {getLicenseButtonValue(row["license_status"])}
            </Button>
            }
            <Button
                htmlType="button"
                icon={<DownloadOutlined/>}
                onClick={() => setKeyRegenerateModalStatus({
                    visible: true,
                    key: row["consumer_guid"],
                })}>
                Regenerate Key
            </Button>

            <Link
                to={{
                    pathname: `/setup/consumer/edit/${row["consumer_guid"]}`
                }}>
                <Button
                    htmlType="button"
                    icon={<EditOutlined/>}/>
            </Link>
            <Button
                danger
                htmlType="button"
                icon={<DeleteOutlined/>}
                onClick={() => onDeleteConfirm(row["consumer_guid"])}/>
        </Space>
    );
};

export default ActionCellRenderer;