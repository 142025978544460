import React, {Fragment, useState} from "react";
import {Button, Card, Col, PageHeader, Row, Divider, Typography, Form, Input, Select, Upload, Radio} from "antd";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import { InboxOutlined } from '@ant-design/icons';

import menuData from "../../../../../layout/dashboardManagement/menuData";
import ResultNotificationModal from "../../../../common/resultNotification/ResultNotificationModal";
import FormLayout from "../../../common/formLayout/FormLayout";

const {Title, Paragraph} = Typography;
const {Option} = Select;
const { Dragger } = Upload;

const SourceForm = ({form, updateMode}) => {
    const history = useHistory();
    const [conType, setConType] = useState('canvas');
    const [visibleResult, setVisibleResult] = useState({status:false, data:{}});

    const onChangeType = (e) => {
        setConType(e)
    }

    const updateOptions = [
        { label: 'Append Data', value: 'append' },
        { label: 'Replace Data', value: 'replace' },
        { label: 'Merge Data', value: 'merge' },
    ];

    const onTestConnectionClick = () => {
        setVisibleResult({status:true, data:{
                status:"success",
                title: "Connection Success",
                subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.",
                primaryButtonText: "Done",
                secondaryButtonText: "Change",
                primaryButtonClick: () => {setVisibleResult({status: false, data: {}})},
                secondaryButtonClick: () => {setVisibleResult({status: false, data: {}})},
            }})
    }

    const renderForm = () => {
        return (
            <div className="dm-ac-form">
                <Title level={4}>Add Data Source</Title>
                <Paragraph className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc urna nunc, rhoncus et metus et, consequat lobortis metus.
                </Paragraph>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        label="Connection Name"
                        name="connectionName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input connection name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Connection Type"
                        name="connectionType"
                        required={true}
                    >
                        <Select defaultValue="canvas" style={{ width: '100%' }} onChange={e => onChangeType(e)}>
                            <Option value="canvas">Canvas</Option>
                            <Option value="mysql">MySQL</Option>
                            <Option value="csv">CSV</Option>
                            <Option value="naplan">Naplan</Option>
                            <Option value="jobReady">Job Ready</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="API url"
                        name="apiUrl"
                        hidden={conType === 'naplan'}
                        rules={[
                            {
                                required: conType !== 'naplan',
                                message: 'Please input API URL',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Database"
                        name="database"
                        hidden={conType === 'naplan'}
                        rules={[
                            {
                                required: conType !== 'naplan',
                                message: 'Please input database',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {updateMode &&
                    <Form.Item
                        name="updateType"
                        rules={[
                            {
                                required: updateMode === true,
                                message: 'Please select an update method',
                            },
                        ]}
                    >
                         <Radio.Group options={updateOptions}/>
                    </Form.Item>
                        }
                    <Form.Item
                        name="uploadedCsv"
                        hidden={conType !== 'naplan'}
                        rules={[
                            {
                                required: conType === 'naplan',
                                message: 'Please upload a file',
                            },
                        ]}
                    >
                        <Dragger>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload CSV</p>
                        </Dragger>
                    </Form.Item>
                    <Divider/>
                    <Button onClick={() => history.push(menuData.manageDataSource.url)}>Back</Button>
                    <div className="dm-ac-form-buttons">
                        <Button type="primary" htmlType="submit" ghost onClick={onTestConnectionClick}>Test Connection</Button>
                        &nbsp;
                        <Button disabled>Done</Button>
                    </div>

                </Form>
                <ResultNotificationModal isModalVisible={visibleResult.status} {...visibleResult.data}/>
            </div>
        )
    }

    const renderInfo = () => {
        switch (conType) {
            case "canvas":
                return (
                    <div>
                        <Title level={4}>How to add canvas data</Title>
                        <Paragraph className="description">
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                        </Paragraph>
                        <div className="videoCover">
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube.com/embed/AKU7CN4huYw"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                    </div>
                )
            case "naplan":
                return (
                    <div>
                        <Title level={4}>How to add Naplan</Title>
                        <Paragraph className="description">
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                        </Paragraph>
                        <div className="videoCover">
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube.com/embed/AKU7CN4huYw"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                        <Button type="primary" ghost>Download Sample File</Button>
                    </div>
                )

        }

    }

    return (
        <Fragment>
            <PageHeader
                className="site-page-header"
            />
            <FormLayout
                form={renderForm()}
                infoContent={renderInfo()}
            />
        </Fragment>
    )
}

SourceForm.defaultProps = {
    updateMode: false
}

SourceForm.propTypes = {
    form: PropTypes.object,
    updateMode: PropTypes.bool
}

export default SourceForm;