import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";

import DataTable from "./DataTable";

const DataTableContainer = ({data, ...props}) => {
    const {students} = {...data};
    const [page, setPage] = useState(1);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [filterMeta, setFilterMeta] = useState({
        name: [],
        role: [],
        status: [],
        dealership: [],
    })
    const [tableMeta, setTableMeta] = useState({
        filteredInfo: {},
        current: students,
    });

    useEffect(() => {
        if(students){
            setFilteredStudents(students);
            setTableMeta({...tableMeta, filteredInfo: {}, current: students});
        }
    }, [students])

    useEffect(() => {
        if(tableMeta.current){
            setFilterMeta({
                name: prepareFilterValues(tableMeta.current, "name", "name"),
                role: prepareFilterValues(tableMeta.current, "role", "role"),
                status: prepareFilterValues(tableMeta.current, "status", "status"),
                dealership: prepareFilterValues(tableMeta.current, "dealership", "dealership"),
            });
        }
    }, [tableMeta.current])

    const handleChangeTable = (pagination, filters, sorter, extras) => {
        const {currentDataSource} = extras;
        setTableMeta({
            filteredInfo: filters,
            sortedInfo: sorter,
            current: currentDataSource,
        });
    };


    const prepareFilterValues = useCallback((dataset, uniqKey, valueProp) => {
        if (Array.isArray(dataset)){
            return [...new Map(dataset.map(item =>
                [item[uniqKey], {text:item[valueProp], value:item[uniqKey]}])).values()];
        }
        return []
    }, [tableMeta.current]);


    const columns = [
        {
            title: 'Student',
            dataIndex: 'name',
            key: 'name',
            filters: filterMeta.name,
            onFilter: (value, record) => record.name === value,
            sorter: (a, b) => a.name && a.name.localeCompare(b.name),
            filteredValue: tableMeta.filteredInfo.name || null,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: filterMeta.role,
            onFilter: (value, record) => record.role === value,
            sorter: (a, b) => a.role && a.role.localeCompare(b.role),
            filteredValue: tableMeta.filteredInfo.role || null,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: filterMeta.status,
            onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a.status && a.status.localeCompare(b.status),
            filteredValue: tableMeta.filteredInfo.status || null,
        },
        {
            title: 'Dealership',
            dataIndex: 'dealership',
            key: 'dealership',
            filters: filterMeta.dealership,
            onFilter: (value, record) => record.dealership === value,
            sorter: (a, b) => a.dealership && a.dealership.localeCompare(b.dealership),
            filteredValue: tableMeta.filteredInfo.dealership || null,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => moment(a.startDate) - moment(b.startDate),
            align: "right"
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => moment(a.endDate) - moment(b.endDate),
            align: "right"
        },
    ];

    const pagination = {
        current: page,
        onChange: setPage,
        total: filteredStudents,
        showSizeChanger: false
    };

    const onSearch = (event) => {
        const {value} = event.target;
        if  (value){
            const filtered = students.filter(student => {
                return student['name'].toLowerCase().includes(value.toLowerCase());
            });
            setFilteredStudents(filtered);
            setTableMeta({...tableMeta, current: filtered})
        }else {
            setFilteredStudents(students);
            setTableMeta({...tableMeta, current: students})
        }
    }


    // on data source change, table pagination will be reset to 1
    useEffect(() => {
        setPage(1);
    }, [filteredStudents]);

    return (
        <DataTable
            columns={columns}
            data={{...data, students: filteredStudents}}
            pagination={pagination}
            onSearch={onSearch}
            {...props}
            handleChangeTable={handleChangeTable}
        />
    )
}

export default DataTableContainer;