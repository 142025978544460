import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';

import {
  getDataSourcesFetchDataLoading,
  getDataSourcesSaveLoading,
  getDataSourcesDataResponse,
  getPermissionDataByPath,
  makeDataSourceSyncStatus,
} from '../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchSettings,
  saveSettings,
  fetchSectionSyncStatus,
} from '../../../../../actions/vetTrack/analysis/settings/commonSettings.action';

import { settingsSections } from '../../../../../constant/vetTrack/analysis';
import { syncCheckInterval } from '../../../../../constant/vetTrack/settings';
import DataSourcesView from './DataSourcesView';

const DataSourcesContainer = () => {
  const dispatch = useDispatch();

  // selectors
  const fetchDataLoading = useSelector(getDataSourcesFetchDataLoading);
  const saveDataLoading = useSelector(getDataSourcesSaveLoading);
  const dataSources = useSelector(getDataSourcesDataResponse);
  const isAdmin = useSelector(getPermissionDataByPath('isAdmin'));
  const syncData = useSelector(makeDataSourceSyncStatus);
  const isSyncing = _get(syncData, 'isSyncing', false);

  // state
  const [lastSync, setLastSync] = useState(null);

  const onSave = () => {
    dispatch(
      saveSettings(dataSources, 'all', settingsSections.dataSources, null, 0),
    );
  };

  // refs
  const timer = useRef(null);

  // effects
  useEffect(() => {
    dispatch(fetchSettings({}, settingsSections.dataSources));
  }, []);

  useEffect(() => {
    fetchDataSourcesStatus();
    setLastSync(Date.now());
  }, []);

  useEffect(() => {
    if (isSyncing && lastSync) {
      const timeout = setTimeout(() => {
        fetchDataSourcesStatus();
        setLastSync(Date.now());
      }, syncCheckInterval);
      timer.current = timeout;
    }

    return () => {
      clearTimeout(timer.current);
    }
    
  }, [lastSync, isSyncing]);

  const fetchDataSourcesStatus = () => {
    dispatch(fetchSectionSyncStatus({
      section: settingsSections.dataSources,
      subSectionId: 0,
    }));
  };

  return (
    <DataSourcesView
      isLoading={fetchDataLoading || saveDataLoading}
      onSave={onSave}
      isAdmin={isAdmin}
      isSyncing={isSyncing}
      syncData={syncData}
    />
  );
};

export default DataSourcesContainer;
