import React, {memo} from "react";
import {Typography} from "antd";
import PropTypes from "prop-types";

import PieChart from "../../../../../../../../../../common/charts/PieChart";
import ChartMeta from "../MetaCard";
import NAMessage from "../../../../../../../../NAMessage";

import {chartConf} from "./chartConf";

const ChartCard = ({name, percentage, metaConfig, dataset, enabled}) => {
    const titleData = {title: {
            text: percentage? `${percentage}%` : "",
            verticalAlign: 'middle',
            style: {}
        }}

    const seriesData = {
        series: [{
            colorByPoint: true,
            states: {
                hover: {
                    enabled: false,
                    brightness:0,
                }
            },
            data: dataset
        }]
    }

    return (
        <div>
            <Typography.Paragraph style={{textAlign:"center"}} strong>{name}</Typography.Paragraph>
            {enabled ?
                <PieChart chartOptions={{...chartConf, ...titleData, ...seriesData}}/>
                :
                <NAMessage/>
            }
            <div className="visualInfoBox">
                <ChartMeta {...metaConfig}/>
            </div>
        </div>
    )
}

ChartCard.defaultProps = {
    enabled: true
}

ChartCard.propTypes = {
    name: PropTypes.string.isRequired,
    percentage: PropTypes.string.isRequired,
    metaConfig: PropTypes.object,
    dataset: PropTypes.array,
}

export default memo(ChartCard);