import _get from "lodash/get";

// return domain meta object
export const getDomainData = state => _get(state, 'tentacleMeta.domainMeta');

// return domain meta isLoading
export const getDomainDataLoading = state => _get(getDomainData(state), 'isLoading');

// return domain meta object
export const getDomainMetaData = state => _get(getDomainData(state), 'data.msg');
