import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";

const PrincipalProfileWrapper = ({WrappedComponent}) => {

    return  ({interimUser, ...props}) => {
        const branch = props.location.state ? props.location.state.branch : null;

        return (
            <WrappedComponent
                {...props}
                schoolId={branch ? branch : interimUser.schoolIdArray.length > 0 ? interimUser.schoolIdArray[0] : null}
            />
        )
    }
}

const mapStateToProps = ({Authentication}) => {
    return {
        interimUser: Authentication.interimUser
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps),
    PrincipalProfileWrapper
);
