import React, { Fragment } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const BannerSection = props => {
  const {
    bannerImage,
    isFinishedLoading,
    studentName,
    term,
    semester,
    showProfileButton,
    gotoUser
  } = props;

  return (
    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
      <div className="card custom-card  ">
        <div className="row">
          <div className="col-2 col-sm-2">
            <img src={bannerImage} alt="bannerImage" />
          </div>
          <div className="col-5 col-sm-5 with-border">
            <div className="student-name">
              <h5>
                <span className="counter">STUDENT ACADEMIC REPORT</span>
              </h5>
                {isFinishedLoading && (
                  <Fragment>
                    <h4>
                      <b>{studentName}</b>
                    </h4>
                    <h6>{`${term} - Semester ${semester}`}</h6>
                    <h6 className="printed-on">
                      Printed on {moment().format("DD/MM/YYYY")}
                    </h6>
                  </Fragment>
                )}
            </div>
          </div>
          {showProfileButton && (
            <div className="col-5 col-sm-5 ">
              <button
                style={{
                  marginTop: '48px',
                  width: "80%",
                  backgroundColor:"#003f7a",
                    color:"#ffffff"
                }} 
                className="btn btn-lg" 
                onClick={gotoUser}
              >
                <FontAwesomeIcon icon={faUser} /> 
                STUDENT PROFILE
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default BannerSection;