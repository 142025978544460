import React, {Fragment} from "react";
import {Alert, Form, Input} from "antd";

const StepUrlConfig = ({readOnly}) => {
    const description = "Please enter your Canvas URL to complete the integration. Ex: yourschoolname.instructure.com"

    return (
        <Fragment>
            <Form.Item
                name="url"
                label="Enter your Canvas URL"
                rules={[
                    {required: true, message: "'Url' is required"},
                    {type: "url", message: "'Url' is not a valid url"}
                ]}>
                <Input placeholder="https://" size={"large"} readOnly={readOnly}/>
            </Form.Item>
            <Alert message="" description={description} type="info" showIcon/>
        </Fragment>
    );
};

export default StepUrlConfig;