import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input, Checkbox, Spin, Row } from 'antd';
import { get as _get } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

import { makeOnBoardingSignUpState } from '../../../selectors/vetTrack/rto.selector';
import { signUp } from '../../../actions/vetTrack/rto/onBoarding.action';
import { isEmptyValue } from '../analysis/utils/general';
import { onError, onSuccess } from '../../../utils/notificationHandler';

const { Item, useForm } = Form;
const loadingIcon = <LoadingOutlined spin />;

const RTOOnBoardingSignUp = () => {
  const [adminEmailCheck, setAdminEmailCheck] = useState(false);
  const [stagingDbCheck, setStagingDbCheck] = useState(false);
  const [signUpForm] = useForm();

  const dispatch = useDispatch();

  const rtoSignupState = useSelector(makeOnBoardingSignUpState);
  const { isDataSaving } = rtoSignupState;

  const onSubmit = data => {
    const { resetFields } = signUpForm;
    const values = Object.keys(data);
    dispatch(signUp(data, (err) => {
      if (!err) {
        resetFields(values);
        setAdminEmailCheck(false);
        setStagingDbCheck(false)
        onSuccess('Sign up success!');
        return;
      }

      onError('Sign up failed!', err);
    }));
  };

  const validatePhone = (rule, value) => {
    const phoneRegx = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    return new Promise((resolve, reject) => {
      if (isEmptyValue(value)) return resolve();

      if (phoneRegx.test(value)) {
        return resolve();
      }

      return reject();
    });
  };

  const onChangeAdminEmailCheck = event => {
    const { checked } = event.target;
    setAdminEmailCheck(checked);

    if (checked) {
      const { getFieldValue, setFieldsValue, validateFields } = signUpForm;

      const emailValue = getFieldValue('email');
      setFieldsValue({ adminEmail: emailValue });
      validateFields(['adminEmail']);
    }
  };

  const onChangeStagingDbCheck = event => {
    const { checked } = event.target;
    setStagingDbCheck(checked);

    if (checked) {
      const { getFieldValue, setFieldsValue, validateFields } = signUpForm;

      const stagingDbValue = getFieldValue('subdomain');
      setFieldsValue({ stagingdb: stagingDbValue });
      validateFields(['stagingdb']);
    }
  };

  const onFieldsChange = fields => {
    const fieldName = _get(fields, '[0].name[0]', '');
    const value = _get(fields, '[0].value', '');


    if (fieldName === 'email' && adminEmailCheck) {
      const { setFieldsValue, validateFields } = signUpForm;

      setFieldsValue({adminEmail: value});
      validateFields(['adminEmail']);
    }

    if (fieldName === 'subdomain' && stagingDbCheck) {
      const { setFieldsValue, validateFields } = signUpForm;

      setFieldsValue({stagingdb: value});
      validateFields(['stagingdb']);
    }
  };

  return (
    <div className="on-boarding-form">
      <h4>RTO Onboarding Admin Sign Up</h4>
      <Spin spinning={isDataSaving} indicator={loadingIcon}>
        <Form
          name="basic"
          onFinish={onSubmit}
          layout="vertical" 
          form={signUpForm}
          onFieldsChange={onFieldsChange}
        >
          <Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please enter your first name!',
              },
            ]}
          >
            <Input placeholder="John" />
          </Item>
          <Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please enter your last name!',
              },
            ]}
          >
            <Input placeholder="Doe" />
          </Item>
          <Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Email should be valid and not empty',
              },
            ]}
          >
            <Input placeholder="johnDoe@example.com" />
          </Item>
          <Item
            label="Admin Email"
            name="adminEmail"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Admin Email should be valid and not empty',
              },
            ]}
            className='admin-email-row'
          >
            <Input placeholder="admin@example.com" disabled={adminEmailCheck} />
          </Item>
          <Checkbox
            checked={adminEmailCheck}
            className='admin-email-check-box'
            onChange={onChangeAdminEmailCheck}
          >
            Use same email
          </Checkbox>
          <Item
            label="Contact Number"
            name="contactNumber"
            rules={[
              {
                required: true,
                message: 'Please enter contact number',
              },
              {
                validator: validatePhone,
                message: 'Invalid phone number',
              }
            ]}
          >
            <Input placeholder="+61112090354" />
          </Item>
          <Item
            label="School Name"
            name="schoolName"
            rules={[
              {
                required: true,
                message: 'Please enter name of the school',
              },
            ]}
          >
            <Input placeholder="University of Bonn" />
          </Item>
          <Item
            label="Subdomain"
            name="subdomain"
            rules={[
              {
                required: true,
                message: 'Staging db name should be valid and not empty',
              },
            ]}
          >
            <Input placeholder="example" />
          </Item>
          <Item
            label="Staging Database name"
            name="stagingdb"
            rules={[
              {
                required: true,
                message: 'Please enter staging db name',
              },
            ]}
            className='staging-db-row'
          >
            <Input placeholder="example" disabled={stagingDbCheck} />
          </Item>
          <Checkbox
            checked={stagingDbCheck}
            className='staging-db-check-box'
            onChange={onChangeStagingDbCheck}
          >
            Use same as subdomain
          </Checkbox>
          <Row style={{justifyContent:'flex-end'}}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default RTOOnBoardingSignUp;