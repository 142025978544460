import React, {memo} from "react";
import PropTypes from "prop-types";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ScrollContainer from 'react-indiana-drag-scroll';


const Pathway = ({nodes, onDataTableShowClick}) => {

    const onNodeClick = (node) => {
        onDataTableShowClick(node)
    }

    const renderIcon = (value) => (
        <div className="pathway-icon">{value}</div>
    )

    const renderNodes = () => {
        nodes.sort((a,b) => a.order - b.order);
        return (
            nodes.map(node => (
                <VerticalTimelineElement
                    key={node.order}
                    contentStyle={{ backgroundColor: '#fff !important' }}
                    date={`${node.completed} out of ${node.total}`}
                    iconClassName="pathway-icon-container"
                    iconStyle={{ background: node.completed === node.total? "#95A844" :  "#A21E4D"}}
                    icon={renderIcon(node.order)}
                >
                    <h5 className="pathway-node-content" onClick={() => onNodeClick(node)}> {node.name}({node.id})</h5>
                </VerticalTimelineElement>
            ))
        )
    }

    return (
        <div className="pathway">
            <VerticalTimeline layout="1-column-right">
                <ScrollContainer className="pathway-scroll" horizontal={false}>
                    {renderNodes()}
                </ScrollContainer>
            </VerticalTimeline>
        </div>
    )
}

Pathway.propTypes = {
    nodes: PropTypes.array,
    onDataTableShowClick: PropTypes.func,
}

export default memo(Pathway);