import React, {useEffect, useState} from "react";
import {Select, Tag} from "antd";
import PropTypes from "prop-types"

const {Option} = Select;

const CustomSelector = ({width, datalist, paramKey, value, onChange, setDefaultData, defaultValues, showTitle, ...rest}) => {
    const [values, setValues] = useState([]);

    const formatValues = () => {
        return [...datalist.map(item => item[paramKey]), 'all'];
    }

    useEffect(() => {
        if(datalist && datalist.length){
            if(setDefaultData){
                setValues(formatValues());
            }
            if(defaultValues){
                if(defaultValues.length === datalist.length) {
                    setValues(formatValues());
                }else{
                    setValues(defaultValues);
                }

            }

        }else{
            setValues([]);
        }
    }, [setDefaultData, datalist, defaultValues])


    const onSelect = (value) => {
        let recs;
        if(value === "all"){
            recs = formatValues();
        }else{
            if (values.length + 1 === datalist.length){
                recs = formatValues();
            }else {
                recs = [...values, value]
            }
        }
        setValues(recs);
        onChange && onChange(recs)
    }

    const onDeselect = (value) => {
        let recs;
        if(value === "all"){
            recs = [];
        }else {
            if(values.includes("all")) {
                recs = [...values.filter(val => val !== value && val !== "all")];
            }else {
                recs = [...values.filter(val => val !== value)];
            }
        }
        setValues(recs);
        onChange && onChange(recs)
    }

    const onClear = () => {
        setValues([]);
        onChange && onChange([])
    }

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;

        if (values.includes("all")){
            return  <Tag value={value} key={value} onClose={onClose} closable={false} >
                All
            </Tag>
        }

        return <Tag value={value} key={value} onClose={onClose} closable={closable} >
            {label}
        </Tag>
    }


    return (
        <Select
            style={{ width: width }}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 && option.value !== "all"
            }
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            tagRender={tagRender}
            maxTagTextLength={10}
            value={values}
            title={showTitle? datalist.filter(item => values.includes(item[paramKey])).map(item => item[value]).join("\n"): null}
            {...rest}
        >
            {datalist && datalist.length && <Option key="all" value="all">Select All</Option>}
            {datalist && datalist.map(item => <Option key={item[paramKey]} value={item[paramKey]}>{item[value]}</Option>)}
        </Select>
    )
}

CustomSelector.propTypes = {
    width: PropTypes.string,
    datalist: PropTypes.array,
    paramKey: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    defaultValues: PropTypes.array,
    showTitle: PropTypes.bool,
}

export default CustomSelector;