import React from "react";
import {Col, Input, Row, Typography} from "antd";
import PropTypes from "prop-types";

import {SearchOutlined} from "@ant-design/icons";

const StudentsTableHeader = ({title, searchEnabled, onSearch, dataSource, setSearchVal, searchVal}) => {
    const onChange = (e) => {
        onSearch(e.target.value);
    }

    return (
        <Row display="flex" justify="space-between" className="tableHeader">
            <Col span={10}>
                <Typography.Title level={4}>{title}</Typography.Title>
            </Col>
            <Col span={14}>
                {searchEnabled &&
                <div style={{float: "right"}}>
                    <Input addonAfter={<SearchOutlined/>} style={{maxWidth: 200}} value={searchVal} onChange={onChange} />
                </div>
                }
            </Col>
        </Row>
    )
}

StudentsTableHeader.propTypes = {
    title: PropTypes.string.isRequired,
    searchEnabled: PropTypes.bool,
    onSearch: PropTypes.func,
    dataSource: PropTypes.array,
}

export default StudentsTableHeader;