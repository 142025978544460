import React from 'react';
import moment from "moment";

const GenericCellRenderer = ({data, type = "string", options = {}}) => {

    const formatData = () => {
        // should ignore int 0 because it can be a data
        if (data === "" || data === null || data === undefined) {
            return "-";
        }

        let value;

        switch (type) {
            case "DATE":
                const dateFormat = "DD/MM/YYYY";
                value = moment(data).format(dateFormat);
                break;
            case "ENUM":
                value = options && options.enum && options.enum[data] ? options.enum[data] : "-";
                break;
            default:
                value = data;
        }

        return value;
    };

    return (
        <div>{formatData()}</div>
    );
};

export default GenericCellRenderer;