import _get from "lodash/get";

// return data sources data
export const getDataSourcesData = state => _get(state, 'jrGeneric.settings.dataSources');

export const getDataSourcesFetchDataLoading = state => _get(getDataSourcesData(state), 'selected.isLoading');
export const getDataSourcesSaveLoading = state => _get(getDataSourcesData(state), 'saved.isLoading');
export const getDataSourcesDataResponse = state => _get(getDataSourcesData(state), 'data');

// return data sources setting by path
export const getDataSourcesSettingByPath = path => state => {
    const result = getDataSourcesDataResponse(state);
    return _get(result, path, undefined);
};

export const getDrawerSelected = state => _get(getDataSourcesData(state), 'drawerSelected');
export const getDrawerSelectedLoading = state => _get(getDrawerSelected(state), 'isLoading');
export const getDrawerSelectedOptions = state => _get(getDrawerSelected(state), 'options');
export const getDrawerSelectedType = state => _get(getDrawerSelected(state), 'type');
