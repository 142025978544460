import {
    JR_GEN_OVERVIEW_CHARTS_FETCH,
    JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING,
    JR_GEN_OVERVIEW_STUDENTS_FETCH,
    JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING
} from "../../../constant/actionTypes";
import service from "../../../utils/serviceHandlers/jobReady/customServices/jrGeneric";
import {resolveResponseErrorFormat} from "../../../components/jobReadyGeneric/utils/general";

/** Fetch overview charts data **/
// fetch overview charts data loading
const fetchOverviewChartsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch overview charts data handler
const fetchOverviewChartsDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_OVERVIEW_CHARTS_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch overview charts data
export const fetchOverviewCharts = (data) => dispatch => {

    dispatch(fetchOverviewChartsLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchRiskOverview`,
        method: "POST",
        data: {
            ...data
        }
    }).then(response => {
        dispatch(fetchOverviewChartsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchOverviewChartsDataStatus(resolveResponseErrorFormat(error)))
    })
};


/** Fetch overview students data **/
// fetch overview students data loading
const fetchOverviewStudentsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING,
        payload: {isLoading},
    });
};


// fetch overview students data handler
const fetchOverviewStudentsDataStatus = (response) => dispatch => {
    dispatch({
        type: JR_GEN_OVERVIEW_STUDENTS_FETCH,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch overview students data
export const fetchOverviewStudents = (data) => dispatch => {
    dispatch(fetchOverviewStudentsLoading());

    service.make({
        service:`insights/jrp/generic/dashboards/fetchRiskOverviewStudents`,
        method: "POST",
        data: {
            ...data
        }
    }).then(response => {
        dispatch(fetchOverviewStudentsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchOverviewStudentsDataStatus(resolveResponseErrorFormat(error)))
    })
};