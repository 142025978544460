import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { Modal, Form, Radio, Input } from 'antd';

import Loader from "../../../../../common/layout/Loader";

const ResetPasswordModal = ({isVisible, handleOk, handleCancel, onFormSubmit, form, isLoading}) => {

    return (
        <Modal title="Reset Password" visible={isVisible} onOk={handleOk} onCancel={handleCancel} okText="Reset Password">
            <Loader isLoading={isLoading}/>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFormSubmit}
                layout="vertical"
                initialValues={{ resetType: 'email' }}
            >
                <Form.Item
                    name="resetType"
                    rules={[{ required: true, message: 'Select Type' }]}
                >
                    <Radio.Group>
                        <Radio value="email">Email</Radio>
                        <Radio value="tempPassword">Temporary Password</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.resetType !== currentValues.resetType}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('resetType') === 'tempPassword' ? (
                            <Fragment>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        { required: true },
                                        {
                                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*\\s)(?=.*[!,@,#,$,%,^,&,*,(,),])",
                                            message: "Password should contain at least one uppercase, lowercase letter, digit, symbol and cannot contain whitespaces",
                                        },
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    name="password2"
                                    label="Re-type Password"
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                            </Fragment>
                        ) : null
                    }
                </Form.Item>

            </Form>
            {/*</Loader>*/}
        </Modal>
    )
}

ResetPasswordModal.propTypes = {
    isVisible: PropTypes.bool,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default ResetPasswordModal;