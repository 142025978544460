import React, { Component, Fragment } from 'react';
import { Col, Row, Table } from 'antd';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import ApiServices from '../../../services/tentacle';
import accimage from '../../../assets/images/acclogo.png';
import boxplotimage from '../../../assets/images/boxplot.png';
import { withRouter } from "react-router";
import helper from '../common/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import addStock from "highcharts/modules/stock";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
var moment = require('moment-timezone');
const { Column, ColumnGroup } = Table;

addStock(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class SingleStudentProfile extends Component {
    constructor(props) {
        super(props);
        var queryx = new URLSearchParams(this.props.location.search);
        this.state = {
            isFilterTermsLoaded:false,
            isFilterSemestersLoaded: false,
            isFilterStudentsLoaded:false,
            filterTermValue:0,
            filterSemesterValue:0,
            filterStudentValue:0,
            filterYearLevelValue:0,
            filterTerms:[],
            filterStudents:[],
            filterSemesters: [],
            courseAnalysisData:[],
            logged_in: queryx.get('token'),
            token_with_id: queryx.get('t'),
            token: 'Bearer ' + queryx.get('t'),
            profile: queryx.get('profile'),
            isFirstTimeLoad:true,
            isCourseAnalysisLoading: true,
            role_access: queryx.get('access')
        }
    }

    componentDidMount(){
        this.filterTerms();
    }

    filterTerms() {
      this.setState({
        isFilterYearLevelsLoaded: false,
        isFilterCoursesLoaded: false,
        isFilterStudentsLoaded: false,
        isFilterSemestersLoaded: false,
        isFilterTermsLoaded: false,
        isGoButtonDisabled: true
      });

      const params = {logged_in: this.state.logged_in, access: this.state.role_access};
      ApiServices.AccFilterService.individualStudentFilters(params, this.state.token).then(res => res.json()).then((result) => {
        this.setState({
          filterData: result.data,
          filterTerms: result.data.years
        }, ()=> {
          if(this.state.filterTerms.length > 0){
            
                this.setState({
                  isFilterTermsLoaded: true,
                  filterTermValue: this.state.filterTerms[0].id,
                }, () => {
                  this.changeTermValue(this.state.filterTermValue);
                });
          } else {
            this.handleNoDataInFilters();
          }
        });
      },(error) => {
        this.setState({
          isFilterTermsLoaded: true
        });
      });
    }

    handleNoDataInFilters(){
      this.setState({
        isFilterTermsLoaded: true,
        isFilterSemestersLoaded: true,
        isFilterStudentsLoaded: true,
        isStudentNameLoaded: true,
        filterTerms: [{name: "No Data", id: ""}],
        filterSemesters: [{name: "No Data", id: ""}],
        filterStudents: [{name: "No Data", id: ""}],
      }, ()=>{
        this.setState({
          isCourseAnalysisLoaded: false,
          isCourseAnalysisLoading: false
        });
      });
    }
 
    // filterTerms = () => {
    //     this.setState({
    //       isFilterTermsLoaded:false,
    //         isGoButtonDisabled: true
    //     });
    //   const params = {logged_in: this.state.logged_in};
    //   ApiServices.AccFilterService.studentFilterTerm(params, this.state.token).then(res => res.json()).then((result) => {
    //       this.setState({
    //         isFilterTermsLoaded: true, 
    //         filterTermValue:2021,
    //         filterTerms:result.data.result
    //       });
    //       this.filterSemestersFunc(result.data.result[0].id);
    //     },

    //     (error) => {
    //       this.setState({
    //         isFilterTermsLoaded: true,
    //         error
    //       });
    //     }
    //     ) ;
    //  }

     filterSemestersFunc = () => {
        // this.setState({
        //   filterStudentValue: 0,
        //   filterStudents: [],
        //   studentName: "",
        //   isGoButtonDisabled: true}, () => {
        //     this.setState({
        //       isFilterSemestersLoaded: true,
        //       filterSemesters: [{ "id": 1, "name": "Semester 01" },{ "id": 2, "name": "Semester 02" }],
        //       filterSemesterValue: 1
        //     },() => {
        //       this.changeSemesterValue(this.state.filterSemesterValue);
        //     });
        //   });
        this.setState({
          isFilterStudentsLoaded: false,
          isFilterCoursesLoaded: false,
          filterStudents: [],
          filterCourses: [],
          isGoButtonDisabled: true
        }, () => {
          this.setState({
            isFilterSemestersLoaded: true,
            filterSemesters: this.state.filterData.semesters,
            filterSemesterValue: this.state.filterData.semesters[0].id
          }, () => {
            this.changeSemesterValue(this.state.filterSemesterValue)
          });
        });
      }

     changeTermValue(value, trigger){
        this.setState({filterTermValue:value}, () => {
          this.filterSemestersFunc();
        });

        if(trigger === "redraw"){
          this.setState({
            isFirstTimeLoad:false
          });
        }
     }

     changeSemesterValue(value, trigger) {
      this.setState({ filterSemesterValue: value }, () => {
        this.filterStudents();
      });

      if(trigger === "redraw"){
        this.setState({
          isFirstTimeLoad:false
        });
      }
    }

    filterStudents = () => {
     this.setState({
       isFilterStudentsLoaded: false,
       isFilterCoursesLoaded: false,
       filterStudents: [],
       filterCourses: [],
       isGoButtonDisabled: true
     });
 
     var students = this.state.filterData.students;
 
     if(students && students.length > 0){   
       this.setState({
         isFilterStudentsLoaded: true, 
         filterStudents: students,
         filterStudentValue: students[0].id
       }, () => {
         this.changeStudentValue(this.state.filterStudentValue);
       });
     } else {
       this.setState({
         isFilterStudentsLoaded: true, 
         isStudentNameLoaded: true,
         studentName:"",
         filterStudents: [{name: "- No Data", id: ""}],
         isGoButtonDisabled: true
       }, () => {
         this.setNoCoursesOnStudent();
       });
     }
   }

     changeStudentValue(value, trigger){
      //  value = 7250;
        this.setState({filterStudentValue:value}, () => {
          this.basic_info();
          this.filterCourses();

          if(trigger === "redraw"){
            this.setState({
              isFirstTimeLoad:false
            });
          }
        }, () => {
          if(this.state.isFirstTimeLoad){
            this.redrawCharts();
          }
        });
     }

    //  filterStudents = () => {
    //     this.setState({
    //         isFilterStudentsLoaded:false,
    //         isGoButtonDisabled: true
    //     });

    //     const params = {accountId: this.state.logged_in};
    //     ApiServices.AccFilterService.students(params, this.state.token).then(res => res.json()).then((result) => {
    //       if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result.length > 0){
    //         this.setState({
    //           isFilterStudentsLoaded: true,
    //           // filterStudentValue:result.data.result[0].id,
    //           filterStudents:result.data.result
    //         }, () => {
    //             this.changeStudentValue(result.data.result[0].id);
    //         });
    //       }else{
    //         this.setState({
    //           isFilterStudentsLoaded: true,
    //           filterStudents: [{name:"No Data", id:""}],
    //           isGoButtonDisabled: true
    //         }, () => {
    //           this.setState({
    //             isFilterCoursesLoaded: true,
    //             filterCourses: [{name: "No Data", id: 0}],
    //             isGoButtonDisabled: true,
    //             isCourseAnalysisLoaded: true,
    //           });
    //         });
    //       }
    //       },
  
    //       (error) => {
    //         this.setState({
    //           isFilterStudentsLoaded: true,
    //           error
    //         });
    //       }
    //       ) ;
    //  }

    filterCourses = () => {
      this.setState({
        filterCourses: [],
        isGoButtonDisabled: true,
        isFilterCoursesLoaded: false
      });

      let courses = [];
      try{
        courses = this.state.filterData.courses[this.state.filterStudentValue][this.state.filterTermValue][this.state.filterSemesterValue];
      }catch(e){
      }
        
      if(courses && courses.length > 0){
        this.setState({
          filterCourses: courses
        }, () => {
          this.setState({
            isFilterCoursesLoaded: true,
            filterCourseValue: courses[0].id,
            isGoButtonDisabled: false
          }, () => {
            this.changeCourseValue(this.state.filterCourseValue);
          });
        });
      } else {
        this.setNoCoursesOnStudent();
      }
    }

    //  filterCourses() {
    //   this.setState({
    //     filterCourses: [],
    //     isGoButtonDisabled: false, // override GoButton disablity from here as no course filter to show the no data in causes. buttom enabled
    //     isFilterCoursesLoaded: false
    //   });
  
    //   const params = {logged_in: this.state.logged_in, observer: 'O'};
    //   ApiServices.AccFilterService.studentFilters(params, this.state.token).then(res => res.json()).then((result) => {
    //     // const params = { id: this.state.logged_in, role: 'O'};
    //     // ApiServices.AccFilterService.parentStudentCoursesBySemester(params, this.state.token).then(res => res.json()).then((result) => {
    //       if(helper.isObject(result) && helper.isObject(result.data)){
    //           let courses = [];
    //         try{
    //           courses = result.data.courses[this.state.filterStudentValue][this.state.filterTermValue][this.state.filterSemesterValue];
    //         }catch(e){
    //         }
            
    //         if(courses.length > 0){
    //           this.setState({
    //             isFilterCoursesLoaded: true,
    //             filterCourses: courses
    //           });

    //           if(this.state.isFirstTimeLoad){
    //             this.redrawCharts();
    //           }
    //         }else
    //           this.setNoCoursesOnStudent();
    //       }else
    //         this.setNoCoursesOnStudent();
    //     },
    //     (error) => {
    //       this.setState({
    //         isFilterCoursesLoaded: true,
    //         error
    //       });
    //     }
    //   );
    // }

    changeCourseValue(value) {
      this.setState({ filterCourseValue: value }, () => {
          if (this.state.isFirstTimeLoad) {
            this.setState({
              isFirstTimeLoad: false
            });
            this.redrawCharts();
          }
      });
    }

    setNoCoursesOnStudent = () => {
      // if(this.state.isFirstTimeLoad){
        this.setState({
          isFilterCoursesLoaded: true,
          filterCourses: [{name: "- No Data", id: 0}],
          isCourseAnalysisLoaded: false,
          isCourseAnalysisLoading: false,
        });
      // }
    }

     redrawCharts(){
        this.setState({
            isCourseAnalysisLoaded: false
        }, () => {
            this.subject_analysis();
        });
       }
  
      basic_info(){
        const params = {logged_in: this.state.logged_in, studentId: this.state.filterStudentValue, access: this.state.role_access};
        ApiServices.AccStudentProfileService.studentProfile(params, this.state.token).then(res => res.json()).then((result) => {
            this.setState({
              isStudentNameLoaded: true,
              studentId:result.data.result.id,
              studentName:result.data.result.name, 
              avatar:result.data.result.avatar_url
            });
          },
          (error) => {
            this.setState({
              isStudentNameLoaded: true,
              error
            });
          }) ;
      }

      subject_analysis = () => {
        const courseIds = [];
        var statusRole = 'PA';
        
        for (var i = 0; i < this.state.filterCourses.length; i++) {
            courseIds[i] = this.state.filterCourses[i].id;
        }
        this.setState({
          isCourseAnalysisLoaded: false,
          isCourseAnalysisLoading: true,
          courseAnalysisData: []
        });
        
        const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterYearLevelValue, status: statusRole, semester: this.state.filterSemesterValue , access: this.state.role_access};
        //getting submission data
        ApiServices.AccStudentProfileService.studentSubjectAnalysis(params, this.state.token).then(res => res.json()).then((result) => {
          if(result.data.response){
            if(Object.keys(result.data.response.body.data).length > 0){
              this.setState({
                courseAnalysisData: result.data.response.body.data
              }, () => {
                if(this.state.courseAnalysisData.length > 0)
                this.drawSubjectAnalysis();
                else
                  this.setState({
                    isCourseAnalysisLoaded: true,
                  });
              });
            }else{
              this.setState({
                isCourseAnalysisLoading: false,
              });
            }
          }
              
            },

            (error) => {
              this.setState({
                isCourseAnalysisLoading: true,
                error
              });
            }
          );
      }

    drawSubjectAnalysis = () => {
      let mainarr = [];
      this.state.courseAnalysisData.map((course, index) => {
        let visual = {
          chart: {
            inverted: true,
            type: 'boxplot',
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
            height: "30%"
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories: course.name,
            title: {
                text: ''
            },
            labels: {
              enabled: false
          },
        },
        plotOptions: {
          boxplot: {
              lineWidth: 2
          }
        },
        yAxis: {
            title: {
                text: ''
            }       
        },
        credits: {
          enabled: false
        },
        exporting: { enabled: false },
        series: [{
            name: 'COHORT',
            color:"#002b80",
            data: course.boxplot
             
        }, {
            name: 'STUDENT',
            color: "#801100",
            type: 'scatter',
            data: [course.scatter],
             
            tooltip: {
                pointFormat: 'STUDENT: {point.y}'
            }
        }]
        };
        mainarr.push(visual);
      });
      this.setState({
        couseAnalysisChartsObjects: mainarr,
      }, () => {
        this.setState({
          isCourseAnalysisLoaded: true
        });
      });
    }

    routeChange=()=> {
        let path = `${process.env.PUBLIC_URL}/dashboard/student?token=${this.state.logged_in}&t=${this.state.token_with_id}&year=${this.state.filterTermValue}&semester=${this.state.filterSemesterValue}&student=${this.state.filterStudentValue}&profile=${this.state.profile}&role=O&access=${this.state.role_access}`;
        const { history } = this.props;
        history.push(path);
      }

      getRenderedHtml = (value) => {
        if(value === "PENDING")
          return <i>{value}</i>
        return value
      }

      generatepdf = () => {
        const input = document.getElementById('collddd');
        html2canvas(input).then((canvas) => {
              const { width, height } = canvas;
              var pdf = new jsPDF({
                orientation: width > height? 'l': 'p',
                unit: "mm",
                format: [(height + 20) * 0.2645833333, (width) * 0.2645833333]
              });
              const imgData = canvas.toDataURL('image/png');

              pdf.addImage(imgData, 'PNG', 0, 0);
              pdf.save("Parent_Profile_" + moment().tz('Australia/Lindeman').format('YYYY-MM-DD hh:mm:ss'));
        });
      }

     render() {
     return (
        <Fragment>
       <div className="container-fluid " > 
       <div className="card custom-card  " style={{backgroundColor:"#e0e0e0",marginTop: '90px'}}> 
        <div className="row ">
        <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
        <div className="row "  >
          <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
          <div className="card o-hidden custom-cardx">
            <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
              <div className="media static-top-widget">
                <div className="media-body">
                  <span className="m-0">YEAR</span>
                  <h4 className="mb-0 counter">
                  <select className="form-control digits "  value={this.state.filterTermValue}
                        onChange={(e) => this.changeTermValue(e.target.value, "redraw")}>
                            {this.state.isFilterTermsLoaded
                        ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                        : <option>Loading...</option>
                        }
                    </select>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
            <div className="card o-hidden custom-cardx">
            <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                <div className="media static-top-widget">

                <div className="media-body"><span className="m-0">SEMESTER</span>
                    <h4 className="mb-0 counter">
                    <select className="form-control digits" value={this.state.filterSemesterValue}
                        onChange={(e) => this.changeSemesterValue(e.target.value, "redraw")}>
                        {this.state.isFilterSemestersLoaded
                        ? this.state.filterSemesters.map((semester) => <option key={semester.id} value={semester.id}>{semester.name}</option>)
                        : <option>Loading...</option>

                        }
                    </select>
                    </h4>
                </div>
                </div>
            </div>
            </div>
        </div>
         {/* <div className="col-sm-4 col-md-4 col-xs-12 col-xl-4 col-lg-4">
          <div className="card o-hidden custom-cardx">
          <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
              <div className="media static-top-widget">  
                <div className="media-body"><span className="m-0">STUDENTS</span>
                  <h4 className="mb-0 counter">
                    <select className="form-control digits" value={this.state.filterStudentValue}
                        onChange={(e) => this.changeStudentValue(e.target.value, "redraw")}>
                        {this.state.isFilterStudentsLoaded
                        ? this.state.filterStudents.map((filterStudents) => <option key={filterStudents.id} value={filterStudents.id}>{filterStudents.name} - {filterStudents.id}</option>)
                        : <option>Loading...</option>
                        }
                    </select>
                  </h4> 
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
       </div>
        <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
          <div className="">
                <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled}  style={{marginTop: '24px',width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled} style={{ marginTop: '24px', width: "100%" }} className="btn btn-secondary btn-lg" onClick={(e) => this.generatepdf()}>Export to PDF</button>
          </div>
        </div>
     </div>
     </div>     
     <div id ="collddd">
        <div className="user-profile"> 
          <div className="row">
              <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                <div className="card custom-card  ">
                    <div className="row">
                    <div className="col-2 col-sm-2">
                    <img  src={accimage}></img>
                    </div>
                    <div className="col-5 col-sm-5 with-border">
                        <div className="student-name">
                          <h5><span className="counter">STUDENT ACADEMIC REPORT</span></h5>
                          {this.state.isStudentNameLoaded && this.state.isFilterTermsLoaded && this.state.isFilterSemestersLoaded ? 
                            <Fragment>
                                <h4><b>{this.state.studentName} </b></h4>
                                <h6>{`${this.state.filterTermValue} - Semester ${this.state.filterSemesterValue}`}</h6>
                                <h6 className="printed-on">Printed on {moment().format("DD/MM/YYYY")}</h6>
                            </Fragment>
                          : this.state.loader
                          }
                      </div>
                    </div>
                      <div className="col-5 col-sm-5 ">
                          {/* <button  style={{ marginTop: '48px', width: "80%",backgroundColor:"#003f7a",color:"#ffffff"}} className="btn btn-lg"  onClick={(e) => this.routeChange()}><FontAwesomeIcon icon={faUser} /> STUDENT PROFILE </button> */}
                    </div>
                    </div>
                </div>
                 </div>

                 <div className="col-xl-12 xl-100">
                   <div className="card">
                     <div className="row" style={{ margin: "30px 0 20px 0" }}>
                       <div className="col-md-5 col-lg-5 ">
                         <h6 style={{ textAlign: "center", fontWeight: "bold" }}>SUBJECT</h6>
                       </div>
                       <div className="col-lg-3 col-md-3">
                         <h6 style={{ textAlign: "center", fontWeight: "bold" }}>SUBJECT GRADE</h6>
                       </div>
                       {/* <div className="col-lg-2 col-md-2">
                                <h6 style={{ textAlign: "center"}}>ENGAGEMENT GRADE</h6>
                            </div> */}
                       <div className="col-lg-4 col-md-4">
                         <h6 style={{ textAlign: "center", fontWeight: "bold" }}>MARKS DISTRIBUTION</h6>
                       </div>
                       {/* <div className="col-lg-2 col-md-2 ">
                                <h6 style={{ textAlign: "center"}}>COHORT AVERAGE</h6>
                            </div> */}
                     </div>
                     <div className="card-body" style={{ backgroundColor: "white" }}>
                       <div className="user-status table-responsive">
                         <div className="user-profile">
                           {this.state.isCourseAnalysisLoaded ?
                             this.state.courseAnalysisData.map((course, index) => (
                               <div key={index} style={{ height: "auto", marginBottom: "20px" }}>
                                 <Row gutter={[8, 8]}>
                                   <Col className="gutter-row" span={10} style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto" }}>
                                     <div className="card-body" style={{ backgroundColor: "#003470", color: "#ffffff", height: "100%", marginBottom: "10px" }}>
                                       <div className="user-status table-responsive">
                                         <div style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto", fontSize: "18px" }}>
                                           {
                                             course.name
                                           }
                                         </div>
                                       </div>
                                     </div>
                                   </Col>
                                   <Col className="gutter-row" style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto", fontWeight: "bold" }} span={6}>
                                     <h4> {
                                       this.getRenderedHtml(course.subject_grade)
                                     }&ensp;{course.finalMark}</h4>
                                   </Col>
                                   <Col className="gutter-row" span={8}>
                                     <div>
                                       <HighchartsReact
                                         highcharts={Highcharts}
                                         options={this.state.couseAnalysisChartsObjects[index]}
                                       // containerProps={{ style: { height: 130 } }}

                                       />
                                     </div>
                                   </Col>
                                   {/* <Col className="gutter-row" span={6}>
                                  <div style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto" }} >
                                    <h4>{
                                      this.getRenderedHtml(course.cohort_average)
                                    }</h4>
                                  </div>
                                </Col> */}
                                   <Col className="gutter-row" span={24}>
                                     <Table dataSource={course.assignmentList} pagination={false} style={{paddingBottom: "4px"}}>
                                       <ColumnGroup>
                                         <Column className="acc-data-table" title={<div className="assignment-tasks-header">Assignments Tasks</div>} dataIndex="nameOfAssignment" width='40%' key="nameOfAssignment" />
                                         <Column className="acc-data-table" title={<div className="assignment-tasks-header">Assignment Result</div>} dataIndex="assignmentResult" width='29%' key="assignmentResult" />
                                         <Column className="acc-data-table" style={{padding: "10px"}} title={<div className="assignment-tasks-header">Assignment Status</div>} dataIndex="assignmentStatus" width='31%' key="assignmentStatus" render={(_text, record) => {
                                           if (record.assignmentResult === "-") {
                                             return (
                                               <div key={record.key} style={{ color: "#F12828" }}>{_text}</div>
                                             );
                                           }
                                           return (
                                             <div key={record.key} style={{ color: "#389b65" }}>{_text}</div>
                                           );
                                         }} />
                                       </ColumnGroup>
                                     </Table>
                                   </Col>
                                 </Row>
                            </div>
            
                        ))
                        : this.state.isCourseAnalysisLoading ?
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        : <div style={{ display: 'flex', justifyContent: 'center', padding: '100px' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                        }

                        <div className="col-xl-12 xl-100" style={{margin:"auto",textAlign:"center"}}>
                            <img src={boxplotimage} style={{width: "inherit", maxWidth: "min-content"}}/>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div> 
        </Fragment>
    )
    };
}

export default withRouter(SingleStudentProfile);