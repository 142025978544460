import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Row, Button, Space } from "antd";

const UnitsTable = ({ pagination, dataset, columns, editMode, setEditMode, onEditSubmit, handleChangeTable }) => {

    const renderTableFooter = () => {
        return (
            <Row display="flex" justify="end">
                <div>
                    <Space size="small">
                        {editMode ?
                            <Fragment>
                                <Button onClick={onEditSubmit} type="primary">
                                    Save
                                </Button>
                                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                            </Fragment>

                            :
                            <Button onClick={() => setEditMode(true)} type="primary">
                                Edit
                            </Button>
                        }
                    </Space>
                </div>
            </Row>
        )
    }

    return (
        <div className="vw-unit-data-table">
            <Table
                onChange={handleChangeTable}
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataset}
                scroll={{ x: true }}
                pagination={pagination}
                size="small"
                footer={dataset && dataset.length ? renderTableFooter : null}
            />
        </div>

    )
}

UnitsTable.propTypes = {
    pagination: PropTypes.object,
    dataset: PropTypes.array,
    columns: PropTypes.array,
    setEditMode: PropTypes.func,
    editMode: PropTypes.bool,
    onEditSubmit: PropTypes.func,
    handleChangeTable: PropTypes.func
}

export default UnitsTable;