import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Popconfirm, Space, Tag } from 'antd';

import { makeFetchUserListData } from '../../../../../../selectors/vetTrack/dashboardManagement.selector';
import {
  deleteUser,
  fetchDashboardUsers,
} from '../../../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action';
import { onSuccess } from '../../../../../../utils/notificationHandler';

import UsersTable from './UsersTable';
import ResetPasswordModal from './partials/resetPassword/index';
import SetPermissionModal from './partials/setPermissions/SetPermissionModal';
import { sendResetPasswordLink } from '../../../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action';

const TableContainer = ({ isTemporaryHidden }) => {
  const [isResetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [isSetPermissionVisible, setSetPermissionVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => { 
  //  TODO add this fetch users function
  dispatch(fetchDashboardUsers());
  }, [])

  // selectors
  const users = useSelector(makeFetchUserListData);

  const usersWithKeyProp = () => (
    users.map( (user) => (
      {
        ...user, 
        key: user.id,
      }
    ))
  );

  // Comment this, if isTemporaryHidden = true
  // const showResetPasswordModal = () => {
  //   setResetPasswordVisible(true);
  // };

  const handleResetPasswordOk = () => {
    setResetPasswordVisible(false);
  };

  const handleResetPasswordCancel = () => {
    setResetPasswordVisible(false);
  };

  const showSetPermissionModal = () => {
    setSetPermissionVisible(true);
  };

  const handleSetPermissionOk = () => {
    setSetPermissionVisible(false);
  };

  const handleSetPermissionCancel = () => {
    setSetPermissionVisible(false);
  };

  const onUserDeleteConfirm = email => {
    dispatch(
      deleteUser(email, () => {
        onSuccess('User deleted successfully!');
        dispatch(fetchDashboardUsers());
      }),
    );
  };

  const onResetUserPasswordConfirm = email => {
    dispatch(
      sendResetPasswordLink(email, () => {
        onSuccess('Password reset successfully!');
      }),
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    isTemporaryHidden 
      ? {}
      : {
          title: 'Permissions',
          key: 'permissions',
          dataIndex: 'permissions',
          render: permissions => (
            <>
              {permissions &&
                permissions.map(permission => (
                  <Tag color="#57409C">{permission}</Tag>
                ))}
            </>
          ),
        },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <Space size="middle">
          {/* <Button
            size="small"
            onClick={() => showResetPasswordModal(record.id)}
          >
            Reset Password
          </Button> */}
          <Popconfirm
            title="Are you sure to Reset this user password?"
            onConfirm={() => onResetUserPasswordConfirm({ email: record.email })}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger type="primary">
              Reset Password
            </Button>
          </Popconfirm>

          {!isTemporaryHidden && <Button
            size="small"
            onClick={() => showSetPermissionModal(record.id)}
          >
            Set Permissions
          </Button>}

          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => onUserDeleteConfirm({ email: record.email })}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger type="primary">
              Remove
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <UsersTable users={usersWithKeyProp()} columns={columns} />
      {
        !isTemporaryHidden && <><ResetPasswordModal
          isVisible={isResetPasswordVisible}
          handleCancel={handleResetPasswordCancel}
          handleOk={handleResetPasswordOk}
        />
          <SetPermissionModal
            isVisible={isSetPermissionVisible}
            handleCancel={handleSetPermissionCancel}
            handleOk={handleSetPermissionOk}
          />
        </>
      }
    </>
  );
};

export default TableContainer;
