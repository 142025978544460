import {
    JR_GEN_ACADEMIC_CARDS_DATA_FETCH,
    JR_GEN_ACADEMIC_CARDS_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH,
    JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH,
    JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH,
    JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH,
    JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH,
    JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH,
    JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING,
    JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH,
    JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING
} from "../../../constant/actionTypes";

const defaultState = {
    cards: {
        isLoading: false,
        data: {}
    },
    students: {
        isLoading: false,
        data: {}
    },
    overview: {
        isLoading: false,
        data: {
            lineChartData: null,
            disabilities:null,
            requirementMet: null,
            targetNotCompleted: null,
            currentUnitProgress: null,
            expectedUnitProgress: null,
            attendance: null
        }
    },
    disabilities: {
        isLoading: false,
        data: {}
    },
    requirementMet: {
        isLoading: false,
        data: {}
    },
    targetNotCompleted: {
        isLoading: false,
        data: {}
    },
    unitProgress: {
        isLoading: false,
        data: {}
    },
    attendance: {
        isLoading: false,
        data: {}
    },
}

const raAcademicReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_ACADEMIC_CARDS_DATA_FETCH:
        case JR_GEN_ACADEMIC_CARDS_DATA_FETCH_LOADING:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH:
        case JR_GEN_ACADEMIC_STUDENTS_DATA_FETCH_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH:
        case JR_GEN_ACADEMIC_OVERVIEW_DATA_FETCH_LOADING:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH:
        case JR_GEN_ACADEMIC_DISABILITIES_DATA_FETCH_LOADING:
            return {
                ...state,
                disabilities: {
                    ...state.disabilities,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH:
        case JR_GEN_ACADEMIC_REQUIREMENT_MET_DATA_FETCH_LOADING:
            return {
                ...state,
                requirementMet: {
                    ...state.requirementMet,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH:
        case JR_GEN_ACADEMIC_TARGET_NOT_COMPLETED_DATA_FETCH_LOADING:
            return {
                ...state,
                targetNotCompleted: {
                    ...state.targetNotCompleted,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH:
        case JR_GEN_ACADEMIC_UNIT_PROGRESS_DATA_FETCH_LOADING:
            return {
                ...state,
                unitProgress: {
                    ...state.unitProgress,
                    ...action.payload
                }
            }
        case JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH:
        case JR_GEN_ACADEMIC_ATTENDANCE_DATA_FETCH_LOADING:
            return {
                ...state,
                attendance: {
                    ...state.attendance,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default raAcademicReducer;