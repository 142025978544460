import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
    makeVwDealershipPathwayElementsListsDataResponse,
    makeVwDealershipPathwaySelectedDataLoadingResponse,
    makeVwDealershipPathwaySelectedDataSelectedElementResponse,
    makeVwDealershipPathwaySelectedDataResponse,
} from "../../../../../selectors/vwDealership/qualificationPathway.selector";
import {fetchStudentsData} from "../../../../../actions/vwDealership/qualificationPathway.action";

import QualificationPathway from "./QualificationPathwayView";

const QualificationPathwayContainer = () => {
    const [showDataTable, setShowDataTableData] = useState(false);
    const [selectedFiltersMeta, setSelectedFiltersMeta] = useState({
        roles: null,
        pathways: null,
        programs: null,
        students: [],
    })

    const dispatch = useDispatch();

    // selectors
    const pathwayNodes = useSelector(makeVwDealershipPathwayElementsListsDataResponse);
    const studentsData = useSelector(makeVwDealershipPathwaySelectedDataResponse);
    const studentsDataLoading = useSelector(makeVwDealershipPathwaySelectedDataLoadingResponse);
    const selectedElement = useSelector(makeVwDealershipPathwaySelectedDataSelectedElementResponse);

    const onElementClick = (data) => {
        // get student data
        dispatch(fetchStudentsData({...data,
            role: selectedFiltersMeta.roles,
            pathway:selectedFiltersMeta.pathways,
            program: selectedFiltersMeta.programs,
            students: selectedFiltersMeta.students
        }));
    };

    const onShowDataTable = (data) => {
        onElementClick(data)
        setShowDataTableData(true);
    };

    const handleCancel = () => {
        setShowDataTableData(false);
    };

    return (
        <QualificationPathway
            nodes={pathwayNodes}
            dataTableConfig={{onShowDataTable, handleCancel}}
            showDataTable={showDataTable}
            studentsDataLoading={studentsDataLoading}
            tableData={{name: `${selectedElement.name}(${selectedElement.id})`, students: studentsData}}
            filterMeta={{selectedFiltersMeta, setSelectedFiltersMeta}}
        />
    )
}

export default QualificationPathwayContainer;