import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import StudentAssignmentView from "./StudentAssignmentView";
import {
    fetchAssignments,
    fetchAssignmentFilterData,
    fetchTeacherStudentAssignmentsChartData,
    clearAssignmentChartData,
} from "../../../actions/innovative/assignment.action";
import {useLocation, useParams} from "react-router";
import {fetchStudent} from "../../../actions/innovative/student.action";
import {makeInnovativeStudentResponseData} from "../../../selectors/innovative/student.selector";
import {
    makeInnovativeAssignmentsLoading,
    makeInnovativeAssignmentsResponseData,
    makeInnovativeOverallOutcomesResponseData,
    makeInnovativeOverallMasteryStatusResponseData,
    makeInnovativeAssignmentFilterData,
    makeInnovativeAssignmentsComparisonResponseData,
    getAssignmentGradeCohortData,
} from "../../../selectors/innovative/assignment.selector";
import {setBreadcrumb} from "../../../actions/innovative/layout.action";
import {
    getIsApplicationAdmin,
    makeInnovativeCourseResponseData
} from "../../../selectors/innovative/course.selector";
import {makeInnovativeSettingByPath, makeInnovativeSettingsLoading} from "../../../selectors/innovative/setting.selector";
import {getAccessToken, queryStringToObject} from "../../../utils/general";
import {fetchSettings} from "../../../actions/innovative/settings.action";
import session from "../../../utils/session";
import {
    resolvePropertiesFromSettingArray,
} from "../../../utils/innovative/resolveSettings";
import impressionHandler from "../../../utils/impressionHandler/impressionHandler";
import { FILTER_ASSIGNMENT_GROUP, FILTER_MODULES } from '../../../utils/innovative/constants';
import ChartDataFetch from '../studentProfile/chartDataFetch';

const ADMIN_PATH = "/insights/admin";

const StudentProfile = () => {
    const {studentId, courseId, termId} = useParams();

    const dispatch = useDispatch();
    const control = useRef();

    const search = queryStringToObject(useLocation().search);
    const [breadcrumbKey, setBreadcrumbKey] = useState({
        key: "tasks",
        section: "Tasks",
    });
    const [isApplyFilterInvoking, seIsApplyFilterInvoking] = useState(false);
    const [chartDataController, setChartDataController] = useState(null);
    const [taskTablePagination, setTaskTablePagination] = useState(0);

    const {state} = useLocation();
    const moduleData = state && state.moduleData;
    const innovativeMetaData = session.get(session.keys.innovativeMeta);

    const parsedUrl = new URL(window.location.href);
    const isAdminPath = parsedUrl.pathname.split("/teacher")[0] === ADMIN_PATH;

    // selectors
    const overallMastery = useSelector(makeInnovativeOverallMasteryStatusResponseData);
    const overallOutcomes = useSelector(makeInnovativeOverallOutcomesResponseData);
    const student = useSelector(makeInnovativeStudentResponseData);
    const selectedCourse = useSelector(makeInnovativeCourseResponseData);
    const assignmentReport = useSelector(makeInnovativeAssignmentsResponseData);
    const assignmentReportLoading = useSelector(makeInnovativeAssignmentsLoading);
    const markingScheme = useSelector(makeInnovativeSettingByPath("markingScheme"));
    const colorScheme = useSelector(makeInnovativeSettingByPath("colorScheme"));
    const settingsLoading = useSelector(makeInnovativeSettingsLoading);
    const assignmentFilterData = useSelector(makeInnovativeAssignmentFilterData);
    const isApplicationAdminData = useSelector(getIsApplicationAdmin);
    const assignmentsListComparison = useSelector(
        makeInnovativeAssignmentsComparisonResponseData
    );
    const cohortData = useSelector(getAssignmentGradeCohortData);

    // This is related to overall outcome & mastery status cohort
    const resolveCellData = useCallback((func, data, setting, propMapping) => {
        return func(data, setting, propMapping);
    }, [overallOutcomes, colorScheme.proficiencyRatingForOutcomes]);


    // mastery status cohort conf
    const masteryResolved = resolveCellData(
        resolvePropertiesFromSettingArray,
        overallMastery,
        colorScheme.masteryStatus,
        [0, "status"]);
    const masteryConfig = {
        chart: {
            height: 200,
            width:300,
            marginLeft: 20
        },
        legend: {
            width: 130,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
        },
        series: [{data:overallMastery}],
        plotOptions: {
            pie: {
                colors: masteryResolved.colors,
                center: [30, 70],
                size: '120px',
                left: '0',
            }
        }
    };


    const resolved = resolveCellData(
        resolvePropertiesFromSettingArray,
        overallOutcomes,
        colorScheme.proficiencyRatingForOutcomes,
        [0, "description"]);
    const outcomeConfig = {
        chart: {
            height: 200,
            width:300,
            marginLeft: 20
        },
        legend: {
            width: 130,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
        },
        series: [{data:overallOutcomes}],
        plotOptions: {
            pie: {
                colors: resolved.colors,
                center: [30, 70],
                size: '120px',
                left: '0',
            }
        }
    };



    const chartOptions = {
        legend: {
            align: "right",
            verticalAlign: "middle",
            layout: "horizontal",
        },
    };

    const sideChartsConfigs = [
        { title: "Overall Task Grades" },
        { title: "Overall Task Submissions" },
    ];

    const { filterAssignmentGroups } = assignmentFilterData;
    const groupIds = filterAssignmentGroups.map(item => item.id);

    useEffect(() => {
        if(search.current){
            setBreadcrumbData(search.current);
        }
    }, [search.current])

    useEffect(() => {
        dispatch(fetchSettings({
            requestParams: {
                "subject_id": courseId,
                "school_id": session.get([
                    session.keys.innovativeMeta,
                    "school_id"
                ]),
            }
        }));
        // Set impression data
        impressionHandler.sendImpressionData("innovative_tasks")

        dispatch(fetchStudent({
            requestParams: {
                "student_id": studentId
            }
        }));
    }, []);

    useEffect(() => {
        abortCallsClearChartData();
        dispatch(fetchAssignmentFilterData({
            requestParams: {
                course_id: courseId,
            }
        }))
    }, [courseId]);


    useEffect(() => {
        if (!groupIds.length) return ;

        if(isApplyFilterInvoking) {
            seIsApplyFilterInvoking(false);
            return;
        };

        if(moduleData){
            return;
        };
        abortCallsClearChartData();
        dispatch(fetchAssignments({
            requestParams: {
                course_id: courseId,
                student_id: studentId,
                filterValuesOfAssignments: groupIds,
                filterType: FILTER_ASSIGNMENT_GROUP,
            }
        }));

    }, [JSON.stringify(groupIds)]);

    const getSearchQuery = () => {
        return `?token=${innovativeMetaData.token}&t=${innovativeMetaData.t}&access=${getAccessToken()}&actAs=${isApplicationAdminData.role}`;
    };

    // setting breadcrumb
    useEffect(() => {
        if (student && selectedCourse) {
            const breadCrumb = [
                {
                    key: "home",
                    section: "Home",
                    href: isAdminPath ? "/insights/admin/courses" : "/insights/teacher/courses",
                    searchQuery: getSearchQuery(),
                },
                {
                    key: "course",
                    section: selectedCourse["course_name"],
                    href: isAdminPath ? "/insights/admin/courses" : "/insights/teacher/courses",
                    searchQuery: getSearchQuery(),
                },
                {
                    key: "student",
                    section: student["student_name"],
                    href: isAdminPath ? `/insights/admin/teacher/student-profile/${studentId}/${courseId}/${termId}` : `/insights/teacher/student-profile/${studentId}/${courseId}/${termId}`,
                },
                breadcrumbKey,
            ];
            dispatch(setBreadcrumb(breadCrumb));
        }
    }, [student, selectedCourse, breadcrumbKey]);

    const setBreadcrumbData = (key) => {
        const findData = () => {
            switch (key) {
                case "outcomes":
                    return {
                        key: "outcomes",
                        section: "Outcomes",
                    }
                case "tasks":
                default:
                    return {
                        key: "tasks",
                        section: "Tasks",
                    }
            }
        }
        setBreadcrumbKey(findData())
    }
    const applyFilter = (type, values) => {
        dispatch(clearAssignmentChartData());
        seIsApplyFilterInvoking(true);
        dispatch(fetchAssignments({
            requestParams: {
                course_id: courseId,
                student_id: studentId,
                filterValuesOfAssignments: values,
                filterType: type,
            },
        }));
    };

    useEffect(() => {
        // initiate fetching chart data after main report data loaded
        const assignmentIds = assignmentsListComparison.map(assignment => assignment.assignment_id);
        if (
            control.current &&
            assignmentsListComparison &&
            !assignmentReportLoading &&
            assignmentIds.length
        ) {
            fetchChartData(assignmentIds);
        }
    }, [control.current, assignmentsListComparison, assignmentReportLoading]);

    useEffect(() => {
        if (
            cohortData &&
            cohortData.length > 0 &&
            chartDataController
        ) {
            chartDataController.validateArray(cohortData.map(data => data.assignmentId), taskTablePagination);
        }
    }, [cohortData, taskTablePagination]);

    useEffect(() => {
        // create abort controller instance for abort fetch calls
        control.current = new AbortController();
        dispatch(clearAssignmentChartData());

        return () => {
            control.current.abort();
        };
    }, []);

    const fetchChartData = async assignmentIds => {
        // create object of data handler and initiate chart data fetching
        const chartController = new ChartDataFetch(process.env.REACT_APP_COHORT_CHART_FETCH_ARRAY_SIZE, assignmentIds, dispatchChartData);
        setChartDataController(chartController);
        chartController.initiateFetch();
    }

    const dispatchChartData = (assignmentId) => {
        const data = {
            "course_ids": courseId,
            "student_id": studentId,
            "filterValuesOfAssignments": groupIds,
            "filterType": "assignment_group",
            "assignment_id": assignmentId,
        };

        dispatch(fetchTeacherStudentAssignmentsChartData(data, control.current.signal));
    }

    const abortChartFetchCalls = () => {
        control.current.abort();
        control.current = new AbortController();
    }

    const abortCallsClearChartData = () => {
        // remove ongoing chart data fetching calls
        control.current && abortChartFetchCalls();
        // clear current fetched chart data
        dispatch(clearAssignmentChartData());
    }

    return <StudentAssignmentView
        assignmentsReport={assignmentReport}
        assignmentsLoading={assignmentReportLoading}
        student={student}
        chartOptions={chartOptions}
        sideChartsConfigs={sideChartsConfigs}
        currentViewTab={search.current}
        outcomeEnabled={markingScheme.activatingItems?.outcomes}
        tasksEnabled={markingScheme.activatingItems?.assignments || markingScheme.activatingItems?.quizzes}
        outcomeConfig={outcomeConfig}
        masteryConfig={masteryConfig}
        setBreadcrumb={setBreadcrumbData}
        settingsLoading={settingsLoading}
        applyFilter={applyFilter}
        setTaskTablePagination={setTaskTablePagination}
    />
};

export default StudentProfile;
