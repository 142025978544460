import React, {memo} from "react";
import {Typography} from "antd";
import PropTypes from "prop-types";

import PieChart from "../../../../../../../../../../common/charts/PieChart";


const PieChartCard = ({name, data}) => {
    const chartConf = {

        legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: 'vertical',
            margin:8,
            // labelFormatter: function () {
            //     return `${this.name} ${Math.round(this.percentage)} %`;
            // }
        },

        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                },

            },
            pie: {
                size: "100%",
                innerSize: "60%",
                minSize: 90,
                slicedOffset:0,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function() {
                            return false;
                        }
                    }
                }
            }
        },

        tooltip: {
            enabled: false
        },

        series: [{
            colorByPoint: true,
            states: {
                hover: {
                    enabled: false,
                    brightness:0,
                }
            },
            data:data
        }]

    }


    return (
        <div>
            <Typography.Paragraph strong>{name}</Typography.Paragraph>
            <PieChart chartOptions={chartConf}/>
        </div>
    )
}

PieChartCard.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.array,
}

export default memo(PieChartCard);