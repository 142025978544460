import React, { useCallback, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllCourses,
  getAllCourseLoadingState,
  getLastAllCoursesSearchValue,
} from '../../../../../../selectors/userManagement/userManagement.selector';
import { getUserManagementCourses } from '../../../../../../actions/userManagement/userManagement.action';
import CourseSwitch from './courseSwitch';
import _debounce from "lodash/debounce";
import { get as _get } from 'lodash';
import Loader from "../../../../../../components/dashboardCommon/Loader";
import { dashboardConst } from '../../../../../../constant/dashboardManagement/commonDashboardConst';

const AllCourses = ({
  selectedUsersArray = [],
  onSwitchChange,
  searchCourseText,
  currentSelectedTab,
  previousSearchCourseText,
  fetchCourseData,
}) => {
  const allCourses = useSelector(getAllCourses);
  const tableDataLoading = useSelector(getAllCourseLoadingState);
  const lastSearchQuery = useSelector(getLastAllCoursesSearchValue);
  const accountId = _get(selectedUsersArray[0], "account_id", null);

  const dispatch = useDispatch();

  const onUserSearch = useCallback(_debounce(({ value }) => {
    if (value.length > dashboardConst.minSearchTextLength) {
      const userIds = selectedUsersArray.map(user => user.user_id);
      const payload = {
        searchValue: value,
        accountId,
        userIds,
        tab: dashboardConst.tabKey.all,
        ...dashboardConst.initialPagination,
      };

      dispatch(getUserManagementCourses(payload));
    }
  }, 500), [selectedUsersArray]);

  useEffect(() => {
    if (
      currentSelectedTab === dashboardConst.tabKey.all &&
      previousSearchCourseText !== searchCourseText &&
      lastSearchQuery !== searchCourseText
    ) {
      onUserSearch({ value: searchCourseText });
    }
  }, [currentSelectedTab, previousSearchCourseText, searchCourseText]);

  const onPaginationChange = (page, pageSize) => {
    fetchCourseData({
      pageLimit: pageSize,
      pageNo: page,
      tab: dashboardConst.tabKey.all,
      searchValue: searchCourseText,
    });
  };

  const columns = [
    {
      dataIndex: 'courseId',
      render: (_, record) => <CourseSwitch
        record={record}
        onSwitchChange={onSwitchChange}
      />
    },
  ];

  return (
    <div className='course-table'>
      <Loader isLoading={tableDataLoading} />
      <Table
        columns={columns}
        dataSource={allCourses.data}
        pagination={{
          onChange: onPaginationChange,
          total: allCourses.count,
          pageSizeOptions: dashboardConst.contentPerPage,
        }}
      />
    </div>
  )
}

export default AllCourses;
