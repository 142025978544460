import React, { memo } from 'react';
import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import Sidebar from "../common/layout/Sidebar";
import Loader from "../common/layout/Loader";

const SettingsView = ({ activeSection, sections, onSectionSelect, onSubmit, isLoading, onResetDefault, sidebarData, setSidebar }) => {

    const renderContent = () => {
        const Component = sections[activeSection].component;
        return <Component onSubmit={onSubmit} onResetDefault={onResetDefault} onSectionSelect={onSectionSelect} />
    }

    return (
        <div className="in-sidebarLayout">
            <Loader isLoading={isLoading} />
            <Sidebar
                title="Courses"
                items={sections}
                mapping={{
                    key: "key",
                    title: "name",
                }}
                onItemClick={onSectionSelect}
                activeSection={activeSection}
                sidebarData={sidebarData}
                setSidebar={setSidebar}
            />
            <Layout>
                <div className="in-smallContainer">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            {renderContent()}
                        </Col>
                    </Row>
                </div>
            </Layout>
        </div>
    );
};

SettingsView.propTypes = {
    activeSection: PropTypes.number,
    sections: PropTypes.array,
    onSectionSelect: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    sidebarData: PropTypes.object,
    setSidebar: PropTypes.func,
}

export default memo(SettingsView);