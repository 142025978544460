import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import Dropdown from '../../../common/dropDown';
import ModuleDataContainer from './components/moduleDataContainer';
import { useSelector, useDispatch } from "react-redux";
import { getModuleProgressItemsObj } from '../../../../../selectors/innovative/course.selector';
import { fetchModuleItemData } from '../../../../../actions/innovative/course.action';

const ModuleProgressDropdownCard = ({ module, studentId, courseId }) => {
  const dispatch = useDispatch();

  const moduleProgressionData = useSelector(getModuleProgressItemsObj);
  const [moduleProgressCardData, setModuleProgressCardData] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const moduleDataObjKey = `${studentId}_${module.moduleId}`

  useEffect(() => {
    if (isDropdownOpen && moduleDataObjKey in moduleProgressionData) {
      setModuleProgressCardData({ ...moduleProgressionData[moduleDataObjKey] })
    }

    else if (isDropdownOpen && (!Object.keys(moduleProgressCardData).length || !moduleProgressCardData?.isLoading)) {
      dispatch(fetchModuleItemData(
        {
          moduleDataObjKey,
          course_id: courseId,
          student_id: studentId,
          moduleIds: [module.moduleId],
        }
      ))
    }
  }, [isDropdownOpen, JSON.stringify(moduleProgressCardData), JSON.stringify(moduleProgressionData)]);

  const renderHeaderComponent = () => {
    return (
      <div
        className="moduleHeadText"
        justify={'space-between'}
        gutter={[10, 10]}
      >
        <Tooltip title={module.moduleName}>
          <div className="moduleName">{module.moduleName}</div>
        </Tooltip>
        <div
          className="state"
          style={{ backgroundColor: module.colorRange }}
        >
          {module.moduleState}
        </div>
      </div>
    )
  }

  return (
    <div className="moduleSegment">
      <Dropdown
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        header={() => renderHeaderComponent()}
      >
        <ModuleDataContainer
          prerequisite={module.modulePrerequisiteString}
          isLoading={moduleProgressCardData?.isLoading}
          moduleProgressCardData={moduleProgressCardData?.modeleDataArr?.[0]}
        />
      </Dropdown>

    </div>
  )
};

ModuleProgressDropdownCard.propTypes = {
};

export default ModuleProgressDropdownCard;
