import React from 'react';
import {Avatar} from "antd";
import PropTypes from "prop-types";

const StudentProfileCard = ({src, name, id}) => {
    return (
        <div className="profileCard">
            <Avatar src={src}/>
            <h2>{name}</h2>
            <h4>{id}</h4>
        </div>    
    );
};

StudentProfileCard.propTypes = {
    src: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default StudentProfileCard;