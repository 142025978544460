import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Typography, Popconfirm } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { onError } from '../../../../../../utils/notificationHandler';

import {
  saveSettings,
  discardDraft,
} from '../../../../../../actions/vetTrack/analysis/settings/commonSettings.action';
import {
  getBaseSettingByPath,
  getIsEdited,
  getPermissionDataByPath,
  getRiSettingsByPath,
} from '../../../../../../selectors/vetTrack/analysis.selector';

import SettingApplyPopup from './ApplyPopup';

import { initiateValidations } from '../helperValidators';
import {
  subSections,
  settingsSections,
} from '../../../../../../constant/vetTrack/analysis';

const configMapper = {
  [settingsSections.riskCalculations]: 'saveCalculations',
  [settingsSections.riskIndicators]: 'saveColors',
};

const SettingButtonGroup = ({ section, subSection }) => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);

  // selectors
  const isDraft = useSelector(
    getBaseSettingByPath(`${section}.${subSection}.data.isDraft`),
  );
  const dataset = useSelector(
    getBaseSettingByPath(`${section}.${subSection}.data`),
  );
  const isEdited = useSelector(getIsEdited);
  const subSectionPermission = useSelector(getPermissionDataByPath(subSection));

  const getSubSectionData = () => {
    const subSectionKey = Object.keys(subSections).find(
      k => subSections[k].key === subSection,
    );
    return subSections[subSectionKey];
  };

  const sectionErrors = useSelector(
    getRiSettingsByPath(`${subSection}.errors`),
  );

  const hasErrors = () => {
    const errors = sectionErrors || {};
    if (Object.keys(errors).length) {
      onError(
        'Invalid Values',
        'There are some invalid values in the configuration.'
      );
      return true;
    }

    return false;
  };

  const saveSettingsData = mode => {
    if (hasErrors()) return; 

    if (!initiateValidations(section, dataset)) {
      const subSectionData = getSubSectionData();
      dispatch(
        saveSettings(dataset, mode, section, subSection, subSectionData.id),
      );
    }
  };

  const showModal = () => {
    if (hasErrors()) return;

    setIsModalVisible(true);
  };

  const onConfirmDiscard = () => {
    const subSectionData = getSubSectionData();
    dispatch(discardDraft(section, subSection, subSectionData.id));
  };

  return (
    <>
      <div className="vtt-gen-setting-button-group">
        <Row align="middle">
          <Col xs={{ span: 24 }} lg={{ span: 15 }} className="save-actions">
            {isDraft && !isEdited.state && (
              <>
                {subSectionPermission && subSectionPermission.applyAll && (
                  <Button onClick={showModal}>Apply</Button>
                )}
              </>
            )}
            {isDraft && (
              <div className="info">
                <WarningFilled style={{ color: 'red', fontSize: 20 }} />
                <Typography.Text strong>
                  Un-applied changes exist
                </Typography.Text>
              </div>
            )}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 9 }} className="local-actions">
            {isDraft && (
              <Popconfirm
                title="Are you sure?"
                onConfirm={onConfirmDiscard}
                okText="Yes"
                cancelText="No"
              >
                <Button>Discard Changes</Button>
              </Popconfirm>
            )}
            {dataset &&
              'factors' in dataset &&
              subSectionPermission &&
              subSectionPermission[configMapper[section]] && (
                <Button
                  type="primary"
                  onClick={() => saveSettingsData('draft')}
                >
                  Save
                </Button>
              )
            }
          </Col>
        </Row>
      </div>
      <SettingApplyPopup
        setIsModalVisible={setIsModalVisible}
        saveSettingsData={saveSettingsData}
        isModalVisible={isModalVisible}
        showModal={showModal}
      />
    </>
  );
};

SettingButtonGroup.propTypes = {
  section: PropTypes.string,
  subSection: PropTypes.string,
};

export default SettingButtonGroup;
