import {
  FETCHING_USER_ACCOUNTS,
  FETCH_USER_MANAGEMENT_TABLE_DATA,
  FETCH_USER_MANAGEMENT_TABLE_DATA_LOADING,
  RESET_SEARCH_USERS_DATA,
  RESET_USER_MANAGEMENT_COURSE_DATA,
  SEARCH_USERS_DATA,
  SEARCH_USERS_DATA_LOADING,
  USER_ACCOUNTS_DATA,
  USER_MANAGEMENT_COURSE_DATA,
  USER_MANAGEMENT_COURSE_DATA_LOADING,
  USER_MANAGE_CHECK_USER_ROLE_DATA,
  USER_MANAGE_CHECK_USER_ROLE_LOADING,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";
import { showNotification } from "../../utils/general";
import { onError, onSuccess } from "../../utils/notificationHandler";

const fetchingUsers = () => dispatch => {
  dispatch({
    type: FETCH_USER_MANAGEMENT_TABLE_DATA_LOADING,
    payload: {
      isLoading: true,
    }
  });
};

const setUserManagementData = data => dispatch => {
  const { error, response } = data;
  dispatch({
    type: FETCH_USER_MANAGEMENT_TABLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response.data,
    }
  });
  if (error) {
    return onError("Fetching Data Failed!");
  }
};

export const getAccountData = () => dispatch => {
  dispatch(fetchingUsers());

  const feedReducer = response => {
    dispatch(setUserManagementData(response));
  }

  const requestOptions = {
    service: "insights/userManage/getAccountData",
    method: "POST",
    authorized: true,
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: error.message }))
};

export const onUserDelete = data => dispatch => {
  const feedReducer = ({response, error}) => {
    if (error) {
      return onError("User Delete Action Failed!!");
    }

    showNotification({
      message: 'Success',
      description: 'Updated Successful!!'
    });
    dispatch(getAccountData());
  }

  const requestOptions = {
    service: "insights/userManage/removeAccountUser",
    method: "POST",
    data,
    authorized: true,
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: true }))
};

const fetchingUserAccounts = () => dispatch => {
  dispatch(
    {
      type: FETCHING_USER_ACCOUNTS,
      payload: { isLoading: true }
    }
  );
}

const userAccountsData = data => dispatch => {
  const { error, response } = data;

  dispatch(
    {
      type: USER_ACCOUNTS_DATA,
      payload: {
        isLoading: false,
        error: error,
        data: response?.data.data,
      }
    }
  );
}

export const fetchAddUserPopupAvailableAccounts = () => dispatch => {
  dispatch(fetchingUserAccounts());

  const feedReducer = response => {
    dispatch(userAccountsData(response))
  }

  const requestOptions = {
    service: "insights/userManage/fetchAccountDetails",
    method: "POST",
    authorized: true,
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: error.message }))
};

export const onAddUserSubmit = data => dispatch => {
  const feedReducer = (responseData) => {
    const { error, response } = responseData;
    if (response) {
      dispatch(getAccountData());
      return
    }
    if (error) {
      onError("Adding user Failed!");
    }
  }

  const requestOptions = {
    service: "insights/userManage/saveUserAccounts",
    method: "POST",
    authorized: true,
    data
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: error.message }))
};

const searchedUserLoading = () => dispatch => {
  dispatch(
    {
      type: SEARCH_USERS_DATA_LOADING,
      payload: {
        isLoading: true,
        error: false,
        data: [],
      }
    }
  );
}

export const searchedUserData = data => dispatch => {
  const { error, response } = data;
  dispatch(
    {
      type: SEARCH_USERS_DATA,
      payload: {
        isLoading: false,
        error: error,
        data: response.data.data,
      }
    }
  );
}

export const resetSearchedUserData = () => dispatch => {
  dispatch({
    type: RESET_SEARCH_USERS_DATA,
  });
};

export const getAccountBaseUser = data => dispatch => {
  dispatch(searchedUserLoading());

  const feedReducer = response => {
    dispatch(searchedUserData(response));
  }

  const requestOptions = {
    service: "insights/userManage/getAccountBaseUsers",
    method: "POST",
    authorized: true,
    data,
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: error.message }))
};

export const validateUserLoading = (loading = true) => dispatch => {
  dispatch(
    {
      type: USER_MANAGE_CHECK_USER_ROLE_LOADING,
      payload: {
        isLoading: loading,
        error: false,
        data: {},
      }
    }
  );
};

const validateUserData = data => dispatch => {
  const { error, response } = data;
  dispatch(
    {
      type: USER_MANAGE_CHECK_USER_ROLE_DATA,
      payload: {
        isLoading: false,
        error: error,
        data: response,
      }
    }
  );
};

export const validateSelectedUser = data => dispatch => {
  dispatch(validateUserLoading());

  const feedReducer = response => {
    dispatch(validateUserData(response));
  }

  const requestOptions = {
    service: "insights/userManage/checkUserRole",
    method: "POST",
    authorized: true,
    data,
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response: response.data }))
    .catch(error => feedReducer({ error }))
};

const getUserManagementCoursesLoading = ({ tab = "all" }) => dispatch => {
  dispatch(
    {
      type: USER_MANAGEMENT_COURSE_DATA_LOADING,
      payload: {
        tab,
        isLoading: true,
        error: false,
      }
    }
  );
}

export const getUserManagementCoursesData = ({ error, response, tab, searchValue = "" }) => dispatch => {
  dispatch(
    {
      type: USER_MANAGEMENT_COURSE_DATA,
      payload: {
        searchValue,
        tab,
        isLoading: false,
        error: error,
        data: response?.data.data,
      }
    }
  );
}

export const getUserManagementCourses = data => dispatch => {
  const { searchValue, userIds, ...rest} = data;
  dispatch(getUserManagementCoursesLoading(data));

  const feedReducer = response => {
    dispatch(getUserManagementCoursesData({ ...response, tab: data.tab }));
  }

  const requestOptions = {
    service: "insights/userManage/getAccountBaseCourses",
    method: "POST",
    authorized: true,
    data: {
      ...rest,
      searchValue,
      userIds: userIds.length === 1 ? userIds[0]: userIds,
    },
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response, searchValue }))
    .catch(error => feedReducer({ error: error.message }))
};

export const saveCourseChanges = data => dispatch => {
  const { reqData, fetchPayload, fetchCallBackFunction, resetTempCallBack, resetCourseCallBack } = data;

  const { userIds, ...rest } = reqData;

  const feedReducer = ({ response, error }) => {
    if (error) {
      return onError("Course Update Failed!!")
    };

    onSuccess("Updated Successful!!");
    dispatch(resetCourseCallBack());
    resetTempCallBack();
    fetchCallBackFunction(fetchPayload);
  };

  const requestOptions = {
    service: "insights/userManage/saveCourseAccess",
    method: "POST",
    authorized: true,
    data: {
      ...rest,
      userIds: userIds.length === 1 ? userIds[0] : userIds,
    },
  };

  service.make(requestOptions)
    .then(response => feedReducer({ response }))
    .catch(error => feedReducer({ error: true }))
};

export const resetCourseData = () => dispatch => {
  dispatch(
    {
      type: RESET_USER_MANAGEMENT_COURSE_DATA,
    }
  );
}