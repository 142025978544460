import React from 'react';
import PropTypes from 'prop-types';
import noDataImage from '../../../images/other-images/no-data.png';

const NoDataOverlay = props => {
  const {
    message,
    mainClass,
    subClass,
    imageWidth,
    overlayImage
  } = props;

  return (
    <div className={mainClass}>
      <div className={subClass}>
        <img src={overlayImage} alt="noDataImage" width={imageWidth} />
        <h3>{message}</h3>
      </div>
    </div>
  );
};

NoDataOverlay.defaultProps = {
  mainClass: 'in-container',
  subClass: 'in-section-no-data',
  message: 'No course data',
  imageWidth: '60px',
  overlayImage: noDataImage,
};

NoDataOverlay.propTypes = {
  message: PropTypes.string,
  mainClass: PropTypes.string,
  subClass: PropTypes.string,
  imageWidth: PropTypes.string,
  overlayImage: PropTypes.string,
};

export default NoDataOverlay;
