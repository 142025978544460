import {
    STUDENT_FETCH_ASSIGNMENTS,
    STUDENT_FETCH_ASSIGNMENTS_LOADING,
    STUDENT_FETCH_ASSIGNMENTS_INFORMATION,
    STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING,
    STUDENT_FETCH_COURSES_LOADING,
    STUDENT_FETCH_COURSES
} from "../../constant/actionTypes";
import request from "../../utils/request";
import {getAccessToken} from "../../utils/general";

// Fetch all
// handle fetch loading state for assignments fetch request
export const fetchingAssignments = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_ASSIGNMENTS_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the assignments fetch request
export const setAssignmentsFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_ASSIGNMENTS,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle assignments fetch request
export const fetchAssignments = (payload) => dispatch => {
    dispatch(fetchingAssignments());

    const feedReducer = (response) => {
        dispatch(setAssignmentsFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/assignment_profile/assignments_details/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// handle fetch loading state for assignments Information fetch request
export const fetchingAssignmentsInformation = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the assignments Information fetch request
export const setAssignmentsInformationFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_ASSIGNMENTS_INFORMATION,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle assignments Information fetch request
export const fetchAssignmentsInformation = (payload) => dispatch => {
    dispatch(fetchingAssignmentsInformation());

    const feedReducer = (response) => {
        dispatch(setAssignmentsInformationFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/assignment_profile/assignment_information/${getAccessToken()}`,
        method: "POST",
        data: {...payload.requestParams},
        authorized: true,
        onSuccess: feedReducer,
    };

    request.make(requestOptions);
};

// fetch courses
// handle fetch loading state for student courses fetch request
export const fetchingStudentCourses = (isLoading = true) => dispatch => {
    dispatch({
        type: STUDENT_FETCH_COURSES_LOADING,
        payload: {
            isLoading
        }
    });
};

// handle response of the student courses fetch request
export const setStudentsCoursesFetchStatus = data => dispatch => {
    const {isLoading, error, response} = data;
    dispatch({
        type: STUDENT_FETCH_COURSES,
        payload: {
            isLoading,
            error,
            response,
        }
    });
};

// handle student courses fetch request
export const fetchStudentsCourses = (data) => dispatch => {
    dispatch(fetchingStudentCourses());

    const feedReducer = (response) => {
        dispatch(setStudentsCoursesFetchStatus(response));
    }

    const requestOptions = {
        service: `insights/student_profile/search_courses/${getAccessToken()}`,
        method: "POST",
        data,
        authorized: true,
        onSuccess: feedReducer,
        onError: feedReducer,
    };

    request.make(requestOptions);
};