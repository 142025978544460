import React, { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as d3 from 'd3';
import { Switch, Row } from 'antd';
import { useD3 } from '../../../../utils/customHooks';
import CustomAvatar from './partials/CustomAvatar';
import DetailModal from './partials/DetailModal';
import { getPlotLines } from './utils/ChartData';
import { get } from 'lodash';

const fullHeight = 600;
const margin = { top: 20, right: 20, bottom: 30, left: 55 };
const height = fullHeight - margin.top - margin.bottom;
const MIN_CIRCLE_R = 15;
const MAX_CIRCLE_R = 25;
const R_DIFFERENCE = MAX_CIRCLE_R - MIN_CIRCLE_R;

const ClusterChart = ({ data = [], tabRef, axisData }) => {
  const divRef = useRef(null);
  const fullWidth = tabRef.current?.offsetWidth;
  const [width, setWidth] = useState(fullWidth - (margin.left + margin.right));
  const { max_x_axis_percentage, max_y_axis_percentage } = axisData;

  const calculateNewR = zoom_k => {
    const radius = MIN_CIRCLE_R + ((R_DIFFERENCE / (MAX_CIRCLE_R + MIN_CIRCLE_R)) * zoom_k);
    return radius;
  };

  useEffect(() => {
    let timeout;
    const updateWidth = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setWidth(divRef.current?.offsetWidth - (margin.left + margin.right));
      }, 200)
    };
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, []);

  const [showUserLabels, setShowUserLabels] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedNode, setSelectedNode] = useState([])
  const [selectedNodeColor, setSelectedNodeColor] = useState('')

  const onItemClick = (users, color, value) => {
    // show popups
    setSelectedNode({ users, value });
    setModalStatus(true);
    setSelectedNodeColor(color)
  };

  const onShowNamesSwitch = value => {
    setShowUserLabels(value);
  };

  const getCircleStroke = items => {
    if (items.length > 1) {
      return 3
    }

    return 2;
  };

  //equal to (0,0)
  const d3ChatInitCoordinates = {
    y: height - 50,
    x: margin.left,
  };

  //cluster view padding
  const paddingLeft = 5;
  const paddingTop = 0;

  const formatYTickValue = value => {
    if (value !== 0) {
      return `${value} %`;
    }
  };

  // updates the chart when the user zoom
  const updateChart = ({
    e,
    xAxisScale,
    yAxisScale,
    xAxisGroup,
    yAxisGroup,
    clusterChartViewGroup,
    line,
    clusterGroups,
    clusterGroupsPath,
    labelGroup,
  }) => {
    clusterChartViewGroup.selectAll('path').remove();
    clusterGroups.selectAll('*').remove();
    clusterGroupsPath.selectAll('*').remove();
    labelGroup.selectAll('*').remove();

    const zoomConst = get(e, "transform.k", 1);
    const circle_r = calculateNewR(zoomConst);
    // recover the new scale
    let newXAxisScale = e.transform.rescaleX(xAxisScale);
    let newYAxisScale = e.transform.rescaleY(yAxisScale);

    // update axes with new boundaries
    xAxisGroup.call(d3
      .axisBottom(newXAxisScale)
      .tickFormat(value => `${value} %`)
      .scale(newXAxisScale)
    );

    yAxisGroup.call(d3
      .axisLeft(newYAxisScale)
      .tickFormat(value => formatYTickValue(value))
      .scale(newYAxisScale)
    );

    // plotLines
    line = d3
      .line()
      .x(d => newXAxisScale(d.engagement))
      .y(d => newYAxisScale(d.performance));

    getPlotLines({
      max_y_axis_percentage,
      max_x_axis_percentage,
    }).forEach(lineObj => {
      clusterChartViewGroup
        .append('path')
        .datum(lineObj.coordinates)
        .attr('fill', 'none')
        .attr('stroke', lineObj.color)
        .attr('class', 'plot-line')
        .attr('stroke-width', lineObj.width)
        .attr('d', line)
    });

    renderClusterGroups({
      clusterGroupsPath,
      clusterGroups,
      line,
      xAxisScale: newXAxisScale,
      yAxisScale: newYAxisScale,
      labelGroup,
      circle_r,
    })
  };

  const renderClusterGroups = ({
    clusterGroupsPath,
    clusterGroups,
    line,
    xAxisScale,
    yAxisScale,
    labelGroup,
    circle_r = MIN_CIRCLE_R
  }) => {
    data.length && data.forEach((groupObj, groupIndex) => {
      const { values, group, color } = groupObj;
      const averageData = groupObj.center

      values.forEach((value, index) => {
        // lines connected to cluster elements with center
        const lineData = [value, averageData[0]];
        const key = `score_${groupIndex}_${index}`;
        const { users } = value;
        const avatar = ReactDOMServer.renderToString(
          <CustomAvatar
            list={users}
            color={color}
            size={circle_r * 2}
          />
        );

        clusterGroupsPath
          .append('path')
          .datum(lineData)
          .attr('fill', 'none')
          .attr('stroke', '#000000')
          .attr('stroke-dasharray', '2 5')
          .attr('stroke-width', 2)
          .attr('d', line);

        // create cluster elements
        clusterGroups
          .selectAll(`.dot-${key} item-dots`)
          .data([value])
          .enter()
          .append('circle')
          .attr('class', `dot-${key}`)
          .attr('r', circle_r + getCircleStroke(users) / 2)
          .attr('cx', xAxisScale(value.engagement))
          .attr('cy', yAxisScale(value.performance))
          .style('stroke', color)
          .style('fill', '#FFFFFF')
          .style('stroke-width', getCircleStroke(users) + "px")

        // if 10 is radius of the circle. width and height of the image should be twice as radius
        clusterGroups.append("foreignObject")
          .attr("x", xAxisScale(value.engagement) - circle_r)
          .attr("y", yAxisScale(value.performance) - circle_r)
          .attr("class", 'avatar-tag')
          .attr("width", `${(circle_r * 2) + getCircleStroke(users)}px`)
          .attr("height", `${(circle_r * 2) + getCircleStroke(users)}px`)
          .html(avatar)
          .style("cursor", 'pointer')
          .on('click', () => { onItemClick(users, color, value) });

        showUserLabels && users.length === 1 && (
          labelGroup.append("foreignObject")
            .attr("x", xAxisScale(value.engagement) - 50)
            .attr("y", yAxisScale(value.performance) + 30)
            .attr("class", 'name-tag')
            .html(`<span>${users[0].name}</span>`)
        )
      });

      clusterGroups
        .selectAll(`.dot-${group}`)
        .data(averageData)
        .enter()
        .append('circle')
        .attr('class', `dot-${group}`)
        .attr('r', 5)
        .attr('cx', d => xAxisScale(d.engagement))
        .attr('cy', d => yAxisScale(d.performance))
        .style('fill', color);
    });

    clusterGroups.raise();
  };

  const svgRef = useD3(svgViewPort => {
    svgViewPort
      .attr('width', width)
      .attr('height', height)
      .style("border", "1px solid #ddd")

    svgViewPort
      .append('text')
      .attr('y', height - 28)
      .attr('x', width - 70)
      .attr('dy', '1em')
      .attr('class', 'axis-label')
      .text('ENGAGEMENT')
      .style('font-weight', 'bold')

    svgViewPort
      .append('text')
      .attr('y', 2)
      .attr('x', -62)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .attr('class', 'axis-label')
      .text('PERFORMANCE')
      .attr('transform', 'rotate(-90)')
      .style('font-weight', '600')

    let clusterChartViewGroup = svgViewPort.append('g');

    clusterChartViewGroup
      .attr('transform', 'translate(' + paddingLeft + ',' + paddingTop + ')')

    // axis render
    const xAxisScale = d3.scaleLinear()
      .range([d3ChatInitCoordinates.x, width])
      .domain([0, max_x_axis_percentage]);

    const yAxisScale =
      d3.scaleLinear()
        .range([d3ChatInitCoordinates.y, margin.top])
        .domain([0, max_y_axis_percentage]);

    // Append or select x-axis group
    let xAxisGroup = clusterChartViewGroup.select('.x-axis');
    if (xAxisGroup.empty()) {
      xAxisGroup = clusterChartViewGroup.append('g')
        .attr('transform', `translate(0, ${d3ChatInitCoordinates.y})`)
        .attr('class', 'x-axis');
    }

    // Append or select y-axis group
    let yAxisGroup = clusterChartViewGroup.select('.y-axis');
    if (yAxisGroup.empty()) {
      yAxisGroup = clusterChartViewGroup.append('g')
        .attr('transform', `translate(${margin.left}, 0)`)
        .attr('class', 'y-axis');
    }

    xAxisGroup.call(d3.axisBottom(xAxisScale).tickFormat(value => `${value} %`));
    yAxisGroup.call(d3.axisLeft(yAxisScale).tickFormat(value => formatYTickValue(value)));

    // plotLines
    let line = d3
      .line()
      .x((d) => xAxisScale(d.engagement))
      .y((d) => yAxisScale(d.performance));

    getPlotLines({
      max_y_axis_percentage,
      max_x_axis_percentage,
    }).forEach(lineObj => {
      clusterChartViewGroup
        .append('path')
        .datum(lineObj.coordinates)
        .attr('fill', 'none')
        .attr('stroke', lineObj.color)
        .attr('class', 'plot-line')
        .attr('stroke-width', lineObj.width)
        .attr('d', line)
    });

    let clusterGroups = clusterChartViewGroup.append('g');
    let clusterGroupsPath = clusterChartViewGroup.append('g');
    let labelGroup = clusterChartViewGroup.append('g');

    renderClusterGroups({
      line,
      xAxisScale,
      yAxisScale,
      clusterGroups,
      clusterGroupsPath,
      labelGroup,
    });

    let zoom = d3.zoom()
      .scaleExtent([1, 30])  // This control how much you can unzoom (x1) and zoom (x30)
      .extent([
        [margin.left, margin.top],
        [width, height - (margin.bottom + margin.top)]
      ]) // Set zoom & pan extent boundaries
      .translateExtent([
        [margin.left, margin.top],  // Minimum translation for x and y
        [width, height - (margin.bottom + margin.top)] // Maximum translation for x and y
      ])
      .on("zoom", e => updateChart({
        e,
        xAxisScale,
        yAxisScale,
        xAxisGroup,
        yAxisGroup,
        clusterChartViewGroup,
        line,
        clusterGroups,
        clusterGroupsPath,
        labelGroup,
      }));

    clusterChartViewGroup
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .call(zoom);
  },
    data,
    showUserLabels,
    width,
  );

  return (
    <div ref={divRef}>
      <div className='clusterSection' >
        <svg
          ref={svgRef}
          width={width}
          height={fullHeight}
          className='chartContainer'
        />
        <Row className='bottom-row'>
          <div className='name-switch'>
            <Switch
              checked={showUserLabels}
              onChange={onShowNamesSwitch}
              size='small'
            />
            <p>Show Names</p>
          </div>
          <Row className='legend'>
            <div className='legend-item'>
              <span className='legend-circle low-low' />
              <div className='legend-label'>
                <p>Low Engagement</p>
                <p>Low Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle high-low' />
              <div className='legend-label'>
                <p>High Engagement</p>
                <p>Low Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle black low-high' />
              <div className='legend-label'>
                <p>Low Engagement</p>
                <p>High Performance</p>
              </div>
            </div>
            <div className='legend-item'>
              <span className='legend-circle high-high' />
              <div className='legend-label'>
                <p>High Engagement</p>
                <p>High Performance</p>
              </div>
            </div >
          </Row>
        </Row>
        <DetailModal
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          selectedNodeColor={selectedNodeColor}
          setSelectedNodeColor={setSelectedNodeColor}
        />
      </div>
    </div>
  );
};

export default ClusterChart;