import _get from "lodash/get";
import { createSelector } from 'reselect';

// return innovative student main report response
export const makeInnovativeStudentTermsResponse = state => _get(state, 'innovative.student.terms');

// return innovative student main report response loading status
export const makeInnovativeStudentTermsLoading = state => {
    const result = makeInnovativeStudentTermsResponse(state);
    return result && result.isLoading;
};

// return innovative student main report response data
export const makeInnovativeStudentTermsResponseData = state => {
    const result = makeInnovativeStudentTermsResponse(state);
    return _get(result, 'response.data');
};

// return innovative student main report response
export const makeInnovativeStudentMainReportResponse = state => _get(state, 'innovative.student.mainReport');

// return innovative student main report response loading status
export const makeInnovativeStudentMainReportLoading = state => {
    const result = makeInnovativeStudentMainReportResponse(state);
    return result && result.isLoading;
};

// return innovative student main report response data
export const makeInnovativeStudentMainReportResponseData = state => {
    const result = makeInnovativeStudentMainReportResponse(state);
    return _get(result, 'response.data.result');
};

// return innovative student main report course comparison response data
export const makeInnovativeStudentMainReportCourseComparisonResponseData = state => {
    const result = makeInnovativeStudentMainReportResponseData(state);
    return _get(result, 'course_comparison', []);
};

// return innovative student main report course comparison response data
export const makeInnovativeStudentMainReportSummaryCardsResponseData = state => {
    const result = makeInnovativeStudentMainReportResponseData(state);
    return _get(result, 'course_cards', []);
};

// return innovative student main report assignment submission response data
export const makeInnovativeStudentMainReportAssignmentSubmissionResponseData = state => {
    const result = makeInnovativeStudentMainReportResponseData(state);
    return _get(result, 'overall_assignment_submission', []);
};

// return innovative student main report course grade response data
export const makeInnovativeStudentMainReportCourseGradeResponseData = state => {
    const result = makeInnovativeStudentMainReportResponseData(state);
    return _get(result, 'overall_course_grade', []);
};

// return innovative student main report response error
export const makeInnovativeStudentMainReportError = state => {
    const result = makeInnovativeStudentMainReportResponse(state);
    return result.error;
};

// return innovative student main report outcomes response data
export const makeInnovativeStudentMainReportOutcomesResponseData = state => {
    const result = makeInnovativeStudentMainReportResponseData(state);
    return _get(result, 'outcomes', []);
};


// return innovative student due assignment response
export const makeInnovativeStudentDueAssignmentsResponse = state => _get(state, 'innovative.student.dueAssignments');

// return innovative assignment response loading status
export const makeInnovativeStudentDueAssignmentsLoading = state => {
    const result = makeInnovativeStudentDueAssignmentsResponse(state);
    return result && result.isLoading;
};

// return innovative assignment response data
export const makeInnovativeStudentDueAssignmentsResponseData = state => {
    const result = makeInnovativeStudentDueAssignmentsResponse(state);
    return _get(result, 'response.data', {});
};

// return innovative assignment response error
export const makeInnovativeStudentDueAssignmentsError = state => {
    const result = makeInnovativeStudentDueAssignmentsResponse(state);
    return result.error;
};


// return innovative student response
export const makeInnovativeStudentResponse = state => _get(state, 'innovative.student.selected');

// return innovative student response loading status
export const makeInnovativeStudentLoading = state => {
    const result = makeInnovativeStudentResponse(state);
    return result && result.isLoading;
};

// return innovative student response data
export const makeInnovativeStudentResponseData = state => {
    const result = makeInnovativeStudentResponse(state);
    return _get(result, 'response.data.result', {});
};

// return innovative student response error
export const makeInnovativeStudentError = state => {
    const result = makeInnovativeStudentResponse(state);
    return result.error;
};

// return innovative student cohort data response
export const makeInnovativeStudentCohortDataResponse = state => _get(state, 'innovative.student.cohortData');

// return innovative student gauge data response
export const makeInnovativeStudentGaugeDataResponse = state => _get(state, 'innovative.student.gaugeData');

// return innovative student due assignment response
export const makeInnovativeStudentSearchResponse = state => _get(state, 'innovative.student.searchStudents');

// return innovative assignment response loading status
export const makeInnovativeStudentSearchLoading = createSelector(
    makeInnovativeStudentSearchResponse, data => _get(data, 'isLoading', false),
);

// return innovative assignment response data
export const makeInnovativeStudentSearchResponseData = createSelector(
    makeInnovativeStudentSearchResponse, data => _get(data, 'response.data.result', []),
);

// return innovative assignment response error
export const makeInnovativeStudentSearchError = createSelector(
    makeInnovativeStudentSearchResponse, data => _get(data, 'error', false),
);
