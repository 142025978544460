import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  getDashboardFiltersSelectedData,
  getComplianceOverviewData,
  getComplianceOverviewLoading,
  getComplianceOverviewDurationToExpire,
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchComplianceOverview,
  fetchComplianceOverviewDurationToExpire,
} from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/compliance.action';

import Overview from './Overview';
import PopupTable from '../../../../../common/popupTable/PopupTable';
import StudentDetailCell from '../../../../../common/StudentDetailCell';

import {
  getUniqValuesForFilters,
  isEmptyValue,
} from '../../../../../../../../utils/general';
import {
  subSections,
  noDataValue,
} from '../../../../../../../../../../../constant/vetTrack/analysis';
import NoDataCell from '../../../../../../../../common/partials/NoDataCell';

const OverviewTab = () => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});

  // selectors
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const overviewDataLoading = useSelector(getComplianceOverviewLoading);
  const overviewData = useSelector(getComplianceOverviewData);
  const durationToExpireData = useSelector(
    getComplianceOverviewDurationToExpire,
  );

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading) {
      const { compliance } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: compliance.id,
      };
      dispatch(fetchComplianceOverview(data));
    }
  }, [selectedFilters]);

  const onPopupOpen = key => {
    setShowPopup(true);
    setSelectedKey(key);
    const selectedData = tableConfigMapper[key];

    const { compliance } = subSections;
    const data = {
      filters: selectedFilters,
      subSectionId: compliance.id,
      popupType: key,
    };

    if (selectedData && selectedData.dispatcher) {
      dispatch(selectedData.dispatcher(data));
    }
    setSelectedConfig(tableConfigMapper[key]);
  };

  const onPopupClose = () => {
    setShowPopup(false);
    setSelectedKey(null);
    setSelectedConfig({});
  };

  const getDataSource = () => {
    if (selectedKey && selectedKey === 'durationToExpire') {
      return durationToExpireData;
    }
    return {};
  };

  const commonCellRender = (value, isExpireDate = false) => {
    if (value === undefined || value === null || value === '') {
      return <NoDataCell />
    }

    if (isExpireDate && parseInt(value) < 1) {
      return (
        <div className="vtt-cell-no-data visa-error">
          <ExclamationCircleOutlined />
          <p>Expired</p>
        </div>
      );
    }

    return value;
  };

  const expirationSorter = (row1, row2) => {
    const value1 = _get(row1, 'expirationDate', -1);
    const value2 = _get(row2, 'expirationDate', -1);

    return value1 - value2;
  };

  const onFilterColumns = (value, row, key) => {
    const recordValue = _get(row, `${key}`);

    if (value === noDataValue) {
      return isEmptyValue(recordValue);
    }

    return value === recordValue;
  };

  const renderPopupCustomColumns = () => {
    const dataSource = getDataSource().data?.list;
    const configKey = _get(selectedConfig, 'key', '');

    if (configKey === 'durationToExpire') {
      return [
        {
          title: 'Days to Expire',
          dataIndex: 'expirationDate',
          sorter: expirationSorter,
          onFilter: (value, row) => onFilterColumns(value, row, 'expirationDate'),
          filters: getUniqValuesForFilters(
            dataSource,
            'expirationDate',
            'expirationDate',
          ),
          render: value => commonCellRender(value, true),
        },
        {
          title: 'Type',
          dataIndex: 'type',
          sorter: (value1, value2) => value1.type && value1.type.localeCompare(value2.type),
          onFilter: (value, row) => onFilterColumns(value, row, 'type'),
          filters: getUniqValuesForFilters(dataSource, 'type', 'type'),
          render: value => commonCellRender(value),
        },
        {
          title: 'Category',
          dataIndex: 'category',
          sorter: (value1, value2) =>
            value1.category &&
            value1.category.localeCompare(value2.category),
          onFilter: (value, row) => onFilterColumns(value, row, 'category'),
          filters: getUniqValuesForFilters(dataSource, 'category', 'category'),
          render: value => commonCellRender(value),
        },
      ];
    }

    return [];
  };

  const renderPopupColumns = () => {
    const dataSource = getDataSource().data?.list;
    return [
      {
        title: 'Student Details',
        dataIndex: 'studentDetails',
        sorter: (value1, value2) =>
          value1.studentDetails.name &&
          value1.studentDetails.name.localeCompare(value2.studentDetails.name),
        render: value => (
          <StudentDetailCell
            title={value.name}
            subtitle={value.id}
            avatar={value.image}
          />
        ),
        onFilter: (value, record) => record.studentDetails.name === value,
        filters: getUniqValuesForFilters(
          dataSource,
          'studentDetails.name',
          'studentDetails.name',
        ),
      },
      ...renderPopupCustomColumns(),
    ];
  };

  const tableConfigMapper = {
    durationToExpire: {
      title: 'Duration to Expire',
      key: 'durationToExpire',
      dispatcher: data => fetchComplianceOverviewDurationToExpire(data),
      searchableColumns: ['studentDetails.name'],
    },
  };

  return (
    <>
      <Overview
        onPopupOpen={onPopupOpen}
        lineChartData={_get(overviewData, 'lineChartData', null)}
        pieChartData={_get(overviewData, 'pieChartData', null)}
        barChartData={_get(overviewData, 'durationToExpire', null)}
        dataLoading={overviewDataLoading}
      />
      <PopupTable
        isLoading={getDataSource().isLoading}
        visible={showPopup}
        onCancel={onPopupClose}
        searchEnabled
        title={selectedConfig.title}
        columns={renderPopupColumns()}
        dataSource={getDataSource().data?.list}
        searchableColumns={selectedConfig.searchableColumns}
      />
    </>
  );
};

export default OverviewTab;
