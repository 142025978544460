import React, {Fragment, useState} from "react";
import {LogoutOutlined} from "@ant-design/icons";
import {useSelector, useDispatch} from "react-redux";
import {Button} from "antd";
import {useHistory} from "react-router-dom";

import {getSubUserIsLoggedIn} from "../../../selectors/onboarding/auth.selector";
import {removeSubUser} from "../../../actions/authentication.action"
import ConfirmBox from "../../widgets/common/confirm_box";
import { GET } from "../../../utils/network";

const SignOutContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLoggedIn = useSelector(getSubUserIsLoggedIn)
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)

    const onLogoutClicked = () => {
        setShowLogoutConfirm(false);
        GET(`${process.env.REACT_APP_APIURL}logout`)
        .then(()=>{
            dispatch(removeSubUser())
        })
    }

    return (
        <Fragment>
            <div>
                {
                    // show logout icon if logged in
                    userLoggedIn &&
                    <Button title={"Logout"} type="ghost" icon={<LogoutOutlined />} onClick={() => setShowLogoutConfirm(true)}>Logout</Button>
                }
            </div>
            <ConfirmBox
                topic="Logout"
                message="Are you sure?"
                confirmButtonColor="danger"
                confirmButtonText="Logout"
                show={showLogoutConfirm}
                onHide={() => setShowLogoutConfirm(false)}
                onConfirm={onLogoutClicked}/>
        </Fragment>


    )
}

export default SignOutContainer;