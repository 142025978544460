import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Form, Input, Row, Button, InputNumber } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSubUserIsLoggedIn, getSubUserIsVerified, getSubUser } from "../../../selectors/onboarding/auth.selector";
import { sendVerificationCode, validateVerificationCode } from "../../../actions/authentication.action";
import { getLicenseManagerVerificationData, getLicenseManagerSendCodeIsLoading, getLicenseManagerValidateCodeIsLoading } from "../../../selectors/consumer.selector";
import Loader from "../common/layout/Loader";
const PartnerVerification = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const sendCodeLoading = useSelector(getLicenseManagerSendCodeIsLoading);
  const validateCodeLoading = useSelector(getLicenseManagerValidateCodeIsLoading);
  const subUserResponse = useSelector(getSubUserIsLoggedIn);
  const subUserIsVerified = useSelector(getSubUserIsVerified);
  const verificationData = useSelector(getLicenseManagerVerificationData);
  const subUser = useSelector(getSubUser);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [verificationRequested, setVerificationRequested] = useState(false);
  const redirection = useCallback(() => {
    history.push('/setup/consumer');
  }, [history]);

  if (sendCodeLoading || validateCodeLoading) {
    setLoading(true);
  }

  useEffect(() => {
    if (!verificationRequested && isLoggedIn) {
      dispatch(sendVerificationCode(subUser));
    }
    if (subUserResponse && subUserIsVerified && isLoggedIn) {
      redirection();
    }
    setVerificationRequested(true)

  }, [redirection, subUserResponse, subUserIsVerified, verificationRequested, subUser, dispatch, isLoggedIn])
  const onSubmitHandler = (data) => {
    const validationData = { ...data, verificationId: verificationData }
    dispatch(validateVerificationCode(validationData, () => {
      redirection();
    }))
  }

  return (
    <Fragment>
      <div className="partner-portal-form">
        <div className="partner-portal-form-heading">Verify your account</div>
        <div className="partner-portal-form-sub-heading">Enter the verification code that has been sent to your email address.</div>
        <Loader isLoading={loading} />
        <Form
          name="verificationForm"
          layout="vertical"
          initialValues={{ remember: false }}
          onFinish={onSubmitHandler}
          style={{ marginTop: '24px' }}
        >
          <Form.Item
            name="code"
            label="Enter verification code"
            rules={[
              { type: "integer", message: "'Code' entered is not valid" }, { required: true, message: 'This field is required to proceed' }
            ]}>
            <InputNumber
              size={"large"}
              min="1"
              step="1" style={{ display: 'inline-block', width: '100%' }} />
          </Form.Item>

          <Row justify="end">
            <Button type="primary" htmlType="submit">Submit</Button>
          </Row>
        </Form>
      </div>
    </Fragment>
  )
}

export default PartnerVerification;