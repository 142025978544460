import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Title } = Typography;

const NAMessage = ({
  containerProps,
  containerStyles,
  contentProps,
  contentStyles,
}) => (
  <div
    {...containerProps}
    style={{ width: '100%', height: '100%', ...containerStyles }}
  >
    <Title
      {...contentProps}
      style={{ textAlign: 'center', padding: '70px 0', ...contentStyles }}
    >
      N/A
    </Title>
  </div>
);

NAMessage.propTypes = {
  containerProps: PropTypes.object,
  containerStyles: PropTypes.object,
  contentProps: PropTypes.object,
  contentStyles: PropTypes.object,
};

export default NAMessage;
