import React from "react";
import PropTypes from "prop-types";

import CardTable from "./partials/CardTable";

const MainCard = ({data}) => {

    return (
        <div>
            <CardTable data={data}/>
        </div>
    )
}

MainCard.propTypes = {
    data: PropTypes.array
}

export default MainCard;