import React, {useEffect, useState} from "react";
import {Table, Input} from "antd";
import PropTypes from "prop-types";

const { Search } = Input;

const StatTable = ({searchEnable, searchPlaceHolder, dataSource, columns, searchableColumns}) => {
    const [tableDataSource, setTableDataSource] = useState([])

    useEffect(() => {
        setTableDataSource(dataSource)
    }, [dataSource])

    const onSearch = (searchVal) => {
        if (searchVal === "") return setTableDataSource(dataSource)

        if (searchableColumns){

            setTableDataSource(
                dataSource.filter(source => {
                    let valid = false
                    searchableColumns.map(col => {
                        if (source[col].includes(searchVal)){
                            valid = true
                        }
                    })
                    return valid
                })
            )
        }
    }

    const renderSearch = () => (
        searchEnable &&
        <Search placeholder={searchPlaceHolder} onSearch={onSearch} style={{ width: 200 }} />
    )

    const renderTable = () => (
        <Table dataSource={tableDataSource} columns={columns} />
    )
    return (
        <div>
            <div style={{float:"right"}}>
                {renderSearch()}
            </div>
            <div>
                {renderTable()}
            </div>
        </div>
    )
}

StatTable.defaultProps = {
    searchPlaceHolder: "Search",
    searchEnable: true
}

StatTable.propTypes = {
    searchPlaceHolder: PropTypes.string,
    searchEnable: PropTypes.bool,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    searchableColumns: PropTypes.array
}

export default StatTable