import _get from "lodash/get";

// return dashboard manage data
export const makeDashboardSettingsResponse = state => state && state.dashboardManage && state.dashboardManage.settings && state.dashboardManage.settings.selected;

// return innovative settings response loading status
export const makeDashboardSettingsLoading = state => {
    const result = makeDashboardSettingsResponse(state);
    return result && result.isLoading;
};

// return innovative settings response data
export const makeDashboardSettingsResponseData = state => {
    const result = makeDashboardSettingsResponse(state);
    return result && result.response && result.response.data;
};

// return innovative settings response error
export const makeDashboardSettingsError = state => {
    const result = makeDashboardSettingsResponse(state);
    return result.error;
};

// return innovative setting by path
export const makeDashboardSettingByPath = path => state => {
    const result = makeDashboardSettingsResponseData(state);
    return _get(result, path, undefined);
};


// return innovative settings response
export const makeDashboardSettingsSaveResponse = state => state && state.innovative && state.innovative.settings && state.innovative.settings.saved;

// return innovative settings response loading status
export const makeDashboardSettingsSaveLoading = state => {
    const result = makeDashboardSettingsSaveResponse(state);
    return result && result.isLoading;
};

// return innovative settings response data
export const makeDashboardSettingsSaveResponseData = state => {
    const result = makeDashboardSettingsSaveResponse(state);
    return result && result.response && result.response.data;
};

// return innovative settings response error
export const makeDashboardSettingsSaveError = state => {
    const result = makeDashboardSettingsSaveResponse(state);
    return result.error;
};