import React from "react";
import AccManagementProfile from "./AccManagementProfile";
import { withRouter } from 'react-router-dom';
import {useSelector} from "react-redux";

import {interimUserSchool} from "../../../../selectors/tentacle/auth.selector";

const ManagementProfile = (props) => {
    //selectors
    const interimUserSchoolData = useSelector(interimUserSchool);

    if(interimUserSchoolData === "acc"){
        return (
            <AccManagementProfile location={props.location}/>
        );
    }else{
    }
};

export default withRouter(ManagementProfile);