import React from "react";
import {Layout, Card} from "antd";
import {LogoutOutlined, MenuOutlined} from '@ant-design/icons';
import logo from '../../../assets/images/sqLogo.png';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

import {getDomainMetaData} from "../../../selectors/tentacleMeta.selector";

import Sidebar from "../../jrDashboardManagement/common/layout/Sidebar";
import Breadcrumb from "../../jrDashboardManagement/common/layout/Breadcrumb";
import UserLogout from "../../auth/externalAuth/Logout/Logout";

import menuData from "./jrMenuData";
import {getDataFromLocalStore} from "../../../utils/platformBaseHelpers/jobReady/general";

import "../../../assets/css/jrDashboardManagement/main.css";
import "./main.css";

const JRDashboardManagementLayout = ({children}) => {
    const history = useHistory();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);

    const domainData = useSelector(getDomainMetaData);

    const {
        manageDashboard,
        manageUsers,
    } = menuData;

    const adminOnlyItems = [];

    // TODO: remove this once jr_gen user management is done. this is a temp workaround
    if(domainData && domainData.code !== "jr_gen_01"){
        adminOnlyItems.push(manageUsers)
    }

    const prepareSidebarItems = (items) => {
        return items.map(item => ({
            name: item.name,
            key: item.key,
            icon: item.icon,
            click: () => history.push(item.url),
        }))
    }

    const validateSidebarItems = (items) => {
        // check if the user has admin rights and add admin only items
        const {isAdmin} = getDataFromLocalStore([{key:"isAdmin", default: false}]);

        if(isAdmin){
            return [...items, ...adminOnlyItems];
        }
        return items;
    }

    const sidebarItems = prepareSidebarItems(validateSidebarItems([manageDashboard]));

    return (
        <Layout>
            <Layout.Header className="jr-header withMenu" style={{background: "white"}}>
                <div className="mobileNav">
                    <MenuOutlined onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}/>
                </div>
                <div className="logo">
                    <img src={logo} alt="headerLogo"/>
                </div>

                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", height:"100%"}}>
                    <Breadcrumb hideBackButton={true}/>

                    <UserLogout button={
                        ({onClick}) => <span title="Sign out" onClick={onClick} className="jr-setting-icon" style={{border: "none", cursor: "pointer"}}>
                            <LogoutOutlined/>
                        </span>
                    }/>

                </div>



            </Layout.Header>
            <Layout className="jr-sidebarLayout">
                <Sidebar
                    collapsed={isSidebarCollapsed}
                    setCollapsed={setIsSidebarCollapsed}
                    sidebarItems={sidebarItems}
                />
                <Layout.Content className="jr-content-main">
                    <Card>
                        {children}
                    </Card>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default JRDashboardManagementLayout;