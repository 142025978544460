import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UserOutlined, DashboardFilled } from '@ant-design/icons';
import { getSelectedMenu } from '../../../../../selectors/vetTrack/dashboardManagement.selector';

const Sidebar = ({ collapsed, setCollapsed, sidebarItems }) => {
  const [openMenus, setOpenMenus] = useState([]);
  const selectedMenu = useSelector(getSelectedMenu);

  useEffect(() => {
    if (selectedMenu) {
      getOpenMenus(selectedMenu);
    }
  }, [selectedMenu]);

  const renderIcon = (icon) => {
   switch(icon){
    case "UserOutlined":
      return <UserOutlined />
    case "DashboardFilled":
      return <DashboardFilled twoToneColor="#52c41a"/>
    default:  return null;
   }
  }

  // this will identify the menu items that need to be opened
  const getOpenMenus = currentMenu => {
    if (openMenus.includes(currentMenu)) {
      // close the menu if already opened
      return setOpenMenus(
        openMenus.filter(
          (menu, index) => index < openMenus.indexOf(currentMenu),
        ),
      );
    }
    const items = currentMenu.split('.');
    const menuItems = [];
    items.reduce((acc, currValue) => {
      if (acc === null) {
        menuItems.push(currValue);
      } else {
        menuItems.push(`${acc}.${currValue}`);
      }
      return currValue;
    }, null);
    setOpenMenus(menuItems);
  };

  const onBreakPoint = broken => {
    if (broken) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  const onMenuItemClick = menuItemData => {
    getOpenMenus(menuItemData.key);
    if (menuItemData && typeof menuItemData.click === 'function') {
      menuItemData.click();
    }
  };

  const renderMenuItem = menuItemData => (
    <Menu.Item
      className="vtt-menu-item"
      key={menuItemData.key}
      icon={menuItemData.icon && renderIcon(menuItemData.icon)}
      onClick={() => onMenuItemClick(menuItemData)}
    >
      {menuItemData.name}
    </Menu.Item>
  );

  const renderSubMenu = (mainItem, subItems) => (
    <Menu.SubMenu
      key={mainItem.key}
      icon={mainItem.icon && renderIcon(mainItem.icon)}
      title={mainItem.name}
      onTitleClick={() => onMenuItemClick(mainItem)}
    >
      {subItems.map(item =>
        item.subItems
          ? renderSubMenu(item, item.subItems)
          : renderMenuItem(item),
      )}
    </Menu.SubMenu>
  );

  const renderMenu = () =>
    sidebarItems.map(item =>
      item.subItems ? renderSubMenu(item, item.subItems) : renderMenuItem(item),
    );

  return (
    <Layout.Sider
      width={250}
      className="site-layout-background vtt-sider"
      breakpoint="md"
      collapsedWidth="0"
      trigger={null}
      collapsed={collapsed}
      onBreakpoint={onBreakPoint}
    >
      <div className="menuScroller">
        <Menu mode="inline" selectedKeys={selectedMenu} openKeys={openMenus}>
          {renderMenu()}
        </Menu>
      </div>
    </Layout.Sider>
  );
};

Sidebar.defaultProps = {
  sidebarItems: [],
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  sidebarItems: PropTypes.array,
};

export default Sidebar;
