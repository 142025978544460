import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Card, Col, Form, Input, Modal, Row, Select, Space, Typography} from 'antd';

import CustomSelector from "../../../../../../vwDealership/common/CustomSelect";
import Loader from "../../../../../common/layout/Loader";

const {Option} = Select;
const {Text} = Typography;

const AddUserModal = ({isModalVisible, handleOk, handleCancel, form, isLoading, dealerships, roles, modalState}) => {
    const [dealershipsDisabled, setDealershipsDisabled] = useState(false);

    useEffect(() => {
        setDealershipsDisabled(form.getFieldValue('role') === roles.manager.key)
    }, [form.getFieldValue('role')])

    const onRoleChange = (role) => {
        if (roles.manager.key === role){
            form.setFieldsValue({dealerships: dealerships.map(d => d.id)})
        }else{
            form.setFieldsValue({dealerships:[]})
        }
        setDealershipsDisabled(roles.manager.key === role);
    }
    const renderForm = () => (
        <Form
            layout="vertical"
            form={form}
            onFinish={handleOk}
            initialValues={{octopusRole:"manager"}}
        >
            <Row gutter={[24, 24]}>
                <Card style={{ width: "100%" }}>
                    <Space direction="vertical">
                        <Text strong>{modalState === "add" ? "Add New User" : "Edit User"}</Text>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    required
                                    label="First Name"
                                    name="firstName"
                                    rules={[{required: true, message: "Please input first name!"}]}
                                >
                                    <Input placeholder="input placeholder" maxLength={30}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    required
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{required: true, message: "Please input last name!"}]}
                                >
                                    <Input placeholder="input placeholder" maxLength={40}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                required
                                label="Email"
                                name="email"
                                rules={[{required: true, message: "Please input an email!"}]}
                            >
                                <Input placeholder="input placeholder"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Text strong>User Settings</Text>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Octopus Role"
                                    name="role"
                                    rules={[{required: true, message: "Please select a role!"}]}
                                >
                                    <Select style={{ width: "100%" }} onChange={onRoleChange}>
                                        {Object.keys(roles).map(role => (
                                            <Option value={roles[role].key}>{roles[role].name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Dealership"
                                    name="dealerships"
                                    rules={[{required: true, message: "Please select dealership(s)!"}]}
                                    valuePropName="defaultValues"
                                >
                                    <CustomSelector
                                        disabled={dealershipsDisabled}
                                        width="100%"
                                        datalist={dealerships}
                                        paramKey="id"
                                        mode="multiple"
                                        value="name"
                                        maxTagCount={1}
                                        showTitle={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                </Card>
            </Row>
            <br/>
            <Row display="flex" justify="end">
                <Space>
                    <Button htmlType="submit" type="primary">Save</Button>
                    <Button onClick={handleCancel}>Close</Button>
                </Space>
            </Row>
        </Form>
    )

    return (
        <Modal
            maskClosable={false}
            title={modalState === "add" ? "Add New User" : "Edit User"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
        >
            <Loader isLoading={isLoading}/>
            {renderForm()}
        </Modal>
    )
}

AddUserModal.propTypes = {
    isModalVisible: PropTypes.bool,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
    form: PropTypes.object,
    isLoading: PropTypes.bool,
    dealerships: PropTypes.array,
    roles: PropTypes.object,
    modalState: PropTypes.string
}

export default AddUserModal;