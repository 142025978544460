import _get from "lodash/get";
import {makeInnovativeSettingsResponseData} from "../innovative/setting.selector";

// return innovative_student selected course
export const makeInnovativeStudentSelectedCourseResponse = state => _get(state, 'innovativeStudent.course');

// return innovative_student selected course loading
export const makeInnovativeStudentSelectedCourseLoading = state => {
    const result = makeInnovativeStudentSelectedCourseResponse(state);
    return result && result.isLoading;
};

// return innovative_student selected course data
export const makeInnovativeStudentSelectedCourseData = state => {
    const result = makeInnovativeStudentSelectedCourseResponse(state);
    return _get(result, 'response.data.result', {});
};

// return innovative_student selected course settings
export const makeInnovativeStudentSelectedCourseSettingsResponse = state => _get(state, 'innovativeStudent.commonData.courseSettings');

// return innovative_student selected course settings loading
export const makeInnovativeStudentSelectedCourseSettingsLoading = state => {
    const result = makeInnovativeStudentSelectedCourseSettingsResponse(state);
    return result && result.isLoading;
};

// return innovative_student selected course settings data
export const makeInnovativeStudentSelectedCourseSettingsData = state => {
    const result = makeInnovativeStudentSelectedCourseSettingsResponse(state);
    return _get(result, 'response.data', {});
};

// return innovative_student setting by path
export const makeInnovativeSettingByPath = path => state => {
    const result = makeInnovativeStudentSelectedCourseSettingsData(state);
    return _get(result, path, undefined);
};

// return innovative student main report response
export const makeInnovativeStudentTermsResponse = state => _get(state, 'innovativeStudent.commonData.terms');

// return innovative student main report response loading status
export const makeInnovativeStudentTermsLoading = state => {
    const result = makeInnovativeStudentTermsResponse(state);
    return result && result.isLoading;
};

// return innovative student main report response data
export const makeInnovativeStudentTermsResponseData = state => {
    const result = makeInnovativeStudentTermsResponse(state);
    return _get(result, 'response.data');
};

// return acting role response
export const makeActingRoleResponse = state => _get(state, 'innovativeStudent.commonData.actingRole');

// return acting role response loading status
export const makeActingRoleLoading = state => {
    const result = makeActingRoleResponse(state);
    return result && result.isLoading;
};

// return acting role response data
export const makeActingRoleResponseData = state => {
    const result = makeActingRoleResponse(state);
    return _get(result, 'response.data', undefined);
};

// return acting role response error
export const makeActingRoleError = state => {
    const result = makeActingRoleResponse(state);
    return result.error;
};

// return student list response
export const makeStudentListResponse = state => _get(state, 'innovativeStudent.commonData.students');

// return student list response loading status
export const makeStudentListLoading = state => {
    const result = makeStudentListResponse(state);
    return result && result.isLoading;
};

// return student list response data
export const makeStudentListResponseData = state => {
    const result = makeStudentListResponse(state);
    return _get(result, 'response.data', []);
};

// return student list response error
export const makeStudentListError = state => {
    const result = makeStudentListResponse(state);
    return result.error;
};

// return innovative_student selected master settings
export const makeInnovativeStudentSelectedMasterSettingsResponse = state => _get(state, 'innovativeStudent.commonData.masterSettings');

// return innovative_student selected master settings loading
export const makeInnovativeStudentSelectedMasterSettingsLoading = state => {
    const result = makeInnovativeStudentSelectedMasterSettingsResponse(state);
    return result && result.isLoading;
};

// return innovative_student selected master settings data
export const makeInnovativeStudentSelectedMasterSettingsData = state => {
    const result = makeInnovativeStudentSelectedMasterSettingsResponse(state);
    return _get(result, 'response.data', {});
};

// return innovative_student master setting by path
export const makeInnovativeMasterSettingByPath = path => state => {
    const result = makeInnovativeStudentSelectedMasterSettingsData(state);
    return _get(result, path, undefined);
};

