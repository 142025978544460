import React, { useEffect, useState } from "react";
import { Button, Card, Row, Pagination, Tabs, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import menuData from "../../layout/dashboardManagement/menuData";
import { setBreadcrumb, setMenuSelected } from "../../../actions/dashboardManagement/layout.action";
import { fetchCredentialManagement } from "../../../actions/dashboardManagement/dashboard.action";
import { getCredentialManagementData } from "../../../selectors/dashboardManagement/dashboard.selector";
import DeveloperKeyInstructionCard from "./cardList/components/developerKeyInstructionCard";
import DeveloperKeyCard from "./cardList/components/developerKeyCard";
import AdminTokenCard from "./cardList/components/adminTokenCard";
import { PlusOutlined } from '@ant-design/icons';
import UpdateDevKeyModal from "./cardList/components/updateDevKeyModal";
import AddTokenModal from "./cardList/components/addTokenModal";
import UserInfoCard from "./cardList/components/userInfoCard";
import ClearDevKeyModal from "./cardList/components/clearDevKeyModal";

const CredentialManageContainer = () => {
  const dispatch = useDispatch();

  const [isAdminTokenView, setIsAdminTokenView] = useState(false);
  const [isUpdateDevKeyPopUpModalVisible, setUpdateDevKeyPopupModalVisible] = useState(false);
  const [isAddTokenPopupModalVisible, setAddTokenPopupModalVisible] = useState(false);
  const [isClearDevKeyPopUpModalVisible, setClearDevKeyPopupModalVisible] = useState(false);

  //use in Pagination component
  const [filterDataLowerPoint, setFilterDataLowerPoint] = useState(0);
  const [filterDataUpperPoint, setFilterDataUpperPoint] = useState(5);

  // selectors
  const credentialManagementData = useSelector(getCredentialManagementData);
  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.credentialManagement.key,
      section: menuData.credentialManagement.name,
    },
  ];

  //set breadcrumb
  useEffect(() => {
    dispatch(setBreadcrumb(breadcrumbItems))
  }, []);

  // set menu item
  useEffect(() => {
    dispatch(setMenuSelected(menuData.credentialManagement.key));
  }, []);

  // fetch dashboards
  useEffect(() => {
    dispatch(fetchCredentialManagement())
  }, []);

  return (
    <div className="in-container credentialManagement">
      {isUpdateDevKeyPopUpModalVisible &&
        <UpdateDevKeyModal
          setIsModalVisible={setUpdateDevKeyPopupModalVisible}
          isModalVisible={isUpdateDevKeyPopUpModalVisible}
        />
      }
      {isClearDevKeyPopUpModalVisible &&
        <ClearDevKeyModal
          setIsModalVisible={setClearDevKeyPopupModalVisible}
          isModalVisible={isClearDevKeyPopUpModalVisible}
          consumerId={credentialManagementData.data.devTokenList.consumer_pk}
        />
      }
      {isAddTokenPopupModalVisible &&
        <AddTokenModal
          setIsModalVisible={setAddTokenPopupModalVisible}
          isModalVisible={isAddTokenPopupModalVisible}
        />
      }
      <h4>Credential Management</h4>
      <Card>
        <Tabs
          tabBarExtraContent={
            isAdminTokenView &&
            <Button
              icon={<PlusOutlined />}
              onClick={() => { setAddTokenPopupModalVisible(true) }}
              type="primary"
            >
              Add Token
            </Button>
          }
          size="large"
          onChange={(tabKey) => { tabKey === "item-1" ? setIsAdminTokenView(false) : setIsAdminTokenView(true) }}
        >
          <Tabs.TabPane tab="Developer key" key="item-1" className="devKeyManage" >
            <DeveloperKeyInstructionCard
              sequenceNumber={1}
              title="Create a developer key inside canvas"
              subTitle="Use below information when creating key"
              instructionsArray={[
                `To enable a developer key as a Canvas root admin, log in and access the "Developer Keys" page. Click "+ Developer Key" under the Account tab, then select "API key."`,
                `Select the "API key" option and fill in the required key name and corresponding redirect URL. These details are usually located below the developer key's description. After accurate entry, save the configuration to generate a developer key. It will be added to your Canvas account under the "Account" tab, offering enhanced functionality and API access within the Canvas environment.`
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <UserInfoCard
                    title={"Key name"}
                    keyValue={credentialManagementData.data && credentialManagementData.data.devTokenList.key_name}
                  />
                </Col>
                <Col span={12}>
                  <UserInfoCard
                    title={"Redirect URL"}
                    keyValue={credentialManagementData.data && `${process.env.REACT_APP_APIURL}${credentialManagementData.data.devTokenList.redirect_url}`}
                  />
                </Col>
              </Row>
            </DeveloperKeyInstructionCard>
            <hr />
            <DeveloperKeyInstructionCard
              sequenceNumber={2}
              title="Add Developer Key"
              subTitle="Developer Key"
              instructionsArray={[
                `To start, go to the "Account" tab and find the Client ID (developer ID) you created in the "Details" column. Copy it. Return to the Credential management page, click "Update" and insert the copied key into the "Client ID" field.`, 
                `Access the root account in Canvas, go to "Developer Key" locate and click "Show Key" for the correct key. Copy the key, return to Tentacle app's credentials page, paste into "Client Secret" and confirm. This integrates the developer key for desired functionalities.`
              ]}
            >
              <Row style={{ width: "100%" }} justify="center" wrap={false}>
                <DeveloperKeyCard
                  setPopupModalVisible={setUpdateDevKeyPopupModalVisible}
                  setClearPopupModalVisible={setClearDevKeyPopupModalVisible}
                  credentialManagementData={credentialManagementData}
                />
              </Row>
            </DeveloperKeyInstructionCard>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Admin Tokens" key="item-2">
            {credentialManagementData.data
              && credentialManagementData.data.tokenList
              && credentialManagementData.data.tokenList.length > 1 ?
              credentialManagementData.data.tokenList
                .filter((_, i) => i < filterDataUpperPoint & i >= filterDataLowerPoint)
                .map((tokenInfo, i) => (
                  <AdminTokenCard
                    key={`${tokenInfo.consumerId}-${i}`}
                    tokenInfo={tokenInfo}
                  />
                ))
              :
              credentialManagementData.data
              && credentialManagementData.data.tokenList
              && credentialManagementData.data.tokenList.map((tokenInfo, i) => (
                <AdminTokenCard
                  key={`${tokenInfo.consumerId}-${i}`}
                  tokenInfo={tokenInfo}
                  isDeleteDisabled={true}
                />
              ))
            }

            <Row justify="end">
              <Pagination
                onChange={(page, pageSize) => {
                  setFilterDataLowerPoint(page === 1 ? 0 : (page - 1) * pageSize);
                  setFilterDataUpperPoint(page * pageSize);
                }}
                total={credentialManagementData.data
                  && credentialManagementData.data.tokenList
                  && credentialManagementData.data.tokenList.length}
                pageSize={5}
              />
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  )
}

export default CredentialManageContainer;
