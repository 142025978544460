import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import {
  getFinancialStudentsData,
  getFinancialStudentsLoading,
  getFinancialFactorsData,
  getSelectedCardsByPath,
  getDashboardFiltersSelectedData
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchFinancialStudents } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/financial.action';
import { setSelectedCards } from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/common.action';

import Students from './Students';
import StudentDetailCell from '../../../../../common/StudentDetailCell';
import ProgressBarCell from '../../../../../common/ProgressBarCell';
import Loader from '../../../../../../../../common/layout/Loader';

import {
  financialSections,
  subSections,
} from '../../../../../../../../../../../constant/vetTrack/analysis';
import { getUniqValuesForFilters } from '../../../../../../../../utils/general';
import { prepareRiskMeasurementsColumns } from '../../../../../../../../utils/studentTable';
import { filterStudentByCard } from '../../../../../common/topCards/subSectionTopCards';
import {
  isEmptyValue,
  sortDataColumn,
  onFilterColumn,
} from '../../../../../../../../utils/general';
import NoDataCell from '../../../../../../../../common/partials/NoDataCell';

const StudentsTab = () => {
  const dispatch = useDispatch();
  const [filteredStudents, setFilteredStudents] = useState([]);

  // selectors
  const students = useSelector(getFinancialStudentsData);
  const enabledFactors = useSelector(getFinancialFactorsData);
  const studentsLoading = useSelector(getFinancialStudentsLoading);
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const selectedCard = useSelector(
    getSelectedCardsByPath(subSections.financial.key),
  );

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !studentsLoading) {
      const data = {
        filters: selectedFilters,
        subSectionId: subSections.financial.id,
      };
      dispatch(setSelectedCards({ [subSections.financial.key]: null }));
      dispatch(fetchFinancialStudents(data));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (students) {
      setFilteredStudents(students);
    }
  }, [students]);

  useEffect(() => {
    filterStudentByCard(
      selectedCard,
      setFilteredStudents,
      students,
      subSections.financial.key,
    );
  }, [selectedCard]);

  const renderProgressCells = data => {
    if (!_isEmpty(data) && !isEmptyValue(data.value)) {
        return (
          <div className={`overview-progress-cell-${data.text}`}>
            <ProgressBarCell
              value={data.value}
              displayValue={`${data.value}`}
              color={data.color}
            />
          </div>
        );
    }

    return <NoDataCell />;
  };

  const textCellRender = value => {
    if (value === null || value === undefined) {
      return <NoDataCell />;
    }

    return value;
  };

  const columns = [
    {
      title: '',
      className: 'table-remove-left',
      children: [
        {
          title: 'Student Details',
          dataIndex: 'studentDetails',
          sorter: (value1, value2) =>
            value1.studentDetails.name &&
            value1.studentDetails.name.localeCompare(value2.studentDetails.name),
          render: value => (
            <StudentDetailCell
              title={value.name}
              subtitle={value.id}
              avatar={value.image}
            />
          ),
          onFilter: (value, record) => record.studentDetails.name === value,
          filters: getUniqValuesForFilters(
            students,
            'studentDetails.name',
            'studentDetails.name',
          ),
          fixed: 'left',
        },
        {
          title: 'Course',
          dataIndex: ['course', 'name'],
          sorter: (value1, value2) =>
            value1.course.name && value1.course.name.localeCompare(value2.course.name),
          onFilter: (value, record) =>
            onFilterColumn(value, record, 'course', 'name'),
          filters: getUniqValuesForFilters(
            students,
            'course.name',
            'course.name',
          ),
          render: textCellRender,
        },
        {
          title: 'Financial Risk Score',
          dataIndex: 'financial',
          sorter: (row1, row2) => sortDataColumn(row1, row2, 'financial'),
          onFilter: (value, record) =>
            onFilterColumn(value, record, 'financial', 'value'),
          render: value => renderProgressCells(value),
          filters: getUniqValuesForFilters(
            students,
            'financial.value',
            'financial.value',
          ),
        },
      ],
    },
    {
      title: 'Risk Measurements',
      children: prepareRiskMeasurementsColumns(
        enabledFactors,
        students,
        financialSections,
      ),
    },
  ];

  return (
    <div className="sectorTable">
      <Loader isLoading={studentsLoading}>
        <Students students={filteredStudents} columns={columns} />
      </Loader>
    </div>
  );
};

export default StudentsTab;
