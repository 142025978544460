import React, {useState, Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import {Button, Checkbox, Input, Space, Form, Row} from "antd";
import _startCase from "lodash/startCase";
import _lowerCase from "lodash/lowerCase";

import {
    getDataSourcesSettingByPath,
    getDrawerSelectedLoading,
    getDrawerSelectedOptions,
    getDrawerSelectedType
} from "../../../../../../selectors/jobReadyGeneric/settings/dataSources.selector";
import {getPermissionDataByPath} from "../../../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";
import {fetchDrawerOptions} from "../../../../../../actions/jobReadyGeneric/settings/dataSources.action";
import {setSettings} from "../../../../../../actions/jobReadyGeneric/settings/commonSettings.action";

import ReadyTecSection from "./ReadyTecSection";
import JRDrawer from "../../../../common/Drawer";
import Loader from "../../../../common/layout/Loader";

import {drawerTypes, settingsSections} from "../../../../../../constant/jobReadyGeneric/constants";

const ReadyTecSectionContainer = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerOptions, setDrawerOptions] = useState([]);
    const dispatch = useDispatch();
    const [drawerForm] = Form.useForm();

    // selectors
    const readyTecId = useSelector(getDataSourcesSettingByPath("readyTec.id"));
    const enrolmentStatuses = useSelector(getDataSourcesSettingByPath("readyTec.enrolmentStatus"));
    const invoiceStatuses = useSelector(getDataSourcesSettingByPath("readyTec.invoiceStatus"));
    const localStatuses = useSelector(getDataSourcesSettingByPath("readyTec.localStatus"));
    const options = useSelector(getDrawerSelectedOptions);
    const optionsLoading = useSelector(getDrawerSelectedLoading);
    const drawerType = useSelector(getDrawerSelectedType);
    const isAdmin = useSelector(getPermissionDataByPath("isAdmin"));

    const configMapper = {
        [drawerTypes.enrolment]: {
            data:enrolmentStatuses,
            path: "data.readyTec.enrolmentStatus"
        },
        [drawerTypes.invoice]: {
            data:invoiceStatuses,
            path: "data.readyTec.invoiceStatus"
        },
        [drawerTypes.local]: {
            data:localStatuses,
            path: "data.readyTec.localStatus"
        },
    }

    // effects
    useEffect(() => {
        if(drawerType && drawerVisible){
            // generate initial values for drawer options
            let initOptions =  configMapper[drawerType]?.data.map(i => i.id)
            drawerForm.setFieldsValue({
                options: initOptions
            })
        }
    }, [drawerType, drawerVisible, enrolmentStatuses, invoiceStatuses, localStatuses, options])

    useEffect(() => {
        if(options){
            setDrawerOptions(options);
        }
    }, [options])

    const showDrawer = (subsection) => {
        dispatch(fetchDrawerOptions({dataSourceId: readyTecId, statusType:subsection}));
        setDrawerVisible(true);
    };
    const onClose = () => {
        setDrawerVisible(false);
    };

    const onSelect = () => {
        // get actual object from selected ids
        const finalOptions = options.filter(i => drawerForm.getFieldValue("options").includes(i.id))

        // dispatch for set settings with the related path
        dispatch(setSettings(finalOptions, configMapper[drawerType].path, settingsSections.dataSources, null))

        drawerForm.resetFields();
        setDrawerVisible(false);
    };

    const onDelete = (data, type) => {
         // dispatch for set settings with the related path
        const finalOptions = configMapper[type].data.filter(i => i.id !== data.id);
        dispatch(setSettings(finalOptions, configMapper[type].path, settingsSections.dataSources, null))
    }

    const renderDrawerFooter = () => {
        return (
            <div style={{textAlign:"end"}}>
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" onClick={onSelect}>Select</Button>
                </Space>
            </div>
        )
    }

    const renderDrawerSection = () => {
        const onSearch = (value) => {
            setDrawerOptions([...options.filter(option => _lowerCase(option.name).includes(_lowerCase(value)))])
        }

        return (
            <div>
                <Loader isLoading={optionsLoading}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Input.Search placeholder="Search" onSearch={onSearch}/>
                        <Form name="optionForm" onFinish={() => drawerForm.resetFields()} form={drawerForm} >
                            <Form.Item
                                name="options"
                            >
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                >
                                    {drawerOptions.map(option => (
                                        <Row>
                                            <Checkbox value={option.id}>{option.name}</Checkbox>
                                        </Row>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </Form>
                    </Space>
                </Loader>
            </div>
        )
    }

    return (
        <Fragment>
            <ReadyTecSection
                enrolmentStatuses={enrolmentStatuses}
                invoiceStatuses={invoiceStatuses}
                localStatuses={localStatuses}
                openDrawer={showDrawer}
                onDelete={onDelete}
                isAdmin={isAdmin}
            />
            <JRDrawer
                closable={true}
                title={drawerType? `Valid ${_startCase(drawerType)} Status`: null}
                footer={renderDrawerFooter()}
                onClose={onClose}
                width={350}
                visible={drawerVisible}
            >
                {renderDrawerSection()}
            </JRDrawer>
        </Fragment>
    )

}

ReadyTecSectionContainer.propTypes = {
}

export default ReadyTecSectionContainer;