import React, { useEffect, useState } from "react";
import { WarningFilled } from '@ant-design/icons';
import { zeroPad } from 'react-countdown-now';

const AiRetryModal = ({
  timeLeft,
}) => {
  const [placeHolder, setPlaceHolder] = useState("");

  useEffect(() => {
    countDownRenderer(timeLeft);
  }, [JSON.stringify(timeLeft)]);

  const countDownRenderer = ({ minutes, seconds }) => {
    if (minutes === 0 && seconds === 0) {
      return setPlaceHolder("");
    }

    return setPlaceHolder(`Auto Retry in: ${minutes}:${zeroPad(seconds)}`);
  };

  return (
    <div>
      <div>
        <div className="clusterRetryError">
          <WarningFilled style={{
            color: "#F5222D",
            paddingRight: "5px",
          }} />
          Server Error occurred
        </div>
        <div className="clusterRetryCountdown">
          {placeHolder}
        </div>
      </div>
    </div>
  )
}

export default AiRetryModal;
