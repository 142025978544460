import React, { memo } from "react";
import PropTypes from "prop-types";
import { Collapse } from 'antd';
import { CaretRightOutlined, } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { makeVwDealershipSelectedProgram } from "../../../../../../selectors/vwDealership/setting.selector";

const { Panel } = Collapse;

const CollapseSection = ({ programs, onChangeHandler }) => {

    const handleOnchange = (e, programId) => {
        e.stopPropagation()
        onChangeHandler(programId)
    }

    const selectedProgram = useSelector(makeVwDealershipSelectedProgram)

    return <div className="setting-collapse">
        <Collapse
            accordion
            ghost
            destroyInactivePanel
            defaultActiveKey={"1"}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
        >
            <Panel header={"Programs"} key="1" style={{ fontWeight: "bold" }}>
                <Collapse
                    accordion
                    ghost
                    destroyInactivePanel
                    style={{ fontWeight: "normal" }}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 0 : 0} />}
                    className="site-collapse-custom-collapse"
                >
                    {programs && programs.length > 0 && programs.map(program => <Panel header={<div style={{ color: selectedProgram === program.id && "#0C5CA7" }} onClick={e => handleOnchange(e, program.id)}>{program.name}</div>} key={program.id} style={{ fontWeight: "bold" }}> </Panel>)}
                </Collapse>
            </Panel>
        </Collapse>
    </div>
}

CollapseSection.propTypes = {
    programs: PropTypes.array,
    onChangeHandler: PropTypes.func
}

export default memo(CollapseSection);