import moment from "moment";

const getNumber = (value) => {
    return value;
}

const getDate = (value) => {
    return moment(value);
}

const addDays = function(date, days) {
    date.setDate(date.getDate() + parseInt(days));
    return date;
};

export function arrayPaginate(array, size) {
    return array.reduce((acc, val, i) => {
    let idx = Math.floor(i / size)
    let page = acc[idx] || (acc[idx] = [])
    page.push(val)

    return acc
  }, [])
}

export {
    getNumber,
    getDate,
    addDays,
};

