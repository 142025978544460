import _get from "lodash/get";
import _map from "lodash/map"

// return vw dealership settings response
export const makeVwDealershipSettingResponse = state => _get(state, 'vwDealership.dealershipSettings');

// return vw dealership settings open panels response
export const makeVwDealershipOpenPanelsDataResponse = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.openPanels;
};

// return vw dealership settings edit mode response
export const makeVwDealershipEditModeDataResponse = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.editMode;
};

// get selected program from set order sidebar
export const makeVwDealershipSelectedProgram = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.selectedProgram;
};

// return vw dealership settings sidebar data response
export const makeVwDealershipSidebarDataResponse = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.lists;
};

// return vw dealership settings sidebar order data response
export const makeVwDealershipSidebarOrderDataResponse = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.order;
};

// return vw dealership settings sidebar order data loading
export const makeVwDealershipSidebarOrderDataLoading = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.order?.isLoading;
};

// return vw dealership settings sidebar order  program list
export const makeVwDealershipSidebarOrderPrograms = state => {
    const result = makeVwDealershipSidebarOrderDataResponse(state);
    const programs = result && _map(result.programs, "program");
    return programs;
};
// return vw dealership settings order isLoading response
export const makeVwDealershipOrderIsLoadingResponse = state => {
    const result = makeVwDealershipSidebarOrderDataResponse(state);
    return result && result.saveIsLoading ? result.saveIsLoading : false;
};

// return vw dealership settings order units response
export const makeVwDealershipSidebarOrderResponseData = state => {
    const result = makeVwDealershipSidebarOrderPrograms(state);
    return result && result.data;
};

// return vw dealership settings order  program list for table
export const makeVwDealershipOrderProgramUnits = key => state => {
    const result = makeVwDealershipSidebarOrderDataResponse(state);
    const matchedItem = key && result && result?.programs?.filter(item => item?.program?.id === key);
    let finalResult;
    if (matchedItem && matchedItem.length > 0) {
        finalResult = matchedItem[0]?.units;
    }
    return finalResult;
};

// return vw dealership settings sidebar data loading response
export const makeVwDealershipSidebarDataLoading = state => {
    const result = makeVwDealershipSidebarDataResponse(state);
    return result && result.isLoading;
};

// return vw dealership settings sidebar dealership loading response
export const makeVwDealershipSidebarResponseData = key => state => {
    const result = makeVwDealershipSidebarDataResponse(state);
    return result && result[key];
};

// return vw dealership settings sidebar units response
export const makeVwDealershipSidebarUnitsResponse = state => {
    const result = makeVwDealershipSettingResponse(state);
    return result && result.units;
};

// return vw dealership settings units isLoading response
export const makeVwDealershipSidebarIsLoadingResponse = state => {
    const result = makeVwDealershipSidebarUnitsResponse(state);
    return result && result.saveIsLoading;
};

// return vw dealership settings sidebar units response
export const makeVwDealershipSidebarUnitsResponseData = state => {
    const result = makeVwDealershipSidebarUnitsResponse(state);
    return result && result.data;
};