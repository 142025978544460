import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const ChartCell = ({chartType, series, color, showLegend, containerProps}) => {
    const getColor = () => {
        if (typeof color === "string"){
            return {color}
        }else{
            return {colors: color}
        }
    }
    const formatSeriesData = () => {
        switch (chartType) {
            case 'column':
                return series
            case 'line':
                return [{
                    data: series
                }]
        }
    }
    const options = {
        credits: {
            enabled: false
        },
        chart: {
            type: chartType,
        },

        title: false,
        subTitle: false,
        legend: {enabled: showLegend},

        xAxis:{
            visible: false,
        },
        yAxis:{
            visible: false,
        },

        series: formatSeriesData()
        ,
        plotOptions: {
            line: {
                marker:{enabled:false},
                ...getColor()
            },
            column: {
                // pointWidth:50,
                grouping: true,
                ...getColor()
            },
            series: {
                groupPadding: 0,
            }
        },
        tooltip:{
            enabled: true,
            formatter: function () {
                return 'Value: ' + this.y;
            }
        }
    }

    return (
        <Fragment>
            <HighchartsReact
                containerProps={containerProps}
                highcharts={Highcharts}
                options={options}
            />
        </Fragment>
    )
}

ChartCell.defaultProps = {
    showLegend: false,
    containerProps:{style:{height:60, width:200, padding:0}}
}

ChartCell.propTypes = {
    chartType: PropTypes.oneOf([
        'line',
        'column',
    ]),
    series: PropTypes.array,
    showLegend: PropTypes.bool,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    containerProps: PropTypes.object
}

export default ChartCell;