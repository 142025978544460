import React, { Component, Fragment } from "react";

import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Modal from "react-responsive-modal";
import ApiServices from "../../../services/tentacle";
import accimage from "../../../assets/images/acclogo.png";
import { withRouter } from "react-router";
import Helper from "../common/numberHelper";
import PrincipalProfileWrapper from "./principalProfileWrapper/PrincipalProfileWrapper";
import Datatable from "./datatable";
// import "./index.css"
import addStock from "highcharts/modules/stock";
import "./../../../assets/css/principal-profile.css";

addStock(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class PrincipalProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      titleFocus: React.createRef(),
      isFilterTermsLoaded:false,
      isFilterNumberFormatLoaded: false,
      totalCostVsBudgetCostChartLoaded: false,
      targetFundingChartLoaded: false,
      staffCostChartLoaded: false,
      staffFTEChartLoaded: false,
      studentOverheadChartLoaded: false,
      staffOverheadChartLoaded: false,
      drillThroughTotalIncomeChartLoaded: false,
      drillThroughTotalCostChartLoaded: false,
      totalModalChartLoaded:false,
      totalModalDrillDownChartLoaded:false,
      filterTermValue:0,
      filterTerms:[],
      financialData:[],
      staffFTEData:[],
      isFirstTimeLoad:true,
      totalModalOpen: false,
      filterSchools: [],
      isFilterSchoolsLoaded: false,
      filterSchoolValue:"",
      schoolFilterStatus: 0
    }
  }

  componentDidMount() {
    if(this.props.location.pathname == "/dashboard/ceo/ceo_principal"){
      this.schoolData();
    } else {
      this.filterTerms();
    }
    
    this.filterNumberFormats();
  }

  schoolData = () => {
    this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true
    });

    const params = {logged_in: this.state.logged_in};
    ApiServices.AccFilterService.getSchoolData(params).then((data) => {
      if(Object.keys(data.result).length !== "" && Object.keys(data.result).length > 0){
        if(data.result.main.length > 0){
          this.setState({
            isFilterSchoolsLoaded: true, 
            filterSchoolValue:data.result.main[0].schoolId,
            filterSchools:data.result.main
          }, () => {
            this.filterTerms(data.result.main[0].schoolId);
          });

          const { history } = this.props;

          history.push({
            state: { currentSchool: this.state.filterSchoolValue }
          });
        }
      }
    },(error) => {
      this.setState({
        isFilterTermsLoaded: true,
        error
      });
    }) ;
  }

  changeSchoolValue = (value) => {
    this.setState({filterSchoolValue:value}, () => {
      const { history } = this.props;

      history.push({
        state: { currentSchool: this.state.filterSchoolValue }
      });

      this.filterTerms(value);
    });
  }

  changeTermValue = (value) => {
    this.setState({filterTermValue:value}, () => {
      if(this.state.isFirstTimeLoad){
        this.setState({
          isFirstTimeLoad: false,
        })
        this.financialData();
        this.staffFTEData();
      }
    });
  }

  changeNumberFormat = (value) => {
    this.setState({numberFormat:value}, () =>{
      if(this.state.financialData)
        this.drawFinancialCharts();
      if(this.state.staffFTEData)
        this.drawStaffFTEChart();
    });
  }

  filterTerms = (schoolId="") => {
    try {
      this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true,
        isFilterTermsLoaded: false
      });

      var params = { school: schoolId !== "" ? schoolId : this.props.schoolId }

      if(!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school)){
        ApiServices.AccFilterService.principalFilterTerm(params).then((data) => {
          if(data && data.data && Object.keys(data.data.result).length !== "" && Object.keys(data.data.result).length > 0){
            let termArray = data.data.result;
            this.setState({
              isFilterTermsLoaded: true, 
              filterTermValue:termArray[0].id,
              filterTerms:termArray
            }, () => {
              this.changeTermValue(termArray[0].id);
              if(this.state.schoolFilterStatus == 0){
                this.setState({
                  isGoButtonDisabled: false
              });
              }
            });
          }
        },(error) => {
          this.setState({
            isFilterTermsLoaded: true,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  filterNumberFormats = () => {
    this.setState({
      isFilterNumberFormatLoaded: true,
      filterNumberFormats: [
        {"code": "AF", "name": "In Dollars" }, 
        {"code": "K", "name": "In Hundred Thousand Dollars (K)" }, 
        {"code": "M", "name": "In Million Dollars (M)"}],
      numberFormat: "AF"
    });
  }

  redrawCharts = () => {
    this.setState({
      isGoButtonDisabled: true
    }, () => {
      this.financialData();
      this.staffFTEData();
    })
  }

  financialData = () => {
    try {
      this.setState({
        financialData : [],
        totalCostVsBudgetCostChartLoaded: false,
        targetFundingChartLoaded: false,
        staffCostChartLoaded: false,
        studentOverheadChartLoaded: false,
        staffOverheadChartLoaded: false,
        // drillThroughTotalIncomeChartLoaded: false,
        // drillThroughTotalCostChartLoaded: false
        totalModalChartLoaded: false
      });
      const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== "" ? this.state.filterSchoolValue : this.props.schoolId};
      
      if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
        ApiServices.AccPrincipalProfileService.financialData(params).then((result) => {
          if(result && result.data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              financialData:result.data.result,
              tableHeaderColumns: [
                { width: 100, Header: <b>GL CODE</b>, accessor: "glNumber", Cell: true, style: { "fontSize": "12px", textAlign: "center", "background": "#1251AE", color: "#FFFFFF"}},
                { Header: <b>DESCRIPTION</b>, accessor: "glName", Cell: false, style: { "fontSize": "12px" }},
                { Header: <b>THIS YEAR YTD</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.actualCost < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "actualCost", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>YTD BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.YTDBudget < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "YTDBudget", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <><b>VARIANCE</b> <p style={{"fontSize" : "12px"}}><b style={{fontSize: "12px !important"}}>THIS YEAR YTD - YTD BUDGET</b></p></>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.variance < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "variance", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>ANNUAL BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.annualBudget < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "annualBudget", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>PREVIOUS YEAR</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.previousActualCost < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "previousActualCost", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                }
              ],
              staffCostTableHeaderColumns: [
                { Header: <b>STAFF TYPE</b>, accessor: "name", Cell: true, style: { "fontSize": "12px", textAlign: "center", "background": "#1251AE", color: "#FFFFFF"}},
                { Header: <b>THIS YEAR YTD</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.thisYearAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "thisYearAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>YTD BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.YTDBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "YTDBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <><b>VARIANCE</b> <p style={{"fontSize" : "12px"}}><b style={{fontSize: "12px !important"}}>THIS YEAR YTD - YTD BUDGET</b></p></>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.varianceAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "varianceAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>ANNUAL BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.annualBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "annualBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },          
                { Header: <b>PREVIOUS YEAR</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.previousYear < 
                      0 ? "red" : null,
                    }
                  }}, Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>,  
                  accessor: "previousYear", style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                }
              ],
              studentOverheadTableHeaderColumns: [
                { Header: <b>CATEGORY</b>, accessor: "name", Cell: true, style: { "fontSize": "12px", textAlign: "center", "background": "#1251AE", color: "#FFFFFF"}},
                { Header: <b>THIS YEAR YTD</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.thisYearAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "thisYearAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>YTD BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.YTDBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "YTDBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <><b>VARIANCE</b> <p style={{"fontSize" : "12px"}}><b style={{fontSize: "12px !important"}}>THIS YEAR YTD - YTD BUDGET</b></p></>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.varianceAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "varianceAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>ANNUAL BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.annualBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "annualBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                }          
              ],
              staffOverheadTableHeaderColumns: [
                { Header: <b>CATEGORY</b>, accessor: "name", Cell: true, style: { "fontSize": "12px", textAlign: "center", "background": "#1251AE", color: "#FFFFFF"}},
                { Header: <b>THIS YEAR YTD</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.thisYearAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "thisYearAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>YTD BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.YTDBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "YTDBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <><b>VARIANCE</b> <p style={{"fontSize" : "12px"}}><b style={{fontSize: "12px !important"}}>THIS YEAR YTD - YTD BUDGET</b></p></>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.varianceAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "varianceAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center" },
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                },
                { Header: <b>ANNUAL BUDGET</b>, getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo?.row?.annualBudgetAmount < 
                      0 ? "red" : null,
                    }
                  }}, accessor: "annualBudgetAmount", 
                  Cell: props => <span>{props.value !== "" ? props.value < 0 ? "-$" + Math.abs(props.value).toLocaleString() : "$" + props.value.toLocaleString() : "-"}</span>, 
                  style: { "fontSize": "12px", textAlign: "center"},
                  sortMethod: (a, b) => {
                    if(a !== "" && b !== ""){
                      if(a > b){
                        return 1;
                      } else {
                        return -1;
                      }
                    } else if (a !== "" && b == ""){
                      return -1;
                    } else if (a == "" && b !== ""){
                      return 1;
                    } else if (a == "" && b == ""){
                      return -1;
                    }
                  }
                }
              ]
            }, () => {
              this.drawFinancialCharts();
              this.setState({
                isGoButtonDisabled: false,
                targetFundingChartLoaded: true
              })
            });
          }
        },(error) => {
          this.setState({
            totalCostVsBudgetCostChartLoaded: true,
            targetFundingChartLoaded: true,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  drawFinancialCharts = () => {
    this.drawTotalCostVsBudgetChart();
    this.drawTargetedFundingChart();
    this.drawStaffCostChart();
    this.drawStudentOverheadChart();
    this.drawStaffOverheadChart();
    this.drawDrillThroughTotalIncomeChart();
    this.drawDrillThroughTotalCostChart();
  }

  staffFTEData = () => {
    try {
      this.setState({
        staffFTEData: [],
        staffFTEChartLoaded: false,
        isGoButtonDisabled: true
      });
      const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== "" ? this.state.filterSchoolValue : this.props.schoolId};

      if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
        ApiServices.AccPrincipalProfileService.staffFTEData(params).then((result) => {
          if(result && result .data && Object.keys(result.data.result).length !== "" && Object.keys(result.data.result).length > 0){
            this.setState({
              staffFTEData:result.data.result
            }, this.drawStaffFTEChart);
          }
        },(error) => {
          this.setState({
            staffFTEChartLoaded: true,
            error
          });
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  drawTotalCostVsBudgetChart = () => {
    let gThis = this.state;
    this.setState({
      totalCostVsBudgetCostChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.totalCostVsBudgetCost.chart.categories,
          title: {
            text: "CATEGORY"
            //align: "center"
          },
          min: 0,
          max: this.state.financialData.totalCostVsBudgetCost.chart.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.financialData.totalCostVsBudgetCost.chart.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `CATEGORY: <b>${this.key}</b><br/>${gThis.financialData.totalCostVsBudgetCost.chart.tooltipCategories[this.point.x]}<br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.totalCostVsBudgetCost.chart.series,
      }
    }, () => {
      this.setState({
        totalCostVsBudgetCostChartLoaded: true
      });
    })
  }

  drawTargetedFundingChart = () => {
    let gThis = this.state;
    this.setState({
      targetedFundingChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}`
              }
            }
          },
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.targetedFunding.chart.categories,
          title: {
            text: "TARGETED FUNDING CATEGORY"
          },
          // scrollbar: {
          //   enabled: this.state.financialData.targetedFunding.chart.categories.length > 5 ? true : false
          // }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `<b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.targetedFunding.chart.series,
      }
    }, () => {
      this.setState({
        targetFundingChartLoaded: true
      });
    })
  }

  drawStaffCostChart = () => {
    let gThis = this.state;
    this.setState({
      staffCostChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.staffCost.chart.categories,
          title: {
            text: "STAFF TYPE"
            //align: "center"
          },
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `<b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.staffCost.chart.series,
      }
    }, () => {
      this.setState({
        staffCostChartLoaded: true
      });
    })
  }

  drawStaffFTEChart = () => {
    let gThis = this.state;
    this.setState({
      staffFTEChartLoaded:false,
      staffFTEChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
            dataLabels: {
              enabled: true
            }
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.staffFTEData.staffFTE.chart.categories,
          title: {
            text: "STAFF TYPE"
            //align: "center"
          },
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "FTE"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          shared: true,
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `<b>${gThis.staffFTEData.staffFTE.chart.tooltipCategories[gThis.staffFTEData.staffFTE.chart.categories.indexOf(this.x)]}</b><br/>
                    ${this.points[1] ? `<span style="color:${this.points[1].color}">${this.points[1].series.name}</span>: <b>${Helper.numberFormat(this.points[1].y)}</b><br/>` : ""}
                    ${this.points[0] ? `<span style="color:${this.points[0].color}">${this.points[0].series.name}</span>: <b>${Helper.numberFormat(this.points[0].y)}</b>` : ""}`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.staffFTEData.staffFTE.chart.series,
      }
    }, () => {
      this.setState({
        staffFTEChartLoaded: true
      });
    })
  }

  drawStudentOverheadChart = () => {
    let gThis = this.state;
    this.setState({
      studentOverheadChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.studentOverhead.chart.categories,
          title: {
            text: "CATEGORY"
            //align: "center"
          },
          min: 0,
          max: this.state.financialData.studentOverhead.chart.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.financialData.studentOverhead.chart.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `CATEGORY: <b>${gThis.financialData.studentOverhead.chart.tooltipCategories[this.point.x]} - ${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.studentOverhead.chart.series,
      }
    }, () => {
      this.setState({
        studentOverheadChartLoaded: true
      });
    })
  }

  drawStaffOverheadChart = () => {
    let gThis = this.state;
    this.setState({
      staffOverheadChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.staffOverhead.chart.categories,
          title: {
            text: "CATEGORY"
            //align: "center"
          },
          min: 0,
          max: this.state.financialData.staffOverhead.chart.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.financialData.staffOverhead.chart.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `CATEGORY: <b>${gThis.financialData.staffOverhead.chart.tooltipCategories[this.point.x]} - ${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.staffOverhead.chart.series,
      }
    }, () => {
      this.setState({
        staffOverheadChartLoaded: true
      });
    })
  }

  drawDrillThroughTotalIncomeChart = () => {
    let gState = this.state;
    let gThis = this;
    this.setState({
      drillThroughTotalIncomeChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  gThis.showDrillDownChart({category_name:this.category,index: this.series.index, mode: "I"});
                }
              }
            }
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.drillThroughTotalIncome.chart.categories,
          title: {
            text: "CATEGORY"
            //align: "center"
          },
          min: 0,
          max: this.state.financialData.drillThroughTotalIncome.chart.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.financialData.drillThroughTotalIncome.chart.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `CATEGORY: <b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gState.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.drillThroughTotalIncome.chart.series,
      }
    }, () => {
      this.setState({
        drillThroughTotalIncomeChartLoaded: true
      });
    })
  }

  drawDrillThroughTotalCostChart = () => {
    let gState = this.state;
    let gThis = this;
    this.setState({
      drillThroughTotalCostChartOptions: {
        title: {
          text: ""
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  gThis.showDrillDownChart({category_name:this.category,index: this.series.index, mode: "C"});
                }
              }
            }
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.financialData.drillThroughTotalCost.chart.categories,
          title: {
            text: "CATEGORY"
            //align: "center"
          },
          min: 0,
          max: this.state.financialData.drillThroughTotalCost.chart.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.financialData.drillThroughTotalCost.chart.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "(SM)"
            //align: "center"
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
          formatter: function () {
            return `CATEGORY: <b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gState.numberFormat)}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.financialData.drillThroughTotalCost.chart.series,
      }
    }, () => {
      this.setState({
        drillThroughTotalCostChartLoaded: true
      });
    })
  }

  drawDrillDownChart = (params) => {
    let gThis = this.state; 
    return {
      title: {
        text: ""
      },
      chart: {
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 2000);
          }
        },
        type:"column",
        animation: true,
        style: {
          color: "#444444"
        },
        zoomType: "x"
      },
      plotOptions: {
        series: {
          linewidth:6,
          borderRadius: 1,
        },
        column: {
          linewidth: 6,
          borderRadius: 1,
          pointPadding: 5,
          pointWidth: 20,
          groupPadding: 0.1
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        categories: params.mode == "I" ? gThis.financialData.drillThroughTotalIncome[params.category_name].chart.categories : 
                                          gThis.financialData.drillThroughTotalCost[params.category_name].chart.categories,
        title: {
          text: params.mode == "I" ? "INCOME CATEGORY" : "COST CATEGORY"
          //align: "center"
        },
        min: 0,
        max: params.mode == "I" ? (gThis.financialData.drillThroughTotalIncome[params.category_name].chart.categories.length > 12 ? 12 : null) :  
                                  (gThis.financialData.drillThroughTotalCost[params.category_name].chart.categories.length > 12 ? 12 : null),
        scrollbar: {
          enabled: params.mode == "I" ? (this.state.financialData.drillThroughTotalIncome[params.category_name].chart.categories.length > 12 ? true : false) : 
                                        (this.state.financialData.drillThroughTotalCost[params.category_name].chart.categories.length > 12 ? true : false)
        }
      },
      yAxis: {
        gridLineColor: "#F3F3F3",
        lineColor: "#F3F3F3",
        minorGridLineColor: "#F3F3F3",
        tickColor: "#F3F3F3",
        tickWidth: 1,
        title: {
          text: "($)"
          //align: "center"
        }
      },
      legend:{
        enabled:false,
        symbolHeight: 12,
        symbolWidth: 20,
        symbolRadius: 0
      },
      tooltip: {
        animation: true,
        useHTML: true,
        // pointFormat: "<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>",
        formatter: function () {
          return `CATEGORY: <b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>$${Helper.setMetricSuffix(this.y,gThis.numberFormat)}</b>`
        }
      },
      credits: {
        enabled: false
      },
      legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
      background2: "#505053",
      dataLabelsColor: "#B0B0B3",
      textColor: "#C0C0C0",
      contrastTextColor: "#F0F0F3",
      maskColor: "rgba(255,255,255,0.3)",
      
      series: params.mode == "I" ? [this.state.financialData.drillThroughTotalIncome[params.category_name].chart.series[params.index]] : [this.state.financialData.drillThroughTotalCost[params.category_name].chart.series[params.index]],
    }
  }

  totalModalOpen = (mode) => {
    this.setState({
      totalModalChartLoaded: false,
    }, () => {
      if(mode == "I" && this.state.drillThroughTotalIncomeChartLoaded){
        this.setState({
          totalModalChartOptions : this.state.drillThroughTotalIncomeChartOptions,
          totalModalPreviousYear: this.state.financialData.drillThroughTotalIncome.previousFY,
          totalModalThisYear: this.state.financialData.drillThroughTotalIncome.ytd
        }, () => {
          this.setState({
            totalModalChartLoaded: true,
            totalModalOpen: true,
            ModalTitle: "TOTAL INCOME"
          });
        });
        } else if(mode == "C" && this.state.drillThroughTotalCostChartLoaded){
          this.setState({
            totalModalChartOptions : this.state.drillThroughTotalCostChartOptions,
            totalModalPreviousYear: this.state.financialData.drillThroughTotalCost.previousFY,
            totalModalThisYear: this.state.financialData.drillThroughTotalCost.ytd
          }, () => {
            this.setState({
              totalModalChartLoaded: true,
              totalModalOpen: true,
              ModalTitle: "TOTAL COST"
            });
          });
        } else {
          /** Called with drill down back */
          this.setState({
            totalModalDrillDownChartLoaded: false,
          }, () => {
              this.setState({
                totalModalChartOptions: this.state.totalModalChartMode == "I" ? 
                                        this.state.drillThroughTotalIncomeChartOptions : this.state.drillThroughTotalCostChartOptions
              }, () => {
                  this.setState({
                    totalModalChartLoaded: true
                  })
              })
          });
        }
    });
  }

  showDrillDownChart = (params) => {
    this.setState({
      totalModalChartLoaded:false,
      totalModalChartMode: params.mode
    },() => {
      this.setState({
        totalModalDrillDownChartOptions: this.drawDrillDownChart(params)
      }, () => {
        this.setState({
          totalModalDrillDownChartLoaded:true
        });
      });
    });
  }

  closeModalCourse = () => {
    this.setState({
      totalModalOpen: false
    })
  }

  routeChange=(subPath)=> {
    let path = "";
    if(this.props.userRole === "ceo"){
      path = `${process.env.PUBLIC_URL}/dashboard/ceo/ceo_principal/${subPath}`;
    }else {
      path = `${process.env.PUBLIC_URL}/dashboard/principal/${subPath}`;
    }
    
    const { history } = this.props;
    // history.push(path);

    history.push({
      pathname: path,
      state: { currentSchool: this.state.filterSchoolValue }
    });
  }


  render() {
    return (
      <Fragment>
        <div className="container-fluid " > 
          <div className="card custom-card  " style={{backgroundColor:"#e0e0e0",marginTop: "90px"}}> 
            <div className="row ">
              <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
                <div className="row ">
                  { this.props.location.pathname == "/dashboard/ceo/ceo_principal" ?
                    <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                      <div className="card o-hidden custom-cardx">
                        <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                          <div className="media static-top-widget">
                            <div className="media-body">
                              <span className="m-0">SCHOOL</span>
                              <h4 className="mb-0 counter">
                                <select className="form-control digits "  value={this.state.filterSchoolValue}
                                  onChange={(e) => this.changeSchoolValue(e.target.value)}>
                                      {this.state.isFilterSchoolsLoaded
                                  ? this.state.filterSchools.map((filterSchool) => <option key={filterSchool.schoolId} value={filterSchool.schoolId}>{filterSchool.schoolName}</option>)
                                  : <option>Loading...</option>
                                  }
                                </select>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                  }

                  <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <span className="m-0">YEAR</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits "  value={this.state.filterTermValue}
                                onChange={(e) => this.changeTermValue(e.target.value)}>
                                    {this.state.isFilterTermsLoaded
                                ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <span className="m-0">Figure Mode</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits "  value={this.state.numberFormat}
                                onChange={(e) => this.changeNumberFormat(e.target.value)}>
                                    {this.state.isFilterNumberFormatLoaded
                                ? this.state.filterNumberFormats.map((numberFormat) => <option key={numberFormat.code} value={numberFormat.code}>{numberFormat.name}</option>)
                                : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
                <div className="">
                  <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled}  style={{marginTop: "24px",width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                </div>
              </div>
            </div>
          </div>     
      
          <div className="user-profile"> 
            <div className="row">
              <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                <div className="card custom-card  ">
                  <div className="row">
                    <div className="col-2 col-sm-2">
                      <img  src={accimage}></img>
                    </div>
                    <div className="col-5 col-sm-5 with-border">
                      <div className="student-name">
                        <h5><span className="counter">PRINCIPAL DASHBOARD</span></h5>
                        <h4>{this.state.isStudentNameLoaded
                        ? this.state.studentName
                        : this.state.loader}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="principal-profile">
            <div className="row">
              <div className="col-6">
                  <div className="card">
                      <div className="card-body card-blue" onClick={() => this.routeChange("enrolments")}>
                        ENROLMENT ANALYSIS &gt;&gt;
                      </div>
                  </div>
              </div>
              <div className="col-6">
                  <div className="card">
                      <div className="card-body card-blue" onClick={() => this.routeChange("management")}>
                        MANAGEMENT ANALYSIS &gt;&gt;
                      </div>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>TOTAL COST VS BUDGET COST</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.totalCostVsBudgetCostChartLoaded ?
                      <Fragment>
                        <div className="row">
                          <div className="col-3">
                            <div className="card">
                                <div className="card-body card-shadow card-custom-padding">
                                  <span className="card-text">PREVIOUS YEAR</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.totalCostVsBudgetCost.previousFY, this.state.numberFormat)}</span>
                                </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                                <div className="card-body card-shadow card-custom-padding">
                                  <span className="card-text">ANNUAL BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.totalCostVsBudgetCost.budgetAnnual, this.state.numberFormat)}</span>
                                </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                                <div className="card-body card-shadow card-custom-padding">
                                  <span className="card-text">THIS YEAR YTD</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.totalCostVsBudgetCost.ytd, this.state.numberFormat)}</span>
                                </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                                <div className="card-body card-shadow card-custom-padding">
                                  <span className="card-text">YTD BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.totalCostVsBudgetCost.YTDBudget, this.state.numberFormat)}</span>
                                </div>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.totalCostVsBudgetCostChartOptions}
                            /> */}
                            <Datatable
                              myData={this.state.financialData.totalCostVsBudgetCost.table}
                              headerColumns={this.state.tableHeaderColumns}
                              pageSize={this.state.financialData.totalCostVsBudgetCost.table.length > 20 ? 20 : this.state.financialData.totalCostVsBudgetCost.table.length}
                              pagination={true}
                              emptyRowsWhenPaging= {true}
                              myClass="table table-striped table-bordered table-responsive"
                            /> 
                          </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>TARGETED FUNDING</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.targetFundingChartLoaded ?
                      <Fragment>
                        <div className="row">
                          <div className="col-6">
                            <div className="card">
                              <div className="card-body card-shadow card-blue card-custom-padding" onClick={() => this.totalModalOpen("I")}>
                                <span className="card-text">TOTAL INCOME YTD &gt;&gt;</span>  <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.targetedFunding.totalIncomeYTD, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="card">
                              <div className="card-body card-shadow card-blue card-custom-padding" onClick={() => this.totalModalOpen("C")}>
                                <span className="card-text">TOTAL COST YTD &gt;&gt;</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.targetedFunding.totalCostYTD, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.targetedFundingChartOptions}
                            />
                          </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STAFF COST</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.staffCostChartLoaded ?
                      <Fragment>
                        <div className="row">
                            <div className="col-12">
                              {/* <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.staffCostChartOptions}
                                /> */}
                              <Datatable
                                myData={this.state.financialData.staffCost.table}
                                headerColumns={this.state.staffCostTableHeaderColumns}
                                pageSize={4}
                                pagination={false}
                                myClass="table table-striped table-bordered table-responsive"
                              /> 
                            </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STAFF FTE</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.staffFTEChartLoaded ?
                      <Fragment>
                        <div className="row">
                          <div className="col-12">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.staffFTEChartOptions}
                            />
                          </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STUDENT OVERHEAD</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.studentOverheadChartLoaded ?
                      <Fragment>
                        <div className="row">
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">PREVIOUS YEAR</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.studentOverhead.previousFY, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">ANNUAL BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.studentOverhead.budgetAnnual, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">THIS YEAR YTD</span><span className="card-value">${Helper.setMetricSuffix(this.state.financialData.studentOverhead.ytd, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">YTD BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.studentOverhead.YTDBudget, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.studentOverheadChartOptions}
                                /> */}
                            <Datatable
                              myData={this.state.financialData.studentOverhead.table}
                              headerColumns={this.state.studentOverheadTableHeaderColumns}
                              pageSize={this.state.financialData.studentOverhead.table.length > 20 ? 20 : this.state.financialData.studentOverhead.table.length}
                              pagination={true}
                              myClass="table table-striped table-bordered table-responsive"
                            />
                          </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STAFF OVERHEAD</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.staffOverheadChartLoaded ?
                      <Fragment>
                        <div className="row">
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">PREVIOUS YEAR</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.staffOverhead.previousFY, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">ANNUAL BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.staffOverhead.budgetAnnual, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">THIS YEAR YTD</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.staffOverhead.ytd, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="card">
                              <div className="card-body card-shadow card-custom-padding">
                                <span className="card-text">YTD BUDGET</span> <span className="card-value">${Helper.setMetricSuffix(this.state.financialData.staffOverhead.YTDBudget, this.state.numberFormat)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.staffOverheadChartOptions}
                                /> */}
                              <Datatable
                                myData={this.state.financialData.staffOverhead.table}
                                headerColumns={this.state.staffOverheadTableHeaderColumns}
                                pageSize={this.state.financialData.staffOverhead.table.length > 20 ? 20 : this.state.financialData.staffOverhead.table.length}
                                pagination={true}
                                myClass="table table-striped table-bordered table-responsive"
                              />
                          </div>
                        </div>
                      </Fragment>
                        : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.totalModalOpen} onClose={this.closeModalCourse}   classNames={{modal: "modal-90w"}} >
          <div className="modal-body">
            <div className="product-modal row">
              <div className="principal-profile cust-model-size">
                <div className="row">
                  <div className="col-xl-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-header card-header-blue">
                        <h5 ref={this.state.titleFocus}>{this.state.ModalTitle}</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status">
                          {this.state.totalModalChartLoaded || this.state.totalModalDrillDownChartLoaded ?
                            <Fragment>
                              <div className="row">
                                {this.state.totalModalChartLoaded ? 
                                  <Fragment>
                                    <div className="col-6">
                                      <div className="card">
                                        <div className="card-body card-shadow card-custom-padding">
                                          <span className="card-text">PREVIOUS YEAR</span> <span className="card-value">${Helper.setMetricSuffix(this.state.totalModalPreviousYear, this.state.numberFormat)}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="card">
                                        <div className="card-body card-shadow card-custom-padding">
                                        <span className="card-text">THIS YEAR YTD</span> <span className="card-value">${Helper.setMetricSuffix(this.state.totalModalThisYear, this.state.numberFormat)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                  :
                                  <div className="col-6">
                                    <div className="card">
                                      <div className="card-body card-blue card-custom-padding" onClick={this.totalModalOpen}>
                                      <span className="card-text"> &lt;&lt; BACK</span>
                                      </div>
                                    </div>
                                  </div>
                                }
                                <div className="col-12">
                                {this.state.totalModalChartLoaded ? 
                                  <HighchartsReact
                                      highcharts={Highcharts}
                                      options={this.state.totalModalChartOptions}
                                      />
                                  :
                                  <HighchartsReact
                                      highcharts={Highcharts}
                                      options={this.state.totalModalDrillDownChartOptions}
                                      />
                                  }
                                  </div>
                              </div>
                            </Fragment>
                              : <div style={{display: "flex", justifyContent: "center"}} ><img src ={preloaderGif} height="100px"></img></div>
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        </Fragment>
    )
  }
}


export default PrincipalProfileWrapper(withRouter(PrincipalProfile));