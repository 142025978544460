import React, { useState ,Fragment } from 'react';
import { useDispatch } from 'react-redux'
import Language from './language';
import UserMenu from './userMenu';
import Notification from './notification';
import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { AlignLeft, Filter, Maximize, Bell, X, MessageCircle, MoreHorizontal } from 'react-feather';
import YearFilter from "./../header-component/YearFilter";
import MonthFilter from "./../header-component/MonthFilter";

const tassLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tass_logo.png`;

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);

 const togglePanel = (type) => {
     const className = type === 'sidebar' ? 'mainMenuOpen' : 'filterMenuOpen';
     const classList = document.querySelector("#tassWrapper").classList;
     // the panel is open
     if (classList.contains(className)) {
         return classList.remove(className);
     }
     // the panel is closed
     return classList.add(className);
  }

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="page-main-header" >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                <img className="img-fluid" src={tassLogo} alt="" />
              </Link>
            </div>
          </div>

          {/* <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div> */}
          <a href="#_" className="burgerMenu" onClick={() => {togglePanel('sidebar')}}>
              <AlignLeft />
          </a>
          <a href="#_" className="filterMenu" onClick={() => {togglePanel('filerMenu')}}>
              <Filter />
          </a>
          <div className="filter-panel">
              <div className="yearfilter">
                  <YearFilter/>
              </div>
              <div className="monthfilter">
                <MonthFilter/>
              </div>
          </div>

          {/* <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className="col-md-6 col-xs-12">
                      <YearFilter/>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <MonthFilter/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal/></div>
          </div> */}

          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
          </script>
        </div>
      </div>
      <div className="mobile-filter-panel">
        <div className="mobileMenuHead">
            <a href="#_" className="close" onClick={() => {togglePanel('filerMenu')}}>
                <X />
            </a>
            <h6>Filter</h6>
        </div>

        <YearFilter mobile/>
        <MonthFilter mobile/>
      </div>
    </Fragment>
  )
};
export default Header;