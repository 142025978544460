import React from "react";
import AccIndividualStudentProfile from "./AccIndividualStudentProfile";
import { withRouter } from 'react-router-dom'

const SingleStudentProfile = (props) => {
    const params = new URLSearchParams(props.location.search);
    if(params.get('profile') == 'acc'){
        return (
            <AccIndividualStudentProfile location={props.location}/>
        );
    }else{
    }
};

export default withRouter(SingleStudentProfile);