import React from "react";
import { Modal, Space, Typography, } from "antd";
import { InfoCircleFilled } from '@ant-design/icons';
import { AI_DISCLAIMER_INFO_ARRAY } from "../../../../../constant/innovative/common";

const { Title } = Typography;

const AiDisclaimerModal = ({
  closeModal,
  isOpen,
}) => {
  return (
    <div>
      <div>
        <Modal
          title="Octopus BI | AI Generator Disclaimer"
          visible={isOpen}
          onOk={() => { closeModal() }}
          onCancel={() => { closeModal() }}
          okText="Understood"
          maskClosable={false}
          className="disclaimerModal"
        >
          <p>
            This disclaimer is intended to inform users of Octopus Insights about the use of “Octopus AI” feature, developed within the Application. Please read this Disclaimer carefully before using the Application.
          </p>

          {AI_DISCLAIMER_INFO_ARRAY.map(disclaimer => <div className='cluster-info-section-card-text'>
            <Space>
              <InfoCircleFilled style={{ color: "#4A44E5" }} />
              <Title level={4} className='sub-title'>
                {disclaimer.title}
              </Title>
            </Space>
            <p>
              {disclaimer.info}
            </p>
          </div>)
          }
        </Modal>
      </div>
    </div>
  )
}

export default AiDisclaimerModal;
