import serviceMaker from "../service";
import session from "../session";

const make = (requestOptions) => {
    return new Promise((resolve, reject) => {
        serviceMaker.make({
            ...requestOptions,
            metaKey: session.keys.impressionMeta
        }).then(response => resolve(response)).catch(error => reject(error))
    })
}

const service = {
    make,
};

export default service;