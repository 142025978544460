import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import './../../../assets/css/tass-web/rsuite-default.css';
import DataStore from "./../common/DataStore";
import Loader from "./../common/Loader";
import LoginComponent from "./../common/LoginComponent";
import Breadcrumb from "../common/Breadcrumb";
import Helper from "./../common/Helper";
import { setSideBarActive } from '../../../actions/authentication.action';

const textBlock = `${process.env.REACT_APP_S3_BUCKET_URL}/tassweb/public/einstain.png`;
const blurimg = `${process.env.REACT_APP_S3_BUCKET_URL}/tassweb/public/solvency.png`;

class Dashboard extends Component {
  state = {
    filters: {},
    months: DataStore.getMonths(),
    authUser: localStorage.getItem('authUser') || false,
    valueFormat: 'M'
  };

  componentDidMount() {
    this.props.dispatch(setSideBarActive({sideBarActive : 'solvency'}));
    const {headerFilters} = this.props;
    this.setState({filters: headerFilters}, () => {
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {headerFilters} = this.props;
    if (headerFilters !== prevProps.headerFilters) {
      // ApiService.cancelOperatingExpenses();
      this.setState({filters: headerFilters}, () => {
        // on year change
        if (headerFilters.year !== prevProps.headerFilters.year) {
        }
        // on month change
        if (headerFilters.period !== prevProps.headerFilters.period) {
        }
      });
    }
  }

  render() {

    return (
      <Fragment>
          <div className="bgBlur">
                <img src={blurimg} /> 
          </div>
          <div className="comingSoonCover">
                <div className="contentBlock">
                      <img src={textBlock} />
                      <div className="textBlock">
                        <p> 
                            {/* <b>Hi there knowledge seeker! </b><br /> */}
                            We are thrilled that you clicked, but we are still working hard in the background to get this dashboard ready for action. <a href="mailto:info@octopusbi.com" >Click here</a> so that we can let you know as soon as it is ready for you.
                        </p>
                      </div>
                </div>
          </div>
      </Fragment>
    );
}
}

const mapStateToProps = (state) => {
  return {
    headerFilters: state.filters.header
  }
}

export default connect(mapStateToProps)(Dashboard);