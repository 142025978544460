export const chartConf = {

    chart: {
        height: "150px",
    },

    plotOptions: {
        series: {
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
        pie: {
            size: "110px",
            innerSize: "65px",
            minSize: 90,
            slicedOffset:0,
            dataLabels: {
                enabled: false
            },
            borderWidth: 0
        }
    },

    tooltip: {
        enabled: false
    },

}