import React, {Component} from "react";
import ApexChart from "react-apexcharts";

class ApexChartComponent extends Component {
  intervalID = 0;
    state = { 
        options : this.props.chartOptions,
        series: this.props.series,
        type: this.props.type,
        chartID: this.props.chartID
     }
     componentDidMount() {
        this.intervalID =  setInterval(() => {
            this.updateCharts();
        }, 1000);
        
      }
      componentWillUnmount() {
        clearInterval(this.intervalID);
      }
      updateCharts = () => {
        this.setState({
            options : this.props.chartOptions,
            series: this.props.series,
        });
      }
    render() {
        return ( 
            <ApexChart
              options={this.state.options}
              series={this.state.series}
              type={this.state.type}
              // width="500"
            /> );
    }
}

export default ApexChartComponent;
