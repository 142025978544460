import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {reactLocalStorage} from "reactjs-localstorage";

const PrivateCanvasRoute = ({ component: Component, Authentication, ...rest }) => {
  const {isLoggedIn} = reactLocalStorage.getObject('interimUser', undefined);
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};



const mapStateToProps = (state) => {
    return {
        Authentication: state.Authentication,
    };
};

export default connect(mapStateToProps)(PrivateCanvasRoute);
