import React, {memo} from "react";
import { List } from 'antd';
import PropTypes from 'prop-types';
import DashboardCard from "./DashboardCard";

const DashboardGrid = ({dashboards, saveDashboard}) => {

    const cardRenderer = (dashboard) => {
        return (
            <DashboardCard 
                dashboard={dashboard} 
                saveDashboard={saveDashboard} 
            />
        )
    }

    const makeDashboardGrid = () => {
        return  (
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 5,
                }}
                dataSource={dashboards}
                renderItem={dashboard => (
                    cardRenderer(dashboard)
                )}
            />

        )
    }

    return (
        <div className="site-card-wrapper">
            {makeDashboardGrid()}
        </div>
    )
}
DashboardGrid.prototype = {
    dashboards: PropTypes.array,
    saveDashboard: PropTypes.func
}

export default memo(DashboardGrid);
