import React, {useState} from "react";
import PropTypes from "prop-types";
import {Layout} from "antd";
import {RightSquareOutlined} from "@ant-design/icons";

const vgaLogo = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/vga_logo.jpg`;
const { Sider } = Layout;

const Sidebar = ({width, children}) => {
    const [collapse, setCollapse] = useState(false);

    const renderTrigger = () => {
        return (
            <div><RightSquareOutlined/></div>
        )
    }

    const onBreakPoint = (broken) => {
        if (broken){
            !collapse && setCollapse(true);
        }else{
            collapse && setCollapse(false);
        }
    }

    return (
        <Sider
            width={width}
            className="vw-layout-sidebar"
            theme="light"
            breakpoint="md"
            collapsed={collapse}
            collapsedWidth="0"
            zeroWidthTriggerStyle={{top:0, right:-37, border:"1px solid #D9D9D9"}}
            trigger={renderTrigger()}
            onBreakpoint={onBreakPoint}
            onCollapse={() => setCollapse(!collapse)}
        >
            {children}

            <div className="vw-sidebar-footer">
                <img width={170} height={50} style={{opacity: 0.4}} src={vgaLogo} alt="vga-logo"/>
            </div>
        </Sider>
    )
}

Sidebar.defaultProps = {
    width: 200
}

Sidebar.propTypes = {
    width: PropTypes.number
}

export default Sidebar;