import React from "react";
import { Route, Redirect } from "react-router-dom";
import {getDataFromLocalStore} from "../platformBaseHelpers/jobReady/general";

const JrPrivateRoute = ({ component: Component, adminOnlyRoute, ...rest }) => {
    const {isLoggedIn, isAdmin} = getDataFromLocalStore([{key:"isLoggedIn", default: false}, {key:"isAdmin", default: false}]);

    const checkAdminRights = (props) => {
        if(adminOnlyRoute){
            if(isAdmin){
                return <Component {...props} />
            }else{
                return <Redirect
                    to={{
                        pathname: "/error-404",
                        state: { home: "/jr/dashboard/manage" },
                    }}
                />
            }
        }else{
            return <Component {...props} />
        }
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return isLoggedIn ? (
                    checkAdminRights(props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/tenant/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};


export default JrPrivateRoute;
