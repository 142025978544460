import React from "react";
import { Modal, Space, Typography, } from "antd";
import { AI_GUID_INFO_ARRAY } from "../../../../../constant/innovative/common";

const { Title } = Typography;

const AiUserGuidModal = ({
  closeModal,
  isOpen,
}) => {
  return (
    <div>
      <div>
        <Modal
          title="Quick Guide to Course Inquiries"
          visible={isOpen}
          onOk={() => { closeModal() }}
          onCancel={() => { closeModal() }}
          maskClosable={false}
          footer={null}
          className="guidModal"
        >
          <p>
            This guide outlines how to ask questions about your course effectively. Use the '@' or '#' symbol followed by specific parameters to tailor your inquiry for precise answers.
          </p>

          {AI_GUID_INFO_ARRAY.map(section => <div className='guidInfoSection'>
            <Space>
              <Title level={4}>
                {section.title}
              </Title>
            </Space>

            {section.info.map(guid => guid)}
          </div>)
          }
        </Modal>
      </div>
    </div>
  )
}

export default AiUserGuidModal;
