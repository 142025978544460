import React, { memo, useState, useEffect } from 'react';
import { Collapse, Table, Row, Col, Spin } from 'antd';
import Icon, { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons'; 
import Highcharts from 'highcharts';
import HighChartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import AddStock from "highcharts/modules/stock";
import AccLoader from "./accLoader";
AddStock(Highcharts);
HighChartsMore(Highcharts);

const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;
const windowWidth = window.innerWidth;
const assignmentRedStatus = ['Excused', 'Missing'];

const indicator = <AccLoader styles={{height: '35px', verticalAlign: 'middle'}}/>;
const CollapsePlus = <Icon component={PlusSquareOutlined} style={{ fontSize: "24px"}} />;
const CollapseMin = <Icon component={MinusSquareOutlined} style={{ fontSize: "24px"}} />;
const noDataMessage = "There is no data for the selected year and semester."


const CourseSection = props => {
  const { course, chartOptions, getRenderedHtml, filterChanged } = props;
  const { isLoading, hasNoData, statuscode, message  } = course;
  const [isExpanded, setIsExpanded] = useState('');
  const hasError = statuscode && message;

  useEffect(() => {
    setIsExpanded('');
  }, [filterChanged])

  const getAssignmentStatus = (text, record) => {
    if (record.assignmentResult === '-' || assignmentRedStatus.includes(text)) {
      return (
        <div key={record.key} style={{ color: '#F12828' }}>
          {text}
        </div>
      );
    }

    return (
      <div key={record.key} style={{ color: '#389b65' }}>
        {text}
      </div>
    );
  };

  const renderDataHeaders = () => {
    if (isLoading) {
      return (
        <Col span={14}>
          <div style={{
            width: '100%',
            minHeight: '100%',
            textAlign: 'center',
          }}>
            <Spin indicator={indicator}/>
          </div>
        </Col>
      );
    }

    if (hasNoData) {
      return (
        <Col span={14} className="gutter-row">
          <div style={{
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
            fontWeight: 'bold',
          }}>
            <p className='course-message'>{noDataMessage}</p>
          </div>
        </Col>
      );
    }

    if (hasError) {
      return (
        <Col span={14} className="gutter-row">
          <div style={{
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
            fontWeight: 'bold',
          }}>
            <p className='course-message'>{message}</p>
          </div>
        </Col>
      );
    }

    return (
      <>
        <Col
          className="gutter-row"
          style={{
              textAlign: 'center',
              marginTop: 'auto',
              marginBottom: 'auto',
              fontWeight: 'bold',
          }}
          span={6}
        >
          <h4>
            {' '}
            {getRenderedHtml(course.subject_grade)}&ensp;{course.finalMark}
          </h4>
        </Col>
        <Col className="gutter-row" span={8}>
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
          </div>
        </Col>
      </>
    )
  }

  const getRowHeader = () => {
    return (
      <div style={{
        display: 'flex',
        width: (windowWidth - 200),
        minHeight: '160px',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Col
          className="gutter-row"
          span={10}
          style={{
            textAlign: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <div
            className="card-body"
            style={{
              backgroundColor: '#003470',
              color: '#ffffff',
              height: '100%',
              marginBottom: '10px',
            }}
          >
            <div className="user-status table-responsive">
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  fontSize: '18px',
                }}
              >
                {course.name}
              </div>
            </div>
          </div>
        </Col>
          { renderDataHeaders() }
      </div>
    );
  };

  const getCollapseButton = () => {

    return (
      <div style={{display: 'flex', position: 'relative'}}>
        {isExpanded ? CollapseMin : CollapsePlus}
        <p style={{marginLeft:'10px', fontSize:"18px", marginTop: "2px"}}>
          { isExpanded ? 'Hide Assignments' : 'Show Assignments' }
        </p>
      </div>
    )
  };

  const onChangeCollapse = (status) => {
    props.onCollapse(status);
    setIsExpanded(status);
  };

  return (
    <div 
      id={`course_${course.courseId1}`}
      className='course' 
      style={{ height: 'auto', marginBottom: '20px' }}
    >
      <Row gutter={[8, 8]}>
        <Collapse 
          accordion
          key="collapseCourseData"
          onChange={onChangeCollapse}
          expandIcon={getCollapseButton}
          activeKey={isExpanded}
        >
          <Panel
            header={getRowHeader()}
            key={course.courseId1}
            showArrow={
              !(isLoading || hasNoData || hasError)
            }
            collapsible={
              isLoading || hasNoData || hasError ? 'disabled' : true
            }
          >
            <Col className="gutter-row" span={24}>
              <Table
                dataSource={course.assignmentList}
                pagination={false}
                style={{ paddingBottom: '4px' }}
              >
                <ColumnGroup>
                  <Column
                    className="acc-data-table"
                    title={
                      <div className="assignment-tasks-header">
                        Assignments Tasks
                      </div>
                    }
                    dataIndex="nameOfAssignment"
                    width="40%"
                    key="nameOfAssignment"
                  />
                  <Column
                    className="acc-data-table"
                    title={
                      <div className="assignment-tasks-header">
                        Assignment Result
                      </div>
                    }
                    dataIndex="assignmentResult"
                    width="29%"
                    key="assignmentResult"
                  />
                  <Column
                    className="acc-data-table"
                    style={{ padding: '10px' }}
                    title={
                      <div className="assignment-tasks-header">
                        Assignment Status
                      </div>
                    }
                    dataIndex="assignmentStatus"
                    width="31%"
                    key="assignmentStatus"
                    render={getAssignmentStatus}
                  />
                </ColumnGroup>
              </Table>
            </Col>
          </Panel>
        </Collapse>
      </Row>
    </div>
  );
};

export default memo(CourseSection);