import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Space, List } from 'antd';
import _startCase from 'lodash/startCase';

import SettingColorPicker from '../ColorPicker';

const OverallIndicatorsView = ({
  subSection,
  data,
  onValueChange,
  hasPermission,
  errors,
}) => {

  const getFieldStyles = (type, key) => {
    const errorKey = `overall_${type}_${key}`;

    if (errors[errorKey]) {
      return { 
        width: 70,
        border: '1px solid #f5222d',
      };
    }

    return { width: 70 };
  };

  const renderMeasurementContent = type => (
    <List.Item>
      <div>
        <Space>
          <InputNumber
            disabled={!hasPermission}
            min={0}
            max={100}
            style={getFieldStyles(type, 'min')}
            onChange={value => onValueChange(type, 'min', value)}
            value={data && data[type].min}
          />
          <span>&amp;</span>
          <InputNumber
            disabled={!hasPermission}
            min={0}
            max={100}
            style={getFieldStyles(type, 'max')}
            onChange={value => onValueChange(type, 'max', value)}
            value={data && data[type].max}
          />
        </Space>
      </div>
      <div>
        <Space>
          <div style={{ width: 70 }}>
            <SettingColorPicker
              disabled={!hasPermission}
              onChange={color => onValueChange(type, 'color', color)}
              value={data && data[type].color}
              text={_startCase(type)}
            />
          </div>
        </Space>
      </div>
    </List.Item>
  );

  return (
    <List
      size="small"
      header={
        <div className="ri-overall-header">
          <div>{_startCase(subSection)} Risk Score Range</div>
          <div>Colour</div>
        </div>
      }
      bordered
    >
      {renderMeasurementContent('low')}
      {renderMeasurementContent('medium')}
      {renderMeasurementContent('high')}
    </List>
  );
};

OverallIndicatorsView.propTypes = {
  subSection: PropTypes.string,
  data: PropTypes.object,
  onValueChange: PropTypes.func,
  hasPermission: PropTypes.bool,
  errors: PropTypes.object,
};

export default OverallIndicatorsView;
