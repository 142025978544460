import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getDataFromLocalStore } from '../platformBaseHelpers/jobReady/general';

const VetTrackPrivateRoute = ({
  component: Component,
  adminOnlyRoute,
  ...rest
}) => {
  const { isLoggedIn, isAdmin } = getDataFromLocalStore([
    { key: 'isLoggedIn', default: false },
    { key: 'isAdmin', default: false },
  ]);

  const checkAdminRights = props => {
    if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/tenant/login',
            state: { from: props.location },
          }}
        />
      );
    }

    if (adminOnlyRoute) {
      if (isAdmin) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/error-404',
            state: { home: '/vettrack/dashboard/manage' },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={checkAdminRights} />;
};

VetTrackPrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
  adminOnlyRoute: PropTypes.bool,
};
export default VetTrackPrivateRoute;
