/***
 * This class is used to handle the chart data fetching functionality in student profile in Innovative dashboard
 * In this, array based fetching is based as the main fetching handler and data is fetched in sequence manner
***/

class ChartDataFetch {
    constructor(arraySize, courseIds, dispatchFunc) {
        this.courseArray = [];
        this.currentIndex = 0;
        this.arraySize = arraySize;
        this.courseIds = courseIds;
        this.dispatchFunc = dispatchFunc;
    }

    initiateFetch = () => {
        // add to array
        this.courseArray.push(...this.courseIds.slice(0,this.arraySize))
        this.currentIndex = this.arraySize - 1;

        // Initiate fetch call for courses
        this.courseArray.map(course => this.dispatchFunc(course))
    }

    isArrayCompleted = () => this.courseIds.length - 1 === this.currentIndex;


    validateArray = (fetchedCourses, selectedTablePagination) => {
        const sliceArrayFrom = (selectedTablePagination === 1 ? 0 : ((selectedTablePagination - 1) * 10) - 1);
        const sliceArrayTo = sliceArrayFrom + 9;
        const paginationTableDataArray = this.courseIds.slice(sliceArrayFrom, sliceArrayTo);
        const fetchQueueArray = selectedTablePagination >= 1 ? paginationTableDataArray : fetchedCourses;

        if (!this.isArrayCompleted()) {
            this.courseArray.map(course => {
                if (fetchQueueArray.includes(course)) {
                    const nextCourse = this.getNextCourse();
                    if (nextCourse) this.updateArray(nextCourse, course);
                }
            });
        }
    }

    getNextCourse = () => {
        if(!this.isArrayCompleted() && ((this.currentIndex + 1) in this.courseIds)){
            const newCourse =  this.courseIds[this.currentIndex + 1];
            this.currentIndex += 1;
            return newCourse;
        }else {
            return false;
        }
    }

    updateArray = (newCourse, oldCourse) => {
        this.courseArray = this.courseArray.filter(item => item !== oldCourse)
        this.courseArray.push(newCourse)
        // Initiate fetch call newly added course
        this.dispatchFunc(newCourse)
    }

}

export default ChartDataFetch;