import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import LineChart from '../../../../../common/charts/LineChart';

import { commonLineChartConf } from '../../../../../../../../constant/vetTrack/configs';

const { Title } = Typography;

const LineChartCard = ({ title, data }) => {
  const customConf = {
    xAxis: {
      categories: data?.categories,
    },
    series: data?.seriesData,
  };

  return (
    <div>
      {title && <Title level={5}>{title}</Title>}
      <LineChart chartOptions={{ ...commonLineChartConf, ...customConf }} />
    </div>
  );
};

LineChartCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};

export default LineChartCard;
