export default {
  getMonths: function () {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return months;
  },
  getMonthInLong: function () {
    const months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    return months;
  },
  getTwelveMonthData: function () {
    return {
      response: [
        {period:1, value:{income:122222,expenses:234100,liabilities:34,assets:54}},
        {period:2, value:{income:124533,expenses:234,liabilities:34,assets:54}},
        {period:3, value:{income:123532,expenses:445553,liabilities:34,assets:54}},
        {period:4, value:{income:12343,expenses:0,liabilities:34,assets:54}},
        {period:5, value:{income:12653,expenses:1,liabilities:34,assets:54}},
        {period:6, value:{income:-23453,expenses:0,liabilities:34,assets:54}},
        {period:7, value:{income:0,expenses:-10000,liabilities:34,assets:54}},
        {period:8, value:{income:-126783,expenses:100,liabilities:34,assets:54}},
        {period:9, value:{income:12223,expenses:100,liabilities:34,assets:54}},
        {period:10, value:{income:123,expenses:100,liabilities:34,assets:54}},
        {period:11, value:{income:123,expenses:-100,liabilities:34,assets:54}},
        {period:12, value:{income:123,expenses:100,liabilities:34,assets:54}},
      ]
    }
  }
};
