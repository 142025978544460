import { combineReducers } from "redux";
import layout from "./layout.reducer";
import settings from "./settings.reducer";
import dashboard from "./dashboard.reducer";
import stats from "./stats.reducer";
import saleAdmin from "./saleAdmin.reducer";
import twoFaAuth from "./twoFaAuth.reducer";
import studentClusterAnalyzer from "./studentClusterAnalyzer";
import appTesting from "./app.testing.reducer";
import dataPrivacyManagementReducer from"./dataPrivacyManagement.reducer"

const dashboardManagementReducers = combineReducers({
    layout,
    settings,
    dashboard,
    stats,
    saleAdmin,
    twoFaAuth,
    studentClusterAnalyzer,
    appTesting,
    dataPrivacyManagementReducer,
});

export default dashboardManagementReducers;