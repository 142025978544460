import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PieChart from '../../../../../../../common/charts/PieChart';

import { topCardPieChartConf } from '../../../../../../../../../../constant/vetTrack/configs';

const TopCard = ({ name, data }) => {
  const customConf = {
    series: [
      {
        colorByPoint: true,
        data,
      },
    ],
  };

  return (
    <div className="mainCard">
      <div className="heading">{name}</div>
      <PieChart chartOptions={{ ...topCardPieChartConf, ...customConf }} />
    </div>
  );
};

TopCard.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array,
};

export default memo(TopCard);
