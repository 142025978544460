import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import { Button, Tag } from 'antd';
import moment from 'moment';
import { makeInnovativeSettingByPath } from '../../../../selectors/innovative/setting.selector';
import MetaCellRenderer from '../../common/table/MetaCellRenderer';
import ChartDonutCellRenderer from '../../common/table/ChartDonutCellRenderer';
import {
  resolveFromObjectByKey,
  resolvePropertiesFromSettingObject,
  resolvePropertiesFromCohortData,
} from '../../../../utils/innovative/resolveSettings';
import AssignmentComparisonGridView from './AssignmentComparisonGridView';
import {
  getAssignmentGradeCohortData,
  getAssignmentSubmissionCohortData,
  makeInnovativeAssignmentsComparisonResponseData,
  makeInnovativeAssignmentsLoading,
} from '../../../../selectors/innovative/assignment.selector';
import StatCellRenderer from '../../common/table/statCell/StatCellRenderer';
import StatCellRenderContainer from '../../common/table/statCell';
import TableColumnFilterDropdown from '../../common/table/partials/TableColumnFilterDropdown';
import { FILTER_MODULES } from '../../../../utils/innovative/constants';
import {useParams} from "react-router";
import ChartWithLoader from '../../studentProfile/courseComparisonGrid/cellRenderers/ChartWithLoader';

const DUE_DATE_FORMAT = 'DD/MM/YYYY';

const AssignmentComparisonGridContainer = ({currentFilter, setTaskTablePagination}) => {
  const { courseId} = useParams();

  // selectors
  const colorScheme = useSelector(makeInnovativeSettingByPath('colorScheme'));
  const areAssignmentsLoading = useSelector(makeInnovativeAssignmentsLoading);
  const assignmentsList = useSelector(
    makeInnovativeAssignmentsComparisonResponseData,
  );
  const gradeCohortData = useSelector(getAssignmentGradeCohortData);
  const gradeSubmissionData = useSelector(getAssignmentSubmissionCohortData);

  // state
  const [drawerMeta, setAssignmentDrawerMeta] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [columnFilterSearch, setColumnFilterSearch] = useState({
    assignment_name: '',
    student_grade: '',
    due_date: '',
    student_submission: '',
  });

  const getCourseChartData = (assignmentId, data, dataFormatter = null) => {
    // find the chart data of the particular assignment and format if needed
    // since the cohort and gauge chart data fetched separately, we need this
    // interim function to slice data
    
    const assignmentData = data.find(item => item && parseInt(item.assignmentId) === parseInt(assignmentId));

    if (assignmentData) {
      return {
        data: dataFormatter
          ? { assignmentId, data: dataFormatter(assignmentData.data) }
          : assignmentData,
        isLoading: false,
      };
    }

    return { data: null, isLoading: true };
  }

  const handleAssignmentView = (metaData, id) => {
    setAssignmentDrawerMeta(true);
    setAssignmentId(id);
  };

  const getMetaCell = (value, row) => (
    <MetaCellRenderer
      title={value}      
      suppressAvatar
    />
  );

  const getGradeCell = (value, row) => (
    <StatCellRenderer
      label={value === 'N/A' ? '' : ''}
      value={value}
      styles={{ color: row.student_grade_color }}
    />
  );

  const getCohortCell = (_, row) => (
    <ChartWithLoader
      resolver={resolvePropertiesFromCohortData}
      component={ChartDonutCellRenderer}
      {...getCourseChartData(row.assignment_id, gradeCohortData)}
      customOptions={{ legend: { itemWidth: 100 }, chart: { height: 120, marginTop: 20 } }}
    />
  );

  const getSubMissionCell = (value, row) => (
    <StatCellRenderContainer
      params={{
        key: _camelCase(value),
        value,
        row,
        settings: colorScheme.submission,
        resolver: resolveFromObjectByKey,
      }}
    />
  );

  const getSubMissionCohortCell = (_, row) => (
    <ChartWithLoader
      resolver={resolvePropertiesFromSettingObject}
      settings={colorScheme.submission}
      component={ChartDonutCellRenderer}
      {...getCourseChartData(row.assignment_id, gradeSubmissionData)}
      customOptions={{ chart: { height: 120, marginTop: 20 } }}
    />
  );

  const getActionCell = (value, row) => (
    <Button
      htmlType="button"
      onClick={() => handleAssignmentView(true, row.assignment_id)}
    >
      View
    </Button>
  );

  const renderModuleState = (rowData) => {

    if (rowData?.moduleState) {
      return (
      <Tag 
        color={rowData.modelStateColor} 
        className="module-state">
          {rowData.moduleState}
      </Tag>
      );
    }
    return (<div className="module-state">N/A</div>);
  }

  const getModuleStatus = (_, row) => {
    return <div>{renderModuleState(row)}</div>;
  };

  const getDueDateCell = value => {
    const cellValueDate = moment(value, DUE_DATE_FORMAT);

    if (cellValueDate.isValid()) {
      return cellValueDate.format(DUE_DATE_FORMAT);
    }

    return 'N/A';
  };

  const defaultSorter = (data, column) => {
    const [value1, value2, sort] = data;

    if (sort === 'ascend') {
      _get(value2, `${column}`, '').localeCompare(
        _get(value1, `${column}`, ''),
      );
    }
    return _get(value1, `${column}`, '').localeCompare(
      _get(value2, `${column}`, ''),
    );
  };

  const getNameFilters = column => {
    const filterValues = [];
    const currentValues = [];
    assignmentsList.forEach(item => {
      const value = _get(item, `${column}`, '');

      if (!currentValues.includes(value)) {
        filterValues.push({ text: value, value });
        currentValues.push(value);
      }
    });

    const search = columnFilterSearch[column];

    if (search) {
      return filterValues.filter(item => {
        const value = _get(item, 'value', '');
        return value.toLowerCase().includes(search.toLowerCase());
      });
    }
    return filterValues;
  };

  const onColumnFilter = (value, record, column) =>
    _get(record, `${column}`, '').includes(value);

  const filterDropdown = (filterProps, column) => (
    <TableColumnFilterDropdown
      {...filterProps}
      columnIndex={column}
      setColumnFilterSearch={setColumnFilterSearch}
      columnFilterSearch={columnFilterSearch}
      assignmentsList={assignmentsList}
    />
  );

  // conditional columns
  const optionalColumnDefinition = () => {
    if(currentFilter === FILTER_MODULES){
      return  [{
        key: 'module_status',
        title: 'Module Status',
        dataIndex: 'module_status',
        width: 180,
        className: 'moduleStatus',
        render: getModuleStatus,
      }];
    };

    return [];
  };

  const columnDefinition = [
    {
      key: 'assignment_name',
      title: 'Task',
      dataIndex: 'assignment_name',
      render: getMetaCell,
      sorter: (...data) => defaultSorter(data, 'assignment_name'),
      filters: getNameFilters('assignment_name'),
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'assignment_name'),
      filterDropdown: filterProps =>
        filterDropdown(filterProps, 'assignment_name'),
    },
    {
      key: 'student_grade',
      title: 'Student Grade',
      dataIndex: 'student_grade',
      render: getGradeCell,
      sorter: (...data) => defaultSorter(data, 'student_grade'),
      filters: getNameFilters('student_grade'),
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'student_grade'),
      filterDropdown: filterProps =>
        filterDropdown(filterProps, 'student_grade'),
    },
    {
      key: 'grade_cohort',
      title: 'Cohort',
      dataIndex: 'grade_cohort',
      className: 'cohortChart',
      width: 220,
      render: getCohortCell,
    },
    {
      key: 'student_submission',
      title: "Student's Submission",
      dataIndex: 'student_submission',
      className: 'submisionValue',
      render: getSubMissionCell,
      sorter: (...data) => defaultSorter(data, 'student_submission'),
      filters: getNameFilters('student_submission'),
      onFilter: (value, record) =>
        onColumnFilter(value, record, 'student_submission'),
      filterDropdown: filterProps =>
        filterDropdown(filterProps, 'student_submission'),
    },
    {
      key: 'due_date',
      title: 'Due Date',
      dataIndex: 'due_date',
      render: getDueDateCell,
      defaultSortOrder: 'ascend',
      sorter: (...data) => defaultSorter(data, 'due_date'),
      className: 'due-date-column',
      filters: getNameFilters('due_date'),
      onFilter: (value, record) => onColumnFilter(value, record, 'due_date'),
      filterDropdown: filterProps => filterDropdown(filterProps, 'due_date'),
    },
    {
      key: 'submission_chohort',
      title: 'Cohort',
      dataIndex: 'submission_chohort',
      width: 225,
      className: 'cohortChart',
      render: getSubMissionCohortCell,
    },
    ...optionalColumnDefinition(),
    {
      key: 'actions',
      title: '',
      fixed: 'right',
      render: getActionCell,
    },
  ];

  return (
    <AssignmentComparisonGridView
      dataSource={assignmentsList}
      columns={columnDefinition}
      isLoading={areAssignmentsLoading}
      assignmentDrawerVisibility={!!drawerMeta}
      assignmentDrawerData={{}}
      setAssignmentDrawerMeta={setAssignmentDrawerMeta}
      assignmentId={assignmentId}
      setTaskTablePagination={setTaskTablePagination}
    />
  );
};

export default AssignmentComparisonGridContainer;
