import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserManagementTable from "./userManagementTable";
import { getUserManagementUserList } from "../../../../selectors/userManagement/userManagement.selector";
import RenderTableHeader from "./renderTableHeader";

const UserManagementTableView = ({
  setEnableSideDrawer,
  setSelectedUsersArray,
  selectedUsersArray,
}) => {
  const userList = useSelector(getUserManagementUserList);
  const [tableUserListArray, setTableUserListArray] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    setTableUserListArray(userList);
  }, [JSON.stringify(userList)]);

  return (
    <div>
      <RenderTableHeader
        searchString={searchString}
        setSearchString={setSearchString}
        setTableUserListArray={setTableUserListArray}
      />
      <UserManagementTable
        userList={tableUserListArray}
        setEnableSideDrawer={setEnableSideDrawer}
        setSelectedUsersArray={setSelectedUsersArray}
        selectedUsersArray={selectedUsersArray}
      />
    </div>)
}

export default UserManagementTableView;
