import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ children, isLoading, ...rest }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin
      className="vtt-gen-loader"
      indicator={antIcon}
      spinning={isLoading}
      {...rest}
    >
      {children}
    </Spin>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isLoading: PropTypes.bool,
};

export default Loader;
