import React, {Fragment} from "react";

import SyncingBanner from "./SyncingBannerView";

export const withSyncingBanner = (Component) => ({...props}) => {
    const {isSyncing} = props;

    return <Fragment>
        {isSyncing && <SyncingBanner/>}
        <Component
        {...props}
    />
    </Fragment>
}