import React, { useEffect, memo } from "react";
import { Layout, Row, Col } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import CustomPaginator from "./sidebarPartials/Pagination";
import {
  adminVerificationLoading,
  getStudentClusterDataLoading,
  getIsApplicationAdmin,
} from "../../../../selectors/innovative/course.selector";
import { updateApplicationLevelAdmin } from "../../../../actions/innovative/course.action";
import CourseList from "./sidebarPartials/CourseList";

const ADMIN_PATH = "/insights/admin/courses";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const SidebarCourseView = () => {
  const isAdminVerificationLoading = useSelector(adminVerificationLoading);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs");

  const isAdminDashboardView = () => {
    return (path === ADMIN_PATH &&
      (
        isApplicationAdminData.role.toLowerCase() === ACCOUNT_MEMBERSHIP ||
        actAs.toLowerCase() === ACCOUNT_MEMBERSHIP
      ));
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs
    }));
  }, [actAs]);

  const className = isClusterDataLoading ? "in-sectionCover-disabled-course-view" : "in-sectionCover";

  return (
    <Layout.Sider
      width={250}
      breakpoint="md"
      collapsedWidth="0"
      trigger={null}
    >
      <div className={className}>
        <Row gutter={[16, 5]} justify={"center"}>
          <Col>
            {!isAdminVerificationLoading && isAdminDashboardView() && (
              <CustomPaginator />
            )}
          </Col>
          <Col>
            {!isAdminVerificationLoading && !isAdminDashboardView() && (
              <CourseList />
            )}
          </Col>
        </Row>
      </div>
    </Layout.Sider>
  )
}

export default memo(SidebarCourseView);
