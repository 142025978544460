import React, {Fragment, useEffect, useState} from "react";
import {Alert, Card, Col, Row, Spin} from "antd";
import {LoadingOutlined, UserOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchConsumer} from "../../../actions/consumer.action";
import {
    makeConsumerFetchLoading,
    makeConsumerFetchResponseData
} from "../../../selectors/consumer.selector";
import Stepper from "./partials/Stepper";
import FormWrapper from "./partials/FormWrapper";
import LicenseConfigContainer from "./steps/containers/consumer/LicenseConfigContainer";
import UrlConfigContainer from "./steps/containers/consumer/UrlConfigContainer";
import KeyConfigContainer from "./steps/containers/consumer/KeyConfigContainer";

import "../../../assets/css/onboarding/common.css";

const ConsumerSignupContainer = () => {
    const consumerFetchLoading = useSelector(makeConsumerFetchLoading);
    const consumerFetchResult = useSelector(makeConsumerFetchResponseData);
    const {token} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const type = "whiteLabel"
    const steps = {
        whiteLabel: [
            {title: "licenseConfiguration", Component: LicenseConfigContainer},
            {title: "urlConfiguration", Component: UrlConfigContainer},
            {title: "keyConfiguration", Component: KeyConfigContainer},
        ]
    };

    useEffect(() => {
        setIsLoading(!!consumerFetchLoading);
    }, [consumerFetchLoading]);

    useEffect(() => {
        if (token) {
            dispatch(fetchConsumer(token));
        }
    }, [token]);

    const getSelectedStep = () => {
        return steps[type][activeStep];
    };

    const triggerEndAction = () => {
        history.push("/setup/consumer");
    };

    const renderFormSection = () => {
        if (consumerFetchResult === null) {
            return <Alert
                message=""
                description="No record found"
                type="warning"
                showIcon/>
        }

        return (
            <Fragment>
                <Stepper steps={steps[type]} activeStep={activeStep}/>
                <FormWrapper
                    type={type}
                    totalSteps={steps[type].length}
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    selected={getSelectedStep()}
                    editMode={!!token}
                    initialValues={consumerFetchResult}
                    triggerEndAction={triggerEndAction}
                />
            </Fragment>
        );
    };

    const renderContent = () => {
        return (
            <Fragment>
                <Row gutter={75} className={"layout-row"} wrap={true}>
                    <Col flex="1 1 50%"  className={"layout-video"}>
                        <h3>Welcome to Tentacle Insights</h3>
                        <p>
                        {/* Need to update a description here */}
                        </p>
                        <div className="videoCover">
                            <iframe
                                width="100%"
                                height="315"
                                src="https://www.youtube.com/embed/unPT_CtL2js"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                    </Col>
                    <Col flex="1 1 50%"  className={"layout-form"}>
                        <Card  className={"layout-card"}>
                            {renderFormSection()}
                        </Card>
                    </Col>
                </Row>

            </Fragment>
        );
    };

    return (
        <Fragment>
            <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
                <Row>
                    <Col span={24} className="text-center p-t-10">
                        <h2> Enter Details </h2>
                    </Col>
                </Row>

                <div className="wizardContainr">
                    {renderContent()}
                </div>
            </Spin>
        </Fragment>
    );
};

export default ConsumerSignupContainer;