import React, { memo } from "react";
import { Button, Card, Col, Row } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';

const UserInfoCard = ({ title, keyValue }) => {
  return (
    <Card className="userInfoCard">
      <Row>
        <Col md={24} lg={12} >
          <h6>{title}</h6>
          <p>{keyValue}</p>
        </Col>
        <Col md={24} lg={12} >
          <Row style={{ height: "100%" }} justify="end" align="middle">
            <CopyToClipboard text={keyValue}>
              <Button type="primary" ghost  icon={<CopyOutlined />} className="userInfoCard-btn">
                Copy
              </Button>
            </CopyToClipboard>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default memo(UserInfoCard);
