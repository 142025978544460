import React, {useEffect, useState} from "react";
import {Button, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {
    getAcademicStudentsData,
    getAcademicStudentsLoading,
    getAcademicFactorsData
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/academic.selector";
import {fetchAcademicStudents} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/academic.action";
import {
    getDashboardFiltersSelectedData,
    getSelectedCardsByPath
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {setSelectedCards} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/common.action";

import Students from "./Students";
import StudentDetailCell from "../../../../../common/StudentDetailCell";
import ProgressBarCell from "../../../../../common/ProgressBarCell";
import Loader from "../../../../../../../../common/layout/Loader";
import {filterStudentByCard} from "../../../../../common/topCards/subSectionTopCards";

import {academicSections, subSections} from "../../../../../../../../../../constant/jobReadyGeneric/constants";
import {
    getUniqValuesForFilters,
} from "../../../../../../../../utils/general";
import {prepareRiskMeasurementsColumns} from "../../../../../../../../utils/studentTable";
import NoDataCell from "../../../../../../../../common/partials/NoDataCell";

const StudentsTab = () => {
    const dispatch = useDispatch();
    const [filteredStudents, setFilteredStudents] = useState([])

    // selectors
    const students = useSelector(getAcademicStudentsData);
    const enabledFactors = useSelector(getAcademicFactorsData);
    const studentsLoading = useSelector(getAcademicStudentsLoading);
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const selectedCard = useSelector(getSelectedCardsByPath(subSections.academic.key));


    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters) && !studentsLoading){
            const {academic} = subSections;
            const data = {
                filters: selectedFilters,
                subSectionId: academic.id
            }
            dispatch(setSelectedCards({[subSections.academic.key]: null}))
            dispatch(fetchAcademicStudents(data));
        }
    }, [selectedFilters])

    useEffect(() => {
        if(students){
            setFilteredStudents(students);
        }
    }, [students])

    useEffect(() => {
        filterStudentByCard(selectedCard, setFilteredStudents, students, subSections.academic.key)
    }, [selectedCard])

    const renderProgressCells = (data) => {
        if (data) {
            return (
                <ProgressBarCell
                    value={data.value}
                    displayValue={data.value}
                    color={data.color}
                />
            );
        }

        return <NoDataCell />;
    };

    const textCellRender = value => {
        if (value === null || value === undefined) {
            return <NoDataCell />;
        }

        return value;
    };

    const columns = [
        {
            title: "",
            className: "table-remove-left",
            children: [
                {
                    title: 'Student Details',
                    dataIndex: 'studentDetails',
                    sorter: (a, b) => a.studentDetails.name && a.studentDetails.name.localeCompare(b.studentDetails.name),
                    render: (value) => <StudentDetailCell title={value.name} subtitle={value.id} avatar={value.image}/>,
                    onFilter: (value, record) => record.studentDetails.name === value,
                    filters: getUniqValuesForFilters(students, "studentDetails.name", "studentDetails.name"),
                    fixed: "left",
                },
                {
                    title: 'Course',
                    dataIndex: ['course','name'],
                    sorter: (a, b) => a.course.name && a.course.name.localeCompare(b.course.name),
                    onFilter: (value, record) => record.course.name === value,
                    filters: getUniqValuesForFilters(students, "course.name", "course.name"),
                    render: textCellRender
                },
                {
                    title: 'Academic Risk Score',
                    dataIndex: 'academic',
                    sorter: (a, b) => parseFloat(a.academic.value) - parseFloat(b.academic.value),
                    onFilter: (value, record) => record.academic.value === value,
                    render: (value) => renderProgressCells(value),
                    filters: getUniqValuesForFilters(students, "academic.value", "academic.value"),
                },
            ]
        },
        {
            title: 'Risk Measurements',
            children: prepareRiskMeasurementsColumns(enabledFactors, students, academicSections)
        },
        // Not in phase 01
        // {
        //     title: "",
        //     align:"center",
        //     render: (value, record) => <Space>
        //         <Button size="small">View</Button>
        //         <Button size="small" type="primary" ghost>Take Action</Button>
        //     </Space>,
        //     fixed: "right",
        // },
    ];


    return (
        <Loader isLoading={studentsLoading}>
            <Students
                students={filteredStudents}
                columns={columns}
            />
        </Loader>
    )
}

export default StudentsTab;