import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import {
  getAcademicCardsLoading,
  getAcademicCardsData,
  getDashboardFiltersSelectedData,
} from '../../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchAcademicCards } from '../../../../../../../../actions/vetTrack/analysis/riskAnalysis/academic.action';

import SubSectionTopCards from '../../common/topCards/subSectionTopCards';
import InnerTabSection from './innerTabSection/InnerTabSection';

import { subSections } from '../../../../../../../../constant/vetTrack/analysis';

const AcademicTabContainer = () => {
  const [activeTab, setActiveTab] = useState(null);
  const dispatch = useDispatch();

  // selectors
  const cardData = useSelector(getAcademicCardsData);
  const cardDataLoading = useSelector(getAcademicCardsLoading);
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);

  // effects
  useEffect(() => {
    setActiveTab('students');
  }, []);

  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters)) {
      const { academic } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: academic.id,
      };
      dispatch(fetchAcademicCards(data));
    }
  }, [selectedFilters]);

  const onTabChange = key => {
    setActiveTab(key);
  };

  return (
    <div>
      <SubSectionTopCards
        cardData={cardData?.cards}
        cardLoading={cardDataLoading}
        chartData={cardData?.charts}
        subSection={subSections.academic.key}
      />
      <br />
      <InnerTabSection tabConfig={{ activeTab, setActiveTab: onTabChange }} />
    </div>
  );
};

export default AcademicTabContainer;
