import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { get } from "lodash";

const DEFAULT_VALUE = "Not setup";

const DeveloperKeyCard = ({
  setPopupModalVisible,
  setClearPopupModalVisible,
  credentialManagementData
}) => {
  const [isKeysAvailable, setIsKeysAvailable] = useState(false);
  const [clientId, setClientId] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);

  const getKeys = value => {
    if (
      value === null ||
      !value.length
    ) {
      setIsKeysAvailable(false);
      return DEFAULT_VALUE;
    }
    setIsKeysAvailable(true);
    return value;
  }

  useEffect(() => {
    setClientId(
      getKeys(
        get(
          credentialManagementData,
          "data.devTokenList.api_client_id",
          DEFAULT_VALUE
        )
      )
    );
    setClientSecret(
      getKeys(
        get(
          credentialManagementData,
          "data.devTokenList.secretMask",
          DEFAULT_VALUE
        )
      )
    );
  }, [JSON.stringify(credentialManagementData)]);

  const renderKeyValue = value => {
    if (isKeysAvailable) {
      return <p>{value}</p>
    }
    return <p className="fadeValue">{value}</p>
  };

  const renderButtonOptions = () => {
    if (isKeysAvailable) {
      return (
        <>
          <Button
            className="copy-btn"
            type="primary"
            onClick={() => { setPopupModalVisible(true) }}
            icon={<EditOutlined />}
          >
            Update
          </Button>
          <Button
            className="copy-btn"
            type="secondary"
            onClick={() => setClearPopupModalVisible(true)}
            icon={<DeleteOutlined />}
          >
            Clear
          </Button>
        </>
      );
    }
    return (
      <Button
        className="copy-btn"
        type="primary"
        onClick={() => { setPopupModalVisible(true) }}
        icon={<EditOutlined />}
      >
        Add developer Key
      </Button>
    );
  };

  return (
    <Card
      style={{ width: "100%" }}
      className="userInfoCard"
    >
      <Row >
        <Col md={24} lg={8}>
          <p>Client ID</p>
          {renderKeyValue(clientId)}
        </Col>
        <Col md={24} lg={8}>
          <p>Client Secret</p>
          {renderKeyValue(clientSecret)}
        </Col>
        <Col md={24} lg={8}>
          <Row
            style={{ height: "100%" }}
            wrap={true}
            justify="end"
            align="middle"
          >
            <div className="actionButtons">
              {renderButtonOptions()}
            </div>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default DeveloperKeyCard;
