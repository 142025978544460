import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Loader from '../../common/loader';
import { fetchGlobalSettings } from '../../../actions/dashboardManagement/settings.action';


const GlobalSettingsLayout = ({ children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalSettings());
  }, [])


  return (
    <div className='gs-wrapper'>
      <Loader isLoding={true} />
      {children}
    </div>
  );
}

export default GlobalSettingsLayout;