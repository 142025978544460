import {
    JR_GEN_SETTINGS_FETCH,
    JR_GEN_SETTINGS_FETCH_LOADING,
    JR_GEN_DATA_SOURCES_OPTIONS_FETCH,
    JR_GEN_DATA_SOURCES_OPTIONS_FETCH_LOADING,
    JR_GEN_SETTINGS_ALTERNATION,
    JR_GEN_SETTINGS_SAVE,
    JR_GEN_SETTINGS_SAVE_LOADING
} from "../../../constant/actionTypes";
import _get from "lodash/get";
import _has from "lodash/has";
import _set from "lodash/set";
import {settingsSections} from "../../../constant/jobReadyGeneric/constants";

const defaultState = {
    selected: {
        isLoading: false
    },
    saved: {
        isLoading: false
    },
    data: {},
    drawerSelected: {
        isLoading: false,
        type: null,
        options: []
    }
}

const KEY = settingsSections.dataSources;

const dataSourcesReducer = (state=defaultState, action) => {
    const {path, section, payload, type} = action;

    switch (type) {
        case JR_GEN_SETTINGS_FETCH_LOADING:
            if(section===KEY && path && _get(state, path, undefined)){
                return {..._set(state, path, payload)}
            }else{
                return state
            }
        case JR_GEN_SETTINGS_FETCH:
            if(section===KEY && path && _get(state, path, undefined)){
                return {..._set({..._set(state, path, payload)}, `${path}2`, payload)}
            }else{
                return state
            }
        case JR_GEN_SETTINGS_ALTERNATION:
            if(_has(state, path)){
                return {..._set(state, path, payload.data)}
            }else{
                return state
            }
        case JR_GEN_SETTINGS_SAVE_LOADING:
        case JR_GEN_SETTINGS_SAVE:
            if(section===KEY && path && _get(state, path, undefined)){
                return {..._set(state, path, payload)}
            }else{
                return state
            }

        case JR_GEN_DATA_SOURCES_OPTIONS_FETCH:
        case JR_GEN_DATA_SOURCES_OPTIONS_FETCH_LOADING:
            return {
                ...state,
                drawerSelected: {
                    ...state.drawerSelected,
                    ...payload
                }
            }
        default:
            return state
    }
}

export default dataSourcesReducer;