import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setMenuSelected, setBreadcrumb } from '../../../actions/dashboardManagement/layout.action';
import { makeDashboardSettingByPath, makeDashboardSettingsLoading, makeDashboardSettingsResponseData, makeDashboardSettingsSaveLoading } from '../../../selectors/dashboardManagement/settings.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import Loader from '../../innovative/common/layout/Loader';
import useSettingsHelpers from '../../../utils/innovative/settingHandler';
import ConfirmationPopupGlobal from '../../innovative/common/settingsComponents/confirmationPopupGlobal';
import { Col, Layout, Row } from 'antd';

const ColorScheme = React.lazy(() => import('../../innovative/common/settingsComponents/colorScheme'));

const ColorSchemeContainer = () => {

  const dispatch = useDispatch();

  const { isModalVisible, setIsModalVisible, onResetDefault, onConfirmHandler, modalConfig, onPreSubmit } = useSettingsHelpers();

  const pageSettingsPath = "colorScheme";

  const pageSettings = useSelector(makeDashboardSettingByPath(pageSettingsPath))

  const pageSettingsPathMarking = "markingScheme";

  const settingsLoading = useSelector(makeDashboardSettingsLoading);

  const settingsSaving = useSelector(makeDashboardSettingsSaveLoading);

  const pageSettingsMarking = useSelector(
    makeDashboardSettingByPath(pageSettingsPathMarking)
  );


  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.globalConfigurations.key,
      section: menuData.globalConfigurations.name
    },
    {
      key: menuData.colorScheme.key,
      section: menuData.colorScheme.name,
    },
  ];

  useEffect(() => {
    dispatch(setMenuSelected(menuData.colorScheme.key));
    dispatch(setBreadcrumb(breadcrumbItems));
  }, []);

  return (
    <div>
      <ConfirmationPopupGlobal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onConfirm={onConfirmHandler}
        modalConfig={modalConfig}
      />
      <Layout>
        <Suspense fallback={<Loader isLoading={true} />}>
          <div className='in-smallContainer'>
            <Loader isLoading={settingsLoading || settingsSaving} />
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ColorScheme
                  outcomeVisible={false}
                  onSubmit={onPreSubmit}
                  onResetDefault={onResetDefault}
                  pageSettings={pageSettings}
                  pageSettingsPath={pageSettingsPath}
                  pageSettingsMarking={pageSettingsMarking}
                />
              </Col>
            </Row>
          </div>
        </Suspense>
      </Layout>
    </div>
  )

}

export default ColorSchemeContainer;