import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';
import { updateSubUser, removeSubUser } from "./actions/authentication.action";
import { getSubUser } from "./selectors/onboarding/auth.selector";
import { getLicenseManagerTokenStillValid } from "./selectors/consumer.selector";
import { GET } from "./utils/network";

const PrivateOnboardRoute = ({ component: Component, Authentication,render:renders, ...rest }) => {
  const dispatch = useDispatch();
  const subUser = useSelector(getSubUser)
  const sessionIsValid = useSelector(getLicenseManagerTokenStillValid)
  const {isLoggedIn} = reactLocalStorage.getObject('subUser', undefined);
  const {verified} = reactLocalStorage.getObject('subUserVerified', undefined);
  let authorized = false;

  if(isLoggedIn && verified){
    if(!authorized){
      authorized = true
    }
  }

  useEffect(() => {
    // set a time to validate session availability
    setTimeout(() => {
      // make a request to find the availability of the user with the token
      dispatch(updateSubUser({ token: subUser.response.token }))
      // set 31 minutes to check if session is expired
    }, 1000 * 60 * 31);
  }, [dispatch, subUser.response.token]);

  // check and log user out if session is not available
  if (sessionIsValid === false) {
    GET(`${process.env.REACT_APP_APIURL}logout`)
      .then(() => {
        dispatch(removeSubUser())
      })
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // if user is authorized render flow else redirect user to login page
        return authorized ? (
          renders()
        ) : (
          <Redirect
            to={{
              pathname: "/setup/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};



const mapStateToProps = (state) => {
    return {
        Authentication: state.Authentication,
    };
};

export default connect(mapStateToProps)(PrivateOnboardRoute);
