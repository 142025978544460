import _get from "lodash/get";

// my dashboard state
export const myDashboardState = state => _get(state, 'jrDashboardManage.dashboards');

// return my dashboard list data
export const makeDashboardListData = state => {
    const result = myDashboardState(state);
    return result && _get(result, 'list.data', []);

};

// return my dashboard list loading
export const makeDashboardListLoading = state => {
    const result = myDashboardState(state);
    return result && _get(result, 'list.isLoading', false);

};

// return my dashboard save loading
export const makeDashboardSavedDataLoading = state => {
    const result = myDashboardState(state);
    return result && _get(result, 'saved.isLoading', false);

};

// return my dashboard validity loading
export const makeDashboardValidityLoading = state => {
    const result = myDashboardState(state);
    return result && _get(result, 'dashboardValidity.isLoading', false);

};

// return my dashboard validity data
export const makeDashboardValidityData = state => {
    const result = myDashboardState(state);
    return result && _get(result, 'dashboardValidity.data');

};

