import React, {useEffect} from "react";
import {Form} from "antd";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {Redirect} from "react-router-dom";

import {
    getExternalUserLoading
} from "../../../../selectors/authentication.selector";
import {
    getDomainDataLoading,
    getDomainMetaData
} from "../../../../selectors/tentacleMeta.selector";
import { getSamlStrategies, getSamlStrategiesIsLoading } from "../../../../selectors/authentication/samlAuthentication.selector";
import {externalUserAuth} from "../../../../actions/authentication.action";
import {requestLoginMethods} from "../../../../actions/authentication/samlAuthentication.action";

import ExternalLogin from "./Login";
import {onError} from "../../../../utils/notificationHandler";

import localStore from "../../../../utils/localStorage";

const ExternalLoginContainer = () => {
    const [loginForm] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const baseUrl = process.env.REACT_APP_APIURL;

    // selectors
    const authLoading = useSelector(getExternalUserLoading);
    const domainDataLoading = useSelector(getDomainDataLoading);
    const domainData = useSelector(getDomainMetaData);
    const samlMethods = useSelector(getSamlStrategies);
    const strategiesLoading = useSelector(getSamlStrategiesIsLoading);

    const meta = localStore.getLsSlim(localStore.keys.authMeta, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET);

    const onsubmit = (values) => {
        dispatch(externalUserAuth({...values, domain: process.env.REACT_APP_OVERRIDE_DOMAIN || window.location.hostname}, (data) => {
            let statCheck = true;
            if(!domainData){
                statCheck = false;
                onError("No domain meta data found");
            }
            if(!domainData.main_url){
                statCheck = false;
                onError("No main URL found for redirection")
            }
            if (statCheck){
                localStore.setLsSlim(localStore.keys.authMeta, data, 25200, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET)
            }
            domainData && history.replace(domainData.main_url)
        }));
    }

    useEffect(() => {
        const subDomain = {domain: window.location.hostname};
        dispatch(requestLoginMethods(subDomain))
    },[]);

    const connectWithSsoClick = (method) => {
        const identifier = method.strategy;
        window.location.href=`${baseUrl}saml/login?identifier=${identifier}&domain=${window.location.origin}`;
    } 

    return (
        meta ? <Redirect
                to={{
                    pathname: domainData.main_url,
                }}
            /> :
            <ExternalLogin
                form={loginForm}
                onsubmit={onsubmit}
                isLoading={authLoading || domainDataLoading}
                title={domainData && domainData.login_title ? domainData.login_title : "Tentacle Login" }
                connectWithSSO={connectWithSsoClick}
                samlMethods= {samlMethods}
                strategiesLoading= {strategiesLoading}
                domainCode={domainData && domainData.code}
            />
    )
}

export default ExternalLoginContainer;