import {
  VTT_OVERVIEW_CHARTS_FETCH,
  VTT_OVERVIEW_CHARTS_FETCH_LOADING,
  VTT_OVERVIEW_STUDENTS_FETCH,
  VTT_OVERVIEW_STUDENTS_FETCH_LOADING,
} from '../../../../constant/actionTypes';

const defaultState = {
  charts: {
    isLoading: false,
    data: {},
  },
  students: {
    isLoading: false,
    data: {},
  },
};

const raOverviewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VTT_OVERVIEW_CHARTS_FETCH:
    case VTT_OVERVIEW_CHARTS_FETCH_LOADING:
      return {
        ...state,
        charts: {
          ...state.charts,
          ...action.payload,
        },
      };
    case VTT_OVERVIEW_STUDENTS_FETCH:
    case VTT_OVERVIEW_STUDENTS_FETCH_LOADING:
      return {
        ...state,
        students: {
          ...state.students,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default raOverviewReducer;
