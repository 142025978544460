// return consumer fetch response
export const makeConsumerFetchResponse = state => state && state.consumer && state.consumer.selected;

// return consumer fetch response loading status
export const makeConsumerFetchLoading = state => {
    const result = makeConsumerFetchResponse(state)
    return result && result.isLoading;
};

// return consumer fetch response data
export const makeConsumerFetchResponseData = state => {
    const result = makeConsumerFetchResponse(state)
    return result && result.response && result.response.data
};

// return consumer fetch response error
export const makeConsumerFetchError = state => {
    const result = makeConsumerFetchResponse(state)
    return result.error
};


// return consumer fetch response
export const makeConsumersFetchResponse = state => state && state.consumer && state.consumer.list;

// return consumers fetch response loading status
export const makeConsumersFetchLoading = state => {
    const result = makeConsumersFetchResponse(state)
    return result && result.isLoading;
};

// return consumers fetch response data
export const makeConsumersFetchResponseData = state => {
    const result = makeConsumersFetchResponse(state)
    return result && result.response && result.response.data
};

// return consumers fetch response error
export const makeConsumersFetchError = state => {
    const result = makeConsumersFetchResponse(state)
    return result.error
};


// return consumer save response
export const makeConsumerSaveResponse = state => state && state.consumer && state.consumer.created;

// return consumer save response loading status
export const makeConsumerSaveLoading = state => {
    const result = makeConsumerSaveResponse(state)
    return result && result.isLoading;
};

// return consumer save response data
export const makeConsumerSaveResponseData = state => {
    const result = makeConsumerSaveResponse(state)
    return result && result.response && result.response.data
};

// return consumer save response error
export const makeConsumerSaveError = state => {
    const result = makeConsumerSaveResponse(state)
    return result.error
};


// return consumer update response
export const makeConsumerUpdateResponse = state => state && state.consumer && state.consumer.updated;

// return consumer update response loading status
export const makeConsumerUpdateLoading = state => {
    const result = makeConsumerUpdateResponse(state)
    return result && result.isLoading;
};

// return consumer update response data
export const makeConsumerUpdateResponseData = state => {
    const result = makeConsumerUpdateResponse(state)
    return result && result.response && result.response.data
};

// return consumer update response error
export const makeConsumerUpdateError = state => {
    const result = makeConsumerUpdateResponse(state)
    return result.error
};


// return consumer delete response
export const makeConsumerDeleteResponse = state => state && state.consumer && state.consumer.deleted;

// return consumer delete response loading status
export const makeConsumerDeleteLoading = state => {
    const result = makeConsumerDeleteResponse(state)
    return result && result.isLoading;
};

// return consumer delete response data
export const makeConsumerDeleteResponseData = state => {
    const result = makeConsumerDeleteResponse(state)
    return result && result.response && result.response.data
};

// return consumer delete response error
export const makeConsumerDeleteError = state => {
    const result = makeConsumerDeleteResponse(state)
    return result.error
};


// return keygen
export const makeKeygenResponse = state => state && state.consumer && state.consumer.keygen;

// return keygen loading status
export const makeKeygenResponseData = state => {
    const result = makeKeygenResponse(state);
    return result && result.response;
};

// return keygen response data
export const makeKeygenLoading = state => {
    const result = makeKeygenResponse(state);
    return result && result.isLoading;
};

// return password reset 
export const getLicenseManagerReset = state => state && state.consumer.reset;

// return license manager reset password loading
export const getLicenseManagerResetPasswordLoading = state => {
    const result = getLicenseManagerReset(state)
    return result && result.isLoading;
};

// return reset link
export const getLicenseManagerResetEmail = state => state && state.consumer.resetLink;

// return license manager reset email loading
export const getLicenseManagerResetPasswordEmailLoading = state => {
    const result = getLicenseManagerResetEmail(state)
    return result && result.isLoading;
};

// return verification code
export const getLicenseManagerSendCode = state => state && state.consumer.sendCode;

// return license manager request code loading
export const getLicenseManagerSendCodeIsLoading = state => {
    const result = getLicenseManagerSendCode(state)
    return result && result.isLoading;
};

export const getLicenseManagerVerificationData = state => {
    const result = getLicenseManagerSendCode(state)
    return result.verificationId;
};

// send verification code
export const getLicenseManagerValidateCode = state => state && state.consumer.validateCode;

// return license manager request code loading
export const getLicenseManagerValidateCodeIsLoading = state => {
    const result = getLicenseManagerValidateCode(state)
    return result && result.isLoading;
};

// send verification code
export const getLicenseManagerUpdate = state => state && state.consumer.licenseManagerUpdate;

// return license manager request code loading
export const getLicenseManagerTokenStillValid = state => {
    const result = getLicenseManagerUpdate(state)
    return result && result.tokenValidity;
};

