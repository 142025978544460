import React, { useState, useCallback, useEffect } from "react";
import { Typography, InputNumber, notification, List, Tooltip } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _map from "lodash/map";
import { useDispatch, useSelector } from "react-redux";

import {
    setEditMode as setEditModeData,
    saveUnitsTableOrderData
} from "../../../../../actions/vwDealership/settings.action";
import {
    makeVwDealershipEditModeDataResponse,
    makeVwDealershipSelectedProgram,
    makeVwDealershipSidebarOrderDataResponse,
} from "../../../../../selectors/vwDealership/setting.selector";

import UnitsTable from "./UnitsTable";

const { Text } = Typography;

const UnitsTableContainer = ({ units }) => {
    const [page, setPage] = useState(1);
    const [filteredUnits, setFilteredUnits] = useState(units);
    const [filterMeta, setFilterMeta] = useState({
        roles: [],
        units: [],
    })
    const [tableMeta, setTableMeta] = useState({
        filteredInfo: {},
        current: units,
    });
    const dispatch = useDispatch();

    // selectors
    const editMode = useSelector(makeVwDealershipEditModeDataResponse);
    const selectedProgram = useSelector(makeVwDealershipSelectedProgram);
    const allOrderData = useSelector(makeVwDealershipSidebarOrderDataResponse)

    const pagination = {
        current: page,
        onChange: setPage,
        total: filteredUnits,
        showSizeChanger: false,
        defaultPageSize: 20
    };

    useEffect(() => {
        if (filteredUnits) {
            setFilterMeta({
                roles: prepareFilterValues(filteredUnits, "roleName", "roleName"),
                units: prepareFilterValues(filteredUnits, "name", "name"),
            })
        }
    }, [filteredUnits])

    const renderOrdering = (id, value, row) => {
        return <InputNumber
            disabled={!editMode}
            key={id}
            name={id}
            value={value}
            size="small"
            max={99}
            min={1}
            stringMode={true}
            controls={false}
            onChange={(e) => onChangeItem(id, { order: e })}
            style={{ width: 50 }}
        />
    }

    const handleChangeTable = (pagination, filters, sorter, extras) => {
        const { currentDataSource } = extras;
        setTableMeta({
            filteredInfo: filters,
            sortedInfo: sorter,
            current: currentDataSource,
        });
    };

    const onChangeItem = (id, data) => {
        // update the unit record with user input
        onUnitPropChange(filteredUnits.map(item => (item.recordId === id ? { ...item, ...data } : item)))

    }


    const prepareFilterValues = useCallback((dataset, uniqKey, valueProp) => {
        if (Array.isArray(dataset)) {
            return [...new Map(dataset.map(item => {
                return [item[uniqKey], { text: item[valueProp], value: item[uniqKey] }]
            })).values()];
        }
        return []
    }, [tableMeta.current]);


    const columns = [
        {
            title: 'Units',
            dataIndex: ['name'],
            key: 'unit',
            filters: filterMeta.units,
            onFilter: (value, record) => record.name === value,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.unit || null,
            // render: (name) => <Tooltip title={name}>{name}</Tooltip>
        },
        {
            title: 'Role',
            dataIndex: ['roleName'],
            key: 'role',
            filters: filterMeta.roles,
            onFilter: (value, record) => record.roleName === value,
            sorter: (a, b) => a.roleName.localeCompare(b.roleName),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.role || null,
            render: (roleName) => <Tooltip title={roleName}>{roleName}</Tooltip>
        },
        {
            title: 'Order',
            dataIndex: 'order',
            align: "center",
            sorter: (a, b) => a.staticOrder - b.staticOrder,
            render: (value, row) => renderOrdering(row.recordId, value, row),
            defaultSortOrder: "ascend",
            fixed: 'right'
        },

    ];

    const getFilteredUnits = () => {
        return units && units.length && units.map(({ order, ...rest }) => ({ staticOrder: order, ...rest, order }));
    }

    useEffect(() => {
        const formattedUnits = getFilteredUnits();
        setFilteredUnits(formattedUnits);
        setTableMeta({ filteredInfo: {}, current: units })
    }, [units, selectedProgram])

    const onUnitPropChange = (data) => {
        // set updated dataset as units when user input
        setFilteredUnits(data)
    }

    const setEditMode = (data) => {
        dispatch(setEditModeData(data))
    }

    const setEdit = (mode) => {
        if (mode) {
            setEditMode(true);
        } else {
            resetData();
        }
    }

    const resetData = () => {
        setEditMode(false);
        setFilteredUnits(units);
    }

    const renderNotification = (title, unit) => {
        notification["error"]({
            message: `Cannot have duplicate order values for the role ${title}`,
            description:
                <div>
                    <List size="small">
                        {unit && <List.Item><Text strong>Unit: {unit.name}</Text></List.Item>}
                        {unit && <List.Item><Text strong>Order: {unit.order}</Text></List.Item>}
                    </List>
                </div>,
        });
    }

    const handleBulkUpdate = (value) => {
        const filteredIds = tableMeta.current.map(item => item.recordId);
        const newFilteredUnits = filteredUnits.map(unit => {
            if (filteredIds.indexOf(unit.recordId) !== -1) {
                return { ...unit, order: value }
            }
            return unit;
        })
        setFilteredUnits(newFilteredUnits);
    }

    const validateData = () => {
        // filter the null values out of the array
        const unitsSorted = filteredUnits.filter(item => item.order !== null);
        // sort according to the order
        const sortedUnits = unitsSorted.sort((a, b) => parseInt(a.order) - parseInt(b.order));
        let dataObject = {}
        let errorFlag = [false, null, null];
        // update the dataObject to track unit order
        sortedUnits.every(unit => {
            if (unit.order) {
                if (dataObject[unit.roleName] && dataObject[unit.roleName][unit.order]) {
                    errorFlag = [true, unit.roleName, unit]
                    return false;
                }
                dataObject[unit.roleName] = {
                    ...dataObject[unit.roleName],
                    [unit.order]: 1,
                }
            }
            return true;
        })
        return errorFlag;
    }

    const onSubmit = () => {
        const validation = validateData();
        if (!validation[0]) {
            const newOrderData = allOrderData.programs.map(item => {
                if (item?.program?.id === selectedProgram) {
                    return { program: { ...item.program }, units: (filteredUnits.map(({ staticOrder, ...rest }) => ({ ...rest }))) }
                }
                return item;
            });
            const requestData = filteredUnits.map(({ recordId, order }) => ({ recordId, order }))
            dispatch(saveUnitsTableOrderData({ units: requestData }, newOrderData))
        }
        if (validation[0]) {
            renderNotification(validation[1], validation[2]);
        }
    }

    return (
        <UnitsTable
            handleBulkUpdate={handleBulkUpdate}
            bulkUpdateDisabled={!(editMode && tableMeta.filteredInfo.unit && tableMeta.filteredInfo.unit.length === 1)}
            dataset={filteredUnits}
            columns={columns}
            pagination={pagination}
            editMode={editMode}
            setEditMode={setEdit}
            onEditSubmit={onSubmit}
            handleChangeTable={handleChangeTable}
        />
    )
}

UnitsTableContainer.propTypes = {
    units: PropTypes.array,
}

export default UnitsTableContainer;