import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty as _isEmpty,
  get as _get,
} from 'lodash';

import {
  getDashboardFiltersSelectedData,
  getAcademicOverviewAttendanceResponse,
  getAcademicOverviewData,
  getAcademicOverviewDisabilitiesResponse,
  getAcademicOverviewLoading,
  getAcademicOverviewUnitProgressResponse,
} from '../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchAcademicDisabilitiesOverview,
  fetchAcademicOverview,
  fetchAcademicOverviewAttendance,
  fetchAcademicOverviewUnitProgress,
} from '../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/academic.action';

import Overview from './Overview';
import PopupTable from '../../../../../common/popupTable/PopupTable';
import StudentDetailCell from '../../../../../common/StudentDetailCell';
import ProgressBarCell from '../../../../../common/ProgressBarCell';
import NoDataCell from '../../../../../../../../common/partials/NoDataCell';

import {
  getUniqValuesForFilters,
  isEmptyValue,
} from '../../../../../../../../utils/general';
import {
  subSections,
  noDataValue,
} from '../../../../../../../../../../../constant/vetTrack/analysis';

const OverviewTab = () => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState({});

  // selectors
  const selectedFilters = useSelector(getDashboardFiltersSelectedData);
  const overviewDataLoading = useSelector(getAcademicOverviewLoading);
  const overviewData = useSelector(getAcademicOverviewData);
  const disabilitiesData = useSelector(getAcademicOverviewDisabilitiesResponse);
  const unitProgressData = useSelector(getAcademicOverviewUnitProgressResponse);
  const attendanceData = useSelector(getAcademicOverviewAttendanceResponse);

  // effects
  useEffect(() => {
    if (selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading) {
      const { academic } = subSections;
      const data = {
        filters: selectedFilters,
        subSectionId: academic.id,
      };
      dispatch(fetchAcademicOverview(data));
    }
  }, [selectedFilters]);

  const onPopupOpen = key => {
    setShowPopup(true);
    setSelectedKey(key);
    const selectedData = tableConfigMapper[key];

    const { academic } = subSections;
    const data = {
      filters: selectedFilters,
      subSectionId: academic.id,
      popupType: key,
    };

    if (selectedData.dispatcher) {
      dispatch(selectedData.dispatcher(data));
    }
    setSelectedConfig(selectedData);
  };

  const onPopupClose = () => {
    setShowPopup(false);
    setSelectedKey(null);
    setSelectedConfig({});
  };

  const renderProgressCells = data => {
    if (!_isEmpty(data) && !isEmptyValue(data.value)) {
      return (
        <div className={`overview-progress-cell-inline`}>
          <ProgressBarCell
            style={{
              width: "200px"
            }}
            showInfo={false}
            value={data.value}
            color={data.color}
            trailColor={`${data.color}1A`}//Opacity percentage in a Hex color code
          />
        </div>
      );
    }

    return <NoDataCell />;
  }

  const getDataSource = useCallback(() => {
    if (selectedKey) {
      switch (selectedKey) {
        case 'disabilities':
          return disabilitiesData;
        case 'unitProgress':
          return unitProgressData;
        case 'attendance':
          return attendanceData;
        default:
          return {};
      }
    }
    return {};
  }, [
    selectedKey,
    disabilitiesData,
    unitProgressData,
    attendanceData,
  ]);

  const onProgressItemFilter = (value, row, item) => {
    const recordValue = _get(row, `${item.dataIndex}.value`);

    if (value === noDataValue) {
      return isEmptyValue(recordValue);
    }

    return value === recordValue;
  };

  const getProgressItems = (progress, dataSource) => {
    if (!progress) return [];

    return progress.map(item => ({
      title: item.title,
      dataIndex: item.dataIndex,
      sorter: (value1, value2) =>
        parseFloat((value1[item.dataIndex] ? value1[item.dataIndex].value : null) || 0) -
        parseFloat((value2[item.dataIndex] ? value2[item.dataIndex].value : null) || 0),
      onFilter: (value, row) => onProgressItemFilter(value, row, item),
      render: value => renderProgressCells(value),
      filters: getUniqValuesForFilters(
        dataSource,
        `${item.dataIndex}.value`,
        `${item.dataIndex}.value`,
      ),
    }));
  };

  const renderPopupColumns = (progress = false) => {
    const values = getDataSource();
    const dataSource = _get(values, 'data.list', []);
    return [
      {
        title: 'Student Details',
        dataIndex: 'studentDetails',
        sorter: (value1, value2) =>
          value1.studentDetails.name &&
          value1.studentDetails.name.localeCompare(value2.studentDetails.name),
        render: value => (
          <StudentDetailCell
            title={value.name}
            subtitle={value.id}
            avatar={value.image}
          />
        ),
        onFilter: (value, record) => record.studentDetails.name === value,
        filters: getUniqValuesForFilters(
          dataSource,
          'studentDetails.name',
          'studentDetails.name',
        ),
      },
      {
        title: 'Course',
        dataIndex: ['course', 'name'],
        sorter: (value1, value2) =>
          value1.course.name && value1.course.name.localeCompare(value2.course.name),
        onFilter: (value, record) => record.course.name === value,
        filters: getUniqValuesForFilters(
          dataSource,
          'course.name',
          'course.name',
        ),
      },
      ...getProgressItems(progress, dataSource),
    ];
  };

  let tableConfigMapper = {
    disabilities: {
      columnConfig: null,
      title: 'Students with disabilities',
      dispatcher: data => fetchAcademicDisabilitiesOverview(data),
      searchableColumns: ['studentDetails.name', 'course.name'],
    },
    unitProgress: {
      columnConfig: [
        { title: 'Current Unit Progress', dataIndex: 'currentUnitProgress' },
      ],
      title: 'Unit Progress',
      dispatcher: data => fetchAcademicOverviewUnitProgress(data),
      searchableColumns: ['studentDetails.name', 'course.name'],
    },
    attendance: {
      columnConfig: [{ title: 'Attendance', dataIndex: 'attendance' }],
      title: 'Attendance',
      dispatcher: data => fetchAcademicOverviewAttendance(data),
      searchableColumns: ['studentDetails.name', 'course.name'],
    },
  };

  return (
    <>
      <Overview
        onPopupOpen={onPopupOpen}
        dataLoading={overviewDataLoading}
        overviewData={overviewData}
      />

      <PopupTable
        isLoading={getDataSource().isLoading}
        visible={showPopup}
        onCancel={onPopupClose}
        searchEnabled
        title={selectedConfig.title}
        columns={renderPopupColumns(selectedConfig.columnConfig)}
        dataSource={getDataSource().data?.list}
        searchableColumns={selectedConfig.searchableColumns}
      />
    </>
  );
};

export default OverviewTab;
