// return canvas key gen response
export const makeCanvasKeysResponse = state => state && state.signup && state.signup.canvasSetup;

// return canvas key gen response loading status
export const makeCanvasKeysLoading = state => {
    const result = makeCanvasKeysResponse(state)
    return result && result.isLoading;
};

// return canvas key gen response data
export const makeCanvasKeysResponseData = state => {
    const result = makeCanvasKeysResponse(state)
    return result && result.response && result.response.data
};

// return canvas key gen response error
export const makeCanvasKeysError = state => {
    const result = makeCanvasKeysResponse(state)
    return result.error
};