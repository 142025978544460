// UTILS TO HANDLE COMMON FUNCTIONS IN STUDENT TABLES ACROSS ALL TABS

import React from "react";
import {getSortMethod, getSubSectionDataByKey, getUniqValuesForFilters} from "./general";
import NoDataCell from "../common/partials/NoDataCell";

/**
 * Global function to prepare risk measurement columns in all the student tables in all tabs
 * @param {object[]} enabledFactors    enabled risk factors risk
 * @param {object[]} students  students array
 * @param {object} sectionData  section data object relevant to tab section
 * @param {object} customParams custom parameters for columns
 */
export const prepareRiskMeasurementsColumns = (enabledFactors, students, sectionData, customParams={}) => {
    return (enabledFactors?.map(factor => {
        const factorData = getSubSectionDataByKey(factor, sectionData);
        if(factorData){
            return {
                title: factorData.name,
                dataIndex: ["factors", factor],
                sorter: getSortMethod(factorData.mode, `factors.${factor}`),
                className: factorData.colClassName,
                onHeaderCell: () => ({style: {textAlign: "left"}}),
                width:200,
                filters: getUniqValuesForFilters(students, `factors.${factor}`, `factors.${factor}`),
                onFilter: (value, record) => record.factors[factor] === value,
                render: (text) => cellRender(text, factorData),
                ...customParams
            }
        }
    }))
}

const cellRender = (text, factorData) => {
  if (text === null || text === undefined) {
    return <NoDataCell />
  }
  return (
    <div>
      {factorData.displayValModifier ? factorData.displayValModifier(text) : text}
    </div>
  );
};
