import React from "react";
import {Table} from "antd";
import PropTypes from "prop-types";

import StatCell from "../common/StatCell";
import ChartCell from "../common/ChartCell";

const {Column} = Table;

const Product = ({paginationDisplayRows}) => {
    const dataSource = [
        {
            key: '1',
            id: '1',
            metric: "Up Time",
            value: 32,
            h24: {name:5.2, type:"up", color:"green"},
            d7: {name:0.0, type:"down", color:"red"},
            last7Days: [{data:[1, 2, 1, 5, 3, 4, 7], color: "red"}],
        },
        {
            key: '2',
            id: '2',
            metric: "Account retention rate",
            value: 50,
            h24: {name:5.2, type:"up", color:"green"},
            d7: {name:5.2, type:"up", color:"green"},
            last7Days: [{name: "critical",data: [5, 6, 1, 3, 3, 2, 1], color: "red"}, {name: "major",data:[4, 9, 7, 2, 6, 5, 3], color: "green"}]
        },

    ];

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Metric',
            dataIndex: 'metric',
            key: 'metric',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: '24h %',
            dataIndex: 'h24',
            key: 'h24',
            render:(text, rec, index) => (
                <StatCell type={rec.h24.type} value={rec.h24.name} valueColor={rec.h24.color}/>
            )
        },
        {
            title: '7d %',
            dataIndex: 'd7',
            key: 'd7',
            render:(text, rec, index) => (
                <StatCell type={rec.d7.type} value={rec.d7.name} valueColor={rec.d7.color}/>
            )
        },
        {
            title: 'Last 7 Days',
            dataIndex: 'last7Days',
            key: 'last7Days',
            render:(text, rec, index) => (
                <ChartCell
                    chartType="column"
                    series={rec.last7Days}
                    showLegend={true}
                    containerProps={{
                    style:{height:100, width:400, padding:0}
                }}
                />
            )
        },
    ];

    return (
        <div className="stat-tab-table-div">
            <Table dataSource={dataSource} pagination={{ pageSize: paginationDisplayRows}}>
                {columns.map(col => (
                    <Column title={col.title} dataIndex={col.dataIndex} key={col.key} render={col.render}/>
                ))}
            </Table>
        </div>
    )
}

Product.propTypes = {
    paginationDisplayRows: PropTypes.string
}

export default Product;