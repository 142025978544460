import {
    FETCH_SETTING_SIDEBAR_DATA,
    FETCH_SETTING_SIDEBAR_DATA_LOADING,
    SAVE_SETTING_UNITS_DATA,
    SAVE_SETTING_UNITS_DATA_LOADING,
    SET_OPEN_PANELS,
    SET_EDIT_MODE,
    CLEAR_SETTING_UNITS_DATA,
    FETCH_SETTING_SIDEBAR_UNITS_LOADING,
    FETCH_SETTING_SIDEBAR_UNITS,
    SET_SELECTED_PROGRAM,
    SAVE_SETTING_UNITS_ORDER_DATA,
    SAVE_SETTING_UNITS_ORDER_DATA_LOADING,
} from "../../constant/actionTypes";

import service from "../../utils/serviceHandlers/jobReady/customServices/vwDealership";
import { onSuccess, onError } from "../../utils/notificationHandler";

/** Clear Units data **/
// clear units data of sidebar
export const clearUnitsData = () => dispatch => {
    dispatch({
        type: CLEAR_SETTING_UNITS_DATA,
    });
};

// set open panels data of sidebar
export const setSelectedProgram = (programId) => dispatch => {
    dispatch({
        type: SET_SELECTED_PROGRAM,
        payload: programId,
    });
};

/** Set open panels **/
// set open panels data of sidebar
export const setOpenPanels = (data) => dispatch => {
    dispatch({
        type: SET_OPEN_PANELS,
        payload: data,
    });
};

/** Set edit mode **/
// set edit mode data of sidebar
export const setEditMode = (data) => dispatch => {
    dispatch({
        type: SET_EDIT_MODE,
        payload: data,
    });
};



/** Sidebar data fetch **/
// fetch sidebar data loading
const fetchingSidebarDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_SETTING_SIDEBAR_DATA_LOADING,
        payload: { isLoading },
    });
};

// fetch sidebar data loading
const fetchingSidebarUnitsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_SETTING_SIDEBAR_UNITS_LOADING,
        payload: { isLoading },
    });
};
// fetch sidebar units handler
const fetchSidebarUnitsStatus = (response, key) => dispatch => {
    let data;

    // filter only the requested data from the return object
    if (response.data) {
        data = response.data.data.programs;
    }
    dispatch({
        type: FETCH_SETTING_SIDEBAR_UNITS,
        payload: {
            isLoading: false,
            error: response.error,
            ...(data && { [`${key}s`]: data }),
            ...(response.data && response.data.data.units && { units: response.data.data.units }),
        },
    });
};

// fetch sidebar data handler
const fetchSidebarDataStatus = (response, key) => dispatch => {
    let data;

    // filter only the requested data from the return object
    if (response.data) {
        data = response.data.data[key];
    }
    dispatch({
        type: FETCH_SETTING_SIDEBAR_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(data && { [`${key}s`]: data }),
            ...(response.data && response.data.data.units && { units: response.data.data.units }),
        },
    });
};

// fetch sidebar data
export const fetchingSidebarData = (data) => dispatch => {
    dispatch(fetchingSidebarDataLoading());

    service.make({
        service: `insights/jrp/vga/settings/fetch/mandatory`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchSidebarDataStatus({
            data: response.data
        }, data.key))
    }).catch(error => {
        dispatch(fetchSidebarDataStatus({
            error: error.message
        }, data.key))
    })
};

// Fetching unit lists for set order tab
export const fetchingUnitsSetOrder = (data) => dispatch => {
    dispatch(fetchingSidebarUnitsLoading());

    service.make({
        service: `insights/jrp/vga/settings/fetch/order`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchSidebarUnitsStatus({
            data: response.data
        }, data.key))
    }).catch(error => {
        dispatch(fetchSidebarUnitsStatus({
            error: error.message
        }, data.key))
    })
};
/** units table data save **/
// save unit tables loading
const savingUnitsTableDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_SETTING_UNITS_DATA_LOADING,
        payload: { isLoading },
    });
};

// save unit tables order data loading
const savingUnitsTableOrderDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_SETTING_UNITS_ORDER_DATA_LOADING,
        payload: { isLoading },
    });
};

// save unit table order data handler
const saveUnitsTableOrderDataStatus = (response, newUnits = null) => dispatch => {
    dispatch({
        type: SAVE_SETTING_UNITS_ORDER_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            data: response.data,
            ...(newUnits && { newUnits }),
        },
    });
};

// save unit table order data
export const saveUnitsTableOrderData = (data, newUnits) => dispatch => {
    dispatch(savingUnitsTableOrderDataLoading());

    service.make({
        service: `insights/jrp/vga/settings/save/order`,
        method: "POST",
        data
    }).then(response => {
        dispatch(saveUnitsTableOrderDataStatus({
            data: response.data
        }, newUnits))
        dispatch(setEditMode(false));
        onSuccess("Units Updated Successfully");
    }).catch(error => {
        dispatch(saveUnitsTableOrderDataStatus({
            error: error.message
        }))
        onError("Something Went Wrong")
    })
};

// save unit table data handler
const saveUnitsTableDataStatus = (response, newUnits = null) => dispatch => {
    dispatch({
        type: SAVE_SETTING_UNITS_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            data: response.data,
            ...(newUnits && { newUnits }),
        },
    });
};

// save unit table data
export const saveUnitsTableData = (data, newUnits) => dispatch => {
    dispatch(savingUnitsTableDataLoading());

    service.make({
        service: `insights/jrp/vga/settings/save/mandatory`,
        method: "POST",
        data
    }).then(response => {
        dispatch(saveUnitsTableDataStatus({
            data: response.data
        }, newUnits))
        dispatch(setEditMode(false));
        onSuccess("Units Updated Successfully");
    }).catch(error => {
        dispatch(saveUnitsTableDataStatus({
            error: error.message
        }))
        onError("Something Went Wrong")
    })
};

