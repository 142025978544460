import React from 'react';
import {Avatar} from "antd";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const MetaCellRenderer = ({title, avatar, subtitle, suppressAvatar, url}) => {

    const getStyles = () => {
        if(suppressAvatar) {
            return {
                paddingLeft: 0
            }
        }

        return {};
    };

    return (
        <div className="table-cell-meta" style={getStyles()}>
            {!suppressAvatar ? <Avatar src={avatar} alt={title}/> : null}
            <div title={title} className="meta-title">
                {url ?
                    <Link style={{color:"unset"}} to={url}>{title}</Link>
                    :
                    subtitle ? 
                        title
                        : 
                        <small className="meta-subtitle">{title}</small> 
                }
            </div>
            {subtitle ? <small className="meta-subtitle">{subtitle}</small> : null}
        </div>
    );
};

MetaCellRenderer.defaultValues = {
    suppressAvatar: false
};

MetaCellRenderer.propTypes = {
    title: PropTypes.string,
    avatar: PropTypes.string,
    subtitle: PropTypes.string,
    suppressAvatar: PropTypes.bool,
    url: PropTypes.string,
};

export default MetaCellRenderer;