import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ children, isLoading }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin className="vtt-loader" indicator={antIcon} spinning={isLoading}>
      {children}
    </Spin>
  );
};
Loader.defaultProps = {
  isLoading: false,
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default Loader;
