import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restDefaultGlobalSettings, saveGlobalSettings } from '../../actions/dashboardManagement/settings.action';
import { makeDashboardSettingsResponseData } from '../../selectors/dashboardManagement/settings.selector';

const config = {
  save: {
    title: "Apply Changes",
    type: "save",
    subtitle: "Please select the scope the changes will be applied to",
    optionOne: "Apply to all courses",
    optionTwo: "Apply to all but do not overide course configurations",
    messageLineOne: "This change will overide all the course configurations",
    messageLineTwo: "This change will overide all the dashboard configurations except course configurations"
  },
  reset: {
    title: "Reset to Default",
    type: "reset",
    subtitle: "Please select the scope of settings you wish to reset",
    optionOne: "Reset for all courses",
    optionTwo: "Reset courses without course level configurations",
    messageLineOne: "This change will reset settings in all courses and dashboards",
    messageLineTwo: "This change will reset all the dashboard configurations except course configurations"
  }
}

const useSettingsHelpers = () => {
  const [requestPayload, setRequestPayload] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [tmpPayload, setTmpPayload] = useState(null);


  const settings = useSelector(makeDashboardSettingsResponseData);
  const dispatch = useDispatch();

  // handles save and reset to default scenarios on popup confirm state.
  const onConfirmHandler = (config) => {
    let payload = { overide: config.overide };
    if (modalConfig.type === "reset") {
      payload = { ...payload, ...requestPayload };
    }
    if (modalConfig.type === "save") {
      payload = { ...payload, tmpPayload }
    }
    onConfirm(payload, modalConfig.type);
  }

  const onSubmit = (values, overide) => {
    const section = Object.keys(values)[0];
    let saveObj = {
      ...settings,
    };

    if (section) {
      const subSections = Object.keys(values[section]);

      // overriding current settings with applied changes
      subSections.forEach((subSection) => {
        saveObj[section][subSection] = values[section][subSection];
      });
    }

    dispatch(saveGlobalSettings({ ...saveObj, overide }));
  };

  // handles the pre submit -> before the confirmation popup confirm state
  const onPreSubmit = (payload) => {
    setModalConfig(config.save);
    setTmpPayload(payload);
    setIsModalVisible(true);
  }

  // Triggers after confirming the confirmation popup
  const onConfirm = (payload, type) => {
    type === "reset" && dispatch(restDefaultGlobalSettings(payload));
    type === "save" && onSubmit(payload.tmpPayload, payload.overide);
  }

  const onResetDefault = (tmpPayload) => {
    let saveObj = { ...tmpPayload };
    let settingType = "";
    switch (tmpPayload.whichSettingToGetDefault) {
      case "markingScheme":
        settingType = "Marking Scheme";
        break;
      case "colorScheme":
        settingType = "Color Scheme";
        break;
      case "conditionalColor":
        settingType = "Conditional Colors";
        break;
      default:
        return;
    }
    setModalConfig(config.reset)
    setRequestPayload(saveObj);
    setIsModalVisible(true);
  };

  return { onSubmit, requestPayload, isModalVisible, setIsModalVisible, onResetDefault, modalConfig, onPreSubmit, onConfirmHandler }

}

export default useSettingsHelpers;