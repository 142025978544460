// return user token validate response
export const makeUserTokenValidationResponse = state => state && state.signup && state.signup.validateUserToken;

// return user token validate response loading status
export const makeUserTokenValidationLoading = state => {
    const result = makeUserTokenValidationResponse(state)
    return result && result.isLoading;
};

// return user token validate response data
export const makeUserTokenValidationResponseData = state => {
    const result = makeUserTokenValidationResponse(state)
    return result && result.response && result.response.data
};

// return user token validate response error
export const makeUserTokenValidationError = state => {
    const result = makeUserTokenValidationResponse(state)
    return result.error
};