import {
  CLEAR_TERMS_DATA,
  CLEAR_COURSE,
  FETCH_COURSE,
  CLEAR_COURSES,
  FETCH_COURSE_LOADING,
  FETCH_COURSE_REPORT,
  FETCH_COURSE_REPORT_LOADING,
  FETCH_COURSES,
  FETCH_COURSES_LOADING,
  FETCH_IS_ADMIN_LEVEL_USER,
  FETCH_IS_ADMIN_LEVEL_USER_LOADING,
  FETCH_TERMS,
  FETCH_TERMS_LOADING,
  SET_TERM,
  SET_SELECTED_ACCOUNT,
  CLEAR_COURSE_REPORT,
  SET_COURSE_FILTER,
  SET_SWITCH_FORM_VALUES,
  FETCH_COURSE_PROGRESS,
  FETCH_STUDENT_CLUSTER_DATA,
  FETCH_STUDENT_CLUSTER_DATA_LOADING,
  FETCH_CHAT_HISTORY_DATA,
  FETCH_CHAT_HISTORY_DATA_LOADING,
  SEND_CHAT_MESSAGE_LOADING,
  UPDATE_SEND_CHAT,
  UPDATE_FETCH_COURSE_TIME_FILTERS,
  RESET_ADMIN_LEVEL_DATA,
  UPDATE_APPLICATION_LEVEL_ADMIN_USER,
  FETCH_CHAT_HISTORY_ERROR_HANDEL,
  FETCH_MODULE_ITEM_DATA,
  FETCH_MODULE_ITEM_DATA_LOADING,
  RESET_MODULE_PROGRESS_DATA,
  RESET_STUDENT_CLASSIFICATION_DATA,
  RESET_CHAT_COUNT_DOWN_TIMER,
  UPDATE_FILTER_RADIO_VALUE,
  UPDATE_SECTION_FILTER_VALUES,
  RESET_SECTION_FILTER_VALUES,
  UPDATE_CLUSTER_MESSAGE_VISIBILITY,
  FETCH_CLUSTER_MASSAGES,
  RESET_CLUSTER_MASSAGES,
  RESET_FETCH_COURSE_TIME_FILTERS,
  FETCH_NEW_COURSE_DATA,
} from "../../constant/actionTypes";
import { setBreadcrumb } from "./layout.action";
import request from "../../utils/request";
import { getAccessToken } from "../../utils/general";
import { get as _get, isArray as _isArray } from "lodash";
import { onError as ErrorNotification } from "../../utils/notificationHandler";
import session from "../../utils/session";

const FETCH_MODULE_DATA_PAGE_1 = "TE-01";

// clear last fetched data before new fetch
export const clearTermsData = () => dispatch => {
  dispatch({
    type: CLEAR_TERMS_DATA,
  });
};

// set term ID
export const setTerm = (term) => dispatch => {
  dispatch({
    type: SET_TERM,
    payload: term
  });
};

// set course status filter values 
export const setCourseStatus = (statuses) => dispatch => {
  dispatch({
    type: SET_COURSE_FILTER,
    payload: statuses
  })
}

// set Selected account ID
export const setSelectedAccount = (account) => dispatch => {
  dispatch({
    type: SET_SELECTED_ACCOUNT,
    payload: account
  });
};

// handle fetch loading state for terms fetch request
export const fetchingTerms = (isLoading = true) => dispatch => {
  clearTermsData()
  dispatch({
    type: FETCH_TERMS_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the terms fetch request
export const setTermsFetchStatus = data => dispatch => {
  const { error, response } = data;
  dispatch({
    type: FETCH_TERMS,
    payload: {
      isLoading: false,
      error,
      response,
    }
  });
};

export const setVerificationStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: FETCH_IS_ADMIN_LEVEL_USER,
    payload: {
      isLoading: false,
      error,
      response,
    }
  });
};


export const fetchingVerification = () => dispatch => {
  dispatch({
    type: FETCH_IS_ADMIN_LEVEL_USER_LOADING,
    payload: {
      isLoading: true
    }
  });
}

// reset admin level Data
export const resetAdminLevelData = () => dispatch => {
  dispatch({
    type: RESET_ADMIN_LEVEL_DATA,
  });
};

export const verifyAdminStatus = (data) => dispatch => {
  dispatch(fetchingVerification());
  const feedReducer = response => {
    dispatch(setVerificationStatus(response))
  }

  const requestOptions = {
    service: `insights/adminLevelUsers/fetchAccounts/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
}



// handle terms fetch request
export const fetchTerms = (data) => dispatch => {
  dispatch(fetchingTerms());

  const feedReducer = (response) => {
    dispatch(setTermsFetchStatus(response));
  }

  const requestOptions = {
    service: `insights/course_profile/filter_terms/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

export const clearCoursesData = () => dispatch => {
  dispatch({
    type: CLEAR_COURSES,
  });
};

// clear last fetched course data before new fetch
export const clearCourseData = () => dispatch => {
  dispatch({
    type: CLEAR_COURSE,
  });
};

export const clearCourseReport = () => dispatch => {
  dispatch({
    type: CLEAR_COURSE_REPORT,
  })
}

export const fetchNewCourseData = fetch => dispatch => {
  dispatch({
    type: FETCH_NEW_COURSE_DATA,
    payload: fetch,
  });
};

// Fetch selected course
// handle fetch loading state for course fetch request
export const fetchingCourse = (isLoading = true) => dispatch => {
  dispatch(resetClusterSection());
  dispatch({
    type: FETCH_COURSE_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the course fetch request
export const setCourseFetchStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: FETCH_COURSE,
    payload: {
      isLoading,
      error,
      response,
    }
  });
};

// handle course fetch request
export const fetchCourse = (payload) => dispatch => {
  dispatch(fetchingCourse());

  const feedReducer = (response) => {
    dispatch(setCourseFetchStatus(response));
  }

  const requestOptions = {
    service: `insights/course_profile/course_description/${getAccessToken()}`,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};


// Fetch all
// handle fetch loading state for courses fetch request
export const fetchingCourses = (isLoading = true) => dispatch => {
  //dispatch(clearCoursesData());
  dispatch({
    type: FETCH_COURSES_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the courses fetch request
export const setCoursesFetchStatus = data => dispatch => {
  const { error, response } = data;

  const responseData = _get(response, 'data.data', []);

  if (!responseData.length) {
    dispatch(clearCourseReport());
    dispatch(clearCourseData());
    dispatch(setBreadcrumb([
      {
        key: 'home',
        section: "Home"
      }
    ]));
  }
  
  const getResponseData = () => {
    if (_isArray(responseData)) {
      return response;
    }

    return {
      data: {
        data: [],
      },
    };
  }

  dispatch({
    type: FETCH_COURSES,
    payload: {
      isLoading: false,
      error,
      response: getResponseData()
    }
  });
};

// handle courses fetch request
export const fetchCourses = (payload) => dispatch => {
  dispatch(fetchingCourses());

  const feedReducer = (response) => {
    dispatch(setCoursesFetchStatus(response));
    dispatch(fetchNewCourseData(false));
  }

  const service = payload.existingRequest ? `insights/course_profile/search_courses/${getAccessToken()}` : `insights/adminLevelUsers/fetchCourses/${getAccessToken()}`;

  const requestOptions = {
    service,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};


// Course report
// handle fetch loading state for courses fetch request
export const fetchingCourseReport = (isLoading = true) => dispatch => {
  dispatch({
    type: FETCH_COURSE_REPORT_LOADING,
    payload: {
      isLoading
    }
  });
};

// handle response of the courses fetch request
export const setCoursesReportFetchStatus = data => dispatch => {
  const { error, response } = data;
  dispatch({
    type: FETCH_COURSE_REPORT,
    payload: {
      isLoading: false,
      error,
      response,
    }
  });
};

// handle courses fetch request
export const fetchCoursesReport = (payload) => dispatch => {
  dispatch(fetchingCourseReport());

  const feedReducer = (response) => {
    dispatch(setCoursesReportFetchStatus(response));
  }

  const requestOptions = {
    service: `insights/course_profile/main_report_details/${getAccessToken()} `,
    method: "POST",
    data: { ...payload.requestParams },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

export const setSwitchFormValues = payload => dispatch => {
  dispatch({
    type: SET_SWITCH_FORM_VALUES,
    payload,
  });
};

// handle response of the courses fetch request
export const fetchCourseProgressDataStatus = data => dispatch => {
  const {
    response: {
      data: {
        result: {
          moduleCompletionView,
          overallModuleCompletion,
          moduleData
        }
      }
    },
    error,
    isLoading,
    ...rest
  } = data;


  dispatch({
    type: FETCH_COURSE_PROGRESS,
    payload: {
      data: { moduleCompletionView, overallModuleCompletion, moduleData, ...rest }
    }
  });
};

// fetch course Progress Data
export const fetchSelectedCourseProgress = (payload, signal) => dispatch => {
  const feedReducer = (response) => {
    const data = { ...response, courseId: payload.course_id, studentId: payload.student_id };
    dispatch(fetchCourseProgressDataStatus(data));
  }

  const requestOptions = {
    service: `insights/course_profile/module_progress_data/${getAccessToken()}`,
    method: "POST",
    signal,
    data: {
      ...payload,
      pageNo: "TE-01",
    },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions)
};

// fetch course Progress Data
export const resetFetchedCourseProgress = () => dispatch => {
  dispatch({
    type: FETCH_COURSE_PROGRESS,
    payload: {
      isLoading: false,
      error: {},
      response: {},
    }
  });
};

// update application admin state
export const updateApplicationLevelAdmin = (data) => dispatch => {
  dispatch({
    type: UPDATE_APPLICATION_LEVEL_ADMIN_USER,
    payload: data
  });
};

// fetch cluster data
export const fetchClusterDataStatus = ({
  error,
  response,
  fetchChatHistoryData = false,
  ...rest
}, fetchChat) => dispatch => {

  if (error?.info) {
    ErrorNotification(`${error.info}`);

    dispatch({
      type: FETCH_STUDENT_CLUSTER_DATA,
      payload: {
        error,
        ...rest,
        response: { ...response },
      }
    });
    return;
  }

  if (
    response &&
    fetchChatHistoryData &&
    fetchChat
  ) {
    dispatch(fetchClusterMessages(true));
  }

  dispatch({
    type: FETCH_STUDENT_CLUSTER_DATA,
    payload: {
      error,
      ...rest,
      response: { ...response },
    }
  });
};

export const fetchingClusterData = (isLoading = true) => dispatch => {
  dispatch({
    type: FETCH_STUDENT_CLUSTER_DATA_LOADING,
    payload: {
      isLoading: isLoading,
    }
  });
};

export const resetClusterData = () => dispatch => {
  dispatch({
    type: RESET_STUDENT_CLASSIFICATION_DATA,
  });
}

export const updateClusterSection = ({ selectedSectionArray = [] }) => dispatch => {
  dispatch({
    type: UPDATE_SECTION_FILTER_VALUES,
    payload: {
      selectedSectionArray,
    }
  });
};

export const resetClusterSection = () => dispatch => {
  dispatch({
    type: RESET_SECTION_FILTER_VALUES,
  });
};

export const fetchClusterData = (data, fetchChat = true) => dispatch => {
  dispatch(fetchingClusterData());

  const feedReducer = (response) => {
    dispatch(fetchClusterDataStatus({ ...response, fetchChatHistoryData: true }, fetchChat));
  }
  const requestOptions = {
    service: `insights/course_profile/cluster_data/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: {
      ...data,
      "course_id": session.get([
        session.keys.innovativeMeta,
        "selectedCourseId"
      ]),
    },
    onSuccess: feedReducer,
    onError: feedReducer,
  };
  request.make(requestOptions)
};

export const requestClusterDataConfirmation = () => dispatch => {
  dispatch(fetchingClusterData());
  const feedReducer = response => {
    response.error && dispatch(fetchClusterDataStatus(response));
  };

  const requestOptions = {
    service: `insights/course_profile/cluster_data_confirmation/${getAccessToken()}`,
    method: "POST",
    data: {
      "course_id": session.get([
        session.keys.innovativeMeta,
        "selectedCourseId"
      ])
    },
    authorized: true,
    onSuccess: feedReducer,
    onError: feedReducer,
  };
  request.make(requestOptions);
};


export const resetChatData = () => dispatch => {
  dispatch({
    type: FETCH_CHAT_HISTORY_DATA,
    payload: {
      isLoading: false,
      error: false,
      response: {
        data: []
      }
    }
  });
}

export const addNewMessageToChatHistory = newMessageObj => dispatch => {
  const courseId = session.get([
    session.keys.innovativeMeta,
    "selectedCourseId"
  ]);

  dispatch({
    type: UPDATE_SEND_CHAT,
    payload: {
      data: newMessageObj,
      courseId,
    }
  });
};

export const resetChatHistory = () => dispatch => {
  dispatch({
    type: RESET_CLUSTER_MASSAGES,
  });
};

export const fetchChatHistoryStatus = ({
  isLoading,
  error,
  response,
  message_id,
  courseId,
}) => dispatch => {
  if (error?.info) {
    ErrorNotification(`${error.info}`);
    dispatch({
      type: FETCH_CHAT_HISTORY_ERROR_HANDEL,
      payload: {
        isLoading,
        error: error,
        response: {
          data: {
            courseId,
            retryAfter: response.error.limitReset,
            message_id,
            historyMessages: [],
          }
        },
      }
    });
    return;
  }

  const validationErrorText = response?.data?.message;
  if (validationErrorText) {
    dispatch({
      type: FETCH_CHAT_HISTORY_ERROR_HANDEL,
      payload: {
        isLoading,
        error: {
          info: "Validation Error",
          status: 403,
        },
        response: {
          data: {
            courseId,
            historyMessages: [
              {
                content: validationErrorText,
                role: "assistant"
              }
            ]
          }
        },
      }
    });

    return;
  }

  dispatch({
    type: FETCH_CHAT_HISTORY_DATA,
    payload: {
      isLoading,
      error,
      response,
    }
  });
};

export const fetchingChatHistory = (isLoading = true) => dispatch => {
  dispatch({
    type: FETCH_CHAT_HISTORY_DATA_LOADING,
    payload: {
      isLoading,
      error: {},
      response: {},
    }
  });
};

export const fetchChatHistory = () => dispatch => {
  dispatch(fetchingChatHistory());
  const courseId = session.get([
    session.keys.innovativeMeta,
    "selectedCourseId"
  ]);

  const feedReducer = response => {
    dispatch(fetchChatHistoryStatus({ ...response, courseId }));
  };

  const requestOptions = {
    service: `insights/course_profile/question_analyzer/${getAccessToken()}`,
    method: "POST",
    data: { course_id: courseId },
    authorized: true,
    onSuccess: feedReducer,
    onError: feedReducer,
  };
  request.make(requestOptions)
};

export const resetChatCountDownTimer = () => dispatch => {
  const courseId = session.get([
    session.keys.innovativeMeta,
    "selectedCourseId"
  ]);

  dispatch({
    type: RESET_CHAT_COUNT_DOWN_TIMER,
    payload: { courseId }
  });
};

export const sendingChatMessage = ({
  isLoading,
  isMassageLoading = false,
  message_id,
  courseId,
}) => dispatch => {
  dispatch({
    type: SEND_CHAT_MESSAGE_LOADING,
    payload: {
      isLoading: isLoading,
      isMassageLoading,
      message_id,
      courseId,
    }
  });
};

export const sendChatMessage = data => dispatch => {
  const { message_id, isMassageLoading } = data;
  const courseId = session.get([
    session.keys.innovativeMeta,
    "selectedCourseId"
  ]);

  setTimeout(() => {
    dispatch(sendingChatMessage({
      isLoading: true,
      message_id,
      isMassageLoading,
      courseId,
    }));

    const feedReducer = response => {
      dispatch(sendingChatMessage({ isLoading: false }));
      dispatch(fetchChatHistoryStatus({
        ...response,
        message_id,
        courseId,
      }));
    }

    const requestOptions = {
      service: `insights/course_profile/question_analyzer/${getAccessToken()}`,
      method: "POST",
      authorized: true,
      data: {
        ...data,
        course_id: courseId,
      },
      onSuccess: feedReducer,
      onError: feedReducer,
    };
    request.make(requestOptions)
  }, 1000);

};

// update fetch course date filter data
export const setDateFilterForFetchCourse = data => dispatch => {
  dispatch({
    type: UPDATE_FETCH_COURSE_TIME_FILTERS,
    payload: {
      ...data,
    }
  });
};

// reset course date filter data
export const resetDateFilterForFetchCourse = data => dispatch => {
  dispatch({
    type: RESET_FETCH_COURSE_TIME_FILTERS,
  });
};

// handle response of the module data item fetch request
export const fetchModuleItemStatus = data => dispatch => {
  const {
    response: {
      data: {
        result: {
          moduleData: {
            modeleDataArr
          }
        }
      }
    },
    moduleDataObjKey,
    ...rest
  } = data;

  dispatch({
    type: FETCH_MODULE_ITEM_DATA,
    payload: {
      [moduleDataObjKey]: {
        modeleDataArr,
        ...rest
      }
    }
  });
};

export const fetchingModuleItemData = ({ moduleDataObjKey }) => dispatch => {
  dispatch({
    type: FETCH_MODULE_ITEM_DATA_LOADING,
    payload: {
      [moduleDataObjKey]: {
        isLoading: true
      }
    }
  });
};

// fetch course Progress Data
export const fetchModuleItemData = (payload) => dispatch => {
  const { moduleDataObjKey, ...restPayload } = payload;
  dispatch(fetchingModuleItemData({ moduleDataObjKey }));

  const feedReducer = (response) => {
    dispatch(fetchModuleItemStatus({ ...response, moduleDataObjKey }));
  };

  const requestOptions = {
    service: `insights/course_profile/module_items_data/${getAccessToken()}`,
    method: "POST",
    data: {
      ...restPayload,
      pageNo: FETCH_MODULE_DATA_PAGE_1,
    },
    authorized: true,
    onSuccess: feedReducer,
  };

  request.make(requestOptions);
};

export const resetModuleProgressData = () => dispatch => {
  dispatch({
    type: RESET_MODULE_PROGRESS_DATA,
  });
};

// update Selected Filter radio value
export const updateFilterRadioValue = data => dispatch => {
  dispatch({
    type: UPDATE_FILTER_RADIO_VALUE,
    payload: {
      value: data,
    }
  });
};

// updates cluster Message visibility
export const updateClusterMessageVisibility = data => dispatch => {
  dispatch({
    type: UPDATE_CLUSTER_MESSAGE_VISIBILITY,
    payload: {
      isVisible: data,
    }
  });
};

// updates to fetch chat messages
export const fetchClusterMessages = fetch => dispatch => {
  dispatch({
    type: FETCH_CLUSTER_MASSAGES,
    payload: {
      fetchMessages: fetch,
    }
  });
};
