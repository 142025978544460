import React from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import MetaCellRenderer from '../../../../common/table/MetaCellRenderer';
import ModuleProgressDropdownCard from '..';
import Loader from '../../../../common/layout/Loader';
import PropTypes from "prop-types";

const ADMIN_PATH = "/insights/admin/courses";

const ModuleProgressCard = ({ cardData, data, courseId, selectedTerm, index }) => {
  const { student_sis_user_id, student_id, student_name, student_avatar_url } = data;
  const isDatLoaded = cardData ? true : false;
  const cardDataAvailable = cardData?.moduleData?.length > 0 ? true : false;

  const parsedUrl = new URL(window.location.href);
  const isAdminPath = parsedUrl.pathname === ADMIN_PATH;

  const renderCardData = () => {
    if (cardDataAvailable) {
      return (
        <Space>
          <Space>
            <div>Module Completion:</div>
            <div className="mainNumber">
              {isDatLoaded && cardData?.moduleCompletionView ?
                cardData.moduleCompletionView : "N/A"
              }</div>
          </Space>

          <div className="subNumber">
            {isDatLoaded && cardData?.overallModuleCompletion ?
              cardData.overallModuleCompletion : "N/A"
            } </div>
        </Space>
      );
    }

    return (
      <div className="module-no-data">
        <InfoCircleOutlined />
        <div>
          No module progress data for this course.  
        </div>
      </div>
    );
  };

  return (
    <div className={index % 2 === 0 ? 'gray-module-progress-card' : 'white-module-progress-card'}>
      <Col className='module-card'>
        <Row
          justify={"space-between"}
          className="moduleSingleItem"
        >
          <MetaCellRenderer
            title={student_name}
            subtitle={student_sis_user_id}
            avatar={student_avatar_url}
          />

          <Space>
              <Loader isLoading={!isDatLoaded} />
              {renderCardData()}
            <div>
              <Link className="ant-btn ant-btn-default" to={
                {
                  pathname: isAdminPath ? `/insights/admin/teacher/student-profile/${student_id}/${courseId}/${selectedTerm}`:
                  `/insights/teacher/student-profile/${student_id}/${courseId}/${selectedTerm}`,
                  state: { viewModuleProgressTab: true }
                }
              }>
                View
              </Link>
            </div>
          </Space>
        </Row>
            <Loader isLoading={!isDatLoaded} />
            {cardDataAvailable &&
              <Row gutter={[10, 10]} className="moduleCardList">
                {cardData.moduleData.map((module, index) => (
                  <Col xs={8} sm={8} md={8}>
                    <ModuleProgressDropdownCard
                      key={`module-card-${index}`}
                      module={module}
                      studentId={student_id}
                      courseId={courseId}
                    />            
                  </Col>
                ))}
              </Row>
            }
      </Col>
    </div>
  )
};

ModuleProgressCard.propTypes = {
  cardData: PropTypes.object,
  data: PropTypes.object,
  courseId: PropTypes.number,
  selectedTerm: PropTypes.number,
  index: PropTypes.number,
};

export default ModuleProgressCard;
