import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    fetchDealershipSidebarData,
    fetchingMainReportData,
    setFilteredSidebarData,
    clearDealershipsData
} from "../../../../../actions/vwDealership/dealership.action";
import {
    makeVwDealershipSidebarFilteredDataResponse,
    makeVwDealershipSidebarSelectDataResponse,
    makeVwDealershipSidebarListsDataDealershipsResponse
} from "../../../../../selectors/vwDealership/dealership.selector";

import {orderAlphabeticallyArrayData} from "../../../../../utils/general";

import DashboardSidebar from "./Sidebar";

const SidebarContainer = () => {
    // states
    const [nationalCheckedList, setNationalCheckedList] = useState([]);
    const [stateCheckedList, setStateCheckedList] = useState([]);
    const [dealershipCheckedList, setDealershipCheckedList] = useState([]);
    const [brandCheckedList, setBrandCheckedList] = useState([]);

    // selectors
    const sidebarData = useSelector(makeVwDealershipSidebarFilteredDataResponse);
    const sidebarDealershipsData = useSelector(makeVwDealershipSidebarListsDataDealershipsResponse);
    const sidebarDataSelected = useSelector(makeVwDealershipSidebarSelectDataResponse);

    const dispatch = useDispatch();

    const nationalCheckChange = (items) => {
        setNationalCheckedList(items)

        let dealerships = [];
        let states =[];

        // filter states and records matches to selected nationals
        const filteredData = sidebarDealershipsData.filter(dealership => items.some(val => dealership.nationalState.map(item => item.countryId)
            .includes(val))).map(dealership => {
            states = resolveDataObject(states, dealership.nationalState, "stateId", "stateName", true, "countryId", items);
            return dealership;
        })

        // filter dealerships with brand filters
        filteredData.filter(dealership => brandCheckedList.some(val => dealership.brand.map(item => item.id)
            .includes(val))).map(dealership => {
            dealerships.push({id: dealership.id, name: dealership.name});
        })

        dispatch(setFilteredSidebarData({dealership:orderAlphabeticallyArrayData(dealerships, "name"), state:states}))

        // set filters data as selected items
        setStateCheckedList(filterIds(states, "id"));
        setDealershipCheckedList(filterIds(dealerships, "id"));
    }

    const stateCheckChange = (items) => {
        setStateCheckedList(items)

        let dealerships = [];

        // filter records matches to selected states
        const filteredData = sidebarDealershipsData.filter(dealership => items.some(val => dealership.nationalState.map(item => item.stateId)
            .includes(val)))

        // filter dealerships with brand filters
        filteredData.filter(dealership => brandCheckedList.some(val => dealership.brand.map(item => item.id)
            .includes(val))).map(dealership => {
            dealerships.push({id: dealership.id, name: dealership.name});
        })

        dispatch(setFilteredSidebarData({dealership:orderAlphabeticallyArrayData(dealerships, "name")}));

        // set filters data as selected items
        setDealershipCheckedList(filterIds(dealerships, "id"));
    }

    const brandCheckChange = (items) => {
        setBrandCheckedList(items)

        let dealerships = [];

        // filter dealerships matches to selected brands and apply both state filter and national filter
        sidebarDealershipsData.filter(dealership => items.some(val => dealership.brand.map(item => item.id)
            .includes(val)) && nationalCheckedList.some(val => dealership.nationalState.map(item => item.countryId)
            .includes(val)) && stateCheckedList.some(val => dealership.nationalState.map(item => item.stateId)
            .includes(val))).map(dealership => {
            dealerships.push({id: dealership.id, name: dealership.name});
        })

        dispatch(setFilteredSidebarData({dealership:orderAlphabeticallyArrayData(dealerships, "name")}));

        // set filters data as selected items
        setDealershipCheckedList(filterIds(dealerships, "id"));
    }

    const config = {
        national: {checkedList:nationalCheckedList, setCheckedList:nationalCheckChange},
        state: {checkedList:stateCheckedList, setCheckedList:stateCheckChange},
        dealership: {checkedList:dealershipCheckedList, setCheckedList:setDealershipCheckedList},
        brand: {checkedList:brandCheckedList, setCheckedList:brandCheckChange}
    }

    const filterIds = (list, idParam) => {
        return list && list.map(item => typeof item === "string" ? item : item && item[idParam]);
    }

    const resolveDataObject = (dataset, values, keyProp="id", valueProp="name", parse=false, checkKey=false, checkVal=false) => {
        // this will evaluate the dataset and provide resolved data set for the filters
        let set = dataset;
        let keys = dataset.map(item => item.id)

        const parseData = (value) => {
            if(parse){
                set.push({id:value[keyProp], name:value[valueProp]})
            }else{
                set.push(value)
            }
        }

        values.map(value => {
            if(!keys.includes(value[keyProp])){
                if(checkKey && checkVal){
                    if (checkVal.includes(value[checkKey])){
                        parseData(value);
                    }
                }else{
                    parseData(value);
                }
            }
        })
        return set;
    }

    const prepareFilterData = () => {
        // this will prepare all the filter data sets and apply select all mode to all the filters at the dashboard load
        let dataset = {
            national: [],
            state: [],
            brand: [],
            dealership: [],
        }
        if(sidebarDealershipsData && sidebarDealershipsData.length){
            sidebarDealershipsData.map(dealership => {
                dataset.national = resolveDataObject(dataset.national, dealership.nationalState, "countryId", "countryName", true);
                dataset.state = resolveDataObject(dataset.state, dealership.nationalState, "stateId", "stateName", true);
                dataset.brand = resolveDataObject(dataset.brand, dealership.brand);
                dataset.dealership = [...dataset.dealership, {id: dealership.id, name: dealership.name}];
            })
        }
        dispatch(setFilteredSidebarData({...dataset, dealership:orderAlphabeticallyArrayData(dataset.dealership, "name")}, true));
        dispatch(fetchingMainReportData(dataset));
    }

    useEffect(() => {
        dispatch(fetchDealershipSidebarData());

        // reset dealerships on component unmount
        return () => {dispatch(clearDealershipsData())}
    }, [])

    useEffect(() => {
        if(sidebarDealershipsData && sidebarDealershipsData.length){
            // prepare dataset for filters from dealership data object
            prepareFilterData();
        }
    }, [sidebarDealershipsData])

    useEffect(() => {
        if(sidebarDataSelected){
            // assign selected items to filters
            setNationalCheckedList(filterIds(sidebarDataSelected.national, "id"));
            setStateCheckedList(filterIds(sidebarDataSelected.state, "id"));
            setDealershipCheckedList(filterIds(sidebarDataSelected.dealership, "id"));
            setBrandCheckedList(filterIds(sidebarDataSelected.brand, "id"));
        }
    }, [sidebarDataSelected])

    const filterSubmit = () => {
        // send all the filter data for main report data fetch
        const data = {
            national: nationalCheckedList ? nationalCheckedList : [],
            state: stateCheckedList ? stateCheckedList : [],
            dealership: dealershipCheckedList ? dealershipCheckedList : [],
            brand: brandCheckedList ? brandCheckedList : [],
        }
        const dealerships = sidebarDealershipsData.filter(deal => data.dealership.includes(deal.id)).map(({id, name}) => ({id, name}))
        dispatch(fetchingMainReportData({...data, dealership:dealerships}))
    }
    return (
        <DashboardSidebar
            filterConfig={config}
            onSubmit={filterSubmit}
            sidebarData={sidebarData}
        />
    )
}

export default SidebarContainer;