import React, { Fragment } from 'react';
import {useParams} from "react-router-dom";

const INFORMATION = [
    {
        code: 'no_api_key',
        message:'Add your API key before access the dashboards',
        title: 'No API key was found'
    },
    {
        code: 'denied',
        message:`You don't have access to this page. If you need to get access, please contact the Systems Administrator.`,
        title: 'Access Denied'
    },
    {
        code: 'trial_expired',
        message:'Please contact Octopus bi support (<a href = "mailto: support@octopusbi.com" target="_blank">support@octopusbi.com</a>) to renew your license.',
        title: 'Your trial has expired'
    },
    {
        code: 'license_expired',
        message:'Please contact Octopus bi support (<a href = "mailto: support@octopusbi.com" target="_blank">support@octopusbi.com</a>) to renew your license.',
        title: 'Your license has expired'
    },
    {
        code: 'consumer_not_found',
        message:'Application configuration error occured! Please contact your administrator to get this fixed.',
        title: 'Configuration error'
    },
    {
        code: 'consumer_verification_failed',
        message:'Application verification failed! Please contact your administrator to get this fixed.',
        title: 'Configuration error'
    },
    {
        code: 'cache_invalidated',
        message:'Application cache invalidated! Please refresh the browser.',
        title: 'Cache invalidated'
    },
]

const OnboardingInformationPage = () => {
    const {code} = useParams();
    const getInfoBody = () => {
        const codeData = INFORMATION.find(info => info.code === code)
        console.log(codeData);
        if (codeData){
            return (
                <Fragment>
                    <div className="maintenance-heading">
                        <h2 className="headline">{codeData.title}</h2>
                    </div>
                    <h4 className="sub-content" dangerouslySetInnerHTML={{__html: codeData.message}}></h4>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper maintenance-bg">
                    <div className="container">
                        <ul className="maintenance-icons">
                            <li><i className="fa fa-cog"></i></li>
                            <li><i className="fa fa-cog"></i></li>
                            <li><i className="fa fa-cog"></i></li>
                        </ul>
                        {getInfoBody()}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OnboardingInformationPage;