import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    makeInnovativeSettingsResponseData,
} from "../../../../selectors/innovative/setting.selector";
import SubmissionsCellRenderer from "./cellRenderers/SubmissionsCellRenderer";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import ChartDonutCellRenderer from "../../common/table/ChartDonutCellRenderer";
import {resolvePropertiesFromCohortData} from "../../../../utils/innovative/resolveSettings";
import CourseComparisonGridView from "./CourseComparisonGridView";
import {
    makeInnovativeStudentCohortDataResponse, makeInnovativeStudentGaugeDataResponse,
    makeInnovativeStudentMainReportCourseComparisonResponseData,
    makeInnovativeStudentMainReportLoading
} from "../../../../selectors/innovative/student.selector";
import StatCellRenderer from "../../common/table/statCell/StatCellRenderer";
import ChartWithLoader from "./cellRenderers/ChartWithLoader";
import SolidGaugeCellRenderer from "../../common/table/SolidGaugeCellRenderer";
import {Button} from "antd";
import {useParams} from "react-router";

const ADMIN_PATH = "/insights/admin";

const CourseComparisonGrid = ({setSelectedTablePagination}) => {
    const {studentId, courseId, termId} = useParams();

    const parsedUrl = new URL(window.location.href);
    const isAdminPath = parsedUrl.pathname.split("/teacher")[0] === ADMIN_PATH;

    // selectors
    const settings = useSelector(makeInnovativeSettingsResponseData);
    const courseComparison = useSelector(makeInnovativeStudentMainReportCourseComparisonResponseData);
    const courseComparisonLoading = useSelector(makeInnovativeStudentMainReportLoading);
    const cohortData = useSelector(makeInnovativeStudentCohortDataResponse);
    const gaugeData = useSelector(makeInnovativeStudentGaugeDataResponse);

    const radioOptions = [
        {value: 'all', label: 'All'},
        {value: 'archived', label: 'Archived'},
        {value: 'nonArchived', label: 'Non Archived'},
        {value: 'extraCurricular', label: 'Extra Curricular'},
    ];

    const getCourseChartData = (course, data, dataFormatter=null) => {
        // find the chart data of the particular course and format if needed
        // since the cohort and gauge chart data fetched separately, we need this
        // interim function to slice data
        const courseData = data.find(item => item && item.courseId === parseInt(course))
        if(courseData){
             return {data:dataFormatter? {courseId, data: dataFormatter(courseData.data)} : courseData, isLoading:false}
        }else{
            return {data: null, isLoading: true}
        }
    }

    const getColumnDefinition = () => {
        return [
            {
                key: "course_name",
                title: 'Course',
                dataIndex: 'course_name',
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["course_id"]}
                    avatar={row["course_image"]}
                />,
            }, {
                key: "student_grade",
                title: "Student Grade",
                dataIndex: "student_grade",
                render: (value, row) => <StatCellRenderer
                    label={value === "N/A" ? "": "Grade"}
                    value={value}
                    styles={{color: row["student_grade_color"]}}
                />
            }, {
                key: "grade_cohort",
                title: "Cohort",
                dataIndex: "grade_cohort",
                width: 220,
                className: "cohortChart",
                render: (data, row) => <ChartWithLoader
                    resolver={resolvePropertiesFromCohortData}
                    component={ChartDonutCellRenderer}
                    {...getCourseChartData(row.course_id,cohortData)}
                    customOptions={{legend:{itemWidth: 100}, chart:{height: 120, marginTop:20}}}
                />
            }, {
                key: "engagement",
                title: 'Engagement',
                dataIndex: 'engagement',
                width: "200",
                className: "enagagementChart",
                render: (data, row) => <ChartWithLoader
                    component={SolidGaugeCellRenderer}
                    {...getCourseChartData(row.course_id,gaugeData, data => {
                        return {score:data[0], cohort:data[1], color:data[2]}
                    } )}
                />
            }, {
                key: "student_submission",
                title: 'Submissions',
                dataIndex: 'student_submission',
                render: (...params) => <SubmissionsCellRenderer
                    params={params}
                    settings={settings.colorScheme.submission}
                />
            }, {
                key: "actions",
                title: "",
                fixed: 'right',
                render: (value, row) => <Button htmlType="button">
                    <Link to={
                        isAdminPath ? `/insights/admin/teacher/student-assignments/${studentId}/${row["course_id"]}/${termId}` :
                            `/insights/teacher/student-assignments/${studentId}/${row["course_id"]}/${termId}`
                    }>View</Link>
                </Button>
            },
        ];
    };

    return <CourseComparisonGridView
        radioOptions={radioOptions}
        isLoading={courseComparisonLoading}
        dataSource={courseComparison}
        columns={getColumnDefinition()}
        setSelectedTablePagination={setSelectedTablePagination}
    />
};

export default CourseComparisonGrid;