import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    makeVwDealershipSidebarDataLoading,
    makeVwDealershipSidebarUnitsResponseData,
    makeVwDealershipSidebarIsLoadingResponse,
    makeVwDealershipSidebarOrderDataLoading,
    makeVwDealershipSelectedProgram,
    makeVwDealershipOrderProgramUnits,
    makeVwDealershipOrderIsLoadingResponse,
    makeVwDealershipEditModeDataResponse
} from "../../../selectors/vwDealership/setting.selector";
import { getExternalUserLoading } from "../../../selectors/authentication.selector";

import DealershipSettingsView from "./DealershipSettingsView";

const DealershipSettingsContainer = () => {
    const [warningVisible, setWarningVisible] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([])
    const [activeKey, setActiveKey] = useState("1");

    // Selectors
    const sidebarDataLoading = useSelector(makeVwDealershipSidebarDataLoading);
    const units = useSelector(makeVwDealershipSidebarUnitsResponseData);
    const unitsSaveLoading = useSelector(makeVwDealershipSidebarIsLoadingResponse);
    const signOutLoading = useSelector(getExternalUserLoading);
    const sidebarOrderLoading = useSelector(makeVwDealershipSidebarOrderDataLoading);
    const selectedProgram = useSelector(makeVwDealershipSelectedProgram);
    const orderSaveLoading = useSelector(makeVwDealershipOrderIsLoadingResponse);
    const editMode = useSelector(makeVwDealershipEditModeDataResponse)
    const unitlist = useSelector(makeVwDealershipOrderProgramUnits(selectedProgram));

    const handleTabChange = (e) => {
        if (editMode) {
            setWarningVisible(true);
            return;
        }
        setActiveKey(e);
    }

    return (
        <DealershipSettingsView
            isLoading={sidebarDataLoading || unitsSaveLoading || signOutLoading || sidebarOrderLoading || orderSaveLoading}
            units={units}
            unitOrderList={unitlist}
            warningConfig={{ visible: warningVisible, setVisible: setWarningVisible }}
            breadcrumbConfig={{ breadcrumb: breadcrumb, setBreadcrumb: setBreadcrumb }}
            handleTabChange={handleTabChange}
            activeKey={activeKey}
        />
    )
}

export default DealershipSettingsContainer;