import {
    FETCH_DASHBOARD_LOADING,
    FETCH_DASHBOARD,
    FETCH_USER_ROLES_LOADING,
    FETCH_USER_ROLES,
    SAVE_SHARE_ROLES_LOADING,
    SAVE_SHARE_ROLES,
    SELECT_DASHBOARD,
    SAVE_DASHBOARD_LOADING,
    SAVE_DASHBOARD,
    FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING,
    FETCH_CREDENTIAL_MANAGEMENT_DATA,
    UPDATE_DEVELOPER_KEY_DATA,
    UPDATE_DEVELOPER_KEY_DATA_LOADING,
    ADD_ADMIN_TOKEN_DATA,
    ADD_ADMIN_TOKEN_LOADING,
    DELETE_ADMIN_TOKEN_DATA,
    DELETE_ADMIN_TOKEN_LOADING,
    GET_INFO,
    FETCHING_GET_INFO,
    DELETE_DEVELOPER_KEY_DATA,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";
import { onSuccess, onError } from "../../utils/notificationHandler";

// fetch credential management loading
const fetchingCredentialManagementData = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_CREDENTIAL_MANAGEMENT_DATA_LOADING,
        payload: { isLoading },
    });
};

// fetch credential management handler
const fetchCredentialManagementData = (response) => dispatch => {
    dispatch({
        type: FETCH_CREDENTIAL_MANAGEMENT_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            data: response.data.data
        },
    });
};

//fetch credential management data
export const fetchCredentialManagement = () => dispatch => {
    dispatch(fetchingCredentialManagementData());
    service.make({
        service: `insights/credentialManage/fetchToken`,
        method: "POST",
        authorized: true,
    }).then(response => {
        dispatch(fetchCredentialManagementData({
            data: response
        }));
    }).catch(error => {
        dispatch(fetchCredentialManagementData({
            error: error.message
        }));
    })
};

// update dev key loading
const updateDeveloperKeyData = (response) => dispatch => {
    response && response.error ?
        dispatch({
            type: UPDATE_DEVELOPER_KEY_DATA,
            payload: { isLoading: false },
        })
        :
        dispatch({
            type: UPDATE_DEVELOPER_KEY_DATA_LOADING,
            payload: { isLoading: true },
        });
};

// delete dev key loading
const deleteDeveloperKeyData = (response) => (dispatch) => {
    if (response && response.error) {
        dispatch({
            type: DELETE_DEVELOPER_KEY_DATA,
            payload: { isLoading: false },
        });
    }
};

// add admin token loading
const addAdminTokenData = (response) => dispatch => {
    response && response.error ?
        dispatch({
            type: ADD_ADMIN_TOKEN_DATA,
            payload: { isLoading: false },
        })
        :
        dispatch({
            type: ADD_ADMIN_TOKEN_LOADING,
            payload: { isLoading: true },
        });
};

// delete admin token loading
const deleteAdminTokenData = (response) => dispatch => {
    response && response.error ?
        dispatch({
            type: DELETE_ADMIN_TOKEN_DATA,
            payload: { isLoading: false },
        })
        :
        dispatch({
            type: DELETE_ADMIN_TOKEN_LOADING,
            payload: { isLoading: true },
        });
};

// Update developer key
export const updateDeveloperKey = (devKeyInfo) => dispatch => {
    dispatch(updateDeveloperKeyData());
    service.make({
        service: `insights/credentialManage/saveDevToken`,
        method: "POST",
        authorized: true,
        data: devKeyInfo
    }).then(() => {
        onSuccess("Updated Successfully!");
        dispatch(fetchCredentialManagement());
    }).catch(error => {
        onError("Update Failed!");
        dispatch(updateDeveloperKeyData({
            error: error.message
        }));
    })
};

export const deleteDeveloperKey = devKeyInfo => dispatch => {
    dispatch(deleteDeveloperKeyData());
    service.make({
        service: `insights/credentialManage/removeToken`,
        method: "POST",
        authorized: true,
        data: devKeyInfo,
    }).then(() => {
        onSuccess("Clear Developer Key Successfully!");
        dispatch(fetchCredentialManagement());
    }).catch(error => {
        onError("Clear Developer Key Failed!");
        dispatch(deleteDeveloperKeyData({
            error: error.message
        }));
    })
};

// add admin key
export const addAdminToken = (adminKeyInfo) => dispatch => {
    dispatch(addAdminTokenData());
    service.make({
        service: `insights/credentialManage/saveToken`,
        method: "POST",
        authorized: true,
        data: adminKeyInfo,
    }).then(() => {
        onSuccess("Token Added Successfully!");
        dispatch(fetchCredentialManagement());
    }).catch(error => {
        onError("Adding Token Failed!");
        dispatch(addAdminTokenData({
            error: error.message
        }));
    })
};

// delete admin key
export const deleteAdminToken = (adminKeyInfo) => dispatch => {
    dispatch(deleteAdminTokenData());
    service.make({
        service: `insights/credentialManage/removeToken`,
        method: "POST",
        authorized: true,
        data: adminKeyInfo,
    }).then(() => {
        onSuccess("Deleted Token Successfully!");
        dispatch(fetchCredentialManagement());
    }).catch(error => {
        onError("Delete Token Failed!");
        dispatch(deleteAdminTokenData({
            error: error.message
        }));
    })
};

// set selected dashboard
export const selectDashboard = payload => dispatch => {
    dispatch({
        type: SELECT_DASHBOARD,
        payload,
    });
};


// fetch dashboard loading
const fetchingDashboards = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD_LOADING,
        payload: {isLoading},
    });
};


// fetch dashboard handler
const fetchDashboardsStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_DASHBOARD,
        payload: {
            isLoading:false,
            error: response.error,
            data: response.data
        },
    });
};

// fetch dashboards
export const fetchDashboards = () => dispatch => {
    dispatch(fetchingDashboards());

    service.make({
        service:`tenant_admin/dashboards`,
        authorized: true
    }).then(response => {
        dispatch(fetchDashboardsStatus({
            data: response
        }))
    }).catch(error => {
        console.log(error)
        dispatch(fetchDashboardsStatus({
            error: error.message
        }))
    })
};

// save dashboard loading
const savingDashboard = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_DASHBOARD_LOADING,
        payload: {isLoading},
    });
};


// save dashboard handler
const saveDashboardStatus = (response) => dispatch => {
    dispatch({
        type: SAVE_DASHBOARD,
        payload: {
            isLoading:false,
            error: response.error,
            data: response.data
        },
    });
};

// save dashboard
export const saveDashboard = (dashboardData, extendedMode= false) => dispatch => {
    dispatch(savingDashboard());

    const serviceMaker = (resolve=false, reject=false) => {
        service.make({
            service:`tenant_admin/dashboards`,
            authorized: true,
            method: 'post',
            data:dashboardData
        }).then(response => {
            dispatch(saveDashboardStatus({
                data: response
            }))
            if (!extendedMode){
                onSuccess("Saved Successfully!")
                dispatch(fetchDashboards())
            }
            resolve && resolve();
        }).catch(error => {
            console.log(error)
            dispatch(saveDashboardStatus({
                error: error.message
            }))
            reject && reject();
        })

    }

    if (extendedMode){
        return new Promise((resolve, reject) => {
            serviceMaker(resolve, reject);
        })
    }else{
        serviceMaker();
    }



};

export const saveUserGroupsWith2fa = (dashboardData, dashboardId, data) => dispatch => {
    dispatch(saveDashboard(dashboardData, true)).then(() => {
        dispatch(saveShareUserRoles(dashboardId, data, true)).then(() => {
            onSuccess("Saved Successfully!")
        })
    })

};


// fetch user roles loading
const fetchingUserRoles = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_USER_ROLES_LOADING,
        payload: {isLoading},
    });
};


// fetch user roles handler
const fetchUserRolesStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_USER_ROLES,
        payload: {
            isLoading:false,
            error: response.error,
            data: response.data
        },
    });
};

// fetch user roles
export const fetchUserRoles = (dashboardId) => dispatch => {
    dispatch(fetchingUserRoles());

    service.make({
        service:`tenant_admin/dashboards/share?dashboard_id=${dashboardId}`,
        authorized: true
    }).then(response => {
        dispatch(fetchUserRolesStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchUserRolesStatus({
            error: error.message
        }))
    })
};


//save share with roles

// save share with user roles loading
const savingShareUserRoles = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_SHARE_ROLES_LOADING,
        payload: {isLoading},
    });
};


// save share with user roles handler
const saveShareUserRolesStatus = (response) => dispatch => {
    dispatch({
        type: SAVE_SHARE_ROLES,
        payload: {
            isLoading:false,
            error: response.error,
        },
    });
};

// save share with user roles
export const saveShareUserRoles = (dashboardId, data, extendedMode=false) => dispatch => {
    dispatch(savingShareUserRoles());

    const serviceMaker = (resolve=false, reject=false) => {
        service.make({
            service:`tenant_admin/dashboards/share`,
            authorized: true,
            method: "POST",
            data: {dashboard_id: dashboardId, user_groups: data}
        }).then(response => {
            dispatch(saveShareUserRolesStatus(response))
            if (!extendedMode){
                onSuccess("Saved Successfully!");
                dispatch(fetchDashboards());
            }
            resolve && resolve();
        }).catch(error => {
            dispatch(saveShareUserRolesStatus({
                error: error.message
            }))
            reject && reject();
        })
    }

    if (extendedMode){
        return new Promise((resolve, reject) => {
            serviceMaker(resolve, reject);
        })
    }else{
        serviceMaker();
    }

};

// Fetch info to use in web socket
export const fetchingBaseInfo = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCHING_GET_INFO,
        payload: {
            isLoading
        }
    });
};

export const getInfoData = response => dispatch => {
    const { error, data } = response;
    dispatch({
        type: GET_INFO,
        payload: {
            isLoading: false,
            error,
            response: data,
        }
    });
};

export const fetchCourse = (payload) => dispatch => {

    dispatch(fetchingBaseInfo());

    service.make({
        service: `insights/course_profile/getInfo`,
        authorized: true,
        method: "POST",
    }).then(response => {
        dispatch(getInfoData({
            data: response.data
        }))
    }).catch(error => {
        dispatch(getInfoData({
            error: error.message
        }))
    })
};