import React, {memo} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Typography } from 'antd';

import ExternalLoginLayout from "../common/Layout";
import SingleSignOn from "./saml/SingleSignOn";

import "../../../../assets/css/auth/externalAuth.css";
import { domainCode as vttCode } from "../../../../constant/vetTrack/analysis";

const {Text} = Typography;

const ExternalLogin = ({form, onsubmit, isLoading, title, connectWithSSO, samlMethods, strategiesLoading, domainCode}) => {

    return (
        <ExternalLoginLayout
            title={title}
            isLoading={isLoading}
            actions={[
                // not enable this as we do not allow users to change their passwords
                // <div className="ext-auth-footer">
                //     <Link to="/tenant/password/forgot">Forgot Password? Try Reset</Link>
                // </div>
            ]}>
            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                onFinish={onsubmit}
                autoComplete="off"
            >
                <Form.Item
                    label={<Text strong>Email</Text>}
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please input valid email!'
                        },
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input  size="large" />
                </Form.Item>

                <Form.Item
                    label={<Text strong>Password</Text>}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password  size="large" />
                </Form.Item>

                <Form.Item

                >
                    <Button
                        type="primary"
                        style={{width:"100%"}}
                        htmlType="submit"
                        size="large"
                        className={ domainCode === vttCode ? 'vtt-login-btn' : ''}
                    >
                        Login
                    </Button>
                    <SingleSignOn onMethodClick={connectWithSSO} samlMethods={samlMethods} strategiesLoading={strategiesLoading}/>
                </Form.Item>
            </Form>
        </ExternalLoginLayout>
    );
};

ExternalLogin.propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    connectWithSSO: PropTypes.func,
    isLoading: PropTypes.bool,
    title: PropTypes.any,
    samlMethods: PropTypes.array,
    strategiesLoading: PropTypes.bool,
    domainCode: PropTypes.string,
}

export default memo(ExternalLogin);
