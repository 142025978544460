import React, { memo } from "react";
import PropTypes from "prop-types";
import SelectFilter from "../../selectFilter";

const AccountFilter = ({
  accounts,
  accountsLoading,
  reportLoading,
  accountChange,
  selectedAccount,
  ...rest
}) => {
  return (
    <div>
      <SelectFilter
        items={accounts}
        itemChange={accountChange}
        itemsLoading={accountsLoading}
        selectedItem={selectedAccount}
        reportLoading={reportLoading}
        {...rest}
      />
    </div>
  )
}

AccountFilter.propTypes = {
  accounts: PropTypes.array,
  accountsLoading: PropTypes.bool,
  reportLoading: PropTypes.bool,
  accountChange: PropTypes.func,
  selectedAccount: PropTypes.string
}

export default memo(AccountFilter);