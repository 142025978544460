import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Layout, Button } from 'antd';
import { useDispatch } from 'react-redux';

import { setSidebarData } from '../../../../../actions/vetTrack/analysis/layout.action';
import { menu } from '../../../../../constant/vetTrack/menu';

const { Sider } = Layout;

const VetTrackSidebar = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setSidebarData({ isSidebar: true, collapsed: false }));
    return () => dispatch(setSidebarData({ isSidebar: false }));
  }, []);

  const onStaticMenuClick = key => {
    switch (key) {
      case 'analysis':
        history.push(menu.riskAnalysis);
        return;
      case 'actionCenter':
      default:
        return null;
    }
  };

  return (
    <Sider theme="light" style={{ backgroundColor: '#F9F9F9' }} trigger={null}>
      {/* <div className="mobile-only main-menu-items">
        <Button
          block
          success="true"
          onClick={item => onStaticMenuClick('analysis')}
          className="bg-success"
        >
          {' '}
          Risk Analysis
        </Button>
        <Button ghost block>
          {' '}
          Action Center
        </Button>
      </div> */}
      {children}
    </Sider>
  );
};

export default VetTrackSidebar;
