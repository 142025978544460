import React, {Fragment, memo} from 'react';
import PropTypes from "prop-types";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const CharWithLoader = ({
    data, 
    resolver, 
    component:Component, 
    isLoading, 
    customOptions, 
    settings,
}) => {
    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

    return (
        <Fragment>
            <Spin className={'in-loader'} indicator={antIcon} spinning={isLoading}/>
                {data &&
                <Component
                    data={data.data}
                    resolver={resolver}
                    customOptions={customOptions}
                    settings={settings}
                />
                }
        </Fragment>
    );
};

CharWithLoader.propTypes = {
    data: PropTypes.array,
    resolver: PropTypes.func,
    component: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default memo(CharWithLoader);