import React from 'react';
import PropTypes from "prop-types";
import { Button, Spin } from 'antd';
import Icon from '@ant-design/icons';

const antIcon = <Icon type="loading" style={{ fontSize: 35 }} spin />;

const SingleSignOn = ({ onMethodClick, samlMethods = [], strategiesLoading }) => {

    // single sign on strategy implementation
    return (
        <Spin indicator={antIcon} spinning={strategiesLoading}>
            {samlMethods.length ? (
                <div>
                    <div className='samlOrLine'>
                        <span>or</span>
                    </div>
                    <div
                        className="customButtonsSSO"
                    >
                        {(
                            samlMethods.map(method => (
                                <Button
                                    className="samlBtn"
                                    key={method.platform}
                                    onClick={() => onMethodClick(method)}
                                    block
                                    size="large"
                                >
                                    {method.name}
                                    <img className="samlImg"  src={`${process.env.REACT_APP_S3_BUCKET_URL}/jobready/public/saml_icon.png`} alt="saml logo" />
                                </Button>
                            ))
                        ) }
                    </div>
                </div>
            ) : null}

        </Spin>
    );
}


SingleSignOn.propTypes = {
    onMethodClick: PropTypes.func,
    samlMethods: PropTypes.array,
    strategiesLoading: PropTypes.bool,
}

export default SingleSignOn;
