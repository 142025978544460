import React, { Fragment } from 'react';
import { Col, Form, Input, Row, Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { sendLicenseManagerPasswordResetEmail } from '../../../actions/consumer.action';
import {getLicenseManagerResetPasswordEmailLoading} from "../../../selectors/consumer.selector";
import Loader from "../common/layout/Loader";
import { onSuccess } from '../../../utils/notificationHandler';

const ForgotPasswordForm = (props) => {
    const dispatch = useDispatch();
    const { onClickBack } = props;

    // selectors
    const isLoading = useSelector(getLicenseManagerResetPasswordEmailLoading);

    const onSubmitHandler = (data) => {
        //dispatch password reset link and get call-back
        dispatch(sendLicenseManagerPasswordResetEmail(data, () => {
            onSuccess("Email Successfully Sent");
            onClickBack();
        }))
    }
    
    return (
        <Fragment>
            <Loader isLoading={isLoading} />
            <div className="partner-portal-form">
                <div className="partner-portal-form-heading">Reset your password</div>
                <div className="partner-portal-form-sub-heading">Enter your email address so we can send a password reset link to your email</div>
                <Form
                    name="directLogin"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onSubmitHandler}
                    style={{ marginTop: '24px' }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email', message: 'Please enter valid Email!' }]}
                    >
                        <Input size={"large"} />
                    </Form.Item>
                    <Row >
                        <Col span={12}>
                            <Form.Item>
                                <Typography.Text onClick={onClickBack} className="login-form-typography-text">
                                    Back to login
                                </Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Fragment>
    )
}

export default ForgotPasswordForm