import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Form} from 'antd';
import PropTypes from "prop-types";

import ShareOptionsView from "./ShareOptionsView";
import Loader from "../../../common/layout/Loader";
import session from "../../../../../utils/session";
import menuData from "../../../../layout/dashboardManagement/menuData";

import {setBreadcrumb, setMenuSelected} from "../../../../../actions/dashboardManagement/layout.action";
import {
    getSharedUserRolesData,
    getUserRolesData,
    getSaveDashboardLoading,
    getSelectedDashboard,
    getDashboardListData
} from "../../../../../selectors/dashboardManagement/dashboard.selector";
import {
    fetchUserRoles,
    saveShareUserRoles,
    saveUserGroupsWith2fa,
    selectDashboard
} from "../../../../../actions/dashboardManagement/dashboard.action";

const ShareOptionsContainer = () => {
    const [form] = Form.useForm();
    const [currentDashboard, setCurrentDashboard] = useState(null)
    const {dashboardId} = useParams();
    const dispatch = useDispatch();

    //selectors
    const userRoles = useSelector(getUserRolesData)
    const dashboards = useSelector(getDashboardListData);
    const sharedUserRoles = useSelector(getSharedUserRolesData)
    const saveDashboardLoading = useSelector(getSaveDashboardLoading)
    const selectedDashboard = useSelector(getSelectedDashboard)

    const breadcrumbItems = [
        {
            key: 'admin',
            section: "Admin",
            href: menuData.manageDashboard.url
        }, {
            // TODO: this needs to be updated as the correct dashboard name
            key: 'dashboard',
            section: 'Dashboard',
        }, {
            key: 'shareOption',
            section: 'Share Options',
        }
    ]

    //set breadcrumb
    // set menu item
    // fetch user roles
    useEffect(() => {
        // TODO: selected dashboard needs to be fetched from backend. This way is not the correct way
        dispatch(selectDashboard(dashboardId))
        dispatch(setBreadcrumb(breadcrumbItems))
        dispatch(setMenuSelected(menuData.shareOptions.key));
        dispatch(fetchUserRoles(dashboardId))
    }, [])

    // get current dashboard data from dashboard list
    useEffect(() => {
        const {data} = dashboards;
        if (data && selectedDashboard){
            setCurrentDashboard(data.find(dashboard => dashboard.id === selectedDashboard));
        }
    }, [selectedDashboard, dashboards])

    const formatSharedGroupsPostData = (values) => {
        // convert userGroups data to correct format
        const formatUserGroups = {}
        Object.keys(values).filter(key => key.includes("userRoles."))
            .map(key => (formatUserGroups[[key.replace("userRoles.", "")]]=values[key]))
        return formatUserGroups;
    }

    const onShareOptionsFormSubmit = (values) => {
        const userGroups = formatSharedGroupsPostData(values)
        const twoFaAuth = values['2faAuth'] ? "email" : 'all';

        dispatch(saveUserGroupsWith2fa(
            {dashboard_id: dashboardId, otp_channel: twoFaAuth},
            dashboardId,
            userGroups || [],
        ))

    }

    return (
        <div>
            <Loader isLoading={userRoles.isLoading || saveDashboardLoading}>
            </Loader>
             <ShareOptionsView userRoles={userRoles.data} form={form} onFormSubmit={onShareOptionsFormSubmit} sharedUserRoles={sharedUserRoles} dashboard={currentDashboard}/>
        </div>
    )

}
ShareOptionsContainer.propTypes = {};

export default ShareOptionsContainer;