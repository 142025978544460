import React, { memo, useState } from 'react';
import { Popover } from 'antd';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const SettingColorPicker = ({ value, onChange, disabled, text }) => {
  const [pickerEnabled, setPickerEnabled] = useState(false);
  const styles = {
    width: 'auto',
    display: 'block',
    borderRadius: '20px',
    textAlign: 'center',
    background: `${value}`,
    color: '#FFFF',
    padding: '2px',
    fontWeight: 600,
    cursor: disabled ? 'normal' : 'pointer',
  };

  const handleClick = () => {
    if (!disabled) {
      setPickerEnabled(!pickerEnabled);
    }
  };

  const handleChange = color => {
    if (typeof onChange !== 'function') return;
    onChange(color.hex);
  };

  return (
    <div>
      <Popover
        content={<SketchPicker color={value} onChange={handleChange} />}
        trigger="click"
        visible={pickerEnabled}
        onVisibleChange={handleClick}
      >
        <div style={styles}>
          {text}
        </div>
      </Popover>
    </div>
  );
};

SettingColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export default memo(SettingColorPicker);
