import React, { useState ,Fragment, useEffect } from 'react';
import logo from '../../../assets/images/tentacle-logo.png';
import { Link, withRouter } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { MoreHorizontal } from 'react-feather';
import { LogoutOutlined, RollbackOutlined } from '@ant-design/icons';

import ConfirmBox from "../../widgets/common/confirm_box";

import {interimUserLoggedIn} from "../../../selectors/tentacle/auth.selector";
import {interimUserLogoutAuth} from "../../../actions/authentication.action";

const ACCEPTED_URLS_FOR_LOGIN = ["principal", "enrolments", "management", "ceo", "ceo_dashboard", "ceo_principal"]
const ACCEPTED_URLS_FOR_BACK = ["ceo_dashboard", "ceo_principal", "enrolments", "management"]
const Header = ({history, pathname}) => {
  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
  let showGoBackButton = false;

  //selectors
  const isInterimUserLoggedIn = useSelector(interimUserLoggedIn);

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function onLogoutClicked() {
    setShowLogoutConfirm(false);
    history.replace('', null);
    dispatch(interimUserLogoutAuth(() => history.push("/dashboard/login")));

  }

  function onBackClickedHandler () {
    history.goBack();
  }

  if(ACCEPTED_URLS_FOR_BACK.includes(pathname) && isInterimUserLoggedIn){
    showGoBackButton = true;
  }
  
  return (
      <Fragment>
        <div className="page-main-header" >
          <div className="main-header-right row">
            <div className="main-header-left d-lg-none">
              <div className="logo-wrapper">
                <Link to="/dashboard/default">
                  <img className="img-fluid" src={logo} alt="" />
                </Link>
              </div>
            </div>

            {/* back button for admin */}
            {
              showGoBackButton ?  <div onClick={onBackClickedHandler} style={{
                color: '#95A8F8', marginBottom: '4px', fontSize: '20px', cursor: 'pointer'
              }}>
              <RollbackOutlined/>
              </div> : ''
            }
            <div className="nav-right col p-0">
              <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
                <li>
                  {/* <SearchHeader /> */}
                </li>
                {
                  // show logout icon if logged in
                  (ACCEPTED_URLS_FOR_LOGIN.includes(pathname) && isInterimUserLoggedIn) &&
                  <li>
                    <a onClick={() => setShowLogoutConfirm(true)} title="Logout">
                      <LogoutOutlined />
                    </a>
                  </li>
                }
              </ul>
              <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal/></div>
            </div>
            <script id="result-template" type="text/x-handlebars-template">
              <div className="ProfileCard u-cf">
                <div className="ProfileCard-avatar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                  </path>
                    <polygon points="12 15 17 21 7 21 12 15"></polygon>
                  </svg>
                </div>
                <div className="ProfileCard-details">
                  <div className="ProfileCard-realName"></div>
                </div>
              </div>
            </script>
            <script id="empty-template" type="text/x-handlebars-template">
              <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
            </script>
          </div>
        </div>
        <ConfirmBox
            topic="Logout"
            message="Are you sure?"
            confirmButtonColor="danger"
            confirmButtonText="Logout"
            show={showLogoutConfirm}
            onHide={() => setShowLogoutConfirm(false)}
            onConfirm={onLogoutClicked}/>
      </Fragment>
  )
};
export default withRouter(Header);