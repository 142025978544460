import React from "react";
import { Modal, Button, Result } from 'antd';
import PropTypes from "prop-types";

const ResultNotificationModal = ({
                                     isModalVisible,
                                     primaryButtonClick,
                                     secondaryButtonClick,
                                     status,
                                     title,
                                     subtitle,
                                     primaryButtonText,
                                     secondaryButtonText,
                                     primaryButtonProps,
                                     secondaryButtonProps,

                                 }) => {

    return (
        <Modal visible={isModalVisible} footer={null} closable={false}>
            <Result
                status={status}
                title={title}
                subTitle={subtitle}
                extra={[
                    <Button {...secondaryButtonProps} onClick={secondaryButtonClick}>{secondaryButtonText}</Button>,
                    <Button type="primary" {...primaryButtonProps} onClick={primaryButtonClick}>
                        {primaryButtonText}
                    </Button>,
                ]}
            />
        </Modal>
    )
}

ResultNotificationModal.defaultProps = {
    primaryButtonText: "Ok",
    secondaryButtonText: "Close",
}


ResultNotificationModal.propTypes = {
    isModalVisible: PropTypes.bool,
    primaryButtonClick: PropTypes.func,
    secondaryButtonClick: PropTypes.func,
    status: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    primaryButtonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    primaryButtonProps: PropTypes.object,
    secondaryButtonProps: PropTypes.object,
}

export default ResultNotificationModal;