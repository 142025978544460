import React from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'antd';

const ConfirmBox = ({message, topic, confirmButtonText, confirmButtonDanger, onConfirm, show, onHide, ...props}) => {
    return (
        <Modal
            visible={show}
            title={topic}
            onOk={onConfirm}
            onCancel={onHide}
            footer={[
                <div key={0}>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                    <Button type="primary" onClick={onConfirm} danger={confirmButtonDanger}>
                        {confirmButtonText}
                    </Button>
                </div>,
            ]}
            {...props}
        >
            {message}
        </Modal>
    )
}

ConfirmBox.defaultProps = {
    message: "Are You Sure?",
    topic: "",
    confirmButtonText: "Proceed",
    confirmButtonDanger: "",
}

ConfirmBox.propTypes = {
    message: PropTypes.string,
    topic: PropTypes.string,
    confirmButtonText: PropTypes.string,
    confirmButtonDanger: PropTypes.string,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onConfirm: PropTypes.func,
}

export default ConfirmBox;