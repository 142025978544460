import {
  VTT_FETCH_USER_PERMISSIONS,
  VTT_FETCH_USER_PERMISSIONS_LOADING,
  VTT_SETTINGS_ALTERNATION,
  VTT_SETTINGS_RESET_EDIT_MODE,
  VTT_SETTINGS_DISCARD_DRAFT,
  VTT_SETTINGS_DISCARD_DRAFT_LOADING,
} from '../../../../constant/actionTypes';

const defaultState = {
  isEdited: {
    state: false,
    section: null,
    subSection: null,
  },
  permissions: {
    isLoading: false,
    data: {},
  },
  discardDraft: {
    isLoading: false,
  },
};

const commonReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VTT_FETCH_USER_PERMISSIONS:
    case VTT_FETCH_USER_PERMISSIONS_LOADING:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload,
        },
      };
    case VTT_SETTINGS_DISCARD_DRAFT:
    case VTT_SETTINGS_DISCARD_DRAFT_LOADING:
      return {
        ...state,
        discardDraft: {
          ...state.discardDraft,
          ...action.payload,
        },
      };
    case VTT_SETTINGS_ALTERNATION:
      return {
        ...state,
        isEdited: {
          state: true,
          section: action.section,
          subSection: action.subSection,
        },
      };
    case VTT_SETTINGS_RESET_EDIT_MODE:
      return {
        ...state,
        isEdited: { ...defaultState.isEdited },
      };
    default:
      return state;
  }
};

export default commonReducer;
