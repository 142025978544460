
export default {
    "CU_VGA_001": {
        description:"VW Dealership Training Dashboard",
        showActive: false,
        settingsUrl: null,
    },
    "JR_GEN_001": {
        description:"Job Ready Risk Analysis app will assist you to identify students at risk in your institute.",
        showActive: true,
        settingsUrl: null,
    },
}