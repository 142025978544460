import _get from "lodash/get";

// return ra financial data
export const getDashboardFinancialData = state => _get(state, 'jrGeneric.riskAnalysis.financial');

export const getFinancialCardsLoading = state => _get(getDashboardFinancialData(state), 'cards.isLoading');
export const getFinancialCardsData = state => _get(getDashboardFinancialData(state), 'cards.data');

export const getFinancialStudentsLoading = state => _get(getDashboardFinancialData(state), 'students.isLoading');
export const getFinancialStudentsData = state => _get(getDashboardFinancialData(state), 'students.data.students', []);
export const getFinancialFactorsData = state => _get(getDashboardFinancialData(state), 'students.data.enabledFactors');

export const getFinancialOverviewLoading = state => _get(getDashboardFinancialData(state), 'overview.isLoading');
export const getFinancialOverviewData = state => _get(getDashboardFinancialData(state), 'overview.data');