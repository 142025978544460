import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
import thunkMiddleware from 'redux-thunk'

// Import custom components
import reducers from '../reducers/index';

const middlewares = [];

middlewares.push(thunkMiddleware);

/**
 * Create a Redux store that holds the app state.
 */

export const createAStore = () => createStore(reducers, compose(
    applyMiddleware(thunkMiddleware),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
        return f;
    }
));
const store = createAStore();

// eslint-disable-next-line
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
});


export default store;