import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'
import { Layout, Row, Col, Divider, Card, Typography } from 'antd';
import '../../../assets/css/ceo-profiles-selector.css';
import accImage from "../../../assets/images/acclogo.png";
import profileImg from "../../../assets/images/analytics.png";
const CEOProfilesNav = (props) => {
    const { Content } = Layout;
    const { history } = props;

    const routeChange = (e) => {
        let path = '';
        switch (e) {
            case 'ceo':
                path = `${process.env.PUBLIC_URL}/dashboard/ceo/ceo_dashboard`;
                break;
            case 'principal':
                path = `${process.env.PUBLIC_URL}/dashboard/ceo/ceo_principal`;
                break;
            default:
                throw new Error('Unknown profile')
        }
        history.push(path);
    }
    
    const routeChangeToCeo = () => {
        routeChange('ceo')
    }
    const routeChangeToPrincipal = () => {
        routeChange('principal')
    }
    return (
        <Fragment>
            <Layout className='acc-ceo-profile-selector-layout'>
                <Content>
                    <Card className='acc-ceo-profile-selector-content-wrapper'>
                        <div className='acc-ceo-profile-selector-acc-image'>
                            <img src={accImage} alt="" />
                        </div>
                        <div className='acc-ceo-profile-selector-divider-choose-account'>Choose an account</div>
                        <Divider orientation="center" style={{ border: '1px solid #dadce0' }} />
                        <Row type="flex" align="middle" className="acc-ceo-profile-selector-row">
                            <Col span={12}>
                                <div onClick={routeChangeToCeo} className="acc-ceo-profile-selector-button" >
                                    <Row>
                                        <Col><img src={profileImg} alt="acclogo" />
                                            <Typography className="acc-ceo-profile-selector-typography">CEO Dashboard</Typography>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="acc-ceo-profile-selector-button" onClick={routeChangeToPrincipal} >
                                    <Row>
                                        <Col> 
                                            <img src={profileImg} alt="acclogo" />
                                            <Typography className="acc-ceo-profile-selector-typography">Principal Dashboard</Typography>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Content>
            </Layout>
        </Fragment >
    );

};
export default withRouter(CEOProfilesNav);