import React, {useCallback, useEffect, useState} from 'react';
import {Card, Col, PageHeader, Row, Spin} from "antd";
import PolicyAgreement from '../verification/PolicyAgreement';
import {useDispatch, useSelector} from "react-redux";
import HeaderControls from "./partials/HeaderControls";
import ConsumerGrid from "./consumerGrid";
import {fetchConsumers} from "../../../actions/consumer.action";
import {getSubUserPartnerId} from "../../../selectors/onboarding/auth.selector";
import { notification } from 'antd';
import {
    makeConsumerDeleteLoading,
    makeConsumersFetchLoading,
    makeConsumersFetchResponseData
} from "../../../selectors/consumer.selector";
import {LoadingOutlined} from "@ant-design/icons";
import RegenerateKeyModal from "./consumerGrid/partials/RegenerateKeyModal";
import { policyTermsAgreed } from '../../../actions/authentication.action';
import { reactLocalStorage } from 'reactjs-localstorage';

const ConsumerListContainer = () => {
    const dispatch = useDispatch();
    const consumerListFetching = useSelector(makeConsumersFetchLoading);
    const consumerList = useSelector(makeConsumersFetchResponseData);
    const consumerDeleting = useSelector(makeConsumerDeleteLoading);
    const userPartner = useSelector(getSubUserPartnerId);
    const [isLoading, setIsLoading] = useState(true);
    const [showAgreement, setShowAgreement] = useState(false);
    const {policy} = reactLocalStorage.getObject('subUserPolicy', undefined);

    const handleAgreedTerms = () => {
        setShowAgreement(false);
        dispatch(policyTermsAgreed());
        document.getElementById("notificationTrigger").click();
    }
    useEffect(() => {
        if(policy){
            setShowAgreement(false)
        }else{
            setShowAgreement(true)
        }
        dispatch(fetchConsumers({}, userPartner));
    }, [dispatch, userPartner,policy]);

    const setPageLoader = useCallback(() => {
        // setting a default buffer for the spinner to avoid blink effect
        const status = (consumerListFetching || consumerDeleting);
        if (!status) {
            return setTimeout(() => {
                setIsLoading(status);
            }, 500);
        }

        return setIsLoading(status);
    },[consumerDeleting, consumerListFetching])

    useEffect(() => {
        setPageLoader();
    }, [consumerListFetching, consumerDeleting, setPageLoader]);

    const openNotification = placement => {
        notification.info({
            message: `Cookies`,
            description: 'This website uses cookies to offer you the most relevant experience. Using this website means you are ok with this. You can change which cookies are set anytime.',
            placement,
        });
    };
    return (
        <div>
            {showAgreement ? <PolicyAgreement agreedTerms={handleAgreedTerms}/> : ""}
            <button style={{display: 'none'}} id='notificationTrigger' onClick={() => openNotification('bottomLeft')}/>
            <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
                <Row gutter={100}>
                <Col span={24}>
                    <PageHeader
                        className="site-page-header"
                        title="Partner Portal"
                        extra={<HeaderControls/>}
                    />
                    <Card>
                        <ConsumerGrid data={consumerList}/>
                    </Card>
                </Col>
                </Row>
            </Spin>
        </div>
        
    );
};

export default ConsumerListContainer;