import React from 'react';
import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeResetUserPasswordLoading } from '../../../../../../../../selectors/vetTrack/dashboardManagement.selector';
import { resetUserPassword } from '../../../../../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action';

import ResetPasswordModal from './ResetPasswordModal';
import { onSuccess } from '../../../../../../../../utils/notificationHandler';

const ResetPasswordContainer = ({ handleOk, handleCancel, ...rest }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // selectors
  const resetPasswordLoading = useSelector(makeResetUserPasswordLoading);

  const handleFormSubmit = values => {
    dispatch(
      resetUserPassword(values, () => {
        onSuccess('Password reset successfully!');
      }),
    );
  };

  const onFormSubmit = () => {
    form.validateFields().then(values => {
      handleFormSubmit(values);
      form.resetFields();
      handleOk();
    });
  };

  const onHandleCancel = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <ResetPasswordModal
      {...rest}
      handleOk={onFormSubmit}
      handleCancel={onHandleCancel}
      form={form}
      onFormSubmit={onFormSubmit}
      isLoading={resetPasswordLoading}
    />
  );
};

ResetPasswordContainer.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ResetPasswordContainer;
