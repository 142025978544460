import React, {useEffect, useRef, useState} from "react";
import {Col, Button, Form, Row, Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const FormWrapper = ({selected: {Component}, activeStep, setActiveStep, totalSteps, editMode, initialValues, triggerEndAction, previous=true}) => {
    const [form] = Form.useForm();
    const child = useRef(null);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [stepStatus, setStepStatus] = useState([]);

    const getValue = (key, item) => {
        if (formData && formData[key] !== undefined) {
            return formData[key];
        }

        if (!item.getter) {
            return initialValues[item.field];
        }

        return item.getter(initialValues[item.field]);
    };

    useEffect(() => {
        if (initialValues && editMode) {
            const mapping = child.current.fieldMapping;
            if (mapping) {
                Object.keys(mapping).forEach(key => {
                    const item = mapping[key];
                    form.setFieldsValue({[key]: getValue(key, item)})
                })
            }
        }
    }, [initialValues, editMode, activeStep])

    const isFirstStep = () => {
        return !(activeStep > 0);
    };

    const isLastStep = () => {
        return !(activeStep < (totalSteps - 1));
    };

    const goToPrevious = () => {
        if (isFirstStep()) {
            return false;
        }

        return setActiveStep(activeStep - 1);
    };

    const goToNext = () => {
        setActiveStep(activeStep + 1);
    };

    const updateStepStatus = (status) => {
        let updated = [...stepStatus];
        updated[activeStep] = status;
        setStepStatus(updated);
    };

    const handleSubmit = (values) => {
        setFormData({
            ...formData,
            ...values,
        });

        if (child && child.current && child.current.onSubmit) {
            return child.current.onSubmit({values, goToNext});
        }

        if (!isLastStep()) {
            return goToNext();
        }

        return triggerEndAction();
    };

    const renderPreviousBtn = () => {
        if (isFirstStep()) {
            return null;
        }

        return (
            <Form.Item>
                <Button onClick={goToPrevious} type="primary" size={"large"}>Back</Button>
            </Form.Item>
        )
    };

    const renderNextBtn = () => {
        if (isLastStep() && triggerEndAction.toString() === "() => {}"){
            return (
                <div/>
            )
        }
        const btnLabel = isLastStep() ? "Done" : (stepStatus[activeStep] ? "next" : "Save");
        return (
            <Form.Item>
                <Button type="primary" htmlType="submit" size="large">{btnLabel}</Button>
            </Form.Item>
        );
    };

    return (
        <Form form={form}
              name="formWrapper-disable-search"
              layout="vertical"
              onFinish={handleSubmit}>
            <Spin
                spinning={isFormLoading}
                className="modalSpin"
                indicator={<LoadingOutlined/>}>
                <Component
                    ref={child}
                    form={form}
                    formData={formData}
                    goToNext={goToNext}
                    srepDone={stepStatus[activeStep]}
                    updateStepStatus={updateStepStatus}
                    setIsFormLoading={setIsFormLoading}
                    initialValues={initialValues}
                    editMode={editMode}/>
            </Spin>
            <Row className="formBottomBar">
                <Col span={12} size="large" style={{textAlign: "left"}}>
                    {previous &&
                    renderPreviousBtn()
                    }
                </Col>
                <Col span={12} size="large" style={{textAlign: "right"}}>
                    {renderNextBtn()}
                </Col>
            </Row>
        </Form>
    );
};

FormWrapper.defaultProps = {
    triggerEndAction: () => {}
}

FormWrapper.propTypes = {
    selected: PropTypes.object,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    totalSteps: PropTypes.number,
    editMode: PropTypes.bool,
    initialValues: PropTypes.object,
};

export default FormWrapper;