import React, { memo, useEffect, useState } from 'react';
import { Col, Layout, Row, Tabs } from "antd";
import PropTypes from "prop-types";
import StudentProfileCard from "../common/cards/StudentProfileCard";
import Calendar from "./calendar";
import CourseComparisonGrid from "./courseComparisonGrid";
import OutcomeGridContainer from "./outcomeGrid/OutcomeGridContainer";
import TopCard from "./topCard";
import SideCharts from "../common/cards/sideCharts";
import GridSwitch from "../common/gridSwitches/GridSwitches";
import StudentClusterContainer from '../course/studentClustering';
import ModuleProgressGrid from './moduleProgress/moduleProgressGrid';
import { useLocation } from 'react-router';
import HeaderFilters from './customHeader/headerFilters';

const { TabPane } = Tabs;

const StudentProfileView = ({
  student,
  studentId,
  studentsReport,
  studentsReportLoading,
  sideChartsConfigs,
  terms,
  termsLoading,
  termChange,
  selectedTerm,
  currentViewTab,
  onChangeCourseState,
  switchFormValues,
  setSwitchFormValues,
  entrollmentOptions,
  selectedEnrollment,
  onEnrollmentChanged,
  clusteringEnabled,
  isClusteringDataAvailable,
  setSelectedTablePagination,
}) => {
  const [currentTab, setCurrentTab] = useState("courses");
  const [isCloseClassificationVisible, setIsCloseClassificationVisible] = useState(true);
  const { state } = useLocation();
  const viewModuleProgressTab = state && state.viewModuleProgressTab;

  useEffect(() => {
    if (viewModuleProgressTab) {
      setCurrentTab("module_progress");
    }
    if (currentTab === "courses") {
      setIsCloseClassificationVisible(false);
    }
  }, [viewModuleProgressTab])

  useEffect(() => {
    if (currentViewTab) {
      if (currentViewTab === 'classification' && !clusteringEnabled) {
        setCurrentTab('courses');
        return;
      }
      setCurrentTab(currentViewTab);
    }
  }, [currentViewTab])

  const onTabChange = e => {
    setCurrentTab(e)
  }

  return (
    <>
      <HeaderFilters
        studentsReportLoading={studentsReportLoading}
        terms={terms}
        termsLoading={termsLoading}
        termChange={termChange}
        selectedTerm={selectedTerm}
        enrollmentOptions={entrollmentOptions}
        selectedEnrollment={selectedEnrollment}
        onEnrollmentChanged={onEnrollmentChanged}
        student={student}
        switchFormValues={switchFormValues}
      />
      <div className='student-profile-content'>
        <Layout.Content className="site-layout-background singleColumnLayout">
          <Row gutter={[12, 12]}>
            <Col md={24} xl={6}>
              <Row gutter={[16, 0]}>
                <Col span={24} >
                  <Row gutter={[16, 0]}>
                    <Col span={24} >
                      <StudentProfileCard
                        src={student["student_avatar_url"]}
                        name={student["student_name"]}
                        id={student["student_sis_user_id"]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col sm={24} md={24} xl={24}>
                      <div className="in-sectionCover">
                        <SideCharts
                          configs={sideChartsConfigs}
                          records={studentsReport}
                          recordsLoading={studentsReportLoading}
                          termsLoading={termsLoading}
                          type="student"
                        />
                      </div>
                    </Col>
                    <Col sm={24} md={24} xl={24}>
                      <div className="in-sectionCover assignmentCalenderCover">
                        <Calendar studentId={studentId} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={24} xl={18} style={{ display: "flex", flexFlow: "column" }}>
              <div className="in-sectionCover studentDetails">
                <Row gutter={[16, 16]}>
                  <TopCard />
                </Row>
              </div>
              <div className="in-sectionCover courseComparison">
                <Row gutter={[16, 16]}>
                  <Col span={24}>

                    <Tabs
                      defaultActiveKey={currentTab}
                      tabBarStyle={{ fontSize: 40 }}
                      activeKey={currentTab}
                      onChange={onTabChange}
                      className="customTabs"
                      tabBarExtraContent={
                        <GridSwitch
                          onChange={onChangeCourseState}
                          isLoading={studentsReportLoading}
                          formValues={switchFormValues}
                          setFormValues={setSwitchFormValues}
                          switchConf={{
                            switches: {
                              switch1: {
                                label: "Published",
                                key: "published",
                                onChangeKeys: ["published", "unpublished"]
                              },
                              switch2: {
                                label: "Unpublished",
                                key: "unpublished",
                                onChangeKeys: ["unpublished", "published"]
                              },
                            },
                          }}
                        />
                      }
                    >
                      {clusteringEnabled && isCloseClassificationVisible && (
                        <TabPane tab="Course Classification" key="classification">
                          <StudentClusterContainer
                            type="scatter"
                            isClusteringDataAvailable={isClusteringDataAvailable}
                          />
                        </TabPane>
                      )}
                      <TabPane tab="Course Comparison" key="courses">
                        <CourseComparisonGrid setSelectedTablePagination={setSelectedTablePagination} />
                      </TabPane>
                      <TabPane tab="Outcomes" key="outcomes">
                        <OutcomeGridContainer />
                      </TabPane>
                      <TabPane
                        tab="Module Progress"
                        key="module_progress"
                      >
                        <ModuleProgressGrid
                          selectedTerm={selectedTerm}
                        />
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Layout.Content>
      </div>
    </>
  );
};

StudentProfileView.propTypes = {
  student: PropTypes.object,
  studentId: PropTypes.string,
  sideChartsConfigs: PropTypes.array,
  terms: PropTypes.array,
  termsLoading: PropTypes.bool,
  termChange: PropTypes.func,
  selectedTerm: PropTypes.string,
  currentViewTab: PropTypes.string,
  onChangeCourseState: PropTypes.func,
  switchFormValues: PropTypes.object,
  setSwitchFormValues: PropTypes.func,
  clusteringEnabled: PropTypes.func,
  isClusteringDataAvailable: PropTypes.bool,
  setSelectedTablePagination: PropTypes.func,
}

export default memo(StudentProfileView);
