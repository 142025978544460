import React from "react";

import VwUserManagementView from "./UserManagementView";


const VwUserManagementContainer= () => {

    return (
        <VwUserManagementView/>
    )
}

export default VwUserManagementContainer;