import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Row, Radio, Space, Tooltip } from 'antd';
import _lowerCase from 'lodash/lowerCase';
import _isEqual from 'lodash/isEqual';

import { SearchOutlined } from '@ant-design/icons';
import { getElementArrayFromObjectArray } from '../../../../../../utils/general';

const FilterSection = ({
  mode,
  isMulti,
  searchEnabled,
  options,
  selectedOptions,
  onChange,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  // effects
  useEffect(() => {
    if (options && Array.isArray(options)) {
      setFilteredOptions(options);
      return;
    }
    setFilteredOptions([]);
  }, [options]);

  useEffect(() => {
    // this is used to set the initial value of the "select all" checkbox
    if (selectedOptions && isMulti && filteredOptions) {
      const list = filteredOptions.map(i => i.id);
      const isIdentical = _isEqual([...selectedOptions].sort(), list.sort());
      if (isIdentical) {
        setCheckAll(true);
        setIndeterminate(false);
      }
      if (!selectedOptions.length) {
        setIndeterminate(false);
        setCheckAll(false);
      }
      if (selectedOptions.length && !isIdentical) {
        setIndeterminate(true);
        setCheckAll(false);
      }
    }
  }, [selectedOptions, filteredOptions]);

  const onCheckAllChange = event => {
    const { checked } = event.target;
    setIndeterminate(false);
    setCheckAll(checked);
    if (checked) {
      onChange(getElementArrayFromObjectArray(filteredOptions, 'id'));
      return;
    }
    onChange([]);
  };

  const renderRadioSection = () => (
    <Radio.Group
      value={selectedOptions}
      onChange={e => onChange(e.target.value)}
    >
      <Space direction="vertical">
        {filteredOptions.map(option => (
          <Radio key={option.id} value={option.id}>
            <Tooltip title={option.name} placement="rightBottom">
              {option.name}
            </Tooltip>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );

  const renderCheckboxSection = () => (
    <div>
      <Space direction="vertical">
        {isMulti && filteredOptions.length && (
          <Row>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Select All
            </Checkbox>
          </Row>
        )}
        <Checkbox.Group
          style={{ width: '100%' }}
          value={selectedOptions}
          onChange={e => onChange(e)}
        >
          <Space direction="vertical">
            {filteredOptions.map(option => (
              <Row key={option.id}>
                <Checkbox key={option.id} value={option.id}>
                  <Tooltip title={option.name} placement="rightBottom">
                    {option.name}
                  </Tooltip>
                </Checkbox>
              </Row>
            ))}
          </Space>
        </Checkbox.Group>
      </Space>
    </div>
  );

  const onSearch = e => {
    const { value } = e.target;
    setFilteredOptions([
      ...options.filter(option =>
        _lowerCase(option.name).includes(_lowerCase(value)),
      ),
    ]);
  };

  const renderSection = () => {
    if (mode === 'radio') {
      return renderRadioSection();
    }
    return renderCheckboxSection();
  };

  const renderSearch = () => {
    if (!options) return null;

    return (
      <Input
        addonAfter={<SearchOutlined />}
        placeholder="Search"
        onChange={onSearch}
      />
    );
  };

  return (
    <div>
      <Space direction="vertical">
        {searchEnabled && renderSearch()}
        {renderSection()}
      </Space>
    </div>
  );
};

FilterSection.propTypes = {
  mode: PropTypes.string,
  isMulti: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  options: PropTypes.array,
  selectedOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default FilterSection;
