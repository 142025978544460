import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import {Button, Row, Col, Typography, Modal, Popconfirm} from "antd";
import {WarningFilled} from "@ant-design/icons";

import {
    saveSettings,
    discardDraft
} from "../../../../../actions/jobReadyGeneric/settings/commonSettings.action";
import {getBaseSettingByPath} from "../../../../../selectors/jobReadyGeneric/settings/base.selector";
import {
    getIsEdited,
    getPermissionDataByPath
} from "../../../../../selectors/jobReadyGeneric/settings/commonSettings.selector";

import SettingApplyPopup from "./ApplyPopup";

import {initiateValidations} from "../helperValidators";
import {subSections, settingsSections} from "../../../../../constant/jobReadyGeneric/constants";

const configMapper = {
    [settingsSections.riskCalculations]: "saveCalculations",
    [settingsSections.riskIndicators]: "saveColors"
}


const SettingButtonGroup = ({section, subSection}) => {
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);

    // selectors
    const isDraft = useSelector(getBaseSettingByPath(`${section}.${subSection}.data.isDraft`))
    const dataset = useSelector(getBaseSettingByPath(`${section}.${subSection}.data`))
    const isEdited = useSelector(getIsEdited);
    const subSectionPermission = useSelector(getPermissionDataByPath(subSection));

    const getSubSectionData = () => {
        const subSectionKey = Object.keys(subSections).find(k => subSections[k].key === subSection)
        return subSections[subSectionKey];
    }

    const saveSettingsData = (mode) => {
        if(!initiateValidations(section, dataset)) {
            const subSectionData = getSubSectionData();
            dispatch(saveSettings(dataset, mode, section, subSection, subSectionData.id));
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onConfirmDiscard = () => {
        const subSectionData = getSubSectionData();
        dispatch(discardDraft(section, subSection, subSectionData.id));
    }

    return (
        <Fragment>
            <div className="jr-gen-setting-button-group">
                <Row align="middle">
                    <Col  xs={{ span: 24}} lg={{ span: 15 }} className="save-actions">
                        {isDraft && !isEdited.state &&
                        <Fragment>
                            {subSectionPermission && subSectionPermission.applyAll &&
                            <Button onClick={showModal}>Apply</Button>
                            }
                        </Fragment>
                        }
                        {isDraft &&
                        <div className="info">
                            <WarningFilled style={{color: "red", fontSize: 20}}/>
                            <Typography.Text strong>Un-applied changes exist</Typography.Text>
                        </div>

                        }
                    </Col>
                    <Col xs={{ span: 24}} lg={{ span: 9 }} className="local-actions">
                        {/* not in phase 01 */}
                        {/*<Button>Reset to default</Button>*/}
                        {/*<Button>Reset to previous</Button>*/}
                        {isDraft &&
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={onConfirmDiscard}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>Discard Changes</Button>
                        </Popconfirm>
                        }
                        {dataset && "factors" in dataset && subSectionPermission && subSectionPermission[configMapper[section]] &&
                        <Button type="primary" onClick={() => saveSettingsData("draft")}>Save</Button>
                        }
                    </Col>
                </Row>
            </div>
            <SettingApplyPopup
                setIsModalVisible={setIsModalVisible}
                saveSettingsData={saveSettingsData}
                isModalVisible={isModalVisible}
                showModal={showModal}
            />
        </Fragment>
    )
}

SettingButtonGroup.propTypes = {
    section: PropTypes.string,
    subSection: PropTypes.string
}

export default SettingButtonGroup