import _get from "lodash/get";

// return sale admin data
export const get2Fa = state => _get(state, 'dashboardManage.twoFaAuth');


// return 2fa check
export const get2faCheckData = state => {
    const result = get2Fa(state);
    return result && result.check;
};

// return otp auth
export const getOtpAuthData = state => {
    const result = get2Fa(state);
    return result && result.auth;
};

