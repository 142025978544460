import React from "react";
import {Table, Tag} from "antd";
import {useHistory} from "react-router";
import PropTypes from "prop-types";
import _get from "lodash/get";

import StatCell from "../common/StatCell";
import ChartCell from "../common/ChartCell";
import IconCell from "./partials/IconCell";
import CustomerScale from "../customerScale/customerScale";
import menuData from "../../../../layout/dashboardManagement/menuData";

const { Column } = Table

const View = ({data, paginationDisplayRows, filterData}) => {
    const history = useHistory();
    const dataSource = _get(data, 'data.response', []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'names',
            key: 'names',
            sorter: (a, b) => a.name?.localeCompare(b.name),
            defaultSortOrder: "ascend",
            render:(text, rec, index) => (
                <div className="view-name">
                    <a type="link" className="name" onClick={() => history.push({pathname:`${menuData.statsDataViewSales.url}/${rec.school_id}`, state:{start: filterData.dateRange[0].toISOString(), end:filterData.dateRange[1].toISOString()}})}>{rec.name}</a>
                    {/*<div className="scale-badge" style={{backgroundColor: rec.name.color,display: "inline-block"}}/>*/}
                </div>

            )
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            render:(value, row) => <IconCell platform={value}/>

        },
        {
            title: 'Unique User Logins',
            dataIndex: 'views',
            key: 'views',
            sorter: (a, b) => a.views - b.views,
        },
        {
            title: 'Growth 24h %',
            dataIndex: 'h24',
            key: 'h24',
            sorter: (a, b) =>  {
                if (a.h24.name === "NA" ) {
                    return -1

                }
                if (b.h24.name === "NA") {
                    return 1
                }

                else return a.h24.name - b.h24.name
            },
            render:(text, rec, index) => (
                <StatCell type={rec.h24.type} value={rec.h24.name} valueColor={rec.h24.color}/>
            )
        },
        {
            title: 'Growth 7d%',
            dataIndex: 'd7',
            key: 'd7',
            sorter: (a, b) =>  {
                if (a.d7.name === "NA" ) {
                    return -1

                }
                if (b.d7.name === "NA") {
                    return 1
                }

                else return a.d7.name - b.d7.name
            },
            render:(text, rec, index) => (
                <StatCell type={rec.d7.type} value={rec.d7.name} valueColor={rec.d7.color}/>
            )
        },
        {
            title: 'Total User Logins',
            dataIndex: 'totalViews',
            key: 'totalViews',
            sorter: (a, b) => a.totalViews - b.totalViews,
        },
        {
            title: 'Last 7 Days',
            dataIndex: 'last7Days',
            key: 'last7Days',
            render:(text, rec, index) => (
                <ChartCell chartType="line" series={rec.last7Days} color={"red"}/>
            )
        },
    ];


    return (
        <div className="stat-tab-table-div">
            <Table dataSource={dataSource} pagination={{ pageSize: paginationDisplayRows}} >
                {columns.map(col => (
                    <Column title={col.title} dataIndex={col.dataIndex} key={col.key} render={col.render} {...col}/>
                ))}
            </Table>
            {/*<div style={{display:"inline-block"}}>*/}
            {/*    <CustomerScale/>*/}
            {/*</div>*/}
        </div>
    )
}

View.propTypes = {
    paginationDisplayRows: PropTypes.string,
    data: PropTypes.array,
    filterData: PropTypes.object,
}
export default View;