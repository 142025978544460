import React from "react";
import {Form, Row, Col, Card, Typography} from "antd";
import PropTypes from "prop-types";

import StatFilters from "./partials/StatFilters";
import MainCard from "./partials/mainCards/MainCard";
import StatChart from "./common/Chart";
import UserDetailsTable from "./partials/userDetailsTables/UserDetailsTable";
import StudentUserDetailsTable from "./partials/userDetailsTables/StudentUserDetailsTable";
import StatMainSection from "./partials/mainSection/StatMainSection";
import Loader from "../../../common/layout/Loader";

import {
    prepareViewsChartOptions,
    prepareViewsOverTimeChartOptions,
    preparePlatformChartOptions,
    prepareUserGroupChartOptions
} from "./chartOptions";

const {Title} = Typography;

const chartContainerProps = {
    style:{height:"100%", width:"100%", paddingTop:40}
}

const StatDataView = ({filterForm, filterSubmit, reportData, reportDataLoading}) => {
    const {hourWiseViews, userDetails, viewsByUserGroups, trendingDashboards, topDashboards, platforms, device, monthWiseViews, weekWiseViews} = reportData;

    const generateMainChartSection = () => {
        return (
            <StatMainSection data={reportData}/>
        )
    }

    const renderViewsByGroups = () => {
        return (
            <div>
                <Title className="stat-header" level={5}>Views by User Type</Title>
                <StatChart containerProps={chartContainerProps} chartOptions={prepareUserGroupChartOptions(viewsByUserGroups)}/>
            </div>
        )
    }

    const renderStudentDetails = () => {
        //removed due to the changes
        return (
            <Card>
                <Title className="stat-header" level={5}>User Details - Students</Title>
                <StudentUserDetailsTable/>
            </Card>
        )
    }

    const viewsOverTimeChart = () => {
        return (
            <Loader>
                <StatChart containerProps={chartContainerProps} chartOptions={prepareViewsOverTimeChartOptions(hourWiseViews)}/>
            </Loader>
        )
    }

    const viewsChart = () => {
        return (
            <Loader>
                <StatChart containerProps={chartContainerProps} chartOptions={prepareViewsChartOptions(monthWiseViews, weekWiseViews)} immutable={true}/>
            </Loader>
        )
    }

    const renderPlatformSection = () => {
        //removed due to the changes
        return (
            <Loader>
                <div className="platform-section-main">
                    <div style={{width:"100%"}}>
                        <StatChart chartOptions={preparePlatformChartOptions(platforms)}/>
                    </div>
                    <div style={{width:"100%"}}>
                        <StatChart chartOptions={preparePlatformChartOptions(device)}/>
                    </div>
                </div>
            </Loader>
        )
    }

    const renderUserDetails = () => {
        //removed due to the changes
        return (
            <UserDetailsTable data={userDetails}/>
        )
    }

    return (
        <div className="stat-data-view-main">
            <Loader isLoading={reportDataLoading}>
                <div className="filter-main statsDashFilter">
                    <div className="filter-div">
                        <StatFilters form={filterForm} filterSubmit={filterSubmit}/>
                    </div>
                </div>
                <div>
                    <Row gutter={[16, 16]}  className="statRow">
                        <Col className="stat-card" xs={24} sm={24} lg={12} xl={10} span={8}>
                            <Card style={{height:"100%"}}>
                                {generateMainChartSection()}
                            </Card>
                        </Col>
                        <Col className="stat-card" xs={24} sm={24} lg={12} xl={14} span={16}>
                            <Card style={{height:"100%"}}>
                                {renderViewsByGroups()}
                            </Card>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]} className="statRow">
                        <Col className="stat-card" xs={24} sm={24} lg={14} xl={14} span={14}>
                            <Card style={{height:"100%"}}>
                                {/* TODO: need to enable this once the backend is finished*/}
                                {/*<Title className="stat-header" level={5}>Platform</Title>*/}
                                {/*{renderPlatformSection()}*/}
                                {/*<div>*/}
                                {/*    <Title className="stat-header" level={5}>User Details</Title>*/}
                                {/*    {renderUserDetails()}*/}
                                {/*</div>*/}
                                <Title className="stat-header" level={5}>Usage Summary - Total Views</Title>
                                {viewsChart()}
                            </Card>
                        </Col>
                        <Col className="stat-card" xs={24} sm={24} lg={10} xl={10} span={10}>
                            <Card className="minStatCard">
                                <div className="main-card-header">
                                    <span style={{fontSize:18}}>Trending Dashboards</span> &nbsp;
                                    <span style={{fontSize:12}}>(Highest Viewed In Last 10 Days)</span>
                                </div>
                                <MainCard data={trendingDashboards}/>
                            </Card>
                            <Card className="minStatCard">
                                <div className="main-card-header">
                                    <span style={{fontSize:18}}>Top Dashboards</span> &nbsp;
                                    <span style={{fontSize:12}}>(All Time)</span>
                                </div>
                                <MainCard  data={topDashboards}/>
                            </Card>
                            {/*<Card>*/}
                            {/*    <Title className="stat-header" level={5}>Views</Title>*/}
                            {/*    {viewsChart()}*/}
                            {/*</Card>*/}
                        </Col>
                    </Row>
                    <Row  className="statRow">
                        <Col>
                            <Card>
                                <Title className="stat-header" level={5}>Views by time of the day</Title>
                                {viewsOverTimeChart()}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Loader>
        </div>
    )
}

StatDataView.propTypes = {
    filterForm: PropTypes.object,
    filterSubmit: PropTypes.func,
    reportData: PropTypes.object,
    reportDataLoading: PropTypes.bool,
}

export default StatDataView;