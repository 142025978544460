import {
    FETCH_PATHWAY_STUDENTS_DATA,
    FETCH_PATHWAY_STUDENTS_DATA_LOADING,
    FETCH_PATHWAY_TIMELINE_DATA,
    FETCH_PATHWAY_TIMELINE_DATA_LOADING,
    CLEAR_PATHWAY_TIMELINE_DATA,
} from "../../constant/actionTypes";

import service from "../../utils/serviceHandlers/jobReady/customServices/vwDealership";

/** clear timeline data **/
// clear timeline data
export const clearTimelineData = () => dispatch => {
    dispatch({
        type: CLEAR_PATHWAY_TIMELINE_DATA,
    });
};


/** timeline data fetch **/
// fetch timeline data loading
const fetchingTimelineDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_PATHWAY_TIMELINE_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch timeline data handler
const fetchTimelineDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_PATHWAY_TIMELINE_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch timeline data
export const fetchTimelineData = (data) => dispatch => {
    dispatch(fetchingTimelineDataLoading());

    service.make({
        service:`insights/jrp/vga/pathway/applyFilters`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchTimelineDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchTimelineDataStatus({
            error: error.message
        }))
    })
};

/** Students data fetch **/
// fetch students data loading
const fetchingStudentsDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_PATHWAY_STUDENTS_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch students data handler
const fetchStudentsDataStatus = (response, selected) => dispatch => {
    dispatch({
        type: FETCH_PATHWAY_STUDENTS_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data}),
            elementSelected: selected
        },
    });
};

// fetch students data
export const fetchStudentsData = (data) => dispatch => {
    dispatch(fetchingStudentsDataLoading());

    service.make({
        service:`insights/jrp/vga/pathway/liststudents`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchStudentsDataStatus({
            data: response.data
        }, data))
    }).catch(error => {
        dispatch(fetchStudentsDataStatus({
            error: error.message
        }, data))
    })
};


