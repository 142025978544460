import React from "react";
import {Button, Card, Row} from "antd";

const { Meta } = Card;

const PricingTableCard = ({item, sendEmail, cardStyles, coverStyles}) => {
    return (
        <Card
            hoverable
            style={cardStyles}
            cover={
                <div style={{backgroundColor:item.color, width:"100%", height:"300px" , ...coverStyles}} >
                    <Row align={"bottom"} justify={"center"}>
                        {/*<p style={{color:"white", fontSize:"80px"}}>{item.price}</p>*/}
                    </Row>
                </div>
            }
        >
            <h4>{item.name}</h4>
            <Meta  description={item.description} />

            <br/>
            {/*<Row justify={"end"}>*/}
            {/*    <Button type="link" onClick={() => sendEmail(item.id)}>More Info.</Button>*/}
            {/*</Row>*/}
        </Card>
    )
}

export default PricingTableCard;