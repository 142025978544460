import {
  FETCHING_USER_ACCOUNTS,
  FETCH_USER_MANAGEMENT_TABLE_DATA,
  FETCH_USER_MANAGEMENT_TABLE_DATA_LOADING,
  RESET_USER_MANAGEMENT_COURSE_DATA,
  SEARCH_USERS_DATA,
  SEARCH_USERS_DATA_LOADING,
  USER_ACCOUNTS_DATA,
  USER_MANAGEMENT_COURSE_DATA,
  USER_MANAGEMENT_COURSE_DATA_LOADING,
  USER_MANAGE_CHECK_USER_ROLE_LOADING,
  USER_MANAGE_CHECK_USER_ROLE_DATA,
  RESET_SEARCH_USERS_DATA,
} from "../../constant/actionTypes";

const userManagementDefaultState = {
  userList: {
    isLoading: false,
    error: false,
    data: [],
  },
  accountList: {
    isLoading: false,
    error: false,
    data: [],
  },
  searchUserData: {
    isLoading: false,
    error: false,
    data: [],
  },
  courseList: {
    all: {
      lastSearchValue: "",
      dataFetched: false,
      isLoading: false,
      error: false,
      data: {
        count: 0,
        data: []
      },
    },
    allow: {
      lastSearchValue: "",
      dataFetched: false,
      isLoading: false,
      error: false,
      data: {
        count: 0,
        data: []
      },
    },
    restrict: {
      lastSearchValue: "",
      dataFetched: false,
      isLoading: false,
      error: false,
      data: {
        count: 0,
        data: []
      },
    },
    validateUser: {
      isLoading: false,
      error: false,
      data: {
        data: 0,
        existUser: false,
      },
    }
  },
};

const userManagementReducer = (state = userManagementDefaultState, action) => {
  switch (action.type) {
    case USER_MANAGE_CHECK_USER_ROLE_LOADING:
      return {
        ...state,
        validateUser: {
          isLoading: action.payload.isLoading,
        }
      }
    case USER_MANAGE_CHECK_USER_ROLE_DATA:
      return {
        ...state,
        validateUser: {
          ...action.payload,
        }
      }
    case RESET_USER_MANAGEMENT_COURSE_DATA:
      return {
        ...state,
        courseList: {
          ...userManagementDefaultState.accountList
        }
      }
    case USER_MANAGEMENT_COURSE_DATA_LOADING:
      const relatedTab = action.payload.tab;
      switch (relatedTab) {
        case "all":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              all: {
                lastSearchValue:  userManagementDefaultState.courseList.allow.lastSearchValue,  
                ...action.payload,
                data: {
                  ...userManagementDefaultState.courseList.all.data
                },
              },
            },
          }
        case "restrict":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              restrict: {
                lastSearchValue:  userManagementDefaultState.courseList.allow.lastSearchValue, 
                ...action.payload,
                data: {
                  ...userManagementDefaultState.courseList.restrict.data
                },
              },
            },
          }
        case "allow":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              allow: {
                lastSearchValue: userManagementDefaultState.courseList.allow.lastSearchValue, 
                ...action.payload,
                data: {
                  ...userManagementDefaultState.courseList.allow.data
                },
              },
            },
          }
        default: { }
      }
    // eslint-disable-next-line no-fallthrough
    case USER_MANAGEMENT_COURSE_DATA:
      const { tab, isLoading, error, data, searchValue } = action.payload;
      switch (tab) {
        case "all":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              all: {
                lastSearchValue: searchValue, 
                isLoading,
                error,
                data: data?.all,
                dataFetched: true,
              },
            },
          }
        case "restrict":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              restrict: {
                lastSearchValue: searchValue, 
                isLoading,
                error,
                data: data?.restrict,
                dataFetched: true,
              },
            },
          }
        case "allow":
          return {
            ...state,
            courseList: {
              ...state.courseList,
              allow: {
                lastSearchValue: searchValue, 
                isLoading,
                error,
                data: data?.allow,
                dataFetched: true,
              },
            },
          }
        default: { }
      }
    // eslint-disable-next-line no-fallthrough
    case FETCH_USER_MANAGEMENT_TABLE_DATA_LOADING:
      return {
        ...state,
        userList: {
          ...userManagementDefaultState.userList,
          ...action.payload,
        }
      }
    case FETCH_USER_MANAGEMENT_TABLE_DATA:
      return {
        ...state,
        userList: {
          ...action.payload,
        }
      }
    case FETCHING_USER_ACCOUNTS:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          ...action.payload,
        }
      }
    case USER_ACCOUNTS_DATA:
      return {
        ...state,
        accountList: {
          ...action.payload,
        }
      }
    case SEARCH_USERS_DATA_LOADING:
    case SEARCH_USERS_DATA:
      return {
        ...state,
        searchUserData: {
          ...action.payload
        }
      }
    case RESET_SEARCH_USERS_DATA:
      return {
        ...state,
        searchUserData: userManagementDefaultState.searchUserData,
      }
    default:
      return state;
  }
};

export default userManagementReducer;
