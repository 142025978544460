const fallbackState = {
  markingScheme: {
    activatingItems: {
      assignments: false,
      quizzes: false,
      outcomes: false,
      outcomeWeight: 0,
    },
    calculatingEngagementScore: {
      relativePageViewScore: {
        status: true,
        value: 30,
      },
      relativeParticipationScore: {
        status: true,
        value: 30,
      },
      onTimeSubmission: {
        status: true,
        value: 25,
      },
      lateSubmission: {
        status: true,
        value: 15,
      },
    },
    calculatingRiskScore: {
      performanceScore: {
        status: true,
        value: 50,
      },
      engagementScore: {
        status: true,
        value: 50,
      },
    },
  },
  colorScheme: {
    marksToGradeMappingForAssignments: [
      { from: 75, to: 100, grade: "A", color: "#50af60" },
      { from: 65, to: 75, grade: "B", color: "#d98c27" },
      { from: 50, to: 65, grade: "C", color: "#2674cf" },
      { from: 35, to: 50, grade: "D", color: "#39c3e5" },
      { from: 25, to: 35, grade: "E", color: "#e77d50" },
      { from: 0, to: 25, grade: "F", color: "#65666b" },
    ],
    marksToGradeMappingForPerformanceScore: [
      { from: 75, to: 100, grade: "A", color: "#50af60" },
      { from: 65, to: 75, grade: "B", color: "#d98c27" },
      { from: 50, to: 65, grade: "C", color: "#2674cf" },
      { from: 35, to: 50, grade: "D", color: "#39c3e5" },
      { from: 25, to: 35, grade: "E", color: "#e77d50" },
      { from: 0, to: 25, grade: "F", color: "#65666b" },
    ],
    taskCompleteStatus: [
      {
        status: "Complete",
        color: "#50af60",
      },
      {
        status: "Incomplete",
        color: "#65666b",
      },
    ],
    submission: {
      onTime: {
        color: "#5cbd26",
        submission_status: "On Time",
      },
      late: {
        color: "#faad14",
        submission_status: "Late",
      },
      missing: {
        color: "#eb5757",
        submission_status: "Missing",
      },
    },
    masteryStatus: {
      mastered: {
        status: "Mastered",
        color: "#50af60",
      },
      notMastered: {
        status: "Not Mastered",
        color: "#65666b",
      },
    },

    availability: true,
  },
  conditionalColors: {
    performanceScore: [
      { from: 0, to: 35, color: "#f5222d" },
      { from: 35, to: 75, color: "#faad14" },
      { from: 75, to: 100, color: "#52c41a" },
    ],
    engagementScore: [
      { from: 0, to: 35, color: "#f5222d" },
      { from: 35, to: 75, color: "#faad14" },
      { from: 75, to: 100, color: "#52c41a" },
    ],
    missingSubmission: [
      { from: 0, to: 3, color: "#262626" },
      { from: 3, to: "max", color: "#f5222d" },
    ],
    lateSubmission: [
      { from: 0, to: 4, color: "#262626" },
      { from: 4, to: "max", color: "#f5222d" },
    ],
    definingRiskCondition: [
      { from: 0, to: 35, color: "#52c41a", risk_category: "Low" },
      { from: 35, to: 75, color: "#faad14", risk_category: "Medium" },
      { from: 75, to: 100, color: "#f5222d", risk_category: "High" },
    ],
  },
};

const settingsReducerDefaultState = {
  selected: {
    isLoading: false,
    error: false,
    response: {
      data: fallbackState,
    },
  },
  saved: {
    isLoading: false,
    error: false,
    response: undefined,
  },
};


export { settingsReducerDefaultState }