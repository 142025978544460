import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { get as _get } from 'lodash';
import { Card, Tag, List, Button, Row, Col, Switch, Space, Typography, Tooltip } from 'antd';
import { EyeOutlined, ShareAltOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { LockFilled, SendOutlined, ClockCircleFilled } from "@ant-design/icons";
import SelectUser from './partials/selectUser';
import dashboardConfig from "../../../../../../constant/dashboardManagement/dashboardConfig";
import impressionHandler from "../../../../../../utils/impressionHandler/impressionHandler";
import { resetUserSearchData } from "../../../../../../actions/dashboardManagement/app.testing.action";

const { Title } = Typography;
const RENDER_TAG_TYPE = "user";

const DashboardCard = ({ dashboard, saveDashboard }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [maintenanceMode, setMaintenanceMode] = useState(dashboard.maintenance);
    const [maintenanceModeUsersArray, setMaintenanceModeUsersArray] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [setTestToggle, setMaintenanceTestToggle] = useState(dashboard.maintenance);
    const [isMaintenanceTestPopupOpen, setIsMaintenancePopupOpen] = useState(false);

    const getDashboardData = () => {
        return dashboardConfig[dashboard.access_code]
    }

    const updateMaintenanceModeUsers = () => {
        if (dashboard && dashboard.maintenance) {
            const filterMaintenanceUsers = dashboard.assignedTo.filter(user => user?.type !== "group");
            filterMaintenanceUsers && setMaintenanceModeUsersArray(filterMaintenanceUsers);
        }
    }

    // set maintenance mode of the current dashboard
    useEffect(() => {
        if (dashboard) {
            setMaintenanceMode(dashboard.maintenance);
            setDashboardData(getDashboardData());
        }
    }, [dashboard]);

    useEffect(() => {
        updateMaintenanceModeUsers();
        dispatch(resetUserSearchData());
    }, [isMaintenanceTestPopupOpen]);

    const renderMaintenanceUserTags = (user) => {
        if (user) {
            if (dashboard.maintenance && user?.type === RENDER_TAG_TYPE) {
                if (user.sis_id) {
                    return <Tag key={user.userId}>{user.name} ({user.sis_id})</Tag>;
                }

                return <Tag key={user.userId}>{user.name}</Tag>;
            }

            return <Tag key={`${user?.account_id}-${user?.handle}`}>{user?.handle}</Tag>;

        }
        return null;
    }

    const renderAssignedTags = () => {
        const assignedTo = _get(dashboard, 'assignedTo', []);

        const assignClassName = () => {
            if (assignedTo && assignedTo.length > 1) {
                return "render-assign-tags-margin";
            }
            return "render-assign-tags";
        };

        return (
            <div className={assignClassName()}>
                <Fragment>
                    <Title level={5}>Assign to</Title>
                    {assignedTo.map(group => renderMaintenanceUserTags(group))}
                </Fragment>
            </div>
        )
    }

    const renderStatusScreen = status => {
        switch (status) {
            case "inactive":
                return (
                    <Tooltip title="Locked" placement="bottom">
                        <div className="status-screen inactive-status-screen">
                            <LockFilled className={"dashboard-status-icon"} />
                        </div>
                    </Tooltip>
                )
            case "demo":
                return (
                    <Tooltip title="On Demo" placement="bottom">
                        <div className="status-screen demo-status-screen">
                            <SendOutlined className={"dashboard-status-icon"} />
                        </div>
                    </Tooltip>
                )
            case "pending":
                return (
                    <Tooltip title="Request Sent" placement="bottom">
                        <div className="status-screen pending-status-screen">
                            <ClockCircleFilled  className={"dashboard-status-icon"} />
                        </div>
                    </Tooltip>
                )
            default: 
                return <></>
        }
    }

    const renderMaintenanceSwitch = status => {
        if (['active', 'demo'].includes(status)) {
            return (
                <Fragment>
                    <span>Enable Maintenance Mode</span> &nbsp; 
                    <Switch 
                        checked={maintenanceMode} 
                        onChange={value => maintenanceModeChange(dashboard.id, value)} 
                    />
                </Fragment>
            )
        }
    }

    const maintenanceModeChange = (dashboard, modeStatus) => {
        setMaintenanceTestToggle(modeStatus);
        saveDashboard({ dashboard_id: dashboard, maintenance: modeStatus });
    }

    const testUsersModeChange = modeStatus => {
        setIsMaintenancePopupOpen(!!modeStatus);
    }

    const viewDashboard = (url, role) => {
        if (url) {
            const path = new URL(url);
            const navigateTo = path.pathname.concat(path.search);
            // send user access data for STATS
            const navigateUrl = role ? `${navigateTo}&role=${role}` : `${navigateTo}`;
            impressionHandler.sendUserAccessDataAsync({
                dashboard: dashboard.access_code,
                accessCode: dashboard.accessHash
            })
                .then(res => (
                    history.push(navigateUrl)
                ))
        }
    }

    return (
        <div>
            <Card style={{ marginBottom: "20px" }} className="dashboardRow">
                <List.Item
                    key={dashboard.id}
                >
                    <div className="reportImage">
                        <img
                            className="dashboard-prev-img"
                            alt="dashboard thumbnail"
                            src={dashboard.thumbnail}
                        />
                        {renderStatusScreen(dashboard.active_status)}
                    </div>
                    <Row gutter={16}>
                        <Col >
                            <List.Item.Meta
                                title={<Title level={3}>{dashboard.name}</Title>}
                                className="metaDetails"
                                description={dashboardData && dashboardData.description}
                            />
                            {renderAssignedTags()}
                        </Col>
                    </Row>
                    <div className="dashboard-maintenance-mode">
                        {renderMaintenanceSwitch(dashboard.active_status)}
                    </div>
                    <div className="dashboard-button-group">
                        <Space>
                            {dashboard.maintenance &&
                                <Button
                                    icon={<EyeOutlined />}
                                    size="large"
                                    onClick={() => testUsersModeChange(setTestToggle)}
                                >
                                    Test
                                </Button>
                            }

                            <Button size="large"
                                onClick={() => viewDashboard(dashboard.path, dashboard.role)}
                            >
                                <Space>
                                    <EyeOutlined />
                                    View
                                </Space>
                            </Button>
                            {/*TODO: Uncomment this in once the components are completed*/}
                            {/*<Button icon={<SettingOutlined />} type="primary" size="large" onClick={() => history.push(`/tenant-admin/settings/config/${dashboard.id}`)}>Configure</Button>*/}
                            <Button 
                                disabled={dashboard.maintenance ? true : false} 
                                icon={<ShareAltOutlined />} 
                                size="large" 
                                onClick={() => history.push(`/tenant-admin/settings/share/${dashboard.id}`)}
                            >
                                Share
                            </Button>
                        </Space>

                        <SelectUser
                            setIsMaintenancePopupOpen={setIsMaintenancePopupOpen}
                            isModalOpen={isMaintenanceTestPopupOpen}
                            dashboardId={dashboard.id}
                            accountId={dashboard.accountId}
                            accessCode={dashboard.access_code}
                            maintenanceModeUsersArray={maintenanceModeUsersArray}
                        />
                    </div>
                </List.Item>
            </Card>
        </div>
    )
}

DashboardCard.propTypes = {
    dashboard: PropTypes.object,
    saveDashboard: PropTypes.func,
};

export default DashboardCard;
