import React from "react";
import CEOProfilesSelector from "./CEOProfilesSelector";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { getInterimUser } from "../../../selectors/tentacle/auth.selector";
import Error403 from "../../dashboardCommon/errorPages/Error403";

const ParentProfile = (props) => {

    const { history } = props;
    const interimUser = useSelector(getInterimUser);
    const goToHome = () => {
        if (interimUser.role === "principal") {
            let path = `${process.env.PUBLIC_URL}/dashboard/principal`;
            history.push(path);
        }
        else{
            history.go(-1);
        }
    }
    if (interimUser.school === "acc" && interimUser.role === "ceo") {
        return <CEOProfilesSelector location={props.location} />
    } else {
        return (
            <div><Error403 onClick={goToHome} text="Back Home"/></div>
        )
    }
};

export default withRouter(ParentProfile);