import {
  VTT_FINANCIAL_CARDS_DATA_FETCH,
  VTT_FINANCIAL_CARDS_DATA_FETCH_LOADING,
  VTT_FINANCIAL_STUDENTS_DATA_FETCH,
  VTT_FINANCIAL_STUDENTS_DATA_FETCH_LOADING,
  VTT_FINANCIAL_OVERVIEW_DATA_FETCH,
  VTT_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING,
} from '../../../../constant/actionTypes';
import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';
import { resolveResponseErrorFormat } from '../../../../components/vetTrack/analysis/utils/general';

/** Fetch financial cards data * */
// fetch financial cards data loading
const fetchFinancialCardsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_CARDS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch financial cards data handler
const fetchFinancialCardsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_CARDS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch financial cards data
export const fetchFinancialCards = data => dispatch => {
  dispatch(fetchFinancialCardsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskCard`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchFinancialCardsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchFinancialCardsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch financial students data * */
// fetch financial students data loading
const fetchFinancialStudentsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_STUDENTS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch financial students data handler
const fetchFinancialStudentsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_STUDENTS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch financial students data
export const fetchFinancialStudents = data => dispatch => {
  dispatch(fetchFinancialStudentsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskStudents`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchFinancialStudentsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchFinancialStudentsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch financial overview data * */
// fetch financial overview data loading
const fetchFinancialOverviewLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch financial overview data handler
const fetchFinancialOverviewDataStatus = response => dispatch => {
  dispatch({
    type: VTT_FINANCIAL_OVERVIEW_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch financial overview data
export const fetchFinancialOverview = data => dispatch => {
  dispatch(fetchFinancialOverviewLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewCharts`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchFinancialOverviewDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchFinancialOverviewDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};
