import { onError } from '../../../../../utils/notificationHandler';
import { settingsSections } from '../../../../../constant/vetTrack/analysis';

const validateRiskCalculationData = dataset => {
  const totalWeight = Object.keys(dataset.factors).reduce(
    (accum, factorKey) => accum + parseFloat(dataset.factors[factorKey].weight),
    0,
  );

  if (totalWeight !== 100) {
    onError('Total factor weight should be equal to 100');
    return true;
  }
  return false;
};

export const initiateValidations = (section, dataset) => {
  let isError = false;

  if (section === settingsSections.riskCalculations) {
    isError = validateRiskCalculationData(dataset);
  }
  return isError;
};
