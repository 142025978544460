import {
    FETCH_SAML_STRATEGIES,
    FETCH_SAML_STRATEGIES_LOADING,
    SAML_USER_DATA
} from "../../constant/actionTypes";
import service from "../../utils/serviceHandlers/common/customServices/extAuth";

const fetchLoginMethods = (response) => dispatch => {
    dispatch({
        type: FETCH_SAML_STRATEGIES,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && { data: response.data })
        },
    });
};

const fetchLoginMethodsLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_SAML_STRATEGIES_LOADING,
        payload: { isLoading },
    });
};

/** get multiple SAML single sign on stratey methods for subdomain **/
export const requestLoginMethods = (data) => dispatch => {
    const domain = process.env.REACT_APP_OVERRIDE_DOMAIN || data.domain;
    dispatch(fetchLoginMethodsLoading());
    service.make({
        service: `saml/strategies`,
        method: "POST",
        data: { domain: domain }
    }).then(response => {
        dispatch(fetchLoginMethods({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchLoginMethods({
            error: error.message,
        }))
    })
};

const fetchSamlUserData = (response) => dispatch => {
    dispatch({
        type: SAML_USER_DATA,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && { data: response.data })
        },
    });
};

export const validateSamlAccessToken = ({ user }, callback) => dispatch => {
    service.make({
        service: `saml/validateSamlToken`,
        method: "POST",
        data: { user }
    }).then(response => {
        dispatch(fetchSamlUserData({
            data: response.data
        }))
        callback(response.data);
    }).catch(error => {
        dispatch(fetchSamlUserData({
            error: error.message,
        }))
    })
};

