import React, { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { Button, Popover } from "antd";
import PropTypes from "prop-types";
import { MoreOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

import OutcomeGridView from "./OutcomeGridView";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import ChartDonutCellRenderer from "../../common/table/ChartDonutCellRenderer";
import OutcomeCell from "./partials/OutcomeCell";
import { resolvePropertiesFromSettingArray } from "../../../../utils/innovative/resolveSettings";

import {
    makeInnovativeCoursesMainReportLoading,
    makeInnovativeCoursesMainReportOutcomeData,
    makeInnovativeCoursesMainReportOutcomeListData
} from "../../../../selectors/innovative/course.selector";
import { makeInnovativeSettingByPath } from "../../../../selectors/innovative/setting.selector";

const ADMIN_PATH = "/insights/admin/courses";

const OutcomeGridContainer = ({ courseId, selectedTerm, setSearchFunc }) => {
    // state
    const [outcomeData, setOutcomeData] = useState([]);
    const [outcomeDataLoaded, setOutcomeDataLoaded] = useState(true);

    //selectors
    const outcomeDetails = useSelector(makeInnovativeCoursesMainReportOutcomeData);
    const outcomeList = useSelector(makeInnovativeCoursesMainReportOutcomeListData);
    const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);
    const colorScheme = useSelector(makeInnovativeSettingByPath("colorScheme"));

    const parsedUrl = new URL(window.location.href);
    const isAdminPath = parsedUrl.pathname === ADMIN_PATH;

    useEffect(() => {
        setOutcomeData(outcomeDetails);

        // check the first loaded outcome. if empty, need to show empty message instead of the table
        if (!outcomeDetails || outcomeDetails.length === 0) {
            setOutcomeDataLoaded(false)
        } else {
            setOutcomeDataLoaded(true)
        }
    }, [outcomeDetails]);


    // student search from the array
    const onStudentSearch = useCallback(_debounce((keyword) => {
        if (keyword) {
            const filtered = outcomeDetails.filter(student => {
                return student['student_name'].toLowerCase().includes(keyword.toLowerCase());
            });
            return setOutcomeData(filtered);
        }

        return setOutcomeData(outcomeDetails);
    }, 500), [outcomeDetails]);

    // init debounced calls
    const debouncedCall = (event, callback) => {
        const {value} = event.target;
        callback(value);
    };

    const renderOutcomeDescription = (description) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: description}}/>
        )
    }

    const renderPopoverTitle = (title) => <div style={{fontWeight:"bold"}}>{title}</div>

    const renderOutcomeColumnHeader = (outcome) => (
        <Popover content={renderOutcomeDescription(outcome.description)} title={() => renderPopoverTitle(outcome.title)} trigger="click">
            <div title={outcome.title} className="outcome-title-container">
                <span className="outcome-title">{outcome.title} &nbsp; <MoreOutlined /></span>
            {/*<span className="outcome-title">Performance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance scorePerformance score &nbsp; <MoreOutlined /></span>*/}
            </div>
            </Popover>
    )

    const assignOutcomeCellRenderer = () => {
        return outcomeList.map(outcome => {
            const outcomeRender = (value, row) => {
                if(row[outcome.dataIndex]){
                    return (
                        <OutcomeCell
                            title={row[outcome.dataIndex].proficiencyRating}
                            score={row[outcome.dataIndex].mark}
                            colorSettings={row[outcome.dataIndex].proficiencyRatingColor}
                            mastered={row[outcome.dataIndex].mastered}
                        />
                    )
                }else{
                    return <div>"N/A"</div>
                }
            }
            return (
                {
                    ...outcome,
                    title:renderOutcomeColumnHeader(outcome),
                    render:outcomeRender}
            )
        })
    }


    // column definition for the table
    const getColumnDefinition = () => {
        return [
            {
                title: "Student Details",
                dataIndex: "student_name",
                fixed: 'left',
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["student_sis_user_id"]}
                    avatar={row["student_avatar_url"]}
                    url={ isAdminPath ? `/insights/admin/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}?current=outcomes` :
                    `/insights/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}?current=outcomes`
                    }
                />,
            }, {
                title: '',
                align: "center",
                width: 100,
                render: (value, row) => <Button htmlType="button">
                    <Link to={
                        isAdminPath ? `/insights/admin/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}?current=outcomes` :
                            `/insights/teacher/student-profile/${row["student_id"]}/${courseId}/${selectedTerm}?current=outcomes`
                    }>
                        View
                    </Link>
                </Button>,
            }, {
                title: "Overall Proficiency Ratings",
                dataIndex: "proficiencyCohort",
                width: 250,
                className: "row-pie-chart",
                render: (data) => <ChartDonutCellRenderer
                    data={data}
                    settings={colorScheme.proficiencyRatingForOutcomes}
                    customOptions={{chart:{width:280}, legend:{x:-20}}}
                    propMapping={[0, "description"]}
                    resolver={resolvePropertiesFromSettingArray}
                    customClasses="outcome-chart"
                />
            },{
                title: "Mastery Status",
                dataIndex: "masteryCohort",
                width: 250,
                className: "row-pie-chart",
                render: (data) => <ChartDonutCellRenderer
                    data={data}
                    settings={colorScheme.masteryStatus}
                    customOptions={{chart:{width:280}, legend:{x:-50}}}
                    propMapping={[0, "status"]}
                    resolver={resolvePropertiesFromSettingArray}
                    customClasses="outcome-chart"
                />
            },
        ];
    }
    return (
        <OutcomeGridView
            isLoading={courseMainReportLoading}
            outcomeDataLoaded={outcomeDataLoaded}
            onSearch={e => debouncedCall(e, onStudentSearch)}
            columns={getColumnDefinition().concat(assignOutcomeCellRenderer())}
            setSearchFunc={setSearchFunc}
            dataSource={outcomeData}
        />
    )
}
OutcomeGridContainer.propTypes = {
    setSearchFunc: PropTypes.func,
    courseId: PropTypes.string,
    selectedTerm: PropTypes.string,
};



export default OutcomeGridContainer;