import ls from 'localstorage-slim';
import AES from 'crypto-js/aes';
import CryptoEncUtf8 from 'crypto-js/enc-utf8';

// set encryptors to crypto js
ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(CryptoEncUtf8));
  } catch (e) {
    // incorrect/missing secret, return the encrypted data instead
    return data;
  }
};

const keys = {
    authMeta: "auth.meta"
}

const setLsSlim = (key, data, ttl = null, encrypt = false, secret = false) => {
    ls.set(key, data, { ttl, encrypt,  secret});
}

const getLsSlim = (key, decrypt = false, secret = false) => {
    try {
        return ls.get(key, {decrypt,  secret});
    }catch (e) {
        console.error(e)
        ls.remove(key);
        return {}
    }

}

const removeLsSlim = (key) => {
    return ls.remove(key);
}

const localStore = {
    keys,
    setLsSlim,
    getLsSlim,
    removeLsSlim,
}

export default localStore;

