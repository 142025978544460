import {combineReducers} from "redux";
import dealershipSettings from "./settings.reducer";
import dealershipMain from "./dealership.reducer";
import mandatoryOnline from "./mandatoryOnline.reducer";
import pathway from "./qualificationPathway.reducer";

const vwDealershipReducers = combineReducers({
    dealershipMain,
    mandatoryOnline,
    dealershipSettings,
    pathway,
});

export default vwDealershipReducers;