// return Authentication
export const authentication = state => state && state.Authentication;

// get Interim User
export const getInterimUser = state => {
    const result = authentication(state);
    return result && result.interimUser;
};

// return interim user is Logged in
export const interimUserLoggedIn = state => {
    const result = getInterimUser(state);
    return result && result.isLoggedIn;
};

// return interim user school
export const interimUserSchool = state => {
    const result = getInterimUser(state);
    return result && result.school;
};

