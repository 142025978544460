import React, {Fragment} from "react";
import {Card, Col, PageHeader, Row, Typography, Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useHistory} from "react-router";

import LiveConnections from "./partials/list/LiveConnections";
import menuData from "../../../layout/dashboardManagement/menuData";

const {Title} = Typography;

const LiveConnectView = () => {
    const history = useHistory();
    return (
        <div className="in-container">
                <Row justify="space-between">
                    <Col span={12}>
                        <Title level={4}>Live Connections</Title>
                    </Col>
                    <Col span={12} style={{textAlign:"end"}}>
                        <Button type="primary" onClick={() => history.push(menuData.createLiveConnect.url)} icon={<PlusOutlined/>}>Add Connection</Button>
                    </Col>
                </Row>
                <div className="liveConnections"> 
                    <LiveConnections/>
                </div>
        </div>
    )
}

export default LiveConnectView;