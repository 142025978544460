import _get from "lodash/get";

// return settings data
export const getSettingsData = state => _get(state, 'jrGeneric.settings.common');

export const getUserPermissions = state => _get(getSettingsData(state), 'permissions');
export const getUserPermissionsLoading = state => _get(getUserPermissions(state), 'isLoading');
export const getUserPermissionsData = state => _get(getUserPermissions(state), 'data.permissions');

// return data sources setting by path
export const getPermissionDataByPath = path => state => {
    const result = getUserPermissionsData(state);
    return _get(result, path, undefined);
};


export const getIsEdited = state => _get(getSettingsData(state), 'isEdited');

export const getDiscardDraftLoading = state => _get(getSettingsData(state), 'discardDraft.isLoading');
