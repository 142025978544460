import React from "react";
import { Col, Row } from "antd";
import { InfoCircleTwoTone } from '@ant-design/icons';

const DeveloperKeyInstructionCard = ({ sequenceNumber, title, subTitle, instructionsArray, children }) => {
  return (
    <div className="seqSteps">
      <Row justify="start" wrap={false}>
        {/* // type start,mid,end must change the css in number according to that  */}
        <Col>
          <div className="seqHeader">
            <div className="sequenceNumber">
              {sequenceNumber}
            </div>
            <h5>{title}</h5>
          </div>
          {instructionsArray.map((instruction, i) => (
            <div key={`${i}-${sequenceNumber}`} className="instruction-card" wrap={false}>
              <div className="instruction-card-icon">
                <InfoCircleTwoTone />
              </div>
              <p className="instruction-card-info">{instruction}</p>
            </div>
          ))}
          <h5>{subTitle}</h5>
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default DeveloperKeyInstructionCard;
