import React, { Fragment, useState, memo } from "react";
import PropTypes from "prop-types";
import { Table, Row, Button, Space, Col, InputNumber } from "antd";

const BulkUpdate = ({ disabled, handleOnChange }) => {
    const [bulkUpdateValue, setBulkUpdateValue] = useState(1);

    const onChange = (value) => {
        setBulkUpdateValue(value)
    }

    const handleBulkUpdate = () => {
        handleOnChange(bulkUpdateValue);
    }

    return (
        <Row justify="end" gutter={8} >
            <Space>
                <InputNumber
                    disabled={disabled}
                    value={bulkUpdateValue}
                    size="small"
                    max={99}
                    min={1}
                    step="1"
                    stringMode={true}
                    controls={false}
                    onChange={onChange}
                    style={{ width: 50 }}
                />
                <Button onClick={handleBulkUpdate} disabled={disabled} type="primary">
                    Update All
                </Button>
            </Space>
        </Row >)
}

const UnitsTable = ({ pagination, dataset, columns, editMode, setEditMode, onEditSubmit, handleChangeTable, handleBulkUpdate, bulkUpdateDisabled }) => {

    const renderTableFooter = () => {
        return (
            <Row display="flex" justify="end">
                <div>
                    <Space size="small">
                        {editMode ?
                            <Fragment>
                                <Button onClick={onEditSubmit} type="primary">
                                    Save
                                </Button>
                                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                            </Fragment>

                            :
                            <Button onClick={() => setEditMode(true)} type="primary">
                                Edit
                            </Button>
                        }
                    </Space>
                </div>
            </Row>
        )
    }

    return (
        <div className="vw-unit-data-table">
            <BulkUpdate disabled={bulkUpdateDisabled} handleOnChange={handleBulkUpdate} />
            <Table
                rowKey={(row) => row.recordId}
                onChange={handleChangeTable}
                columns={columns}
                dataSource={dataset}
                // scroll={{ x: true }}
                pagination={pagination}
                size="small"
                className="unitStructure"
                footer={dataset && dataset.length ? renderTableFooter : null}
            />
        </div>

    )
}

UnitsTable.propTypes = {
    pagination: PropTypes.object,
    dataset: PropTypes.array,
    columns: PropTypes.array,
    setEditMode: PropTypes.func,
    editMode: PropTypes.bool,
    onEditSubmit: PropTypes.func,
    handleChangeTable: PropTypes.func
}

export default UnitsTable;