import {
  STUDENT_FETCH_STUDENT,
  STUDENT_FETCH_STUDENT_LOADING,
  STUDENT_FETCH_STUDENT_REPORT,
  STUDENT_FETCH_STUDENT_REPORT_LOADING,
  STUDENT_CLEAR_STUDENT_DATA,
  STUDENT_FETCH_DUE_ASSIGNMENTS,
  STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING,
  STUDENT_CLEAR_STUDENT_DETAILS,
  STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA,
  STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA,
  SET_STUDENT_COURSE_STATUS,
  RESET_STUDENT_COURSE_STATUS,
} from "../../constant/actionTypes";

const studentReducerDefaultState = {
  selected: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {},
      },
    }
  },
  mainReport: {
    isLoading: false,
    error: false,
    response: {
      data: {
        result: {
          course_comparison: [],
          course_outcomes: [],
          course_cards: [],
          overall_course_grade: [],
          overall_assignment_submission: [],
          submissionColor: []
        },
      },
    }
  },
  courseStatus: ["all"],
  dueAssignments: {
    isLoading: false,
    error: false,
    response: {
      data: {}
    }
  },
  cohortData: [],
  gaugeData: [],
};

const studentReducer = (state = studentReducerDefaultState, action) => {
  switch (action.type) {
    case STUDENT_CLEAR_STUDENT_PROFILE_CHART_DATA:
      return {
        ...state,
        cohortData: studentReducerDefaultState.cohortData,
        gaugeData: studentReducerDefaultState.gaugeData
      };
    case SET_STUDENT_COURSE_STATUS:
      return {
        ...state,
        courseStatus: action.payload
      };
    case RESET_STUDENT_COURSE_STATUS:
      return {
        ...state,
        courseStatus: studentReducerDefaultState.courseStatus,
      };
    case STUDENT_FETCH_STUDENT_PROFILE_CHART_DATA:
      const { course_id, engagement, grade_cohort } = action.payload;
      return {
        ...state,
        cohortData: [...state.cohortData, { courseId: course_id, data: grade_cohort }],
        gaugeData: [...state.gaugeData, { courseId: course_id, data: engagement }]
      };
    case STUDENT_CLEAR_STUDENT_DETAILS:
      return {
        ...state,
        selected: studentReducerDefaultState.selected
      };
    case STUDENT_FETCH_STUDENT:
      return {
        ...state,
        selected: action.payload
      };
    case STUDENT_FETCH_STUDENT_LOADING:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: action.payload.isLoading
        }
      };
    case STUDENT_FETCH_STUDENT_REPORT:
      return {
        ...state,
        mainReport: action.payload
      };
    case STUDENT_FETCH_STUDENT_REPORT_LOADING:
      return {
        ...state,
        mainReport: {
          ...studentReducerDefaultState.mainReport,
          isLoading: action.payload.isLoading
        }
      };
    case STUDENT_CLEAR_STUDENT_DATA:
      return {
        ...state,
        mainReport: studentReducerDefaultState.mainReport
      };
    case STUDENT_FETCH_DUE_ASSIGNMENTS:
      return {
        ...state,
        dueAssignments: action.payload
      };
    case STUDENT_FETCH_DUE_ASSIGNMENTS_LOADING:
      return {
        ...state,
        dueAssignments: {
          ...state.dueAssignments,
          isLoading: action.payload.isLoading
        }
      };
    default:
      return state;
  }
};

export default studentReducer;