import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDashboard } from '../../../../actions/vetTrack/dashboardManagement/userManagement/vttUser.action';
import {
  makeDashboardSelectionsData,
  makeDashboardSelectionsLoading,
  makeSelectedDashboard,
  makeUserListLoading,
  makeAddUserLoading,
  makeDeleteUserLoading,
} from '../../../../selectors/vetTrack/dashboardManagement.selector';
import VetTrackUserManagementView from './vetTrackUserManagementView';
import UserModalContainer from './partials/userModal';
import '../../../../assets/scss/vetTrack/dashboardManagement/userManagement.scss';

const VetTrackUserManagementContainer = () => {
  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const dispatch = useDispatch();

  // selectors
  const dashboardsLoading = useSelector(makeDashboardSelectionsLoading);
  const dashboardsData = useSelector(makeDashboardSelectionsData);
  const selectedDashboard = useSelector(makeSelectedDashboard);
  const usersLoading = useSelector(makeUserListLoading);
  const addUserLoading = useSelector(makeAddUserLoading);
  const deleteUserLoading = useSelector(makeDeleteUserLoading);

  const isTemporaryHidden = true;

  useEffect(() => {
    // auto select first dashboard and fetch users
    if (dashboardsData && dashboardsData.length) {
      dispatch(setSelectedDashboard(1));
    }
  }, [dashboardsData]);

  const onDashboardChange = dashboard => {
    dispatch(setSelectedDashboard(dashboard));
  };

  const showUserModal = () => {
    setUserModalVisible(true);
  };

  const handleUserModalOk = () => {
    setUserModalVisible(false);
  };

  const handleUserModalCancel = () => {
    setUserModalVisible(false);
  };

  return (
    <>
      <VetTrackUserManagementView
        showUserModal={showUserModal}
        isLoading={
          dashboardsLoading ||
          usersLoading ||
          addUserLoading ||
          deleteUserLoading
        }
        dashboards={dashboardsData}
        onDashboardChange={onDashboardChange}
        selectedDashboard={selectedDashboard}
        isTemporaryHidden={isTemporaryHidden}
      />
      <UserModalContainer
        isVisible={isUserModalVisible}
        handleCancel={handleUserModalCancel}
        handleOk={handleUserModalOk}
        isTemporaryHidden={isTemporaryHidden}
      />
    </>
  );
};

export default VetTrackUserManagementContainer;
