import _get from "lodash/get";

// return ra data
export const getDashboardCommonData = state => _get(state, 'jrGeneric.riskAnalysis.common');

export const getDashboardFilterData = state => _get(getDashboardCommonData(state), 'filters');
export const getDashboardFiltersFetchLoading = state => _get(getDashboardFilterData(state), 'fetched.isLoading');
export const getDashboardFiltersFetchData = state => _get(getDashboardFilterData(state), 'fetched.data.filters');
export const getDashboardFiltersSelectedData = state => _get(getDashboardFilterData(state), 'selected');

export const getSelectedCardsByPath = path => state => {
     const result = _get(getDashboardCommonData(state), 'selectedCards');
     return _get(result, path, undefined)
};