import React, {Fragment, useEffect, useState, useCallback} from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import {Card, Col, Form, Input, Row, Divider, Avatar, Button, notification, Typography} from "antd";
import { UserOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from "react-redux";
import {setSubUser} from "../../../actions/authentication.action";
import {getSubUserIsLoggedIn, getSubUserLoading, getSubUserPartnerId} from "../../../selectors/onboarding/auth.selector";
import {useHistory} from "react-router-dom";
import 'antd/dist/antd.css';
import ForgotPasswordForm from "../passwordRest/ForgotPasswordForm";
import PartnerVerification from "../verification/PartnerVerification";
import Loader from "../common/layout/Loader";
import "../../../assets/css/onboarding/common.css";


const SignInContainer = () => {
    const dispatch = useDispatch();
    const subUserLoading = useSelector(getSubUserLoading);
    const subUserPartnerId = useSelector(getSubUserPartnerId);
    const subUserIsLogged = useSelector(getSubUserIsLoggedIn);
    const [signInStatus, setSignInStatus] = useState(false);
    const [currentForm, setCurrentForm] = useState(0);
    const showLoginFrom = 0;
    const showForgotPasswordForm = 1;
    const showVerificationForm = 2;
    const history = useHistory();
    const {isLoggedIn} = reactLocalStorage.getObject('subUser', undefined);
    const {verified} = reactLocalStorage.getObject('subUserVerified', undefined);
    const redirection = useCallback(() => {
        history.push('/setup/consumer');
      }, [history]);

    useEffect(() => {
        if (!subUserLoading && signInStatus && subUserIsLogged && verified) {
            redirection();
        }
        if (subUserIsLogged && verified) {
            redirection();
        }
        if (subUserIsLogged && subUserPartnerId && isLoggedIn) {
            setCurrentForm(showVerificationForm);
        }
    }, [subUserLoading, signInStatus, subUserIsLogged, subUserPartnerId, showVerificationForm, verified, redirection, isLoggedIn, dispatch])

    const onFinish = (data) => {
        dispatch(setSubUser(data, showErrNotification));
        setSignInStatus(true);
    }

    const showErrNotification = (title, message) => {
        notification.error({
            message: title,
            description:message,
            duration: 2
        });
    }

    const renderContent = () => {
        return (
            <Fragment>
                <Row gutter={75} className={"layout-row"} wrap={true}>
                    <Col flex="1 1 50%"  className={"layout-video"}>
                        <h3>Partner Up With Octopus.</h3>
                        <h4>Empower schools. Make a difference!</h4>
                        <Typography.Paragraph type="secondary" className="intro">
                            Octopus has just the right analytic tools to help principals, parents and CEO's improve 
                            the quality and the efficiency of education. Our platform processes raw data of students,
                            subjects and scores to make meaningful insights to shape the future of the next generation.
                            As an Octopus Partner, you help schools experience the Octopus platform and grow along with us.
                        </Typography.Paragraph>
                        <div className="videoCover">
                            <iframe
                                title="partnerFormVideo"
                                width="100%"
                                height="315"
                                src="https://www.youtube.com/embed/unPT_CtL2js"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                    </Col>
                    <Col flex="1 1 50%"  className={"layout-form"}>
                        <Loader isLoading={subUserLoading} />
                        <Card className={"signin-card"}>
                            <Row className="partner-portal-header" justify="center">
                                <Col span={8} offset={5}>
                                    <Avatar style={{ backgroundColor: '#5D5FE9' }} size={64} icon={<UserOutlined/>} />
                                </Col>
                                <Divider />
                            </Row>
                            {
                                (() => {
                                    switch (currentForm) {
                                        case 0: return renderLoginForm();
                                        case 1: return <ForgotPasswordForm onClickBack={() =>
                                            (setCurrentForm(showLoginFrom))} />;
                                        case 2: return <PartnerVerification isLoggedIn verified/>;
                                        default: renderLoginForm();
                                    }
                                })()
                            }
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    };

    const renderLoginForm = () => {
        return (
            <div className="partner-portal-form">
                <div className="partner-portal-form-heading">Login</div>
                <Form
                    name="directLogin"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish} 
                    style={{marginTop: '40px'}}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your Email!' },{ type: 'email', message: 'Please enter valid Email!' }]}
                    >
                        <Input size={"large"}/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            size="large"
                            type="password"
                        />
                    </Form.Item>
                    <Row >
                        <Col span={12}>
                            <Form.Item>
                                <Typography.Text onClick={()=> (setCurrentForm(showForgotPasswordForm))} className="login-form-typography-text">
                                    Forgotten password?
                                </Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Sign In</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="wizardContainr signin-container">
                {renderContent()}
            </div>
        </Fragment>

    )
}

export default SignInContainer;