import {
    GET_DOMAIN_META,
    GET_DOMAIN_META_LOADING
} from "../constant/actionTypes";
import service from "../utils/service";

/** Domain data fetch **/
// fetch domain data loading
const fetchingDomainDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: GET_DOMAIN_META_LOADING,
        payload: {isLoading},
    });
};


// fetch domain data handler
const fetchDomainDataStatus = (response) => dispatch => {
    dispatch({
        type: GET_DOMAIN_META,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch domain data
export const fetchDomainData = (domain) => dispatch => {
    const domainVal = process.env.REACT_APP_OVERRIDE_DOMAIN || domain;
    dispatch(fetchingDomainDataLoading());

    service.make({
        service:`insights/jrp/subDomain/getInfo`,
        method: "POST",
        data:{domain:domainVal}
    }).then(response => {
        dispatch(fetchDomainDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchDomainDataStatus({
            error: error.message,
        }))
    })
};