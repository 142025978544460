import React, {useCallback} from 'react';
import { Tag } from 'antd';
import {
    resolveFromArrayByKey,
} from "../../../../../utils/innovative/resolveSettings";

const RiskIndicatorCellRenderer = ({params}) => {
    const resolveCellData = useCallback(() => {
        return resolveFromArrayByKey(params);
    }, [params]);

    const resolved = resolveCellData();

    return (
        <Tag color={resolved.styles.color} className="risk-indicators">{resolved.value}</Tag>
    );
};

export default RiskIndicatorCellRenderer;