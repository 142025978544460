import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {makeConsumerSaveResponseData, makeKeygenResponseData} from "../../../../../../selectors/consumer.selector";
import StepKeyConfig from "../../views/StepKeyConfig";
import {regenKey} from "../../../../../../actions/consumer.action";
import {notification} from "antd";

const KeyConfigContainer = forwardRef(({form, initialValues, editMode}, ref ) => {
    const dispatch = useDispatch();
    const consumerSaveResult = useSelector(makeConsumerSaveResponseData);
    const keygenResponse = useSelector(makeKeygenResponseData);
    const fieldMapping = {
        consumerKey: {field: "consumer_key"},
        secretKey: "**********"
    };

    useImperativeHandle(ref, () => ({
        fieldMapping,
    }));


    useEffect(() => {
        form.setFieldsValue({
            consumerKey: undefined,
        });
        if(keygenResponse && keygenResponse.consumerKey){
            form.setFieldsValue({
                consumerKey: keygenResponse.consumerKey
            });
        }
    }, [keygenResponse])



    useEffect(() => {
        if (!editMode) {
            if (consumerSaveResult) {
                setTimeout(() => form.setFieldsValue({
                    consumerKey: consumerSaveResult["consumer_key"],
                    secretKey: consumerSaveResult.secret,
                }));
            }
        }
    }, [consumerSaveResult]);

    const showErrNotification = (title, message) => {
        notification.error({
            message: title,
            description:message,
            duration: 2
        });
    }

    const onKeyRegenerate = (field) => {
        dispatch(regenKey(!editMode ? consumerSaveResult["consumer_guid"] : initialValues["consumer_guid"], field, showErrNotification))
    }

    return <StepKeyConfig hasRegenerate onKeyRegenerate={onKeyRegenerate} hideSecret={!!editMode}/>;
});

KeyConfigContainer.displayName = "KeyConfigContainer";

KeyConfigContainer.propTypes = {
    form: PropTypes.object,
};

export default KeyConfigContainer;