import React from "react";
import { Card } from "antd";
import { FileOutlined } from '@ant-design/icons';

const NoStudentAvailable = () => {
	return (
		<>
			<Card
				className={"no-student-select-card"}
			>
				<FileOutlined className="no-student-select-icon" />
				<h3>
					No Data
				</h3>
				<p>
					All the available students will get listed in the search box.
				</p>
			</Card>
		</>
	)
}

export default NoStudentAvailable;
