import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import MyDashboards from "./MyDashboardView";

import {setBreadcrumb, setMenuSelected} from "../../../actions/jrDashboardManagement/layout.action";
import {fetchDashboards, saveDashboard} from "../../../actions/jrDashboardManagement/myDashboards.action";
import {
    makeDashboardListData,
    makeDashboardListLoading,
    makeDashboardSavedDataLoading
} from "../../../selectors/jrDashboardManagement/myDashboard.selector";
import jrMenuData from "../../layout/jrDashboardManagement/jrMenuData";


const JRMyDashboardContainer = () => {
    const dispatch = useDispatch();

    // selectors
    const dashboardsLoading = useSelector(makeDashboardListLoading)
    const dashboards = useSelector(makeDashboardListData)
    const dashboardSaveLoading = useSelector(makeDashboardSavedDataLoading)

    const breadcrumbItems = [
        {
            key: "home",
            section: "Home",
        },
        {
            key: jrMenuData.manageDashboard.key,
            section: jrMenuData.manageDashboard.name,
        },
    ];

    //set breadcrumb, menu
    useEffect(() => {
        dispatch(setMenuSelected(jrMenuData.manageDashboard.key));
        dispatch(setBreadcrumb(breadcrumbItems));
        dispatch(fetchDashboards());
    }, [])

    const onActiveChange = (dashboard, active) => {
        dispatch(saveDashboard({dashboard, active}))
    }

    return (
        <MyDashboards
            isLoading={dashboardsLoading || dashboardSaveLoading}
            dashboards={dashboards}
            onActiveChange={onActiveChange}
        />
    )
}

export default JRMyDashboardContainer;