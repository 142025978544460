import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import defaultAvatar from '../../../../../../../assets/images/vtt-default-avatar.png';

const { Text } = Typography;

const StudentDetailCell = ({ title, avatar, subtitle }) => {
  const onAvatarError = error => {
    error.target.src = defaultAvatar;
  };

  return (
    <div className="studentCard">
      <div className='student-cell-avatar'>
        <img
          src={avatar || defaultAvatar}
          alt={title}
          onError={onAvatarError}
        />
      </div>
      <div>
        <Text className="name">
          <b>{title}</b>
        </Text>
        <Text>
          {subtitle && <small className="meta-subtitle">{subtitle}</small>}
        </Text>
      </div>
    </div>
  );
};

StudentDetailCell.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
  subtitle: PropTypes.string,
};

export default StudentDetailCell;
