import React, { useEffect, useState } from 'react';

export const withTablePaginator = Component => ({ ...props }) => {
  const { students } = props;
  const [page, setPage] = useState(1);

  const pagination = {
    current: page,
    onChange: setPage,
    total: students,
    showSizeChanger: false,
    pageSize: 10,
  };

  // on data source change, table pagination will be reset to 1
  useEffect(() => {
    setPage(1);
  }, [props.students]);

  return <Component {...props} pagination={pagination} />;
};
