import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {DatePicker, Form, Input, InputNumber, Select} from "antd";

const StepLicenseConfig = ({dateFormat, getInitialDates, partnerId}) => {
    const initialDates = getInitialDates();

    return (
        <Fragment>
            <Form.Item
                name={"partnerId"}
                initialValue={partnerId}
                rules={[
                    {required: true},
                ]}>
                <Input type={"hidden"}/>
            </Form.Item>
            <Form.Item
                name={"schoolName"}
                label="School Name"
                rules={[
                    {required: true, message: "'School Name' is required"},
                ]}>
                <Input size={"large"}/>
            </Form.Item>
            <Form.Item
                name={"schoolEmail"}
                label="School Email"
                rules={[
                    {required: true, type: "email", message: "Enter valid email"}
                ]}>
                <Input size={"large"} />
            </Form.Item>
            <Form.Item
                name={"studentCount"}
                label="Number of students"
                rules={[
                    {type: "integer", message: "'Number of students' is not a valid number"}
                ]}>
                <InputNumber
                    size={"large"}
                    min="1"
                    step="1" style={{ display: 'inline-block', width: '100%' }}/>
            </Form.Item>
            <Form.Item
                name={"licenseStartDate"}
                label="License start date"
                initialValue={initialDates.start}
                style={{display: 'inline-block', width: '50%'  }}
                rules={[
                    {required: true, message: "'License Start date' is required"},
                ]}>
                <DatePicker size={"large"} format={dateFormat} style={{ display: 'inline-block', width: '100%' }}/>
            </Form.Item>
            <Form.Item
                name={"licenseEndDate"}
                label="License end date"
                initialValue={initialDates.end}
                style={{ display: 'inline-block', width: '50%' }}
                rules={[
                    {required: true, message: "'License End date' is required"},
                ]}>
                <DatePicker size={"large"} format={dateFormat} style={{ display: 'inline-block', width: '100%', marginLeft:"5px" }}/>
            </Form.Item>

            <Form.Item
                name={"licenseType"}
                label="Status"
                initialValue={0}
                rules={[
                    {required: true, message: "'Status' is required"},
                ]}>
                <Select size={"large"}>
                    <Select.Option value={0}>Trial</Select.Option>
                    <Select.Option value={1}>Premium</Select.Option>
                    <Select.Option value={2}>Trial End</Select.Option>
                    <Select.Option value={3}>Premium End</Select.Option>
                </Select>
            </Form.Item>
        </Fragment>
    );
};

StepLicenseConfig.propTypes = {
    dateFormat: PropTypes.string,
    getInitialDate: PropTypes.func,
};

export default StepLicenseConfig;