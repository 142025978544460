import React, { memo } from 'react';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';

import NAMessage from '../../../../../../../NAMessage';

const { Paragraph, Title } = Typography;

const ValueCard = ({ title, value, color, enabled, className, ...rest }) => {
  const getContent = () => {
    if (enabled && value) {
      return (
        <Title style={{ color }} className="value-card-value">
          {`${value}%`}
        </Title>
      );
    }

    return (
      <NAMessage
        contentStyles={{ marginTop: '1.2em', textAlign: 'left', padding: 0 }}
      />
    );
  };

  return (
    <Card {...rest} className={`value-card ${className}`}>
      <Paragraph className="cardHeading">{title}</Paragraph>
      {getContent()}
    </Card>
  );
};

ValueCard.defaultProps = {
  enabled: true,
};

ValueCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  enabled: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(ValueCard);
