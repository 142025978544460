import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import OutcomeGridView from "./OutcomeGrid";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import OutcomeCellGroup from "./partials/OutcomeCellGroup";

import {
    makeInnovativeStudentMainReportLoading,
    makeInnovativeStudentMainReportOutcomesResponseData
} from "../../../../selectors/innovative_student/student.selector";

const ACCOUNT_MEMBERSHIP = "accountmembership";
const ADMIN_PATH = "/insights/admin/student/profile";

const OutcomeGridContainer = ({termId, studentId}) => {

    // selectors
    const courseOutcomes = useSelector(makeInnovativeStudentMainReportOutcomesResponseData);
    const courseReportLoading = useSelector(makeInnovativeStudentMainReportLoading);
    
    const parsedUrl = new URL(window.location.href);
    const actAs = parsedUrl.searchParams.get("actAs");
    const path = parsedUrl.pathname;

    const isAdminDashboardView = () => {
        return (actAs &&
            actAs.toLowerCase() === ACCOUNT_MEMBERSHIP &&
            path === ADMIN_PATH);
    };

    const assignOutcomeCellRenderer = (outcomes) => <OutcomeCellGroup outcomes={outcomes}/>

    const renderViewButtonCol = () => {
        return (
            {
                title: '',
                width: 100,
                fixed: 'right',
                render: (value, row) => <Button htmlType="button">
                    <Link to={isAdminDashboardView()
                        ?`/insights/admin/student/assignments/${studentId}/${row["course_id"]}/${termId}?current=outcomes`
                        : `/insights/student/assignments/${studentId}/${row["course_id"]}/${termId}?current=outcomes`
                    }
                    >
                        View
                    </Link>
                </Button>,
            }
        )
    }

    // column definition for the table
    const getColumnDefinition = () => {
        return [
            {
                title: "Course",
                dataIndex: "course_name",
                render: (value, row) => <MetaCellRenderer
                    title={value}
                    subtitle={row["course_id"]}
                    avatar={row["course_image"]}
                />,
            }, {
                title: "Outcomes",
                dataIndex: "outcomes",
                 render: (value, row) => assignOutcomeCellRenderer(value)
            },
        ];
    }
    return (
        <OutcomeGridView
            isLoading={courseReportLoading}
            columns={getColumnDefinition().concat(renderViewButtonCol())}
            dataSource={courseOutcomes}
        />
    )
}
OutcomeGridContainer.propTypes = {
    termId:PropTypes.string,
    studentId:PropTypes.string,
};



export default OutcomeGridContainer;