import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import _intersection from 'lodash/intersection';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchSidebarFilters,
  selectSidebarFilters,
} from '../../../../../actions/jobReadyGeneric/riskAnalysis/common.action';
import {
  getDashboardFiltersFetchData,
} from '../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector';
import {
  getOverviewChartsLoading,
  getOverviewStudentsLoading,
} from '../../../../../selectors/jobReadyGeneric/riskAnalysis/overview.selector';
import {
  getAcademicCardsLoading,
  getAcademicStudentsLoading,
  getAcademicOverviewLoading,
} from '../../../../../selectors/jobReadyGeneric/riskAnalysis/academic.selector';
import {
  getComplianceCardsLoading,
  getComplianceStudentsLoading,
  getComplianceOverviewLoading,
} from '../../../../../selectors/jobReadyGeneric/riskAnalysis/compliance.selector';
import {
  getFinancialCardsLoading,
  getFinancialStudentsLoading,
  getFinancialOverviewLoading,
} from '../../../../../selectors/jobReadyGeneric/riskAnalysis/financial.selector';

import FilterView from './FilterView';

import { getElementArrayFromObjectArray } from '../../../../../utils/general';

const FilterContainer = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [finalDataset, setFinalDataset] = useState({});

  // selectors
  const filterData = useSelector(getDashboardFiltersFetchData);
  const ovChartLoading = useSelector(getOverviewChartsLoading);
  const ovStudentLoading = useSelector(getOverviewStudentsLoading);

  const acCardLoading = useSelector(getAcademicCardsLoading);
  const acStudentLoading = useSelector(getAcademicStudentsLoading);
  const acOverviewLoading = useSelector(getAcademicOverviewLoading);

  const cmCardLoading = useSelector(getComplianceCardsLoading);
  const cmStudentLoading = useSelector(getComplianceStudentsLoading);
  const cmOverviewLoading = useSelector(getComplianceOverviewLoading);

  const fiCardLoading = useSelector(getFinancialCardsLoading);
  const fiStudentLoading = useSelector(getFinancialStudentsLoading);
  const fiOverviewLoading = useSelector(getFinancialOverviewLoading);

  const buttonLoading =
    ovChartLoading ||
    ovStudentLoading ||
    acCardLoading ||
    acStudentLoading ||
    acOverviewLoading ||
    cmCardLoading ||
    cmStudentLoading ||
    cmOverviewLoading ||
    fiCardLoading ||
    fiStudentLoading ||
    fiOverviewLoading;

  // effects
  useEffect(() => {
    dispatch(fetchSidebarFilters());
  }, []);

  useEffect(() => {
    if (filterData) {
      const years = getElementArrayFromObjectArray(
        filterData.enrolmentYears,
        'id',
      ) || [];
      const currentYear = new Date().getFullYear().toString();
      const selectedYear = years.includes(currentYear) ? currentYear : years.reverse()[0];

      filterForm.setFieldsValue({
        enrolmentYears: selectedYear,
      });

      // trigger force change on enrolment year filter
      onSectionValueChange({ enrolmentYears: selectedYear }, filterData);

      filterForm.submit();
    }
  }, [filterData]);

  const onSectionValueChange = (changedData, forceDataset = null) => {
    const configMapper = {
      enrolmentYears: ['states', 'qualificationLevels', 'courses'],
      states: ['qualificationLevels', 'courses'],
      qualificationLevels: ['courses'],
      courses: [],
    };
    const changedKey = Object.keys(changedData)[0];
    const changedValue = Object.values(changedData)[0];

    const effectedElements = configMapper[changedKey];

    let newDataset = {};

    const { enrolmentYears, states } = filterForm.getFieldsValue();

    // this will identify the changed key and apply cascading flow for filters
    switch (changedKey) {
      case 'enrolmentYears':
        newDataset = onYearChange(changedValue);
        break;
      case 'states':
        newDataset = onStateChange(changedValue, enrolmentYears);
        break;
      case 'qualificationLevels':
        newDataset = onLevelChange(changedValue, enrolmentYears, states);
        break;
      default:
        newDataset = {};
    }

    if (forceDataset) {
      setFinalDataset({ ...forceDataset, ...newDataset });
    } else {
      setFinalDataset({ ...finalDataset, ...newDataset });
    }

    // set form data - set default selected items when section changes
    const formData = {
      ...(effectedElements.includes('states') && {
        states: getElementArrayFromObjectArray(newDataset.states, 'id'),
      }),
      ...(effectedElements.includes('qualificationLevels') && {
        qualificationLevels: getElementArrayFromObjectArray(
          newDataset.qualificationLevels,
          'id',
        ),
      }),
      ...(effectedElements.includes('courses') && {
        courses: getElementArrayFromObjectArray(newDataset.courses, 'id'),
      }),
    };

    filterForm.setFieldsValue(formData);
  };

  // helper filters for the cascading flow. this will generate the valid filters for relevant filter sections
  const filterStates = year =>
    filterData.states.filter(i => i.enrolmentYears.includes(year));
  const filterLevels = (year, states) =>
    filterData.qualificationLevels.filter(
      i =>
        i.enrolmentYears.includes(year) &&
        _intersection(states, i.states).length > 0,
    );
  const filterCourses = (year, states, levels) =>
    filterData.courses.filter(
      i =>
        i.enrolmentYears.includes(year) &&
        _intersection(states, i.states).length > 0 &&
        _intersection(levels, i.qualificationLevels).length > 0,
    );

  const onYearChange = year => {
    // apply cascading flow when year changes
    console.log(year);
    const states = filterStates(year);
    const stateIds = getElementArrayFromObjectArray(states, 'id');
    const levels = filterLevels(year, stateIds);
    const levelIds = getElementArrayFromObjectArray(levels, 'id');
    const courses = filterCourses(year, stateIds, levelIds);

    return { states, qualificationLevels: levels, courses };
  };

  const onStateChange = (states, year) => {
    // apply cascading flow when state changes
    const levels = filterLevels(year, states);
    const levelIds = getElementArrayFromObjectArray(levels, 'id');
    const courses = filterCourses(year, states, levelIds);

    return { qualificationLevels: levels, courses };
  };

  const onLevelChange = (levels, year, states) => {
    // apply cascading flow when level changes
    const courses = filterCourses(year, states, levels);

    return { courses };
  };

  const onFilterSelect = values => {
    dispatch(selectSidebarFilters(values));
  };

  return (
    <FilterView
      enrolmentYears={finalDataset && finalDataset.enrolmentYears}
      states={finalDataset && finalDataset.states}
      qualificationLevels={finalDataset && finalDataset.qualificationLevels}
      courses={finalDataset && finalDataset.courses}
      filterForm={filterForm}
      filterSubmit={onFilterSelect}
      onSectionValueChange={onSectionValueChange}
      buttonLoading={buttonLoading}
    />
  );
};

export default FilterContainer;
