import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Collapse, Button, Form } from 'antd';
import { ArrowRightOutlined, ArrowDownOutlined } from '@ant-design/icons';

import FilterSection from './FilterSection';

const { Panel } = Collapse;
const { Title, Text } = Typography;
const defaultActiveSections = ['1_year']

const FilterView = ({
  enrolmentYears,
  states,
  qualificationLevels,
  courses,
  filterForm,
  filterSubmit,
  onSectionValueChange,
  buttonLoading,
}) => {
  const renderPanelTitle = name => <Text strong>{name}</Text>;

  return (
    <div>
      <Title level={5} style={{ color: '#6244BB' }}>
        Filters
      </Title>
      <Form
        form={filterForm}
        onFinish={filterSubmit}
        onValuesChange={changedValues => onSectionValueChange(changedValues)}
      >
        <Collapse
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? <ArrowDownOutlined /> : <ArrowRightOutlined />
          }
          defaultActiveKey={defaultActiveSections}
          bordered={false}
        >
          <Panel header={renderPanelTitle('Enrolment Year')} key="1_year">
            <Form.Item
              noStyle
              name="enrolmentYears"
              valuePropName="selectedOptions"
            >
              <FilterSection
                options={enrolmentYears}
                isMulti={false}
                mode="radio"
                searchEnabled={false}
              />
            </Form.Item>
          </Panel>
          <Panel header={renderPanelTitle('State')} key="2_state" forceRender>
            <Form.Item noStyle name="states" valuePropName="selectedOptions">
              <FilterSection
                options={states}
                mode="checkbox"
                searchEnabled={false}
                isMulti
              />
            </Form.Item>
          </Panel>
          <Panel
            header={renderPanelTitle('Qualification Level')}
            key="3_level"
            forceRender
          >
            <Form.Item
              noStyle
              name="qualificationLevels"
              valuePropName="selectedOptions"
            >
              <FilterSection
                options={qualificationLevels}
                mode="checkbox"
                searchEnabled
                isMulti
              />
            </Form.Item>
          </Panel>
          <Panel header={renderPanelTitle('Course')} key="4_course" forceRender>
            <Form.Item noStyle name="courses" valuePropName="selectedOptions">
              <FilterSection
                options={courses}
                mode="checkbox"
                searchEnabled
                isMulti
              />
            </Form.Item>
          </Panel>
        </Collapse>
        <Button
          loading={buttonLoading}
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
        >
          Apply
        </Button>
      </Form>
    </div>
  );
};

FilterView.propTypes = {
  enrolmentYears: PropTypes.array,
  states: PropTypes.array,
  qualificationLevels: PropTypes.array,
  courses: PropTypes.array,
  filterForm: PropTypes.any,
  filterSubmit: PropTypes.func,
  onSectionValueChange: PropTypes.func,
  buttonLoading: PropTypes.bool,
};

export default FilterView;
