import React, {Fragment} from 'react';
import Loader from "../../layout/Loader";
import PieChart from "../../charts/PieChart";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

const SideChartsView = ({isLoading, configs}) => {
    return (
        <Fragment>
            <Loader isLoading={isLoading}>
                <Row>
                    {
                        configs.map(config => (
                                <Col md={12} xl={24}>
                                    <h5>{config.title}</h5>
                                    <div className="studentSumPie">
                                        <PieChart chartOptions={config.chartOptions}/>
                                    </div>
                                </Col>
                            )
                        )
                    }
                </Row>
            </Loader>
        </Fragment>
    );
};

SideChartsView.propTypes = {
    isLoading: PropTypes.bool,
    configs: PropTypes.array,
};

export default SideChartsView;