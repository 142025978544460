import React, {Fragment, useState} from "react";
import {Avatar, Button, Card, Col, Divider, Form, Input, notification, Row, Spin} from "antd";
import {KeyOutlined, LoadingOutlined, CheckCircleTwoTone} from "@ant-design/icons";
import {useParams} from "react-router-dom";

import "../../../../../assets/css/onboarding/common.css";

import ApiService from "../../../../../services/onboarding";

const ApiKeyUpdateSuccessWidget = () => {
    return (
        <Card>
            <div style={{textAlign: "center"}}>
                <div style={{ fontSize: "200px" }}>
                    <CheckCircleTwoTone twoToneColor="#52c41a"/>
                </div>
                <h1>Now Canvas Integration is Completed</h1>
                <p>Please Refresh Your Browser</p>
            </div>
        </Card>
    );
};

const AddApiKey = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const {token, accountId} = useParams();

    const onSuccess = (response) => {
        setIsLoading(false);
        !response.error && setUpdateSuccess(true)
    }
    const onError = () => {
        setIsLoading(false);
        notification.error({
            message: "Something Went Wrong!",
            description: "Please try again",
            duration: 2
        });
    }
    const onFinish = ({apikey}) => {
        setIsLoading(true);
        ApiService.OnboardingConsumer.addApiKey(token, accountId, apikey, onSuccess, onError)
    }
    const renderAddApiForm = () => {
        return (
            <Fragment>
                <Spin spinning={isLoading} indicator={<LoadingOutlined/>}>
                    <Form
                        name="addApiKey"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="apikey"
                            label="Api Key"
                            rules={[{ required: true, message: 'Please input API key!' },]}
                        >
                            <Input size={"large"}/>
                        </Form.Item>

                        <Row justify="end">
                            <Button type="primary" htmlType="submit">Add</Button>
                        </Row>
                    </Form>
                </Spin>
            </Fragment>
        )
    }
    const renderContent = () => {
        return (
            <Fragment>
                {updateSuccess ? <ApiKeyUpdateSuccessWidget/> :
                    <Row gutter={75} className={"layout-row"} wrap={true}>
                        <Col flex="1 1 50%" className={"layout-video"}>

                            <h3>Welcome to Tentacle Insights</h3>
                            <p>
                                To complete the integration, please generate an API key and enter the same in the given input box. Please refer below short video which demonstrates how you can create an API key in Canvas.
                            </p>
                            <div className="videoCover">
                                <iframe
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/eZJJN5rs48U"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                            </div>
                        </Col>
                        <Col flex="1 1 50%" className={"layout-form"}>
                            <Card className={"layout-card"}>
                                <Row justify="center">
                                    <Col span={8} offset={4}>
                                        <Avatar style={{backgroundColor: '#1890ff'}} size={64} icon={<KeyOutlined/>}/>
                                    </Col>
                                    <Divider/>
                                </Row>

                                {renderAddApiForm()}
                            </Card>
                        </Col>
                    </Row>
                }
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={"wizardContainr"}>
                {renderContent()}
            </div>
        </Fragment>
    )
}

export default AddApiKey;