import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/layout/Loader";
import DashboardGrid from "./DashboardGrid";
import menuData from "../../../layout/dashboardManagement/menuData";
import { setBreadcrumb, setMenuSelected } from "../../../../actions/dashboardManagement/layout.action";
import { fetchDashboards, saveDashboard } from "../../../../actions/dashboardManagement/dashboard.action";
import {
    getDashboardListData,
    getShareDashboardLoading,
    getSaveDashboardLoading,
} from "../../../../selectors/dashboardManagement/dashboard.selector";
import {
    clearCourseData,
    clearCourseReport,
    clearCoursesData,
    clearTermsData,
    resetAdminLevelData,
    resetClusterSection,
} from "../../../../actions/innovative/course.action";
import { clearStudentDetails } from "../../../../actions/innovative_student/student.action";

const DashboardCardListContainer = () => {
    const dispatch = useDispatch();

    // selectors
    const dashboards = useSelector(getDashboardListData);
    const shareDashboardLoading = useSelector(getShareDashboardLoading);
    const saveDashboardLoading = useSelector(getSaveDashboardLoading);

    const breadcrumbItems = [
        {
            key: 'admin',
            section: "Admin",
        }
    ]

    const resetCourseData = () => {
        dispatch(resetClusterSection());
        dispatch(clearTermsData());
        dispatch(clearCourseData());
        dispatch(clearCourseReport());
    }

    //set breadcrumb
    useEffect(() => {
        dispatch(setBreadcrumb(breadcrumbItems));
        dispatch(resetAdminLevelData());
        dispatch(clearStudentDetails());
        dispatch(clearCoursesData());
        resetCourseData();
    }, [])

    // set menu item
    useEffect(() => {
        dispatch(setMenuSelected(menuData.manageDashboard.key));
    }, [])

    // fetch dashboards
    useEffect(() => {
        setTimeout(() => dispatch(fetchDashboards()), 1000);
    }, [])

    const saveDashboardData = (dashboardData) => {
        dispatch(saveDashboard(dashboardData));
    }

    return (
        <div className="in-container">
            <Loader isLoading={dashboards.isLoading || shareDashboardLoading || saveDashboardLoading}></Loader>
            <h4>Dashboards</h4>
            <DashboardGrid dashboards={dashboards.data} saveDashboard={saveDashboardData} />
        </div>
    )
}

export default DashboardCardListContainer;