import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Select, Form, Input, Checkbox, Row } from 'antd';
import { get as _get } from 'lodash';
import {
  makeInnovativeAssignmentFilterData,
  makeInnovativeAssignmentsLoading,
} from '../../../../selectors/innovative/assignment.selector';
import {
  makeInnovativeAssignmentsLoading as makeStudentAssignmentsLoading
} from '../../../../selectors/innovative_student/assignment.selector';
import {
  ASSIGNMENT_FILTERS,
  FILTER_ASSIGNMENT_GROUP,
  FILTER_MODULES,
} from '../../../../utils/innovative/constants';
import { useLocation } from 'react-router';

const { Item } = Form;
const { Group } = Input;
const { Option } = Select;
const maxTags = 1;

const TaskFilterSection = props => {
  const { applyFilter, setCurrentFilter } = props;
  const { state } = useLocation();
  const moduleData = state && state.moduleData;
  const fromModuleProgress = state && state.fromModuleProgress;

  const assignmentFilterData = useSelector(makeInnovativeAssignmentFilterData);
  const assignmentsLoading = useSelector(makeInnovativeAssignmentsLoading);
  const studentAssignmentsLoading = useSelector(makeStudentAssignmentsLoading);
  const { filterAssignmentGroups, filterModules } = assignmentFilterData;
  const defaultSelected = filterAssignmentGroups.map(item => item.id);

  const [filterType, setFilterType] = useState(FILTER_ASSIGNMENT_GROUP);
  const [selectedFilters, setSelectedFilters] = useState(defaultSelected);
  const [currentOptions, setCurrentOptions] = useState(defaultSelected);

  useEffect(() => {
    if (!moduleData) {
      setCurrentFilter(FILTER_ASSIGNMENT_GROUP);
      setFilterType(FILTER_ASSIGNMENT_GROUP);
      setSelectedFilters(defaultSelected);
      setCurrentOptions(defaultSelected);
    }
  }, [JSON.stringify(defaultSelected)]);

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  }

  useEffect(() => {
    if (moduleData?.length || fromModuleProgress) {
      setCurrentFilter(FILTER_MODULES);
      setFilterType(FILTER_MODULES);
      const availableModules =  filterModules.map(item => item.id);
      const tempModuleArray = removeDuplicates([...moduleData, ...availableModules]);
      tempModuleArray.length && applyFilter(FILTER_MODULES, [...tempModuleArray]);
      setSelectedFilters([...tempModuleArray]);
      setCurrentOptions([...tempModuleArray]);
    }
  }, [filterModules]);

  const onFilterTypeChanged = value => {
    setFilterType(value);
    setCurrentFilter(value);

    let selected = defaultSelected;

    if (value === FILTER_MODULES) {
      selected = filterModules.map(item => item.id);
    }
    setSelectedFilters(selected);
    setCurrentOptions(selected);
  };

  const getCurrentOptions = () => {
    let currentFilters = filterAssignmentGroups;

    if (filterType === FILTER_MODULES) {
      currentFilters = filterModules;
    }

    return currentFilters.map(filter => {
      const { id } = filter
      return (
        <Option value={id} key={`${filterType}_${id}`}>
          <Checkbox
            checked={selectedFilters.includes(id)}
            style={{ marginRight: '10px' }}
          />
          {filter.name}
        </Option>
      );
    });
  };

  const onFilterOptionChanged = values => {
    setSelectedFilters(values);
  };

  const onSelectAll = (event, checked) => {
    event.stopPropagation();

    if (checked) {
      setSelectedFilters([]);
      return;
    }

    setSelectedFilters(currentOptions);
  };

  const getDropdownRenderer = currentNode => {
    if (!currentOptions.length) return currentNode;

    const checked = currentOptions.length === selectedFilters.length;
    let indeterminate = !!selectedFilters.length;

    if (checked) {
      indeterminate = false;
    }

    return (
      <>
        <Row
          className="task-filter-select-all"
          onClick={event => onSelectAll(event, checked)}
        >
          <Checkbox indeterminate={indeterminate} checked={checked} />
          <span>Select All</span>
        </Row>
        {currentNode}
      </>
    );
  };

  const getTagRenderer = tagProps => {
    const labelValue = _get(tagProps, 'label[1]', '');
    const label =
      labelValue && selectedFilters.length > 1 ? `${labelValue},` : labelValue;

    return <p className="task-filter-custom-tag">{label}</p>;
  };

  const onApply = () => {
    if (applyFilter) {
      applyFilter(filterType, selectedFilters);
    }
  };

  return (
    <div className="task-filter-group">
      <Item label="Filter By">
        <Group compact>
          <Item>
            <Select
              options={ASSIGNMENT_FILTERS}
              onChange={onFilterTypeChanged}
              value={filterType}
              placeholder="Select type"
              style={{ minWidth: 200 }}
            />
          </Item>
          <Item>
            <Select
              value={selectedFilters}
              placeholder="Select value"
              style={{ minWidth: 200 }}
              mode="multiple"
              menuItemSelectedIcon={() => null}
              onChange={onFilterOptionChanged}
              dropdownRender={getDropdownRenderer}
              maxTagCount={maxTags}
              tagRender={getTagRenderer}
              showSearch={false}
            >
              {getCurrentOptions()}
            </Select>
          </Item>
          <Button
            type="primary"
            style={{ marginLeft: '10px' }}
            disabled={
              !selectedFilters.length ||
              assignmentsLoading ||
              studentAssignmentsLoading
            }
            onClick={onApply}
          >
            Apply
          </Button>
        </Group>
      </Item>
    </div>
  );
};

TaskFilterSection.propTypes = {
  applyFilter: PropTypes.func,
};

export default TaskFilterSection;
