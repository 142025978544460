import React from "react";
import {Row, Col, Typography, Input, Form} from "antd";

const {Title} = Typography;

const ScoreToGradeConversion = () => {
    const renderForm = () => {
        return (
                <div className="form-main" style={{marginTop: '6px' }}>
                    <Form.Item
                    className="form-item"
                    style={{width: 'calc(30% - 12px)' }}
                >
                    <Input/>
                </Form.Item>
                <span
                    style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}
                >
                +
              </span>
                <Form.Item className="form-item" style={{width: 'calc(30% - 12px)' }}>
                    <Input/>
                </Form.Item>
                </div>

        )
    }

    const renderInnerCard = (value) => {
        return (
            <div className="score2grade-card">
                <Row justify="space-between" align="center">
                    <Col span={8} className="title">{value}</Col>
                    <Col span={16}>
                        {renderForm()}
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div>
            <Title level={5}>Score to grade conversion</Title>
            <Row gutter={16}>
                <Col span={12}>
                    {renderInnerCard("A+")}
                </Col>
                <Col span={12}>
                    {renderInnerCard("A")}
                </Col>
            </Row>
            <br/>
            <Row gutter={16}>
                <Col span={12}>
                    {renderInnerCard("B")}
                </Col>
                <Col span={12}>
                    {renderInnerCard("C")}
                </Col>
            </Row>
        </div>
    )

}

export default ScoreToGradeConversion;