import {
  SET_VTT_LAYOUT_BREADCRUMB,
  SET_VTT_LAYOUT_MENU_SELECTED,
} from '../../../constant/actionTypes';

const layoutReducerDefaultState = {
  menu: {
    selected: false,
  },
  breadcrumb: {
    segments: [],
  },
};

const layoutReducer = (state = layoutReducerDefaultState, action) => {
  switch (action.type) {
    case SET_VTT_LAYOUT_BREADCRUMB:
      return {
        ...state,
        breadcrumb: {
          segments: action.payload,
        },
      };
    case SET_VTT_LAYOUT_MENU_SELECTED:
      return {
        ...state,
        menu: {
          selected: action.payload,
        },
      };
    default:
      return state;
  }
};

export default layoutReducer;
