import React from "react";
import {Form, Input, Button, DatePicker, TreeSelect, Select} from "antd";
import moment from 'moment';
import PropTypes from "prop-types";
import {SearchOutlined} from "@ant-design/icons";

import CustomRangePicker from "../common/CustomRangePicker";

const {Option} = Select;
const { RangePicker } = DatePicker;

const FilterForm = ({form, onSubmit, setDisplayRows, onSearchChange, searchVal}) => {
    const onChangeDisplayRows = (e) => {
        setDisplayRows(e)
    }

    const selectData = [
        {
            title: 'TASS Web',
            value: 'tass',
            key: 'tass',
        },
        {
            title: 'Job Ready',
            value: 'jr',
            key: 'jr',
        },
        {
            title: 'ACC',
            value: 'acc',
            key: 'acc',
        },
    ]

    const renderRangePicker = () => (
        <CustomRangePicker label="Date Range" required={true}/>
    )

    return (
        <div style={{right:0}} className="sale-admin-filters">

            <div className="school-search-container">
                <Input
                    value={searchVal}
                    onChange={(e) => onSearchChange(e.target.value)}
                    style={{ width: 250, margin: "3px 20px 10px 20px"}}
                    placeholder="Search"
                    size="middle"
                    prefix={<SearchOutlined/>}
                />
            </div>
            <Form
                layout="inline"
                form={form}
                onFinish={onSubmit}
            >
                {renderRangePicker()}
                <Form.Item label="Show rows" name="rows">
                    <Select style={{ width: 120 }} onChange={onChangeDisplayRows}>
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="75">75</Option>
                        <Option value="100">100</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Apply</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

FilterForm.propTypes = {
    form: PropTypes.object,
    setDisplayRows: PropTypes.func,
    onSubmit: PropTypes.func
}

export default FilterForm;

