import React, { useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { getUserManagementUserList } from "../../../../selectors/userManagement/userManagement.selector";
import Search from "../../../innovative/common/form/Search";

const RenderTableHeader = ({ searchString, setSearchString, setTableUserListArray }) => {
  const userList = useSelector(getUserManagementUserList);

  useEffect(() => {
    if (searchString.length && userList.length) {
      const filterUsersArray = userList.filter(
        user => {
          if (
            (user.user_name.toLowerCase().includes(searchString.toLowerCase())) ||
            (user.sis_id.toString() === searchString) ||
            (user.user_id.toString() === searchString)
          ) {
            return true;
          }
          return false;
        });
      setTableUserListArray(filterUsersArray);
    } else {
      setTableUserListArray(userList);
    }
  }, [searchString]);

  const onChange = e => {
    const searchValue = e.target.value;
    setSearchString(searchValue);
  };

  return (
    <div className="userNameHeader">
      <h4>Users</h4>
      <Search
        onSearch={onChange}
        placeholder="Search by username, user ID or Sis ID"
        addonAfter={
          <SearchOutlined
            style={{
              fontSize: 16,
              color: '#1890ff',
            }}
          />
        }
      />
    </div>
  )
}

export default RenderTableHeader;
