import React from 'react';

const Maintenance = () => (
  <>
    <div className="page-wrapper">
      <div className="error-wrapper maintenance-bg">
        <div className="container">
          <ul className="maintenance-icons">
            <li>
              <i className="fa fa-cog"></i>
            </li>
            <li>
              <i className="fa fa-cog"></i>
            </li>
            <li>
              <i className="fa fa-cog"></i>
            </li>
          </ul>
          <div className="maintenance-heading">
            <h2 className="headline">MAINTENANCE</h2>
          </div>
          <h4 className="sub-content">
            Something awesome will happen here. Please check later. <br />
            Thank You For Patience
          </h4>
        </div>
      </div>
    </div>
  </>
);

export default Maintenance;
