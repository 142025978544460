import serviceMaker from "../service";
import session from "../../../session";
import localStore from "../../../localStorage";
import {generateCorrelationIdHeader} from "../../serviceUtils";

// THIS IS A REDIRECTION HANDLER FOR THIS SERVICE.
const redirectionHandler = (status) => {
    switch (status){
        case 401:
        case 403:
            localStore.removeLsSlim(localStore.keys.authMeta)
            setTimeout(() => window.location.href = `${window.location.origin}/tenant/login`, 1000);
            return false
        default:
            return false
    }
}

const make = async ({headers, ...requestOptions}) => {
    const customHeaders = {...generateCorrelationIdHeader(), ...headers}
    return new Promise((resolve, reject) => {
        serviceMaker.make({
            ...{headers:customHeaders, ...requestOptions},
            metaKey: session.keys.vwDealershipMeta,
            // THIS IS HOW WE ADD REDIRECTION HANDLER TO THE SERVICE.
            redirectionHandler: (status) => redirectionHandler(status),
        }).then(response => resolve(response)).catch(error => reject(error))
    })
}

const service = {
    make,
};

export default service;