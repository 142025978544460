import React, { Fragment } from "react";
import { Card, Col, Form, Input, Row, Divider, Avatar, Button, Typography } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { licenseManagerResetPassword } from "../../../actions/consumer.action";
import { getLicenseManagerResetPasswordLoading } from "../../../selectors/consumer.selector";
import { onSuccess } from "../../../utils/notificationHandler";
import { queryStringToObject } from "../../../utils/general";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../common/layout/Loader";

const SignInContainer = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const search = queryStringToObject(useLocation().search);

  // selectors
  const isLoading = useSelector(getLicenseManagerResetPasswordLoading);

  const onSubmitHandler = (values) => {
    //dispatch password reset 
    dispatch(licenseManagerResetPassword({ userId: search.uid || null, token: search.t || null, ...values }, () => {
      onSuccess("Password has been reset successfully!");
      history.push("/setup/login");
    }))
  }
  const renderContent = () => {
    return (
      <Fragment>
        <Loader isLoading={isLoading} />
        <Row gutter={75} className={"layout-row"} wrap={true}>
          <Col flex="1 1 50%" className={"layout-video"}>
            <h3>Partner Up With Octopus.</h3>
            <h4>Empower schools. Make a difference!</h4>
            <Typography.Paragraph type="secondary" className="intro">
              Octopus has just the right analytic tools to help principals, parents and CEO's improve
              the quality and the efficiency of education. Our platform processes raw data of students,
              subjects and scores to make meaningful insights to shape the future of the next generation.
              As an Octopus Partner, you help schools experience the Octopus platform and grow along with us.
            </Typography.Paragraph>
            <div className="videoCover">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/unPT_CtL2js"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen />
            </div>
          </Col>
          <Col flex="1 1 50%" className={"layout-form"} >
            <Card className={"signin-card"}>
              <Row justify="center" className="partner-portal-header">
                <Col span={8} offset={5}>
                  <Avatar style={{ backgroundColor: '#5D5FE9' }} size={64} icon={<UserOutlined />} />
                </Col>
                <Divider />
              </Row>
              {
                renderResetPasswordForm()
              }
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  };

  const renderResetPasswordForm = () => {
    return (
      <Fragment>
        <div className="partner-portal-form">
          <div className="partner-portal-form-heading">Reset your password</div>
          <div className="partner-portal-form-sub-heading">Enter a new password for your account. we highly recommend you create a unique password - one that you dont use for any other websites.</div>
          <Divider />
          <Form
            name="resetPassword"
            layout="vertical"
            initialValues={{ remember: false }}
            onFinish={onSubmitHandler}
            autoComplete="off"
          >
            <Form.Item
              label={<Typography.Text strong>New Password</Typography.Text>}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input new password!',
                },
                {
                  min: 10,
                  message: 'Password should have at least 10 characters',
                },
                {
                  pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*\\s)(?=.*[!,@,#,$,%,^,&,*,(,),])",
                  message: "Password should contain at least one uppercase, lowercase letter, digit, symbol and cannot contain whitespaces",
                },
              ]}
            >
              <Input.Password size="large" visibilityToggle />
            </Form.Item>
            <Form.Item
              label={<Typography.Text strong>Confirm Password</Typography.Text>}
              name="re-password"
              rules={[
                {
                  required: true,
                  message: 'Please enter password again!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            ><Input.Password size="large" visibilityToggle />
            </Form.Item>
            <Row style={{ paddingTop: '5px' }} justify="end">
              <Button type="primary" htmlType="submit">Reset Password</Button>
            </Row>
          </Form>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="wizardContainr signin-container">
        {renderContent()}
      </div>
    </Fragment>

  )
}

export default SignInContainer;