import { numberFormat } from "highcharts";

export default {
  numberFormat: (x,mode) => {
    if(x || x == 0){
      if(typeof mode !== 'undefined'){
        if(x == 0)
          return '0.00';
        return x.toFixed(2);
      }else{
        if(x == 0)
        return '0.00';
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } 
  },

  setMetricSuffix: (num, format="K", digits= 1) => {
    if(format == 'AF')
      return numberFormat(num);
    
    // let si = [{ value: 1, symbol: "" }];
    let si = [];
    if(format == 'K')
      si.push({ value: 1E3, symbol: "k" });
    if(format == 'M'){
      si.push({ value: 1E6, symbol: "M" });
      digits = 3;
    }
      // { value: 1E9, symbol: "G" },
      // { value: 1E12, symbol: "T" },
      // { value: 1E15, symbol: "P" },
      // { value: 1E18, symbol: "E" }

    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },
  nFormatter: (num) => {
    if(typeof num !== 'undefined'){
      let format;
      if (num < 0) {
        format =  "-$" +(Math.abs(num) / 1e6).toFixed(2) + "M";
      } else {
        format = '$' + (num / 1e6).toFixed(2) + "M";
      }
      return format;
    }
  },
  validateSpecialCharacter: (value) => {
    if(value !== '' && value !== null && typeof(value) != "undefined"){
      var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
      return regex.test(value);
    }
  },
  numberValidation: (number) => {
    if(number !== '' && number !== null && typeof(number) != "undefined"){
      var regex = /[0-9]/g;
      return regex.test(number);
    }
  },
  yearTermValidation: (year) => {
    if(year !== '' && year !== null && typeof(year) != "undefined"){
      if(year.toString().length === 4){
        var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
        return regex.test(year);
      }
    }
  },
  validateSpecialCharacterArray: (arrayStore) => {
    let filteredArr = [];

    var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    arrayStore.forEach(element => {
      var regexResult = regex.test(element.schoolId);
      
      if(!regexResult){
        filteredArr.push(element);
      }
    });
    return filteredArr;
  }
}