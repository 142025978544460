import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _unionBy from 'lodash/unionBy';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Divider,
  Input,
  List,
  Row,
  Space,
  Typography,
  Card,
  Form,
  Select,
  InputNumber,
} from 'antd';
import {
  DeleteOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { range as _range } from 'lodash';

import {
  getCategoryOptionSelectedLoading,
  getCategoryOptionSelectedData,
} from '../../../../../../../selectors/vetTrack/analysis.selector';
import { fetchRcCategoryOptions } from '../../../../../../../actions/vetTrack/analysis/settings/riskCalculations.action';

import SubDrawer from '../subDrawer/SubDrawer';
import VetDrawer from '../../../../common/Drawer';
import { onError } from '../../../../../../../utils/notificationHandler';

import {
  calculationModes,
  maxPercentage,
  maxFactorValue,
} from '../../../../../../../constant/vetTrack/analysis';
import {
  rangeOperators,
  greaterOperators,
  lessOperators
} from '../../../../../../../constant/vetTrack/settings';

const { Option } = Select;
const MainDrawer = ({ drawerConfig, onFinish }) => {
  const [drawerForm] = Form.useForm();
  const [subDrawerForm] = Form.useForm();
  const dispatch = useDispatch();
  const notPercentage = _get(drawerConfig, 'drawerMeta.factor.notPercentage', false);

  const [showForm, setShowForm] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subDrawerVisible, setSubDrawerVisible] = useState(false);
  const [selectedSubDrawerMeta, setSelectedSubDrawerMeta] = useState({});

  // selectors
  const optionsLoading = useSelector(getCategoryOptionSelectedLoading);
  const options = useSelector(getCategoryOptionSelectedData);

  // effects
  useEffect(() => {
    // update form data
    if (drawerConfig?.drawerMeta?.id) {
      const { factor, mode, id } = drawerConfig.drawerMeta;
      const selctedValue = factor.scores.find(item => item.id === id);
      setSelectedScore(selctedValue);

      // set category options to local state
      if (mode === calculationModes.category) {
        setSelectedOptions(selctedValue.options);
      }

      drawerForm.setFieldsValue({
        [`${mode}-risk`]: selctedValue.risk,
        [`${mode}-logics`]: selctedValue.logics,
        [`${mode}-groupName`]: selctedValue.name,
      });
    }
  }, [drawerConfig.drawerMeta.id]);

  useEffect(() => {
    // set selected options for sub drawer
    if (selectedOptions) {
      const { mode } = drawerConfig.drawerMeta;
      if (mode === calculationModes.category) {
        subDrawerForm.setFieldsValue({
          options: selectedOptions.map(item => item.id),
        });
      }
    }
  }, [selectedOptions, subDrawerVisible]);

  const showSubDrawer = () => {
    // fetch category options
    const { factor } = drawerConfig.drawerMeta;
    dispatch(fetchRcCategoryOptions({ factor }));
    setSubDrawerVisible(true);
  };
  const onCloseSubDrawer = () => {
    subDrawerForm.resetFields();
    setSubDrawerVisible(false);
  };

  const onSelectOptionsSubDrawer = selectedDrawerOptions => {
    setSelectedOptions(selectedDrawerOptions);
  };

  const filterOptionDataset = (backendOptions, syncedOptions) =>
    // get the dataset sent from backend and sync, and filter out the modified options with relevant to backend dataset
    _unionBy(backendOptions, syncedOptions, 'id');
  const preProcessDataset = values => {
    // process dataset for update settings

    // check if the current record is new or existing one
    const { factor, mode, id } = drawerConfig.drawerMeta;

    if (
      mode === calculationModes.condition &&
      (!values[`${mode}-logics`] || !values[`${mode}-logics`].length)
    ) {
      throw onError('Logics are required');
    }

    if (
      mode === calculationModes.category &&
      (!selectedOptions || !selectedOptions.length)
    ) {
      throw onError('Options are required');
    }

    let newDataset;
    if (selectedScore) {
      // existing record: update the selected score's data
      switch (mode) {
        case calculationModes.condition:
          newDataset = {
            ...factor,
            scores: factor.scores.map(item => {
              if (id === item.id) {
                return {
                  ...item,
                  risk: values[`${mode}-risk`],
                  logics: values[`${mode}-logics`],
                };
              }
              return item;
            }),
          };
          break;
        case calculationModes.category:
          newDataset = {
            ...factor,
            scores: factor.scores.map(item => {
              if (id === item.id) {
                return {
                  ...item,
                  risk: values[`${mode}-risk`],
                  name: values[`${mode}-groupName`],
                  options: filterOptionDataset(item.options, selectedOptions),
                };
              }
              return item;
            }),
          };
          break;
        default:
          newDataset = null;
      }
    } else {
      // newly created record: add new score to the factor
      switch (mode) {
        case calculationModes.condition:
          newDataset = {
            ...factor,
            scores: [
              ...factor.scores,
              {
                id: uuidv4(),
                risk: values[`${mode}-risk`],
                logics: values[`${mode}-logics`],
                new: true,
              },
            ],
          };
          break;
        case calculationModes.category:
          newDataset = {
            ...factor,
            scores: [
              ...factor.scores,
              {
                id: uuidv4(),
                risk: values[`${mode}-risk`],
                name: values[`${mode}-groupName`],
                options: selectedOptions,
                new: true,
              },
            ],
          };
          break;
        default:
          newDataset = null;
      }
    }
    onFinish(newDataset);
  };

  const onDrawerFormSubmit = () => {
    drawerForm
      .validateFields()
      .then(values => {
        preProcessDataset(values);
        onDrawerClose();
      })
      .catch(() => {});
  };

  const onDrawerClose = () => {
    subDrawerForm.resetFields();
    drawerForm.resetFields();
    drawerForm.setFieldsValue({
      [`${calculationModes.condition}-logics`]: undefined,
    });
    setSelectedScore(null);
    setSelectedOptions([]);
    setShowForm(false);
    drawerConfig.onClose();
  };

  const onOptionDelete = id => {
    const filteredOptions = selectedOptions.filter(i => i.id !== id);
    setSelectedOptions(filteredOptions);
  };

  const renderAddLogicForm = () => (
    <Form.List name={`${drawerConfig.drawerMeta?.mode}-logics`}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{ display: 'flex', marginBottom: '10px' }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'new']}
                initialValue={false}
                noStyle
              />
              <Form.Item
                {...restField}
                name={[name, 'name']}
                initialValue={drawerConfig?.drawerMeta?.factor.name}
                rules={[
                  { required: true, message: 'Missing name' },
                  {
                    validator: (rule, value) =>
                      scoreValidator(rule, value, key),
                    message: '',
                  },
                ]}
              >
                <Input readOnly size="small" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'condition']}
                rules={[
                  { required: true, message: 'Missing condition' },
                  {
                    validator: (rule, value) =>
                      scoreValidator(rule, value, key),
                    message: '',
                  },
                ]}
                initialValue=">"
              >
                <Select size="small" style={{ width: 60 }}>
                  <Option value="=">=</Option>
                  <Option value="!=">!=</Option>
                  <Option value=">">&gt;</Option>
                  <Option value=">=">&gt;=</Option>
                  <Option value="<">&lt;</Option>
                  <Option value="<=">&lt;=</Option>
                </Select>
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'value']}
                rules={[
                  { required: true, message: 'Missing value' },
                  {
                    validator: (rule, value) =>
                      scoreValidator(rule, value, key),
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  size="small"
                  min={0}
                  max={ notPercentage ? maxFactorValue : maxPercentage }
                />
              </Form.Item>
              <CloseCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <div style={{ textAlign: 'end' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => add({ new: true })}
                icon={<PlusOutlined />}
              >
                Add Logic
              </Button>
            </div>
          </Form.Item>
        </>
      )}
    </Form.List>
  );

  const renderDrawerFooter = () => (
    <div style={{ textAlign: 'end' }}>
      <Space>
        <Button onClick={onDrawerClose}>Cancel</Button>
        <Button type="primary" onClick={onDrawerFormSubmit}>
          Save
        </Button>
      </Space>
    </div>
  );

  const renderConditionDrawer = () => {
    const logics = selectedScore?.logics;
    return (
      <div>
        <Typography.Paragraph strong>Risk(%):</Typography.Paragraph>
        <Form.Item
          name={`${drawerConfig.drawerMeta?.mode}-risk`}
          rules={[{ required: true, message: 'Risk is required' }]}
        >
          <InputNumber
            controls={false}
            style={{ width: 150 }}
            min={1}
            max={100}
          />
        </Form.Item>
        <Divider />
        <Typography.Paragraph strong>Logics</Typography.Paragraph>
        {showForm || (logics && logics.length) ? (
          <div className="vtt-settings-validate-logics">
            {renderAddLogicForm()}
          </div>
        ) : (
          <Card style={{ textAlign: 'center' }}>
            <Typography.Title level={4}>Add Logics</Typography.Title>
            <Typography.Paragraph>
              The risk score that you provide above will be assigned once the
              following logics you add are met.
            </Typography.Paragraph>
            <div style={{ marginTop: 5 }}>
              <Button type="primary" onClick={() => setShowForm(true)}>
                Add
              </Button>
            </div>
          </Card>
        )}
      </div>
    );
  };

  const renderCategoryDrawer = () => (
    <div>
      <Row gutter={16}>
        <Col span={16}>
          <Typography.Paragraph>Group Name:</Typography.Paragraph>
          <Form.Item
            name={`${drawerConfig.drawerMeta?.mode}-groupName`}
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Typography.Paragraph>Risk:</Typography.Paragraph>
          <Form.Item
            name={`${drawerConfig.drawerMeta?.mode}-risk`}
            rules={[{ required: true, message: 'Risk is required' }]}
          >
            <InputNumber controls={false} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={5}>
        {drawerConfig.drawerMeta?.factor?.name}
      </Typography.Title>
      <List
        size="small"
        itemLayout="vertical"
        bordered
        dataSource={selectedOptions}
        renderItem={item => (
          <List.Item
            extra={
              <Button
                size="small"
                onClick={() => onOptionDelete(item.id)}
                danger
                icon={<DeleteOutlined />}
              />
            }
            key={item.id}
          >
            {item.name}
          </List.Item>
        )}
      />
      <div style={{ marginTop: 5, textAlign: 'end' }}>
        <Button type="primary" onClick={() => showSubDrawer()}>
          Add
        </Button>
      </div>
      <SubDrawer
        title={drawerConfig.drawerMeta?.factor?.name}
        isLoading={optionsLoading}
        options={options}
        subDrawerConfig={{
          showDrawer: showSubDrawer,
          onClose: onCloseSubDrawer,
          drawerMeta: selectedSubDrawerMeta,
          subDrawerVisible,
        }}
        form={subDrawerForm}
        onSelectOptions={onSelectOptionsSubDrawer}
        selectedOptions={selectedOptions}
      />
    </div>
  );

  const renderDrawerSection = () => (
    <div>
      {drawerConfig.drawerMeta?.mode && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            form={drawerForm}
            autoComplete="off"
            onValuesChange={validateLogics}
          >
            {drawerConfig.drawerMeta?.mode === calculationModes.condition
              ? renderConditionDrawer()
              : renderCategoryDrawer(drawerConfig.drawerMeta?.factor)}
          </Form>
        </Space>
      )}
    </div>
  );

  const scoreValidator = (rule, value, key) => {
    const ruleField = _get(rule, 'field', '');
    const fieldValues = ruleField.split('.');
    const filedKey = parseInt(_get(fieldValues, '[1]', key), 10);
    const scores = drawerForm.getFieldValue('condition-logics') || [];

    return new Promise((resolve, reject) => {
      if (isValueEmpty(value)) {
        reject();
      }

      const error = scoreValidation(filedKey, scores);

      if (error) {
        return reject(new Error(error));
      }

      const crossError = crossValidation(filedKey);

      if (crossError) {
        return reject(new Error(crossError));
      }

      return resolve();
    });
  };

  const validateLogics = () => {
    const currentMode = _get(drawerConfig, 'drawerMeta.mode', '');
    if (currentMode !== calculationModes.condition) return;

    setTimeout(() => {
      drawerForm.validateFields();
    }, 300);
  };

  const scoreValidation = (key = null, scores, crossLogic = null) => {
    const currentScore = key !== null ? scores[key] : crossLogic;
    const currentScoreCondition = _get(currentScore, 'condition', '');
    const currentScoreValue = _get(currentScore, 'value', '');
    const sameKindScores = scores.filter(score => {
      const { condition } = score;
      return (
        (
          greaterOperators.includes(condition) &&
          greaterOperators.includes(currentScoreCondition)
        ) ||
        (
          lessOperators.includes(condition) &&
          lessOperators.includes(currentScoreCondition)
        )
      );
    });
    const oppositeScores = scores.filter(
      score =>
        score.condition !== currentScoreCondition &&
        rangeOperators.includes(currentScoreCondition) &&
        rangeOperators.includes(score.condition),
    );
    const oppositeScoreValues = oppositeScores.map(score => score.value);
    const minOpposite = Math.min(...oppositeScoreValues);
    const maxOpposite = Math.max(...oppositeScoreValues);

    if (!currentScore) return null;

    if (!currentScoreCondition) return null;

    if (!currentScoreValue === '' || currentScoreValue === 'null') return null;

    if (scores.length > 1 && currentScoreCondition === '=') {
      return 'Cannot have an equal condition with multiple rule conditions.';
    }

    if (
      rangeOperators.includes(currentScoreCondition) &&
      sameKindScores.length > 1
    ) {
      return 'Cannot have same type of conditions more than once.';
    }

    if (
      currentScoreCondition === '>' &&
      oppositeScores.length &&
      minOpposite <= currentScoreValue
    ) {
      return 'Condition mismatch.';
    }

    if (
      currentScoreCondition === '<' &&
      oppositeScores.length &&
      maxOpposite >= currentScoreValue
    ) {
      return 'Condition mismatch.';
    }

    return null;
  };

  const crossValidation = key => {
    const savedRules = _get(drawerConfig, 'drawerMeta.factor.scores', []);
    const currentRuleId = _get(selectedScore, 'id', '');
    const otherRules = savedRules.filter(item => item.id !== currentRuleId);
    const logics = drawerForm.getFieldValue('condition-logics') || [];
    const filteredLogics = logics.filter(logic => !isValueEmpty(logic.value));
    const currentLogic = logics[key];

    const hasOtherRuleIssues = [];
    otherRules.forEach(item => {
      const ruleLogics = item.logics || [];
      if (!ruleLogics.length) {
        return;
      }

      // First validate existing logics are valid
      const validatedLogics = [];
      ruleLogics.forEach((existing, index) => {
        const error = scoreValidation(index, ruleLogics);
        if (!error) {
          validatedLogics.push({
            ...existing,
          });
        }
      });

      const filteredValidated =  validatedLogics.filter(
        logic => rangeOperators.includes(logic.condition)
      );

      const noEqualValues = [];
      const equalValues = []
      validatedLogics.forEach(logic => {
        if (logic.condition === '!=') {
          noEqualValues.push(logic.value);
        }

        if (logic.condition === '=') {
          equalValues.push(logic.value);
        }
      });

      // Validate current logic against valid existing logics
      if (
        currentLogic.condition === '=' &&
        equalValues.includes(currentLogic.value)
      ) {
        hasOtherRuleIssues.push(item);
      }

      if (!filteredValidated.length) return;

      const logicRange = getLogicRange(filteredValidated);
      const currentRange = getLogicRange(filteredLogics);
      const isOverLapping = logicRange.filter(
        value => currentRange.includes(value)
      ).length;

      if (isOverLapping) {
        if (
          currentLogic.condition === '=' &&
          noEqualValues.includes(currentLogic.value)
        ) {
          return;
        }

        hasOtherRuleIssues.push(item);
      }
    });

    if (hasOtherRuleIssues.length) {
      return 'Condition is not matched with one or more previously saved rule conditions';
    }

    return null;
  };

  const getLogicRange = (logics) => {
    const maxValue = notPercentage ? maxFactorValue : maxPercentage;
    let max = maxValue + 1 // Due to range generation with lodash
    let min = 0;

    logics.forEach(logic => {
      switch(logic.condition) {
        case '<':
          max = logic.value;  // Due to range generation with lodash
          return;
        case '<=':
          max = logic.value + 1 // Due to range generation with lodash
          return;
        case '>':
          min = logic.value + 1
          return;
        case '>=':
          min = logic.value;
          return;
        case '=':
          min = logic.value;
          max = logic.value + 1; // Due to range generation with lodash
          return;
        default:
          return null;
      }
    });
    
    return _range(min, max);
  };

  const isValueEmpty = value =>
    value === null || value === '' || value === undefined;
  
  return (
    <VetDrawer
      closable
      title={
        drawerConfig.drawerMeta?.mode === calculationModes.condition
          ? drawerConfig.drawerMeta?.factor?.name
          : 'Category Group'
      }
      footer={renderDrawerFooter()}
      onClose={onDrawerClose}
      visible={drawerConfig.drawerVisible}
      width={350}
      className="vtt-gen-set-main-drawer"
    >
      {renderDrawerSection()}
    </VetDrawer>
  );
};

MainDrawer.propTypes = {
  drawerConfig: PropTypes.object,
  onFinish: PropTypes.func,
};

export default MainDrawer;
