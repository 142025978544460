import React from "react";
import {Typography} from "antd";
import PropTypes from "prop-types";

const {Title} = Typography;

const TopCard = ({title, value}) => {
    return (
        <div className="stat-top-card-container">
            <div style={{textAlign:"center"}}>
                <Title level={5}>{title}</Title>
                <Title level={2}>{value}</Title>
            </div>
        </div>
    )
}

TopCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string
}

export default TopCard;