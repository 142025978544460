import Highcharts from 'highcharts'
import _startCase from "lodash/startCase";

// chart Data Preparation
export const prepareMainChartOptions = (data) => {
    return {
        chart: {
            type: "pie",

        },
        plotOptions: {
            pie: {
                showInLegend: true,
                innerSize: "50%",
            }
        },
        legend: {
            align: 'center',
            layout: 'vertical',
            verticalAlign: 'bottom',
            labelFormatter: function () {
                return `${_startCase(this.name)} ${Math.round(this.percentage)} %`;
            },
        },

        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: data
        }]
    }
}

export const prepareUserGroupChartOptions = (data) => {
    return {
        chart: {
            type: 'column',
        },
        xAxis: {
            labels: {
                style: {
                    color: 'black'
                }
            },
            categories: data ? Object.keys(data) : [],
            title: {
                text: 'User Group'
            }
        },
        yAxis: {
            tickInterval:1,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: 'black'
                }
            },
            min: 0,
            title: {
                text: 'Total Dashboard Views',
            }
        },
        plotOptions: {
            column: {
                groupPadding: 0,
                borderWidth: 0,
                showInLegend: false,
                color: "#323F9F"
            }
        },
        series: [{
            data:  data ? Object.values(data) : []

        }]
    }
}

export const preparePlatformChartOptions = (data) => {
    return {
        chart: {
            type: "pie",
            // width:400,
            // height: 400
        },
        plotOptions: {
            pie: {
                showInLegend: true,
                innerSize: "0",
            }
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
            itemMarginBottom: 20,
            labelFormatter: function () {
                return `${this.name} ${Math.round(this.percentage)} %`;
            },
            itemStyle: {fontSize: "12px"}
        },

        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: data
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 400
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        itemMarginBottom: 0,
                    },
                }
            }]
        }
    }
}

export const prepareViewsOverTimeChartOptions = (data) => {
    return {
        chart: {
            type: 'column',
        },
        xAxis: {
            type:'datetime',
            tickInterval:3600 * 3000,
            labels: {
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return Highcharts.dateFormat('%l%P', this.value);
                }
            },
            title: {
                text: 'Time'
            }
        },
        yAxis: {
            tickInterval:1,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: 'black'
                }
            },
            min: 0,
            title: {
                text: 'Total Dashboard Views',
            }
        },
        plotOptions: {
            column: {
                groupPadding: 0,
                borderWidth: 0,
                showInLegend: false,
                color: "#323F9F"
            },
            series: {
                pointStart:Date.UTC(2016,0,17),
                pointInterval: 3600 * 1000
            }
        },
        series: [{
            data: data

        }]
    }
}

export const prepareViewsChartOptions = (monthData, weekData) => {
    return {
        chart: {
            type: 'column',
            events: {
                drilldown: function (e) {
                    this.xAxis[0].setTitle({text: e.seriesOptions.name})
                }, drillupall: function (e) {
                    this.xAxis[0].setTitle({text: e.target.series[0].name})
                }
            }
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    color: 'black'
                }
            },
            title: {
                text: 'Month'
            }
        },
        yAxis: {
            tickInterval:1,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: 'black'
                }
            },
            title: {
                text: 'Total Dashboard Views',
            }
        },
        plotOptions: {
            column: {
                groupPadding: 0.15,
                borderWidth: 0,
                showInLegend: false,
                color: "#323F9F"
            }
        },
        series: [
            {
                name: "Month",
                data: monthData
            }
        ],
        drilldown: {
            series: weekData
        }
    }
}
