import {
    FETCH_VW_DEALERSHIP_USER,
    FETCH_VW_DEALERSHIP_USER_LOADING,
    FETCH_VW_DEALERSHIP_USERS,
    FETCH_VW_DEALERSHIP_USERS_LOADING,
    SAVE_VW_DEALERSHIP_USER_LOADING,
    DELETE_VW_DEALERSHIP_USER_LOADING,
    UPDATE_VW_DEALERSHIP_USER_LOADING,
    USER_FETCH_DEALERSHIPS,
    USER_FETCH_DEALERSHIPS_LOADING,
    USER_RESET_PW_LINK_SEND_LOADING
} from "../../../constant/actionTypes";

const vwDealershipUserManagementReducerDefaultState = {
    main: {
        isLoading:false,
        error: false,
        data: []
    },
    selected: {
        isLoading:false,
        error: false,
        data: {},
    },
    added: {
        isLoading:false,
    },
    deleted: {
        isLoading:false,
    },
    updated: {
        isLoading:false,
    },
    sendLink: {
        isLoading:false,
    },
    resData: {
        dealerships: {
            isLoading:false,
            error: false,
            data: []
        },
    }
};

const vwDealershipUserManagementReducer = (state = vwDealershipUserManagementReducerDefaultState, action) => {
    switch (action.type) {
        case USER_RESET_PW_LINK_SEND_LOADING:
            return {
                ...state,
                sendLink: {
                    ...state.sendLink,
                    ...action.payload,
                }
            }
        case USER_FETCH_DEALERSHIPS_LOADING:
        case USER_FETCH_DEALERSHIPS:
            return {
                ...state,
                resData: {
                    ...state.resData,
                    dealerships: {
                        ...state.dealerships,
                        ...action.payload,
                    }
                }
            }
        case FETCH_VW_DEALERSHIP_USERS_LOADING:
        case FETCH_VW_DEALERSHIP_USERS:
            return {
                ...state,
                main: {
                    ...state.main,
                    ...action.payload,
                }
            }
        case FETCH_VW_DEALERSHIP_USER_LOADING:
        case FETCH_VW_DEALERSHIP_USER:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.payload,
                }
            }
        case SAVE_VW_DEALERSHIP_USER_LOADING:
            return {
                ...state,
                added: {
                    ...state.added,
                    ...action.payload,
                }
            }
        case DELETE_VW_DEALERSHIP_USER_LOADING:
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    ...action.payload,
                }
            }
        case UPDATE_VW_DEALERSHIP_USER_LOADING:
            return {
                ...state,
                updated: {
                    ...state.updated,
                    ...action.payload,
                }
            }
        default:
            return state;
    }
};

export default vwDealershipUserManagementReducer;