import React, {Fragment, memo, useState} from 'react';
import {Modal, Table} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import ActionCellRenderer from "./cellRenderers/ActionCellRenderer";
import GenericCellRenderer from "./cellRenderers/GenericCellRenderer";
import RegenerateKeyModal from "./partials/RegenerateKeyModal";
import {deleteConsumer} from "../../../../actions/consumer.action";

import {getSubUserPartnerId} from "../../../../selectors/onboarding/auth.selector";

const ConsumerGrid = ({data}) => {
    const dispatch = useDispatch();
    const userPartner = useSelector(getSubUserPartnerId);
    const {confirm} = Modal;

    const [keyRegenerateModalStatus, setKeyRegenerateModalStatus] = useState({
        visible: false,
        key: null,
    });

    const licenseStatusOptions = {
        enum: ["Trial", "Premium", "Trial End", "Premium End"]
    };

    const onDeleteConfirm = (key) => {
        confirm({
            title: 'Do you want to delete this consumer?',
            content: 'You will not be able to revise this action once confirmed',
            onOk() {
                dispatch(deleteConsumer(key, userPartner));
            },
        })
    }

    const columns = [
        {
            title: 'School Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'No of Students',
            dataIndex: 'student_count',
            key: 'student_count',
            render: (data) => <GenericCellRenderer data={data}/>,
        }, {
            title: 'License Start',
            dataIndex: 'enable_from',
            key: 'enable_from',
            render: (data) => <GenericCellRenderer data={data} type="DATE"/>,
        }, {
            title: 'License End',
            dataIndex: 'enable_until',
            key: 'enable_until',
            render: (data) => <GenericCellRenderer data={data} type="DATE"/>,
        }, {
            title: 'Status',
            key: 'license_status',
            dataIndex: 'license_status',
            render: (data) => <GenericCellRenderer data={data} type="ENUM" options={licenseStatusOptions}/>,
        }, {
            title: 'Action',
            key: 'action',
            align: "right",
            render: (value, row) => <ActionCellRenderer
                value={value} row={row}
                setKeyRegenerateModalStatus={setKeyRegenerateModalStatus}
                onDeleteConfirm={onDeleteConfirm}/>
        },
    ];

    const modalConfig = {
        title: "Regenerate Keys",
        visible: keyRegenerateModalStatus.visible,
        onCancel: () => {
            setKeyRegenerateModalStatus({
                visible: false,
                key: null,
            });
        },
    };

    const modalData = {
        key: keyRegenerateModalStatus.key
    };

    return (
        <Fragment>
            <Table rowKey={"name"} columns={columns} dataSource={data} scroll={{ x: 100 }}/>
            <RegenerateKeyModal
                config={modalConfig}
                data={modalData}/>
        </Fragment>
    );
};

export default memo(ConsumerGrid);