import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Checkbox, Tooltip} from "antd";

const OrderSelectAllCheck = ({onChange, options, selectedOptions, mode}) => {
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onCheckAllChange = e => {
        onChange(e.target.checked ? "all" : "none", options.map(option => option.id));
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect(() => {
        // check options and selected options and do the state changes accordingly
        if(options && options.length){
            if (options.length && selectedOptions.length){
                if(selectedOptions.length > 0){
                    setIndeterminate(!!selectedOptions.length && selectedOptions.length < options.length);
                }else{
                    setIndeterminate(false);
                }
                setCheckAll(selectedOptions.length === options.length);
            }else{
                setIndeterminate(false);
                setCheckAll(false);
            }
        }
    }, [selectedOptions, options])

    return (
        <Fragment>
            <Tooltip placement="topRight" title="Select All/None">
                <Checkbox
                    indeterminate={indeterminate}
                    checked={checkAll}
                    style={{paddingLeft:10}}
                    onChange={onCheckAllChange}
                    disabled={!mode}
                />
            </Tooltip>
        </Fragment>
    )
}

OrderSelectAllCheck.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    selectedOptions: PropTypes.array,
    mode: PropTypes.bool,
}

export default OrderSelectAllCheck;