import React from 'react';
import { Row, Space } from "antd";
import PropTypes from "prop-types";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import NoDataOverlay from '../../../NoDataOverlay';
import Loader from '../../../../common/layout/Loader';

const ModuleDataContainer = ({ moduleProgressCardData, prerequisite, isLoading = true }) => {

  const renderIcon = (assignment) => {
    if (assignment.assignmentCompleted) {
      return (<CheckCircleFilled
        style={
          { color: assignment.assignmentColor }
        }
      />);
    }
    
    return (
      <CloseCircleFilled
        style={
          { color: assignment.assignmentColor }
        }
      />
    );
  }
  return (
    <div className="subjectSegment">
      <div>
        <p className="criteria">
          <b>Prerequisite: </b>
          {prerequisite ? prerequisite : "N/A"}
        </p>
      </div>

      <Loader isLoading={isLoading}>
        {moduleProgressCardData?.tempModuleNode?.length ? (
          <div className="moduleAssignmentList">
            {moduleProgressCardData.tempModuleNode.map((assignment, i) =>
              <div className="assignmentRow" key={assignment.assignmentId}>
                <Row justify='space-between'>
                  <div>
                    {assignment.assignmentTitle}
                  </div>

                  <Space>
                    {assignment.assignmentLabel}
                    {renderIcon(assignment)}
                  </Space>
                </Row>
              </div>)
            }
          </div>
        )
          :
          <NoDataOverlay message={"No Module data"} />
        }
      </Loader>
    </div>
  );
};

ModuleDataContainer.propTypes = {
  moduleProgressCardData: PropTypes.object,
  prerequisite: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default ModuleDataContainer;
