import React, {Fragment} from "react";
import {Card, Col, PageHeader, Row, Typography, Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useHistory} from "react-router";

import DataSources from "./partials/list/DataSources";
import menuData from "../../../layout/dashboardManagement/menuData";

const {Title} = Typography;

const DataSourceManagementView = () => {
    const history = useHistory();
    return (
        <div className="in-container">
            <Row justify="space-between">
                <Col span={12}>
                    <Title level={4}>Data Source Management</Title>
                </Col>
                <Col span={12} style={{textAlign:"end"}}>
                    <Button type="primary" onClick={() => history.push(menuData.createDataSource.url)} icon={<PlusOutlined/>}>Add Source</Button>
                </Col>
            </Row>    
            <DataSources/>
        </div>
    )
}

export default DataSourceManagementView;