import _get from "lodash/get";

// return innovative assignment response
export const makeInnovativeAssignmentsResponse = state => _get(state, 'innovativeStudent.assignment.list');

// return innovative assignment response loading status
export const makeInnovativeAssignmentsLoading = state => {
    const result = makeInnovativeAssignmentsResponse(state);
    return result && result.isLoading;
};

// return innovative assignment response data
export const makeInnovativeAssignmentsResponseData = state => {
    const result = makeInnovativeAssignmentsResponse(state);
    return _get(result, 'response.data.result', {});
};

// return innovative assignment comparison response data
export const makeInnovativeAssignmentsComparisonResponseData = state => {
    const result = makeInnovativeAssignmentsResponseData(state);
    return _get(result, 'assignment_comparison', []);
};

// return innovative assignment comparison response data
export const makeInnovativeAssignmentsSummaryCardsResponseData = state => {
    const result = makeInnovativeAssignmentsResponseData(state);
    return _get(result, 'assignment_cards', []);
};

// return innovative assignment response error
export const makeInnovativeAssignmentsError = state => {
    const result = makeInnovativeAssignmentsResponse(state);
    return result.error;
};

// return innovative outcomes response data
export const makeInnovativeOutcomesResponseData = state => {
    const result = makeInnovativeAssignmentsResponseData(state);
    return _get(result, 'outcomes', []);
};

// return innovative overall outcomes response data
export const makeInnovativeOverallOutcomesResponseData = state => {
    const result = makeInnovativeAssignmentsResponseData(state);
    return _get(result, 'overall_user_outcome', []);
};

// return innovative overall mastery status response data
export const makeInnovativeOverallMasteryStatusResponseData = state => {
    const result = makeInnovativeAssignmentsResponseData(state);
    return _get(result, 'overall_mastery_outcome', []);
};



// assignment Information
// return innovative assignment information response
export const makeInnovativeAssignmentInformationResponse = state => _get(state, 'innovativeStudent.assignment.assignmentInformation');

// return innovative assignment response loading status
export const makeInnovativeAssignmentInformationLoading = state => {
    const result = makeInnovativeAssignmentInformationResponse(state);
    return result && result.isLoading;
};

// return innovative assignment Information response data
export const makeInnovativeAssignmentInformationResponseData = state => {
    const result = makeInnovativeAssignmentInformationResponse(state);
    return _get(result, 'response.data', {});
};


// return innovative student courses response
export const makeInnovativeStudentCoursesResponse = state => _get(state, 'innovativeStudent.assignment.courses');

// return innovative student courses response loading status
export const makeInnovativeStudentCoursesLoading = state => {
    const result = makeInnovativeStudentCoursesResponse(state);
    return result && result.isLoading;
};

// return innovative student courses response data
export const makeInnovativeStudentCoursesResponseData = state => {
    const result = makeInnovativeStudentCoursesResponse(state);
    return _get(result, 'response.data.result');
};