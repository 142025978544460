import { VTT_RTO_SIGN_UP_DATA_SAVING } from "../../constant/actionTypes";

const initialState = {
  onBoarding: {
    signUp : {
      isDataSaving: false,
      lastSave: ''
    }
  }
};

const rtoReducer = (state = initialState, action ) => {
  const { payload, type } = action;
  
  switch(type) {
    case VTT_RTO_SIGN_UP_DATA_SAVING:
      return {
        ...state,
        onBoarding: {
          ...state.onBoarding,
          signUp: {
            ...state.onBoarding.signUp,
            ...payload,
          }
        }
      }
    default:
      return state;
  };
};

export default rtoReducer;