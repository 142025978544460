import React, { memo } from "react";
import { Button, Tooltip } from "antd";
import Icon, { DownloadOutlined } from "@ant-design/icons";

const downloadIcon = <Icon component={DownloadOutlined} style={{ fontSize: "18px"}} />;
const exportTooltip =
  "Please scroll to the end of the page to load all the records before exporting";

const filterSection = props => {
  const {
    filterTerms,
    isFilterTermsLoaded,
    filterTermValue,
    changeTermValue,
    isFilterSemestersLoaded,
    filterSemesters,
    filterSemesterValue,
    changeSemesterValue,
    isFilterStudentsLoaded,
    filterStudents,
    filterStudentValue,
    changeStudentValue,
    generatepdf,
    redrawCharts,
    isGoButtonDisabled,
    exportButtonDisabled,
  } = props;

  const getYearOptions = () => {
    if (!isFilterTermsLoaded){
      return <option>Loading...</option>;
    } 

    return filterTerms.map(filterTerm => (
      <option
        key={filterTerm.id}
        value={filterTerm.id}
      >
        {filterTerm.name}
      </option>
    ));
  };

  const onChangeTerm = event => {
    const { value } = event.target;
    changeTermValue(value, "redraw");
  };

  const getSemisterOptions = () => {
    if (!isFilterSemestersLoaded) {
      return <option>Loading...</option>;
    }

    return filterSemesters.map(semester => (
      <option 
        key={semester.id} 
        value={semester.id}
      >
        {semester.name}
      </option>
    ));
  };

  const onChangeSemester = event => {
    const { value } = event.target;
    changeSemesterValue(value, "redraw");
  };

  const studentOptions = () => {
    if (!isFilterStudentsLoaded) {
      return <option>Loading...</option>;
    }

    return filterStudents.map(student => (
        <option 
          key={student.id}
          value={student.id}
        >
          {`${student.name} - ${student.id}`}
        </option>
    ));
  };

  const onChangeStudent = event => {
    const { value } = event.target;
    changeStudentValue(value, "redraw");
  };

  const getFilterItem = (
    label,
    getOptions,
    value,
    onchangeFunc,
    parentClass = 'col-sm-4 col-md-4 col-xs-12 col-xl-4 col-lg-4',
  ) => {
    return (
      <div className={parentClass} >
        <div className="card o-hidden custom-cardx">
          <div className="card-body" style={{backgroundColor:"#e0e0e0"}}>
            <div className="media static-top-widget">
              <div className="media-body">
                <span className="m-0">{label}</span>
                <h4 className="mb-0 counter">
                  <select
                    className="form-control digits " 
                    value={value}
                    onChange={onchangeFunc}
                  >
                    {getOptions()}
                  </select>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div 
      className="card custom-card" 
      style={{backgroundColor:"#e0e0e0", marginTop: '90px'}}
    > 
      <div className="row ">
        <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
          <div className="row "  >
            {
              getFilterItem('YEAR', getYearOptions, filterTermValue, onChangeTerm)
            }
            {
              getFilterItem(
                'SEMESTER',
                getSemisterOptions,
                filterSemesterValue,
                onChangeSemester,
                'col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3'
              )
            }
            {
              getFilterItem('STUDENTS', studentOptions, filterStudentValue, onChangeStudent)
            }
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
          <div>
            <Button
              size="large"
              disabled={isGoButtonDisabled}
              readOnly={isGoButtonDisabled}
              style={{marginTop:'24px', width:"100%", color: "#FFF"}}
              className="btn btn-secondary btn-lg"
              onClick={redrawCharts}
            >
              GO
            </Button>
            <Tooltip 
              title={exportButtonDisabled ? exportTooltip : ''}
              placement="bottomRight"
            >
              <div>
                <Button
                  icon={downloadIcon}
                  size="large"
                  disabled={exportButtonDisabled}
                  readOnly={exportButtonDisabled}
                  style={{ marginTop:'24px', width: "100%", color: "#FFF" }}
                  onClick={generatepdf}
                  className={
                    exportButtonDisabled ?
                      "btn btn-light btn-lg": 
                      "btn btn-secondary btn-lg"
                  }
                >
                  Export to PDF
                </Button>
              </div>
            </Tooltip> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(filterSection);