import React, {useEffect, useState} from "react";
import {Button, Space} from "antd";
import _isEmpty from "lodash/isEmpty";
import {useDispatch, useSelector} from "react-redux";

import {
    getDashboardFiltersSelectedData,
    getSelectedCardsByPath
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {fetchComplianceStudents} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/compliance.action";
import {
    getComplianceFactorsData,
    getComplianceStudentsData,
    getComplianceStudentsLoading
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/compliance.selector";
import {setSelectedCards} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/common.action";

import Students from "./Students";
import StudentDetailCell from "../../../../../common/StudentDetailCell";
import ProgressBarCell from "../../../../../common/ProgressBarCell";
import Loader from "../../../../../../../../common/layout/Loader";

import {complianceSections, subSections} from "../../../../../../../../../../constant/jobReadyGeneric/constants";
import {
    getUniqValuesForFilters
} from "../../../../../../../../utils/general";
import {prepareRiskMeasurementsColumns} from "../../../../../../../../utils/studentTable";
import {filterStudentByCard} from "../../../../../common/topCards/subSectionTopCards";
import NoDataCell from "../../../../../../../../common/partials/NoDataCell";

const StudentsTab = () => {
    const dispatch = useDispatch();
    const [filteredStudents, setFilteredStudents] = useState([])

    // selectors
    const students = useSelector(getComplianceStudentsData);
    const enabledFactors = useSelector(getComplianceFactorsData);
    const studentsLoading = useSelector(getComplianceStudentsLoading);
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const selectedCard = useSelector(getSelectedCardsByPath(subSections.compliance.key));

    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters) && !studentsLoading){
            const {compliance} = subSections;
            const data = {
                filters: selectedFilters,
                subSectionId: compliance.id
            }
            dispatch(setSelectedCards({[subSections.compliance.key]: null}))
            dispatch(fetchComplianceStudents(data));
        }
    }, [selectedFilters])

    useEffect(() => {
        if(students){
            setFilteredStudents(students);
        }
    }, [students])

    useEffect(() => {
        filterStudentByCard(selectedCard, setFilteredStudents, students, subSections.compliance.key)
    }, [selectedCard])

    const renderProgressCells = data => {
        if (data) {
            return (
                <ProgressBarCell
                    value={data.value}
                    displayValue={data.value}
                    color={data.color}
                />
            );
        }

        return <NoDataCell />;
    }


    const columns = [
        {
            title: "",
            className: "table-remove-left",
            children: [
                {
                    title: 'Student Details',
                    dataIndex: 'studentDetails',
                    sorter: (a, b) => a.studentDetails.name && a.studentDetails.name.localeCompare(b.studentDetails.name),
                    render: (value) => <StudentDetailCell title={value.name} subtitle={value.id} avatar={value.image}/>,
                    onFilter: (value, record) => record.studentDetails.name === value,
                    filters: getUniqValuesForFilters(students, "studentDetails.name", "studentDetails.name"),
                    fixed: "left",
                },
                {
                    title: 'Compliance Risk Score',
                    dataIndex: 'compliance',
                    sorter: (a, b) => parseFloat(a.compliance.value) - parseFloat(b.compliance.value),
                    onFilter: (value, record) => record.compliance.value === value,
                    render: (value) => renderProgressCells(value),
                    filters: getUniqValuesForFilters(students, "compliance.value", "compliance.value"),
                },
            ]
        },
        {
            title: 'Risk Measurements',
            children: prepareRiskMeasurementsColumns(enabledFactors, students, complianceSections)
        },
        // Not in phase 01
        // {
        //     title: "",
        //     align:"center",
        //     render: (value, record) => <Space>
        //         <Button size="small">View</Button>
        //         <Button size="small" type="primary" ghost>Take Action</Button>
        //     </Space>,
        //     fixed: "right",
        // },
    ];


    return (
        <Loader isLoading={studentsLoading}>
            <Students
                students={filteredStudents}
                columns={columns}
            />
        </Loader>
    )
}

export default StudentsTab;