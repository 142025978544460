import _get from "lodash/get";

// return innovative course response
export const makeInnovativeLayoutBreadcrumb = state => _get(state, 'innovative.layout.breadcrumb');

// return innovative query params
export const makeInnovativeLayoutQueryParams = state => _get(state, 'innovative.layout.queryParams');

// return innovative top nav response
export const makeInnovativeLayoutTopNav = state => _get(state, 'innovative.layout.topNav');

// return selected navigation type
export const makeInnovativeNavigationType = state => _get(state, 'innovative.layout.selectedNavigationType');

// return sidebar data
export const makeInnovativeSidebar = state => _get(state, 'innovative.layout.sidebar');