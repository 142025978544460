import React, { Component, Fragment } from 'react';
import { DollarSign, Tag, ShoppingBag, MessageCircle, MinusCircle, ThumbsUp, Briefcase, MoreHorizontal, Send, Activity, Anchor, Compass, Cpu, Slack, Umbrella, Box, Book } from 'react-feather';
import Modal from 'react-responsive-modal';
import seven from '../../assets/images/user-card/7.jpg';
import Breadcrumb from '../common/breadcrumb';
import { Calendar, Users,MessageSquare,Navigation, Layers, Server, ArrowDown, ArrowUp } from 'react-feather';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem ,Dropdown} from 'reactstrap';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import one from '../../assets/images/user-card/1.jpg';
import three from '../../assets/images/user-card/3.jpg';
import anglicanimage from '../../assets/images/anglican.png';
import two from '../../assets/images/user-card/2.jpg';
import sixteen from '../../assets/images/avtar/16.jpg';
import eleven from '../../assets/images/avtar/11.jpg';
import four from '../../assets/images/avtar/4.png';
import url from '../../assets/images/user/7.jpg';
 
import addHeatmap from 'highcharts/modules/heatmap'
addHeatmap(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class DropdownComponent extends Component {
    constructor(props) {
        super(props);
        var queryx = new URLSearchParams(this.props.location.search);
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          isFilterCoursesLoaded:false,
          isOpen:false,
          url:"",
          isLoaded: true,
          items: [],
          isSubChartLoaded:false,
          subChartItem:[],
          isLateSubChartLoaded:false,
          lateSubmission:[],
          tempStudentName:"",
          studentName:"Teacher",
          avatar:four,
          isFilterTermsLoaded:false,
          isFilterYearLevelsLoaded:false,
          isFilterStudentsLoaded:false,
          filterTermValue:0,
          filterCourseValue:0,
          filterStudentValue:0,
          filterYearLevelValue:0,
          filterTerms:[],
          filterYearLevels:[],
          filterStudents:[],
          filterCourses:[],
          isFeedbacksLoaded:false,
          isStudentStatsLoaded:false,
          readyToSend:[],
          needAttention:[],
          feedbacks:[],
          isMarksByAssignmentsLoaded:false,
          marksByAssignments:[],
          isStudentNameLoaded:false,
          loader:'Loading...',
          twentiethPercentile:0, 
          isWorkloadLoaded:false,
          workLoadItem:[],
          ninetiethPercentile:0,
          logged_in: queryx.get('token'),
          token: 'Bearer ' + queryx.get('t'),
          isActivityByTimeLoaded:false,
          activityByTimeData:[],
          isFirstTimeLoad:true,
          isEngagementPerformanceLoaded:false,
          engagementPerformanceData:[],
          SubmissionModelOpen:false,
          onCloseModal:false,
          isSubChartDetailsLoaded:false,
          subDetailsChartItems:[]
        };
      }
    
      readUrl(event){



      }
 
      filterTerms(){

        this.setState({
          
        isFilterCoursesLoaded:false
     
    
        });
    
    
    
        this.setState({
          isGoButtonDisabled: true
        });
        const requestOptions = {
          credentials: "include",
          method: 'GET',
          headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' }
        
      };
      //getting filter data
      fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/term/"+ this.state.logged_in ,requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isFilterTermsLoaded: true, 
            filterTermValue:result.data.result[0].id,
            filterTerms:result.data.result
          });
        
          this.filterCourses(result.data.result[0].id);
           
        },

        (error) => {
          this.setState({
            isFilterTermsLoaded: true,
            error
          });
        }
        ) ;

     }

     changeYearLevelFilterValue(value){

      ////////////alert(value);
      this.setState({filterYearLevelValue:value});
      this.filterStudents(value);

     }
     changeTermValue(value){

      ////alert(value);
      this.setState({filterTermValue:value});
      this.filterCourses(value);

     }
     changeStudentValue(value){

      //////alert(value);
      this.setState({filterStudentValue:value});
      this.filterCourses(value);

     }
     changeCourseValue(value){
      //////alert(value);
      this.setState({filterCourseValue:value});
    //  this.filterStudents();

     }
 
     redrawCharts(){

      this.setState({
        isSubChartLoaded:false,
        isStudentStatsLoaded:false,
        isLateSubChartLoaded:false,
        isMarksByAssignmentsLoaded:false,
        isStudentNameLoaded:false,
        isStudentNameLoaded:false,
        isFeedbacksLoaded:false,
        isWorkloadLoaded:false,
        isActivityByTimeLoaded:false,
        isEngagementPerformanceLoaded:false
      
      
      });
       this.charts_submissiondata();
       this.table_studentStats();
       this.chart_marks_by_assignment();
       this.basic_info();
       this.charts_workload();
       this.table_lateSubmissions();
       this.chart_activity_by_time();
       this.engagement_performance_chart();
     }

basic_info(){
  const requestOptions = {
    credentials: "include",
    method: 'GET',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' }
  
};
  fetch(process.env.REACT_APP_APIURL+"studentprofile/student/"+ this.state.logged_in +"/"+this.state.logged_in,requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isStudentNameLoaded: true,
        studentName:result.data.result.name, 
        avatar:result.data.result.avatar_url
      });

     
    },

    (error) => {
      this.setState({
        isStudentNameLoaded: true,
        error
      });
    }
    ) ;
}


 filterYearLevel(term=null){
      const requestOptions = {
        credentials: "include",
        method: 'GET',
        headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' }
      
    };

    if(term==null){

      var term = this.state.filterTermValue;
    }
   
    
    //getting filter data
    fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/yearlevel/"+ this.state.logged_in +"/"+term,requestOptions)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isFilterYearLevelsLoaded: true,
          filterYearLevels:result.data.result 
         
        });


        if(result.length >0){
          this.setState({ 
            filterYearLevelValue:result.data.result[0].id
          });

          this.filterStudents(result.data.result[0].id);

        }
    

      },

      (error) => {
        this.setState({
          isFilterYearLevelsLoaded: true,
          error
        });
      }
      ) ;

   }

 filterStudents(yearlevel=null){
    const requestOptions = {
      credentials: "include",
      method: 'GET',
      headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' } 
    
  };

  if(!yearlevel==null){
    const yearlevel = this.state.filterYearLevelValue;
  }
 
  const term = this.state.filterTermValue;
 
  //getting filter data
  fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/teachers/"+ this.state.logged_in +"/"+term+"/"+yearlevel,requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isFilterStudentsLoaded: true,
        filterStudents:result.data.result, 
        
      });

     
      if(result.length >0){
        this.setState({filterStudentValue:result.data.result[0].id,
          studentName:result.data.result[0].name});
        this.filterCourses(result.data.result[0].id);
      }
     

    },

    (error) => {
      this.setState({
        isFilterStudentsLoaded: true,
        error
      });
    }
    ) ;

 }

 filterCourses(term=null){

  this.setState({
     
  isFilterCoursesLoaded:false
   

  });



  this.setState({
    isGoButtonDisabled: true
  });
   
  const requestOptions = {
    credentials: "include",
    method: 'GET',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' }
  
};

const student_id = 0;
const yearLevel = this.state.filterYearLevelValue;
 
if(student_id==null){
  const student_id= this.state.filterStudentValue;
}
 

//getting filter data
fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/teachercourses/"+this.state.logged_in+"/"+term+"/"+yearLevel+"/"+student_id,requestOptions)
.then(res => res.json())
.then(
  (result) => {
    this.setState({
      isFilterCoursesLoaded: true,
      filterCourses:result.data.result
    });
    
    if(result.data.result.length >0){
      this.setState({
        filterCourseValue:result.data.result[0].id
      });


  this.setState({
    isGoButtonDisabled: false
  });
   
 
    }else{
      this.setState({
        filterCourses:[{id:"",name:"-"}]
      });
    }

    if(this.state.isFirstTimeLoad){

      this.setState({
            isFirstTimeLoad:false
      });
      this.redrawCharts();
    }
   

  },

  (error) => {
    this.setState({
      isFilterCoursesLoaded: true,
      error
    });
  }
  ) ;

}

table_lateSubmissions(){
      
     
  const courseId  = this.state.filterCourseValue;

 

//////alert("don");
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ xxxX:"",courseId: courseId,studentId : this.state.filterStudentValue})
  
};
  //getting submission data
  fetch(process.env.REACT_APP_APIURL+"teacherprofile/late_submission_table/data/",requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isFeedbacksLoaded: true,
        feedbacks: result.data.result
        
      });
     

    },

    (error) => {
      this.setState({
        isFeedbacksLoaded: true,
        error
      });
    }
  ) ;
 }

 engagement_performance_chart(){ 

  const courseIds = [this.state.filterCourseValue];
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ courseId: this.state.filterCourseValue,year:this.state.filterTermValue})
  
};
  //getting submission data
  fetch(process.env.REACT_APP_APIURL+"teacherprofile/engagementvsperformance/data/",requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isEngagementPerformanceLoaded: true,
        engagementPerformanceData: result.data.response.body,
        
      });
     

    },

    (error) => {
      this.setState({
        isEngagementPerformanceLoaded: true,
        error
      });
    }
  ) ;
 }


chart_marks_by_assignment(){ 
   

  const courseIds = [this.state.filterCourseValue];
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ courseId: this.state.filterCourseValue,year:this.state.filterTermValue})
  
};
  //getting submission data
  fetch(process.env.REACT_APP_APIURL+"teacherprofile/average_marks_comparison/data/",requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isMarksByAssignmentsLoaded: true,
        marksByAssignments: result.data.response.body,
        
      });
     

    },

    (error) => {
      this.setState({
        isMarksByAssignmentsLoaded: true,
        error
      });
    }
  ) ;
 }



table_studentStats(){
      
     
  const courseIds = [];

  for(var i=0;i<this.state.filterCourses.length;i++){

    courseIds[i]=this.state.filterCourses[i].id;
  }

//////alert("don");
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ courseId: this.state.filterCourseValue,studentId : this.state.filterStudentValue})
  
};
  //getting submission data
  fetch(process.env.REACT_APP_APIURL+"teacherprofile/student/stats/",requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isStudentStatsLoaded: true,
        readyToSend: result.data.result.readyToSend,
        needAttention: result.data.result.needAttention,
        twentiethPercentile:result.data.twenty,
        ninetiethPercentile:result.data.ninety
        
      });

    
     

    },

    (error) => {
      this.setState({
        isStudentStatsLoaded: true,
        error
      });
    }
  ) ;
 }
 charts_workload(){
      
  this.setState({workLoadItem:[]});
  const courseIds = [];

  for(var i=0;i<this.state.filterCourses.length;i++){

    courseIds[i]=this.state.filterCourses[i].id;
  }

//////alert("don");
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ term: this.state.filterTermValue,courseIds: courseIds,studentId:this.state.logged_in,yearLevel:this.state.filterStudentValue})
  
};
  //getting submission data
  fetch(process.env.REACT_APP_APIURL+"teacherprofile/workload/data/",requestOptions)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isWorkloadLoaded: true,
        workLoadItem: result.data.result.body 
         
       // studentName:  result.data.response.body.userName,
      //  avatar: result.data.response.body.avatar
      });
     

    },

    (error) => {
      this.setState({
        isWorkloadLoaded: true,
        error
      });
    }
  ) ;
 }


 chart_activity_by_time() {


  //////alert("don");
  const requestOptions = {
    credentials: "include",
    method: 'POST',
    headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
    body: JSON.stringify({ courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, year: this.state.filterTermValue })

  };
  //getting submission data
  fetch(process.env.REACT_APP_APIURL + "teacherprofile/activity_by_time/data/", requestOptions)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isActivityByTimeLoaded: true,
          activityByTimeData: result.data.response.body,

        });


      },

      (error) => {
        this.setState({
          isActivityByTimeLoaded: true,
          error
        });
      }
    );
}


charts_submissiondata(){
      
     
      const courseIds = [];

      for(var i=0;i<this.state.filterCourses.length;i++){

        courseIds[i]=this.state.filterCourses[i].id;
      }

//////alert("don");
      const requestOptions = {
        credentials: "include",
        method: 'POST',
        headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
        body: JSON.stringify({ term: this.state.filterTermValue,courseId: courseIds,studentId:this.state.filterStudentValue,yearLevel:this.state.filterStudentValue})
      
    };
      //getting submission data
      fetch(process.env.REACT_APP_APIURL+"teacherprofile/submissions/data/2",requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isSubChartLoaded: true,
            subChartItem: result.data.response.body,
            isLateSubChartLoaded:true,
           // studentName:  result.data.response.body.userName,
          //  avatar: result.data.response.body.avatar
          });
         

        },
    
        (error) => {
          this.setState({
            isSubChartLoaded: true,
            error
          });
        }
      ) ;
     }



  charts_submissiondetails(courseId=0) {



    this.setState({
  
      SubmissionModelOpen:true,
      isSubChartDetailsLoaded:false,
      subDetailsChartItems:[]
  
     });
 
    //////alert("don");
    const requestOptions = {
      credentials: "include",
      method: 'POST',
      headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
      body: JSON.stringify({ term: this.state.filterTermValue, courseId: courseId, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue })

    };
    //getting submission data
    fetch(process.env.REACT_APP_APIURL + "teacherprofile/submissions/details/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isSubChartLoaded: true,
            subDetailsChartItems: result.data.response.body,
            isSubChartDetailsLoaded: true,
            // studentName:  result.data.response.body.userName,
            //  avatar: result.data.response.body.avatar
          });


        },

        (error) => {
          this.setState({
            isSubChartDetailsLoaded: true,
            error
          });
        }
      );
  }




       componentDidMount() {
        
        

      this.filterTerms();
     
      //this.redrawCharts();
      //this.table_feedbacks();

          //const response = await fetch('http://api.tentacle.com:801/api/1/health/check', requestOptions);


          
      }





      toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
      }

  
    render() {

      const gState  = this.state;
      const gThis  = this;


      var setModelopen =  function(index){



        gThis.charts_submissiondetails(index);
        
       };
   
       var closeModal = function(){
   
         gThis.setState({
     
           SubmissionModelOpen:false
       
          });
       };
     
   
      
        var easeOutBounce = function (pos) {
            if ((pos) < (1 / 2.75)) {
                return (7.5625 * pos * pos);
            }
            if (pos < (2 / 2.75)) {
                return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
            }
            if (pos < (2.5 / 2.75)) {
                return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
            }
            return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
        };
        
        Math.easeOutBounce = easeOutBounce;

        const colors1 = ["#0266C8", "#F90101", "#F2B50F", "#00933B"];
        const colors = ["#164a90", "#3b3b8a", "#a80c03", "#d43504"];
        const submission_colors = ["#ff8800","#228a3b",  "#db043a", "#d43504"];
    
        let chart1 = {
          title: {
            text: ''
          },
       
            colors:colors ,
            chart: {
              events: {
                load() {
                  this.showLoading();
                  setTimeout(this.hideLoading.bind(this), 2000);
                }
              },
              type:"column",
              animation: true,
              style: {
               // fontFamily: "Roboto",
                color: "#444444"
              }
            },
            plotOptions: {
              series: {
                
                  linewidth:6,
                  borderRadius: 1
              }
          },
            xAxis: {
              gridLineWidth: 1,
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
              categories:this.state.marksByAssignments.categories,
              title: {
                text: 'ASSIGNMENT NAME'
                //align: 'center'
              }
            },
            yAxis: {
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
              title: {
                text: 'MARKS AS A PERCENTAGE'
                //align: 'center'
              }
              
            },
            legend:{
              enabled:false
            },
            tooltip: {
              formatter: function (tooltip) {
      
      
      
                return "Assignment Name : <b>" + this.point.category + "<b/><br/>Marks : <b>" + this.y + "% ";
      
      
              }
            },
            credits: {
              enabled: false
            },
            legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
            background2: "#505053",
            dataLabelsColor: "#B0B0B3",
            textColor: "#C0C0C0",
            contrastTextColor: "#F0F0F3",
            maskColor: "rgba(255,255,255,0.3)",
           
            series: this.state.marksByAssignments.data
        };

         

        let chart2 = {
          title: {
            text: ''
          },
       
            colors:submission_colors ,
            chart: {
              events: {
                load() {
                  this.showLoading();
                  setTimeout(this.hideLoading.bind(this), 1000);
                }
              },
              type:"column",
              animation: true,
              style: {
               // fontFamily: "Roboto",
                color: "#444444"
              }
            },
            plotOptions: {
              series: {
                stacking:"percent",
                  linewidth:6,
                  borderRadius: 1
              },
              column: {
                events: {
                   click:function(event) {
                      var index=  event.point.index ;
      
                     // alert(gState.subChartItem.categoryIds[index]);
                     setModelopen(gState.subChartItem.categoryIds[index]);
                   }
               }
           }
          },
            xAxis: {
              gridLineWidth: 1,
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
              categories:this.state.subChartItem.categories
            },
            yAxis: {
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
              title: {
                text: ''
                //align: 'center'
            },
          },
            tooltip: {
              pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
              shared: true
            },
             
            credits: {
              enabled: false
            },
            legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
            background2: "#505053",
            dataLabelsColor: "#B0B0B3",
            textColor: "#C0C0C0",
            contrastTextColor: "#F0F0F3",
            maskColor: "rgba(255,255,255,0.3)",
           
            series: this.state.subChartItem.data
        };


        let submission_details_chart = {
          title: {
            text: ''
          },
    
          colors: submission_colors,
          chart: {
            events: {
              load() {
                this.showLoading();
                setTimeout(this.hideLoading.bind(this), 1000);
              }
            },
            type: "column",
            animation: true,
            style: {
              // fontFamily: "Roboto",
              color: "#444444"
            }
          },
          plotOptions: {
            series: {
              stacking: "percent",
              linewidth: 6,
              borderRadius: 1
            },
           
          },
          xAxis: {
            gridLineWidth: 1,
            gridLineColor: "#F3F3F3",
            lineColor: "#F3F3F3",
            minorGridLineColor: "#F3F3F3",
            tickColor: "#F3F3F3",
            tickWidth: 1,
            categories: this.state.subDetailsChartItems.categories,
            title: {
              text:"ASSIGNMENT NAME"
            }
          },
          credits: {
            enabled: false
          },
          yAxis: {
            gridLineColor: "#F3F3F3",
            lineColor: "#F3F3F3",
            minorGridLineColor: "#F3F3F3",
            tickColor: "#F3F3F3",
            tickWidth: 1,
            title: {
              text:"SUBMISSIONS AS A PERCENTAGE"
            }
           },
          tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
            shared: true
          },
          legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
          background2: "#505053",
          dataLabelsColor: "#B0B0B3",
          textColor: "#C0C0C0",
          contrastTextColor: "#F0F0F3",
          maskColor: "rgba(255,255,255,0.3)",
    
          series: this.state.subDetailsChartItems.data
        };
         
        let late_submission = {
          title: {
            text: ''
          },
       
            colors:colors ,
            chart: {
              events: {
                load() {
                  this.showLoading();
                  setTimeout(this.hideLoading.bind(this), 1000);
                }
              },
              type:"column",
              animation: true,
              style: {
               // fontFamily: "Roboto",
                color: "#444444"
              }
            },
            credits: {
              enabled: false
            },
            plotOptions: {
              series: {
                
                  linewidth:6,
                  borderRadius: 1
              }
          },
            xAxis: {
              gridLineWidth: 1,
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
              categories:this.state.subChartItem.categories
            },
            yAxis: {
              gridLineColor: "#F3F3F3",
              lineColor: "#F3F3F3",
              minorGridLineColor: "#F3F3F3",
              tickColor: "#F3F3F3",
              tickWidth: 1,
                title: {
                text: ''
                //align: 'center'
            }
              
            },
            legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
            background2: "#505053",
            dataLabelsColor: "#B0B0B3",
            textColor: "#C0C0C0",
            contrastTextColor: "#F0F0F3",
            maskColor: "rgba(255,255,255,0.3)",
           
            series: this.state.subChartItem.data2
        };

        let activity_by_time = {
          title: {
            text: ''
          },
    
          colors: colors,
          chart: {
            type: "line",
            animation: true,
            style: {
              // fontFamily: "Roboto",
              color: "#444444"
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              marker: {
                radius: 9
              },
              lineWidth: 5
            }
          },
          xAxis: {
            gridLineWidth: 1,
            gridLineColor: "#F3F3F3",
            lineColor: "#F3F3F3",
            minorGridLineColor: "#F3F3F3",
            tickColor: "#F3F3F3",
            tickWidth: 1,
            categories: this.state.activityByTimeData.categories,
            title: { text: "TIME SLOT" }
          },
          credits: {
            enabled: false
          },
          tooltip: {
            formatter: function (tooltip) {
    
    
    
              return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during "+gState.activityByTimeData.data[this.point.x]+" days";
    
    
            }
          },
          yAxis: {
            gridLineColor: "#F3F3F3",
            lineColor: "#F3F3F3",
            minorGridLineColor: "#F3F3F3",
            tickColor: "#F3F3F3",
            tickWidth: 1,
            title: { text: "NUMBER OF ACTIVITIES" }
    
          },
          legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
          background2: "#505053",
          dataLabelsColor: "#B0B0B3",
          textColor: "#C0C0C0",
          contrastTextColor: "#F0F0F3",
          maskColor: "rgba(255,255,255,0.3)",
    
          series: [{
            name: "Count",
            data: this.state.activityByTimeData.data,
            animation: {
              duration: 2000
              // Uses Math.easeOutBounce
              // easing: 'easeOutBounce'
            }
          }
          ]
        };

        let workload = {
          title: {
            text: ''
          },
    
          
          chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
          },
    
          colorAxis : {
            min: 0,
            minColor: "#ffffff",
            maxColor: colors[0]
         },
          title: {
            text: ''
          },
    
          xAxis: {
            categories: ["WEEK 1", "THIS WEEK", "NEXT WEEK", "2 WEEKS FROM NOW", "3 WEEKS FROM NOW"],
            title:{
              text:"NEXT FOUR WEEKS"
            }
    
          },
          yAxis: {
            gridLineColor: "#F3F3F3",
            lineColor: "#F3F3F3",
            minorGridLineColor: "#F3F3F3",
            tickColor: "#F3F3F3",
            tickWidth: 1,
            categories: this.state.workLoadItem.categories,
            title: null,
            reversed: true,
            title:{
              text:"COURSE NAME"
            }
          },
          credits: {
            enabled: false
          },
          accessibility: {
            point: {
              descriptionFormatter: function (point) {
    
              }
            }
          },
     
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
          },
    
          tooltip: {
            /*
                                  formatter: function () {                    
                                return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> Yearly Average: <b>$' +	
                                  Highcharts.numberFormat(this.series.chart.yAxis[1].categories[this.point.y],2) + '</b><br>' + 
                                  'Average Labour Rate: <b>' + '$'+Highcharts.numberFormat(this.point.value, 2) + '</b><br>' + 
                                  '<b>' + this.series.xAxis.categories[this.point.x];
                            }
    
           */
            formatter: function (tooltip) {         
              
              if(gState.isWorkloadLoaded){
    
               
                if(this.point.value >0){
    
                  return gState.workLoadItem.allData[this.point.y].tooltips[this.point.x-1];
    
                }else{
    
                  return false;
                }
                
    
              }else{
    
                return "";
              }
            }
        },
    
    
          series: [{
            name: 'Work Load',
            borderWidth: 1,
            data: this.state.workLoadItem.data,
            dataLabels: {
              enabled: true,
              color: '#000000'
            }
          }],
    
    
    
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                yAxis: {
                  labels: {
                    formatter: function () {
                      return this.value.charAt(0);
                    }
                  }
                }
              }
            }]
          }
        };




      let engagement_performance =  {
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'ENGAGEMENT SCORE'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: {
            title: {
                text: 'PERFORMANCE SCORE'
            }
        },
        legend:{
          enabled:false
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                } 
            }
        },
        tooltip: {
        
          formatter: function (tooltip) {         
            
            if(gState.isEngagementPerformanceLoaded){
  
            
              if(this.point.name!=undefined){
               
                //  return "Time Slot : <b>" + this.point.category + "<b/><br/>Count : <b>" + this.y + " ";
                return "Student Name : "+ this.point.name +"<br/>Engagement : "+this.point.x+"<br/>Performance : "+this.point.y;
  
                //return "Student Name : "+gState.engagementPerformanceData.categories[this.series.data.indexOf( this.point )-1]+"<br/>Engagement : "+this.point.x+"<br/>Performance : "+this.point.y;
  
              }else{
  
                return false;
              }
              
  
            }else{
  
              return "";
            }
          }
      },
        credits:{
          enabled:false
        },
        series: [ {
            name: '',
            color: colors[0],
            data:  this.state.engagementPerformanceData.data
        }]
    };      
    
        
        const settings = {
            className: "center",
            centerMode: true,
            speed: 500,
            arrows: false,
            centerPadding: "5px",
            slidesToShow: 1,
            slidesToScroll: 1
          };
          

        return (
            <Fragment>
                
           <div className="container-fluid " > 
           <div className="card custom-card  " style={{backgroundColor:"#e0e0e0",marginTop: '90px'}}> 
            <div className="row ">

            <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
            
            <div className="row "  >
              <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
              <div className="card o-hidden custom-cardx">
                <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                  <div className="media static-top-widget">
                    
                    <div className="media-body">
                      <span className="m-0">YEAR</span>
                      <h4 className="mb-0 counter">
                      <select className="form-control digits "  value={this.state.filterTermValue}
                                         onChange={(e) => this.changeTermValue(e.target.value)}>
                                             {this.state.isFilterTermsLoaded
                                           ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                           : <option>Loading...</option>

                                          }
                                       </select>
                      </h4>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div className="col-sm-4 col-md-4 col-xs-12 col-xl-4 col-lg-4">
              <div className="card o-hidden custom-cardx">
              <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                  <div className="media static-top-widget">  
                    <div className="media-body"><span className="m-0">COURSE</span>
                      <h4 className="mb-0 counter">
                      <select className="form-control digits" value={this.state.filterCourseValue}
                                            onChange={(e) => this.changeCourseValue(e.target.value)}>
                                              {this.state.isFilterCoursesLoaded
                                           ? this.state.filterCourses.map((filterCourses) => <option key={filterCourses.id} value={filterCourses.id}>{filterCourses.name}</option>)
                                           : <option>Loading...</option>

                                          }
                                       </select>
                      </h4>
                       
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
           </div>
  
  
            <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
              <div className="">
            
                    <button disabled={this.state.isGoButtonDisabled} readonly={this.state.isGoButtonDisabled}  style={{marginTop: '24px',width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                            
                    
              </div>
            </div>
         </div>
         </div>     

                
                <div className="user-profile"> 
              
                  <div className="row">

                  <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
              <div className="card custom-card  ">
                <div className="row">
                  <div className="col-4 col-sm-4">
                  <img  src={anglicanimage}></img>
                  </div>
                  <div className="col-8 col-sm-8 with-border">
                      <div className="student-name">
                    <h5><span className="counter">TEACHER PROFILE</span></h5>
                    <h4>{this.state.isStudentNameLoaded
                      ? this.state.studentName
                      : this.state.loader}</h4>
                  </div>

                  </div>

                </div>

              </div>
            </div>

                  <div className="col-xl-6 col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                        <div className="card">
                        <div className="card-header bg-custom-dark bg-danger">
                            <h5>STUDENTS NEEDING ADDITIONAL SUPPORT</h5>
                        </div>
                        <div className="card-body">
                            <div className="user-status table-responsive">

                            <div style={{textAlign:"center",display:this.state.isStudentStatsLoaded?"none" : "block"}}><img src={preloaderGif} height="100px"></img></div>
 
          < div className="table-responsive custom-table-class" style={{display:this.state.isStudentStatsLoaded?"block" : "none"}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            
                                            <th scope="col">ID</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Course</th>
                                            <th  className="text-center" scope="col">Performance</th>
                                            <th  className="text-center" scope="col">Engagement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.isStudentStatsLoaded 
                                      ?  
                                    this.state.needAttention.map((stats) => <tr><td>{stats.id}</td><td>{stats.name}</td><td>{stats.course}</td><td align="center">{stats.finalscore} </td><td align="center">{stats.engagement} </td></tr>)
                                     :
                                   <tr style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></tr>

                                    }
                                
                                    </tbody>
                                </table>
                            </div>
                            <div>
                            
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xl-6  col-md-6 col-sm-6 col-xs-6 col-lg-6">
                        <div className="card">
                        <div className="card-header bg-custom-dark bg-success">
                            <h5>STUDENTS READY TO BE EXTENDED</h5>
                        </div>
                        <div className="card-body">
                            <div className="user-status table-responsive">
                            <div style={{textAlign:"center",display:this.state.isStudentStatsLoaded?"none" : "block"}}><img src={preloaderGif} height="100px"></img></div>
 
                            <div className="table-responsive custom-table-class" style={{display:this.state.isStudentStatsLoaded?"block" : "none"}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            
                                            <th scope="col">ID</th>
                                            <th scope="col">Student Name</th>
                                            <th scope="col">Course</th>
                                            <th className="text-center" scope="col">Performance</th>
                                            <th   className="text-center" scope="col">Engagement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.isStudentStatsLoaded 
                                      ?  
                                    this.state.readyToSend.map((stats) => <tr><td>{stats.id}</td><td>{stats.name}</td><td>{stats.course}</td><td align="center" >{stats.finalscore} </td><td align="center">{stats.engagement} </td></tr>)
                                     :
                                   <tr style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></tr>

                                    }
                                
                                    </tbody>
                                </table>
                            </div>
                            <div>
                            
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
                       
                    <div className="col-xl-12  col-lg-12 col-sm-12 col-md-12 ">
                        <div className="card">
                        <div className="card-header bg-custom-dark">
                            <h5>AVERAGE MARK COMPARISON BY ASSIGNMENT</h5>
                        </div>
                        <div className="card-body">
                            <div className="user-status table-responsive">
                            <div>

                {this.state.isMarksByAssignmentsLoaded
                ?   <HighchartsReact
                  highcharts={Highcharts}
                  options={chart1}/>
                  : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                  
                }
                              
                          
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>              

                    <div className="col-xl-6 xl-100">
                        <div className="card">
                        <div className="card-header bg-custom-dark">
                            <h5>SUBMISSIONS BY COURSE</h5>
                        </div>
                        <div className="card-body">
                            <div className="user-status table-responsive ">
                            <div>
                            {this.state.isSubChartLoaded
                              ?   
                            <HighchartsReact
                            highcharts={Highcharts}
                            options={chart2}
                            />
                            : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>

                      }
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
 
              

                    <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Activity By Time Of The Day</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                      <div>


                        {this.state.isActivityByTimeLoaded
                          ? <HighchartsReact
                            highcharts={Highcharts}
                            options={activity_by_time}
                          />
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
 
             <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Workload By Week</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                    
                        {this.state.isWorkloadLoaded
                          ?
                          <div style={{height:4*90}}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={workload}
                            containerProps={{style :{height:4*90}}}
                          />
                          </div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        }
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 xl-100">
                <div className="card">
                  <div className="card-header bg-custom-dark">
                    <h5>Engagement VS Performance</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status table-responsive">
                    
                        {this.state.isEngagementPerformanceLoaded
                          ?
                          <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={engagement_performance}
                           
                          />
                          </div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                        }
                      
                    </div>
                  </div>
                </div>
              </div>
                   

                </div>
                </div>
                </div>


                <Modal open={this.state.SubmissionModelOpen} onClose={closeModal}   className="custom-large-modal" >
                  <div className="modal-body">
                    <div className="product-modal row">
                     
                    <div className="user-profile cust-model-size">

            <div className="row">


                    <div className="col-xl-12 xl-100">
                      <div className="card">
                        <div className="card-header bg-custom-dark">
                          <h5>SUBMISSION BY  ASSIGNMENT</h5>
                        </div>
                        <div className="card-body">
                          <div className="user-status table-responsive">
                            <div>
                              {this.state.isSubChartDetailsLoaded
                                ?
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={submission_details_chart}
                                />
                                : <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>

                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 xl-100">
                        <div className="card">
                        <div className="card-header bg-custom-dark">
                            <h5>SUBMISSIONS DETAILS</h5>
                        </div>
                        <div className="card-body">
                            <div className="user-status table-responsive custom-table-class">

                            
                            <div style={{textAlign:"center",display:this.state.isSubChartDetailsLoaded?"none" : "block"}}><img src={preloaderGif} height="100px"></img></div>

<div className="table-responsive" style={{display:this.state.isSubChartDetailsLoaded?"block" : "none"}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">ASSIGNMENT</th>
                                            <th scope="col">STATUS</th>
                                            <th scope="col">DUE DATE</th>
                                            <th scope="col">SUBMITTED DATE</th>
                                            <th scope="col">DAYS LATE</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                               {this.state.isSubChartDetailsLoaded 
                                ?  
                                this.state.subDetailsChartItems.table.map((subDetails) => <tr><td>{subDetails.assignment}</td><td>{subDetails.status}</td><td>{subDetails.dueDate}</td><td>{subDetails.submitted}</td><td>{subDetails.delay}</td></tr>)
                                         
                                       :
                                   <tr style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></tr>

                               }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                            
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>



                    </div>                      
                    </div>                       
                    </div>     
                  </div>
                </Modal>

                
            </Fragment>
        );
    }
}

export default DropdownComponent;