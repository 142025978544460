import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import TopCountCard from './TopCountCard';
import TopCardPie from './topCardPie/TopCardPie';
import Loader from '../../../../../../common/layout/Loader';

// helper functions
/**
 * Global handler for filtering student table data relevant to selected card
 * @param {string} selectedCard    selected top card
 * @param {object[]} students  students array
 * @param {function} setFilteredStudents  setter function for filter student
 * @param {string} subSection  subSection which the filtration should happen
 */
export const filterStudentByCard = (
  selectedCard,
  setFilteredStudents,
  students,
  subSection,
) => {
  if (selectedCard && selectedCard !== 'total') {
    setFilteredStudents(
      students?.filter(
        student => student[subSection].text?.toLowerCase() === selectedCard,
      ),
    );
  } else {
    setFilteredStudents(students);
  }
};

const SubSectionTopCards = ({
  cardData,
  subSection,
  cardLoading,
  chartData,
}) => (
  <Loader isLoading={cardLoading}>
    <Row gutter={[16, 16]}>
      <Col sm={12} md={12} lg={12} xl={4}>
        <TopCountCard
          name="Total Count"
          color="#6244BB"
          backColor={false}
          mode="total"
          value={cardData && cardData.total.value}
          subSection={subSection}
        />
      </Col>
      <Col sm={12} md={12} lg={12} xl={4}>
        <TopCountCard
          name="Low Risk"
          color="#52C41A"
          mode="low"
          value={cardData && cardData.low.value}
          subSection={subSection}
        />
      </Col>
      <Col sm={12} md={12} lg={12} xl={4}>
        <TopCountCard
          name="Medium Risk"
          color="#FAAD14"
          mode="medium"
          value={cardData && cardData.medium.value}
          subSection={subSection}
        />
      </Col>
      <Col sm={12} md={12} lg={12} xl={4}>
        <TopCountCard
          name="high Risk"
          color="#EB5757"
          mode="high"
          value={cardData && cardData.high.value}
          subSection={subSection}
        />
      </Col>
      <Col sm={24} md={24} lg={24} xl={8}>
        <TopCardPie name="Cohort" data={chartData} />
      </Col>
    </Row>
  </Loader>
);

SubSectionTopCards.propTypes = {
  cardData: PropTypes.object,
  subSection: PropTypes.string,
  cardLoading: PropTypes.bool,
  chartData: PropTypes.array,
};

export default SubSectionTopCards;
