import {
    STUDENT_FETCH_ASSIGNMENTS,
    STUDENT_FETCH_ASSIGNMENTS_LOADING,
    STUDENT_FETCH_ASSIGNMENTS_INFORMATION,
    STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING,
    STUDENT_FETCH_COURSES,
    STUDENT_FETCH_COURSES_LOADING
} from "../../constant/actionTypes";

const assignmentReducerDefaultState = {
    selected: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {},
            },
        }
    },
    list: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {
                    assignment_cards: [],
                    assignment_comparison: [],
                    outcomes: [],
                    overall_course_grade: [],
                    overall_assignment_submission: [],
                },
            },
        }
    },
    assignmentInformation: {
        isLoading: false,
        error: false,
        response: {
            data: {
                name: "",
                description: "",
                comments: []
            }
        }
    },
    courses: {
        isLoading: false,
        error: false,
        response: {
            data: [],
        }
    },
};

const assignmentReducer = (state = assignmentReducerDefaultState, action) => {
    switch (action.type) {
        case STUDENT_FETCH_ASSIGNMENTS:
            return {
                ...state,
                list: action.payload
            };
        case STUDENT_FETCH_ASSIGNMENTS_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_ASSIGNMENTS_INFORMATION:
            return {
                ...state,
                assignmentInformation: action.payload
            };
        case STUDENT_FETCH_ASSIGNMENTS_INFORMATION_LOADING:
            return {
                ...state,
                assignmentInformation: {
                    ...state.assignmentInformation,
                    isLoading: action.payload.isLoading
                }
            };
        case STUDENT_FETCH_COURSES:
            return {
                ...state,
                courses: action.payload
            };
        case STUDENT_FETCH_COURSES_LOADING:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    isLoading: action.payload.isLoading
                }
            };
        default:
            return state;
    }
};

export default assignmentReducer;