import { Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import SectionBreadcrumb from './Breadcrumb';

const { Title } = Typography;

const SectionHeader = ({ title, items }) => (
  <div
    className="page-header"
    style={{ display: 'flex', alignItems: 'center' }}
  >
    <Space>
      <Title level={3}>{title}</Title>
      <SectionBreadcrumb items={items} />
    </Space>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

export default SectionHeader;
