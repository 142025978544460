import React, {memo} from "react";
import PropTypes from "prop-types";
import {Result, Button, Modal} from "antd";


const WarningMessage = ({visible, setVisible}) => (
    <Modal visible={visible} footer={null} closable={false} onCancel={() => setVisible(false)}>
        <Result
            status="warning"
            title="You are in the Edit mode. Save or discard them for before proceed"
            extra={
                <Button type="primary" key="console" onClick={() => setVisible(false)}>
                    Close
                </Button>
            }
        />
    </Modal>
)

WarningMessage.propTypes = {
    isModalVisible: PropTypes.bool
}

export default memo(WarningMessage);