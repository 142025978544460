import React, {Fragment} from "react";
import {Switch, Row, Col} from "antd";
import PropTypes from "prop-types";

const GridSwitch = ({isLoading, onChange, formValues, setFormValues, switchConf}) => {

    const prepareData = (data) => {
        return Object.keys(data).filter(key => data[key] === true && key)
    }

    const onchange = (value, switchName, otherSwitchName) => {
        if (formValues[otherSwitchName]===false && value===false){
            setFormValues({
                ...formValues,
                [switchName]: true,
            })
        }else{
            setFormValues({
                ...formValues,
                [switchName]: value,
            })
            onChange(prepareData({[switchName]:value, [otherSwitchName]: formValues[otherSwitchName]}))
        }


    }

    return (
        <div className="outcomeState">
            <span>
                <Fragment>
                    <Switch
                        size="small"
                        loading={isLoading}
                        checked={formValues[switchConf.switches.switch1.key]} onChange={(value) => onchange(value,...switchConf.switches.switch1.onChangeKeys)}
                    />
                    &nbsp;
                    <span className="grid-switch-text">{switchConf.switches.switch1.label}</span>
                </Fragment>
            </span>
            <span>
                <Fragment>
                    <Switch
                        size="small"
                        loading={isLoading}
                        checked={formValues[switchConf.switches.switch2.key]} onChange={(value) => onchange(value,...switchConf.switches.switch2.onChangeKeys)}
                    />
                    &nbsp;
                    <span className="grid-switch-text">{switchConf.switches.switch2.label}</span>
                </Fragment>                
            </span>
        </div>
    )
}

GridSwitch.defaultProps = {
    isLoading: false
}

GridSwitch.propTypes = {
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    switchFormValues: PropTypes.object,
    setSwitchFormValues: PropTypes.func,
    switchConf: PropTypes.object,
}

export default GridSwitch;