import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from '../../common/breadcrumb';
import { Grid, List, ChevronDown } from 'react-feather';
import banner from '../../../assets/images/ecommerce/banner.png';
import errorImg from '../../../assets/images/search-not-found.png';
import Modal from 'react-responsive-modal';
import { getVisibleproducts } from '../../../services/index';
import Carousal from './filters/carousal';
import AllFilters from './filters/allfilters';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 


const EcommerceApp = (props) => {
  const layoutColumns = 3;
  const data = useSelector(content => content.data.productItems);
  const filters = useSelector(content => content.filters);
  const products = getVisibleproducts(data, filters)
  const symbol = useSelector(content => content.data.symbol);
  const dispatch = useDispatch();

  const [singleProduct, setSingleProduct] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sidebaron, setSidebaron] = useState(true);
  // eslint-disable-next-line
  const [stock, setStock] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [allaccounts, setAccount] = useState([]);
  const [allgroups, setGroup] = useState([]);
  const [allgroupsOriginal, setGroupOriginal] = useState([]);
  const [groupselected, setgroupselected] = useState([]);
  const [filterSidebar, setFilterSidebar] = useState(true);
  const [checkedAccounts, setCheckedAccounts] = useState([]);
  var queryx = new URLSearchParams(props.location.search);

  const onCloseModal = () => {
    setOpen(false)
  };

 

  
  function onClickDetailPage(){


  }
 
  useEffect(() => {

    const requestOptions = {
      credentials: "include",
    
      headers: { 'Authorization': 'Bearer ' + queryx.get('t'), 'Content-Type': 'application/json' },
      

    };

    function fetchData(){
      fetch(process.env.REACT_APP_APIURL+"tentacle/accounts/all",requestOptions)
          .then(res => res.json()) 
          .then(
            (result) => {
              //
              setGroupOriginal(result.data.result.groups);
             
              setAccount(result.data.result.subaccounts);
             

            } 
          ) 
 
         // .catch(() => this.setState({ hasErrors: true }))
     
      }

    dispatch({ type: 'GET_LIST' });
    fetchData();
    // eslint-disable-next-line
  }, []);

  const filterSortFunc = (event) => {
    dispatch({ type: 'SORT_BY', sort_by: event })
  }
  const gridLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-3');
      el.classList.add('col-sm-6');
      el.classList.add('xl-4')
    });
  }
  //Grid Layout View
  const listLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-12');
    });
  }

  // Layout Column View
  const LayoutView = (layoutColumns) => {
    if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
      var elems = document.querySelector(".gridRow").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = '';
        el.classList.add('col-xl-' + layoutColumns);
      });
    }
  }

   

  const saveAddDashboard = (id) => {
     
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + queryx.get('t'), 'Content-Type': 'application/json' },
      body: JSON.stringify({ groups: groupselected,id:id,accounts:checkedAccounts})
  };
  fetch(process.env.REACT_APP_APIURL+"tentacle/dashboard/add", requestOptions)
       .then(response => response.json())
     .then(data => setOpen(false));
     toast.success("Dashboard is shared successfully!")
  }

   const handleChangeAccounts = (event) => {
      // updating an object instead of a Map
      var accnts = checkedAccounts;
      accnts.push(event.target.value);
      setCheckedAccounts(accnts);

      setGroup(allgroupsOriginal);
       
  }


  const handleChangeGroups = (event) => {
    // updating an object instead of a Map
    var groups = groupselected;
    groups.push(event.target.value);
    setgroupselected(groups);
 
     
}



  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      document.querySelector(".product-wrapper").classList.add('sidebaron');
    } else {
      setSidebaron(true)
      document.querySelector(".product-wrapper").classList.remove('sidebaron');
    }
  }

  const minusQty = () => {
    if (quantity > 1) {
      setStock('InStock')
      setQuantity(quantity - 1)
    }
  }

  const onOpenModal = (productId) => {
    setOpen(true);
    products.map((product, i) => {
      if (product.id === productId) {
        setSingleProduct(product)
      }
      return 0;
    })
  };


  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1)
    } else {
      setStock('Out of Stock !')
    }
  }

  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value))
  }

  const addcart = (product, qty) => {
    dispatch({ type: 'ADD_TO_CART', payload: { product, qty } })
    props.history.push(`${process.env.PUBLIC_URL}/ecommerce/cart/${product.id}`);
  }

  const addWishList = (product) => {
    dispatch({ type: 'ADD_TO_WISHLIST', payload: product });
    props.history.push(`${process.env.PUBLIC_URL}/ecommerce/wishlist/${product.id}`);
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    dispatch({ type: 'SEARCH_BY', search: keyword })
  }

  return (
    <Fragment>
      
      <div className="container-fluid product-wrapper">
        <div className="product-grid mt-4" >
        <div className="feature-products">
            <div className="row">
              <div className="col-md-6 products-total">
                
              </div>
              <div className="col-md-6 text-right">
                <span className="f-w-600 m-r-10"> </span>
                <div className="select2-drpdwn-product select-options d-inline-block">
                 
                </div>
              </div>
            </div>
            <div className="row" style={{display:'none' }}>
              <div className="col-sm-3">
                <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                  <div className="filter-section">
                    <div className="card">
                      <div className="card-header">
                        <h6 className="mb-0 f-w-600">Filters
                                <span className="pull-right">
                            <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                          </span>
                        </h6>
                      </div>
                      <div className="left-filter">
                        <div className="card-body filter-cards-view animate-chk">
                          <AllFilters />
                          <Carousal />
                          <div className="product-filter text-center">
                            <img className="img-fluid banner-product" src={banner} alt="" data-original-title="" title="" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-sm-12">
                <form>
                  <div className="form-group m-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="search"
                      defaultValue={searchKeyword}
                      onChange={(e) => handleSearchKeyword(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="product-wrapper-grid">
            {searchKeyword.length > 0 ?
              <div class="search-not-found text-center">
                <div>
                  <img className="img-fluid second-search" src={errorImg} alt="" />
                  <p>Sorry, We didn't find any results matching this search</p>
                </div>
              </div>
              :
              <div className="row gridRow  ">
                {products ? products.map((item, i) =>
                  <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                    <div className="card" >
                      <div className="product-box">
                        <div className="product-img" style={{maxHeight:400 , overflow:"hidden"}}>
                           
                          <img   className="img-fluid" src={item.img} alt="" />
                          <div className="product-hover">
                           
                          </div>
                        </div>
                        <div className="product-details">
                        <div className="caption">
                                                <h4>
                                               
                                                  {item.name}
                                                 
                                                  </h4>
                                                 
                                                <p>{item.discription}</p>
                             </div>
                             <div className="product-qnty">
                             <div className="addcart-btn">
                                <button className="btn btn-primary m-r-10" type="button"     onClick={() => onOpenModal(item.id)} >Add To Canvas</button>
                               
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ''}
          
                <Modal open={open} onClose={onCloseModal}   className="custom-large-modal" >
                  <div className="modal-body">
                    <div className="product-modal row">
                      <div className="product-img col-md-6" style={{maxHeight:600 , overflow:"scroll"}}>
                        <img className="img-fluid"  src={singleProduct.img} alt="" /></div>
                      <div className="product-details col-md-6 text-left" style={{paddingLeft:10}} >
                        <h3>{singleProduct.name}</h3>
                         
                        <div className="product-view" >
                          <h6 className="f-w-600">Dashboard Details</h6>
                          <p className="mb-0">{singleProduct.discription}</p>

                          <h6 className="f-w-600 mt-5">Enable Dashboard Access</h6>
                          
                          <p className="mb-0">Once enabled selected groups will have access to this report. </p>
                  <br/>
                          <p className="mb-0">Click on your accounts to view user roles</p>
                          <div className="col">


                      <div className="col-md-6 col-lg-6 col-sm-6">

                      <div className="form-group m-t-15 m-checkbox-inline mb-0 ml-1">
                      
                      {allaccounts ? allaccounts.map((account, i) =>
                        <div className="checkbox checkbox-dark">
                          <input id={account.uuid} type="checkbox" onChange={handleChangeAccounts} value={account.id}/>
                          <label for={account.uuid}>{account.name}<span className="digits"> </span></label>
                        </div>

                      ) : "No accounts or user roles/groups found."}
                        
   
                        </div>                            



                      </div>
                          
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group m-t-15">
                         
                        { allgroups ? allgroups.map((group, i) =>
                          <div className="checkbox checkbox-dark">
                            <input id={group.id} type="checkbox" value={group.id} onChange={handleChangeGroups}  />
                            <label for={group.id}>{group.label}<span className="digits"> </span></label>
                          </div>

) : ''}
                          
                          
                        </div>
                        </div>
                      </div>
                        </div>
                       
                        <div className="product-qnty mt-4">
                           <div className="addcart-btn">
                            
                            <button className="btn btn-success" type="button" onClick={() => saveAddDashboard(singleProduct.id)}>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EcommerceApp;
