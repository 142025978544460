import {onError} from  "../../notificationHandler";
import {getCusDomainHeader} from "../serviceUtils";
import localStore from "../../localStorage";

// SERVICE PROPS:
/*
    service - URL - *
    baseUrl - BaseURL
    data - body params
    headers - extra header params
    redirection - error redirection
    showErrorNotification - show error notifications
    authorized - use of bearer token
    exactToken - use of bearer token as the exact one passing
    method - GET, POST, ... (default=GET)
*/

const make = (requestOptions) => {
    const {service, baseUrl, data, metaKey, headers = false, redirection = true, showErrorNotification = true, authorized = false, redirectionHandler=null, exactToken=false,  ...rest} = requestOptions;
    const url = baseUrl ? baseUrl + service : process.env.REACT_APP_APIURL + service;

    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...getCusDomainHeader(),
            ...headers,
        },
        body: data ? JSON.stringify(data) : undefined,
        credentials: "include",
        ...rest
    };
    options.headers.Authorization = (process.env.REACT_APP_AUTH_OVERRIDE || "false") === "true" && localStore.getLsSlim(localStore.keys.authMeta, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET)?.accessToken;

    return new Promise((resolve, reject) => {
        fetch(url, options).then(response => {
            // validate errors
            const responseValidated = responseHandler(response);

            if (responseValidated.status){
                return response.json();
            }else{
                if (redirection && redirectionHandler){

                    // handle redirection
                    const res = redirectionHandler(response.status);

                    if (!res){
                        // handle errors
                        throw new Error(responseValidated.data);
                    }
                }else{
                    // handle errors
                    throw new Error(responseValidated.data);
                }
            }

        }).then(parsed => {
            const {error} = parsed

            if (error) {
                throw parsed
            }

            resolve(parsed);

        }).catch(error => {
            if (showErrorNotification) {
                showError(error);
            }

            reject(error.message)
        })
    })
}

const showError = (response) => {
    onError(response.message, response.errors && response.errors[0] && response.errors[0].msg)
}

const responseHandler = (response) => {
    const {status} = response;
    // check for error codes
    switch (status){
        case 400:
            return {
                status: false,
                data: "Bad Request"
            }
        case 401:
            return {
                status: false,
                data: "Unauthorized"
            }
        case 403:
            return {
                status: false,
                data: "Unauthorized"
            }
        case 404:
            return {
                status: false,
                data: "Invalid Request"
            }
        case 422:
            return {
                status: false,
                data: "Unprocessable Entity"
            }
        case 500:
            return {
                status: false,
                data: "Server Error"
            }
        default:
            return {
                status: true,
                data: false
            }
    }
}

const service = {
    make,
};

export default service;