import React from "react";
import {Row, Col, Button} from "antd";
import PropTypes from "prop-types";

import Filter from "./Filter";
import CustomSelector from "../../../../../common/CustomSelect";

const FilterForm = ({roles, pathways, programs, students, selected, onFilterChange, onApplyClick}) => {

    return (
        <div>
            <br/>
            <Row display="flex" gutter={12}>
                <Col xs={24} sm={12} lg={12} xl={5}>
                    <h5>Roles</h5>
                    <Filter dataset={roles} selected={selected.roles} onChange={(e) => onFilterChange("roles", e)}/>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={5}>
                    <h5>Pathways</h5>
                    <Filter dataset={pathways} selected={selected.pathways} onChange={(e) => onFilterChange("pathways", e)}/>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={7}>
                    <h5>Programs</h5>
                    <Filter dataset={programs} selected={selected.programs} onChange={(e) => onFilterChange("programs", e)}/>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={5}>
                    <h5>Students</h5>
                    <CustomSelector
                            showSearch
                            showArrow
                            allowClear
                            mode="multiple"
                            maxTagCount={1}
                            listHeight={500}
                            onChange={(e) => onFilterChange("students", e.filter(item => item !== "all"))}
                            value="name"
                            paramKey="id"
                            width={"100%"}
                            datalist={students}
                            setDefaultData={true}
                        />
                </Col>
                <Col className="filter-btn" xs={24} sm={24} lg={24} xl={2}>
                    <Button style={{width: "100%"}} type="primary" onClick={onApplyClick}>Apply</Button>
                </Col>
            </Row>
        </div>
    )
}

FilterForm.propTypes = {
    roles: PropTypes.array,
    pathways: PropTypes.array,
    programs: PropTypes.array,
    students: PropTypes.array,
    selected: PropTypes.object,
    onFilterChange: PropTypes.func,
    onApplyClick: PropTypes.func,
}

export default FilterForm;