import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Result } from 'antd';

const EditedWarning = ({ visible, onCancel, onSave, onDiscard, hasErrors }) => (
  <Modal visible={visible} footer={null} onCancel={onCancel}>
    <Result
      status="warning"
      title="You are in the Edit mode. Save or discard them for before proceed"
      extra={
        <>
          <Button
            type="primary"
            key="console"
            onClick={() => onSave()}
            disabled={hasErrors}
          >
            Save
          </Button>
          <Button type="primary" key="console" onClick={() => onDiscard()}>
            Discard
          </Button>
        </>
      }
    />
  </Modal>
);

EditedWarning.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
  hasErrors: PropTypes.bool,
};

export default EditedWarning;
