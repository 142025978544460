import React, {useState, Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    fetchDashboardSelections,
    setSelectedDashboard
} from "../../../actions/jrDashboardManagement/userManagement/jrGenUser.action";
import {
    makeDashboardSelectionsData,
    makeDashboardSelectionsLoading,
    makeSelectedDashboard,
    makeUserListLoading,
    makeAddUserLoading,
    makeDeleteUserLoading
} from "../../../selectors/jrDashboardManagement/userManagement/jrGenUser.selector";

import JRGenUserManagementView from "./JrGenUserManagementView";
import UserModalContainer from "./partials/userModal";

import "../../../assets/scss/jrDashboardManagement/userManagement.scss";

const JRGenUserManagementContainer = () => {
    const [isUserModalVisible, setUserModalVisible] = useState(false);
    const dispatch = useDispatch();

    // selectors
    const dashboardsLoading = useSelector(makeDashboardSelectionsLoading);
    const dashboardsData = useSelector(makeDashboardSelectionsData);
    const selectedDashboard = useSelector(makeSelectedDashboard);
    const usersLoading = useSelector(makeUserListLoading);
    const addUserLoading = useSelector(makeAddUserLoading);
    const deleteUserLoading = useSelector(makeDeleteUserLoading);

    // effects
    useEffect(() => {
        dispatch(fetchDashboardSelections())
    }, [])

     useEffect(() => {
        // auto select first dashboard and fetch users
         if(dashboardsData && dashboardsData.length){
             dispatch(setSelectedDashboard(1))
         }
    }, [dashboardsData])

    const onDashboardChange = (dashboard) => {
        dispatch(setSelectedDashboard(dashboard))
    }

    const showUserModal = () => {
        setUserModalVisible(true);
    };

    const handleUserModalOk = () => {
        setUserModalVisible(false);
    };

    const handleUserModalCancel = () => {
        setUserModalVisible(false);
    };

    return (
        <Fragment>
            <JRGenUserManagementView
                showUserModal={showUserModal}
                isLoading={dashboardsLoading || usersLoading || addUserLoading || deleteUserLoading}
                dashboards={dashboardsData}
                onDashboardChange={onDashboardChange}
                selectedDashboard={selectedDashboard}
            />
            <UserModalContainer
                isVisible={isUserModalVisible}
                handleCancel={handleUserModalCancel}
                handleOk={handleUserModalOk}/>
        </Fragment>
    )
}

export default JRGenUserManagementContainer;