import React, {Fragment} from 'react';

const Breadcrumb = props => {
    const breadcrumb = props;

    return (
        <Fragment>
            <div className="">
                <div className="page-header">
                    <div className="row">
                        <div className="col-10">
                            <div className="page-header-left">
                                <h3>{breadcrumb.title} &nbsp;</h3>
                            </div>
                            {
                                breadcrumb.subtitle ? (
                                    <div className="page-header-left">
                                        <h3><small>{breadcrumb.subtitle}</small></h3>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="col-2">
                            {breadcrumb.tooltip ?                    
                            <ul className="color-tip">
                                <li style={{display:"inline-block"}}>NEGATIVE IMPACT<span className="tooltip-box red"></span></li>
                                <li style={{display:"inline-block"}}>POSITIVE IMPACT<span className="tooltip-box tooltip-box-green green"></span></li>
                            </ul>
                            : null }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
