import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get as _get } from 'lodash';
import { getDomainMetaData } from '../../../selectors/tentacleMeta.selector';
import VetTrackUserManagementContainer from './vetTrackUserManagement';
import MissingPage from './common/pages/MissingPage';
import { setMenuSelected } from '../../../actions/vetTrack/dashboardManagement/layout.action';
import menuData from '../../layout/vetTrack/dashboardManagement/vetTrackMenuData';
import { domainCode as DOMAIN_CODE } from '../../../constant/vetTrack/analysis';

const UserManagementWrapper = () => {
  const dispatch = useDispatch();
  const domainData = useSelector(getDomainMetaData);

  // set menu item
  useEffect(() => {
    dispatch(setMenuSelected(menuData.manageUsers.key));
  }, []);

  const resolveView = () => {
    const domainCode = _get(domainData, 'code', '');

    if (domainCode === DOMAIN_CODE) {
      return <VetTrackUserManagementContainer />;
    }
    return <MissingPage />;
  };

  return resolveView();
};

export default UserManagementWrapper;
