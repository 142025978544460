// Copyright 2024 Octopus BI

// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at

//     http://www.apache.org/licenses/LICENSE-2.0

// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Row, Col } from "antd";
import {
  SendOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import messageSendingGif from "../../../../../assets/images/chatGPT/sending-chat.gif";
import {
  getClusterChartRelatedAssignments,
  getClusterSectionFilters,
  getIsMessageSending,
  getSelectedClusterSectionsIds,
  makeInnovativeCourseMainReportStudentsResponseData,
  makeInnovativeCourseResponseData,
} from "../../../../../selectors/innovative/course.selector";
import {
  addNewMessageToChatHistory,
  sendChatMessage,
} from "../../../../../actions/innovative/course.action";
import { debounce as _debounce } from 'lodash';
import { zeroPad } from 'react-countdown-now';
import { MentionsInput, Mention } from 'react-mentions';
import {
  defaultCss,
  studentCss,
  assignmentsMentionCss,
  courseMentionCss,
  sectionMentionCss,
} from "./chatInputCss";
import { v4 as uuidv4 } from 'uuid';
import AiDisclaimerModal from "./aiDisclaimer";
import AiUserGuidModal from "./aiUserGuid";
import { isValidDate } from "../../../../../utils/general";
import CountdownTimer from "../../../../../utils/countDownTimer";

const PLACE_HOLDER_DEFAULT_VALUE = "Ask Anything";
// remove special characters
const inputTextValidationPattern = /^[^`~#$^*=+{}\[\];<>]*$/;
const FALSE = 0;

const SendChatInput = ({
  isDisabled,
  scrollToEndOfChatRef,
  onCountDownComplete,
  startCountDownTimer,
  retryAt
}) => {
  const dispatch = useDispatch();
  const isMessageSending = useSelector(getIsMessageSending);
  const studentDetails = useSelector(makeInnovativeCourseMainReportStudentsResponseData);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const selectedSectionFilters = useSelector(getSelectedClusterSectionsIds);
  const assignments = useSelector(getClusterChartRelatedAssignments);
  const sectionFiltersArray = useSelector(getClusterSectionFilters);

  const [userQuestion, setUserQuestion] = useState("");
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isInputTextValidationSuccess, setIsInputTextValidationSuccess] = useState(true);
  const [tempUserQuestion, setTempUserQuestion] = useState("");
  const [studentsArray, setStudentsArray] = useState([]);
  const [placeHolder, setPlaceHolder] = useState(PLACE_HOLDER_DEFAULT_VALUE);
  const [isUserGuidOpen, setIsUserGuidOpen] = useState(false);
  const [assignmentsArray, setAssignmentsArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    scrollToEndOfChatRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [isMessageSending]);

  const countDownRenderer = ({ minutes, seconds }) => {
    if (minutes === 0 && seconds === 0) {
      return setPlaceHolder(PLACE_HOLDER_DEFAULT_VALUE);
    }
    setPlaceHolder(`Please Try Again in ${minutes}:${zeroPad(seconds)}`);
  };

  useEffect(() => {
    return () => {
      if (
        timer !== null &&
        timer.isTimerActive()
      ) {
        timer.stop();
      }
    }
  }, [timer]);

  useEffect(() => {
    setStudentsArray(studentDetails.map(student => {
      return {
        id: student.student_id,
        display: student.student_name,
        avatarUrl: student.student_avatar_url,
      };
    }));
  }, [JSON.stringify(studentDetails)]);

  useEffect(() => {
    setAssignmentsArray(assignments.map(assignment => {
      return {
        id: assignment.assignment_id,
        display: assignment.text,
      };
    }));
  }, [JSON.stringify(assignments)]);

  useEffect(() => {
    setSectionArray(sectionFiltersArray.map(section => {
      return {
        id: section.id,
        display: section.text,
      };
    }));
  }, [JSON.stringify(sectionFiltersArray)]);

  const closeDisclaimerModal = () => {
    setIsDisclaimerModalOpen(false);
  };

  const closeUserGuidModal = () => {
    setIsUserGuidOpen(false);
  };

  const onMessageSend = () => {
    const chatId = uuidv4();

    const message = {
      content: userQuestion,
      role: "user",
      message_id: chatId,
    }

    if (
      userQuestion.length &&
      isInputTextValidationSuccess &&
      retryAt === FALSE
    ) {
      dispatch(addNewMessageToChatHistory(message))
      dispatch(sendChatMessage({
        question: userQuestion,
        message_id: chatId,
        filters: {
          sections: selectedSectionFilters,
        },
      }));
      setUserQuestion("");
      setTempUserQuestion("");
    }
  }
  const onSearch = value => {
    value.length && debouncedInputField(value);
  };

  const debouncedInputField = useCallback(
    _debounce(value => {
      const regexValidationSuccess = inputTextValidationPattern.test(value);
      setIsInputTextValidationSuccess(regexValidationSuccess);
    }, 500),
    [],
  );

  useEffect(() => {
    onSearch(userQuestion);
  }, [userQuestion]);


  const renderDropDown = entry => {
    const { display, avatarUrl } = entry;
    return (
      <div>
        <Row>
          {avatarUrl?.length && (
            <Col
              className="mention-data-col"
            >
              <Avatar
                style={{
                  verticalAlign: 'middle',
                }}
                size="small"
                src={avatarUrl}
              />
            </Col>
          )}
          <Col
            className="mention-data-col"
          >
            <div className="mention-display">
              {display}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const readyUserQuestions = (tempUserQuestion) => {
    const regex = /@\[.*?\]/g;

    const userQuestion = tempUserQuestion.replaceAll(regex, "");
    setUserQuestion(userQuestion);
  }

  useEffect(() => {
    readyUserQuestions(tempUserQuestion);
  }, [tempUserQuestion]);

  useEffect(() => {
    if (
      startCountDownTimer &&
      isValidDate(retryAt)) {
      const countDownTimer = new CountdownTimer({
        countDownRenderer,
        onCountDownComplete,
      });

      setTimer(countDownTimer);

      countDownTimer.start({
        endTime: new Date(retryAt),
      });
    };
  }, [startCountDownTimer, retryAt]);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onMessageSend();
    }
  };

  return (
    <>
      <div className={isInputTextValidationSuccess ? "chatInputBox" : "chatInputBoxDanger"} >
        <div className={(isDisabled || startCountDownTimer) ? "disabled-container" : ""}>
          <div
            ref={scrollToEndOfChatRef}
          >
            <div>
              <Row>
                <Col span={23} className="chatInputContainer">
                  <MentionsInput
                    disabled={isMessageSending}
                    onKeyDown={handleKeyDown}
                    className="mentionInput"
                    style={defaultCss}
                    singleLine={true}
                    value={tempUserQuestion}
                    placeholder={placeHolder}
                    onChange={(event) => {
                      setTempUserQuestion(event.target.value);
                    }}>
                    {/* for students */}
                    <Mention
                      trigger="@"
                      markup="@[__display__]student_id(__id__)"
                      style={studentCss}
                      renderSuggestion={renderDropDown}
                      data={studentsArray}
                    />
                    {/* for assignments */}
                    <Mention
                      trigger="@"
                      markup="@[__display__]assignment_id(__id__)"
                      style={assignmentsMentionCss}
                      renderSuggestion={renderDropDown}
                      data={assignmentsArray}
                    />
                    {/* for courses */}
                    <Mention
                      trigger="#"
                      markup="@[__display__]course_id(__id__)"
                      style={courseMentionCss}
                      renderSuggestion={renderDropDown}
                      data={[{
                        id: selectedCourse.course_id,
                        display: selectedCourse.course_name,
                        avatarUrl: selectedCourse.image_url,
                      }]}
                    />
                    {/* for sections */}
                    <Mention
                      trigger="#"
                      markup="@[__display__]section_id(__id__)"
                      style={sectionMentionCss}
                      renderSuggestion={renderDropDown}
                      data={sectionArray}
                    />
                  </MentionsInput>
                </Col>

                <Col span={1}>
                  {
                    <div className="input-suffix">
                      {isMessageSending ?
                        <div className="chatSendLoading">
                          <img src={messageSendingGif} alt="loading..." />
                        </div>
                        : <div
                          className={"themeButton"}
                          onClick={() => {
                            onMessageSend();
                          }}
                        >
                          <SendOutlined />
                        </div>}
                    </div>
                  }
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {!isInputTextValidationSuccess && (
        <div className="chatTextValidation">
          Special characters are not allowed
        </div>
      )}

      <Row justify={"space-between"}>
        <Col span={6}>
          <div className="chatExplained">
            <QuestionCircleFilled />
            <a href="#_" onClick={() => {
              setIsUserGuidOpen(true);
            }}>
              How to use AI chat
            </a>
          </div>
        </Col>

        <Col span={18}>
          <div className="baseDisclaimer">
            Disclaimer: Beta Preview. Our Generative AI Engine may produce inaccurate insights.
            <a href="#_" onClick={() => {
              setIsDisclaimerModalOpen(true);
            }}>
              View Disclaimer
            </a>
          </div>
        </Col>
      </Row>

      <AiDisclaimerModal
        closeModal={closeDisclaimerModal}
        isOpen={isDisclaimerModalOpen}
      />
      <AiUserGuidModal
        closeModal={closeUserGuidModal}
        isOpen={isUserGuidOpen}
      />
    </>
  )
}

export default SendChatInput;
