import React from "react";
import {Card, Typography, Button} from "antd";
import PropTypes from "prop-types";

const {Paragraph} = Typography;

const OverviewCard = ({title, content, icon:Icon}) => {

    return (
        <Card>
            <div className="overview-card-des-div">
                <Paragraph  strong>{title}</Paragraph>
                {content}
            </div>
            <div className="overview-card-icon-div">
                {Icon && <Button className="icon" icon={<Icon/>} size="large" type="link" ghost/>}
            </div>
        </Card>
    )
}
OverviewCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    icon: PropTypes.node,
}

export default OverviewCard;