import React from "react";
const loder = `${process.env.REACT_APP_S3_BUCKET_URL}/jobready/public/jr-sync.gif`;
const SyncingBanner = () => (
    <div className="jr-loading-cover">
        <div className="jr-sync-loader">
            <div className="jr-loading-image">
                <img src={loder} alt="" />
            </div>
            <div className="jr-loading-text">Data synchronization in progress...</div>
        </div>
    </div>
)

export default SyncingBanner;