import {
    FETCH_STATS_REPORT_DATA_LOADING,
    FETCH_STATS_REPORT_DATA,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";

// fetch stats data loading
const fetchingStatsData = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_STATS_REPORT_DATA_LOADING,
        payload: {isLoading},
    });
};


// fetch stats data handler
const fetchStatsDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_STATS_REPORT_DATA,
        payload: {
            isLoading:false,
            error: response.error,
            data: response.data
        },
    });
};

// fetch stat data for sales admins
export const fetchStatsDataSales = (data) => dispatch => {
    dispatch(fetchingStatsData());

    service.make({
        service:"statistics/impression/fetchAllSuperAdmin",
        method:"POST",
        data,
    }).then(response => {
        dispatch(fetchStatsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchStatsDataStatus({
            error: error.message
        }))
    })
};

// fetch stat data for tenant admins
export const fetchStatsDataTenant = (data) => dispatch => {
    dispatch(fetchingStatsData());

    service.make({
        service:"statistics/impression/fetchAllTenantAdmin",
        method:"POST",
        data,
        authorized: true,
    }).then(response => {
        dispatch(fetchStatsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchStatsDataStatus({
            error: error.message
        }))
    })
};


