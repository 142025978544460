import React from "react";
import PropTypes from "prop-types";
import {Popover} from "antd";
import {CheckSquareTwoTone} from "@ant-design/icons";

const OutcomeCellCard = ({outcome}) => {
    const {proficiencyRating, proficiencyRatingColor, mark, mastered} = outcome;

    return (
        <div className="assignment-outcome-cell-main">
            <div>
                <div className="outcome-label" style={{textAlign:"center", backgroundColor:proficiencyRatingColor}}>
                    {proficiencyRating}
                </div>
            </div>
            <div className="outcomeCell" style={{display:"block", width:"100%"}}>
                <div className="grade" style={{width:"100%"}}>
                    {mark}
                    {mastered &&
                    <div style={{marginLeft: 10, display: "inline-block", verticalAlign: "middle"}}>
                        <CheckSquareTwoTone style={{fontSize: '16px'}} twoToneColor="#52c41a"/>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

OutcomeCellCard.propTypes = {
    outcome: PropTypes.object,
}

export default OutcomeCellCard;