import {
    FETCH_ASSIGNMENTS,
    FETCH_ASSIGNMENTS_LOADING,
    FETCH_ASSIGNMENTS_INFORMATION,
    FETCH_ASSIGNMENTS_INFORMATION_LOADING,
    FETCHING_ASSIGNMENT_FILTER_DATA,
    FETCHED_ASSIGNMENT_FILTER_DATA,
    FETCH_STUDENT_ASSIGNMENTS_CHART_DATA,
    CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA,
} from "../../constant/actionTypes";

const assignmentReducerDefaultState = {
    selected: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {},
            },
        }
    },
    list: {
        isLoading: false,
        error: false,
        response: {
            data: {
                result: {
                    assignment_cards: [],
                    assignment_comparison: [],
                    outcomes: [],
                    overall_course_grade: [],
                    overall_assignment_submission: [],
                },
            },
        }
    },
    assignmentInformation: {
        isLoading: false,
        error: false,
        response: {
            data: {
                name: "",
                description: "",
                comments: []
            }
        }
    },
    assignmentFilterData: {
        isLoading: false,
        error: false,
        data: {
            assignmentNodes: [],
            moduleNodes: []
        },
    },
    gradeCohortData: [],
    submissionCohortData: [],
};

const assignmentReducer = (state = assignmentReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_ASSIGNMENTS:
            return {
                ...state,
                list: action.payload
            };
        case FETCH_ASSIGNMENTS_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: action.payload.isLoading
                }
            };
        case FETCH_ASSIGNMENTS_INFORMATION:
            return {
                ...state,
                assignmentInformation: action.payload
            };
        case FETCH_ASSIGNMENTS_INFORMATION_LOADING:
            return {
                ...state,
                assignmentInformation: {
                    ...state.assignmentInformation,
                    isLoading: action.payload.isLoading
                }
            };
        case FETCHING_ASSIGNMENT_FILTER_DATA:
            return {
                ...state,
                assignmentFilterData: {
                    ...state.assignmentFilterData,
                    isLoading: action.payload,
                },
            };
        case FETCHED_ASSIGNMENT_FILTER_DATA:
            return {
                ...state,
                assignmentFilterData: action.payload,
            };
        case FETCH_STUDENT_ASSIGNMENTS_CHART_DATA:
            const { assignmentId, gradeCohortData, submissionCohortData } = action.payload;
            return {
                ...state,
                gradeCohortData: [...state.gradeCohortData, { assignmentId, data: gradeCohortData }],
                submissionCohortData: [...state.submissionCohortData, { assignmentId, data: submissionCohortData }],
            };
        case CLEAR_STUDENT_ASSIGNMENTS_CHART_DATA:
            return {
                ...state,
                gradeCohortData: assignmentReducerDefaultState.gradeCohortData,
                submissionCohortData: assignmentReducerDefaultState.submissionCohortData,
                list: assignmentReducerDefaultState.list,
            };
        default:
            return state;
    }
};

export default assignmentReducer;