import {
    SET_AUTHUSER,
    SET_SIDEBAR_ACTIVE,
    SET_SUB_USER,
    SET_SUB_USER_LOADING,
    REMOVE_SUB_USER,
    REMOVE_SUB_USER_LOADING,
    SET_INTERIM_USER,
    REMOVE_INTERIM_USER,
    REMOVE_INTERIM_USER_LOADING,
    REMOVE_INTERIM_USER_ERROR,
    USER_SIGN_IN,
    USER_SIGN_IN_LOADING,
    USER_SIGN_OUT,
    USER_SIGN_OUT_LOADING,
    USER_RESET_PASSWORD,
    USER_RESET_PASSWORD_LOADING,
    LICENSE_MANAGER_SEND_VERIFICATION_CODE,
    LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE,
    LICENSE_MANAGER_UPDATE_STATUS
} from "../constant/actionTypes";
import ApiServices from "../services/onboarding";
import service from "../utils/service";
import extAuthService from "../utils/serviceHandlers/common/customServices/extAuth";
import { onSuccess, onError } from "../utils/notificationHandler";
import { reactLocalStorage } from "reactjs-localstorage";
import localStore from "../utils/localStorage";

export const setAuthUser = (payload) => dispatch => dispatch({type: SET_AUTHUSER, payload})
// for tentacle
export const setInterimUser = (payload) => dispatch => dispatch({type: SET_INTERIM_USER, payload})

/** Interim User Log out **/
// Interim user Log out loading
const interimUserLogoutLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: REMOVE_INTERIM_USER_LOADING,
        payload: {isLoading},
    });
};


// Interim user Log out data handler
const interimUserLogoutDataStatus = (response, error=false) => dispatch => {
    dispatch({
        type: error ? REMOVE_INTERIM_USER_ERROR:  REMOVE_INTERIM_USER,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// Interim user Log out
export const interimUserLogoutAuth = (callback) => dispatch => {
    dispatch(interimUserLogoutLoading());

    service.make({
        service:`logout`,
        method: "GET",
    }).then(response => {
        dispatch(interimUserLogoutDataStatus({
            data: response.data
        }));
        callback();

    }).catch(error => {
        dispatch(interimUserLogoutDataStatus({
            error: error.message,
        }, true));

    })
};

export const setSideBarActive = (payload) => dispatch => dispatch({type: SET_SIDEBAR_ACTIVE, payload})

/** Onboarding sign in **/
// export const setITentacleUser = (payload) => dispatch => dispatch({type: SET_ITENTACLE_USER, payload})
export const removeSubUser = () => dispatch => dispatch({type: REMOVE_SUB_USER})

const loadingSetSubUser = (isLoading = true) => dispatch => {
    dispatch({
        type: SET_SUB_USER_LOADING,
        payload: {
            isLoading
        }
    });
};

const setSubUserStatus = data => dispatch => {
    const {isLoading, ...res} = data;
    dispatch({
        type: SET_SUB_USER,
        payload: {
            isLoading,
            response: {
                ...res.response,
                isLoggedIn: res.response ? true : undefined
            },
        }
    });
}

export const setSubUser = (credentials, errHandler) => dispatch => {
    dispatch(loadingSetSubUser());

    const showError = (response) => {
        errHandler("Sign in failed", response.error.toString())
        dispatch(setSubUserStatus(response));
    }

    const feedReducer = (response) => {
        dispatch(setSubUserStatus(response));
    }

    ApiServices.OnboardingAuth.login(credentials, feedReducer, showError)
};


/** External User Sign in **/
// external user auth loading
const externalUserAuthLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: USER_SIGN_IN_LOADING,
        payload: {isLoading},
    });
};


// external user auth data handler
const externalUserAuthDataStatus = (response) => dispatch => {
    dispatch({
        type: USER_SIGN_IN,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// external user auth
export const externalUserAuth = (data, callback) => dispatch => {
    dispatch(externalUserAuthLoading());

    service.make({
        service:`insights/jrp/vga/user/signin`,
        method: "POST",
        data
    }).then(response => {
        dispatch(externalUserAuthDataStatus({
            data: response.data
        }));
        callback(response.data);

    }).catch(error => {
        dispatch(externalUserAuthDataStatus({
            error: error.message,
        }));

    })
};

/** External User Sign out **/
// external user sign out loading
const externalUserSignOutLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: USER_SIGN_OUT_LOADING,
        payload: {isLoading},
    });
};


// external user sign out data handler
const externalUserSignOutDataStatus = (response) => dispatch => {
    dispatch({
        type: USER_SIGN_OUT,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// external user sign out
export const externalSignOut = (callback) => dispatch => {
    dispatch(externalUserSignOutLoading());

    extAuthService.make({
        service:`insights/jrp/vga/user/signout`,
        method: "POST",
        headers: {
            authorization: (process.env.REACT_APP_AUTH_OVERRIDE || "false") === "true" && localStore.getLsSlim(localStore.keys.authMeta, true, process.env.REACT_APP_EXTERNAL_AUTH_SECRET)?.accessToken
        }
    }).then(response => {
        dispatch(externalUserSignOutDataStatus({
            data: response.data
        }));
        callback();

    }).catch(error => {
        dispatch(externalUserSignOutDataStatus({
            error: error.message,
        }));
    })
};

/** External User Reset Password **/
// external user reset password loading
const externalUserResetPasswordLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: USER_RESET_PASSWORD_LOADING,
        payload: {isLoading},
    });
};


// external user reset password data handler
const externalUserResetPasswordDataStatus = (response) => dispatch => {
    dispatch({
        type: USER_RESET_PASSWORD,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// external user reset password
export const externalUserResetPassword = (data, callback) => dispatch => {
    dispatch(externalUserResetPasswordLoading());

    service.make({
        service:`insights/jrp/vga/user/setPassword`,
        method: "POST",
        data
    }).then(response => {
        dispatch(externalUserResetPasswordDataStatus({
            data: response.data
        }));
        callback(response.data);

    }).catch(error => {
        dispatch(externalUserResetPasswordDataStatus({
            error: error.message,
        }));

    })
};

// request verification code response trigger
const handleSendVerificationCodeResponse = (response) => dispatch => {
    const {isLoading, data} = response;
    if(!data){
        reactLocalStorage.remove("subUser");
        window.location.href = `/setup/login`;
    }
    dispatch({
        type: LICENSE_MANAGER_SEND_VERIFICATION_CODE,
        payload: {
            isLoading: isLoading,
            verificationId: data.verificationId,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

export const sendVerificationCode = (data) => dispatch => {
    service.make({
        service:`sendVerificationCode`,
        method: "POST",
        data
    }).then(response => {
        dispatch(handleSendVerificationCodeResponse(response));
        onSuccess(response.message, response.data.msg);

    }).catch(error => {
        dispatch(handleSendVerificationCodeResponse({
            error: error.message,
        }));

    })
};

// send verification code response trigger
const handleValidateVerificationCodeResponse = (response) => dispatch => {
    dispatch({
        type: LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE,
        payload: {
            isLoading: response.isLoading,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

export const validateVerificationCode = (data, callback) => dispatch => {
    service.make({
        service:`validateVerificationCode`,
        method: "POST",
        data
    }).then(response => {
        dispatch(handleValidateVerificationCodeResponse({
            data: response.data
        }));
        if(!response.error){
            callback(response.data);    
        }
    }).catch(error => {
        dispatch(handleValidateVerificationCodeResponse({
            error: error.message,
        }));
    })
};

export const updateSubUserStatus = response => dispatch => {
    const {isLoading, data} = response;
    dispatch({
        type: LICENSE_MANAGER_UPDATE_STATUS,
        payload: {
            isLoading: isLoading,
            ...(response.data && {tokenValidity: data.tokenValidity}),
            tokenValidity: data.tokenValidity,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
}
export const updateSubUser = (data, callback) => dispatch => {
    service.make({
        service:`checkLicenseManagerStatus`,
        method: "POST",
        data
    }).then(response => {
        dispatch(updateSubUserStatus({
            data: response.data
        }));
        callback(response.data);  
    }).catch(error => {
        dispatch(updateSubUserStatus({
            error: error.message,
        }));

    })
    
};

export const policyTermsAgreed = () => dispatch => {
    service.make({
        service:`policyAgreed`,
        method: "GET",
    }).then(response => {
        dispatch(handleValidateVerificationCodeResponse({
            data: response.data
        }));
    }).catch(error => {
        dispatch(handleValidateVerificationCodeResponse({
            error: error.message,
        }));
    })
};


// external user reset password
export const validateResetToken = (data, callback) => dispatch => {
    dispatch(externalUserResetPasswordLoading());
    service.make({
        service:`statistics/impression/validateResetToken`,
        method: "POST",
        data
    }).then(response => {
        dispatch(externalUserResetPasswordDataStatus({
            data: response.data
        }));
        callback(response.data);
    }).catch(error => {
        dispatch(externalUserResetPasswordDataStatus({
            error: error.message,
        }));
        callback({error: true});
    })
};