import {
    FETCH_VW_DEALERSHIP_USERS,
    FETCH_VW_DEALERSHIP_USERS_LOADING,
    FETCH_VW_DEALERSHIP_USER,
    FETCH_VW_DEALERSHIP_USER_LOADING,
    SAVE_VW_DEALERSHIP_USER_LOADING,
    DELETE_VW_DEALERSHIP_USER_LOADING,
    UPDATE_VW_DEALERSHIP_USER_LOADING,
    USER_FETCH_DEALERSHIPS,
    USER_FETCH_DEALERSHIPS_LOADING,
    USER_RESET_PW_LINK_SEND_LOADING
} from "../../../constant/actionTypes";

import service from "../../../utils/serviceHandlers/jobReady/customServices/jrDashboardManagement";
import {onError, onSuccess} from "../../../utils/notificationHandler";

/** Users data fetch **/
// fetch users data loading
const fetchingUsersDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_VW_DEALERSHIP_USERS_LOADING,
        payload: {isLoading},
    });
};


// fetch users data handler
const fetchUsersDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_VW_DEALERSHIP_USERS,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch users data
export const fetchUsersData = () => dispatch => {
    dispatch(fetchingUsersDataLoading());

    service.make({
        service:`insights/jrp/vga/user/fetchAllUsers`,
        method: "POST",
    }).then(response => {
        dispatch(fetchUsersDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchUsersDataStatus({
            error: error.message
        }))
    })
};

/** Selected User data fetch **/
// fetch user data loading
const fetchingUserDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_VW_DEALERSHIP_USER_LOADING,
        payload: {isLoading},
    });
};


// fetch user data handler
const fetchUserDataStatus = (response) => dispatch => {
    dispatch({
        type: FETCH_VW_DEALERSHIP_USER,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch user data
export const fetchUserData = (data) => dispatch => {
    dispatch(fetchingUserDataLoading());

    service.make({
        service:`insights/jrp/vga/user/fetchUser`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchUserDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchUserDataStatus({
            error: error.message
        }))
    })
};

/** Save User data **/
// save user data loading
const saveUserDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_VW_DEALERSHIP_USER_LOADING,
        payload: {isLoading},
    });
};

// save user data
export const saveUserData = (data, callback) => dispatch => {
    dispatch(saveUserDataLoading());

    service.make({
        service:`insights/jrp/vga/user/addUsers`,
        method: "POST",
        data
    }).then(response => {
        dispatch(saveUserDataLoading(false));
        onSuccess("User added successfully!");
        callback();
    }).catch(error => {
        dispatch(saveUserDataLoading(false));
    })
};

/** Delete User data **/
// delete user data loading
const deleteUserDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: DELETE_VW_DEALERSHIP_USER_LOADING,
        payload: {isLoading},
    });
};

// delete user data
export const deleteUserData = (data) => dispatch => {
    dispatch(deleteUserDataLoading());

    service.make({
        service:`insights/jrp/vga/user/deleteUsers`,
        method: "POST",
        data
    }).then(response => {
        dispatch(deleteUserDataLoading(false));
        onSuccess("User deleted successfully!");
        dispatch(fetchUsersData());
    }).catch(error => {
        dispatch(deleteUserDataLoading(false));
    })
};

/** Update User data **/
// update user data loading
const updateUserDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: UPDATE_VW_DEALERSHIP_USER_LOADING,
        payload: {isLoading},
    });
};

// update user data
export const updateUserData = (data, callback) => dispatch => {
    dispatch(updateUserDataLoading());

    service.make({
        service:`insights/jrp/vga/user/updateUsers`,
        method: "POST",
        data
    }).then(response => {
        dispatch(updateUserDataLoading(false));
        onSuccess("User updated successfully!");
        callback();
    }).catch(error => {
        dispatch(updateUserDataLoading(false));
    })
};

/** Fetch dealerships data **/
// fetch dealerships data loading
const fetchingDealershipsDataLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: USER_FETCH_DEALERSHIPS_LOADING,
        payload: {isLoading},
    });
};


// fetch dealerships data handler
const fetchDealershipsDataStatus = (response) => dispatch => {
    dispatch({
        type: USER_FETCH_DEALERSHIPS,
        payload: {
            isLoading: false,
            error: response.error,
            ...(response.data && {data: response.data})
        },
    });
};

// fetch dealerships data
export const fetchDealershipsData = (data) => dispatch => {
    dispatch(fetchingDealershipsDataLoading());

    service.make({
        service:`insights/jrp/vga/user/fetchAllDealerships`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchDealershipsDataStatus({
            data: response.data
        }))
    }).catch(error => {
        dispatch(fetchDealershipsDataStatus({
            error: error.message
        }))
    })
};

/** Send Reset Password Link **/
// send reset password link loading
const sendResetPasswordLinkLoading = (isLoading = true) => dispatch => {
    dispatch({
        type: USER_RESET_PW_LINK_SEND_LOADING,
        payload: {isLoading},
    });
};

// send reset password link
export const sendResetPasswordLink = (data) => dispatch => {
    dispatch(fetchingDealershipsDataLoading());

    service.make({
        service:`insights/jrp/vga/user/generateResetToken`,
        method: "POST",
        data
    }).then(response => {
        dispatch(fetchingDealershipsDataLoading(false));
        onSuccess("Reset password link has been sent successfully!");
    }).catch(error => {
        dispatch(fetchingDealershipsDataLoading(false));
    })
};



