import React, { useEffect, useState } from "react";
import { usePrevious } from "../../../../utils/innovative/customHooks";
import { useSelector, useDispatch } from "react-redux";
import {
  getClusterSectionFilters,
  getSelectedClusterSections,
} from "../../../../selectors/innovative/course.selector";
import {
  fetchClusterData,
  updateClusterSection,
} from "../../../../actions/innovative/course.action";
import session from "../../../../utils/session";
import { Row, Col, Select, Button, Checkbox } from "antd";
import { get as _get } from 'lodash';
import { compareArrays } from "../../../../utils/general";

const DEFAULT_SELECTION = "Default";

const SectionFilter = () => {
  const dispatch = useDispatch();

  const [placeholder, setPlaceholder] = useState(DEFAULT_SELECTION);
  const [selectedSections, setSelectedSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSectionsNames, setSelectedSectionsNames] = useState([]);
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);
  const sectionFilters = useSelector(getClusterSectionFilters);
  const selectedSectionFilters = useSelector(getSelectedClusterSections);

  const previousValues = usePrevious({
    sectionFilters,
    selectedSectionFilters,
  });

  const applyPlaceHolder = selectedSectionNamesArray => {
    if (selectedSectionNamesArray.includes(DEFAULT_SELECTION)) {
      return setPlaceholder(DEFAULT_SELECTION);
    }
    if (selectedSectionNamesArray.length === 1) {
      return setPlaceholder(selectedSectionNamesArray[0]);
    }
    const stringForPlaceholder = selectedSectionNamesArray.join(', ');
    setPlaceholder(stringForPlaceholder);
  };

  useEffect(() => {
    setSelectedSections(selectedSectionFilters);
    applyPlaceHolder(selectedSectionFilters.map(section => section.text));
  }, [JSON.stringify(selectedSectionFilters)]);

  useEffect(() => {
    const selectedSectionsNamesArray = selectedSections.map(item => item.text);
    setSelectedSectionsNames(selectedSectionsNamesArray);
  }, [JSON.stringify(selectedSections)]);

  useEffect(() => {
    const prevSelectedSectionFilters = _get(previousValues, 'selectedSectionFilters', []);
    const prevSelectedSectionsNamesArray = prevSelectedSectionFilters.map(item => item.text);

    const hasSelectionsNotChanged = compareArrays(prevSelectedSectionsNamesArray, selectedSectionsNames);
    if (selectedSectionsNames.length && !hasSelectionsNotChanged) {
      setApplyButtonDisabled(false);
      return;
    }
    if (hasSelectionsNotChanged) {
      setApplyButtonDisabled(true);
    }

  }, [JSON.stringify(selectedSectionsNames), JSON.stringify(previousValues)]);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const handleOnChange = e => {
    const { value, checked } = e.target;
    if (value === DEFAULT_SELECTION) {
      if (checked) {
        return setSelectedSections(sectionFilters);
      }
      return setSelectedSections([]);
    }

    const relatedSection = sectionFilters.find(section => section.text === value);
    if (checked) {
      return setSelectedSections([...selectedSections, relatedSection]);
    }
    const removeUncheckedSection = selectedSections.filter(item => item.text !== value && item.text !== DEFAULT_SELECTION);
    return setSelectedSections(removeUncheckedSection);
  };

  const handleOnApply = () => {
    handleOnClick();
    onFilterApply(selectedSections);
  };

  const getDropDown = () => (
    <div className="al-custom-dropdown">
      {sectionFilters.map(section => {
        return (
          <>
            <Checkbox
              key={section.id}
              onChange={handleOnChange}
              checked={selectedSectionsNames.includes(section.text)}
              value={section.text}
            >
              {section.text}
            </Checkbox>
            <br/>
          </>
        )
      })}
      <Button
        style={{ marginTop: 10, width: "100%" }}
        type='primary' 
        onClick={handleOnApply}
        disabled={applyButtonDisabled}
      >
        Apply
      </Button>
    </div>
  );

  const onFilterApply = (selectedSectionsArray) => {
    if (selectedSectionsArray?.length) {
      const validatePayload = () => {
        if (selectedSectionsArray.map(section => section.text).includes(DEFAULT_SELECTION)) {
          return [];
        }

        return selectedSectionsArray.map(section => section.id);
      }

      dispatch(updateClusterSection({
        selectedSectionArray: selectedSectionsArray,
      }));

      dispatch(fetchClusterData(
        {
          "user_id": session.get([
            session.keys.innovativeMeta,
            "token"
          ]),
          filters: {
            sections: validatePayload(),
          },
        },
        false
      ));
    }
  };

  return (
    <Row justify={"end"}>
        <div className="sectionContainer">
          <h5>Sections</h5>
          <div id="innovative-course-statue-select">
            <Select
              style={{ width: '100%' }}
              value={placeholder}
              open={open}
              onDropdownVisibleChange={handleOnClick}
              dropdownRender={getDropDown}
              options={[]}
              getPopupContainer={
                triggerNode => triggerNode.parentElement
              }
            />
          </div>
        </div>
    </Row>
  );
}

export default SectionFilter;
