import React from "react";
import {Card, Table, Empty} from "antd";
import PropTypes from "prop-types";

import Loader from "../../common/layout/Loader";


const OutcomeGrid = ({isLoading, dataSource, columns, pagination}) => {

    return (
        <Card className="tableCoverCard">
            <Loader isLoading={isLoading}/>
            {dataSource && dataSource.length > 0 ?
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                    scroll={{x: "max-content"}}
                />
                :
                <Empty/>
            }
        </Card>
    )
}

OutcomeGrid.defaultProps = {
    isLoading: false,
};

OutcomeGrid.propTypes = {
    isLoading: PropTypes.bool,
    onSearch: PropTypes.func,
    dataSource: PropTypes.array,
    columns: PropTypes.array,
}

export default OutcomeGrid;