import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input, Row, Space } from 'antd';
import _lowerCase from 'lodash/lowerCase';
import _isEqual from 'lodash/isEqual';

import VetDrawer from '../../../../common/Drawer';
import Loader from '../../../../common/layout/Loader';

const SubDrawer = ({
  title,
  subDrawerConfig,
  options,
  isLoading,
  form,
  onSelectOptions,
  selectedOptions,
}) => {
  const [drawerOptions, setDrawerOptions] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  // effects
  useEffect(() => {
    if (options) {
      setDrawerOptions(options);
    }
  }, [options]);

  useEffect(() => {
    // this is used to set the initial value of the "select all" checkbox
    if (selectedOptions) {
      const list = options.map(i => i.id);
      const selectedOptionsIds = selectedOptions.map(i => i.id);
      const isIdentical = _isEqual(selectedOptionsIds.sort(), list.sort());
      if (isIdentical) {
        setCheckAll(true);
        setIndeterminate(false);
      }
      if (!selectedOptionsIds.length) {
        setIndeterminate(false);
        setCheckAll(false);
      }
      if (selectedOptionsIds.length && !isIdentical) {
        setIndeterminate(true);
        setCheckAll(false);
      }
    }
  }, [selectedOptions, subDrawerConfig]);

  const onSelect = () => {
    // get actual object from selected ids
    const finalOptions = options.filter(i =>
      form.getFieldValue('options').includes(i.id),
    );
    onSelectOptions(finalOptions);
    form.resetFields();
    subDrawerConfig.onClose();
  };

  const onClose = () => {
    setCheckAll(false);
    setIndeterminate(false);
    subDrawerConfig.onClose();
  };

  const renderDrawerFooter = () => (
    <div style={{ textAlign: 'end' }}>
      <Space>
        <Button disabled={isLoading} onClick={subDrawerConfig.onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onSelect} type="primary">
          Select
        </Button>
      </Space>
    </div>
  );

  const renderDrawerSection = () => {
    const onSearch = value => {
      setDrawerOptions([
        ...options.filter(option =>
          _lowerCase(option.name).includes(_lowerCase(value)),
        ),
      ]);
    };

    const onChange = list => {
      setIndeterminate(!!list.length && list.length < options.length);
      setCheckAll(list.length === options.length);
    };

    const onCheckAllChange = e => {
      form.setFieldsValue({
        options: e.target.checked ? options.map(item => item.id) : [],
      });
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    };

    return (
      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input.Search placeholder="Search" onSearch={onSearch} />
          <Form
            name="optionForm"
            onFinish={() => form.resetFields()}
            form={form}
          >
            <Row>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Select All
              </Checkbox>
            </Row>
            <Form.Item name="options">
              <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                {drawerOptions.map(option => (
                  <Row>
                    <Checkbox value={option.id}>{option.name}</Checkbox>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Space>
      </div>
    );
  };

  return (
    <VetDrawer
      closable
      title={title}
      footer={renderDrawerFooter()}
      onClose={onClose}
      visible={subDrawerConfig.subDrawerVisible}
      width={350}
      className="vtt-gen-set-sub-drawer"
    >
      <Loader isLoading={isLoading}>{renderDrawerSection()}</Loader>
    </VetDrawer>
  );
};

SubDrawer.propTypes = {
  title: PropTypes.string,
  subDrawerConfig: PropTypes.object,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  form: PropTypes.object,
  onSelectOptions: PropTypes.func,
  selectedOptions: PropTypes.array,
};

export default SubDrawer;
