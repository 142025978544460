import {
  FETCH_SETTINGS,
  FETCH_SETTINGS_LOADING,
  SAVE_SETTINGS,
  SAVE_SETTINGS_LOADING,
} from "../../constant/actionTypes";
import { settingsReducerDefaultState } from "../../constant/innovative/settingState";

const settingsReducer = (state = settingsReducerDefaultState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        selected: action.payload,
      };
    case FETCH_SETTINGS_LOADING:
      return {
        ...state,
        selected: {
          ...state.selected,
          isLoading: action.payload.isLoading,
        },
      };
    case SAVE_SETTINGS:
      return {
        ...state,
        saved: action.payload,
      };
    case SAVE_SETTINGS_LOADING:
      return {
        ...state,
        saved: {
          ...state.saved,
          isLoading: action.payload.isLoading,
        },
      };
    default:
      return state;
  }
};

export default settingsReducer;
