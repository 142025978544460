import {
    JR_GEN_OVERVIEW_CHARTS_FETCH,
    JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING,
    JR_GEN_OVERVIEW_STUDENTS_FETCH,
    JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING
} from "../../../constant/actionTypes";

const defaultState = {
    charts: {
        isLoading: false,
        data: {}
    },
    students: {
        isLoading: false,
        data: {}
    }
}

const raOverviewReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_OVERVIEW_CHARTS_FETCH:
        case JR_GEN_OVERVIEW_CHARTS_FETCH_LOADING:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    ...action.payload
                }
            }
        case JR_GEN_OVERVIEW_STUDENTS_FETCH:
        case JR_GEN_OVERVIEW_STUDENTS_FETCH_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default raOverviewReducer;