import request from "../../utils/request";

export default {
    login: async function (credentials, onSuccess, onError) {
        credentials.userCategory = "partner";
        const requestOptions = {
            service: `login`,
            method: "POST",
            onSuccess: onSuccess,
            onError: onError,
            data: credentials,
            redirection: false
        };

        request.make(requestOptions);
    },

}