import React from 'react';
import { Breadcrumb as AntBreadcrumb, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { getBreadcrumb } from '../../../../../selectors/vetTrack/dashboardManagement.selector';
import { setBreadcrumb } from '../../../../../actions/vetTrack/dashboardManagement/layout.action';

const Breadcrumb = props => {
  const { hideBackButton, history } = props;
  const dispatch = useDispatch();
  const breadcrumb = useSelector(getBreadcrumb);

  const handleGoBack = () => {
    const { segments } = breadcrumb;
    if (segments && segments.length > 0) {
      segments.pop();
      dispatch(setBreadcrumb(segments));
      history.goBack();
    }
  };

  const handleBreadCrumbClick = key => {
    const { segments } = breadcrumb;
    if (segments && segments.length > 0) {
      const newBreadcrumbItems = segments.slice(
        0,
        segments.indexOf(segments.find(item => item.key === key)) + 1,
      );
      dispatch(setBreadcrumb(newBreadcrumbItems));
    }
  };

  const renderBreadcrumbItems = () => {
    if (!breadcrumb && breadcrumb.segments && breadcrumb.segments.length) {
      return null;
    }

    return breadcrumb.segments.map(({ section, href, key, ...rest }) => {
      if (!href) {
        return (
          <AntBreadcrumb.Item key={key} {...rest}>
            {section}
          </AntBreadcrumb.Item>
        );
      }

      return (
        <AntBreadcrumb.Item {...rest}>
          <Link to={href} onClick={() => handleBreadCrumbClick(key)}>
            {section}
          </Link>
        </AntBreadcrumb.Item>
      );
    });
  };

  return (
    <div className="vtt-breadcrumb">
      {!hideBackButton && (
        <div className="backLink">
          <Button
            size="small"
            icon={<ArrowLeftOutlined />}
            ghost
            onClick={handleGoBack}
          />
        </div>
      )}
      <AntBreadcrumb separator={<RightOutlined />}>
        {renderBreadcrumbItems()}
      </AntBreadcrumb>
    </div>
  );
};

Breadcrumb.propTypes = {
  // React Router bindings
  history: PropTypes.object.isRequired,

  // Parent Props
  hideBackButton: PropTypes.bool,
  type: PropTypes.string,
};

Breadcrumb.defaultProps = { hideBackButton: false, type: 'tenant' };

export default withRouter(Breadcrumb);
