import React, { Suspense } from 'react';
import { useSelector } from "react-redux";
import {
    makeInnovativeSettingByPath,
} from "../../../../selectors/innovative/setting.selector";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";

const ConditionalColors = React.lazy(() => import('../../common/settingsComponents/conditionalColors'))

const ConditionalColorsSettingsContainer = ({ onSubmit, onResetDefault }) => {
    const pageSettingsPath = "conditionalColors";
    const pageSettings = useSelector(makeInnovativeSettingByPath(pageSettingsPath));

    return (
        <Suspense fallback={<Loader isLoading={true} />}>
            <ConditionalColors pageSettings={pageSettings} pageSettingsPath={pageSettingsPath} onResetDefault={onResetDefault} onSubmit={onSubmit} />
        </Suspense>
    )
};

ConditionalColorsSettingsContainer.propTypes = {
    onSubmit: PropTypes.func,
};

export default ConditionalColorsSettingsContainer;