import React from "react";
import PropTypes from "prop-types";
import {Card, Col, Divider, Row} from "antd";

const FormLayout = ({form, infoContent, cardStyles}) => {

    return (
        <Card style={cardStyles}>
            <Row>
                <Col span={12}>
                    {form}
                </Col>
                <Col span={2}>
                    <Divider type="vertical"  style={{height:"100%"}}/>
                </Col>
                <Col span={10}>
                    {infoContent}
                </Col>
            </Row>
        </Card>
    )

}

FormLayout.defaultProps = {
    cardStyles: {margin: "1% 21%"}
}

FormLayout.propTypes = {
    form: PropTypes.node,
    infoContent: PropTypes.node,
}

export default FormLayout;