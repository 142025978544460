import React from "react";
import {Space, Empty} from "antd";
import PropTypes from "prop-types";

import FilterForm from "./partials/filterForm";
import Pathway from "./partials/pathway";
import DataTable from "../dataTable";

const QualificationPathway = ({nodes, showDataTable, dataTableConfig, studentsDataLoading, tableData, filterMeta}) => {

    return (
        <div>
            <Space direction="vertical" size="large" style={{width:"100%"}}>
                <FilterForm setSelectedFiltersMeta={filterMeta.setSelectedFiltersMeta}/>
                <div>
                    {nodes && nodes.length ?
                    <Pathway nodes={nodes} onDataTableShowClick={dataTableConfig.onShowDataTable}/>
                    : <Empty style={{marginTop: 100}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </div>
            </Space>
            <DataTable
                visible={showDataTable}
                handleCancel={dataTableConfig.handleCancel}
                isLoading={studentsDataLoading}
                data={tableData}
            />
        </div>
    )
}

QualificationPathway.propTypes = {
    nodes: PropTypes.array,
    showDataTable: PropTypes.bool,
    studentsDataLoading: PropTypes.bool,
    tableData: PropTypes.object,
    dataTableConfig: PropTypes.object,
    filterMeta: PropTypes.object,
}

export default QualificationPathway;