import React, { memo } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import PieChart from '../../../../../../../../../../common/charts/PieChart';
import NAMessage from '../../../../../../../../NAMessage';

import { finacialChartConf } from '../../../../../../../../../../../../../constant/vetTrack/configs';

const { Paragraph } = Typography;

const ChartCard = ({ name, percentage, metaConfig, dataset, enabled }) => {
  const titleData = {
    title: {
      text: percentage ? `${percentage}%` : '',
      verticalAlign: 'middle',
      style: {},
    },
  };

  const seriesData = {
    series: [
      {
        colorByPoint: true,
        states: {
          hover: {
            enabled: false,
            brightness: 0,
          },
        },
        data: dataset,
      },
    ],
  };

  const getContent = () => {
    if (!enabled) return <NAMessage />;

    return (
      <PieChart chartOptions={{ ...finacialChartConf, ...titleData, ...seriesData }} />
    );
  };

  return (
    <div>
      <Paragraph style={{ textAlign: 'center' }} strong>
        {name}
      </Paragraph>
      {getContent()}
    </div>
  );
};

ChartCard.defaultProps = {
  enabled: true,
};

ChartCard.propTypes = {
  name: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  metaConfig: PropTypes.object,
  dataset: PropTypes.array,
  enabled: PropTypes.bool,
};

export default memo(ChartCard);
