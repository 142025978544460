import {
    GET_DOMAIN_META,
    GET_DOMAIN_META_LOADING
} from "../constant/actionTypes";
import localStore from "../utils/localStorage";

const tentacleMetaDefaultState = {
    domainMeta: {
        isLoading:false,
        error: false,
        data: localStore.getLsSlim('domain.meta') || {}
    }
};

const tentacleMetaReducer = (state = tentacleMetaDefaultState, action) => {
    switch (action.type) {
        case GET_DOMAIN_META_LOADING:
            return {
                ...state,
                domainMeta: {
                    ...state.domainMeta,
                    ...action.payload
                }
            }
        case GET_DOMAIN_META:
            localStore.setLsSlim('domain.meta', action.payload && action.payload.data)
            return {
                ...state,
                domainMeta: {
                    ...state.domainMeta,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default tentacleMetaReducer;
