import {
    SET_CANVAS_KEYS,
    SET_CANVAS_KEYS_LOADING,
    VALIDATE_USER_TOKEN,
    VALIDATE_USER_TOKEN_LOADING
} from "../constant/actionTypes";

const signupReducerDefaultState = {
    canvasSetup: {
        isLoading: false,
        error: false,
        response: undefined,
    },
    validateUserToken: {
        isLoading: false,
        error: false,
        response: undefined,
    }
};

const SignupReducer = (state = signupReducerDefaultState, action) => {
    switch (action.type) {
        case SET_CANVAS_KEYS:
            return {
                ...state,
                canvasSetup: action.payload
            };
        case SET_CANVAS_KEYS_LOADING:
            return {
                ...state,
                canvasSetup: {
                    ...state.canvasSetup,
                    isLoading: action.payload.isLoading
                }
            };
        case VALIDATE_USER_TOKEN:
            return {
                ...state,
                validateUserToken: action.payload
            };
        case VALIDATE_USER_TOKEN_LOADING:
            return {
                ...state,
                validateUserToken: {
                    ...state.validateUserToken,
                    isLoading: action.payload.isLoading
                }
            };
        default:
            return state;
    }
};

export default SignupReducer;