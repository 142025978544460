import React from "react";
import { Card } from "antd";
import { UserAddOutlined } from '@ant-design/icons';

const NoStudentSelectView = ({ isAdminPath, selectedStudent }) => {
	return (
		<>
			{
				isAdminPath && !selectedStudent && (
					<Card
						className={"no-student-select-card"}
					>
						<UserAddOutlined className="no-student-select-icon" />
						<h3>
							Please Select a student
						</h3>
						<p>
							All the students of the selected account will get listed in the search box.
						</p>
					</Card>
				)
			}
		</>
	)
}

export default NoStudentSelectView;
