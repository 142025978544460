import React, {Fragment} from 'react';
import PropTypes from "prop-types";

const icons = `${process.env.REACT_APP_S3_BUCKET_URL}/canvas/public/speedometer.png`;

const StatsCard = ({summary}) => {
    const {icon: Icon, ...rest} = summary;

    const renderList = () => {
        if (!(rest && rest.content && rest.content.list && Array.isArray(rest.content.list))) {
            return null;
        }

        return rest.content.list.map((listItem) => (
            <Fragment key={listItem.title}>
                <div className="textFragment">
                    <h5>{listItem.title}</h5>
                    <h1 style={{color: listItem.color}}>
                        {listItem.value}
                    </h1>
                </div>
            </Fragment>
        ));
    };

    const renderStat = (data) => {
        if (!(data && data.stat)) {
            return null;
        }

        return (
            <div style={{fontSize: "14px"}}>
                <span>
                    <strong>{data.stat.title}</strong>:
                </span>
                <span>&nbsp;{data.stat.value}</span>
                {data.stat.suffix ? <span>{data.stat.suffix}</span> : null}
            </div>
        );
    }

    const renderDatum = () => {
        if (!(rest && rest.content && rest.content.datum)) {
            return null;
        }

        return (
            <Fragment>
                <div style={{color: rest.content.datum.color}}>
                    <span>{rest.content.datum.value}</span>
                    {rest.content.datum.suffix ? rest.content.datum.suffix : null}
                </div>
                {renderStat(rest.content.datum)}
            </Fragment>
        );
    }

    return (
        <div className={`textWidget widget-${rest.type}`}>
            <div className="in-iconCover">
                <span className="in-icon" style={{backgroundColor: summary.color}}>
                    <img src={summary.img ?? icons} alt="iocns"/>
                </span>
                <div className="makeVerticleCenter">
                    <span>
                        {rest.title}
                    </span>
                </div>
            </div>
            <div className="valueContainer">
                {renderList()}
                {renderDatum()}
            </div>
            <div
                className="colorscreen"
                style={{
                    backgroundColor: `${summary.color}10`,
                    borderColor: `${summary.color}ff`
                }}
            />
        </div>
    );
};

StatsCard.propTypes = {
    summary: PropTypes.object,
};

export default StatsCard;