import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tabs } from 'antd';
import { get as _get } from 'lodash';

import SettingHeader from '../../common/SectionHeader';
import SettingButtonGroup from '../partials/buttonGroup/ButtonGroup';
import RITabSection from './partials/TabContent';
import Loader from '../../common/layout/Loader';
import SyncingBanner from '../partials/hoc/syncingBanner/SyncingBannerView';
import SyncMessage from '../partials/SyncMessage';

import {
  settingsSections,
  subSections,
} from '../../../../../constant/vetTrack/analysis';
import { menu } from '../../../../../constant/vetTrack/menu';

const { TabPane } = Tabs;
const academicKey = _get(subSections, 'academic.key', '');
const financialKey = _get(subSections, 'financial.key', '');
const complianceKey = _get(subSections, 'compliance.key', '');

const RiskIndicatorsView = ({ isLoading, tabConfig, isSyncing, syncObject }) => {
  const getSyncMessage = () => (
    <div className="vtt-datasources-sub-header no-title">
      <SyncMessage syncData={syncObject} />
    </div>
  );

  return (
    <div>
      <Loader isLoading={isLoading} />
      <SettingHeader
        title="Risk Indicators"
        items={[
          { name: 'Risk Analysis', href: menu.riskAnalysis },
          'Settings',
          'Risk Indicators',
        ]}
      />
      <Tabs activeKey={tabConfig.activeTab} onChange={tabConfig.setActiveTab}>
        <TabPane tab="Academic" key={academicKey}>
          {tabConfig.activeTab === academicKey && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
            {getSyncMessage()}
            <RITabSection subSection={academicKey} />
            <SettingButtonGroup
              section={settingsSections.riskIndicators}
              subSection={academicKey}
            />
          </Card>
        </TabPane>

        <TabPane tab="Financial" key={financialKey}>
          {tabConfig.activeTab === financialKey && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
          {getSyncMessage()}
            <RITabSection subSection={financialKey} />
            <SettingButtonGroup
              section={settingsSections.riskIndicators}
              subSection={financialKey}
            />
          </Card>
        </TabPane>

        <TabPane tab="Compliance" key={complianceKey}>
          {tabConfig.activeTab === complianceKey && isSyncing && (
            <SyncingBanner />
          )}
          <Card>
            {getSyncMessage()}
            <RITabSection subSection={complianceKey} />
            <SettingButtonGroup
              section={settingsSections.riskIndicators}
              subSection={complianceKey}
            />
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

RiskIndicatorsView.defaultProps = {
  isSyncing: false,
};

RiskIndicatorsView.propTypes = {
  isLoading: PropTypes.bool,
  tabConfig: PropTypes.object,
  isSyncing: PropTypes.bool,
  syncObject: PropTypes.object,
};

export default RiskIndicatorsView;
