import React, {useEffect} from "react";

import StatDataView from "./partials/statDataView/StatDataView";
import menuData from "../../layout/dashboardManagement/menuData";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";

import {setBreadcrumb, setMenuSelected} from "../../../actions/dashboardManagement/layout.action";
import {getStatsReportData, getStatsReportDataLoading} from "../../../selectors/dashboardManagement/stats.selector";
import {fetchStatsDataTenant} from "../../../actions/dashboardManagement/stats.action";
import moment from "moment";

const StatDataViewContainer = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    //selectors
    const reportData = useSelector(getStatsReportData);
    const reportDataLoading = useSelector(getStatsReportDataLoading);


    const breadcrumbItems = [
        {
            key: 'admin',
            section: "Admin",
        },
        {
            key: menuData.stats.key,
            section: menuData.stats.name,
        },
        {
            key: menuData.statsDataView.key,
            section: menuData.statsDataView.name,
        },
    ];

    const fetchStatData = () => {
        const {dateRange} = form.getFieldsValue();
        const statParams = {
            start_date:dateRange[0].format("YYYY/MM/DD"),
            end_date:dateRange[1].format("YYYY/MM/DD")
        }
        dispatch(fetchStatsDataTenant(statParams))
    }

        const filtersOnSubmit = () => {
        fetchStatData();
    }



    //set breadcrumb, menu
    useEffect(() => {
        dispatch(setMenuSelected(menuData.stats.key));
        dispatch(setBreadcrumb(breadcrumbItems))

        form.setFieldsValue({
            dateRange: [moment(), moment()],
        });
        fetchStatData();
    }, [])

    return (
        <StatDataView filterForm={form} filterSubmit={filtersOnSubmit} reportData={reportData} reportDataLoading={reportDataLoading}/>
    )
}

export default StatDataViewContainer