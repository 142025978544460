import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import Countdown, { zeroPad } from 'react-countdown-now';
import { useDispatch } from "react-redux";
import { requestClusterDataConfirmation } from "../../../../../actions/innovative/course.action";
import { UndoOutlined } from '@ant-design/icons';
import rateErrorSvg from "../../../../../assets/images/chatGPT/rate-error.svg";

const ClusterDataCountDownCard = ({ startCountDownTimer, retryAt, onCountDownComplete }) => {
  const dispatch = useDispatch();

  const [isRefreshButtonVisible, setRefreshButtonVisible] = useState(false);

  const countDownRenderer = ({ minutes, seconds }) => {
    return <div className={"cluster-countdown-timer"}>{`${minutes}:${zeroPad(seconds)}`}</div>;
  };

  const renderStatus = () => {

    if (isRefreshButtonVisible) {
      return (
        <div className={"cluster-drop-down-padding"}>
          <Tooltip title={"Refresh cluster chart"}>
            <Button
              size={"large"}
              icon={<UndoOutlined />}
              onClick={() => {
                dispatch(requestClusterDataConfirmation());
                onCountDownComplete();
              }}
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={"cluster-drop-down-padding"}>
        <div>
          Limit Resets in
        </div>
        {startCountDownTimer && (
          <Countdown
            renderer={countDownRenderer}
            date={new Date(retryAt)}
            onComplete={() => setRefreshButtonVisible(true)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={"cluster-rate-limit-card"}>
      <img src={rateErrorSvg} alt="rate limit exceeded" />
      <h4 className={""} style={{ paddingTop: "20px" }}>
        Rate limit has exceeded
      </h4>
      <div>
        You have exceeded the maximum number of requests allowed in the<br />current time period. Please wait for the rate limit to reset.
      </div>
      {renderStatus()}
    </div>
  )
};

export default ClusterDataCountDownCard;
