import React from "react";
import { Popover } from "antd";

const maxCount = 10;

const CustomAvatar = ({
  list,
  color,
  size,
  userName,
}) => {
  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: "50%",
    overflow: "hidden",
  };

  if (list.length === 1) {
    const item = list[0];
    if (
      item.avatar === null ||
      item.avatar === undefined
    ) {
      return (
        <div
          size={size}
          style={{
            color: '#000000',
            backgroundColor: `${color}99`,
            ...avatarStyle,
          }}
        />
      );
    }

    return (
      <img
        src={item.avatar}
        alt={item.name}
        style={{ ...avatarStyle, objectFit: "cover" }}
      />
    );
  }

  const content = (
    <div>
      {list.length > 1 ? `Multiple Students` : `${userName}`}
    </div>
  );

  return (
    <Popover content={content}>
      <div
        size={size}
        style={{
          backgroundColor: `${color}99`,
          color: '#000000',
          fontWeight: 600,
          ...avatarStyle,
          paddingLeft: "6px",
          paddingTop: "4px",
        }}
      >
        {list.length > maxCount ? `+ ${maxCount}` : `+ ${list.length - 1}`}
      </div>
    </Popover>
  )
};

CustomAvatar.defaultProps = {
  size: 50,
};

export default CustomAvatar;
