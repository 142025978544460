import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import moment from 'moment';

import AdminList from "./AdminListView";
import Loader from "../../common/layout/Loader";
import menuData from "../../../layout/dashboardManagement/menuData";

import {setMenuSelected} from "../../../../actions/dashboardManagement/layout.action";
import {fetchTenantAdminList} from "../../../../actions/dashboardManagement/saleAdmin.action";
import {getTenantAdminListData} from "../../../../selectors/dashboardManagement/saleAdmin.selector";


const AdminListContainer = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    // selectors
    const adminList = useSelector(getTenantAdminListData) ;

    // set menu item & fetch admin data
    useEffect(() => {
        dispatch(setMenuSelected(menuData.manageTenantAdmin.key));
        dispatch(fetchTenantAdminList({
            start_date:moment().format("YYYY/MM/DD"),
            end_date:moment().format("YYYY/MM/DD"),
        }));
    }, [])

    const filtersOnSubmit = () => {
        const {dateRange} = form.getFieldsValue();
         dispatch(fetchTenantAdminList({
            start_date:dateRange && dateRange[0].format("YYYY/MM/DD"),
            end_date:dateRange && dateRange[1].format("YYYY/MM/DD"),
        }));
    }


    return (
        <Loader isLoading={adminList.isLoading}>
            <AdminList adminData={adminList} filterForm={form} filterFormSubmit={filtersOnSubmit}/>
        </Loader>
    )
}

export default AdminListContainer;