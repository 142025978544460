import React from "react";
import PropTypes from "prop-types"

import OutcomeCellCard from "./OutcomeCellCard";

const OutcomeCellGroup = ({outcomes}) => {
    const outcomeCardRenderer = () => {
        return (
            outcomes.map(outcome => <OutcomeCellCard outcome={outcome}/>)
        )
    }

    return (
        <div style={{display: "flex", flexDirection:"row"}}>
            {outcomeCardRenderer()}
        </div>
    )

}

OutcomeCellGroup.propTypes = {
    outcomes: PropTypes.array
}

export default OutcomeCellGroup;