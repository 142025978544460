import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import { Typography, Card, Row, Switch, Spin } from 'antd';
import { InfoCircleFilled, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  setMenuSelected,
  setBreadcrumb,
} from '../../../actions/dashboardManagement/layout.action';
import {
  fetchSettingData,
  saveClusterSettingStatus
} from '../../../actions/dashboardManagement/studentClusterSettings.action';
import {
  makeSettingsStudentClusterAnalyzerStatus,
} from '../../../selectors/dashboardManagement/studentClusterAnalyzer.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import ConfirmationModal from './partials/confirmationModal';
import {
  STUDENT_CLUSTER_ANALYZER_DESCRIPTION,
  STUDENT_CLUSTER_ANALYZER_DATA_USE,
} from '../../../utils/innovative/constants';
import session from '../../../utils/session';

const sectionKey = _get(menuData, 'studentClusterAnalysis.key', '');
const sectionName = _get(menuData, 'studentClusterAnalysis.name', '');

const breadcrumbItems = [
  {
    key: 'admin',
    section: "Admin",
  },
  {
    key: sectionKey,
    section: sectionName,
  },
];

const { Title, Paragraph } = Typography;
const loadingIcon =
  <LoadingOutlined className='student-cluster-setting-loading' />;

const StudentClusterSettings = () => {
  const [clusterAnalyzer, setClusterAnalyzer] = useState(false);
  const [assistantSwitchStatus, setAssistantSwitchStatus] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState(false);
  const [isClusterAnalyzerPopupOpen, setIsClusterAnalyzerPopupOpen] = useState(false);

  const dispatch = useDispatch();

  const getClusterData = useSelector(makeSettingsStudentClusterAnalyzerStatus);

  useEffect(() => {
    dispatch(fetchSettingData({
      "user_id": session.get([
        session.keys.innovativeMeta,
        "token"
      ]),
    }))

    dispatch(setMenuSelected(sectionKey));
    dispatch(setBreadcrumb(breadcrumbItems));
  }, []);

  useEffect(() => {
    setClusterAnalyzer(getClusterData?.cluster_enable);
    setAssistantSwitchStatus(getClusterData?.cluster_analyzer_enable)
  }, [getClusterData]);

  const saveSelection = () => {
    dispatch(saveClusterSettingStatus({
      clusterAnalyserEnable: clusterAnalyzer,
      assistantEnable: assistantSwitchStatus
    }));
  };

  const onChangeClusterAnalyzer = checked => {
    setClusterAnalyzer(checked);
    setIsClusterAnalyzerPopupOpen(checked === true ? true : false);

    if (checked === false) {
      dispatch(saveClusterSettingStatus({
        clusterAnalyserEnable: false,
        assistantEnable: false
      }));
    }
  };

  const onChangeAssistant = checked => {
    setAssistantSwitchStatus(checked);

    if (checked === false) {
      dispatch(saveClusterSettingStatus({
        clusterAnalyserEnable: clusterAnalyzer,
        assistantEnable: checked,
      }));
      return;
    }

    setConfirmModalStatus(true);
  };

  return (
    <Spin spinning={false} indicator={loadingIcon}>
      <div className='student-cluster-settings in-container'>
        <Title level={4}>Student Cluster Analyzer</Title>
        <Card className='switch-card'>
          <Paragraph className='switch-card-text'>
            Enable Student Cluster Analyzer
          </Paragraph>
          <Row className='switch-section'>
            <span>Disable</span>
            <Switch
              disabled={!getClusterData?.cluster_chart_enable}
              size="small"
              className='status-switch'
              checked={clusterAnalyzer}
              onChange={onChangeClusterAnalyzer}
            />
            <span>Enable</span>
          </Row>
        </Card>
        {clusterAnalyzer &&
          <Card className='switch-card'>
            <Paragraph className='switch-card-text'>
              Enable Assistant
            </Paragraph>
            <Row className='switch-section'>
              <span>Disable</span>
              <Switch
                disabled={!getClusterData?.analyser_enable}
                size="small"
                className='status-switch'
                checked={assistantSwitchStatus}
                onChange={onChangeAssistant}
              />
              <span>Enable</span>
            </Row>
          </Card>
        }
        <Card className='info-section-card'>
          <div className='info-section-card-text'>
            <Row>
              <InfoCircleFilled />
              <Title level={4} className='sub-title'>
                What is 'Student Insights?'
              </Title>
            </Row>
            <Paragraph className='text-paragraph'>
              {STUDENT_CLUSTER_ANALYZER_DESCRIPTION}
            </Paragraph>
            <Row>
              <InfoCircleFilled />
              <Title level={4} className='sub-title'>
                How we use your data
              </Title>
            </Row>
            <Paragraph className='text-paragraph'>
              {STUDENT_CLUSTER_ANALYZER_DATA_USE}
            </Paragraph>
          </div>
        </Card>
        <ConfirmationModal
          modalStatus={isClusterAnalyzerPopupOpen}
          saveSelection={saveSelection}
          closeModal={setIsClusterAnalyzerPopupOpen}
          setToggle={setClusterAnalyzer}
          title={"Please Confirm"}
        >
          <Paragraph>
            <Row className='info-row'>
              <InfoCircleOutlined />
              <Paragraph className='info-row-description'>
                You have turned on the Student Cluster Analyzer. Your Canvas data will be transferred to the Cluster Analyzer. 
                Please note that Octopus BI does not send any personally identifiable information (PII) to Cluster Analyzer.
              </Paragraph>
            </Row>
          </Paragraph>
        </ConfirmationModal>

        <ConfirmationModal
          modalStatus={confirmModalStatus}
          saveSelection={saveSelection}
          closeModal={setConfirmModalStatus}
          setToggle={setAssistantSwitchStatus}
          title={"Please Confirm"}
        >
          <Paragraph>
            You have turned on the <strong>Student Cluster Analyzer AI Assistant</strong>.
            Your canvas data will be transferred to OctopusAI to analyze data.
          </Paragraph>
          <Row className='info-row'>
            <InfoCircleOutlined />
            <Paragraph className='info-row-description'>
              Please note that Octopus BI does not send any personally identifiable information (PII) to AI Services.
            </Paragraph>
          </Row>
        </ConfirmationModal>
      </div>
    </Spin>
  );
};

export default StudentClusterSettings;
