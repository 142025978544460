import React, {useEffect, useState} from "react";

export const withTablePaginator = (Component) => ({...props}) => {
    const [page, setPage] = useState(1);

    const pagination = {
        current: page,
        onChange: setPage,
        total: props.dataSource.length,
        showSizeChanger: false
    };

    // on data source change, table pagination will be reset to 1
    useEffect(() => {
        setPage(1);
    }, [props.dataSource]);

    return <Component {...props} pagination={pagination}/>
}