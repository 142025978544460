import React, { useState, useEffect } from 'react';
import { Layout, Button, Space, Menu } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  SettingOutlined,
  MenuOutlined,
  CloseOutlined,
  LogoutOutlined,
  DashboardOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { setSidebarData } from '../../../../actions/vetTrack/analysis/layout.action';
import {
  getSidebarCollapsed,
  getSidebarEnabled,
} from '../../../../selectors/vetTrack/analysis.selector';
import { getDomainMetaData } from '../../../../selectors/tentacleMeta.selector';
import UserLogout from '../../../auth/externalAuth/Logout/Logout';
import { menu } from '../../../../constant/vetTrack/menu';
import menuData from '../dashboardManagement/vetTrackMenuData';
import vttLogo from '../../../../assets/images/vtt-logo.png'

const { Header } = Layout;
const { Item } = Menu;

const VetTrackHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlPathName = window.location.href;

  // state
  const [selectedMenu, setSelectedMenu] = useState('');

  // selectors
  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  const isSidebarEnabled = useSelector(getSidebarEnabled);
  const domainData = useSelector(getDomainMetaData);

  // effects
  useEffect(() => {
    if (urlPathName.includes(menu.riskAnalysis)) {
      setSelectedMenu(menu.riskAnalysis);
      return;
    }
    setSelectedMenu('');
  }, [urlPathName]);

  const logo = domainData ? domainData.icon : '';

  const toggleSidebarComponent = () => {
    dispatch(setSidebarData({ collapsed: !sidebarCollapsed }));
  };

  const backToDashboards = () => {
    if (urlPathName?.includes(menu.routs.vettrak)) {
      return history.push(menuData.manageDashboard.url);
    }
    if (urlPathName?.includes(menu.routs.axcelerate)) {
      return history.push(menuData.axcelerateManageDashboard.url);
    }
    return history.push(menuData.manageDashboard.url);
  }
  const onLogoError = error => {
    error.target.src = vttLogo;
  };

  const onMenuSelect = event => {
    const { key } = event;
    setSelectedMenu(key);
    history.push(key);
  };

  return (
    <Header className="site-layout-sub-header-background">
      {isSidebarEnabled && (
        <a href="#_" className="mobile-menu-button">
          <MenuOutlined onClick={toggleSidebarComponent} />
          <CloseOutlined onClick={toggleSidebarComponent} />
        </a>
      )}
      <div className="vtt-logo">
        <img
          onError={onLogoError}
          src={logo}
          alt="headerLogo"
          className="header-logo"
          onClick={backToDashboards}
        />
      </div>
      <div className="button-section">
        <Menu
          onClick={onMenuSelect}
          selectedKeys={[selectedMenu]}
          mode="horizontal"
          style={{ maxWidth: '500px' }}
        >
          <Item key={menu.riskAnalysis}>
            <BarChartOutlined />
            Risk Analysis
          </Item>
        </Menu>
      </div>
      <div className="end-div">
        <Space>
          {urlPathName?.includes(menu.riskAnalysis) && (
            <Button
              onClick={backToDashboards}
              icon={<DashboardOutlined fontSize={24} />}
              className="dashboard-button"
            >
              Admin Center
            </Button>
          )}
          <SettingOutlined
            onClick={() => history.push(menu.settings)}
            style={{ fontSize: 22, color: '#6244BB' }}
          />
          <UserLogout
            button={({ onClick }) => (
              <span
                title="Sign out"
                onClick={onClick}
                className="vett-setting-icon"
                style={{ border: 'none', cursor: 'pointer' }}
                onKeyDown={() => { }}
                role="button"
                tabIndex={0}
              >
                <LogoutOutlined />
              </span>
            )}
          />
        </Space>
      </div>
    </Header>
  );
};

export default VetTrackHeader;
