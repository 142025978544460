import React, { useState, useCallback, useEffect } from "react";
import { Typography, Checkbox } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import {
    saveUnitsTableData,
    clearUnitsData,
    setEditMode as setEditModeData
} from "../../../../../actions/vwDealership/settings.action";
import {
    makeVwDealershipOpenPanelsDataResponse,
    makeVwDealershipEditModeDataResponse
} from "../../../../../selectors/vwDealership/setting.selector";

import UnitsTable from "./UnitsTable";
import OrderSelectAllCheck from "./partials/OrderSelectAll";

const { Text } = Typography;

const UnitsTableContainer = ({ units }) => {
    const [page, setPage] = useState(1);
    const [filteredUnits, setFilteredUnits] = useState(units);
    const [filterMeta, setFilterMeta] = useState({
        roles: [],
        pathways: [],
        programs: [],
        units: [],
    })
    const [tableMeta, setTableMeta] = useState({
        filteredInfo: {},
        current: units,
    });
    const dispatch = useDispatch();

    // selectors
    const openPanels = useSelector(makeVwDealershipOpenPanelsDataResponse);
    const editMode = useSelector(makeVwDealershipEditModeDataResponse);

    const pagination = {
        current: page,
        onChange: setPage,
        total: filteredUnits,
        showSizeChanger: false
    };

    useEffect(() => {
        if (filteredUnits) {
            setFilterMeta({
                roles: prepareFilterValues(filteredUnits, "name", "name", 'role'),
                pathways: prepareFilterValues(filteredUnits, "name", "name", 'pathway'),
                programs: prepareFilterValues(filteredUnits, "name", "name", 'program'),
                units: prepareFilterValues(filteredUnits, "name", "name", 'unit'),
            })
        }
    }, [filteredUnits])

    const handleChangeTable = (pagination, filters, sorter, extras) => {
        const { currentDataSource } = extras;
        setTableMeta({
            filteredInfo: filters,
            sortedInfo: sorter,
            current: currentDataSource,
        });
    };

    const onChangeItem = (id, data) => {
        // update the unit record with user input
        onUnitPropChange(filteredUnits.map(item => (item.id === id ? { ...item, ...data } : item)))

    }

    const renderMandatory = (id, value) => {
        return <Checkbox
            key={id}
            name={id}
            checked={value}
            disabled={!editMode}
            onChange={(e) => onChangeItem(id, { mandatory: e.target.checked })}
        />
    }

    const prepareFilterValues = useCallback((dataset, uniqKey, valueProp, objectName = null) => {
        if (Array.isArray(dataset)) {
            return [...new Map(dataset.map(item => {
                if (objectName) {
                    return [item[objectName][uniqKey], { text: item[objectName][valueProp], value: item[objectName][uniqKey] }]
                } else {
                    return [item[uniqKey], { text: item[valueProp], value: item[uniqKey] }]
                }
            })).values()];
        }
        return []
    }, [tableMeta.current]);

    const changeMandatoryCheck = (type, ids) => {
        // check with the type and mark/unmark records
        onUnitPropChange(filteredUnits.map(item => {
            let val = item.mandatory;
            if (ids.includes(item.id)) {
                switch (type) {
                    case "all":
                        val = true;
                        break;
                    case "none":
                        val = false;
                        break;
                }
            }

            return { ...item, mandatory: val }
        }))
    }

    const columns = [
        {
            title: 'Role',
            dataIndex: ['role', 'name'],
            key: 'role',
            filters: filterMeta.roles,
            onFilter: (value, record) => record.role.name === value,
            sorter: (a, b) => a.role.name.localeCompare(b.role.name),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.role || null,
        },
        {
            title: 'Pathway',
            dataIndex: ['pathway', 'name'],
            key: 'pathway',
            filters: filterMeta.pathways,
            onFilter: (value, record) => record.pathway.name === value,
            sorter: (a, b) => a.pathway.name.localeCompare(b.pathway.name),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.pathway || null,
        },
        {
            title: 'Program',
            dataIndex: ['program', 'name'],
            key: 'program',
            filters: filterMeta.programs,
            onFilter: (value, record) => record.program.name === value,
            sorter: (a, b) => a.program.name.localeCompare(b.program.name),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.program || null,
        },
        {
            title: 'Unit',
            dataIndex: ['unit', 'name'],
            key: 'unit',
            filters: filterMeta.units,
            onFilter: (value, record) => record.unit.name === value,
            sorter: (a, b) => a.unit.name.localeCompare(b.unit.name),
            ellipsis: true,
            filteredValue: tableMeta.filteredInfo.unit || null,
        },
        {
            title: <span>Mark As Mandatory
                <OrderSelectAllCheck
                    onChange={changeMandatoryCheck}
                    mode={editMode}
                    options={tableMeta.current}
                    selectedOptions={filteredUnits.filter(unit => tableMeta.current.map(i => i.id).includes(unit.id) && unit.mandatory)}
                />
            </span>,
            dataIndex: 'mandatory',
            filters: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                }],
            onFilter: (value, record) => record.staticMandatory === value,
            align: "center",
            render: (value, row) => renderMandatory(row.id, value),
            fixed: 'right',
            filteredValue: tableMeta.filteredInfo.mandatory || null,
        },
    ];

    const getFilteredUnits = useCallback(() => {
        // get currently opened panels
        const panels = Object.keys(openPanels).filter(panel => panel !== "main" && openPanels[panel])
        // filter opened panels with units data and find the correct units to display
        return units.filter(unit => {
            if (panels && panels.length) {
                let checkVal = true;
                panels.map(panel => {
                    if (unit[panel]) {
                        if (openPanels[panel] !== unit[panel].id) checkVal = false;
                    }
                })
                return checkVal
            } else {
                return true
            }
        }).map(unit => ({ ...unit, staticMandatory: unit.mandatory }))
    }, [units, openPanels]);

    useEffect(() => {
        const unitsData = getFilteredUnits();
        setFilteredUnits(unitsData);
        // set currently applied filters after reloading the new data set
        const { current, ...rest } = tableMeta;
        // temp store the current filters and reset table meta
        setTableMeta({ ...tableMeta, current: unitsData, older: rest, filteredInfo: {} })
    }, [units])

    useEffect(() => {
        // if there are temp stored filters need to apply them by force
        if (tableMeta.older) {
            const { older, ...rest } = tableMeta
            setTableMeta({ ...rest, ...older, current: filterForceApply(older.filteredInfo, tableMeta.current) })
        }
    }, [tableMeta.older])

    useEffect(() => {
        // remove last loaded data in componentWillUnmount
        return () => dispatch(clearUnitsData());
    }, [])

    useEffect(() => {
        const unitsData = getFilteredUnits();
        setFilteredUnits(unitsData);
        setTableMeta({ filteredInfo: {}, current: unitsData })
    }, [openPanels])

    const filterForceApply = (filters, dataset) => {
        // this controller function will apply the temporary stored filters to tableMeta.current
        // this is a forcing application due to the native behaviour of the framework
        let newDataSet = dataset;
        Object.keys(filters).map(filter => {
            if (filters[filter] && filters[filter].length) {
                newDataSet = newDataSet.filter(data => {
                    if (filter === "mandatory") {
                        return filters[filter].includes(data[filter]);
                    }
                    return filters[filter].includes(data[filter].name)
                });
            }
        })
        return newDataSet;
    }

    const onUnitPropChange = (data) => {
        // set updated dataset as units when user input
        setFilteredUnits(data)
    }

    const setEditMode = (data) => {
        dispatch(setEditModeData(data))
    }

    const setEdit = (mode) => {
        if (mode) {
            setEditMode(true);
        } else {
            resetData();
        }
    }

    const resetData = () => {
        setEditMode(false);
        const unitsData = getFilteredUnits();
        setFilteredUnits(unitsData);
    }

    const onSubmit = () => {
        const filteredUnitIds = filteredUnits.map(item => item.id);
        const newUnitDataset = units.filter(unit => !filteredUnitIds.includes(unit.id));
        const requestParam = {
            units: filteredUnits.map(({ id, mandatory }) => ({ id, mandatory }))
        };
        // set pagination to default
        setPage(1);
        dispatch(saveUnitsTableData(requestParam, [...newUnitDataset, ...filteredUnits]));
    }

    return (
        <UnitsTable
            dataset={filteredUnits}
            columns={columns}
            pagination={pagination}
            editMode={editMode}
            setEditMode={setEdit}
            onEditSubmit={onSubmit}
            handleChangeTable={handleChangeTable}
        />
    )
}

UnitsTableContainer.propTypes = {
    units: PropTypes.array,
}

export default UnitsTableContainer;