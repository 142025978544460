// UTILS TO HANDLE COMMON FUNCTIONS IN STUDENT TABLES ACROSS ALL TABS

import React from "react";
import {
  getSortMethod,
  getSubSectionDataByKey,
  getUniqValuesForFilters,
} from "./general";
import NoDataCell from "../common/partials/NoDataCell";
import {
  camelCase as _camelCase,
  get as _get,
} from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  complianceSections,
  noDataValue,
} from "../../../../constant/vetTrack/analysis";

/**
 * Global function to prepare risk measurement columns in all the student tables in all tabs
 * @param {object[]} enabledFactors    enabled risk factors risk
 * @param {object[]} students  students array
 * @param {object} sectionData  section data object relevant to tab section
 * @param {object} customParams custom parameters for columns
 */
export const prepareRiskMeasurementsColumns = (enabledFactors, students, sectionData, customParams={}) => {
  const columns = [];

  if (!enabledFactors) return columns;

  enabledFactors.forEach(factor => {
    const camalized = _camelCase(factor);
    const factorData = getSubSectionDataByKey(camalized, sectionData);
    if (factorData) {
      columns.push({
        title: factorData.name,
        dataIndex: ["factors", factor],
        sorter: getSortMethod(factorData.mode, `factors.${factor}`),
        className: factorData.colClassName,
        onHeaderCell: () => ({style: {textAlign: "left"}}),
        width:200,
        filters: getUniqValuesForFilters(students, `factors.${factor}`, `factors.${factor}`),
        onFilter: (value, record) => onFilterColumn(value, record, factor),
        render: (text) => cellRender(text, factorData),
        ...customParams
      })
    }
  });
  return columns;
}

const cellRender = (text, factorData) => {
  const visaExpirationKey = _get(complianceSections, 'visaExpiration.key', '');
  const factorKey = _get(factorData, 'key');

  if (text === null || text === undefined) {
    return <NoDataCell />
  }

  if (factorKey === visaExpirationKey && parseInt(text) < 1) {
    return (
      <div className="vtt-cell-no-data visa-error">
        <ExclamationCircleOutlined />
        <p>Expired</p>
      </div>
    );
  }

  return (
    <div>
      {factorData.displayValModifier ? factorData.displayValModifier(text) : text}
    </div>
  );
};

const onFilterColumn = (value, record, key) => {
  console.log(value);
  const recordText = _get(record, `factors.${key}`, '');
  if (value === noDataValue) {
    return !(record && record.factors && record.factors[key]);
  }

  return recordText === value;
}; 
