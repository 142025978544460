import React, { Component, Fragment } from 'react';

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import ApiServices from '../../../../services/tentacle';
import accimage from '../../../../assets/images/acclogo.png';
import Helper from "../../common/numberHelper";
import PrincipalProfileWrapper from "../principalProfileWrapper/PrincipalProfileWrapper";

import addStock from "highcharts/modules/stock";
import './../../../../assets/css/principal-profile.css';
import './../../../../assets/css/management-profile.css';
import './../../../../assets/css/enrolment-profile.css'

import addHeatmap from 'highcharts/modules/heatmap';
import {withRouter} from "react-router";

addHeatmap(Highcharts)
addStock(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class AccManagementProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFocus: React.createRef(),
      isFilterTermsLoaded:false,
      filterTermValue:0,
      filterTerms:[],
      isFirstTimeLoad:true,
      filterSchoolValue: this.props.location.state.currentSchool
    }
  }

  componentDidMount() {
    this.filterTerms();
  }

  changeTermValue = (value) => {
    this.setState({filterTermValue:value}, () => {
      if(this.state.isFirstTimeLoad){
        this.setState({
          isFirstTimeLoad: false,
        });
        this.redrawCharts();
      }
    });
  }

  filterTerms = () => {
    this.setState({
        isFilterCoursesLoaded:false,
        isGoButtonDisabled: true
    });
    var params = { school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId }

    if(!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school)){
      ApiServices.AccFilterService.principalFilterTerm(params).then((data) => {
        if(Object.keys(data.data.result).length !== "" && Object.keys(data.data.result).length > 0){
          let termArray = data.data.result;
          this.setState({
            isFilterTermsLoaded: true, 
          isFilterTermsLoaded: true, 
            isFilterTermsLoaded: true, 
            filterTermValue:termArray[0].id,
            filterTerms:termArray,
            isGoButtonDisabled: false
          }, () => {
            this.changeTermValue(termArray[0].id);
          });
        }
      },(error) => {
        this.setState({
          isFilterTermsLoaded: true,
          error
        });
      });
    }
  }

  redrawCharts = () => {
    this.setState({
      isGoButtonDisabled: true
    }, () => {
      this.getStudentToTeacherRatio();
      // this.getClassroomCapacity();
      this.getChurnRate('OC', "initial");
      this.getStudentEnrolmentLength('OC', "initial");
      this.setState({
        isGoButtonDisabled: false
      });
    })
  }

  getStudentToTeacherRatio = () => {
    this.setState({
      STRatioData: [],
      STRatioChartLoaded: false
    });

    const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

    if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
      ApiServices.AccManagementProfileService.studentToTeacherRatio(params).then(res => res.json()).then((result) => {
        this.setState({
          STRatioData:result.data.result.studentToTeacherRatio
        }, this.drawStudentToTeacherRatioChart);
      },(error) => {
        this.setState({
          STRatioChartLoaded: true,
          error
        });
      });
    }
  }

  // getClassroomCapacity = () => {
  //   this.setState({
  //     capacityData: [],
  //     classRoomCapacityTableLoaded: false
  //   });
  //   const params = {term: this.state.filterTermValue};
  //   ApiServices.AccManagementProfileService.classroomCapacity(params, this.props.schoolId).then(res => res.json()).then((result) => {
  //     this.setState({
  //       capacityData:result.data.result
  //     }, () => {
  //       this.setState({
  //         classRoomCapacityTableLoaded: true
  //       })
  //     });
  //   },
  //   (error) => {
  //     this.setState({
  //       classRoomCapacityTableLoaded: true,
  //       error
  //     });
  //   }) ;
  // }

  getChurnRate = (mode, trigger) => {
    if(trigger == "redraw"){
      this.setState({
        churnRateData : this.state.churnRateData ? this.state.churnRateData : [],
        churnRateChartLoaded: this.state.churnRateChartLoaded,
        churnMode: mode,
      });

      if(this.state.churnRateData){
        this.drawChurnRateChart(mode);
      }
    } else {
      this.setState({
        churnRateData : trigger == "initial" ? [] : this.state.churnRateData,
        churnRateChartLoaded: trigger == "initial" ? false: this.state.churnRateChartLoaded,
        churnMode: mode,
      });
      const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

      if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
        ApiServices.AccManagementProfileService.churnRate(params).then(res => res.json()).then((result) => {
          this.setState({
            churnRateData:result.data.result.churnRateChart
          }, () => {
            this.drawChurnRateChart(mode);
          });
        },(error) => {
          this.setState({
            classRoomCapacityTableLoaded: true,
            error
          });
        });
      }
    }
  }

  getStudentEnrolmentLength = (mode, trigger) => {
    if(trigger == "redraw"){
      this.setState({
        enrolmentLengthTable : this.state.enrolmentLengthTableData[mode],
        enrolmentLengthTableDataLoaded: true,
        enrolmentLengthTableLoaded: true,
        enrolemtnLengthMode: mode,
      });
    } else {
      this.setState({
        enrolmentLengthTableData : trigger == "initial" ? [] : this.state.enrolmentLengthTableData,
        enrolmentLengthTableDataLoaded: trigger == "initial" ? false: this.state.enrolmentLengthTableDataLoaded,
        enrolmentLengthTableLoaded: false,
        enrolemtnLengthMode: mode,
      });
      const params = {term: this.state.filterTermValue, school: this.state.filterSchoolValue !== '' ? this.state.filterSchoolValue : this.props.schoolId};

      if(!Helper.yearTermValidation(params.term) && (!Helper.validateSpecialCharacter(params.school) && Helper.numberValidation(params.school))){
        ApiServices.AccManagementProfileService.studentEnrolmentLength(params).then(res => res.json()).then((result) => {
          this.setState({
            enrolmentLengthTableData:result.data.result.enrolmentLengthTable
          }, () => {
            this.setState({
              enrolmentLengthTable: this.state.enrolmentLengthTableData[mode],
              enrolmentLengthTableDataLoaded: true,
              enrolmentLengthTableLoaded: true
            })
          });
        },(error) => {
          this.setState({
            enrolmentLengthTableDataLoaded: true,
            enrolmentLengthTableLoaded: true,
            error
          });
        });
      }
    }
  }

  drawStudentToTeacherRatioChart = () => {
    let gThis = this.state;
    this.setState({
      ratioChrtOptions: {
        title: {
          text: ''
        },
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type:"column",
          animation: true,
          style: {
            color: "#444444"
          },
          zoomType: "x"
        },
        plotOptions: {
          series: {
            linewidth:6,
            borderRadius: 1,
          },
          column: {
            linewidth: 6,
            borderRadius: 1,
            pointPadding: 5,
            pointWidth: 20,
            groupPadding: 0.1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories:this.state.STRatioData.categories,
          title: {
            text: 'MODE'
            //align: 'center'
          },
          min: 0,
          max: this.state.STRatioData.categories.length > 12 ? 12 : null,
          scrollbar: {
            enabled: this.state.STRatioData.categories.length > 12 ? true : false
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'STUDENT TO TEACHER RATIO'
            //align: 'center'
          }
        },
        legend:{
          enabled:true,
          symbolHeight: 12,
          symbolWidth: 20,
          symbolRadius: 0
        },
        tooltip: {
          animation: true,
          useHTML: true,
          formatter: function () {
            return `CATEGORY: <b>${this.key}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>${this.y}</b>`
          }
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        
        series: this.state.STRatioData.series,
      }
    }, () => {
      this.setState({
        STRatioChartLoaded: true
      });
    })
  }

  drawChurnRateChart = (mode) => {
    let gThis = this;
    this.setState({
      churnRateChrtOptions: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },  
        title: {
          text: null
        },
        xAxis: {
          categories: gThis.state.churnRateData[mode]["x-categories"],
          title: {
            text: 'YEAR'
          }
        },
        yAxis: {
          categories: gThis.state.churnRateData[mode]["y-categories"],
          title: {
            text: 'YEAR LEVEL'
          },
          reversed: true
        },
        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              var ix = point.index + 1,
                xName = gThis.getPointCategoryName(point, 'x'),
                yName = gThis.getPointCategoryName(point, 'y'),
                val = point.value;
              return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
            }
          }
        },
        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          maxColor: '#E22525'//Highcharts.getOptions().colors[0]
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },
        tooltip: {
          formatter: function () {
            return `YEAR : <b>${gThis.getPointCategoryName(this.point, 'x')}<br/>
                    YEAR LEVEL : <b>${gThis.getPointCategoryName(this.point, 'y')}</b><br/>
                    CHURN RATE : <b>${this.point.value !== null ? gThis.getchurnRateTooltip(this.point.value) : ""}</b>`;
          }
        },
        series: [{
          name: 'CHURN RATE',
          borderWidth: 1,
          data: gThis.state.churnRateData[mode].series,
          dataLabels: {
            enabled: true,
            color: '#000000'
          }
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter: function () {
                    return this.value.charAt(0);
                  }
                }
              }
            }
          }]
        },
        credits: {
          enabled: false
        }
      }
    }, () => {
      this.setState({
        churnRateChartLoaded: true
      });
    })
  }

  getchurnRateTooltip = (value) => value !== '' ? `${value}%` : '-';
  getPointCategoryName = (point, dimension) => {
    var series = point.series,
      isY = dimension === 'y',
      axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
  }

  churnRateModeOnChange = (mode) => {
    this.getChurnRate(mode, "redraw");
  }

  enrolmentLengthModeOnChange = (mode) => {
    this.getStudentEnrolmentLength(mode, "redraw");
  }

  render() {
    return (
      <Fragment>
        <div className="container-fluid " > 
          <div className="card custom-card  " style={{backgroundColor:"#e0e0e0",marginTop: '90px'}}> 
            <div className="row ">
              <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
                <div className="row ">
                  <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{backgroundColor:"#e0e0e0"}}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <span className="m-0">YEAR</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits "  value={this.state.filterTermValue}
                                onChange={(e) => this.changeTermValue(e.target.value)}>
                                {this.state.isFilterTermsLoaded
                                  ? this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                  : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
                <div className="">
                  <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled}  style={{marginTop: '24px',width:"100%"}} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                </div>
              </div>
            </div>
          </div>     
      
          <div className="user-profile"> 
            <div className="row">
              <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                <div className="card custom-card  ">
                  <div className="row">
                    <div className="col-2 col-sm-2">
                      <img  src={accimage}></img>
                    </div>
                    <div className="col-5 col-sm-5 with-border">
                      <div className="student-name">
                        <h5><span className="counter">MANAGEMENT ANALYSIS</span></h5>
                        <h4>
                          {this.state.isStudentNameLoaded
                          ? this.state.studentName
                          : this.state.loader}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="principal-profile management-profile">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>TEACHER STUDENT RATIO</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.STRatioChartLoaded ?
                        <div className="col-12 center-text">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.ratioChrtOptions}
                          />
                          <span className="center-align">STUDENT TO TEACHER RATIO = STUDENT ENROLMENT / TEACHER FTE</span>
                        </div>
                        : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-6">
                  <div className="card">
                    <div className="card-header card-header-blue">
                      <h5>CLASSROOMS AND CAPACITY</h5>
                    </div>
                    <div className="card-body">
                      <div className="user-status">
                        {this.state.classRoomCapacityTableLoaded ?
                          <Fragment>
                            <div className="col-12">
                              <div className="table-responsive">
                              <table className="table table-bordered enrolment-table enrolment-table-border">
                                  <thead>
                                      <tr>
                                          <th scope="col">BLOCK</th>
                                          <th scope="col">CLASSROOMS</th>
                                          <th scope="col">CAPACITY</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.capacityData.capacityTable.map((capacity,index) => {
                                          return <tr key={index}>
                                              <td scope="col" key={`${index}-0`}>{capacity[0]}</td>
                                              <td scope="col" key={`${index}-1`}>{capacity[1]}</td>
                                              <td scope="col" key={`${index}-2`}>{capacity[2]}</td>
                                          </tr>
                                      })
                                    }
                                    </tbody>
                                  </table>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <div className="card">
                                      <div className={`card-body card-shadow card-custom-padding card-blue card-custom-margin`}>
                                      <span className="card-text">ENROLMENTS(OC)</span>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="card">
                                      <div className={`card-body card-shadow card-custom-padding card-custom-margin`}>
                                      <span className="card-value">{this.state.capacityData.enrolments}</span>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                        }
                      </div>
                    </div>
                  </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>CHURN RATE</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.churnRateChartLoaded ?
                        <div className="row">
                          <div className="col-10">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.churnRateChrtOptions}
                              containerProps={{style :{height:this.state.churnRateData[this.state.churnMode]["y-categories"].length*50}}}
                              // containerProps={{style :{height:10*50}}}
                            />
                          </div>
                          <div className="col-2">
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.churnMode == "OC" ? "active-btn" : null }`} onClick={() => this.churnRateModeOnChange("OC")}>
                                <span className="card-text">OC</span>
                              </div>
                            </div>
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.churnMode == "DE" ? "active-btn" : null }`} onClick={() => this.churnRateModeOnChange("DE")}>
                                <span className="card-text">DE</span>
                              </div>
                            </div>
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.churnMode == "ALL" ? "active-btn" : null }`} onClick={() => this.churnRateModeOnChange("ALL")}>
                                <span className="card-text">ALL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header card-header-blue">
                    <h5>STUDENT ENROLMENT LENGTH</h5>
                  </div>
                  <div className="card-body">
                    <div className="user-status">
                      {this.state.enrolmentLengthTableDataLoaded ?
                        <div className="row">
                          <div className="col-10">
                            {this.state.enrolmentLengthTableLoaded ? 
                              <div className="table-responsive">
                                <table className="table table-bordered enrolment-table enrolment-table-border">
                                  <thead>
                                    <tr>
                                      <th scope="col">CURRENT YEAR LEVEL</th>
                                      <th scope="col">% JOINED IN FOUNDATION OR BEFORE</th>
                                      <th scope="col">% JOINED THIS YEAR</th>
                                      <th scope="col">AVERAGE ENROLMENT LENGTH</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.enrolmentLengthTable.map((grade,index) => {
                                      return <tr key={index}>
                                          <td className="blue-cell" key={grade[0]}>{grade[0]}</td>
                                          <td key={grade[1]+1}>{grade[1] !== "" ? `${grade[1]}%`:"-"}</td>
                                          <td key={grade[2]+2}>{grade[2] !== "" ? `${grade[2]}%`:"-"}</td>
                                          <td key={grade[3]+3}>{grade[3] !== "" ? `${grade[3]}` : "-"}</td>
                                        </tr>
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                              : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                            }
                          </div>
                          <div className="col-2">
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.enrolemtnLengthMode == "OC" ? "active-btn" : null }`} onClick={() => this.enrolmentLengthModeOnChange("OC")}>
                                <span className="card-text">OC</span>
                              </div>
                            </div>
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.enrolemtnLengthMode == "DE" ? "active-btn" : null }`} onClick={() => this.enrolmentLengthModeOnChange("DE")}>
                                <span className="card-text">DE</span>
                              </div>
                            </div>
                            <div className="card">
                              <div className={`card-body card-shadow card-custom-padding cursor-pointer center-text ${this.state.enrolemtnLengthMode == "ALL" ? "active-btn" : null }`} onClick={() => this.enrolmentLengthModeOnChange("ALL")}>
                                <span className="card-text">ALL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        : <div style={{display: 'flex', justifyContent: 'center'}} ><img src ={preloaderGif} height="100px"></img></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      </Fragment>
    )
  }
}
export default PrincipalProfileWrapper(withRouter(AccManagementProfile));