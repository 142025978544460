import React, {memo} from "react";
import {Card, Row, Col} from "antd";
import PropTypes from "prop-types";
import {RightCircleOutlined} from "@ant-design/icons";

const {Meta} = Card;

const CardElement = ({item, onDataTableShowClick}) => {

    return (
        <Card
            className="vw-card-elem"
            cover={
                <div className="cover" onClick={() => onDataTableShowClick(item)}>
                    {item.name}({item.id})
                    <Row>
                        <Col span={20} style={{fontSize:25}}>{item.percentage}%</Col>
                        <Col span={4}><RightCircleOutlined className="icon"/></Col>
                    </Row>
                </div>
            }
        >
            <Meta
                description={
                    <div>
                        <div>Total Count</div>
                    <Row>

                        <Col span={12}>
                            <span className="stat-value" style={{color:"#3CB739"}}>{item.completed}</span>
                            <div className="stat-description">Completed</div>
                        </Col>
                       <Col span={12}>
                           <span className="stat-value" style={{color:"#E62C20"}}>{item.inCompleted}</span>
                            <div className="stat-description">Not Completed</div>
                        </Col>
                    </Row>
                    </div>
                }
            />
        </Card>
    )

}

CardElement.propTypes = {
    item: PropTypes.object,
    onDataTableShowClick: PropTypes.func,
}

export default memo(CardElement);