import {
    FETCH_GLOBAL_SETTINGS,
    FETCH_GLOBAL_SETTINGS_LOADING,
    SAVE_GLOBAL_SETTINGS,
    SAVE_GLOBAL_SETTINGS_LOADING,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement"
import { showNotification } from "../../utils/general";

// Fetch
// handle fetch loading state for settings fetch request
export const fetchingGlobalSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: FETCH_GLOBAL_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};

export const savingGlobalSettings = (isLoading = true) => dispatch => {
    dispatch({
        type: SAVE_GLOBAL_SETTINGS_LOADING,
        payload: {
            isLoading
        }
    });
};


// handle response of the settings fetch request
export const setGlobalSettingsFetchStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: FETCH_GLOBAL_SETTINGS,
        payload: {
            isLoading,
            error: error,
            response: response,
        }
    });
};

// handle response of the settings Save request
export const setGlobalSettingsSaveStatus = data => dispatch => {
    const { isLoading, error, response } = data;
    dispatch({
        type: SAVE_GLOBAL_SETTINGS,
        payload: {
            isLoading,
            error: error,
            response: response,
        }
    });
};

// handle settings fetch request
export const fetchGlobalSettings = () => dispatch => {
    dispatch(fetchingGlobalSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        setTimeout(
            () => dispatch(setGlobalSettingsFetchStatus(response)),
            500
        );
    }

    const requestOptions = {
        service: `insights/settings/global/fetch`,
        method: "POST",
        authorized: true,
    };

    service.make(requestOptions)
        .then(response => feedReducer({ response }))
        .catch(error => feedReducer({ error: error.message }))
};

// handle settings save request
export const saveGlobalSettings = (payload) => dispatch => {
    dispatch(savingGlobalSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        dispatch(setGlobalSettingsSaveStatus(response));
        dispatch(fetchGlobalSettings());

        if (!response.error) {
            showNotification({
                message: 'Success',
                description: 'Settings saved successfully'
            })
        }
    }
    const requestOptions = {
        service: `insights/settings/global/save`,
        method: "POST",
        data: { ...payload },
        authorized: true,
    };

    service.make(requestOptions)
        .then(response => feedReducer({ response }))
        .catch(error => feedReducer({ error: error.message }))
};

// handle settings save request
export const restDefaultGlobalSettings = (payload) => dispatch => {
    dispatch(savingGlobalSettings());

    const feedReducer = (response) => {
        // setting minimum loading time to avoid glitching
        dispatch(setGlobalSettingsSaveStatus(response));
        dispatch(fetchGlobalSettings());

        if (!response.error) {
            showNotification({
                message: 'Success',
                description: 'Settings saved successfully'
            })
        }
    }
    const requestOptions = {
        service: `insights/settings/global/resetToDefault`,
        method: "POST",
        data: { ...payload },
        authorized: true,
    };

    service.make(requestOptions)
        .then(response => feedReducer({ response }))
        .catch(error => feedReducer({ error: error.message }))
};

// http://api.tentacle.com/api/1/insights/settings/global/resetToDefault
// Save / update
// handle save / update loading state for settings fetch request
// export const savingSettings = (isLoading = true) => dispatch => {
//     dispatch({
//         type: SAVE_SETTINGS_LOADING,
//         payload: {
//             isLoading
//         }
//     });
// };

// // handle response of the settings save request
// export const setSettingsSaveStatus = data => dispatch => {
//     const { isLoading, error, response } = data;
//     dispatch({
//         type: SAVE_SETTINGS,
//         payload: {
//             isLoading,
//             error,
//             response,
//         }
//     });
// };

// // color/resetToDefault

// export const resetDefault = (payload) => (dispatch) => {
//     dispatch(savingSettings());

//     const feedReducer = (response) => {
//         dispatch(setSettingsSaveStatus(response));
//         dispatch(
//             fetchSettings({
//                 requestParams: payload,
//             })
//         );
//         showNotification({
//             message: "Success",
//             description: "Settings set to default successfully",
//         });
//     };

//     const requestOptions = {
//         service: `insights/settings/resetToDefault/${getAccessToken()}`,
//         method: "POST",
//         data: { ...payload },
//         authorized: true,
//         onSuccess: feedReducer,
//     };

//     request.make(requestOptions);
// };

// // handle settings save request
// export const saveSettings = (payload) => dispatch => {
//     dispatch(savingSettings());

//     const feedReducer = (response) => {
//         dispatch(setSettingsSaveStatus(response));
//         dispatch(fetchSettings({
//             requestParams: payload.params
//         }));

//         if (!response.error) {
//             showNotification({
//                 message: 'Success',
//                 description: 'Settings saved successfully'
//             })
//         }

//     }

//     const requestOptions = {
//         service: `insights/settings/save/${getAccessToken()}`,
//         method: "POST",
//         data: { ...payload },
//         authorized: true,
//         onSuccess: feedReducer,
//     };

//     request.make(requestOptions);
// };
