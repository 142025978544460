import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

const BarChart = ({ chartOptions }) => {
  const defaultConfig = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'bar',
    },
    legend: {
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
    },

    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
  };

  const config = Highcharts.merge(defaultConfig, chartOptions);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
};

BarChart.propTypes = {
  chartOptions: PropTypes.object.isRequired,
};

export default BarChart;
