import React from 'react';
import { Col, Input, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import { SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

const StudentsTableHeader = ({
  title,
  searchEnabled,
  onSearch,
  searchVal,
}) => {
  const onChange = e => {
    onSearch(e.target.value);
  };

  return (
    <Row display="flex" justify="space-between" className="tableHeader">
      <Col span={10}>
        <Title level={4}>{title}</Title>
      </Col>
      <Col span={14}>
        {searchEnabled && (
          <div style={{ float: 'right' }}>
            <Input
              addonAfter={<SearchOutlined />}
              style={{ maxWidth: 200 }}
              value={searchVal}
              onChange={onChange}
              placeholder="Search"
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

StudentsTableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  searchEnabled: PropTypes.bool,
  onSearch: PropTypes.func,
  searchVal: PropTypes.string,
};

export default StudentsTableHeader;
