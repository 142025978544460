import React, { Fragment } from 'react';
import {useLocation} from "react-router-dom";
import { Link } from 'react-router-dom';

const sad = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/sad.png`;

const Error404 = () => {
    const {home} = useLocation().state
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- error-404 start--> */}
                <div className="error-wrapper">
                    <div className="container"><img className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <h2 className="headline font-primary">404</h2>
                        </div>
                        <div className="col-md-8 offset-md-2">
                            <p className="sub-content">The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.</p>
                        </div>
                        <div><Link to={home} className="btn btn-primary-gradien"> BACK TO HOME PAGE</Link></div>
                    </div>
                </div>
                {/* <!-- error-404 end--> */}
            </div>
        </Fragment>
    );
};

export default Error404;