import React from 'react';
import PropTypes from "prop-types";
import Loader from '../../../common/layout/Loader';
import { Progress } from 'antd';
import { get as _get } from 'lodash';

const ModuleCompletionBar = ({ courseId, moduleData = [] }) => {
  const isDataAvailable = !!moduleData.filter(module => module.courseId === courseId)?.length;
  const moduleCardData = moduleData.find(module => module.courseId === courseId);
  const moduleCompletionPercentage = moduleCardData?.overallModuleCompletion ? moduleCardData.overallModuleCompletion : "N/A";

  const renderComponent = () => {
    if (moduleCompletionPercentage != "N/A") {
      return (<Progress percent={parseInt(moduleCompletionPercentage)} />);
    }

    return (
      <div>
        {moduleCompletionPercentage}
      </div>
    );
  }

  return (
    <div>
      <Loader isLoading={!isDataAvailable}>
        {renderComponent()}
      </Loader>
    </div>
  )
};

ModuleCompletionBar.propTypes = {
  courseId: PropTypes.string,
  moduleData: PropTypes.array,
}

export default ModuleCompletionBar;
