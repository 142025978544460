import _get from "lodash/get";

// return vw dealership online response
export const makeVwDealershipOnlineResponse = state => _get(state, 'vwDealership.mandatoryOnline');

// return vw dealership online modules data response
export const makeVwDealershipOnlineModulesDataResponse = state => {
    const result = makeVwDealershipOnlineResponse(state);
    return result && result.modules;
};

// return vw dealership online modules lists response
export const makeVwDealershipOnlineModuleListsResponse = state => {
    const result = makeVwDealershipOnlineModulesDataResponse(state);
    return result && result.list;
};

// return vw dealership online modules lists data response
export const makeVwDealershipOnlineModuleListsDataResponse = state => {
    const result = makeVwDealershipOnlineModuleListsResponse(state);
    return result && _get(result, 'data.modules', []);
};

// return vw dealership online modules lists loading response
export const makeVwDealershipOnlineModuleListsLoadingResponse = state => {
    const result = makeVwDealershipOnlineModuleListsResponse(state);
    return result && result.isLoading;
};

// return vw dealership online modules selected response
export const makeVwDealershipOnlineModuleSelectedResponse = state => {
    const result = makeVwDealershipOnlineModulesDataResponse(state);
    return result && result.selected;
};

// return vw dealership online modules selected data response
export const makeVwDealershipOnlineModuleSelectedDataResponse = state => {
    const result = makeVwDealershipOnlineModuleSelectedResponse(state);
    return result && _get(result, "data.students", []);
};

// return vw dealership online modules selected data loading response
export const makeVwDealershipOnlineModuleSelectedDataLoadingResponse = state => {
    const result = makeVwDealershipOnlineModuleSelectedResponse(state);
    return result && result.isLoading;
};

// return vw dealership online modules selected data moduleSelected response
export const makeVwDealershipOnlineModuleSelectedDataSelectedModuleResponse = state => {
    const result = makeVwDealershipOnlineModuleSelectedResponse(state);
    return result && result.moduleSelected;
};