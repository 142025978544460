import React from "react";
import {Progress} from "antd";
import PropTypes from "prop-types";

const ProgressBarCell = ({color, value, displayValue, ...rest}) => {
    return (
        <Progress
            format={(() => displayValue)}
            strokeColor={color}
            percent={value}
            status="normal"
            {...rest}
        />
    )
}

ProgressBarCell.propTypes = {
    color: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default ProgressBarCell;