import _get from "lodash/get";

// return stats data
export const getStatsData = state => _get(state, 'dashboardManage.stats');


// return stats report data
export const getStatsReportData = state => {
    const result = getStatsData(state);
    return result && _get(result, 'reportData.data.response.allData', {});
};

// return stats report data loading
export const getStatsReportDataLoading = state => {
    const result = getStatsData(state);
    return result && result.reportData.isLoading;
};

