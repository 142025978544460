import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Typography } from 'antd';

import SettingHeader from '../../common/SectionHeader';
import SectionGroup from './partials/SectionGroup';
import Loader from '../../common/layout/Loader';
import { withSyncingBanner } from '../partials/hoc/syncingBanner/withSyncingBanner';
import SyncMessage from '../partials/SyncMessage';

import { menu } from '../../../../../constant/vetTrack/menu';

const { Paragraph } = Typography;

const DataSourcesView = ({ isLoading, onSave, isAdmin, syncData }) => (
  <div>
    <Loader isLoading={isLoading} />
    <SettingHeader
      title="Activation"
      items={[
        { name: 'Risk Analysis', href: menu.riskAnalysis },
        'Settings',
        'Active Data Sources',
      ]}
    />
    <Card>
      <div className="vtt-datasources-sub-header">
        <Paragraph strong>Active Data Sources</Paragraph>
        <SyncMessage syncData={syncData} />
      </div>
      <SectionGroup />
    </Card>
    {isAdmin && (
      <div style={{ float: 'right', marginTop: 10 }}>
        <Button type="primary" onClick={onSave}>
          Apply all
        </Button>
      </div>
    )}
  </div>
);

DataSourcesView.propTypes = {
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  isAdmin: PropTypes.bool,
  syncData: PropTypes.object,
};

export default withSyncingBanner(DataSourcesView);
