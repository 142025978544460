import {
    JR_GEN_FINANCIAL_CARDS_DATA_FETCH,
    JR_GEN_FINANCIAL_CARDS_DATA_FETCH_LOADING,
    JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH,
    JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH_LOADING,
    JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH,
    JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING,
} from "../../../constant/actionTypes";

const defaultState = {
    cards: {
        isLoading: false,
        data: {}
    },
    students: {
        isLoading: false,
        data: {}
    },
    overview: {
        isLoading: false,
        data: {
            lineChartData: {
                categories: [],
                seriesData: []
            },
            speedometerData: {},
            pieChartData: {}
        }
    },
}

const raFinancialReducer = (state=defaultState, action) => {
    switch (action.type) {
        case JR_GEN_FINANCIAL_CARDS_DATA_FETCH:
        case JR_GEN_FINANCIAL_CARDS_DATA_FETCH_LOADING:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    ...action.payload
                }
            }
        case JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH:
        case JR_GEN_FINANCIAL_STUDENTS_DATA_FETCH_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    ...action.payload
                }
            }
        case JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH:
        case JR_GEN_FINANCIAL_OVERVIEW_DATA_FETCH_LOADING:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    ...action.payload
                }
            }
        default:
            return state
    }
}

export default raFinancialReducer;