import React, {useState, Fragment, useCallback, useEffect} from "react";
import {Button, Space, Tag} from "antd";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {getDashboardFiltersSelectedData} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/common.selector";
import {
    getComplianceOverviewData,
    getComplianceOverviewLoading,
    getComplianceOverviewDurationToExpire,
    getComplianceOverviewFileNotes
} from "../../../../../../../../../../selectors/jobReadyGeneric/riskAnalysis/compliance.selector";
import {
    fetchComplianceOverview,
    fetchComplianceOverviewDurationToExpire,
    fetchComplianceOverviewFileNotes
} from "../../../../../../../../../../actions/jobReadyGeneric/riskAnalysis/compliance.action";

import Overview from "./Overview";
import PopupTable from "../../../../../common/popupTable/PopupTable";
import StudentDetailCell from "../../../../../common/StudentDetailCell";

import {getUniqValuesForFilters} from "../../../../../../../../utils/general";
import {subSections} from "../../../../../../../../../../constant/jobReadyGeneric/constants";

const OverviewTab = () => {
    const dispatch = useDispatch();
    const [selectedKey, setSelectedKey] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState({});


    // selectors
    const selectedFilters = useSelector(getDashboardFiltersSelectedData);
    const overviewDataLoading = useSelector(getComplianceOverviewLoading);
    const overviewData = useSelector(getComplianceOverviewData)
    const durationToExpireData = useSelector(getComplianceOverviewDurationToExpire)
    const fileNotesData = useSelector(getComplianceOverviewFileNotes)

    // effects
    useEffect(() => {
        if(selectedFilters && !_isEmpty(selectedFilters) && !overviewDataLoading){
             const {compliance} = subSections;
            const data = {
                filters: selectedFilters,
                subSectionId: compliance.id
            }
            dispatch(fetchComplianceOverview(data));
        }
    }, [selectedFilters])

    const onPopupOpen = (key) => {
        setShowPopup(true);
        setSelectedKey(key);
        const selectedData = tableConfigMapper[key];

        const {compliance} = subSections;
        const data = {
            filters: selectedFilters,
            subSectionId: compliance.id,
        }

        selectedData.dispatcher && dispatch(selectedData.dispatcher(data));
        setSelectedConfig(tableConfigMapper[key]);
    }

    const onPopupClose = () => {
        setShowPopup(false);
        setSelectedKey(null);
        setSelectedConfig({});
    }

    const renderFileNotesFilters = () => (
        [
            {
                text: "High",
                value: "High",
            },
            {
                text: "Normal",
                value: "Normal",
            },
            {
                text: "Low",
                value: "Low",
            },
        ]
    )

    const renderFileNotes = (note) => (
        <Tag style={{fontSize:15}} color={note.color}>{note.text}</Tag>
    )

    const getDataSource= useCallback(() => {
        if (selectedKey){
            switch (selectedKey) {
                case "durationToExpire":
                    return durationToExpireData;
                case "fileNotes":
                    return fileNotesData;
            }
        }
        return {}
    }, [selectedKey, durationToExpireData, fileNotesData])

    const renderPopupCustomColumns = () =>  {
        const dataSource = getDataSource().data?.list;
        switch (selectedConfig && selectedConfig.key) {
            case "durationToExpire":
                return [
                    {
                        title: "Expiration Date",
                        dataIndex: "expirationDate",
                        sorter: (a, b) => a.expirationDate && moment(a.expirationDate).isAfter(b.expirationDate),
                        onFilter: (value, record) => record.expirationDate === value,
                        filters: getUniqValuesForFilters(dataSource, "expirationDate", "expirationDate"),
                    },
                    {
                        title: "Type",
                        dataIndex: "type",
                        sorter: (a, b) => a.type && a.type.localeCompare(b.type),
                        onFilter: (value, record) => record.type === value,
                        filters: getUniqValuesForFilters(dataSource, "type", "type"),
                    },
                    {
                        title: "Category",
                        dataIndex: "category",
                        sorter: (a, b) => a.category && a.category.localeCompare(b.category),
                        onFilter: (value, record) => record.category === value,
                        filters: getUniqValuesForFilters(dataSource, "category", "category"),
                    },
                ]
            case "fileNotes":
                return [
                    {
                        title: "File Notes (Priority)",
                        dataIndex: "fileNotes",
                        sorter: (a, b) => a.fileNotes.text && a.fileNotes.text.localeCompare(b.fileNotes.text),
                        onFilter: (value, record) => record.fileNotes.text === value,
                        filters: renderFileNotesFilters(),
                        render: (value) => renderFileNotes(value),
                        align: "center"
                    },
                ]
        }
        return []
    }

    const renderPopupColumns = useCallback(() => {
        const dataSource = getDataSource().data?.list;
        return (
        [
            {
                title: 'Student Details',
                dataIndex: 'studentDetails',
                sorter: (a, b) => a.studentDetails.name && a.studentDetails.name.localeCompare(b.studentDetails.name),
                render: (value) => <StudentDetailCell title={value.name} subtitle={value.id} avatar={value.image}/>,
                onFilter: (value, record) => record.studentDetails.name === value,
                filters: getUniqValuesForFilters(dataSource, "studentDetails.name", "studentDetails.name"),
            },
            ...(renderPopupCustomColumns()),
            // Not in phase 01
            // {
            //     title: "",
            //     align:"center",
            //     render: (value, record) => <Space>
            //         <Button size="small">View</Button>
            //     </Space>
            // },
        ]
    )
    }, [JSON.stringify(selectedConfig)])

    const tableConfigMapper = {
        durationToExpire: {
            title: "Duration to Expire",
            key: "durationToExpire",
            dispatcher: (data) => fetchComplianceOverviewDurationToExpire(data),
            searchableColumns: ["studentDetails.name"]
        },
        fileNotes: {
            title: "File Notes (Priority)",
            key: "fileNotes",
            dispatcher: (data) => fetchComplianceOverviewFileNotes(data),
            searchableColumns: ["studentDetails.name"]
        },
    }

    return (
        <Fragment>
            <Overview
                onPopupOpen={onPopupOpen}
                lineChartData={overviewData?.lineChartData}
                pieChartData={overviewData?.pieChartData}
                barChartData={overviewData?.barChartData}
                dataLoading={overviewDataLoading}
            />
            <PopupTable
                isLoading={getDataSource().isLoading}
                visible={showPopup}
                onCancel={onPopupClose}
                searchEnabled={true}
                title={selectedConfig.title}
                columns={renderPopupColumns()}
                dataSource={getDataSource().data?.list}
                searchableColumns={selectedConfig.searchableColumns}
            />
        </Fragment>
    )
}

export default OverviewTab